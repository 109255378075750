import gql from 'graphql-tag';

export const RejectReasonFragment = gql`
    fragment RejectReason on RejectReason {
        id
        name
        code
        description
        isActive
        isArchived
        createdAt
        updatedAt
        createdBy
        updatedBy
    }
`;
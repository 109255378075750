import React from 'react';
import { useQuery } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import { GET_GAME_ACCOUNTS } from '../../../graphql/query/GET_GAME_ACCOUNTS';
import PlayerAccountItem from './player-game-account-item';

function PlayerGameAccounts(props) {

    const { t, player } = props;

    const { data: gameAccounts } = useQuery(GET_GAME_ACCOUNTS, {
        variables: {
            sorting: [{
                field: "isActive",
                direction: "DESC"
            }, {
                field: "createdAt",
                direction: "DESC"
            }],
            filter: {
                playerId: { eq: player.id },
                isActive: { is: true }
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    return (
        <React.Fragment>
            <div className="player-container">
                <Row>
                    <Col sm="12">
                        <div className="table-responsive">
                            <Table className="table mb-0">
                                <thead>
                                    <tr className="font-size-12">
                                        <th>Created</th>
                                        <th>Product</th>
                                        <th>Login</th>
                                        <th>Password</th>
                                        <th>Balance</th>
                                        <th>Remark</th>
                                        <th>Active?</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {
                                        gameAccounts &&
                                        gameAccounts.gameAccounts.edges.length > 0 &&
                                        gameAccounts.gameAccounts.edges.map((gameAccount, index) => {
                                            return (
                                                <PlayerAccountItem key={index} gameAccount={gameAccount} />
                                            )
                                        })
                                    }
                                </tbody>
                                {
                                    gameAccounts &&
                                    gameAccounts.gameAccounts.edges.length === 0 &&
                                    <tbody className="table-body">
                                        <tr className="text-center pt-3">
                                            <td colSpan={12}>
                                                {t('No Game Account Found')}
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(PlayerGameAccounts));
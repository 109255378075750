import _ from "lodash";
import { Component } from "react";
import { useQuery } from "react-apollo";
import { ME } from "../graphql/query";

export default class RoleHelper extends Component {
  static checkRoles = (roles: any) => {
    const { data: meData } = useQuery(ME, {
      fetchPolicy: "cache-only",
    });

    return _.flatMap(roles).includes(meData.me.role);
  };
}

import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import classnames from "classnames";

import { Nav, NavItem, NavLink, Col, Container, Card, Row, TabPane, TabContent } from "reactstrap";
import ProfileMain from './profile-main'

function Profile(props) {

    const [tabID, setTabID] = React.useState('1')

    console.log("props.location.state.platform.id", props.location.state.user)
    const curUser = props.location.state.user;
    return (
        <React.Fragment>
            <div className="page-content profile-details-link">
                <Container fluid>
                    <Row className="pb-4">
                        <Col lg="12" >
                            <Card className="d-none" >
                                <Row className="p-2 m-0">
                                    <Nav pills className="navtab-bg nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabID === "1"
                                                })}
                                                onClick={() => {
                                                    setTabID("1")
                                                }}
                                            >
                                                <span className="d-none d-sm-block">Profile</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabID === "2"
                                                })}
                                                onClick={() => {
                                                    setTabID("2")
                                                }}
                                            >
                                                <span className="d-none d-sm-block">Banking</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabID === "3"
                                                })}
                                                onClick={() => {
                                                    setTabID("3")
                                                }}
                                            >
                                                <span className="d-none d-sm-block"> Deposit/Withdrawal</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabID === "4"
                                                })}
                                                onClick={() => {
                                                    setTabID("4")
                                                }}
                                            >
                                                <span className="d-none d-sm-block">Transfer</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabID === "5"
                                                })}
                                                onClick={() => {
                                                    setTabID("5")
                                                }}
                                            >
                                                <span className="d-none d-sm-block">Statement</span>
                                            </NavLink>
                                        </NavItem>


                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabID === "6"
                                                })}
                                                onClick={() => {
                                                    setTabID("6")
                                                }}
                                            >
                                                <span className="d-none d-sm-block">Security</span>
                                            </NavLink>
                                        </NavItem>

                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabID === "7"
                                                })}
                                                onClick={() => {
                                                    setTabID("7")

                                                }}
                                            >
                                                <span className="d-none d-sm-block">Bonus</span>
                                            </NavLink>
                                        </NavItem>

                                    </Nav>
                                </Row>
                            </Card>
                            <TabContent activeTab={tabID}>
                                <TabPane tabId="1" className="p-3">
                                    <ProfileMain profile={curUser} ></ProfileMain>
                                </TabPane>
                            </TabContent>

                        </Col>
                    </Row>

                </Container>
            </div>
        </React.Fragment>)
}


export default connect()(withTranslation()(Profile));


import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const REJECT_ONE_WITHDRAWAL = gql`
    mutation(
        $id: String!
    ) {
        rejectOneTransaction (id: $id) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import React from 'react';
import { Modal, Row, Col, ModalHeader, ModalBody, Button, Input } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToastHelper from '../../helpers/ToastHelper';
import { useMutation, useQuery } from 'react-apollo';
import NumberFormat from 'react-number-format';
import { CREATE_ONE_BANK_ACCOUNT_ENTRY, CREATE_ONE_BANK_ACCOUNT_TRANSFER } from '../../graphql/mutation';
import { GET_BANK_ACCOUNTS } from '../../graphql/query';

function AccountInOut(props) {

    const { t, modal, toggleModal, type, onDone, fromBankAccount } = props;

    const [processing, setProcessing] = React.useState(false)

    const [createOneBankAccountEntry, { loading: loadingCreateEntry }] = useMutation(CREATE_ONE_BANK_ACCOUNT_ENTRY)
    const [createOneBankAccountTransfer, { loading: loadingCreateTransfer }] = useMutation(CREATE_ONE_BANK_ACCOUNT_TRANSFER)


    const Filter = () => {
        if (fromBankAccount) {
            return { accountHolderType: { eq: "Company" }, id: { neq: fromBankAccount.id } }
        } else {
            return { accountHolderType: { eq: "Company" } }
        }
    }

    const { data: companyBankData } = useQuery(GET_BANK_ACCOUNTS, {
        variables: {
            paging: {
                first: 50,
            },
            filter: Filter()
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    return (
        <React.Fragment>
            <Modal className="banker-workbench" isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} tabIndex="-1" toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    {type ? type.toUpperCase() : ''}
                </ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={async (event, values) => {
                        if (processing) {
                            return
                        }
                        setProcessing(true)

                        if (type === 'transfer') {
                            let result = await createOneBankAccountTransfer({
                                variables: {
                                    input: {
                                        bankAccountTransfer: {
                                            amount: parseFloat(values.amount.toString().replaceAll(',', '')),
                                            fromBankAccountId: fromBankAccount.id,
                                            toBankAccountId: values.toBankAccount,
                                        }
                                    }
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            })

                            if (result) {
                                if (onDone) {
                                    onDone()
                                }
                            }
                        } else {
                            let result = await createOneBankAccountEntry({
                                variables: {
                                    input: {
                                        bankAccountEntry: {
                                            deposit: type === 'increment' ? parseFloat(values.amount.toString().replaceAll(',', '')) : 0,
                                            withdrawal: type === 'decrement' ? parseFloat(values.amount.toString().replaceAll(',', '')) : 0,
                                            bankAccountId: fromBankAccount.id,
                                            description: values.description,
                                            bankAccountEntryType: type === 'increment' ? 'Increment' : 'Decrement'
                                        }
                                    }
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            })

                            if (result) {
                                if (onDone) {
                                    onDone()
                                }
                            }
                        }

                        setProcessing(false)
                    }}>
                        {
                            <Row>
                                {
                                    type === 'transfer' &&
                                    <>
                                        <Col xs={12} sm={12} md={6} lg={6}>
                                            <AvField
                                                value={`${fromBankAccount.accountName} (${fromBankAccount.accountNo}): $${fromBankAccount.balance}`}
                                                label={t('From Bank Account')}
                                                type="text"
                                                name="fromBankAccount"
                                                placeholder="From Bank Account"
                                                errorMessage={t('From Bank Account')}
                                                validate={{
                                                    required: true
                                                }}
                                                disabled
                                            />
                                        </Col>
                                        {
                                            companyBankData &&
                                            <Col xs={12} sm={12} md={6} lg={6}>
                                                <AvField
                                                    label={t('To Bank Account')}
                                                    type="select"
                                                    name="toBankAccount"
                                                    placeholder="To Bank Account"
                                                    errorMessage={t('To Bank Account')}
                                                    className="custom-select"
                                                    validate={{
                                                        required: true
                                                    }}
                                                    defaultValue={companyBankData.bankAccounts.edges.length > 0 && companyBankData.bankAccounts.edges[0].node.id}
                                                >
                                                    {
                                                        companyBankData &&
                                                        companyBankData.bankAccounts.edges.map(({ node }, index) => {
                                                            return (
                                                                <option key={index} value={node.id}>{`${node.accountName}(${node.accountNo}): $${node.balance}`}</option>
                                                            )
                                                        })
                                                    }
                                                </AvField>
                                            </Col>
                                        }

                                    </>
                                }
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        label={t('Amount')}
                                        type="text"
                                        name="amount"
                                        placeholder="Enter Amount"
                                        errorMessage={t('Enter Amount')}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        tag={[Input, NumberFormat]}
                                        validate={{
                                            required: true,
                                            pattern: {
                                                value: "^[0-9,.]+$",
                                                errorMessage: `${t('Enter Only Numbers')}`
                                            },
                                        }}
                                    />
                                </Col>
                                {
                                    type !== 'transfer' &&
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <AvField
                                            label={t('Description')}
                                            type="text"
                                            name="description"
                                            placeholder="Enter Description"
                                            errorMessage={t('Enter Description')}
                                            validate={{
                                                required: true
                                            }}
                                        />
                                    </Col>
                                }
                            </Row>
                        }
                        <div className="submit-container">
                            <Button color="primary" type="submit">
                                {
                                    (loadingCreateEntry || loadingCreateTransfer) &&
                                    <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                }
                                {
                                    !loadingCreateEntry && !loadingCreateTransfer &&
                                    <div>
                                        Submit
                                </div>
                                }
                            </Button>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(AccountInOut));
import React, { useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";

//Import Breadcrumb
import { useLazyQuery, useQuery, useSubscription } from "@apollo/react-hooks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import NewPlayer from "./new-player";
import PlayerItem from "./player-item";

import { GET_USERS } from "../../graphql/query/GET_USERS";
import { CREATED_USER } from "../../graphql/subscription/CREATED_USER";

import _ from "lodash";
import ResetPassword from "./reset-password";

import classnames from "classnames";
import moment from "moment";
import { CSVLink } from "react-csv";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import {
  OperatingUsers,
  Operator,
  Supervisor,
} from "../../assets/scss/role.constant";
import { ME } from "../../graphql/query";
import { EXPORT_PLAYER_LIST } from "../../graphql/query/EXPORT_PLAYER_LIST";
import { UPDATED_ONE_USER } from "../../graphql/subscription/UPDATED_ONE_USER";
import { toggleRightSidebar } from "../../store/actions";
import EditUser from "../Contacts/edit-user";
import ManageUpline from "./manage-upline";
import ManageTags from "./player-tags";
import PlayerTableItem from "./table-player";

function PlayerList(props) {
  const { t } = props;
  const csvLink = React.useRef(null);

  const first = 50;
  const reportTake = 1000;
  const [addUser, setAddUser] = React.useState(false);
  const [editUser, setEditUser] = React.useState(null);
  const [resetPassword, setResetPassword] = React.useState(null);
  const [afterCursor, setAfterCursor] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [pageInfo, setPageInfo] = React.useState(false);
  const [isFetchMore, setIsFetchMore] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);
  const [newData, setNewData] = React.useState(null);
  const [layoutToggle, setLayoutToggle] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("joinedAt");
  const [orderDirection, setOrderDirection] = React.useState("DESC");

  const [csvData, setCSVData] = React.useState([]);
  const [readyDownload, setReadyDownload] = React.useState(false);
  const [csvPageInfo, setCsvPageInfo] = React.useState(null);

  const [showManageTags, setShowManageTags] = React.useState(null);
  const [showManageUpline, setShowManageUpline] = React.useState(null);
  const history = useHistory();

  const Filter = () => {
    if (!_.isEmpty(search)) {
      return {
        and: [
          {
            role: { eq: "Player" },
          },
          {
            and: [
              {
                or: [
                  { serialNo: { iLike: `%${search}%` } },
                  { name: { iLike: `%${search}%` } },
                  { username: { iLike: `%${search}%` } },
                  { phoneNo: { iLike: `%${search}%` } },
                  { telegramId: { iLike: `%${search}%` } },
                ],
              },
              props.filter,
            ],
          },
        ],
      };
    } else {
      return {
        and: [
          {
            role: { eq: "Player" },
          },
          {
            ...props.filter,
          },
        ],
      };
    }
  };

  const { loading, fetchMore, data, refetch } = useQuery(GET_USERS, {
    variables: {
      paging: {
        first: first,
        after: null,
      },
      filter: Filter(),
      sorting: [
        ...props.sort,
        { field: "isActive", direction: "DESC" },
        { field: orderBy, direction: orderDirection },
      ],
    },
  });

  const [getCSVData, { data: csvTempData }] = useLazyQuery(EXPORT_PLAYER_LIST, {
    fetchPolicy: "no-cache",
  });

  const { data: meData } = useQuery(ME);

  const { data: userCreatedData } = useSubscription(CREATED_USER, {
    variables: {
      createdBy: { neq: meData ? meData.me.username : "" },
    },
  });

  useSubscription(UPDATED_ONE_USER);

  React.useEffect(() => {
    if (isFetchMore) {
      fetchMore({
        variables: {
          paging: {
            first: first,
            after: afterCursor,
          },
          filter: {
            and: [
              {
                role: { eq: "Player" },
              },
              {
                and: [
                  {
                    or: [
                      { name: { iLike: `%${search}%` } },
                      { username: { iLike: `%${search}%` } },
                      { phoneNo: { iLike: `%${search}%` } },
                      { telegramId: { iLike: `%${search}%` } },
                    ],
                  },
                  props.filter,
                ],
              },
            ],
          },
          sorting: [
            ...props.sort,
            { field: "isActive", direction: "DESC" },
            { field: orderBy, direction: orderDirection },
          ],
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.users.edges;
          const pageInfo = fetchMoreResult.users.pageInfo;
          setIsFetchMore(false);
          setAfterCursor(pageInfo.endCursor);

          return newEdges.length
            ? {
                users: {
                  __typename: previousResult.users.__typename,
                  edges: _.uniq([...previousResult.users.edges, ...newEdges]),
                  pageInfo,
                },
              }
            : previousResult;
        },
      });
    }
  }, [isFetchMore]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (userCreatedData) {
      setNewData(userCreatedData.createdUser);
    }
  }, [userCreatedData]);

  React.useEffect(() => {
    if (data) {
      setAfterCursor(data.users.pageInfo.endCursor);
      setPageInfo(data.users.pageInfo);
    }
  }, [data]);

  const OrderBy = (type) => {
    if (orderBy === type) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderDirection("ASC");
      setOrderBy(type);
    }
  };

  const Header = (props) => {
    const { type, className, name } = props;

    return (
      <th
        className={`cursor-pointer ${className}`}
        onClick={() => OrderBy(type)}
      >
        <div
          className={classnames({
            "align-center": orderBy === type,
          })}
        >
          <div>{t(name)}</div>
          {orderBy === type && (
            <>
              {orderDirection === "ASC" && <i className="bx bx-sort-up"></i>}
              {orderDirection === "DESC" && <i className="bx bx-sort-down"></i>}
            </>
          )}
        </div>
      </th>
    );
  };

  const debouncedSave = useRef(
    _.debounce((nextValue) => setSearch(nextValue), 300)
  ).current;

  const DownloadToCSV = () => {
    getCSVData();
    setReadyDownload(true);
  };

  const CSVDownload = () => {
    return (
      <>
        <button
          className="waves-effect btn btn-outline-secondary button-add-user ml-2"
          type="button"
          onClick={DownloadToCSV}
        >
          Download (CSV){" "}
          {readyDownload && <i className="bx bx-hourglass bx-spin mr-3"></i>}
        </button>
        <CSVLink
          ref={csvLink}
          filename={"player-list.csv"}
          className="hidden"
          data={
            csvData
              ? csvData.map((item) => {
                  return {
                    ...item,
                    // mainWallet: WalletHelper.getMainWallet(item.wallets.edges) ? `${WalletHelper.getBalance(WalletHelper.getMainWallet(item.wallets.edges).node)}` : '0.00',
                    // rewardWallet: WalletHelper.getRewardWallet(item.wallets.edges) ? `${WalletHelper.getRewardWallet(item.wallets.edges).node.balance}` : '0',
                    // agentWallet: WalletHelper.getAgentWallet(item.wallets.edges) ? `${WalletHelper.getBalance(WalletHelper.getAgentWallet(item.wallets.edges).node)}` : '0.00',
                    isActive: item.isActive ? "YES" : "NO",
                    tagss: item.tags.map((tag) => {
                      return tag.name;
                    }),
                    lastDepositAt: moment(item.lastDepositAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    joinedAt: moment(item.joinedAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                    bankAccounts: item.bankAccounts.map((account, index) => {
                      return (
                        account.accountName +
                        (index < item.bankAccounts - 1 ? ", " : "")
                      );
                    }),
                  };
                })
              : []
          }
          headers={[
            { label: "Serial Number", key: "serialNo" },
            { label: "Username", key: "username" },
            { label: "Name", key: "name" },
            // { label: 'Main Wallet ($)', key: 'mainWallet' },
            // { label: 'Reward Wallet (RP)', key: 'rewardWallet' },
            // { label: 'Agent Wallet (AP)', key: 'agentWallet' },
            { label: "Mobile Number", key: "phoneNo" },
            { label: "Bank Accounts", key: "bankAccounts" },
            { label: "Joined At", key: "joinedAt" },
            { label: "Last Deposit At", key: "lastDepositAt" },
            { label: "Last Deposit Amount", key: "lastDepositAmount" },
            { label: "Telegram Id", key: "telegramId" },
            { label: "Telegram Username", key: "telegramUsername" },
            { label: "Tags", key: "tagss" },

            { label: "Active?", key: "isActive" },
          ]}
        ></CSVLink>
      </>
    );
  };

  React.useEffect(() => {
    if (csvTempData && readyDownload) {
      setCSVData((prevData) => [...prevData, ...csvTempData.exportPlayerList]);
      // setCsvPageInfo(csvTempData.users.pageInfo)
    }
  }, [csvTempData]);

  React.useEffect(() => {
    if (csvData.length > 0) {
      // if (csvPageInfo.hasNextPage) {
      //     getCSVData({
      //         variables: {
      //             filter: Filter(),
      //             paging: {
      //                 first: reportTake,
      //                 after: csvPageInfo.endCursor,
      //             },
      //             sorting: [...props.sort, { field: "isActive", direction: "DESC" }, { field: orderBy, direction: orderDirection }]
      //         }
      //     })
      // } else {
      csvLink.current.link.click();
      setReadyDownload(false);
      setCSVData([]);
      //     }
    }
    // }, [csvPageInfo])
  }, [csvData]);

  return (
    <React.Fragment>
      <div className="page-content users-link">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Player" breadcrumbItem="Player List" />
          <div className="search-bar-container">
            <div className="button-add-user-container">
              <div className="app-search d-none d-lg-block">
                <div className="position-relative">
                  <input
                    value={searchValue}
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                      debouncedSave(e.target.value);
                    }}
                  />
                  <span className="bx bx-search-alt"></span>
                </div>
              </div>
              <div className="d-flex">
                <div className="dropdown d-inline-block d-lg-none ml-2">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    id="page-header-search-dropdown"
                    onClick={() => {
                      setIsSearch(!isSearch);
                    }}
                  >
                    <i className="mdi mdi-magnify"></i>
                  </button>
                  <div
                    className={
                      isSearch
                        ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                        : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                    }
                    aria-labelledby="page-header-search-dropdown"
                  >
                    <form className="p-3">
                      <div className="form-group m-0">
                        <div className="input-group">
                          <input
                            value={searchValue}
                            type="text"
                            className="form-control"
                            placeholder={props.t("Search") + "..."}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                              debouncedSave(e.target.value);
                            }}
                          />
                          <div className="input-group-append">
                            <button className="btn btn-primary" type="submit">
                              <i className="mdi mdi-magnify"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>

            <div className="button-add-user-container">
              <div className="filter-container button-filter-container">
                <button
                  type="button"
                  className="waves-effect btn btn-outline-secondary button-add-user"
                  onClick={() => {
                    props.toggleRightSidebar();
                  }}
                >
                  <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i>{" "}
                  |{" "}
                  <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                </button>
              </div>
              {/* <div className="filter-container button-filter-container px-2">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-user" onClick={() => {
                                    setLayoutToggle(!layoutToggle)
                                }}>
                                    <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div> */}
              <button
                type="button"
                className="waves-effect btn btn-outline-secondary button-add-user ml-2"
                onClick={() => {
                  setAddUser(true);
                }}
              >
                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New
                Player
              </button>
              {meData &&
                (meData.me.role === "Developer" ||
                  meData.me.role === "Admin") && <CSVDownload />}
            </div>

            <div className={`reload-container ${newData ? "active" : ""}`}>
              <Card>
                <CardBody
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  <div className="reload-title">
                    <i className="bx bx-refresh"></i>Reload
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
          <Row>
            {!layoutToggle && data && (
              <Card className="w-100">
                <InfiniteScroll
                  dataLength={data.users.edges.length} //This is important field to render the next data
                  next={() => setIsFetchMore(true)}
                  hasMore={pageInfo.hasNextPage}
                  loader={
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin mr-2"></i> Load
                        more{" "}
                      </Link>
                    </div>
                  }
                  endMessage={
                    <div className="text-center my-3">
                      <div>Yay! You have seen it all</div>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table className="table mb-0 min-h-300">
                      <thead>
                        <tr className="font-size-12">
                          <Header
                            name="Serial No"
                            className=""
                            type="serialNo"
                          />
                          <Header
                            name="Username"
                            className=""
                            type="username"
                          />
                          <th>Wallets</th>
                          <Header name="Name" className="" type="name" />
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) &&
                            !Supervisor.includes(meData.me.role) && (
                              <Header
                                name="Mobile Number"
                                className=""
                                type="phoneNo"
                              />
                            )}
                          <th>Downlines</th>
                          <Header
                            name="Joined At"
                            className=""
                            type="joinedAt"
                          />
                          <Header
                            name="Last Deposit At"
                            className=""
                            type="lastDepositAt"
                          />
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <Header
                                name="Active?"
                                className=""
                                type="isActive"
                              />
                            )}
                          <Header
                            name="Enable Reward Point?"
                            className=""
                            type="enableRewardPoint"
                          />
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <Header
                                name="Telegram ID"
                                className=""
                                type="telegramId"
                              />
                            )}
                          <th>Tags</th>
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <th>Actions</th>
                            )}
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {data.users.edges.map((user, key) => (
                          <PlayerTableItem
                            user={user}
                            meData={meData}
                            key={"_user_" + key}
                            onEdit={() => {
                              setEditUser(user.node);
                            }}
                            onResetPassword={() => {
                              setResetPassword(user.node);
                            }}
                            onManageWallet={(wallet) => {
                              let path = `wallet-details/${user.node.id}/${wallet.id}`;
                              window.open(path, "_blank");
                            }}
                            onManageUser={() => {
                              history.push({
                                pathname: "/profile",
                                state: { user: user.node },
                              });
                            }}
                            onManageTags={() => {
                              setShowManageTags(user.node);
                            }}
                            onManageInWorkbench={() => {
                              history.push({
                                pathname: "/cashier-workbench",
                                target: "_blank",
                                state: { user: user },
                              });
                            }}
                            onChangeUpline={() => {
                              setShowManageUpline(user.node);
                            }}
                          ></PlayerTableItem>
                        ))}
                        {data.users.edges.length === 0 && (
                          <tr className="text-center pb-2">
                            <td colSpan={12}>
                              <div className="no-user pt-0">No User Found</div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </InfiniteScroll>
              </Card>
            )}

            {layoutToggle && (
              <InfiniteScroll
                dataLength={data.users.edges.length} //This is important field to render the next data
                next={() => setIsFetchMore(true)}
                hasMore={pageInfo.hasNextPage}
                loader={
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin mr-2"></i> Load more{" "}
                    </Link>
                  </div>
                }
                endMessage={
                  <div className="text-center my-3">
                    <div>Yay! You have seen it all</div>
                  </div>
                }
              >
                <Col lg="12">
                  <Row>
                    {data &&
                      data.users.edges.map((user, key) => (
                        <PlayerItem
                          user={user}
                          key={"_user_" + key}
                          onEdit={() => {
                            setEditUser(user.node);
                          }}
                          onResetPassword={() => {
                            setResetPassword(user.node);
                          }}
                          onManageWallet={(wallet) => {
                            let path = `wallet-details/${user.node.id}/${wallet.id}`;
                            window.open(path, "_blank");
                          }}
                          onManageUser={() => {
                            history.push({
                              pathname: "/profile",
                              state: { user: user.node },
                            });
                          }}
                          onManageTags={() => {
                            setShowManageTags(user.node);
                          }}
                          onManageInWorkbench={() => {
                            history.push({
                              pathname: "/cashier-workbench",
                              state: { user: user },
                            });
                          }}
                        />
                      ))}
                    {data && data.users.edges.length === 0 && (
                      <Col xl="12" sm="12">
                        <div className="no-user">No User Found</div>
                      </Col>
                    )}
                  </Row>
                </Col>
              </InfiniteScroll>
            )}
          </Row>
          <div className="load-more-container">
            {loading && !pageInfo && (
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-hourglass bx-spin mr-2"></i> Load more{" "}
                  </Link>
                </div>
              </Col>
            )}
          </div>
          <NewPlayer
            modal={addUser}
            toggleModal={() => {
              refetch();
              setAddUser(!addUser);
            }}
          />
          <EditUser
            modal={
              meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role)
                ? !_.isEmpty(editUser)
                : false
            }
            toggleModal={() => {
              setEditUser(null);
            }}
            user={editUser}
          />
          <ResetPassword
            modal={!_.isEmpty(resetPassword)}
            toggleModal={() => {
              setResetPassword(null);
            }}
            user={resetPassword}
          />
          {!_.isEmpty(showManageTags) && (
            <ManageTags
              modal={!_.isEmpty(showManageTags)}
              toggleModal={() => {
                setShowManageTags(null);
                refetch();
              }}
              user={showManageTags}
            />
          )}

          {!_.isEmpty(showManageUpline) && (
            <ManageUpline
              modal={!_.isEmpty(showManageUpline)}
              toggleModal={() => {
                setShowManageUpline(null);
                refetch();
              }}
              user={showManageUpline}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType, ...state.Player };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(PlayerList)
);

// export default PlayerList;

import React, { Fragment } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Input,
    Button,
    FormGroup
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { UPDATE_ONE_BANK_ACCOUNT } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';
import { useQuery } from '@apollo/react-hooks';
import { GET_COUNTRY, GET_BANKS, GET_ENUM } from '../../graphql/query';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import NumberFormat from 'react-number-format';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

function EditBankAccount(props) {

    const { modal, toggleModal, t, account } = props;

    const [isValidCountry, setIsValidCountry] = React.useState(true);
    const [isValidBank, setIsValidBank] = React.useState(true);
    const [country, setCountry] = React.useState([]);
    const [bank, setBank] = React.useState([])

    const [search, setSearch] = React.useState('');
    const [bankSearch, setBankSearch] = React.useState('');

    const [accountName, setAccountName] = React.useState('')
    const [accountNo, setAccountNo] = React.useState('')
    const [canDeposit, setCanDeposit] = React.useState(true)
    const [canWithdraw, setCanWithdraw] = React.useState(true)
    const [isActive, setIsActive] = React.useState(true)
    const [login, setLogin] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [depositLimit, setDepositLimit] = React.useState('100000')
    const [withdrawLimit, setWithdrawLimit] = React.useState('20000')
    const [accountHolderType, setAccountHolderType] = React.useState('Company')
    const [accountStatus, setAccountStatus] = React.useState('Active')

    const { data: countryData, loading: loadingCountry, refetch: refetchCountry } = useQuery(GET_COUNTRY, {
        variables: {
            filter: search === '' ? {} : {
                name: { iLike: `%${search}%` }
            }
        },
        notifyOnNetworkStatusChange: true
    });

    const { data: bankData, loading: loadingBank, refetch: refetchBank } = useQuery(GET_BANKS, {
        variables: {
            filter: {
                and: [
                    { name: { iLike: `%${bankSearch}%` } },
                    { country: { name: { iLike: `%${country.length > 0 ? country[0].node.name : ''}%` } } }
                ]
            }
        },
        notifyOnNetworkStatusChange: true
    });

    const { data: accountStatusData } = useQuery(GET_ENUM, {
        variables: {
            name: "BankAccountStatus"
        }
    });

    const [updateOneBankAccount] = useMutation(UPDATE_ONE_BANK_ACCOUNT);

    React.useEffect(() => {
        if (account) {
            setCountry([{ node: account.bank.country }])
            setBank([{ node: account.bank }])
            setIsValidCountry(true)
            setIsValidBank(true)
            setAccountName(account.accountName)
            setAccountNo(account.accountNo)
            setAccountHolderType('Company')
            setLogin(account.login)
            setPassword(account.password)
            setDepositLimit(account.dailyDepositLimit)
            setWithdrawLimit(account.dailyWithdrawalLimit)
            setCanDeposit(account.canDeposit)
            setCanWithdraw(account.canWithdraw)
            setIsActive(account.isActive)
        } else {
            setCountry([])
            setBank([])
            setIsValidCountry(true)
            setIsValidBank(true)
            setAccountName('')
            setAccountNo('')
            setAccountHolderType('Company')
            setLogin('')
            setPassword('')
            setDepositLimit('100000')
            setWithdrawLimit('20000')
            setCanDeposit(true)
            setCanWithdraw(true)
            setIsActive(true)
        }
    }, [account])

    React.useEffect(() => {
        refetchBank()
    }, [country]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        refetchCountry()
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleCountrySearch = (query) => {
        setSearch(query)
    }

    const handleBankSearch = (query) => {
        setBankSearch(query)
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-account-page">
                    <ModalHeader toggle={toggleModal}>
                        {t('Edit Bank Account')}
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-account-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={() => {
                                    if (country.length > 0 && bank.length > 0) {
                                        updateOneBankAccount({
                                            variables: {
                                                input: {
                                                    update: {
                                                        bankId: bank[0].node.id,
                                                        accountName: accountName,
                                                        accountNo: accountNo,
                                                        accountHolderType: accountHolderType,
                                                        bankAccountStatus: accountStatus,
                                                        login: login,
                                                        password: password,
                                                        dailyDepositLimit: depositLimit === '' ? 0 : parseFloat(depositLimit.toString().replaceAll(',','')),
                                                        dailyWithdrawalLimit: withdrawLimit === '' ? 0 : parseFloat(withdrawLimit.toString().replaceAll(',','')),
                                                        canDeposit: canDeposit,
                                                        canWithdraw: canWithdraw,
                                                        isActive: isActive
                                                    },
                                                    id: account.id
                                                }
                                            }
                                        }).catch(e => {
                                            ToastHelper.toggleToast({
                                                toastType: "error",
                                                message: e,
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }).then(val => {
                                            if (val) {
                                                ToastHelper.toggleToast({
                                                    message: "Update Succesfully!",
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                                toggleModal()
                                            }
                                        })
                                    } else {
                                        if (country.length === 0) {
                                            setIsValidCountry(false)
                                        }

                                        if (bank.length === 0) {
                                            setIsValidBank(false)
                                        }
                                    }
                                }}
                                onInvalidSubmit={() => {
                                    if (country.length === 0) {
                                        setIsValidCountry(false)
                                    }

                                    if (bank.length === 0) {
                                        setIsValidBank(false)
                                    }
                                }}
                            >
                                <Row>
                                    <Col lg="6">
                                        <FormGroup className={`${!isValidCountry ? 'text-danger' : ''}`}>
                                            <Label for="newrole-input">{t('Country')}</Label>
                                            <AsyncTypeahead
                                                id="country-list"
                                                isLoading={loadingCountry}
                                                labelKey={option => `${option.node.name}`}
                                                minLength={0}
                                                selected={country}
                                                onSearch={handleCountrySearch}
                                                onInputChange={(val) => {
                                                    if (val === "") {
                                                        handleCountrySearch('')
                                                    }
                                                }}
                                                options={countryData ? countryData.countries.edges : []}
                                                placeholder={t('Search for Country')}
                                                useCache={false}
                                                renderMenuItemChildren={(option, props) => (
                                                    <Fragment>
                                                        <span>{option.node.name}</span>
                                                    </Fragment>
                                                )}
                                                errorMessage={t('Enter country name')}
                                                isInvalid={!isValidCountry}
                                                onChange={(val) => {
                                                    setIsValidCountry(val.length > 0 ? true : false)
                                                    setCountry(val)
                                                    if (val.length === 0) {
                                                        setBank([])
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (isValidCountry && country.length === 0) {
                                                        setIsValidCountry(false)
                                                        setBank([])
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        country.length > 0 &&
                                        <Col lg="6">
                                            <FormGroup className={`${!isValidBank ? 'text-danger' : ''}`}>
                                                <Label for="newrole-input">{t('Bank')}</Label>
                                                <AsyncTypeahead
                                                    id="country-list"
                                                    isLoading={loadingBank}
                                                    labelKey={option => `${option.node.name}`}
                                                    minLength={0}
                                                    selected={bank}
                                                    onSearch={handleBankSearch}
                                                    onInputChange={(val) => {
                                                        if (val === "") {
                                                            handleBankSearch('')
                                                        }
                                                    }}
                                                    options={bankData ? bankData.banks.edges : []}
                                                    placeholder={t('Search for Bank')}
                                                    useCache={false}
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <span>{option.node.name}</span>
                                                        </Fragment>
                                                    )}
                                                    errorMessage={t('Enter bank name')}
                                                    isInvalid={!isValidBank}
                                                    onChange={(val) => {
                                                        setIsValidBank(val.length > 0 ? true : false)
                                                        setBank(val)
                                                    }}
                                                    onBlur={(e) => {
                                                        if (isValidBank && bank.length === 0) {
                                                            setIsValidBank(false)
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            value={accountName}
                                            label={t('Account Name')}
                                            name="accountName"
                                            type="text"
                                            placeholder={t('Enter Account Name')}
                                            errorMessage={t('Enter Account Name')}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            onChange={(e) => setAccountName(e.target.value.toUpperCase())}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            value={accountNo}
                                            name="accountNo"
                                            label={t('Account Number')}
                                            placeholder={t('Enter Account Number')}
                                            type="text"
                                            errorMessage={t('Enter Account Number')}
                                            validate={{
                                                required: { value: true },
                                            }}
                                            onChange={(e) => setAccountNo(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label for="newrole-input">{t('Account Status')}</Label>
                                            <select className="custom-select" id="newrole-input" defaultValue={accountStatus} onChange={(e) => setAccountStatus(e.target.value)}>
                                                {
                                                    accountStatusData &&
                                                    accountStatusData.__type.enumValues.map(({ name }, index) => {
                                                        return (
                                                            <option key={index} value={name}>{name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            value={login}
                                            label={t('Login')}
                                            name="login"
                                            type="text"
                                            placeholder={t('Online Banking Login')}
                                            errorMessage={t('Enter Online Banking Login')}
                                            validate={{
                                                // required: { value: true },
                                            }}
                                            onChange={(e) => setLogin(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            value={password}
                                            name="password"
                                            label={t('Password')}
                                            placeholder={t('Online Banking Password')}
                                            type="password"
                                            errorMessage={t('Enter Online Banking Password')}
                                            validate={{
                                                // required: { value: true }
                                            }}
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            value={depositLimit}
                                            label={`${t('Daily Deposit Limit')} (${t('Optional')})`}
                                            name="depositLimit"
                                            type="text"
                                            placeholder={t('Daily Deposit Limit')}
                                            errorMessage={t('Enter Daily Deposit Limit')}
                                            thousandSeparator
                                            decimalScale={2}
                                            fixedDecimalScale
                                            tag={[Input, NumberFormat]}
                                            validate={{
                                                pattern: {
                                                    value: "^[0-9,.]+$",
                                                    errorMessage: `${t('Enter Only Numbers')}`
                                                }
                                            }}
                                            onChange={(e) => setDepositLimit(e.target.value)}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            value={withdrawLimit}
                                            name="withdrawLimit"
                                            label={`${t('Daily Withdraw Limit')} (${t('Optional')})`}
                                            placeholder={t('Daily Withdraw Limit')}
                                            type="text"
                                            errorMessage={t('Enter Daily Withdraw Limit')}
                                            thousandSeparator
                                            decimalScale={2}
                                            fixedDecimalScale
                                            tag={[Input, NumberFormat]}
                                            validate={{
                                                pattern: {
                                                    value: "^[0-9,.]+$",
                                                    errorMessage: `${t('Enter Only Numbers')}`
                                                }
                                            }}
                                            onChange={(e) => setWithdrawLimit(e.target.value)}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label for="newrole-input">{t('Ability')}</Label>
                                            <Row>
                                                <Col lg="12">
                                                    <div className="custom-control custom-checkbox">
                                                        <Input checked={canDeposit} type="checkbox" className="custom-control-input checkbox" id="edit-deposit" onChange={(e) => setCanDeposit(!canDeposit)} />
                                                        <Label className="custom-control-label" htmlFor="edit-deposit">{t('Deposit')}</Label>
                                                    </div>
                                                </Col>
                                                <Col lg="12">
                                                    <div className="custom-control custom-checkbox">
                                                        <Input checked={canWithdraw} type="checkbox" className="custom-control-input checkbox" id="edit-withdraw" onChange={(e) => setCanWithdraw(!canWithdraw)} />
                                                        <Label className="custom-control-label" htmlFor="edit-withdraw">{t('Withdraw')}</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label for="newrole-input">{t('Disabled?')}</Label>
                                            <Row>
                                                <Col lg="12">
                                                    <div className="custom-control custom-checkbox">
                                                        <Input checked={isActive} type="checkbox" className="custom-control-input checkbox" id="isActive" onChange={(e) => setIsActive(!isActive)} />
                                                        <Label className="custom-control-label" htmlFor="isActive">{t('Active')}</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="action-container">
                                    <Button color="primary" type="submit">{t('Submit')}</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default connect()(withTranslation()(EditBankAccount));

import gql from 'graphql-tag';
import { RejectReasonFragment } from '../fragments/reject.reason.fragment';

export const CREATE_ONE_REJECT_REASON = gql`
    mutation(
        $input: CreateOneRejectReasonInput!
    ) {
        createOneRejectReason (input: $input) {
            ...RejectReason
        }
    }
    ${RejectReasonFragment}
`;



import React, { Fragment } from 'react';
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    FormGroup,
    Label
} from "reactstrap";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { GET_USERS } from '../../graphql/query';
import { UPDATE_ONE_GAME_ACCOUNT } from '../../graphql/mutation/UPDATE_GAME_ACCOUNT';
export default function EditGameAccount(props) {

    const { modal, toggleModal, curPlatform, gameAccount } = props;
    const [updateOneGameAccount] = useMutation(UPDATE_ONE_GAME_ACCOUNT);

    const [isValidUser, setIsValidUser] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [user, setUser] = React.useState(null);

    const [platform, setPlatform] = React.useState(null);


    React.useEffect(() => {
        if (curPlatform) {
            setPlatform(curPlatform);
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        setSearch('')
        setIsValidUser(true)
        setUser(null)
    }, [modal])


    React.useEffect(() => {
        if (gameAccount) {
            setUser(gameAccount.player)
        }
    }, [gameAccount]) 

    const handleUserSearch = (query) => {
        setSearch(query)
    }


    const { data: userData, loading: loadingUser } = useQuery(GET_USERS, {
        variables: {
            filter: search === '' ? {} : {
                name: { iLike: `%${search}%` }
            }
        },
        notifyOnNetworkStatusChange: true
    });

    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-game-page">
                <ModalHeader toggle={toggleModal}>
                    Edit Game Account
            </ModalHeader>
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                console.log(user);

                                if (user) {
                                    updateOneGameAccount({
                                        variables: {
                                            input: {
                                                "update": {
                                                    "username": values.username,
                                                    "password": values.password
                                                },
                                                id: gameAccount.id
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })

                                } else {
                                    if (user) {
                                        setIsValidUser(false)
                                    }
                                }
                            }
                            }
                            onInvalidSubmit={() => {
                                if (user) {
                                    setIsValidUser(false)
                                }
                            }}
                        >
                            <Row>
                                <Col lg="6">
                                    <FormGroup className={`${!isValidUser ? 'text-danger' : ''}`}>
                                        <Label for="newrole-input">Player</Label>
                                        <AsyncTypeahead
                                            disabled={true}
                                            id="user-list"
                                            isLoading={loadingUser}
                                            labelKey={option => `${option.node.username}`}
                                            minLength={0}
                                            onSearch={handleUserSearch}
                                            onInputChange={(val) => {
                                                if (val === "") {
                                                    handleUserSearch('')
                                                }
                                            }}
                                            defaultInputValue={gameAccount.player ? gameAccount.player.username : ""}
                                            options={userData ? userData.users.edges : []}
                                            placeholder="Search for Users"
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <span>{option.node.username}</span>
                                                </Fragment>
                                            )}
                                            errorMessage="Enter Username"
                                            isInvalid={!isValidUser}
                                            onChange={(val) => {
                                                setIsValidUser(val.length > 0 ? true : false)
                                                setUser(val[0].node)
                                            }}
                                            onBlur={(e) => {
                                                if (isValidUser && user) {
                                                    setIsValidUser(false)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="6">
                                    {gameAccount.kioskAccount && <AvField
                                        label="Kiosk Account"
                                        name="KioskAccount"
                                        type="text"
                                        disabled={true}
                                        value={gameAccount.kioskAccount.username}
                                        placeholder=""
                                        errorMessage=""
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />}
                                </Col>

                            </Row>
                            <Row>
                                <Col lg="6">
                                    <AvField
                                        label="Game Account Login"
                                        name="username"
                                        type="text"
                                        placeholder="Enter Game Account Login"
                                        errorMessage="Enter Game Account Login"
                                        value={gameAccount.username}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="Password"
                                        name="password"
                                        type="text"
                                        placeholder="Enter Password"
                                        errorMessage="Enter Password"
                                        value={gameAccount.password}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>

                            </Row>

                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';

export const FORCE_QUIT_KIOSK_ACCOUNT = gql`
    mutation(
        $id: String!
    ) {
        kioskAccountForceQuitBrowser (
            id: $id
            ) {
            ...KioskInfo
        }
    }
    ${KioskFragment}
`;
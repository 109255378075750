import gql from "graphql-tag";
import { TelegramMenuButtonFragment } from "../fragments/telegram-menu-button.fragment";

export const CREATE_ONE_TELEGRAM_MENU_BUTTON = gql`
  mutation ($input: CreateOneTelegramMenuButtonInput!) {
    createOneTelegramMenuButton(input: $input) {
      ...TelegramMenuButton
    }
  }
  ${TelegramMenuButtonFragment}
`;

import React from 'react';
import { Table } from "reactstrap";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { withTranslation } from 'react-i18next';

function BankEntryTable(props) {

    const { data, t } = props

    return (
        <React.Fragment>
            <div className="wallet-table">
                <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                        <thead>
                            <tr>
                                <th scope="col">Created At</th>
                                <th scope="col">Type</th>
                                <th scope="col">Description</th>
                                <th scope="col">Deposit</th>
                                <th scope="col">Withdrawal</th>
                                <th scope="col">Transaction Ref No.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 &&
                                data.map((entry, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {/* <div>
                                                    <h5 className="text-truncate font-size-14">Wireless Headphone (Black)</h5>
                                                    <p className="text-muted mb-0">$ 225 x 1</p>
                                                </div> */}
                                                {moment(entry.node.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                                            </td>
                                            <td>{entry.node.bankAccountEntryType}</td>
                                            <td>{entry.node.description}</td>
                                            <td>
                                                {
                                                    entry.node.deposit > 0 &&
                                                    <NumberFormat
                                                        value={entry.node.deposit}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        prefix='$'
                                                    />
                                                }
                                            </td>
                                            <td>
                                                {
                                                    entry.node.withdrawal > 0 &&
                                                    <NumberFormat
                                                        value={entry.node.withdrawal}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        decimalScale={2}
                                                        prefix='$'
                                                    />
                                                }
                                            </td>
                                            <td>{entry.node.transaction ? entry.node.transaction.serialNo : 'N/A'}</td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                data.length === 0 &&
                                <tr className="text-center pt-3">
                                    <td colSpan={12}>
                                        {t('No Bank Account Entry Found')}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(BankEntryTable);
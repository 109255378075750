import gql from "graphql-tag";
import { TelegramButtonFragment } from "../fragments/telegram-button-fragment";

export const CREATE_ONE_TELEGRAM_BUTTON = gql`
  mutation ($input: CreateOneTelegramButtonInput!) {
    createOneTelegramButton(input: $input) {
      ...TelegramButton
    }
  }
  ${TelegramButtonFragment}
`;

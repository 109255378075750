import { AvForm } from "availity-reactstrap-validation";
import React, { Fragment, useEffect, useRef } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Button, Card, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { CHANGE_UPLINE } from "../../graphql/mutation/CHANGE_UPLINE";
import { GET_USER } from '../../graphql/query';
import ToastHelper from "../../helpers/ToastHelper";

export default function ManageUpline(props) {

    const { modal, toggleModal, user } = props;

    const [search, setSearch] = React.useState("");
    const [currentUpline, setCurrentUpline] = React.useState([]);
    // const [tagList, setTagList] = React.useState([]);

    const typeaheadRef = useRef(null);


    const handleTagSearch = (data) => {
        setSearch(data)
    }

    const { data, loading } = useQuery(GET_USER, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                and: [
                    {
                        or: [
                            { username: { iLike: `%${search}%` } },
                            { name: { iLike: `%${search}%` } },
                        ]
                    },
                    {
                        username: { neq: user.username }
                    }
                ]
            },
            sorting: {
                field: 'createdAt',
                direction: 'ASC'
            },
        },
        fetchPolicy: 'network-only'
    })

    const [changeUpline] = useMutation(CHANGE_UPLINE);

    useEffect(() => {
        setSearch("");
        if (user.upline) {
            setCurrentUpline([{
                node: {
                    ...user.upline
                }
            }])
        }
        // setCreateTagState(false);
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    const removeUpline = () => {
        changeUpline({
            variables: {
                input: {
                    userId: user.id,
                    uplineUsername: null,
                    force: true
                }
            }
        }).then(() => {
            ToastHelper.toggleToast({
                message: "Update Succesfully!",
                title: "",
                onClick: function () { }
            })
            toggleModal()
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        })
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                {
                    user &&
                    <div className="modal-content change-upline">
                        <ModalHeader toggle={toggleModal}>
                            Manage Player UpLine
                        </ModalHeader>
                        <ModalBody>
                            <Card id="reset-password-wizard" className="twitter-bs-wizard m-1 p-2">
                                <AvForm className="needs-validation" onValidSubmit={(event, values) => {
                                    changeUpline({
                                        variables: {
                                            input: {
                                                userId: user.id,
                                                uplineUsername: currentUpline[0].node.username,
                                                force: true
                                            }
                                        }
                                    }).then(() => {
                                        ToastHelper.toggleToast({
                                            message: "Update Succesfully!",
                                            title: "",
                                            onClick: function () { }
                                        })
                                        toggleModal()
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    })
                                }}>

                                    <Row className="align-items-center">
                                        <Col lg="12">
                                            <FormGroup >
                                                <Label for="newrole-input">Upline for {user.name}</Label>
                                                <AsyncTypeahead
                                                    multiple={false}
                                                    delay={100}
                                                    id="tag"
                                                    isLoading={loading}
                                                    labelKey={option => `${option.node.username}`}
                                                    minLength={0}
                                                    onSearch={handleTagSearch}
                                                    onInputChange={(val) => {
                                                        if (val === "") {
                                                            setSearch(val)
                                                        }
                                                    }}

                                                    options={data ? data.users.edges : []}
                                                    placeholder="Search for Upline"
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <span>{option.node.username}</span>
                                                        </Fragment>
                                                    )}
                                                    errorMessage="Enter Upline"
                                                    onChange={(val) => {
                                                        setCurrentUpline(val)
                                                    }}
                                                    selected={currentUpline}
                                                    ref={typeaheadRef}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <div style={{
                                        display: 'flex',
                                        'justify-content': 'flex-end'
                                    }}>
                                        <Button color="danger" onClick={removeUpline} style={{ "margin-right": '10px' }}>{('Remove Upline')}</Button>
                                        <Button color="primary" type="submit" disabled={currentUpline.length === 0}>{('Submit')}</Button>
                                    </div>
                                </AvForm>

                            </Card>
                        </ModalBody>
                    </div>
                }
            </Modal>
        </React.Fragment>
    );
}
import React, { useEffect, Fragment } from "react";
import { Input, Label } from "reactstrap";

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {
    hideRightSidebar,
    changeFilter,
    changeSort
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";
import DatePicker from "react-datepicker";

import { Link } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { GET_COUNTRY, GET_ENUM } from '../../graphql/query';
import _ from 'lodash';
import { AvForm } from "availity-reactstrap-validation";
import { Typeahead } from 'react-bootstrap-typeahead';

import "../../components/CommonForBoth/rightbar.scss"

function BankAccountFilter(props) {

    const { t } = props
    //filter
    const [accountStatus, setAccountStatus] = React.useState([])
    const [canDeposit, setCanDeposit] = React.useState(true)
    const [canWithdraw, setCanWithdraw] = React.useState(true)
    const [createStart, setCreateStart] = React.useState(null)
    const [createEnd, setCreateEnd] = React.useState(null)
    const [updateStart, setUpdateStart] = React.useState(null)
    const [updateEnd, setUpdateEnd] = React.useState(null)
    const [country, setCountry] = React.useState([])

    //sort
    const [sortBankAccountName, setSortBankAccountName] = React.useState(null)
    const [sortCreate, setSortCreate] = React.useState(null)
    const [sortUpdate, setSortUpdate] = React.useState(null)

    const { data: countryData, loading: loadingCountry } = useQuery(GET_COUNTRY, {
        variables: {
            filter: {}
        },
        notifyOnNetworkStatusChange: true
    });

    const { data: accountStatusData } = useQuery(GET_ENUM, {
        variables: {
            name: "BankAccountStatus"
        }
    });

    const hideRightSidebar = (e) => {
        e.preventDefault();
        props.hideRightSidebar();
    }


    const changeFilter = (data) => {
        props.changeFilter(data);
    }

    const changeSort = (data) => {
        props.changeSort(data);
    }

    const ResetFilter = () => {
        setCountry([])
        setCreateStart(null)
        setCreateEnd(null)
        setUpdateStart(null)
        setUpdateEnd(null)
        setCanDeposit(true)
        setCanWithdraw(true)
        var x = document.getElementsByClassName("checkbox");
        _.forEach(x, checkbox => {
            checkbox.checked = false;
        })
    }

    const ResetSort = () => {
        setSortBankAccountName(null)
        setSortCreate(null)
        setSortUpdate(null)
    }

    useEffect(() => {
        ResetFilter()
        ResetSort()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const Filter = () => {
        if (country.length > 0) {
            return {
                and: [
                    {
                        bank: {
                            countryId: {
                                in: _.map(country, item => {
                                    return item.node.id
                                })
                            }
                        }
                    },
                    { accountHolderType: { eq: "Company" } },
                    { bankAccountStatus: { in: GenerateAccountStatusFilter(accountStatus) } },
                    {
                        or: [
                            { canDeposit: { is: canDeposit } },
                            { canWithdraw: { is: canWithdraw } }
                        ]
                    }
                ]
            }
        } else {
            return {
                and: [
                    { accountHolderType: { eq: "Company" } },
                    { bankAccountStatus: { in: GenerateAccountStatusFilter(accountStatus) } },
                    {
                        or: [
                            { canDeposit: { is: canDeposit } },
                            { canWithdraw: { is: canWithdraw } }
                        ]
                    }
                ]
            }
        }
    }

    useEffect(() => {
        if (accountStatusData) {
            changeFilter({
                belongs: "bank-accounts",
                filter: Filter()
            })
        }
    }, [country, accountStatus, createStart, createEnd, updateStart, updateEnd, canDeposit, canWithdraw]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let sorts = []

        if (sortBankAccountName !== null) {
            sorts.push({
                field: "accountName", direction: sortBankAccountName
            })
        }

        if (sortCreate !== null) {
            sorts.push({
                field: "createdAt", direction: sortCreate
            })
        }

        if (sortUpdate !== null) {
            sorts.push({
                field: "updatedAt", direction: sortUpdate
            })
        }

        changeSort(sorts)
    }, [sortBankAccountName, sortCreate, sortUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    const GenerateAccountStatusFilter = (status) => {
        let allData = [];
        let data = [];
        _.forEach(accountStatus, status => {
            data.push(status)
        })

        _.forEach(accountStatusData.__type.enumValues, ({ name }) => {
            allData.push(name)
        })

        return data.length === 0 ? allData : data
    }

    return (
        <React.Fragment>
            <div className="right-bar bank-filter-page bank-link">

                <SimpleBar style={{ height: "100vh" }}>
                    <div data-simplebar className="h-100">
                        <AvForm className="needs-validation" >

                            <div className="rightbar-title px-3 py-4">
                                <Link to="#" onClick={hideRightSidebar} className="right-bar-toggle float-right">
                                    <i className="mdi mdi-close noti-icon"></i>
                                </Link>
                                <h5 className="m-0">{t('Bank Account Filter')}</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetFilter}>
                                        Reset Filter
                                    </button>
                                </div>
                                <span className="mb-2 d-block">Country Name</span>
                                <Typeahead
                                    id="country-list"
                                    isLoading={loadingCountry}
                                    labelKey={option => `${option.node.name}`}
                                    multiple
                                    options={countryData ? countryData.countries.edges : []}
                                    placeholder="Search for Country"
                                    defa
                                    selected={country}
                                    renderMenuItemChildren={(option, props) => (
                                        <Fragment>
                                            <span>{option.node.name}</span>
                                        </Fragment>
                                    )}
                                    onChange={(val) => {
                                        if (val.length > 0) {

                                            setCountry(val)
                                        } else {
                                            setCountry([])
                                        }
                                    }}
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Ability</span>
                                <div className="custom-control custom-checkbox">
                                    <Input checked={canDeposit} type="checkbox" className="custom-control-input checkbox" id="filter-deposit" onChange={(e) => setCanDeposit(!canDeposit)} />
                                    <Label className="custom-control-label" htmlFor="filter-deposit">{t('Deposit')}</Label>
                                </div>
                                <div className="custom-control custom-checkbox">
                                    <Input checked={canWithdraw} type="checkbox" className="custom-control-input checkbox" id="filter-withdraw" onChange={(e) => setCanWithdraw(!canWithdraw)} />
                                    <Label className="custom-control-label" htmlFor="filter-withdraw">{t('Withdraw')}</Label>
                                </div>

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Account Status</span>
                                {
                                    accountStatusData &&
                                    accountStatusData.__type.enumValues.map(({ name }, index) => {
                                        return (
                                            <div key={index} className="custom-control custom-checkbox">
                                                <Input type="checkbox" className="custom-control-input checkbox" id={name} onChange={() => {
                                                    let data = [];
                                                    if (_.includes(accountStatus, name)) {
                                                        data = _.filter(accountStatus, status => {
                                                            return status !== name
                                                        })
                                                    } else {
                                                        data = [...accountStatus, name]
                                                    }

                                                    setAccountStatus(data)
                                                }} />
                                                <Label className="custom-control-label" htmlFor={name}>{name}</Label>
                                            </div>
                                        )
                                    })
                                }

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Created From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={createStart}
                                    onChange={date => {
                                        if (date > createEnd) {
                                            setCreateEnd(date)
                                        }
                                        setCreateStart(date)
                                    }}
                                    selectsStart
                                    startDate={createStart}
                                    endDate={createEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={createEnd}
                                    onChange={date => {
                                        setCreateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={createStart}
                                    endDate={createEnd}
                                    minDate={createStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Updated From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={updateStart}
                                    onChange={date => {
                                        if (date > updateEnd) {
                                            setUpdateEnd(date)
                                        }
                                        setUpdateStart(date)
                                    }}
                                    selectsStart
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={updateEnd}
                                    onChange={date => {
                                        setUpdateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    minDate={updateStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />

                                <hr className="mt-1" />
                            </div>

                            <div className="rightbar-title px-3 py-4  sorting-title">
                                <h5 className="m-0"> Bank Account Sorting</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetSort}>
                                        Reset Sort
                                    </button>
                                </div>
                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Name</span>
                                    <input
                                        className="name-sort"
                                        type="radio"
                                        id="nameAsc"
                                        name="nameSort"
                                        value="ASC"
                                        checked={sortBankAccountName === 'ASC'}
                                        onClick={() => {
                                            if (sortBankAccountName === 'ASC') {
                                                setSortBankAccountName(null)
                                            } else {
                                                setSortBankAccountName('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="nameAsc">ASC</label>
                                    {"   "}
                                    <input
                                        className="name-sort"
                                        type="radio"
                                        id="nameDesc"
                                        name="nameSort"
                                        value="DESC"
                                        checked={sortBankAccountName === 'DESC'}
                                        onClick={() => {
                                            if (sortBankAccountName === 'DESC') {
                                                setSortBankAccountName(null)
                                            } else {
                                                setSortBankAccountName('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="nameDesc">DESC</label>
                                </div>


                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Created From</span>
                                    <input
                                        type="radio"
                                        id="createAsc"
                                        name="createSort"
                                        value="ASC"
                                        checked={sortCreate === 'ASC'}
                                        onClick={() => {
                                            if (sortCreate === 'ASC') {
                                                setSortCreate(null)
                                            } else {
                                                setSortCreate('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="createAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="createDesc"
                                        name="createSort"
                                        value="DESC"
                                        checked={sortCreate === 'DESC'}
                                        onClick={() => {
                                            if (sortCreate === 'DESC') {
                                                setSortCreate(null)
                                            } else {
                                                setSortCreate('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="createDesc">DESC</label>
                                </div>

                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Updated From</span>
                                    <input
                                        type="radio"
                                        id="updateAsc"
                                        name="updateSort"
                                        value="ASC"
                                        checked={sortUpdate === 'ASC'}
                                        onClick={() => {
                                            if (sortUpdate === 'ASC') {
                                                setSortUpdate(null)
                                            } else {
                                                setSortUpdate('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="updateAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="updateDesc"
                                        name="updateSort"
                                        value="DESC"
                                        checked={sortUpdate === 'DESC'}
                                        onClick={() => {
                                            if (sortUpdate === 'DESC') {
                                                setSortUpdate(null)
                                            } else {
                                                setSortUpdate('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="updateDesc">DESC</label>
                                </div>
                            </div>


                        </AvForm>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay" onClick={hideRightSidebar}></div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeFilter,
    changeSort
})(withTranslation()(BankAccountFilter));

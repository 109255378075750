


import React, { Fragment, useState } from 'react';
import ToastHelper from '../../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery, useLazyQuery } from 'react-apollo';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import _ from 'lodash';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { GET_PLATFORMS } from '../../../graphql/query/GET_PLATFORMS';
import { GET_KIOSK } from '../../../graphql/query/GET_KIOSK';
import { GET_GAME_ACCOUNTS } from '../../../graphql/query/GET_GAME_ACCOUNTS';
import { GET_ENUM } from '../../../graphql/query';
import { CREATE_ONE_GAME_ACCOUNT_BY_KIOSK } from '../../../graphql/mutation/CREATE_ONE_GAME_ACCOUNT_BY_KIOSK';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import {
    showOverlayLoading
} from "../../../store/actions";

function PlayerNewGameAccount(props) {

    const { t, player, modal, toggleModal, refetchGameAccount } = props;

    const [searchPlatform, setSearchPlatform] = useState('')
    const [searchKiosk, setSearchKiosk] = useState('')
    const [platformId, setPlatformId] = useState('')
    const [byAuto, setByAuto] = useState('Auto')
    const [createOneGameAccountByKiosk, { loading }] = useMutation(CREATE_ONE_GAME_ACCOUNT_BY_KIOSK);

    const { data: dataPlatform, loading: loadingPlatform } = useQuery(GET_PLATFORMS, {
        variables: {
            paging: { first: 50 },
            filter: searchPlatform ? {
                name: { iLike: `%${searchPlatform}%` },
                isActive: { is: true }
            } : { isActive: { is: true } },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    const { data: dataKiosk, loading: loadingKiosk } = useQuery(GET_KIOSK, {
        variables: {
            paging: { first: 50 },
            filter: {
                platformId: !_.isEmpty(platformId) ? { eq: platformId } : { iLike: "" },
                username: { iLike: `%${searchKiosk}%` },
                isActive: { is: true }
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    const { data: gameAccountMethod } = useQuery(GET_ENUM, {
        variables: {
            name: "CreateGameAccountMethod"
        }
    });

    const [refetchGameAccounts] = useLazyQuery(GET_GAME_ACCOUNTS, {
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={() => {
            if (!loading) {
                toggleModal()
            }
        }}>
            <div className="modal-content new-game-page">
                <ModalHeader toggle={() => {
                    if (!loading) {
                        toggleModal()
                    }
                }}>
                    Add New Game Account
                </ModalHeader>
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={async (event, values) => {
                                let kiosk = _.find(dataKiosk.kioskAccounts.edges, kiosk => {
                                    return kiosk.node.username === values.kiosk
                                })

                                if (!kiosk) {
                                    return
                                }

                                await createOneGameAccountByKiosk({
                                    variables: {
                                        input: {
                                            gameAccount: {
                                                playerId: player.id,
                                                kioskAccountId: kiosk.node.id,
                                                playerUsername: values.login,
                                                playerPassword: values.password,
                                                method: values.method
                                            }
                                        }
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).then(val => {
                                    if (val) {
                                        refetchGameAccounts({
                                            variables: {
                                                sorting: {
                                                    field: "createdAt",
                                                    direction: "DESC"
                                                },
                                                filter: {
                                                    playerId: { eq: player.id }
                                                },
                                            },
                                        })

                                        if (refetchGameAccount) {
                                            refetchGameAccount()
                                        }
                                    }
                                })
                            }}
                        >
                            <Row>
                                <Col lg="6">
                                    <AvField
                                        disabled={loading}
                                        label="Platform"
                                        name="platform"
                                        type="text"
                                        placeholder="Enter Platform"
                                        errorMessage="Enter Platform"
                                        isLoading={loadingPlatform}
                                        onSearch={(query => setSearchPlatform(query))}
                                        onInputChange={(val) => {
                                            if (val === '') {
                                                setSearchPlatform('')
                                            }
                                        }}
                                        onChange={(val) => {
                                            setPlatformId(val.length > 0 ? val[0].node.id : '')
                                        }}
                                        options={dataPlatform ? dataPlatform.platforms.edges : []}
                                        labelKey={option => `${option.node.displayName}`}
                                        renderMenuItemChildren={(option, props) => (
                                            <Fragment>
                                                <span>{option.node.displayName}</span>
                                            </Fragment>
                                        )}
                                        minLength={0}
                                        tag={[AsyncTypeahead]}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                                {
                                    platformId &&
                                    <Col lg="6">
                                        <AvField
                                            disabled={loading}
                                            label="Kiosk"
                                            name="kiosk"
                                            type="text"
                                            placeholder="Enter Kiosk"
                                            errorMessage="Enter Kiosk"
                                            isLoading={loadingKiosk}
                                            onSearch={(query => setSearchKiosk(query))}
                                            onInputChange={(val) => {
                                                if (val === '') {
                                                    setSearchKiosk('')
                                                }
                                            }}
                                            options={dataKiosk ? dataKiosk.kioskAccounts.edges : []}
                                            labelKey={option => `${option.node.username}`}
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <span>{option.node.username}</span>
                                                </Fragment>
                                            )}
                                            minLength={0}
                                            tag={[AsyncTypeahead]}
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                }
                                {
                                    gameAccountMethod &&
                                    <Col lg="6">
                                        <AvField
                                            disabled={loading}
                                            label={`${t('Method')}`}
                                            type="select"
                                            name="method"
                                            className="custom-select"
                                            id="status-select"
                                            defaultValue={gameAccountMethod && gameAccountMethod.__type.enumValues[0].name}
                                            onChange={(e) => {
                                                setByAuto(e.target.value)
                                            }}
                                        >
                                            {
                                                gameAccountMethod &&
                                                gameAccountMethod.__type.enumValues.map(({ name }, index) => {
                                                    return (
                                                        <option key={index} value={name}>{name}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                }

                                {
                                    byAuto === 'Manual' &&
                                    <>
                                        <Col lg="6">
                                            <AvField
                                                disabled={loading}
                                                label="Game Account Login"
                                                name="login"
                                                type="text"
                                                placeholder="Enter Game Account Login"
                                                errorMessage="Enter Game Account Login"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </Col>
                                        <Col lg="6">
                                            <AvField
                                                disabled={loading}
                                                label="Password"
                                                name="password"
                                                type="text"
                                                placeholder="Enter Password"
                                                errorMessage="Enter Password"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </Col>
                                    </>
                                }
                            </Row>
                            <div className="action-container">
                                <Button color="primary" type="submit" className="align-center">
                                    {
                                        (loading) &&
                                        <>
                                            <i className="fas fa-spinner fa-spin font-size-14 align-middle"></i>
                                            <div className="pl-1"></div>
                                        </>
                                    }
                                    {
                                        <div>Submit</div>
                                    }
                                </Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(withTranslation()(PlayerNewGameAccount));
import gql from 'graphql-tag';


export const SYNC_GAME_ACCOUNT_BALANCE = gql`
    mutation(
        $input: String!
    ) {
        gameAccountSyncBalance(id:$input){
            id
            balance
            lastSyncAt
        }
        
    }
`;
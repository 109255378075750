import _ from 'lodash';

const WalletHelper = {
    getMainWallet(wallets) {
        return _.find(wallets, (wallet) => {
            return wallet.node.name === "Main"
        })
    },
    getRewardWallet(wallets) {
        console.log(
            _.find(wallets, (wallet) => {
                return wallet.node.name === "Reward"
            })
        )
        return _.find(wallets, (wallet) => {
            return wallet.node.name === "Reward"
        })
    },
    getAgentWallet(wallets) {
        console.log(
            _.find(wallets, (wallet) => {
                return wallet.node.name === "Agent"
            })
        )
        return _.find(wallets, (wallet) => {
            return wallet.node.name === "Agent"
        })
    },
    getBalance(wallet) {
        return parseFloat(wallet.balance).toFixed(2).toString()
    }
}

export default WalletHelper;

import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const CLEAR_PHONE_NO = gql`
    mutation(
        $userId: String!
    ) {
        clearPhoneNo (
            userId: $userId
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
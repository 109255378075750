


import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { Fragment } from 'react';
import { useMutation } from 'react-apollo';
import {
    Button, Col, Modal, ModalBody, ModalHeader, Row
} from "reactstrap";
import { CREATE_ONE_TELEGRAM_BUTTON } from "../../../graphql/mutation/CREATE_ONE_TELEGRAM_BUTTON";
import { UPDATE_ONE_TELEGRAM_BUTTON } from "../../../graphql/mutation/UPDATE_ONE_TELEGRAM_BUTTON";
import ToastHelper from '../../../helpers/ToastHelper';


export default function TelegramButtonForm(props) {

    const { toggleModal, telegramButton, mode } = props;
    const [createOneTelegramButton] = useMutation(CREATE_ONE_TELEGRAM_BUTTON)
    const [updateOneTelegramButton] = useMutation(UPDATE_ONE_TELEGRAM_BUTTON);

    return <Fragment>
        <Modal isOpen={mode !== null} role="dialog" size="md" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-game-page">
                {
                    mode &&
                    <ModalHeader toggle={toggleModal}>
                        {mode.charAt(0).toUpperCase() + mode.slice(1)} Telegram Button
                    </ModalHeader>
                }
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                if (mode === 'new') {
                                    createOneTelegramButton({
                                        variables: {
                                            input: {
                                                telegramButton: {
                                                    action: values.action,
                                                }
                                            }
                                        }
                                    }).then((val) => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Create Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    }).catch((e) => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    })
                                } else if (mode === 'edit') {
                                    updateOneTelegramButton({
                                        variables: {
                                            input: {
                                                "update": {
                                                    action: values.action,
                                                },
                                                id: telegramButton.node.id
                                            }
                                        }
                                    }).then((val) => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    }).catch((e) => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    })
                                }
                            }}
                        >
                            <Row>
                                <Col lg="12">
                                    <AvField
                                        label="Telegram Button Action"
                                        name="action"
                                        type="text"
                                        value={telegramButton ? telegramButton.node.action : ''}
                                        placeholder="Enter Telegram Button Action"
                                        errorMessage="Enter Telegram Button Action"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
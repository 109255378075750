import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { BannerFragment } from '../fragments/banner.fragment';

export const GET_BANNERS = gql`
    query (
        $paging: CursorPaging
        $filter: BannerFilter
        $sorting: [BannerSort!]
    ) {
        banners (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...BannerInfo
                }
            }
        }
    }
    ${BannerFragment}
    ${PageFragment}
`;
import gql from 'graphql-tag';
import { CategoryFragment } from '../fragments/category.fragment';

export const REMOVE_PLATFORM_CATEGORY = gql`
    mutation(
        $input: RelationsInput!
    ) {
        removePlatformsFromCategory (input: $input) {
            ...CategoryInfo
        }
    }
    ${CategoryFragment}
`;
import gql from 'graphql-tag';
import { PaymentTypeFragment } from '../fragments/payment.type.fragment';

export const UPDATE_ONE_PAYMENT_TYPE = gql`
    mutation(
        $input: UpdateOnePaymentTypeInput!
    ) {
        updateOnePaymentType (input: $input) {
            ...PaymentType
        }
    }
    ${PaymentTypeFragment}
`;
import React from 'react';
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from "reactstrap";

//Import Breadcrumb
import { useMutation, useQuery } from '@apollo/react-hooks';
import classnames from "classnames";
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import 'react-medium-image-zoom/dist/styles.css';
import { connect } from "react-redux";
import { Admin, Developer, Manager, Supervisor } from '../../assets/scss/role.constant';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SettingEditor from '../../components/CommonForBoth/SettingEditor';
import { FIND_OR_CREATE_ONE_SETTING, UPDATE_ONE_SETTING } from '../../graphql/mutation';
import { DELETE_ONE_ATTENDANCE_STEP } from '../../graphql/mutation/DELETE_ONE_ATTENDANCE_STEP';
import { GET_ATTENDANCE_STEPS } from '../../graphql/query/GET_ATTENDANCE_STEPS';
import RoleHelper from '../../helpers/RoleHelper';
import { toggleRightSidebar } from "../../store/actions";
import EditTier from './edit-tier';
import NewTier from './new-tier';
import TierItem from './tier-item';

function AttendanceTiers(props) {

    const { t } = props;

    const [addTier, setAddTier] = React.useState(false)
    const [editTier, setEditTier] = React.useState(false)
    const [tierItem, setTierItem] = React.useState(null)
    const [orderBy] = React.useState("step")
    const [orderDirection] = React.useState("ASC")
    const [deleteTier, setDeleteTier] = React.useState(null)
    const [showDelete, setShowDelete] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState('Daily')
    const [attendanceType, setAttendanceType] = React.useState("Daily")

    const onEdit = (item) => {
        setTierItem(item)
        setEditTier(true)
    }

    const [updateOneSetting] = useMutation(UPDATE_ONE_SETTING)

    const [getEnableWeeklyAttendanceModule, { data: enableWeeklyAttendanceModule }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'enableWeeklyAttendanceModule',
                settingType: "General",
                value: 'false',
                defaultValue: 'false',
                fieldType: "Boolean",
                description: 'Enable/Disable Weekly Attendance Module',
                developerOnly: false,
            }
        }
    })

    const [getWeeklyAttendancePointExpireNotification, { data: weeklyAttendancePointExpireNotification }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'weeklyAttendancePointExpireNotification',
                settingType: "Bot",
                value: '2,4',
                defaultValue: '2,4',
                fieldType: "TextField",
                description: 'Weekly Attendance Point Expired Reminder Notification(Hours)',
                developerOnly: false,
            }
        }
    })

    const [getWeeklyAttendanceClaimableWhenMainWalletBalanceLessThan, { data: weeklyAttendanceClaimableWhenMainWalletBalanceLessThan }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'weeklyAttendanceClaimableWhenMainWalletBalanceLessThan',
                settingType: "General",
                value: '5',
                defaultValue: '5',
                fieldType: "TextField",
                description: 'Weekly Attendance Claimable When Main Wallet Balance Less Than',
                developerOnly: false,
            }
        }
    })

    const { data: tiersData, refetch } = useQuery(GET_ATTENDANCE_STEPS, {
        variables: {
            filter: {
                attendanceType: { eq: attendanceType }
            },
            sorting: [{ field: orderBy, direction: orderDirection }]

        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const [deleteOneStep] = useMutation(DELETE_ONE_ATTENDANCE_STEP)

    return (
        <React.Fragment>
            <div className="page-content bonus-page">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Attendance Step" breadcrumbItem="Attendance Step" />
                    <Card>
                        <CardBody>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "Daily"
                                        })}
                                        onClick={() => {
                                            setActiveTab("Daily");
                                        }}
                                    >{t('Daily')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "Weekly"
                                        })}
                                        onClick={() => {
                                            setActiveTab("Weekly");
                                        }}
                                    >{t('Weekly')}</NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="Daily" className="pt-3">
                                    <Row>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <SettingEditor description="Enable Daily Attendance Module?(Global)" settingKey="enableDailyAttendanceModule" settingType="General" fieldType="Boolean" inputValue={'false'} defaultValue={'false'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>

                                                <CardBody>
                                                    <SettingEditor description="Daily Attendance Point Expired Reminder Notification(Hours)" settingKey="dailyAttendancePointExpireNotification" settingType="Bot" fieldType="TextField" inputValue={'2,4'} defaultValue={'2,4'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <SettingEditor description="Daily Attendance Claimable When Main Wallet Balance Less Than" settingKey="dailyAttendanceClaimableWhenMainWalletBalanceLessThan" settingType="General" fieldType="TextField" inputValue={'5'} defaultValue={'5'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <SettingEditor description="Minimum Deposit Amount (Daily Attendance)" settingKey="minimumDepositAmountDailyAttendance" settingType="General" fieldType="TextField" inputValue={'50'} defaultValue={'50'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Card>
                                            <CardBody>
                                                <SettingEditor description="Bot Attendence Daily TnC" settingKey="botAttendenceDailyTnc" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify([{ "language": "EN", "content": "" }, { "language": "MS", "content": "" }, { "language": "ZH", "content": "" }])} defaultValue={JSON.stringify([{ "language": "EN", "content": "" }, { "language": "MS", "content": "" }, { "language": "ZH", "content": "" }])} roles={[Developer, Admin, Manager, Supervisor]} />
                                            </CardBody>
                                        </Card>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="Weekly" className="pt-3">
                                    <Row>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <SettingEditor description="Enable Weekly Attendance Module?(Global)" settingKey="enableWeeklyAttendanceModule" settingType="General" fieldType="Boolean" inputValue={'false'} defaultValue={'false'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <SettingEditor description="Weekly Attendance Point Expired Reminder Notification(Hours)" settingKey="weeklyAttendancePointExpireNotification" settingType="Bot" fieldType="TextField" inputValue={'2,4'} defaultValue={'2,4'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <SettingEditor description="Weekly Attendance Claimable When Main Wallet Balance Less Than" settingKey="weeklyAttendanceClaimableWhenMainWalletBalanceLessThan" settingType="General" fieldType="TextField" inputValue={'5'} defaultValue={'5'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={6} lg={6} xl={4} sm={6}>
                                            <Card>
                                                <CardBody>
                                                    <SettingEditor description="Minimum Deposit Amount (Weekly Attendance)" settingKey="minimumDepositAmountWeeklyAttendance" settingType="General" fieldType="TextField" inputValue={'50'} defaultValue={'50'} roles={[Developer, Admin, Manager, Supervisor]} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Card>
                                            <CardBody>
                                                <SettingEditor description="Bot Attendence Weekly TnC" settingKey="botAttendenceWeeklyTnc" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify([{ "language": "EN", "content": "" }, { "language": "MS", "content": "" }, { "language": "ZH", "content": "" }])} defaultValue={JSON.stringify([{ "language": "EN", "content": "" }, { "language": "MS", "content": "" }, { "language": "ZH", "content": "" }])} roles={[Developer, Admin, Manager, Supervisor]} />
                                            </CardBody>
                                        </Card>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </CardBody>
                    </Card>
                    {
                        <Card className="w-100">
                            <CardBody>
                                <div className="search-bar-container mb-3">
                                    <div className="button-add-bonus-container font-size-16">
                                        {`${attendanceType.toUpperCase()} STEP LIST`}
                                    </div>
                                    <div className="button-add-bonus-container">
                                        <button disabled={!RoleHelper.checkRoles([Developer, Admin, Manager, Supervisor])} type="button" className="waves-effect btn btn-outline-secondary button-add-bonus ml-2" onClick={() => { setAddTier(true) }}>
                                            <i className="bx bx-plus font-size-15 align-middle mr-2"></i>{`New ${attendanceType} Step`}
                                        </button>
                                    </div>
                                </div>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: attendanceType === 'Daily'
                                            })}
                                            onClick={() => {
                                                setAttendanceType('Daily');
                                            }}
                                        >{t('Daily')}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: attendanceType === 'Weekly'
                                            })}
                                            onClick={() => {
                                                setAttendanceType('Weekly');
                                            }}
                                        >{t('Weekly')}</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent className="mt-4">
                                    <TabPane>
                                        <div className="table-responsive">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: '30%' }}>Step</th>
                                                        <th style={{ width: '30%' }}>Reward Point</th>
                                                        <th style={{ width: '30%' }}>Active?</th>
                                                        <th style={{ width: '10%' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        tiersData &&
                                                        tiersData.attendanceSteps.edges.length > 0 &&
                                                        tiersData.attendanceSteps.edges.map((attendanceStep, key) =>
                                                            <tr key={"_tier" + key}>
                                                                <TierItem
                                                                    onEdit={onEdit}
                                                                    tier={attendanceStep}
                                                                    onDelete={(tier) => {
                                                                        setDeleteTier(tier)
                                                                        setShowDelete(true)
                                                                    }}
                                                                />
                                                            </tr >
                                                        )
                                                    }
                                                    {
                                                        tiersData &&
                                                        tiersData.attendanceSteps.edges.length === 0 &&
                                                        <tr className="text-center pb-2">
                                                            <td colSpan={12}>
                                                                <div className="no-user pt-0">No Attendance Step Found</div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>}
                    {
                        addTier &&
                        <NewTier
                            modal={addTier}
                            attendanceType={attendanceType}
                            toggleModal={() => {
                                refetch()
                                setAddTier(!addTier)
                            }}
                        />
                    }
                    {
                        editTier &&
                        <EditTier
                            modal={editTier}
                            toggleModal={() => {
                                setEditTier(!editTier)
                            }}
                            tier={tierItem}
                        />
                    }
                    {
                        showDelete &&
                        <SweetAlert
                            error
                            showCancel
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={'Alert'}
                            onConfirm={() => {
                                deleteOneStep({
                                    variables: {
                                        id: deleteTier.node.id
                                    }
                                }).then(() => {
                                    refetch()
                                    setDeleteTier(null)
                                    setShowDelete(false)
                                })
                            }}
                            onCancel={() => {
                                setDeleteTier(null)
                                setShowDelete(false)
                            }}
                        >
                            {`Are you sure want to delete this step?`}
                        </SweetAlert>
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(AttendanceTiers));

// export default ContactsList;
import gql from 'graphql-tag';
import { GameAccountFragment } from '../fragments/game-account.fragment';

export const SYNC_GAME_ACCOUNT = gql`
    mutation(
        $id: String!
    ) {
        gameAccountSyncBalance(id: $id){
            ...GameAccountInfo
        }
    }
    ${GameAccountFragment}
`;
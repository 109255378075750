import gql from 'graphql-tag';

export const GET_USERS_ID = gql`
    query (
        $paging: CursorPaging
        $filter: UserFilter
        $sorting: [UserSort!]
    ) {
        users (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            edges {
                node {
                    id
                }
            }
        }
    }
`;
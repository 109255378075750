import gql from 'graphql-tag';

export const GET_USER_TOTAL_COUNT = gql`
    query {
        users (
            filter: {
                role:{ eq: Player }
            }
        ) {
            totalCount
        }
    }
`;
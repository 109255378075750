import axios from 'axios';

const CloudinaryHelper = {
    async upload(fileuri: any, url: any, onUploadProgress?: any) {

        var bodyFormData = new FormData();
        bodyFormData.append('file', fileuri, fileuri.name);
        let data = await axios.post(url, bodyFormData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: onUploadProgress
        });
        return data
    }
}

export default CloudinaryHelper;
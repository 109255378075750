import gql from 'graphql-tag';
import { CategoryFragment } from '../fragments/category.fragment';
import { PlatformFragment } from '../fragments/platform.fragment';

export const GET_CATEGORY_BY_ID = gql`
    query (
        $id: ID!
    ) {
        category (
            id: $id
        ) {
            ...CategoryInfo
            platforms {
                edges {
                    node {
                        ...PlatformInfo
                    }
                }
            }
        }
    }
    ${CategoryFragment}
    ${PlatformFragment}
`;
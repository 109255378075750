import React, { useState } from 'react';
import { Row, Col, Button, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";
import NumberFormat from 'react-number-format';

//Import Breadcrumb
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import { GET_GAME_ACCOUNTS } from '../../../graphql/query/GET_GAME_ACCOUNTS';
import { CREATE_ONE_CREDITIN } from '../../../graphql/mutation/CREATE_ONE_CREDITIN';
import { GET_USER_WALLETS } from '../../../graphql/query/GET_USER_WALLETS';
import { PROCESS_ONE_CREDITIN } from '../../../graphql/mutation';
import ToastHelper from '../../../helpers/ToastHelper';

function BankerTransfer(props) {

    const { t, type, player, isActive } = props;
    const [wallet, setWallet] = useState(null)

    const [creditIn, { loading: loadingCreditIn }] = useMutation(CREATE_ONE_CREDITIN)
    const [processCreditIn, { loading: loadingProcessCreditIn }] = useMutation(PROCESS_ONE_CREDITIN)

    const { data: gameAccounts } = useQuery(GET_GAME_ACCOUNTS, {
        variables: {
            sorting: {
                field: "createdAt",
                direction: "DESC"
            },
            filter: {
                playerId: { eq: player.id }
            },
        },
        notifyOnNetworkStatusChange: true
    })

    const [getWallet, { data: walletData }] = useLazyQuery(GET_USER_WALLETS, {
        variables: {
            filter: {
                user: { id: { eq: player.id } },
                walletType: { eq: "Credit" },
                name: { eq: "Main" }
            },
        },
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {
        if (walletData) {
            if (walletData.wallets.edges.length > 0) {
                setWallet(walletData.wallets.edges[0].node)
            }
        }
    }, [walletData])

    React.useEffect(() => {
        if (isActive) {
            getWallet()
        }
    }, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="banker-withdraw-container">
                <div className="banker-withdraw-wrapper">
                    <AvForm className="banker-withdraw-content-info" onValidSubmit={async (event, values) => {
                        if (type === 'credit-in') {
                            let game = _.find(gameAccounts.gameAccounts.edges, account => {
                                return account.node.id === values.gameAccount
                            })
                            let result = await creditIn({
                                variables: {
                                    input: {
                                        creditIn: {
                                            quantity: parseFloat(values.transferAmount.toString().replaceAll(',', '')),
                                            platformId: game.node.kioskAccount.platformId,
                                            playerId: player.id,
                                            kioskAccountId: game.node.kioskAccountId,
                                            gameAccountId: values.gameAccount,
                                            fromWalletId: values.wallet
                                        }
                                    }
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            })
                            if (result) {
                                await processCreditIn({
                                    variables: {
                                        id: result.data.createOneCreditIn.id
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                })
                            }
                        } else if (type === 'credit-out') {

                        }
                    }}>
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <AvField
                                    value={player.name}
                                    label={t('Player')}
                                    disabled
                                    type="text"
                                    name="playerName"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <AvField
                                    name="transferAmount"
                                    label={`${t('Transfer Amount')}`}
                                    placeholder={t('Enter Transfer Amount')}
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    tag={[Input, NumberFormat]}
                                    validate={{
                                        required: { value: true, errorMessage: "Enter Transfer Amount" },
                                        max: { value: wallet ? parseFloat(wallet.balance) : null, errorMessage: "Exceed Available Balance" },
                                        step: { value: 5, errorMessage: `Product of ${5}` }
                                    }}
                                    helpMessage={wallet ? `Available Balance: ${parseFloat(wallet.balance).toFixed(2)}` : ''}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <AvField
                                    label={t('From Wallet')}
                                    type="select"
                                    name="wallet"
                                    className="custom-select"
                                    onChange={(e) => {
                                        let selectedWallet = _.find(walletData.wallets.edges, wal => {
                                            return wal.node.id === e.target.value
                                        })
                                        setWallet(selectedWallet.node)
                                    }}
                                    value={walletData &&
                                        walletData.wallets.edges.length > 0 &&
                                        walletData.wallets.edges[0].node.id
                                    }
                                >
                                    {
                                        walletData &&
                                        walletData.wallets.edges.length > 0 &&
                                        walletData.wallets.edges.map((wallet, index) => {
                                            return (
                                                <option key={index} value={wallet.node.id}>{`${wallet.node.name}(${wallet.node.walletType}): $${wallet.node.balance}`}</option>)
                                        })
                                    }
                                </AvField>
                            </Col>
                            {
                                gameAccounts &&
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        label={t('To Game Account')}
                                        type="select"
                                        name="gameAccount"
                                        className="custom-select"
                                        defaultValue={
                                            gameAccounts.gameAccounts.edges.length > 0 &&
                                            gameAccounts.gameAccounts.edges[0].node.id
                                        }
                                    >
                                        {
                                            gameAccounts &&
                                            gameAccounts.gameAccounts.edges.length > 0 &&
                                            gameAccounts.gameAccounts.edges.map((gameAccount, index) => {
                                                return (
                                                    <option key={index} value={gameAccount.node.id}>{gameAccount.node.username}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                </Col>
                            }
                        </Row>
                        <div className="submit-container">
                            <Button color="primary" type="submit">
                                {
                                    (loadingCreditIn || loadingProcessCreditIn) &&
                                    <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                }
                                {
                                    (!loadingCreditIn && !loadingProcessCreditIn) &&
                                    <div>
                                        Submit
                                    </div>
                                }
                            </Button>
                        </div>
                    </AvForm>
                </div>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(BankerTransfer));
import gql from 'graphql-tag';

export const MarketingFragment = gql`
    fragment MarketingCampaign on MarketingCampaign {
        id
        name
        mediaLink
        message
        startAt
        endAt
        marketingCampaignMedium
        targetingLanguage
        isActive
        isArchived
        createdAt
        updatedAt
        createdBy
        updatedBy
        autoMode
    }
`;
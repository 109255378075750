
import React, { Component } from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";

class GameAccountTableItem extends Component {

    state = {
        drp_link: false
    }

    render() {
        const { gameAccount, onEdit } = this.props;
        return <React.Fragment>
            <tr>
                <td><div className="cursor-pointer " onClick={onEdit} >{gameAccount.player.name}</div></td>
                <td><div className="cursor-pointer " onClick={onEdit} >{gameAccount.kioskAccount.platform.displayName}</div></td>
                <td><div className="cursor-pointer " onClick={onEdit} >{gameAccount.kioskAccount.username}</div></td>
                <td><div className="cursor-pointer " onClick={onEdit} >{gameAccount.username}</div></td>
                <td><div className="cursor-pointer " onClick={onEdit} >{gameAccount.password}</div></td>
                <td><div className="cursor-pointer " onClick={onEdit} >{gameAccount.gameAccountStatus}</div></td>
                <td><div className="cursor-pointer " onClick={onEdit} >{gameAccount.balance ? gameAccount.balance : "N/A"}</div></td>
                <td><ButtonDropdown
                    isOpen={this.state.drp_link}
                    toggle={() =>
                        this.setState({ drp_link: !this.state.drp_link })
                    }
                >
                    <DropdownToggle caret color="secondary">
                        Actions <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={onEdit}> Edit Game Account</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown></td>
            </tr>
        </React.Fragment >
    }
}

export default GameAccountTableItem;
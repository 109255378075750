import gql from "graphql-tag";
import { AttendanceFragment } from "../fragments/attendance.fragment";

export const CREATE_ONE_ATTENDANCE_TIER = gql`
  mutation ($input: CreateOneAttendanceStepInput!) {
    createOneAttendanceStep(input: $input) {
      ...AttendanceInfo
    }
  }
  ${AttendanceFragment}
`;

import gql from 'graphql-tag';
import { CurrencyFragment } from '../fragments/currency.fragment';

export const CREATE_ONE_CURRENCY = gql`
    mutation(
        $input: CreateOneCurrencyInput!
    ) {
        createOneCurrency (input: $input) {
            ...CurrencyInfo
        }
    }
    ${CurrencyFragment}
`;

import React, { Component } from 'react';
import { Col, Card, CardBody, Row } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";

import moment from 'moment';

class GameAccountItem extends Component {

    state = {
        drp_link: false
    }

    render() {
        const { gameAccount, onEdit } = this.props;
        return <React.Fragment>
            <Col xl="3" sm="6" lg="4">
                <Card>
                    <div style={{
                        backgroundColor: '#ADE498',
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                    }}>
                    </div>
                    <CardBody className="">


                        <Row>
                            <Col className="text-align pb-0 cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">Player</div>
                                    <div className="font-size-14">{gameAccount.node.player.displayName}</div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-align pb-0 cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">Platform</div>
                                    <div className="font-size-14">{gameAccount.node.kioskAccount.platform.displayName}</div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="text-align pb-0 cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">LOGIN</div>
                                    <div className="font-size-14">{gameAccount.node.username}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-align pb-0">
                                <div>
                                    <div className="header">GAME ACCOUNT STATUS</div>
                                    <div className="font-size-14">{gameAccount.node.gameAccountStatus}</div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-align pb-0 cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">PASSWORD</div>
                                    <div className="font-size-14">{gameAccount.node.password}</div>
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col className="text-align pb-3">
                                <div>
                                    <div className="header">Wallet Balance</div>
                                    <div className="font-size-14">
                                        {gameAccount.node.balance ? gameAccount.node.balance : "-"}
                                    </div>
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <Col className="text-align pb-3">
                                <div>
                                    <div className="header">Last Sync Date</div>
                                    <div className="font-size-14">{gameAccount.node.lastSyncAt ? moment(gameAccount.node.lastSyncAt).calendar() : "-"}</div>
                                </div>
                            </Col>

                        </Row>

                        <Row>
                            <Col className="dropdown-actions">
                                <ButtonDropdown
                                    isOpen={this.state.drp_link}
                                    toggle={() =>
                                        this.setState({ drp_link: !this.state.drp_link })
                                    }
                                >
                                    <DropdownToggle caret color="secondary">
                                        Actions <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={onEdit}>Edit Game Account</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    }
}

export default GameAccountItem;
import gql from 'graphql-tag';
import { GameAccountFragment } from '../fragments/game-account.fragment';

export const CREATED_GAME_ACCOUNT = gql`
    subscription {
        createdGameAccount {
            ...GameAccountInfo
        }
    }
    ${GameAccountFragment}
`;
import React, { useState } from 'react';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";
import moment from 'moment';
import { PROCESS_ONE_CREDITIN, PROCESS_ONE_CREDITOUT, REJECT_ONE_DEPOSIT, APPROVE_ONE_WITHDRAWAL, REJECT_ONE_WITHDRAWAL } from '../../../graphql/mutation';
import { useMutation } from 'react-apollo';
import ToastHelper from '../../../helpers/ToastHelper';
import { APPROVE_ONE_DEPOSIT } from '../../../graphql/mutation/APPROVE_ONE_DEPOSIT';

function PlayerGameAccountItem(props) {

    const { transaction, onApprove } = props;
    const [drp_link, setDrpLink] = useState(false)

    const [processCreditIn, { loading: loadingProcessCreditIn }] = useMutation(PROCESS_ONE_CREDITIN)
    const [processCreditOut, { loading: loadingProcessCreditOut }] = useMutation(PROCESS_ONE_CREDITOUT)
    const [approveOneDeposit, { loading: loadingDeposit }] = useMutation(APPROVE_ONE_DEPOSIT)
    const [rejectOneDeposit, { loading: loadingRejectDeposit }] = useMutation(REJECT_ONE_DEPOSIT)
    const [approveOneWithdrawal, { loading: loadingApproveWithdrawal }] = useMutation(APPROVE_ONE_WITHDRAWAL)
    const [rejectOneWithdrawal, { loading: loadingRejectWithdrawal }] = useMutation(REJECT_ONE_WITHDRAWAL)

    const ProcessTransaction = async () => {
        if (transaction.node.transactionType === 'CreditIn') {
            await processCreditIn({
                variables: {
                    id: transaction.node.id
                }
            }).catch(e => {
                ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () { }
                })
            })
        } else if (transaction.node.transactionType === 'CreditOut') {
            await processCreditOut({
                variables: {
                    id: transaction.node.id
                }
            }).catch(e => {
                ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () { }
                })
            })
        } else if (transaction.node.transactionType === 'Deposit') {
            await approveOneDeposit({
                variables: {
                    id: transaction.node.id
                }
            }).catch(e => {
                ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () { }
                })
            })
        } else if (transaction.node.transactionType === 'Withdrawal') {
            await approveOneWithdrawal({
                variables: {
                    id: transaction.node.id
                }
            }).catch(e => {
                ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () { }
                })
            })
        }

        if (onApprove) {
            onApprove()
        }
    }

    const RejectTransaction = () => {
        if (transaction.node.transactionType === 'Deposit') {
            rejectOneDeposit({
                variables: {
                    id: transaction.node.id
                }
            }).catch(e => {
                ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () { }
                })
            })
        } else if (transaction.node.transactionType === 'Withdrawal') {
            rejectOneWithdrawal({
                variables: {
                    id: transaction.node.id
                }
            }).catch(e => {
                ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () { }
                })
            })
        }
    }

    const StatusType = () => {
        if (transaction.node.transactionStatus === 'Pending' || transaction.node.kioskStatus === 'Pending') {
            return 'pending'
        } else if (transaction.node.transactionStatus === 'Viewed') {
            return 'viewed'
        } else if (transaction.node.transactionStatus === 'Approved' || transaction.node.kioskStatus === 'Completed') {
            return 'created'
        } else if (transaction.node.transactionStatus === 'Processing' || transaction.node.kioskStatus === 'Processing') {
            return 'processing'
        } else {
            return 'error'
        }
    }

    return (
        <React.Fragment>
            <tr className="font-size-12">
                <td>{moment(transaction.node.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>{transaction.node.transactionType}</td>
                {
                    (transaction.node.transactionType === 'Deposit' || transaction.node.transactionType === 'Withdrawal') &&
                    <>
                        <td>{transaction.node.amount}</td>
                        <td>{transaction.node.bonusAmount}</td>
                    </>
                }
                {
                    (transaction.node.transactionType === 'CreditIn' || transaction.node.transactionType === 'CreditOut') &&
                    <>
                        <td>{transaction.node.quantity}</td>
                        <td>{transaction.node.unitPrice}</td>
                    </>
                }
                <td>{transaction.node.totalAmount}</td>
                <td>{transaction.node.movement ? transaction.node.movement : 'N/A'}</td>
                {
                    (transaction.node.transactionType !== 'FreeCredit' && transaction.node.transactionType !== 'Rebate') &&
                    <td>{transaction.node.kioskRemark ? transaction.node.kioskRemark : 'N/A'}</td>
                }
                {
                    (transaction.node.transactionType === 'FreeCredit' || transaction.node.transactionType === 'Rebate') &&
                    <td>{transaction.node.remark ? transaction.node.remark : 'N/A'}</td>
                }
                <td>
                    <div className="new-player">
                        <div className="pr-2">
                            <div className="status-container">
                                <div className={StatusType()}>{transaction.node.transactionStatus ?? transaction.node.kioskStatus}</div>
                            </div>
                        </div>
                        <>
                            {
                                (transaction.node.kioskStatus === 'Failed' || transaction.node.kioskStatus === 'Processing') &&
                                <button type="button" className="btn btn-primary waves-effect waves-light font-size-11 align-center" onClick={ProcessTransaction}>
                                    {
                                        (loadingProcessCreditIn || loadingProcessCreditOut) &&
                                        <>
                                            <i className="fas fa-spinner fa-spin font-size-14 align-middle"></i>
                                            <div className="pl-1"></div>
                                        </>
                                    }
                                    <div>Retry</div>
                                </button>
                            }
                            {
                                (transaction.node.transactionStatus === 'Pending' || transaction.node.kioskStatus === 'Pending') &&
                                <ButtonDropdown
                                    isOpen={drp_link}
                                    toggle={() =>
                                        setDrpLink(!drp_link)
                                    }
                                >
                                    <DropdownToggle className="font-size-11 align-center" caret color="secondary">
                                        {
                                            (loadingProcessCreditIn || loadingProcessCreditOut || loadingDeposit || loadingRejectDeposit || loadingApproveWithdrawal || loadingRejectWithdrawal) &&
                                            <>
                                                <i className="fas fa-spinner fa-spin font-size-14 align-middle"></i>
                                                <div className="pl-1"></div>
                                            </>
                                        }
                                        Actions <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem className="font-size-11" onClick={ProcessTransaction}>Approve</DropdownItem>
                                        <DropdownItem className="font-size-11" onClick={RejectTransaction}>Reject</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            }
                        </>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
}

export default PlayerGameAccountItem;
import gql from 'graphql-tag';
import { TagFragment } from '../fragments/tag.fragment';
import { PageFragment } from '../fragments/page.fragment';

export const GET_TAGS = gql`
query(
    $filter: TagFilter
    $paging: CursorPaging
    $userPaging: CursorPaging
){
    tags(
        paging: $paging
        filter: $filter
        sorting: { field: name, direction: ASC }
    ){
        pageInfo {
            ...Page
        }
        edges {
            node {
                ...TagInfo
                usersAggregate {
                    count {
                      id
                    }
                  }
                users(paging: $userPaging, sorting:[{field:joinedAt,direction:ASC}]){
                    edges {
                        node {
                            id
                            serialNo
                            name
                            username
                            phoneNo
                        }
                    }
                }
            }
        }
    }
}
${PageFragment}
${TagFragment}
`;
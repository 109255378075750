import React from 'react';
import { useMutation } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';
import EasyEdit from 'react-easy-edit';
import { UPDATE_ONE_REJECT_REASON } from '../../graphql/mutation';

function RejectReasonItem(props) {

    const { rejectReason } = props;

    const [isActive, setIsActive] = React.useState(false)

    const [updateOneRejectReason] = useMutation(UPDATE_ONE_REJECT_REASON);

    React.useEffect(() => {
        if (rejectReason) {
            setIsActive(rejectReason.node.isActive)
        }
    }, [rejectReason])

    return (
        <React.Fragment>
            <tr className="font-size-13 payment-item">
                <td className="relative">
                    <div>{rejectReason.node.code}</div>
                </td>
                <td className="relative">
                    <EasyEdit
                        value={rejectReason.node.name}
                        type="text"
                        onSave={(value) => {
                            updateOneRejectReason({
                                variables: {
                                    input: {
                                        update: {
                                            name: value
                                        },
                                        id: rejectReason.node.id
                                    }
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            }).then(val => {
                                if (val) {
                                    ToastHelper.toggleToast({
                                        message: "Update Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                }
                            })
                        }}
                        saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                        cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                        saveButtonStyle="easy-edit-button-save"
                        cancelButtonStyle="easy-edit-button-cancel"
                    />
                </td>
                <td className="relative">
                    <div className="custom-control custom-switch mb-2" dir="ltr">
                        <input type="checkbox" className="custom-control-input pointer" id="customSwitch1" checked={isActive} />
                        <label className="custom-control-label pointer" htmlFor="customSwitch1" onClick={() => {
                            let newVal = !isActive
                            setIsActive(newVal)
                            updateOneRejectReason({
                                variables: {
                                    input: {
                                        update: {
                                            isActive: newVal
                                        },
                                        id: rejectReason.node.id
                                    }
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            }).then(val => {
                                if (val) {
                                    ToastHelper.toggleToast({
                                        message: "Update Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                }
                            })
                        }}>
                        </label>
                    </div>
                </td>
            </tr>
        </React.Fragment >
    );
}

export default RejectReasonItem;
// @flow
import {
    CHANGE_TAB,
} from "./actionTypes";

const INIT_STATE = {
    platformInfoIndex: 1
};

const PlatformList = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_TAB:
            return {
                platformInfoIndex: action.payload
            };
        default:
            return state;
    }
};

export default PlatformList;

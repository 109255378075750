import { AvField, AvForm } from "availity-reactstrap-validation";
import _ from 'lodash';
import moment from "moment";
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { CREATE_ONE_TELEGRAM_BUTTON_LANGUAGE } from "../../../graphql/mutation/CREATE_ONE_TELEGRAM_BUTTON_LANGUAGE";
import { UPDATE_ONE_TELEGRAM_BUTTON_LANGUAGE } from "../../../graphql/mutation/UPDATE_ONE_TELEGRAM_BUTTON_LANGAUGE";
import { ME } from "../../../graphql/query";
import { GET_ONE_TELEGRAM_BUTTON } from "../../../graphql/query/GET_ONE_TELEGRAM_BUTTON";
import { GET_TELEGRAM_BUTTON_LANGUAGES } from "../../../graphql/query/GET_TELEGRAM_BUTTON_LANGAUGES";
import ToastHelper from "../../../helpers/ToastHelper";
import TelegramButtonForm from "../telegram-button/telegram-button-form";

function TelegramButtonLanguage(props) {

    const { pageId, buttonId } = props.match.params
    const { data: meData } = useQuery(ME)

    const [telegramButtonMode, setTelegramButtonMode] = React.useState(null)

    const [createOneTelegramButtonLanguage] = useMutation(CREATE_ONE_TELEGRAM_BUTTON_LANGUAGE)
    const [updateOneTelegramButtonLanguage] = useMutation(UPDATE_ONE_TELEGRAM_BUTTON_LANGUAGE)

    const { data: telegramButtonData } = useQuery(GET_ONE_TELEGRAM_BUTTON, {
        variables: {
            id: buttonId,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const { data: buttonLanguagesData, refetch: buttonLanguagesRefetch } = useQuery(GET_TELEGRAM_BUTTON_LANGUAGES, {
        variables: {
            filter: { telegramButtonId: { eq: buttonId } },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });


    const RenderLanguageForm = ({ lang, header }) => {
        const data = _.find(buttonLanguagesData.telegramButtonLanguages.edges, function (o) {
            return o.node.language === lang
        })

        return <Card>
            <CardBody>
                <AvForm className="needs-validation"
                    onValidSubmit={async (event, values) => {
                        if (data) {
                            updateOneTelegramButtonLanguage({
                                variables: {
                                    input: {
                                        update: {
                                            label: values.label,
                                            language: lang,
                                            telegramButtonId: buttonId
                                        },
                                        id: data.node.id
                                    }
                                }
                            }).then((val) => {
                                if (val) {
                                    ToastHelper.toggleToast({
                                        message: "Update Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                }
                            }).catch((e) => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            })
                        } else {
                            createOneTelegramButtonLanguage({
                                variables: {
                                    input: {
                                        telegramButtonLanguage: {
                                            label: values.label,
                                            language: lang,
                                            telegramButtonId: buttonId
                                        }
                                    }
                                }
                            }).then((val) => {
                                if (val) {
                                    ToastHelper.toggleToast({
                                        message: "Update Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                    buttonLanguagesRefetch()
                                }
                            }).catch((e) => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            })
                        }
                    }}
                >
                    <AvField
                        value={data ? data.node.label : null}
                        label={header.toUpperCase()}
                        name="label"
                        type="text"
                        placeholder={`Enter ${header} label`}
                        errorMessage={`Enter ${header} label`}
                    />
                    <div className="align-center space-between">
                        <button type="submit" className="btn btn-primary btn-sm waves-effect">
                            Submit
                        </button>
                        {
                            data &&
                            <div className="align-center">{`Last Updated At: `}<div className="font-weight-bold ml-2">{`${moment(data.node.updatedAt).format('YYYY-MM-DD HH:mm:ss')}`}</div></div>
                        }
                    </div>
                </AvForm>
            </CardBody>
        </Card>
    }
    return <React.Fragment>
        <div className="page-content telegram-page max-height">
            <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Telegram Page" breadcrumbItem="Telegram Button" back />
                {
                    telegramButtonData &&
                    telegramButtonData.telegramButton &&
                    <Card className="w-100">
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <div>
                                        <p className="text-muted mb-2">Action</p>
                                        <h5 className="align-left cursor-pointer">{telegramButtonData.telegramButton.action}{meData && meData.me.role === 'Developer' && <span className="bx bx-edit-alt ml-1 font-size-17" onClick={() => { setTelegramButtonMode('edit') }}></span>}</h5>
                                    </div>
                                </Col>
                                {/* <Col sm="6">
                                    <div>
                                        <p className="text-muted mb-2">Position</p>
                                        <h5 className="align-left cursor-pointer">{telegramButtonData.telegramButton.position}<span className="bx bx-edit-alt ml-1 font-size-17" onClick={() => { setTelegramButtonMode('edit') }}></span></h5>
                                    </div>
                                </Col> */}
                            </Row>
                        </CardBody>
                    </Card>
                }
                {
                    buttonLanguagesData &&
                    <Row>
                        <Col sm="6">
                            <div className="overflow-auto">
                                <RenderLanguageForm lang={'EN'} header={'English'} />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="overflow-auto">
                                <RenderLanguageForm lang={'ZH'} header={'Chinese'} />
                            </div>
                        </Col>
                        <Col sm="6">
                            <div className="overflow-auto">
                                <RenderLanguageForm lang={'MS'} header={'Malay'} />
                            </div>
                        </Col>
                    </Row>
                }
                <TelegramButtonForm mode={telegramButtonMode} toggleModal={() => {
                    setTelegramButtonMode(null)
                }} telegramMenuId={pageId} telegramButton={telegramButtonData ? { node: telegramButtonData.telegramButton } : null} />
            </Container>
        </div>
    </React.Fragment>
}

export default connect()(withTranslation()(TelegramButtonLanguage));


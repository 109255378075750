import gql from 'graphql-tag';

export const GET_TRANSACTIONS_TOTAL_COUNT = gql`
query (
    $filter: TransactionFilter
) {
    transactions (
        filter: $filter
    ) {
        totalCount
    }
}
`;
import gql from "graphql-tag";
import { PageFragment } from "../fragments/page.fragment";
import {
  FreeCreditFragment,
  FreeCreditTypeDetailFragment,
} from "../fragments/free.credit.fragment";

export const GET_FREE_CREDIT = gql`
  query (
    $paging: CursorPaging
    $filter: FreeCreditTypeFilter
    $sorting: [FreeCreditTypeSort!]
  ) {
    freeCreditTypes(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        ...Page
      }
      edges {
        node {
          ...FreeCreditType
          freeCreditTypeDetails {
            edges {
              node {
                ...FreeCreditTypeDetail
              }
            }
          }
        }
      }
    }
  }
  ${FreeCreditFragment}
  ${FreeCreditTypeDetailFragment}
  ${PageFragment}
`;

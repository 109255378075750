import gql from 'graphql-tag';

export const GENERATE_RAW_LIST = gql`
    mutation (
        $marketingCampaignId: String!
    ) {
        generateRawList (
            marketingCampaignId: $marketingCampaignId
        )
    }
`;
import gql from 'graphql-tag';

export const PaymentTypeFragment = gql`
    fragment PaymentType on PaymentType {
        id
        name
        code
        isActive
        isArchived
        createdAt
        updatedAt
    }
`;
import React, { } from 'react';
import { Row, Col, Input, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { AvForm, AvField } from "availity-reactstrap-validation";
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { GET_BANK_ACCOUNTS } from '../../../graphql/query';
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import { GET_USER_WALLETS } from '../../../graphql/query/GET_USER_WALLETS';
import NumberFormat from 'react-number-format';
import { CREATE_ONE_WITHDRAWAL, APPROVE_ONE_WITHDRAWAL } from '../../../graphql/mutation';
import ToastHelper from '../../../helpers/ToastHelper';
import DropZone from '../Components/drop-zone';
import Compressor from 'compressorjs';
import CloudinaryHelper from '../../../helpers/CloudinaryHelper';
import {
    showOverlayLoading
} from "../../../store/actions";
import NewPlayerBankAccount from './new-bank-account';
import * as moment from 'moment/moment';
import { GET_PAYMENT_TYPES } from '../../../graphql/query/GET_PAYMENT_TYPES';
import { GET_PLAYER_STAT } from '../../../graphql/query/GET_PLAYER_STAT';
import { GET_ONE_USER } from '../../../graphql/query/GET_ONE_USER';

let form;
let interval;

function BankerWithdraw(props) {

    const { t, player, isActive, onDone, playerKey } = props;

    const [wallet, setWallet] = React.useState(null)
    const [isWithdrawal, setIsWithdrawal] = React.useState(false)
    const [withdrawMethod, setWithdrawMethod] = React.useState("Bank")
    const [selectedFile, setSelectFile] = React.useState(null);
    const [addBankAccount, setAddBankAccount] = React.useState(false)
    const [date, setDate] = React.useState(null)
    const [currentTime, setCurrentTime] = React.useState(moment().format('YYYY-MM-DD HH:mm:ss'))

    const [getCompanyAccounts, { data: companyBankAccounts }] = useLazyQuery(GET_BANK_ACCOUNTS, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                accountHolderType: { eq: "Company" },
                canWithdraw: { is: true }
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    const { data: playerData } = useQuery(GET_ONE_USER, {
        variables: {
            id: player.id
        },
        fetchPolicy: "network-only"
    })

    const [getWallet, { data: walletData }] = useLazyQuery(GET_USER_WALLETS, {
        variables: {
            filter: {
                user: { id: { eq: player.id } },
                walletType: { eq: "Credit" },
                name: { eq: "Main" }
            },
        },
        fetchPolicy: 'network-only'
    });

    const { data: paymentTypeData } = useQuery(GET_PAYMENT_TYPES, {
        variables: {
            filter: {
                isActive: { is: true }
            }
        }
    });

    const { data: dataStat } = useQuery(GET_PLAYER_STAT, {
        variables: {
            id: player.id
        },
        fetchPolicy: "network-only"
    })

    const [getBankAccounts, { data: playerBankAccounts }] = useLazyQuery(GET_BANK_ACCOUNTS, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                playerId: { eq: player.id },
                isActive: { is: true }
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    const [createOneWithdrawal, { loading: loadingWithdrawal }] = useMutation(CREATE_ONE_WITHDRAWAL)
    const [approveOneWithdrawal, { loading: loadingApproveWithdrawal }] = useMutation(APPROVE_ONE_WITHDRAWAL)

    React.useEffect(() => {
        if (isActive) {
            getCompanyAccounts()
            getWallet()
            getBankAccounts()
            interval = setInterval(() => {
                setCurrentTime(moment().format('YYYY-MM-DD HH:mm:ss'))
            }, 1000)
        } else {
            clearInterval(interval)
        }
    }, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (walletData) {
            if (walletData.wallets.edges.length > 0) {
                setWallet(walletData.wallets.edges[0].node)
            }
        }
    }, [walletData])

    const Withdraw = async (payload) => {
        let result = await createOneWithdrawal({
            variables: {
                input: {
                    withdrawal: payload
                }
            }
        }).catch(e => {
            console.log(JSON.stringify(e))
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        })

        if (result && result.data) {
            await approveOneWithdrawal({
                variables: {
                    id: result.data.createOneWithdrawal.id
                }
            }).catch(e => {
                ToastHelper.toggleToast({
                    toastType: "error",
                    message: e,
                    title: "",
                    onClick: function () { }
                })
            })
        }

        setIsWithdrawal(false)
        setWallet(null)
        setSelectFile(null)
        setWithdrawMethod('Bank')
        form.reset()
        if (onDone) {
            onDone()
        }
        props.showOverlayLoading(false)
    }

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    return (
        <React.Fragment>
            <div className="player-container">
                <div className="header-wrapper">
                    <div className="player-title">Withdraw</div>
                </div>
            </div>
            <div className="banker-withdraw-container p-2">
                <div className="banker-withdraw-wrapper">
                    <AvForm className="banker-withdraw-content-info" ref={(ref) => form = ref} onValidSubmit={async (event, values) => {
                        if (isWithdrawal) {
                            return
                        }
                        setIsWithdrawal(true)
                        props.showOverlayLoading(true, "Loading")

                        let result
                        if (selectedFile) {
                            props.showOverlayLoading(true, "Uploading Receipt")
                            let imageData = await Compress(selectedFile)
                            result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=rjhrtxfi`)
                        }
                        props.showOverlayLoading(true, "Loading")
                        Withdraw({
                            amount: parseFloat(values.withdrawal.toString().replaceAll(',', '')),
                            playerBankAccountId: values.playerBankAccount,
                            companyBankAccountId: values.companyId,
                            paymentTypeId: values.withdrawalMode,
                            receiptUrl: selectedFile ? result.data.url : null,
                            playerId: player.id,
                            fromWalletId: values.walletId,
                            receiptDate: moment(values.dateAndTime).toISOString(),
                            receiptReferenceNo: values.receiptId
                        })
                    }}>
                        {
                            playerData &&
                            <Row>
                                <Col xs={12} sm={12} md={3} lg={3}>
                                    <AvField
                                        defaultValue={playerData.user.serialNo}
                                        label="Player"
                                        name="depositor"
                                        disabled
                                        type="text"
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={3} lg={3}>
                                    <AvField
                                        value={dataStat ? `${parseFloat(dataStat.playerStats.totalDepositAmount).toFixed(2)} (${parseFloat(dataStat.playerStats.totalWithdrawalAmount).toFixed(2)})` : '-'}
                                        label="Player Deposit/Withdraw"
                                        name="win-loss"
                                        disabled
                                        type="text"
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <AvField
                                        defaultValue={moment().format('YYYY-MM-DD HH:mm:ss')}
                                        value={isActive && currentTime}
                                        label={'Transaction Created At'}
                                        name="timestamp"
                                        disabled
                                        type="text"
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <AvField
                                    label={t('Enter Amount')}
                                    placeholder="Enter Amount"
                                    errorMessage="Enter Amount"
                                    name="withdrawal"
                                    type="text"
                                    decimalScale={2}
                                    fixedDecimalScale
                                    tag={[Input, NumberFormat]}
                                    validate={{
                                        required: { value: true },
                                        max: { value: wallet ? parseFloat(wallet.balance) : null, errorMessage: "Exceed Available Balance" },
                                    }}
                                    helpMessage={wallet ? `Available Balance: ${parseFloat(wallet.balance).toFixed(2)}` : ''}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <AvField
                                    defaultValue={'-'}
                                    label={t('Withdraw Via')}
                                    placeholder="Withdraw Via"
                                    errorMessage="Withdraw Via"
                                    name="withdrawalMode"
                                    type="select"
                                    className="custom-select"
                                    value={paymentTypeData && paymentTypeData.paymentTypes.edges.length > 0 && paymentTypeData.paymentTypes.edges[0].node.id}
                                    onChange={(e) => {
                                        let data = _.find(paymentTypeData.paymentTypes.edges, ({ node }) => {
                                            return node.id === e.target.value
                                        })
                                        if (data) {
                                            setWithdrawMethod(data.node.code)
                                        }
                                    }}
                                >
                                    {
                                        paymentTypeData &&
                                        paymentTypeData.paymentTypes.edges.map(({ node }, index) => {
                                            return (
                                                <option key={index} value={node.id}>{node.name}</option>
                                            )
                                        })
                                    }
                                </AvField>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6}>
                                <AvField
                                    defaultValue={'-'}
                                    label="From Wallet"
                                    name="walletId"
                                    type="select"
                                    className="custom-select"
                                    onChange={(e) => {
                                        let selectedWallet = _.find(walletData.wallets.edges, wal => {
                                            return wal.node.id === e.target.value
                                        })
                                        setWallet(selectedWallet.node)
                                    }}
                                    value={walletData &&
                                        walletData.wallets.edges.length > 0 &&
                                        walletData.wallets.edges[0].node.id
                                    }
                                >
                                    {
                                        walletData &&
                                        walletData.wallets.edges.length > 0 &&
                                        walletData.wallets.edges.map((wallet, index) => {
                                            return (
                                                <option key={index} value={wallet.node.id}>{`${wallet.node.name}(${wallet.node.walletType}): $${wallet.node.balance}`}</option>
                                            )
                                        })
                                    }
                                </AvField>
                            </Col>
                            {
                                withdrawMethod === 'Bank' &&
                                <>
                                    <Col xs={12} sm={12} md={6} lg={6}>
                                        <AvField
                                            label="Withdraw from Company Bank Account"
                                            name="companyId"
                                            type="select"
                                            className="custom-select"
                                            value={
                                                companyBankAccounts &&
                                                companyBankAccounts.bankAccounts.edges.length > 0 &&
                                                companyBankAccounts.bankAccounts.edges[0].node.id
                                            }
                                        >
                                            {
                                                companyBankAccounts &&
                                                companyBankAccounts.bankAccounts.edges.map((bankAccount, index) => {
                                                    return (
                                                        <option key={index} value={bankAccount.node.id}>{`${bankAccount.node.bank.name} - ${bankAccount.node.accountName} - ${bankAccount.node.accountNo}`}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="align-bottom">
                                            <AvField
                                                label="Player Bank Accounts"
                                                name="playerBankAccount"
                                                type="select"
                                                className="custom-select"
                                                value={playerBankAccounts && playerBankAccounts.bankAccounts.edges.length > 0 && playerBankAccounts.bankAccounts.edges[0].node.id}
                                            >
                                                {
                                                    playerBankAccounts &&
                                                    playerBankAccounts.bankAccounts.edges.map((bankAccount, index) => {
                                                        return (
                                                            <option key={index} value={bankAccount.node.id}>{`${bankAccount.node.bank.name} - ${bankAccount.node.accountName} - ${bankAccount.node.accountNo}`}</option>
                                                        )
                                                    })
                                                }
                                            </AvField>
                                            <div className="pl-2"></div>
                                            <Button type="button" color="primary" className="waves-light waves-effect mb-3" onClick={() => {
                                                setAddBankAccount(true)
                                            }}>
                                                <i className="bx bx-plus"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </>
                            }
                        </Row>
                        {
                            withdrawMethod === "Bank" &&
                            <div className="bonus-container">
                                <Row>
                                    <Col>
                                        <div className="pl-0 pr-1 pt-2 pb-3 font-weight-medium">Receipt</div>
                                    </Col>
                                </Row>
                                <DropZone
                                    selectedFile={selectedFile}
                                    onFileDrop={(file) => {
                                        setSelectFile(file)
                                    }}
                                />
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className="align-bottom">
                                            <AvField
                                                defaultValue={new Date()}
                                                selected={date}
                                                label="Receipt Date and Time"
                                                name="dateAndTime"
                                                type="text"
                                                placeholderText="Enter Receipt Date and Time"
                                                errorMessage="Enter Receipt Date and Time"
                                                showTimeSelect
                                                timeIntervals={1}
                                                onChange={date => {
                                                    setDate(date)
                                                }}
                                                selectsStart
                                                maxDate={new Date()}
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                tag={[Input, DatePicker]}
                                                validate={{
                                                    required: { value: true }
                                                }}
                                            />
                                            <div className="pl-2"></div>
                                            <Button type="button" color="primary" className="waves-light waves-effect mb-3" onClick={() => {
                                                setDate(moment().toDate())
                                            }}>
                                                Now
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <AvField
                                            label="Receipt Reference No"
                                            name="receiptId"
                                            type="text"
                                            placeholder="Enter Receipt Reference No"
                                            errorMessage="Enter Receipt Reference No"
                                            validate={{
                                                required: { value: true }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        }
                        <div className="submit-container">
                            <Button color="primary" type="submit">
                                {
                                    (loadingWithdrawal || loadingApproveWithdrawal) &&
                                    <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                }
                                {
                                    (!loadingWithdrawal && !loadingApproveWithdrawal) &&
                                    <div>
                                        Submit
                                    </div>
                                }
                            </Button>
                        </div>
                    </AvForm>
                </div>
            </div>
            <NewPlayerBankAccount
                modal={addBankAccount}
                toggleModal={() => {
                    getBankAccounts()
                    setAddBankAccount(!addBankAccount)
                }}
                player={player}
                playerKey={playerKey}
            />
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(withTranslation()(BankerWithdraw));
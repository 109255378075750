import gql from 'graphql-tag';
import { FreeCreditFragment } from '../fragments/free.credit.fragment';

export const UPDATE_ONE_FREE_CREDIT_TYPE = gql`
    mutation(
        $input: UpdateOneFreeCreditTypeInput!
    ) {
        updateOneFreeCreditType (input: $input) {
            ...FreeCreditType
        }
    }
    ${FreeCreditFragment}
`;
import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component
import Chat from "../pages/Chat/Chat";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

//Tasks
import TasksCreate from "../pages/Tasks/tasks-create";
import TasksKanban from "../pages/Tasks/tasks-kanban";
import TasksList from "../pages/Tasks/tasks-list";

//Projects
import ProjectsCreate from "../pages/Projects/projects-create";
import ProjectsGrid from "../pages/Projects/projects-grid";
import ProjectsList from "../pages/Projects/projects-list";
import ProjectsOverview from "../pages/Projects/projects-overview";

//Ecommerce Pages
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProductDetail";
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";

//Invoices
import InvoiceDetail from "../pages/Invoices/invoices-detail";
import InvoicesList from "../pages/Invoices/invoices-list";

// Authentication related pages
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

// Inner Authentication
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";

// Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";

//Crypto
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell";
import CryptoExchange from "../pages/Crypto/crypto-exchange";
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application";
import CryptoLending from "../pages/Crypto/crypto-lending";
import CryptoOrders from "../pages/Crypto/crypto-orders";
import CryptoWallet from "../pages/Crypto/crypto-wallet";
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";
import MapsVector from "../pages/Maps/MapsVector";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconFontawesome from "../pages/Icons/IconFontawesome";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import EditableTables from "../pages/Tables/EditableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";

// Forms
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormElements from "../pages/Forms/FormElements";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormValidations from "../pages/Forms/FormValidations";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiImageCropper from "../pages/Ui/ui-image-cropper";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiRating from "../pages/Ui/UiRating";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";

//Pages
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesPricing from "../pages/Utility/pages-pricing";
import PagesStarter from "../pages/Utility/pages-starter";
import PagesTimeline from "../pages/Utility/pages-timeline";

//Products
import ProductsList from "../pages/Platforms/platform-lists";
import ProductDetails from "../pages/Platforms/product-main";

//Banks
import BankAccountsList from "../pages/Banking/accounts-list";
import BanksList from "../pages/Banking/banks-list";

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/contacts-list";
import ContactsProfile from "../pages/Contacts/contacts-profile";

//Game
import GameAccountList from "../pages/GameAccount/game-account-list";

//Work Bench
import BonusList from "../pages/Bonus/bonus-list";
import FreeCreditInfo from "../pages/FreeCredit/free-credit-info";
import FreeCreditList from "../pages/FreeCredit/free-credit-list";
import Profile from "../pages/Profile/profile";
import SettingsTemplate from "../pages/Settings/settings-template";
import TransactionAgentPoint from "../pages/Transactions/transaction-agent";
import TransactionDeposit from "../pages/Transactions/transaction-deposit";
import TransactionRewardPoint from "../pages/Transactions/transaction-rp";
import TransactionTransfer from "../pages/Transactions/transaction-transfer";
import TransactionWithdraw from "../pages/Transactions/transaction-withdraw";
import BankerWorkbench from "../pages/Workbench/Banker/banker-workbench";

//playerlist
import PlayerList from "../pages/Player/player-list";
import Referral from "../pages/Referral/referral";
import TagsList from "../pages/Tags/tags-list";
import WalletInfo from "../pages/Wallet/wallet-info";

//kiosk
import AgentTiersList from "../pages/AgentTiers/agent-tiers-list";
import AttendanceTiersList from "../pages/Attendance/attendance-tiers-list";
import BankInfo from "../pages/Banking/bank-info";
import BannerList from "../pages/Banner/banner-list";
import CategoryList from "../pages/Category/category-list";
import CountryList from "../pages/Country/country-list";
import CurrencyList from "../pages/Currency/currency-list";
import KioskListMain from "../pages/Kiosk/kioks-list-main";
import CampaignInfo from "../pages/MarketingCampaign/campaign-info";
import MarketingCampaignList from "../pages/MarketingCampaign/marketing-campaign-list";
import PaymentTypeList from "../pages/PaymentTypes/payment-type-list";
import RejectReasonList from "../pages/RejectReason/reject-reason-list";
import AgentReport from "../pages/Report/agent-report";
import PlayerReport from "../pages/Report/player-report";
import Report from "../pages/Report/report";
import TransactionReport from "../pages/Report/transaction-report";
import RewardTiersList from "../pages/RewardTiers/reward-tiers-list";
import messageAndMediaList from "../pages/TelegramPage/message-and-media/message-and-media-list";
import TelegramButtonLanguage from "../pages/TelegramPage/telegram-button-language/telegram-button-language";
import TelegramButtonTable from "../pages/TelegramPage/telegram-button/telegram-button-table";
import TelegramMenuButtonTable from "../pages/TelegramPage/telegram-menu-button-table/telegram-menu-button-table";
import TelegramPage from "../pages/TelegramPage/telegram-page/telegram-page";
import transactionFreeCredit from "../pages/Transactions/transaction-free-credit";
import transactionRebate from "../pages/Transactions/transaction-rebate";
import Transactions from "../pages/Transactions/transactions";

const operatorRoute = [
  { path: "/dashboard", component: Dashboard },

  //Wokrbench
  { path: "/cashier-workbench", component: BankerWorkbench },
  { path: "/wallet-details/:playerId/:walletId", component: WalletInfo },
  { path: "/bankaccount-details/:bankAccountId", component: BankInfo },

  //Transactions
  { path: "/transactions/:serialNo?", component: Transactions },
  { path: "/transaction-withdraw/:tab?", component: TransactionWithdraw },
  { path: "/transaction-deposit/:tab?", component: TransactionDeposit },
  { path: "/transaction-transfer/:tab?", component: TransactionTransfer },
  { path: "/transaction-promotion/:tab?", component: transactionFreeCredit },
  { path: "/transaction-rebate/:tab?", component: transactionRebate },
  { path: "/transaction-rp", component: TransactionRewardPoint },
  { path: "/transaction-agent", component: TransactionAgentPoint },

  { path: "/kiosk", component: KioskListMain },

  //Banks
  { path: "/bank-accounts-list", component: BankAccountsList },

  //Player
  { path: "/player", component: PlayerList },

  { path: "/agent-tiers", component: AgentTiersList },

  //Report
  { path: "/report/:type/:paramFromDate?/:paramToDate?", component: Report },
  { path: "/player-report", component: PlayerReport },

  { path: "/marketing-campaigns", component: MarketingCampaignList },
  { path: "/reward-tiers", component: RewardTiersList },
  { path: "/attendance", component: AttendanceTiersList },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const supervisorRoute = [
  //Bonus
  { path: "/bonus", component: BonusList },
  { path: "/promotion/:id", component: FreeCreditInfo },
  { path: "/promotion", component: FreeCreditList },

  //Report
  { path: "/agent-report/:agentId", component: AgentReport },
  { path: "/transaction-report", component: TransactionReport },

  //Banks
  { path: "/banks-list", component: BanksList },

  //Settings
  { path: "/settings-template", component: SettingsTemplate },

  //Game
  { path: "/game", component: GameAccountList },
  // { path: "/game-account", component: GameAccountListMain },

  //Game
  { path: "/profile", component: Profile },

  //Referral
  { path: "/referral", component: Referral },

  //Tags
  { path: "/tag", component: TagsList },

  { path: "/payment-type", component: PaymentTypeList },
  { path: "/reject-reason", component: RejectReasonList },

  { path: "/balance-log", component: BankInfo },
  { path: "/campaign-info", component: CampaignInfo },

  { path: "/currency", component: CurrencyList },
  { path: "/country", component: CountryList },

  ...operatorRoute,
];

const authProtectedRoutes = [
  //Crypto
  { path: "/crypto-wallet", component: CryptoWallet },
  { path: "/crypto-buy-sell", component: CryptoBuySell },
  { path: "/crypto-exchange", component: CryptoExchange },
  { path: "/crypto-lending", component: CryptoLending },
  { path: "/crypto-orders", component: CryptoOrders },
  { path: "/crypto-kyc-application", component: CryptoKYCApplication },

  //chat
  { path: "/chat", component: Chat },

  //calendar
  { path: "/calendar", component: Calendar },

  //Ecommerce
  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-product-detail", component: EcommerceProductDetail },
  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },

  //Invoices
  { path: "/invoices-list", component: InvoicesList },
  { path: "/invoices-detail", component: InvoiceDetail },

  // Tasks
  { path: "/tasks-list", component: TasksList },
  { path: "/tasks-kanban", component: TasksKanban },
  { path: "/tasks-create", component: TasksCreate },

  //Projects
  { path: "/projects-grid", component: ProjectsGrid },
  { path: "/projects-list", component: ProjectsList },
  { path: "/projects-overview", component: ProjectsOverview },
  { path: "/projects-create", component: ProjectsCreate },

  //Products
  { path: "/product-detail-platform/:id", component: ProductDetails },
  { path: "/product", component: ProductsList },

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //Banner
  { path: "/banner-list", component: BannerList },
  { path: "/category-list", component: CategoryList },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-image-cropper", component: UiImageCropper },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  //Telegram Page
  { path: "/telegram-button/:buttonId", component: TelegramButtonLanguage },
  { path: "/telegram-button", component: TelegramButtonTable },
  { path: "/telegram-page/:pageId", component: TelegramMenuButtonTable },
  { path: "/telegram-page", component: TelegramPage },
  { path: "/message-and-media", component: messageAndMediaList },

  ...supervisorRoute,
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/auth-lock-screen", component: LockScreen },
];

export { authProtectedRoutes, operatorRoute, publicRoutes, supervisorRoute };


import gql from 'graphql-tag';
import { PlatformFragment } from './platform.fragment';

export const KioskFragment = gql`
    fragment KioskInfo on KioskAccount {
        id
        username
        password
        secondPassword
        platformId
        isActive
        isArchived
        createdAt
        updatedAt
        lastSyncAt
        balance
        isDefault
        maintenanceMode
        autoSyncMode
        message
        isBotEnabled
        messageLabelType
        platform{
            ...PlatformInfo
        }
    }
    ${PlatformFragment}
`;
import gql from 'graphql-tag';
import { BankAccountFragment } from '../fragments/bank.fragment';

export const UPDATE_ONE_BANK_ACCOUNT = gql`
    mutation(
        $input: UpdateOneBankAccountInput!
    ) {
        updateOneBankAccount (input: $input) {
            ...BankAccountInfo
        }
    }
    ${BankAccountFragment}
`;
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const PAY_REFERRAL_FEE = gql`
    mutation(
        $playerId: String!
    ) {
        payReferralFee (
            playerId: $playerId
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
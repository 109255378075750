import gql from 'graphql-tag';
import { PlayerStatFragment } from '../fragments/player.stat.fragment';

export const GET_PLAYER_STAT = gql`
    query (
        $id: String!
    ) {
        playerStats (
            id: $id
        ) {
            ...PlayerStat
        }
    }
    ${PlayerStatFragment}
`;
import gql from 'graphql-tag';

export const CategoryFragment = gql`
    fragment CategoryInfo on Category {
        id
        name
        slug
        logoUrl
        isActive
        isArchived
        createdAt
        updatedAt
    }
`;
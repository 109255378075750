


import React, { Fragment } from 'react';
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    FormGroup,
    Label
} from "reactstrap";

import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { CREATE_ONE_GAME } from '../../graphql/mutation/CREATE_ONE_GAME';
import { GET_USERS } from '../../graphql/query';
import { GET_KIOSK } from '../../graphql/query/GET_KIOSK';
export default function NewGameAccount(props) {

    const { modal, toggleModal, curKioskAccount } = props;
    const [createOneGameAccount] = useMutation(CREATE_ONE_GAME);


    const [isValidUser, setIsValidUser] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [user, setUser] = React.useState([]);

    React.useEffect(() => {
        setSearch('')
        setIsValidUser(true)
        setUser([])
    }, [modal])



    const handleUserSearch = (query) => {
        setSearch(query)
    }


    const { data: userData, loading: loadingUser } = useQuery(GET_USERS, {
        variables: {
            filter: {
                and: [
                    { name: { iLike: `%${search}%` } },
                    { role: { eq: "Player" } }
                ]
            }
        },
        notifyOnNetworkStatusChange: true
    });




    const [isValidKiosk, setValidKiosk] = React.useState(true);
    const [searchKiosk, setSearchKiosk] = React.useState([]);
    const [kioskSearch, setKioskSearch] = React.useState('');

    const { data, isLoading } = useQuery(GET_KIOSK, {
        variables: {
            filter: {
                and: [{
                    platform: { name: { iLike: `%${searchKiosk}%` } }
                }, {
                    isDefault: { is: true }
                }]
            }
        }
    })


    const handleKioskSearch = (value) => {
        setSearchKiosk(value)
    }

    const checkValidity = (value) => {
        if (!curKioskAccount) {
            return isValidKiosk && kioskSearch.length > 0
        } else {
            return true;
        }
    }

    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-game-page">
                <ModalHeader toggle={toggleModal}>
                    Add New Game Account
                </ModalHeader>
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                console.log(user);

                                if (user.length > 0) {


                                    if (!checkValidity()) {
                                        setValidKiosk(false);
                                        return;
                                    }
                                    const { error } = createOneGameAccount({
                                        variables: {
                                            input: {
                                                "gameAccount": {
                                                    "username": values.login,
                                                    "password": values.password,
                                                    "kioskAccountId": curKioskAccount ? curKioskAccount.id : kioskSearch[0].node.id,
                                                    "playerId": user[0].node.id
                                                }
                                            }
                                        }
                                    }).catch(e => {
                                        console.log(e)
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            toggleModal()
                                        }
                                    })


                                    console.log("error", error);

                                } else {
                                    if (user.length === 0) {
                                        setIsValidUser(false)
                                    }
                                }
                            }
                            }
                            onInvalidSubmit={() => {
                                if (user.length === 0) {
                                    setIsValidUser(false)
                                }
                            }}
                        >
                            <Row>
                                <Col lg="6">
                                    <FormGroup className={`${!isValidUser ? 'text-danger' : ''}`}>
                                        <Label for="newrole-input">Player</Label>
                                        <AsyncTypeahead
                                            id="user-list"
                                            isLoading={loadingUser}
                                            labelKey={option => `${option.node.username}`}
                                            minLength={0}
                                            onSearch={handleUserSearch}
                                            onInputChange={(val) => {
                                                if (val === "") {
                                                    handleUserSearch('')
                                                }
                                            }}
                                            options={userData ? userData.users.edges : []}
                                            placeholder="Search for Users"
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <span>{option.node.username}</span>
                                                </Fragment>
                                            )}
                                            errorMessage="Enter Username"
                                            isInvalid={!isValidUser}
                                            onChange={(val) => {
                                                setIsValidUser(val.length > 0 ? true : false)
                                                setUser(val)
                                            }}
                                            onBlur={(e) => {
                                                if (isValidUser && user.length === 0) {
                                                    setIsValidUser(false)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                {curKioskAccount && <Col lg="6">
                                    <AvField
                                        label="Kiosk Account"
                                        name="kioskAccount"
                                        type="text"
                                        disabled={true}
                                        value={curKioskAccount ? curKioskAccount.username : ""}
                                        placeholder=""
                                        errorMessage=""
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />

                                </Col>}

                                {!curKioskAccount && <Col lg="6">
                                    <FormGroup className={`${!isValidKiosk ? 'text-danger' : ''}`}>
                                        <Label for="newrole-input">{"Platform"}</Label>
                                        <AsyncTypeahead
                                            id="platform-list"
                                            isLoading={isLoading}
                                            labelKey={option => `${option.node.platform.displayName}`}
                                            minLength={0}
                                            selected={kioskSearch}
                                            onSearch={handleKioskSearch}
                                            onInputChange={(val) => {
                                                if (val === "") {
                                                    handleKioskSearch('')
                                                }
                                            }}
                                            options={data ? data.kioskAccounts.edges : []}
                                            placeholder={'Search for Platform'}
                                            useCache={false}
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <span>{option.node.platform.displayName}</span>
                                                </Fragment>
                                            )}
                                            errorMessage={'Enter Platform Name'}
                                            isInvalid={!isValidKiosk}
                                            onChange={(val) => {
                                                setValidKiosk(val.length > 0 ? true : false)
                                                setKioskSearch(val)

                                            }}
                                            onBlur={(e) => {
                                                if (isValidKiosk && kioskSearch.length === 0) {
                                                    setValidKiosk(false)
                                                }
                                            }}
                                        />

                                    </FormGroup>
                                </Col>}

                            </Row>
                            <Row>
                                <Col lg="6">
                                    <AvField
                                        label="Game Account Login"
                                        name="login"
                                        type="text"
                                        placeholder="Enter Game Account Login"
                                        errorMessage="Enter Game Account Login"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="Password"
                                        name="password"
                                        type="text"
                                        placeholder="Enter Password"
                                        errorMessage="Enter Password"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>

                            </Row>

                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
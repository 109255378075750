
import moment from 'moment';
import React from 'react';
import { useMutation } from 'react-apollo';
import Zoom from 'react-medium-image-zoom';
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { Admin, Developer, Manager, Supervisor } from '../../assets/scss/role.constant';
import { UPDATE_ONE_MARKETING_CAMPAIGN } from '../../graphql/mutation';
import RoleHelper from '../../helpers/RoleHelper';
import ToastHelper from '../../helpers/ToastHelper';


function CampaignItem(props) {

    const { onEdit, marketingCampaign } = props;
    const [updateOneCampaign] = useMutation(UPDATE_ONE_MARKETING_CAMPAIGN);

    const disabledRoles = !RoleHelper.checkRoles([Developer, Admin, Manager, Supervisor])

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const GetLanguage = (lang) => {
        if (lang == null) {
            return "All Language"
        } else if (lang == "EN") {
            return "English"
        } else if (lang == "MS") {
            return "Malay"
        } else if (lang == "ZH") {
            return "Chinese"
        }
    }


    const onUserEdit = () => {
        if (!disabledRoles) {
            onEdit(marketingCampaign)
        }
    }

    return <React.Fragment>
        <td>
            <div className="">
                <Zoom>
                    <img
                        alt=""
                        src={marketingCampaign.node.mediaLink}
                        // width="40"
                        height="40"
                    />
                </Zoom>
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {marketingCampaign.node.name}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {marketingCampaign.node.marketingCampaignMedium}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {moment(marketingCampaign.node.startAt).format('YYYY-MM-DD HH:mm:ss')}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {moment(marketingCampaign.node.endAt).format('YYYY-MM-DD HH:mm:ss')}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {GetLanguage(marketingCampaign.node.targetingLanguage)}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {marketingCampaign.node.message ? marketingCampaign.node.message : '-'}
            </div>
        </td>
        <td>
            <Switch
                disabled={disabledRoles}
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                id="is-active"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={() => {
                    updateOneCampaign({
                        variables: {
                            input: {
                                update: {
                                    isActive: !marketingCampaign.node.isActive
                                },
                                id: marketingCampaign.node.id
                            }
                        }
                    }).catch(e => {
                        ToastHelper.toggleToast({
                            toastType: "error",
                            message: e,
                            title: "",
                            onClick: function () { }
                        })
                    }).then(val => {
                        if (val) {
                            ToastHelper.toggleToast({
                                message: "Update Succesfully!",
                                title: "",
                                onClick: function () { }
                            })
                        }
                    })

                }}
                checked={marketingCampaign.node.isActive}
            />
        </td>
        <td>
            <Switch
                disabled={disabledRoles}
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                id="is-active"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={() => {
                    updateOneCampaign({
                        variables: {
                            input: {
                                update: {
                                    autoMode: !marketingCampaign.node.autoMode
                                },
                                id: marketingCampaign.node.id
                            }
                        }
                    }).catch(e => {
                        ToastHelper.toggleToast({
                            toastType: "error",
                            message: e,
                            title: "",
                            onClick: function () { }
                        })
                    }).then(val => {
                        if (val) {
                            ToastHelper.toggleToast({
                                message: "Update Succesfully!",
                                title: "",
                                onClick: function () { }
                            })
                        }
                    })

                }}
                checked={marketingCampaign.node.autoMode}
            />
        </td>
        <td className="relative align-flex">
            <button disabled={disabledRoles} type="button" className="btn btn-primary waves-effect waves-light font-size-11 align-center ml-1" onClick={() => {
                if (onEdit) {
                    onEdit(marketingCampaign)
                }
            }}>
                <div>Edit</div>
            </button>

            <Link className="btn btn-secondary waves-effect waves-light font-size-11 align-center ml-1" to={{
                "pathname": "/campaign-info",
                state: { "campaign": marketingCampaign.node }
            }}>
                <div>Manage</div>
            </Link>
        </td>
        {/* <td >
            <Col className="dropdown-actions-table">
                <ButtonDropdown
                    isOpen={isOpen}
                    toggle={() => setIsOpen(!isOpen)}
                >
                    <DropdownToggle caret color="secondary">
                        Actions <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                        <DropdownItem onClick={onEdit}>Edit Campaign</DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </Col>

        </td> */}
    </React.Fragment>
}

export default CampaignItem;
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const RESET_PASSWORD = gql`
    mutation(
        $input: ChangePasswordInput!
        $id: String!
    ) {
        changePassword (
            changePasswordInput: $input
            id: $id
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
import gql from 'graphql-tag';

export const BannerFragment = gql`
    fragment BannerInfo on Banner {
        id
        name
        bannerUrl
        redirectUrl
        isActive
        isArchived
        createdAt
        updatedAt
    }
`;
import gql from 'graphql-tag';
import { FreeCreditFragment } from '../fragments/free.credit.fragment';

export const CREATE_ONE_FREE_CREDIT_TYPE = gql`
    mutation(
        $input: CreateOneFreeCreditTypeInput!
    ) {
        createOneFreeCreditType (input: $input) {
            ...FreeCreditType
        }
    }
    ${FreeCreditFragment}
`;
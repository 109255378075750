import gql from 'graphql-tag';

import { TagFragment } from '../fragments/tag.fragment';

export const CREATED_TAG = gql`
    subscription {
        createdTag {
            ...TagInfo
        }
    }
    ${TagFragment}
`;
import gql from 'graphql-tag';
import { WalletFragment } from '../fragments/wallet.fragment';

export const GET_USER_WALLETS = gql`
    query (
        $filter: WalletFilter
    ) {
        wallets (
            filter: $filter
        ) {
            edges {
                node {
                    ...WalletInfo
                }
            }
        }
    }
    ${WalletFragment}
`;
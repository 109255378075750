
import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter, Link } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';

// users
import { useQuery } from 'react-apollo';
import { ME } from '../../../graphql/query';
import Avatar from '../../Common/Avatar';
import _ from 'lodash';
import EditProfile from './EditProfile';
import ResetPassword from '../../../pages/Contacts/reset-password';

function ProfileMenu(props) {

    const { t } = props
    const [menu, setMenu] = React.useState(false)
    const [editUser, setEditUser] = React.useState(null)
    const [resetPassword, setResetPassword] = React.useState(null)

    // constructor(props) {
    //     super(props);
    //     this.state = {
    //         menu: false,
    //         username: "Henry"
    //     };
    //     this.toggle = this.toggle.bind(this);
    // }


    // toggle() {
    //     this.setState(prevState => ({
    //         menu: !prevState.menu
    //     }));
    // }

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    return (
        <React.Fragment>
            <Dropdown isOpen={menu} toggle={() => { setMenu(!menu) }} className="d-inline-block" >
                <DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
                    {/* <img className="rounded-circle header-profile-user" src={''} alt="Header Avatar" /> */}
                    {
                        meData &&
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}>
                            <Avatar name={meData.me.username} />
                            <span className="d-none d-xl-inline-block ml-2 mr-1">{meData.me.username}</span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </div>
                    }
                </DropdownToggle>
                <DropdownMenu right>
                    {/* <DropdownItem tag="a" href="/crypto-wallet">
                            <i className="bx bx-wallet font-size-16 align-middle mr-1"></i>
                            {this.props.t('My Wallet')}
                        </DropdownItem> */}
                    {/* <DropdownItem tag="a" href="#">
                            <span className="badge badge-success float-right mt-1">5</span><i className="bx bx-wrench font-size-17 align-middle mr-1"></i>
                            {this.props.t('Settings')}
                        </DropdownItem> */}
                    <DropdownItem onClick={() => setEditUser(meData.me)}>
                        <i className="bx bxs-edit font-size-16 align-middle mr-1"></i>{t('Edit Profile')}
                    </DropdownItem>
                    <DropdownItem onClick={() => setResetPassword(meData.me)}>
                        <i className="bx bx-lock-open font-size-16 align-middle mr-1"></i>{t('Edit Password')}
                    </DropdownItem>
                    <div className="dropdown-divider"></div>
                    <Link to="/logout" className="dropdown-item">
                        <i className="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i>
                        <span>{t('Logout')}</span>
                    </Link>
                </DropdownMenu>
            </Dropdown>
            {
                editUser &&
                <EditProfile
                    modal={!_.isEmpty(editUser)}
                    toggleModal={() => {
                        setEditUser(null)
                    }}
                    user={editUser}
                />
            }
            {
                resetPassword &&
                <ResetPassword
                    modal={!_.isEmpty(resetPassword)}
                    toggleModal={() => {
                        setResetPassword(null)
                    }}
                    user={resetPassword}
                />
            }
        </React.Fragment>
    );
}

export default withRouter(withTranslation()(ProfileMenu));

import { Pagination } from '@mui/material';
import { AvField, AvForm } from "availity-reactstrap-validation";
import classnames from "classnames";
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Input, Row, Table } from "reactstrap";
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';
import { ME, SALE_SUMMARY_REPORT } from '../../graphql/query';
import {
    showOverlayLoading
} from "../../store/actions";

function Report(props) {

    var take = 100
    const { t, showOverlayLoading } = props;
    const { type } = props.match.params
    let history = useHistory();

    const csvLink = React.useRef(null);

    const [periodType, setPeriodType] = React.useState(type)
    const [dateDisplay, setDateDisplay] = React.useState('YYYY-MM')
    const [fromDate, setFromDate] = React.useState(moment().startOf("year").format('YYYY-MM-DD'))
    const [toDate, setToDate] = React.useState(moment().endOf("year").format('YYYY-MM-DD'))
    const [tempFromDate, setTempFromDate] = React.useState(moment().startOf("year").toDate())
    const [tempToDate, setTempToDate] = React.useState(moment().endOf("year").toDate())
    const [orderBy, setOrderBy] = React.useState('period')
    const [orderDirection, setOrderDirection] = React.useState('ASC')
    const dateArr = [{ "name": "Today", "key": "day" }, { "name": "Yesterday", "key": "day", "deduct": 1 }, { "name": "In 3 Days", "minus": 2 }, { "name": "In A Week", "minus": 6 }, { "name": "Current Week", "key": "week" }, { "name": "Last Week", "key": "week", "deduct": 1 }, { "name": "In A Month", "minus": 29 }, { "name": "Current Month", "key": "month" }, { "name": "Last Month", "key": "month", "deduct": 1 }, { "name": "Current Year", "key": "year" }, { "name": "Last Year", "key": "year", "deduct": 1 }]
    const rangeArr = [{ "name": "Year", "key": "year" }, { "name": "Month", "key": "month" }, { "name": "Day", "key": "day" }]
    const [dateType, setDateType] = React.useState(dateArr[9])
    const [page, setPage] = React.useState(1);
    const [csvData, setCSVData] = React.useState([])
    const [readyDownload, setReadyDownload] = React.useState(false)

    const { data: meData } = useQuery(ME);

    const [getReport, { data, loading }] = useLazyQuery(SALE_SUMMARY_REPORT, {
        variables: {
            periodType: periodType,
            fromDate: fromDate,
            toDate: toDate,
            orderBy: orderBy,
            orderDirection: orderDirection,
            skip: 0,
            take: take
        },
        fetchPolicy: 'network-only'
    })

    const [getCSVData, { data: csvTempData }] = useLazyQuery(SALE_SUMMARY_REPORT, {
        variables: {
            periodType: periodType,
            fromDate: fromDate,
            toDate: toDate,
            orderBy: orderBy,
            orderDirection: orderDirection,
            skip: 0,
            take: take
        },
        fetchPolicy: 'no-cache'
    })

    React.useEffect(() => {
        setPage(1)
        getReport({
            variables: {
                periodType: periodType,
                fromDate: fromDate,
                toDate: toDate,
                orderBy: orderBy,
                orderDirection: orderDirection,
                skip: 0,
                take: take
            },
            fetchPolicy: 'network-only'
        })
    }, [periodType, fromDate, toDate, orderBy, orderDirection])

    React.useEffect(() => {
        if (props.match.params) {
            // setPeriodType(type)
            DateDisplayFormat(type)
            // SetDates(type)
        }
    }, [props.match.params]) // eslint-disable-line react-hooks/exhaustive-deps

    const Formatter = (value) => {
        let val = parseFloat(value).toFixed(2)
        return <NumberFormat
            value={val}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
        />
    }

    const DateDisplayFormat = (value) => {
        switch (value) {
            case "day":
                setDateDisplay('YYYY-MM-DD')
                break;
            case "month":
                setDateDisplay('YYYY-MM')
                break;
            case "year":
                setDateDisplay('YYYY')
                break;
            default:
                break;
        }
    }

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
            } else {
                setOrderDirection('ASC')
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)
        }
    }

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }

    const SetDates = (dateType) => {
        if (dateType.minus) {
            setTempFromDate(moment().subtract(dateType.minus, "days").toDate())
            setTempToDate(moment().toDate())
            setFromDate(moment().subtract(dateType.minus, "days").format('YYYY-MM-DD'))
            setToDate(moment().format('YYYY-MM-DD'))
        } else if (dateType.key) {
            if (dateType.deduct) {
                setTempFromDate(moment().subtract(dateType.deduct, dateType.key).startOf(dateType.key).toDate())
                setTempToDate(moment().subtract(dateType.deduct, dateType.key).endOf(dateType.key).toDate())
                setFromDate(moment().subtract(dateType.deduct, dateType.key).startOf(dateType.key).format('YYYY-MM-DD'))
                setToDate(moment().subtract(dateType.deduct, dateType.key).endOf(dateType.key).format('YYYY-MM-DD'))
            } else {
                setTempFromDate(moment().startOf(dateType.key).toDate())
                setTempToDate(moment().endOf(dateType.key).toDate())
                setFromDate(moment().startOf(dateType.key).format('YYYY-MM-DD'))
                setToDate(moment().endOf(dateType.key).format('YYYY-MM-DD'))
            }
        }
    }

    React.useEffect(() => {
        showOverlayLoading(loading)
    }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

    const DownloadToCSV = () => {
        getCSVData()
        setReadyDownload(true)
    }

    const CSVDownload = () => {
        return (
            <>
                <Button color="primary" onClick={DownloadToCSV} style={{ width: 165 }}>{t('  Download (CSV)')}{readyDownload && <i className="bx bx-hourglass bx-spin mr-3"></i>}</Button>
                {/* <div className="btn btn-primary btn-sm mb-3 mr-2" onClick={DownloadToCSV}>

                </div> */}
                <CSVLink
                    ref={csvLink}
                    filename={'sales-summary-report.csv'}
                    className="hidden"
                    data={csvData ? _.map(csvData).map(item => {
                        return {
                            ...item,
                            ...{
                                periodType: moment(item.period).format(dateDisplay)
                            }
                        }
                    }) : []}
                    headers={[
                        { label: periodType.charAt(0).toUpperCase() + periodType.slice(1), key: 'periodType' },
                        { label: 'Total Deposit Amount', key: 'totalDepositAmount' },
                        { label: 'Total Deposit Count', key: 'totalDepositCount' },
                        { label: 'Total Withdrawal Amount', key: 'totalWithdrawalAmount' },
                        { label: 'Total Withdrawal Count', key: 'totalWithdrawalCount' },
                        { label: 'Total Bonus Amount', key: 'totalBonusAmount' },
                        { label: 'Total New Register Credit Amount', key: 'totalNewRegisterCreditAmount' },
                        { label: 'Total Free Credit Amount', key: 'totalFreeCreditAmount' },
                        { label: 'Total Rebate Amount', key: 'totalRebateAmount' },
                        { label: 'Total Referral Amount', key: 'totalReferralAmount' },
                        { label: 'Total Reward Amount', key: 'totalRewardAmount' },
                        { label: 'Total Commission Amount', key: 'totalCommissionAmount' },
                        { label: 'Total Daily Attendance Amount', key: 'totalDailyAttendanceAmount' },
                        { label: 'Total Weekly Attendance Amount', key: 'totalWeeklyAttendanceAmount' },
                        { label: 'Total Increment Amount', key: 'totalIncrementAmount' },
                        { label: 'Total Decrement Amount', key: 'totalDecrementAmount' },
                        { label: 'Average Deposit Amount', key: 'averageDepositAmount' },
                        { label: 'Highest Deposit Amount', key: 'highestDepositAmount' },
                        { label: 'Average Withdrawal Amount', key: 'averageWithdrawalAmount' },
                        { label: 'Highest Withdrawal Amount', key: 'highestWithdrawalAmount' },
                        { label: 'Total Credit In', key: 'totalCreditIn' },
                        { label: 'Total Credit Out', key: 'totalCreditOut' },
                        { label: 'Net Winning', key: 'netWinning' },
                        { label: 'Active Player Count', key: 'activePlayerCount' },
                        { label: 'First Depositor Count', key: 'firstDepositorCount' },
                        { label: 'Accumulated Player Count', key: 'accumulatedPlayerCount' },
                        { label: 'Gross Profit', key: 'grossProfit' }
                    ]}
                >
                </CSVLink>
            </>
        )
    }

    React.useEffect(() => {
        if (csvTempData && readyDownload) {
            setCSVData((prevData) => [...prevData, ...csvTempData.salesSummaryReport])
        }
    }, [csvTempData])

    React.useEffect(() => {
        if (csvData.length > 0) {
            console.log(csvData)
            if (csvData.length < _.first(csvData).totalCount) {
                getCSVData({
                    variables: {
                        periodType: periodType,
                        fromDate: fromDate,
                        toDate: toDate,
                        orderBy: orderBy,
                        orderDirection: orderDirection,
                        skip: csvData.length,
                        take: take
                    }
                })
            } else if (csvData.length > 0 && csvLink && readyDownload) {
                csvLink.current.link.click()
                setReadyDownload(false)
                setCSVData([])
            }
        }
    }, [csvData])

    return (
        <React.Fragment>
            <div className="page-content report">
                <Card>
                    <CardBody>
                        <AvForm onValidSubmit={(event, values) => {
                            history.push(`/report/${periodType}`);
                            setFromDate(values.fromDate ? moment(values.fromDate).startOf('day').format('YYYY-MM-DD') : null)
                            setToDate(values.toDate ? moment(values.toDate).startOf('day').format('YYYY-MM-DD') : null)
                        }}>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <AvField
                                                value={tempFromDate}
                                                selected={tempFromDate}
                                                label="From Date"
                                                name="fromDate"
                                                type="text"
                                                placeholderText="Enter From Date"
                                                errorMessage="Enter From Date"
                                                selectsStart
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                tag={[Input, DatePicker]}
                                                onChange={date => {
                                                    setTempFromDate(date)
                                                }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <AvField
                                                value={tempToDate}
                                                selected={tempToDate}
                                                label="To Date"
                                                name="toDate"
                                                type="text"
                                                placeholderText="Enter To Date"
                                                errorMessage="Enter To Date"
                                                selectsStart
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                tag={[Input, DatePicker]}
                                                minDate={tempFromDate}
                                                onChange={date => {
                                                    setTempToDate(date)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="action-container" xs={12} sm={6} md={6} lg={6}>
                                    <div className="mb-3">
                                        <Button color="primary" type="submit">{t('Submit')}</Button>
                                    </div>
                                    {
                                        meData &&
                                        (meData.me.role === 'Developer' || meData.me.role === 'Admin') &&
                                        <div className="ml-2">
                                            <CSVDownload />
                                        </div>
                                    }
                                </Col>
                            </Row>

                            <Row>
                                <div className="button-container ml-3">
                                    <div className="mb-2 mr-2 font-weight-medium">{'Period Range: '}</div>
                                    {
                                        dateArr.map((type, index) =>
                                            <button
                                                className={
                                                    classnames({
                                                        "btn": true,
                                                        "mr-2": true,
                                                        "mb-2": true,
                                                        "btn-outline-primary": type.name !== dateType.name,
                                                        "btn-primary": type.name === dateType.name
                                                    })
                                                }
                                                key={index}
                                                onClick={() => {
                                                    setDateType(type)
                                                    SetDates(type)
                                                }}
                                            >{type.name}</button>
                                        )
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className="button-container ml-3">
                                    <div className="mb-2 mr-2 font-weight-medium">{'Period Type: '}</div>
                                    {
                                        rangeArr.map(({ name, key }, index) =>
                                            <button
                                                className={
                                                    classnames({
                                                        "btn": true,
                                                        "mr-2": true,
                                                        "mb-2": true,
                                                        "btn-outline-primary": key !== periodType,
                                                        "btn-primary": key === periodType
                                                    })
                                                }
                                                key={index}
                                                onClick={() => {
                                                    SetDates(dateType)

                                                    if (key) {
                                                        setPeriodType(key)
                                                    }
                                                }}
                                            >{name}</button>
                                        )
                                    }
                                </div>
                            </Row>
                        </AvForm>

                        <Row>
                            <Col sm="12">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th colSpan="2" className="green">Deposits</th>
                                                <th colSpan="2" className="red">Withdrawals</th>
                                                <th colSpan="9" className="warning">Extra</th>
                                                <th colSpan="2" className="adjust">Adjustment</th>
                                                {/* <th colSpan="3" className="">Profit</th> */}
                                                {
                                                    meData &&
                                                    (!OperatingUsers.includes(meData.me.role) &&
                                                        !Operator.includes(meData.me.role)) &&
                                                    <>
                                                        <th colSpan="2" className="green">Average Deposits</th>
                                                        <th colSpan="2" className="red">Average Withdrawals</th>
                                                        <th colSpan="3" className="warning">Credit</th>
                                                    </>
                                                }
                                                <th colSpan="3">Players</th>
                                                <th colSpan="1" className="">Profit</th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <Header name={periodType} className="text-capitalize" type='period' />
                                                <Header name='Total' className="green" type='totalDepositAmount' />
                                                <Header name='Num' className="green" type='totalDepositCount' />
                                                <Header name='Total' className="red" type='totalWithdrawalAmount' />
                                                <Header name='Num' className="red" type='totalWithdrawalCount' />
                                                <Header name='Bonus' className="warning" type='totalBonusAmount' />
                                                <Header name='New Register Credit' className="warning" type='totalNewRegisterCreditAmount' />
                                                <Header name='Promotion' className="warning" type='totalFreeCreditAmount' />
                                                <Header name='Rebate' className="warning" type='totalRebateAmount' />
                                                <Header name='Referral' className="warning" type='totalReferralAmount' />
                                                <Header name='Reward' className="warning" type='totalRewardAmount' />
                                                <Header name='Comission' className="warning" type='totalCommissionAmount' />
                                                <Header name='Daily Attendance' className="warning" type='totalDailyAttendanceAmount' />
                                                <Header name='Weekly Attendance' className="warning" type='totalWeeklyAttendanceAmount' />
                                                <Header name='Increment' className="green" type='totalIncrementAmount' />
                                                <Header name='Decrement' className="red" type='totalDecrementAmount' />
                                                {/* <Header name='Gross' className="" type='grossProfit' />
                                                <Header name='Net' className="" type='netProfit' />
                                                <Header name='Adjusted' className="" type='adjustedProfit' /> */}
                                                {
                                                    meData &&
                                                    (!OperatingUsers.includes(meData.me.role) &&
                                                        !Operator.includes(meData.me.role)) &&
                                                    <>
                                                        <Header name='Average' className="green" type='averageDepositAmount' />
                                                        <Header name='Highest' className="green" type='highestDepositAmount' />
                                                        <Header name='Average' className="red" type='averageWithdrawalAmount' />
                                                        <Header name='Highest' className="red" type='highestWithdrawalAmount' />
                                                        <Header name='In' className="green" type='totalCreditIn' />
                                                        <Header name='Out' className="red" type='totalCreditOut' />
                                                        <Header name='Win' className="warning" type='netWinning' />
                                                    </>
                                                }
                                                <Header name='Active' type='activePlayerCount' />
                                                <Header name='First Deposit' type='firstDepositorCount' />
                                                <Header name='Accumulated' type='accumulatedPlayerCount' />
                                                <Header name='Gross' className="" type='grossProfit' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data &&
                                                data.salesSummaryReport.length > 0 &&
                                                data.salesSummaryReport.map((report, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th className={
                                                                classnames({
                                                                    month: true,
                                                                    pointer: periodType !== 'day'
                                                                })
                                                            } onClick={() => {
                                                                if (periodType === 'year') {
                                                                    setTempFromDate(moment(report.period).startOf('year').toDate())
                                                                    setTempToDate(moment(report.period).endOf('year').toDate())
                                                                    setFromDate(moment(report.period).startOf('year').format('YYYY-MM-DD'))
                                                                    setToDate(moment(report.period).endOf('year').format('YYYY-MM-DD'))
                                                                } else if (periodType === 'month') {
                                                                    setTempFromDate(moment(report.period).startOf('month').toDate())
                                                                    setTempToDate(moment(report.period).endOf('month').toDate())
                                                                    setFromDate(moment(report.period).startOf('month').format('YYYY-MM-DD'))
                                                                    setToDate(moment(report.period).endOf('month').format('YYYY-MM-DD'))
                                                                }

                                                                if (periodType === 'year') {
                                                                    history.push(`/report/month`);
                                                                    setPeriodType('month')
                                                                } else if (periodType === 'month') {
                                                                    setPeriodType('day')
                                                                    history.push(`/report/day`);
                                                                }
                                                            }}>{moment(report.period).format(dateDisplay)}{periodType === 'day' ? ` (${moment(report.period).format('ddd')})` : ''}</th>
                                                            <th className="align-left">
                                                                <div className="green mr-1">{Formatter(report.totalDepositAmount)}</div>
                                                                {
                                                                    report.diffDepositPercentage >= 0 &&
                                                                    <div className="green">{`(+${parseFloat(report.diffDepositPercentage).toFixed(2)}%)`}</div>
                                                                }
                                                                {
                                                                    report.diffDepositPercentage < 0 &&
                                                                    <div className="red">{`(${parseFloat(report.diffDepositPercentage).toFixed(2)}%)`}</div>
                                                                }
                                                            </th>
                                                            <th className="green">{report.totalDepositCount}</th>
                                                            <th className="red">{Formatter(report.totalWithdrawalAmount)}</th>
                                                            <th className="red">{report.totalWithdrawalCount}</th>
                                                            <th className="warning">{Formatter(report.totalBonusAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalNewRegisterCreditAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalFreeCreditAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalRebateAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalReferralAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalRewardAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalCommissionAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalDailyAttendanceAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalWeeklyAttendanceAmount)}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.totalIncrementAmount >= 0,
                                                                        red: report.totalIncrementAmount < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.totalIncrementAmount)}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        red: true
                                                                    })
                                                                }
                                                            >{Formatter(report.totalDecrementAmount)}</th>

                                                            {
                                                                meData &&
                                                                (!OperatingUsers.includes(meData.me.role) &&
                                                                    !Operator.includes(meData.me.role)) &&
                                                                <>
                                                                    <th className="green">{Formatter(report.averageDepositAmount)}</th>
                                                                    <th className="green">{Formatter(report.highestDepositAmount)}</th>
                                                                    <th className="red">{Formatter(report.averageWithdrawalAmount)}</th>
                                                                    <th className="red">{Formatter(report.highestWithdrawalAmount)}</th>
                                                                    <th className="green">{Formatter(report.totalCreditIn)}</th>
                                                                    <th className="red">{Formatter(report.totalCreditOut)}</th>
                                                                    <th
                                                                        className={
                                                                            classnames({
                                                                                green: report.netWinning >= 0,
                                                                                red: report.netWinning < 0
                                                                            })
                                                                        }
                                                                    >{Formatter(report.netWinning)}</th>
                                                                </>
                                                            }
                                                            <th>{report.activePlayerCount}</th>
                                                            <th>{report.firstDepositorCount}</th>
                                                            <th>{`${report.accumulatedPlayerCount} (+${report.newPlayerCount})`}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.grossProfit >= 0,
                                                                        red: report.grossProfit < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.grossProfit)}</th>
                                                            {/* <th
                                                                className={
                                                                    classnames({
                                                                        green: report.netProfit >= 0,
                                                                        red: report.netProfit < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.netProfit)}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.adjustedProfit >= 0,
                                                                        red: report.adjustedProfit < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.adjustedProfit)}</th> */}
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                data &&
                                                data.salesSummaryReport.length === 0 &&
                                                <tr className="text-center pt-3">
                                                    <td colSpan={25}>
                                                        {t('No Report Found')}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        {
                            data &&
                            data.salesSummaryReport.length > 0 &&
                            <>
                                <div className="align-center p-2">
                                    <div></div>
                                    <Pagination count={Math.ceil(_.first(data.salesSummaryReport).totalCount / take)} color="primary" page={page} onChange={(event, value) => {
                                        // skip = (value - 1) * take
                                        setPage(value)

                                        getReport({
                                            variables: {
                                                periodType: periodType,
                                                fromDate: fromDate,
                                                toDate: toDate,
                                                orderBy: orderBy,
                                                orderDirection: orderDirection,
                                                skip: (value - 1) * take,
                                                take: take
                                            },
                                            fetchPolicy: 'network-only'
                                        })
                                    }} />
                                </div>
                            </>
                        }
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(withTranslation()(Report));
import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { RESET_PASSWORD } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';

export default function ResetPassword(props) {

    const { modal, toggleModal, user } = props;

    const [updateOneUser] = useMutation(RESET_PASSWORD);

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                {
                    user &&
                    <div className="modal-content reset-password-page">
                        <ModalHeader toggle={toggleModal}>
                            Change Password
                        </ModalHeader>
                        <ModalBody>
                            <div id="reset-password-wizard" className="twitter-bs-wizard">
                                <AvForm className="needs-validation" onValidSubmit={(event, values) => {
                                    updateOneUser({
                                        variables: {
                                            input: {
                                                password: values.password,
                                            },
                                            id: user.id
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })
                                }}>
                                    <Row>
                                        <Col lg="6">
                                            <AvField
                                                value={user.username}
                                                label="Username"
                                                name="username"
                                                type="text"
                                                disabled
                                                placeholder="Enter Username"
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <AvField
                                                label="Password"
                                                name="password"
                                                type="password"
                                                placeholder="Enter Password"
                                                errorMessage="Enter password"
                                                validate={{
                                                    required: { value: true },
                                                    minLength: { value: 6, errorMessage: 'Your password must be between 6 and 13 characters' },
                                                    maxLength: { value: 13, errorMessage: 'Your password must be between 6 and 13 characters' }
                                                }}
                                            />
                                        </Col>
                                        <Col lg="6">
                                            <AvField
                                                label="Verify Password"
                                                name="verify-password"
                                                type="password"
                                                placeholder="Enter Verify Password"
                                                errorMessage="Enter Re-password"
                                                validate={{
                                                    required: { value: true },
                                                    match: { value: "password" }
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                </AvForm>
                            </div>
                        </ModalBody>
                    </div>
                }
            </Modal>
        </React.Fragment>
    );
}
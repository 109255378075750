import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const APPROVE_ONE_WITHDRAWAL = gql`
    mutation(
        $id: String!
    ) {
        approveOneWithdrawal (id: $id) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import React, { Fragment } from 'react';
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Label,
    FormGroup
} from "reactstrap";
import { CREATE_ONE_KIOSK } from '../../graphql/mutation/CREATE_ONE_KIOSK';
import { SET_KIOSK_DEFAULT } from '../../graphql/mutation/SET_KIOSK_DEFAULT'
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import _ from "lodash"
import { GET_PLATFORMS } from '../../graphql/query/GET_PLATFORMS';


export default function NewKioksAccount(props) {

    const { modal, toggleModal, curPlatform } = props;
    const [platform, setPlatform] = React.useState();

    React.useEffect(() => {
        if (curPlatform) {

            setPlatform(curPlatform);
        }

    }, [curPlatform])

    const [createOneKioskAccount] = useMutation(CREATE_ONE_KIOSK);
    const [setKioskDefault] = useMutation(SET_KIOSK_DEFAULT);




    const [isValidPlatform, setValidPlatform] = React.useState(true);
    const [searchPlatform, setSearchPlatform] = React.useState([]);
    const [search, setSearch] = React.useState('');

    const { data, isLoading } = useQuery(GET_PLATFORMS, {
        variables: {
            filter: { name: { iLike: `%${search}%` } }
        }
    })

    const handlePlatformSearch = (value) => {
        setSearch(value)
    }


    const checkValidity = (value) => {
        if (!curPlatform) {
            return isValidPlatform
        } else {
            return true;
        }
    }



    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-bank-page">
                <ModalHeader toggle={toggleModal}>
                    Add New Kiosk Account
                </ModalHeader>
                <ModalBody>
                    <div id="new-bank-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {

                                if (!checkValidity()) {
                                    return;
                                }

                                createOneKioskAccount({

                                    variables: {
                                        input: {
                                            "kioskAccount": {
                                                "username": values.login,
                                                "password": values.password,
                                                "secondPassword": values.secondPassword,
                                                "platformId": curPlatform ? platform.id : searchPlatform[0].node.id

                                            }
                                        }
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).then(val => {
                                    if (val) {
                                        console.log(val)
                                        if (_.get(val, "data.createOneKioskAccount.id")) {
                                            setKioskDefault({ variables: { input: _.get(val, "data.createOneKioskAccount.id") } }).catch(e => {
                                                ToastHelper.toggleToast({
                                                    toastType: "error",
                                                    message: e,
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            }).then(() => {
                                                if (val) {
                                                    console.log(val)
                                                    toggleModal();
                                                }
                                            })
                                        }


                                    }
                                })
                            }}
                            onInvalidSubmit={() => {

                            }}
                        >
                            <Row>
                                <Col lg="12">
                                    <AvField
                                        label="Kiosk Login"
                                        name="login"
                                        type="text"
                                        placeholder="Enter Kiosk Login"
                                        errorMessage="Enter Kiosk Login"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <AvField
                                        label="Password"
                                        name="password"
                                        type="text"
                                        placeholder="Enter Password"
                                        errorMessage="Enter Password"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />

                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="Second Password"
                                        name="secondPassword"
                                        type="text"
                                        placeholder="Enter Second Password"
                                        errorMessage="Enter Second Password"
                                        validate={{
                                            required: { value: false },
                                        }}
                                    />

                                </Col>
                            </Row>
                            <Row>
                                {curPlatform && <Col lg="6">
                                    <AvField
                                        label="Platform"
                                        name="Platform"
                                        type="text"
                                        disabled={true}
                                        value={curPlatform.displayName}
                                        placeholder=""
                                        errorMessage=""
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>}

                                {!curPlatform && <Col lg="6">
                                    <FormGroup className={`${!isValidPlatform ? 'text-danger' : ''}`}>
                                        <Label for="newrole-input">{"Platform"}</Label>

                                        <AsyncTypeahead
                                            id="platform-list"
                                            isLoading={isLoading}
                                            labelKey={option => `${option.node.name}`}
                                            minLength={0}
                                            selected={searchPlatform}
                                            onSearch={handlePlatformSearch}
                                            onInputChange={(val) => {
                                                if (val === "") {
                                                    handlePlatformSearch('')
                                                }
                                            }}
                                            options={data ? data.platforms.edges : []}
                                            placeholder={'Search for Platform'}
                                            useCache={false}
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <span>{option.node.name}</span>
                                                </Fragment>
                                            )}
                                            errorMessage={'Enter Platform Name'}
                                            isInvalid={!isValidPlatform}
                                            onChange={(val) => {
                                                setValidPlatform(val.length > 0 ? true : false)
                                                setSearchPlatform(val)

                                            }}
                                            onBlur={(e) => {
                                                if (isValidPlatform && searchPlatform.length === 0) {
                                                    setValidPlatform(false)
                                                }
                                            }}
                                        />

                                    </FormGroup>
                                </Col>}

                            </Row>


                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
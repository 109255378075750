import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Table, Input, FormGroup } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { useLazyQuery } from 'react-apollo';

import moment from 'moment';
import { GET_TRANSACTIONS } from '../../../graphql/query/GET_TRANSACTIONS';
import PlayerGameAccountItem from './game-accounts-item';

function RecentTransaction(props) {

    let first = 10;
    const { t, player, isActive, onApprove, transactionType } = props;

    const [cutOffDate, setCutOffDate] = React.useState(null)
    const [transactionNumber, setTransactionNumber] = React.useState(null)

    const [runTransactions, { data: transactionData, loading }] = useLazyQuery(GET_TRANSACTIONS, {
        variables: {
            paging: {
                first: transactionNumber ? parseInt(transactionNumber) < first ? parseInt(transactionNumber) : first : first
            },
            sorting: {
                field: 'createdAt',
                direction: 'DESC'
            }
        },
        fetchPolicy: 'network-only'
    })

    React.useEffect(() => {
        if (isActive) {
            runTransactions({
                variables: {
                    filter: {
                        playerId: {
                            eq: player.id
                        },
                        transactionType: { in: transactionType }
                    },
                    sorting: {
                        field: "createdAt",
                        direction: "DESC"
                    }
                }
            })
        }
    }, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="player-container">
                <Row>
                    <Col><div className="font-weight-medium font-size-14 pb-3">10 LATEST TRANSACTIONS</div></Col>
                </Row>
                <AvForm>
                    <Row className="search-container">
                        <Col xs={12} sm={12} md={4} lg={5}>
                            <AvField
                                selected={cutOffDate}
                                label="Cutoff Timestamp"
                                name={`${transactionType}-dateTime`}
                                type="text"
                                placeholderText="Cutoff Timestamp"
                                errorMessage="Enter Cutoff Timestamp"
                                onChange={date => {
                                    setCutOffDate(date)
                                }}
                                popperPlacement="top"
                                selectsStart
                                maxDate={new Date()}
                                dateFormat="yyyy-MM-dd"
                                tag={[Input, DatePicker]}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <AvField
                                value={transactionNumber}
                                name={`${transactionType}-transaction`}
                                label={t('Number of Transactions')}
                                placeholder={t('Number of Transactions')}
                                type="text"
                                errorMessage={t('Enter Account Number')}
                                validate={{
                                }}
                                onChange={(e) => setTransactionNumber(e.target.value)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={3}>
                            <FormGroup>
                                <button type="button" className="btn btn-primary waves-effect waves-light action-button mt-2" onClick={() => {
                                    let filter = {
                                        playerId: {
                                            eq: player.id
                                        },
                                        transactionType: { in: transactionType },
                                        createdAt: {
                                            lte: moment(cutOffDate).format('YYYY-MM-DD 23:59:59')
                                        }
                                    }

                                    if (!cutOffDate) {
                                        delete filter.createdAt
                                    }

                                    runTransactions({
                                        variables: {
                                            filter,
                                            sorting: {
                                                field: "createdAt",
                                                direction: "DESC"
                                            }
                                        }
                                    })
                                }}>
                                    <i className="bx bx-search font-size-15 align-middle"></i> Load Transactions
                                </button>
                            </FormGroup>
                        </Col>
                    </Row>
                </AvForm>
                <Row className="pt-3">
                    <Col sm="12">
                        <div className="table-responsive">
                            <Table className="table mb-0">
                                <thead>
                                    <tr className="font-size-12">
                                        <th>Created At</th>
                                        <th>Type</th>
                                        {
                                            (transactionType === 'Deposit' || transactionType === 'Withdrawal') &&
                                            <>
                                                <th>Amount</th>
                                                <th>Bonus</th>
                                            </>
                                        }
                                        {
                                            (transactionType === 'CreditIn' || transactionType === 'CreditOut') &&
                                            <>
                                                <th>Quantity</th>
                                                <th>Unit Price</th>
                                            </>
                                        }
                                        <th>Total Amount</th>
                                        <th>Movement</th>
                                        <th>Remark</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {
                                        transactionData &&
                                        transactionData.transactions.edges.length > 0 &&
                                        <>
                                            {
                                                transactionData.transactions.edges.map((transaction, index) => {
                                                    return (
                                                        <PlayerGameAccountItem
                                                            key={index}
                                                            transaction={transaction}
                                                            onApprove={onApprove}
                                                        />
                                                    )
                                                })
                                            }
                                            <tr className="text-center pt-3">
                                                <td colSpan={12}>
                                                    {
                                                        transactionType === 'Deposit' &&
                                                        <Link to={{
                                                            pathname: `/transaction-deposit/all`,
                                                            state: { "serialNo": player.username }
                                                        }} className="text-success"><div>View All</div></Link>
                                                    }
                                                    {
                                                        transactionType === 'Withdrawal' &&
                                                        <Link to={{
                                                            pathname: `/transaction-withdraw/all`,
                                                            state: { "serialNo": player.username }
                                                        }} className="text-success"><div>View All</div></Link>
                                                    }
                                                    {
                                                        transactionType === ["Bonus", "FreeCredit"] &&
                                                        <Link to={{
                                                            pathname: `/transaction-promotion/all`,
                                                            state: { "serialNo": player.username }
                                                        }} className="text-success"><div>View All</div></Link>
                                                    }
                                                    {
                                                        (transactionType === "CreditIn" || transactionType === "CreditOut") &&
                                                        <Link to={{
                                                            pathname: `/transaction-transfer/all`,
                                                            state: { "serialNo": player.username }
                                                        }} className="text-success"><div>View All</div></Link>
                                                    }
                                                </td>
                                            </tr>
                                        </>
                                    }
                                    {
                                        transactionData &&
                                        transactionData.transactions.edges.length === 0 &&
                                        <tr className="text-center pt-3">
                                            <td colSpan={12}>
                                                {t('No Transactions Found')}
                                            </td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
                <div className="load-more-container">
                    {
                        (loading) &&
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                            </div>
                        </Col>
                    }
                </div>
            </div>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(RecentTransaction));
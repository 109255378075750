import React, { useRef } from 'react';
import { Container, Card, CardBody, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import 'react-medium-image-zoom/dist/styles.css'
import { GET_CURRENCY } from '../../graphql/query/GET_CURRENCY';
import NewCurrency from './new-currency';
import EditCurrency from './edit-currency';
import CurrencyItem from './currency-item';

function CurrencyList(props) {
    const [addCurrency, setAddCurrency] = React.useState(false)
    const [editCurrency, setEditCurrency] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [currencyItem, setCurrencyItem] = React.useState(null)
    const [filter, setFilter] = React.useState({})

    const onEdit = (currency) => {
        setCurrencyItem(currency)
        setEditCurrency(true)
    }

    const { data: currencyData, refetch } = useQuery(GET_CURRENCY, {
        variables: {
            filter: {
                ...filter
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {
        UpdateFilter()
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    const UpdateFilter = () => {
        let filter = { name: { iLike: `%${search}%` } }

        if (search === '') {
            delete filter["name"]
        }

        setFilter(filter)
    }

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content currency-page">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Developer Settings" breadcrumbItem="Currency" />
                    <div className="search-bar-container">
                        <div className="button-add-bonus-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="button-add-currency-container">
                            <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus ml-2" onClick={() => { setAddCurrency(true) }}>
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Currency
                            </button>
                        </div>
                    </div>
                    {
                        <Card className="w-100">
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '15%' }}>Code</th>
                                                <th style={{ width: '15%' }}>Number</th>
                                                <th style={{ width: '45%' }}>Name</th>
                                                <th style={{ width: '15%' }}>Symbol</th>
                                                <th style={{ width: '10%' }}>Active?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                currencyData &&
                                                currencyData.currencies.edges.length > 0 &&
                                                currencyData.currencies.edges.map((currency, key) =>
                                                    <tr key={"_currency" + key}>
                                                        <CurrencyItem
                                                            onEdit={onEdit}
                                                            currency={currency}
                                                        />
                                                    </tr >
                                                )
                                            }
                                            {
                                                currencyData &&
                                                currencyData.currencies.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-currency pt-0">No Currency Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    }
                    {
                        addCurrency &&
                        <NewCurrency
                            modal={addCurrency}
                            toggleModal={() => {
                                refetch()
                                setAddCurrency(!addCurrency)
                            }}
                        />
                    }
                    {
                        editCurrency &&
                        <EditCurrency
                            modal={editCurrency}
                            toggleModal={() => {
                                setEditCurrency(!editCurrency)
                            }}
                            currency={currencyItem}
                        />
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(CurrencyList));

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import Switch from "react-switch";
import {
    DropdownToggle,
    ButtonDropdown
} from "reactstrap";
import classnames from 'classnames';
import moment from 'moment';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';
class KioskItem extends Component {

    state = {
        drp_link: false
    }

    render() {
        const { kiosk, dropDown, onEdit, onToggleActive, showLoginDetails, goToPlatform, onSync, meData } = this.props;

        const Offsymbol = props => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#fff",
                        paddingRight: 2
                    }}
                >
                    {props.text}
                </div>
            );
        };

        const OnSymbol = props => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 12,
                        color: "#fff",
                        paddingRight: 2
                    }}
                >
                    {props.text}
                </div>
            );
        };
        return <React.Fragment>
            <Col xl="4" sm="6" lg="4">
                <Card className={classnames({ "kiosk-disabled": !kiosk.node.isDefault })}>
                    <CardBody className="">


                        {kiosk.node.platform && <Row>
                            <Col className="text-align pb-2 cursor-pointer " onClick={goToPlatform} >
                                <div>
                                    <div className="header">Platform</div>
                                    <div className="font-size-14"><Link onClick={goToPlatform} className="text-dark">{kiosk.node.platform.displayName}</Link></div>
                                </div>
                            </Col>
                        </Row>}
                        <Row>
                            <Col className="text-align pb-2 cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">USERNAME</div>
                                    <div className="font-size-14"><Link onClick={onEdit} className="text-dark">{kiosk.node.username}</Link></div>
                                </div>
                            </Col>

                        </Row>
                        {showLoginDetails && <Row>
                            <Col className="text-align pb-2 cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">PASSWORD</div>
                                    <div className="font-size-14"><Link onClick={onEdit} className="text-dark">{kiosk.node.password}</Link></div>
                                </div>
                            </Col>
                        </Row>}
                        {showLoginDetails && <Row>
                            <Col className="text-align pb-2 cursor-pointer " onClick={onEdit}>
                                <div>
                                    <div className="header">PASSWORD</div>
                                    <div className="font-size-14"><Link onClick={onEdit} className="text-dark">{kiosk.node.secondPassword}</Link></div>
                                </div>
                            </Col>
                        </Row>}


                        <Row>
                            <Col className="text-align pb-2">
                                <div>
                                    <div className="header">Balance</div>
                                    <div className="font-size-14"><Link onClick={onEdit} className="text-dark">{!kiosk.isLoading && <div>{kiosk.node.balance != null ? kiosk.node.balance : "-"}</div>}
                                        {kiosk.isLoading && <div className="p-1">{<div className="spinner-border text-dark m-1" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>}</div>}</Link></div>
                                </div>
                            </Col>
                        </Row>

                        {/* <Row>
                            <Col className="text-align pb-2">
                                <div>
                                    <div className="header">Default Kiosk Account</div>
                                    <div className="font-size-14"><Link onClick={onEdit} className="text-dark">{kiosk.node.isDefault ? "YES" : "NO"}</Link></div>
                                </div>
                            </Col>
                        </Row> */}


                        {
                            meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) &&
                            <>
                                <Row>
                                    <Col className="text-align pb-2">
                                        <div>
                                            <div className="header">Active?</div>
                                            <Switch
                                                readOnly={true}
                                                uncheckedIcon={<Offsymbol text="NO" />}
                                                checkedIcon={<OnSymbol text="YES" />}
                                                className="mr-1"
                                                onColor="#34c38f"
                                                offColor="#f46a6a"
                                                onChange={() => {
                                                    onToggleActive()
                                                }}
                                                checked={kiosk.node.isActive}
                                            />
                                            {/* <div className="custom-control custom-switch mb-2  cursor-pointer" dir="ltr" onClick={(e) => {
                                        onToggleActive(e);
                                    }} >
                                        <input type="checkbox" className="custom-control-input cursor-pointer" id="customSwitch2" checked={kiosk.node.isActive} onChange={() => { }} />
                                        <label className="custom-control-label" htmlFor="customSwitch2">{kiosk.node.isActive ? "ON" : "OFF"}</label>
                                    </div> */}


                                            {/* <div className="font-size-14"><Link onClick={onEdit} className="text-dark">{kiosk.node.isActive ? "YES" : "NO"}</Link></div> */}
                                        </div>
                                    </Col>
                                </Row>


                                <Row>
                                    <Col className="text-align pb-2">
                                        <div>
                                            <div className="header">Maintenance Mode</div>

                                            <Switch
                                                readOnly={true}
                                                disabled={true}
                                                uncheckedIcon={<Offsymbol text="OFF" />}
                                                checkedIcon={<OnSymbol text="ON" />}
                                                className="mr-1"
                                                onColor="#f46a6a"
                                                offColor="#34c38f"
                                                onChange={() => { }
                                                }
                                                checked={kiosk.node.maintenanceMode}
                                            />
                                            {/* <div className="custom-control custom-switch mb-2" dir="ltr">
                                        <input type="checkbox" className="custom-control-input " id="customSwitch1" checked={kiosk.node.maintenanceMode} readOnly disabled/>
                                        <label className="custom-control-label" htmlFor="customSwitch1" onClick={(e) => { }}>{kiosk.node.maintenanceMode?"ON":"OFF"}</label>
                                    </div> */}

                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }

                        <Row>
                            <Col className="text-align pb-2">
                                <div>

                                    <div className="header">Last Sync At</div>
                                    <div className="d-flex">
                                        <div className="font-size-14 px-1"><Link onClick={onEdit} className="text-dark">{kiosk.node.lastSyncAt ? moment(kiosk.node.lastSyncAt).calendar() : "-"}</Link></div>
                                        <div>
                                            <button type="button" className="py-0 btn btn-primary waves-effect waves-light font-size-11 action-button" onClick={onSync}>
                                                <div className="">Sync</div>
                                            </button>

                                        </div>
                                    </div>
                                </div>


                            </Col>
                        </Row>


                        <Row>
                            <Col className="text-align pb-2">
                                <div>
                                    <div className="header">Message</div>
                                    <div className={
                                        classnames({
                                            "font-size-14": true,
                                            "text-info": kiosk.node.messageLabelType === 'Info',
                                            "text-success": kiosk.node.messageLabelType === 'Success',
                                            "text-danger": kiosk.node.messageLabelType === 'Error',
                                            "text-warning": kiosk.node.messageLabelType === 'Warning'
                                        })
                                    }><div onClick={onEdit} className={kiosk.node.message ? "font-size-14 text-danger" : "text-dark"}>{kiosk.node.message ? kiosk.node.message : "-"}</div></div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col className="dropdown-actions">
                                <ButtonDropdown
                                    isOpen={this.state.drp_link}
                                    toggle={() =>
                                        this.setState({ drp_link: !this.state.drp_link })
                                    }
                                >
                                    <DropdownToggle caret color="secondary">
                                        Actions <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    {dropDown}
                                </ButtonDropdown>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    }
}

export default KioskItem;
import React, { } from 'react';
import { Row, Col, Button, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { AvForm, AvField } from "availity-reactstrap-validation";
import NumberFormat from 'react-number-format';
//Import Breadcrumb
import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_BONUSES } from '../../../graphql/query/GET_BONUSES';
import * as moment from 'moment/moment';
import { GET_BANK_ACCOUNTS, CALCULATE_BONUS_AMOUNT } from '../../../graphql/query';
import { CALCULATE_REWARD_POINT, CREATE_ONE_DEPOSIT } from '../../../graphql/mutation';
import { GET_USER_WALLETS } from '../../../graphql/query/GET_USER_WALLETS';
import { APPROVE_ONE_DEPOSIT } from '../../../graphql/mutation/APPROVE_ONE_DEPOSIT';
import ToastHelper from '../../../helpers/ToastHelper';
import {
    showOverlayLoading
} from "../../../store/actions";
import DropZone from '../Components/drop-zone';
import Compressor from 'compressorjs';
import CloudinaryHelper from '../../../helpers/CloudinaryHelper';
import NewPlayerBankAccount from './new-bank-account';
import { GET_PAYMENT_TYPES } from '../../../graphql/query/GET_PAYMENT_TYPES';
import { GET_PLAYER_STAT } from '../../../graphql/query/GET_PLAYER_STAT';
import { CALCULATE_AGENT_POINT } from '../../../graphql/mutation/CALCULATE_AGENT_POINT';
import { UPDATE_ONE_TRANSACTION } from '../../../graphql/mutation/UPDATE_ONE_TRANSACTION';
import { GET_ONE_USER } from '../../../graphql/query/GET_ONE_USER';

let form;
let interval;

function BankerDeposit(props) {

    const { t, player, isActive, onDone, playerKey } = props;
    const [date, setDate] = React.useState(null)
    const [depositBonus, setDepositBonus] = React.useState(null)
    const [depositAmount, setDepositAmount] = React.useState(null)
    const [bonusAmount, setBonusAmount] = React.useState(null)
    const [rolloverAmount, setRolloverAmount] = React.useState(null)
    const [isDeposit, setIsDeposit] = React.useState(false)
    const [depositMethod, setDepositMethod] = React.useState("Bank")
    const [selectedFile, setSelectFile] = React.useState(null);
    const [addBankAccount, setAddBankAccount] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState(moment().format('YYYY-MM-DD HH:mm:ss'))
    const [transactionData, setTransactionData] = React.useState(null)

    const { data: bonusData } = useQuery(GET_BONUSES, {
        variables: {
            filter: {
                isActive: { is: true }
            },
            sorting: [{
                field: 'name',
                direction: 'ASC'
            }]
        },
        notifyOnNetworkStatusChange: true
    });

    const { data: paymentTypeData } = useQuery(GET_PAYMENT_TYPES, {
        variables: {
            filter: {
                isActive: { is: true }
            }
        }
    });

    const { data: dataStat } = useQuery(GET_PLAYER_STAT, {
        variables: {
            id: player.id
        },
        fetchPolicy: "network-only"
    })

    const { data: playerData } = useQuery(GET_ONE_USER, {
        variables: {
            id: player.id
        },
        fetchPolicy: "network-only"
    })

    const [getWallet, { data: walletData }] = useLazyQuery(GET_USER_WALLETS, {
        variables: {
            filter: {
                walletType: { eq: "Credit" },
                name: { eq: "Main" },
                user: { id: { eq: player.id } }
            },
        },
        fetchPolicy: 'network-only'
    });

    const [calculateBonusAmount, { data: bonusAmountData }] = useLazyQuery(CALCULATE_BONUS_AMOUNT, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const [refetchBankAccount] = useLazyQuery(GET_BANK_ACCOUNTS, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                playerId: { eq: player.id }
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    const { data: bankData } = useQuery(GET_BANK_ACCOUNTS, {
        variables: {
            filter:
            {
                accountHolderType: { eq: 'Company' },
                canDeposit: { is: true }
            },
        },
        notifyOnNetworkStatusChange: true
    })

    const { data: playerBankAccounts } = useQuery(GET_BANK_ACCOUNTS, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                playerId: { eq: player.id }
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    const [createOneDeposit, { loading: loadingCreate }] = useMutation(CREATE_ONE_DEPOSIT)
    const [approveOneDeposit, { loading: loadingApprove }] = useMutation(APPROVE_ONE_DEPOSIT)
    const [calculateRp] = useMutation(CALCULATE_REWARD_POINT)
    const [calculateAgentPoint] = useMutation(CALCULATE_AGENT_POINT)
    const [updateOneTransaction] = useMutation(UPDATE_ONE_TRANSACTION)

    React.useEffect(() => {
        if (bonusAmountData) {
            setBonusAmount(bonusAmountData.calculateBonus.bonusAmount)
            setRolloverAmount(bonusAmountData.calculateBonus.requiredRolloverAmount)
        }
    }, [bonusAmountData])

    React.useEffect(() => {
        if (isActive) {
            setDate(null)
            getWallet()
            interval = setInterval(() => {
                setCurrentTime(moment().format('YYYY-MM-DD HH:mm:ss'))
            }, 1000)
        } else {
            clearInterval(interval)
        }
    }, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    return (
        <React.Fragment>
            <div className="player-container">
                <div className="header-wrapper">
                    <div className="player-title">Deposit</div>
                </div>
            </div>
            <div className="p-2 banker-deposit-container">
                <div className="banker-deposit-wrapper">
                    <AvForm autoComplete="off" className="banker-deposit-content-info" ref={(ref) => form = ref} onValidSubmit={async (event, values) => {
                        if (transactionData) {
                            props.showOverlayLoading(true, "Loading")
                            await approveOneDeposit({
                                variables: {
                                    id: transactionData.id
                                }
                            })
                            if (onDone) {
                                onDone()
                            }
                            setDepositBonus(null)
                            setDepositAmount(null)
                            setBonusAmount(null)
                            setRolloverAmount(null)
                            setSelectFile(null)
                            setTransactionData(false)
                            setIsDeposit(false)
                            setDepositMethod("Bank")
                            props.showOverlayLoading(false)
                            return
                        }

                        if (isDeposit) {
                            return
                        }
                        setIsDeposit(true)

                        let result

                        props.showOverlayLoading(true, "Loading")

                        if (selectedFile) {
                            props.showOverlayLoading(true, "Uploading Receipt")
                            let imageData = await Compress(selectedFile)
                            result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=rjhrtxfi`)
                        }
                        props.showOverlayLoading(true, "Loading")
                        await createOneDeposit({
                            variables: {
                                input: {
                                    deposit: {
                                        amount: parseFloat(values.depositAmount.toString().replaceAll(',', '')),
                                        bonusAmount: values.bonusAmount ? parseFloat(values.bonusAmount) : 0,
                                        requiredRolloverAmount: values.rolloverAmount ? parseFloat(values.rolloverAmount) : parseFloat(values.depositAmount),
                                        paymentTypeId: values.method,
                                        playerBankAccountId: values.playerBankAccount,
                                        companyBankAccountId: values.toBankAccount,
                                        toWalletId: values.wallet,
                                        receiptUrl: selectedFile ? result.data.url : null,
                                        playerId: player.id,
                                        receiptDate: moment(values.dateAndTime).toISOString(),
                                        receiptReferenceNo: values.receiptId,
                                        bonusId: depositBonus ? depositBonus.node.id : null
                                    }
                                }
                            }
                        }).then(async (result) => {
                            setTransactionData(result.data.createOneDeposit)
                        }).catch((e) => {
                            ToastHelper.toggleToast({
                                toastType: "error",
                                message: e,
                                title: "",
                                onClick: function () { }
                            })
                        })

                        props.showOverlayLoading(false)
                    }}>
                        {
                            playerData &&
                            <Row>
                                <Col xs={12} sm={12} md={3} lg={3}>
                                    <AvField
                                        defaultValue={playerData.user.serialNo}
                                        label="Player"
                                        name="depositor"
                                        disabled
                                        type="text"
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={3} lg={3}>
                                    <AvField
                                        value={dataStat ? `${parseFloat(dataStat.playerStats.totalDepositAmount).toFixed(2)} (${parseFloat(dataStat.playerStats.totalWithdrawalAmount).toFixed(2)})` : '-'}
                                        label={`Player Deposit/Withdraw`}
                                        name="winloss"
                                        disabled
                                        type="text"
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={3} lg={3}>
                                    <AvField
                                        value={playerData.user.upline ? playerData.user.upline.username : '-'}
                                        label={'Upline'}
                                        name="upline-username"
                                        disabled
                                        type="text"
                                    />
                                </Col>
                                <Col xs={12} sm={6} md={3} lg={3}>
                                    <AvField
                                        defaultValue={moment().format('YYYY-MM-DD HH:mm:ss')}
                                        value={isActive && currentTime}
                                        label={'Transaction Created At'}
                                        name="timestamp"
                                        disabled
                                        type="text"
                                    />
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <AvField
                                    label="Enter Amount"
                                    name="depositAmount"
                                    type="text"
                                    placeholder="Enter Amount"
                                    errorMessage="Enter Amount"
                                    thousandSeparator
                                    decimalScale={2}
                                    fixedDecimalScale
                                    tag={[Input, NumberFormat]}
                                    onChange={(e) => {
                                        setDepositAmount(e.target.value.replace(/,/g, ''))
                                        if (depositBonus) {
                                            calculateBonusAmount({
                                                variables: {
                                                    depositAmount: parseFloat(e.target.value.replace(/,/g, '')),
                                                    bonusId: depositBonus.node.id
                                                },
                                            })
                                        }
                                    }}
                                    debounce={300}
                                    validate={{
                                        required: { value: true },
                                        pattern: {
                                            value: "^[0-9,.]+$",
                                            errorMessage: `${t('Enter Only Numbers')}`
                                        }
                                    }}
                                />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <AvField
                                    label="Deposit Via"
                                    name="method"
                                    type="select"
                                    className="custom-select"
                                    placeholder="Deposit Via"
                                    errorMessage="Deposit Via"
                                    value={paymentTypeData && paymentTypeData.paymentTypes.edges.length > 0 && paymentTypeData.paymentTypes.edges[0].node.id}
                                    onChange={(e) => {
                                        let data = _.find(paymentTypeData.paymentTypes.edges, ({ node }) => {
                                            return node.id === e.target.value
                                        })
                                        if (data) {
                                            setDepositMethod(data.node.code)
                                        }
                                    }}
                                >
                                    {
                                        paymentTypeData &&
                                        paymentTypeData.paymentTypes.edges.length &&
                                        paymentTypeData.paymentTypes.edges.map(({ node }, index) => {
                                            return (
                                                <option key={index} value={node.id}>{node.name}</option>
                                            )
                                        })
                                    }
                                </AvField>
                            </Col>
                            {
                                depositMethod === "Bank" &&
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <div className="align-bottom">
                                        <AvField
                                            label="Player Bank Accounts"
                                            name="playerBankAccount"
                                            type="select"
                                            className="custom-select"
                                            onChange={(e) => {

                                            }}
                                            value={playerBankAccounts && playerBankAccounts.bankAccounts.edges.length > 0 && playerBankAccounts.bankAccounts.edges[0].node.id}
                                        >
                                            {/* <option value={null}>{t('New Bank Account')}</option> */}
                                            {
                                                playerBankAccounts &&
                                                playerBankAccounts.bankAccounts.edges.map((bankAccount, index) => {
                                                    return (
                                                        <option key={index} value={bankAccount.node.id}>{`${bankAccount.node.bank.name} - ${bankAccount.node.accountName} - ${bankAccount.node.accountNo}`}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                        <div className="pl-2"></div>
                                        <Button type="button" color="primary" className="waves-light waves-effect mb-3" onClick={() => {
                                            setAddBankAccount(true)
                                        }}>
                                            <i className="bx bx-plus"></i>
                                        </Button>
                                    </div>
                                </Col>
                            }
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <AvField
                                    label="Bonus"
                                    name="depositBonus"
                                    type="select"
                                    className="custom-select"
                                    placeholder="Bonus"
                                    errorMessage="Bonus"
                                    defaultValue={null}
                                    onChange={(e) => {
                                        let bonus = _.find(bonusData.bonuses.edges, bonus => {
                                            return bonus.node.id === e.target.value
                                        })
                                        setDepositBonus(bonus)
                                        if (bonus && depositAmount) {
                                            calculateBonusAmount({
                                                variables: {
                                                    depositAmount: parseFloat(depositAmount),
                                                    bonusId: bonus.node.id
                                                },
                                            })
                                        }
                                    }}
                                >
                                    <option defaultValue value={null}>No Bonus</option>
                                    {
                                        bonusData &&
                                        bonusData.bonuses.edges.map((bonus, index) => {
                                            return (
                                                <option key={index} value={bonus.node.id}>{bonus.node.name}</option>
                                            )
                                        })
                                    }
                                </AvField>
                            </Col>
                            {
                                bankData &&
                                (depositMethod === "Bank" ||
                                    depositMethod === "ATM" ||
                                    depositMethod === "Telegram") &&
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <AvField
                                        label="Deposit To Company Bank Account"
                                        name="toBankAccount"
                                        type="select"
                                        className="custom-select"
                                        placeholder="Deposit To Company Bank Account"
                                        errorMessage="Deposit To Company Bank Account"
                                        value={
                                            bankData &&
                                            bankData.bankAccounts.edges.length > 0 &&
                                            bankData.bankAccounts.edges[0].node.id}
                                    >
                                        {
                                            bankData &&
                                            bankData.bankAccounts.edges.length === 0 &&
                                            <option defaultValue value={null}>No Account</option>
                                        }
                                        {
                                            bankData &&
                                            bankData.bankAccounts.edges.map((account, index) => {
                                                return (
                                                    <option key={index} value={account.node.id}>{`${account.node.accountName} - ${account.node.accountNo}`}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                </Col>
                            }
                            {
                                walletData &&
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <AvField
                                        label="To Wallet"
                                        name="wallet"
                                        type="select"
                                        className="custom-select"
                                        placeholder="To Wallet"
                                        errorMessage="To Wallet"
                                        value={walletData && walletData.wallets.edges.length > 0 && walletData.wallets.edges[0].node.id}
                                    >
                                        {
                                            walletData &&
                                            walletData.wallets.edges.length === 0 &&
                                            <option defaultValue value={null}>No Wallet</option>
                                        }
                                        {
                                            walletData &&
                                            walletData.wallets.edges.map((wallet, index) => {
                                                return (
                                                    <option key={index} value={wallet.node.id}>{`${wallet.node.name}(${wallet.node.walletType}): $${wallet.node.balance}`}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                </Col>
                            }
                        </Row>
                        {
                            (depositMethod === "Bank" ||
                                depositMethod === "ATM" ||
                                depositMethod === "Telegram") &&
                            <div className="bonus-container">
                                <Row>
                                    <Col>
                                        <div className="pl-0 pr-1 pt-2 pb-3 font-weight-medium">Receipt</div>
                                    </Col>
                                </Row>
                                <DropZone
                                    selectedFile={selectedFile}
                                    onFileDrop={(file) => {
                                        setSelectFile(file)
                                    }}
                                />
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <div className="align-bottom">
                                            <AvField
                                                defaultValue={new Date()}
                                                selected={date}
                                                label="Receipt Date and Time"
                                                name="dateAndTime"
                                                type="text"
                                                placeholderText="Enter Receipt Date and Time"
                                                errorMessage="Enter Receipt Date and Time"
                                                showTimeSelect
                                                timeIntervals={1}
                                                onChange={date => {
                                                    setDate(date)
                                                }}
                                                selectsStart
                                                maxDate={new Date()}
                                                dateFormat="yyyy-MM-dd HH:mm"
                                                tag={[Input, DatePicker]}
                                                validate={{
                                                    required: { value: true }
                                                }}
                                            />
                                            <div className="pl-2"></div>
                                            <Button type="button" color="primary" className="waves-light waves-effect mb-3" onClick={() => {
                                                setDate(moment().toDate())
                                            }}>
                                                Now
                                            </Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <AvField
                                            label="Receipt Reference No"
                                            name="receiptId"
                                            type="text"
                                            placeholder="Enter Receipt Reference No"
                                            errorMessage="Enter Receipt Reference No"
                                            validate={{
                                                required: { value: true }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    transactionData &&
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div className="align-center">
                                                <AvField
                                                    value={transactionData.rewardPoint ?? 0}
                                                    label="Reward Point"
                                                    name="rewardPoint"
                                                    type="text"
                                                />
                                                <div className="pl-2"></div>
                                                {
                                                    (transactionData.transactionStatus === 'Pending' || transactionData.transactionStatus === 'Viewed') &&
                                                    transactionData.rewardPoint === 0 &&
                                                    <div className="cursor-pointer pt-4" onClick={() => {
                                                        calculateRp({
                                                            variables: {
                                                                input: {
                                                                    transactionId: transactionData.id,
                                                                    force: true
                                                                }
                                                            }
                                                        }).then(result => {
                                                            setTransactionData(result.data.calculateRewardPoint)
                                                        })
                                                    }}>
                                                        <i className="bx bx-calculator font-size-18 ml-1 text-success"></i>
                                                    </div>
                                                }
                                                {
                                                    transactionData.rewardPoint > 0 &&
                                                    <div className="cursor-pointer pt-4" onClick={() => {
                                                        updateOneTransaction({
                                                            variables: {
                                                                input: {
                                                                    update: {
                                                                        rewardPoint: 0
                                                                    }, id: transactionData.id
                                                                }
                                                            }
                                                        }).then(result => {
                                                            setTransactionData(result.data.updateOneTransaction)
                                                        })
                                                    }}>
                                                        <i className="bx bx-x font-size-18 ml-1 text-danger"></i>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div className="align-center">
                                                <AvField
                                                    value={transactionData.agentPoint ?? 0}
                                                    label="Agent Point"
                                                    name="agentPoint"
                                                    type="text"
                                                />
                                                <div className="pl-2"></div>
                                                {
                                                    (transactionData.transactionStatus === 'Pending' || transactionData.transactionStatus === 'Viewed') &&
                                                    transactionData.agentPoint === 0 &&
                                                    playerData.user.upline &&
                                                    <div className="cursor-pointer pt-4" onClick={() => {
                                                        calculateAgentPoint({
                                                            variables: {
                                                                input: {
                                                                    transactionId: transactionData.id
                                                                }
                                                            }
                                                        }).then(result => {
                                                            setTransactionData(result.data.calculateAgentPoint)
                                                        })
                                                    }}>
                                                        <i className="bx bx-calculator font-size-18 ml-1 text-success"></i>
                                                    </div>
                                                }
                                                {
                                                    transactionData.agentPoint > 0 &&
                                                    <div className="cursor-pointer pt-4" onClick={() => {
                                                        updateOneTransaction({
                                                            variables: {
                                                                input: {
                                                                    update: {
                                                                        agentPoint: 0
                                                                    }, id: transactionData.id
                                                                }
                                                            }
                                                        }).then(result => {
                                                            setTransactionData(result.data.updateOneTransaction)
                                                        })
                                                    }}>
                                                        <i className="bx bx-x font-size-18 ml-1 text-danger"></i>
                                                    </div>
                                                }
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </div>
                        }
                        {
                            depositBonus &&
                            <div className="bonus-container">
                                <Row>
                                    <Col>
                                        <div className="pl-0 pr-1 pt-2 pb-3 font-weight-medium">Bonus Info</div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="align-center" xl={3} sm={3} lg={3} xs={3}>
                                        <AvField
                                            name="depositAmountDummy"
                                            label="Deposit Amount"
                                            value={depositAmount}
                                            type="text"
                                            disabled
                                            placeholder={t('Deposit Amount')}
                                            errorMessage={t('Enter Amount')}
                                            thousandSeparator
                                            decimalScale={2}
                                            fixedDecimalScale
                                            tag={[Input, NumberFormat]}
                                        />
                                    </Col>
                                    <Col xl={9} sm={9} lg={9} xs={9}>
                                        <Row>
                                            <div className="pl-0 pr-1 pt-2 pb-3 font-weight-medium icon">{<i className="pt-3 fa fa-plus"></i>}</div>
                                            <Col>
                                                <AvField
                                                    name="bonusAmount"
                                                    label="Bonus Amount"
                                                    value={!_.isEmpty(depositAmount) ? bonusAmount : 0}
                                                    type="text"
                                                    placeholder={t('Bonus Amount')}
                                                    errorMessage={t('Enter Bonus Amount')}
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                    onChange={(e) => {
                                                        setBonusAmount(e.target.value.replace(/,/g, ''))
                                                    }}
                                                    validate={{
                                                        required: true,
                                                        pattern: {
                                                            value: "^[0-9,.]+$",
                                                            errorMessage: `${t('Enter Only Numbers')}`
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <div className="pl-0 pr-1 pt-2 pb-3 font-weight-medium icon"><i className="pt-3 fas fa-equals"></i></div>
                                            <Col>
                                                <AvField
                                                    name="totaldeposit"
                                                    label="Total Deposit"
                                                    disabled
                                                    value={!_.isEmpty(depositAmount) ? parseFloat(depositAmount) + parseFloat(bonusAmount) : 0}
                                                    type="text"
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <div className="pl-0 pr-1 pt-2 pb-3 font-weight-medium icon">{<i className="pt-3 fa fa-times"></i>}</div>
                                            <Col>
                                                <AvField
                                                    name="rollover"
                                                    label="Rollover Multiplier"
                                                    value={depositBonus.node.rolloverMultiplier}
                                                    type="text"
                                                    placeholder={t('Rollover Multiplier')}
                                                    disabled
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                />
                                            </Col>
                                            <div className="pl-0 pr-1 pt-2 pb-3 font-weight-medium icon"><i className="pt-3 fas fa-equals"></i></div>
                                            <Col>
                                                <AvField
                                                    name="rolloverAmount"
                                                    label="Total Rollover Amount"
                                                    placeholder={t('Total Rollover Amount')}
                                                    errorMessage={t('Enter Total Rollover Amount')}
                                                    value={!_.isEmpty(depositAmount) ? rolloverAmount : 0}
                                                    type="text"
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                    validate={{
                                                        required: true,
                                                        pattern: {
                                                            value: "^[0-9,.]+$",
                                                            errorMessage: `${t('Enter Only Numbers')}`
                                                        }
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        }
                        <div className="submit-container">
                            <Button color="primary" type="submit">
                                {
                                    (loadingCreate || loadingApprove) &&
                                    <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                }
                                {
                                    !transactionData &&
                                    (!loadingCreate && !loadingApprove) &&
                                    <div>
                                        Submit
                                    </div>
                                }
                                {
                                    transactionData &&
                                    (!loadingCreate && !loadingApprove) &&
                                    <div>
                                        Approve
                                    </div>
                                }
                            </Button>
                        </div>
                    </AvForm>
                </div>
            </div>
            {
                depositMethod === "Bank" &&
                <NewPlayerBankAccount
                    modal={addBankAccount}
                    toggleModal={() => {
                        refetchBankAccount()
                        setAddBankAccount(!addBankAccount)
                    }}
                    player={player}
                    playerKey={playerKey}
                />
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(withTranslation()(BankerDeposit));
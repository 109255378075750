import gql from 'graphql-tag';
import { CountryFragment } from '../fragments/country.fragment';
import { PageFragment } from '../fragments/page.fragment';

export const GET_COUNTRY = gql`
    query (
        $filter: CountryFilter
    ) {
        countries (
            paging: {first: 9999}
            filter: $filter
            sorting: { field: name, direction: ASC }
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...CountryInfo
                }
            }
        }
    }
    ${CountryFragment}
    ${PageFragment}
`;
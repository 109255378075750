import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const CALCULATE_REWARD_POINT = gql`
    mutation(
        $input: CalculateRewardPointInput!
    ) {
        calculateRewardPoint (
            input: $input
        ) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
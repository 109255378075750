import React, { useRef } from 'react';
import { connect } from "react-redux";
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { GET_PLATFORMS } from '../../graphql/query/GET_PLATFORMS';
import { CREATED_PLATFORM } from '../../graphql/subscription/CREATED_PLATFORM';
import PlatformItem from './platform-item'
import PlatformTableItem from './product-table-item'
import NewPlatform from './new-platform'
import _ from 'lodash';

import { useHistory, Link } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import EditPlatformModal from './edit-platform-modal';
import { ME } from '../../graphql/query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';
function ProductsList(props) {
    const [setPlatForm, setAddPlatForm] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const [searchValue, setSearchValue] = React.useState('')

    const [isSearch, setIsSearch] = React.useState(null)
    const [newData, setNewData] = React.useState(false);
    const [pageInfo, setPageInfo] = React.useState(false)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [layoutToggle, setLayoutToggle] = React.useState(true);
    const [isFetchMore, setIsFetchMore] = React.useState(false);
    const [editPlatform, setEditPlatform] = React.useState(null);

    const first = 50

    const history = useHistory();

    const { data: meData } = useQuery(ME)

    const { loading, fetchMore, data, refetch } = useQuery(GET_PLATFORMS, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: {
                and: [
                    {
                        or: [
                            { name: { iLike: `%${search}%` } },
                        ]
                    },
                    props.filter
                ]
            },
            sorting: props.sort ?? { field: "displayName", direction: "ASC", nulls: "NULLS_LAST" }
        },
        notifyOnNetworkStatusChange: true
    })

    React.useEffect(() => {
        if (data) {
            setAfterCursor(data.platforms.pageInfo.endCursor)
            setPageInfo(data.platforms.pageInfo)
        }
    }, [data])

    const { data: platformCreatedData } = useSubscription(CREATED_PLATFORM, {
        variables: {
            createdBy: { neq: meData ? meData.me.username : '' }
        }
    })

    React.useEffect(() => {
        if (platformCreatedData) {
            setNewData(platformCreatedData.createdPlatform)
        }
    }, [platformCreatedData])

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: search !== '' ? {
                        and: [
                            {
                                or: [
                                    { name: { iLike: `%${search}%` } },
                                ]
                            },
                            props.filter
                        ]
                    } : { and: [props.filter] },
                    sorting: props.sort
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.platforms.edges;
                    const pageInfo = fetchMoreResult.platforms.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            platforms: {
                                __typename: previousResult.platforms.__typename,
                                edges: _.uniqBy([...previousResult.platforms.edges, ...newEdges], 'node.id'),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <div className="page-content product-link">
                    <Container fluid>
                        <Breadcrumbs title="Platform" breadcrumbItem="Platform List" />
                        <div className="search-bar-container">
                            <div className="button-add-product-container">
                                <div className="app-search d-none d-lg-block">
                                    <div className="position-relative">
                                        <input
                                            value={searchValue}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setSearchValue(e.target.value)
                                                debouncedSave(e.target.value)
                                            }}
                                        />
                                        <span className="bx bx-search-alt"></span>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dropdown d-inline-block d-lg-none ml-2">
                                        <button
                                            type="button"
                                            className="btn header-item noti-icon waves-effect"
                                            id="page-header-search-dropdown"
                                            onClick={() => { setIsSearch(!isSearch) }}>
                                            <i className="mdi mdi-magnify"></i>
                                        </button>
                                        <div
                                            className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                            aria-labelledby="page-header-search-dropdown"
                                        >
                                            <form className="p-3">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input
                                                            value={searchValue}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={(e) => {
                                                                setSearchValue(e.target.value)
                                                                debouncedSave(e.target.value)
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="submit">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div className="button-add-product-container">
                                <div className="filter-container button-filter-container">
                                    <button type="button" className="waves-effect btn btn-outline-secondary button-add-platform" onClick={() => {
                                        props.toggleRightSidebar();
                                    }}>
                                        <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                    </button>
                                </div>
                                <div className="filter-container button-filter-container px-2">
                                    <button type="button" className="waves-effect btn btn-outline-secondary button-add-platform" onClick={() => {
                                        setLayoutToggle(!layoutToggle)
                                    }}>
                                        <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                                    </button>
                                </div>
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-platform" onClick={() => { setAddPlatForm(true) }}>
                                    <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Platform
                                </button>
                            </div>

                            <div className={`reload-container ${newData ? 'active' : ''}`}>
                                <Card>
                                    <CardBody onClick={() => {
                                        window.location.reload()
                                    }}>
                                        <div className="reload-title">
                                            <i className='bx bx-refresh'></i>Reload
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>

                        </div>

                        <Row>
                            <Col lg="12">
                                <Row>
                                    {
                                        layoutToggle &&
                                        data &&
                                        <Card className="w-100">
                                            <InfiniteScroll
                                                dataLength={data.platforms.edges.length} //This is important field to render the next data
                                                next={() => setIsFetchMore(true)}
                                                hasMore={pageInfo.hasNextPage}
                                                loader={
                                                    <div className="text-center my-3">
                                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                    </div>
                                                }
                                                endMessage={
                                                    <div className="text-center my-3">
                                                        <div>Yay! You have seen it all</div>
                                                    </div>
                                                }
                                            >
                                                <div className="table-responsive">
                                                    <Table className="table mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>Image</th>
                                                                {
                                                                    meData &&
                                                                    meData.me.role === 'Developer' &&
                                                                    < th > Platform Name</th>
                                                                }
                                                                <th>Display Name</th>
                                                                <th>Kiosk Account</th>
                                                                <th>Unit Price</th>
                                                                <th>Url</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                data &&
                                                                meData &&
                                                                data.platforms.edges.map((platform, key) =>
                                                                    <PlatformTableItem
                                                                        platform={platform}
                                                                        key={"_platform_" + key}
                                                                        me={meData.me}
                                                                        onEdit={() => {

                                                                            setEditPlatform(platform.node)

                                                                            // history.push({
                                                                            //     "pathname": "/product-detail-platform",
                                                                            //     state: { "platform": platform.node }
                                                                            // })
                                                                        }}
                                                                        onManage={() => {
                                                                            history.push(`/product-detail-platform/${platform.node.id}`
                                                                            )
                                                                        }}
                                                                    />

                                                                )
                                                            }
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </InfiniteScroll>
                                        </Card>
                                    }
                                    {
                                        !layoutToggle && data &&
                                        meData &&
                                        <InfiniteScroll
                                            dataLength={data.platforms.edges.length} //This is important field to render the next data
                                            next={() => setIsFetchMore(true)}
                                            hasMore={pageInfo.hasNextPage}
                                            loader={
                                                <div className="text-center my-3">
                                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                </div>
                                            }
                                            endMessage={
                                                <div className="text-center my-3">
                                                    <div>Yay! You have seen it all</div>
                                                </div>
                                            }
                                        >
                                            <Row>
                                                <Col lg="12">
                                                    <Row>
                                                        {
                                                            data.platforms.edges.map((platform, key) =>
                                                                <PlatformItem
                                                                    platform={platform}
                                                                    key={"_platform_" + key}
                                                                    me={meData.me}
                                                                    onEdit={() => {
                                                                        setEditPlatform(platform.node)
                                                                    }}

                                                                    onManage={() => {
                                                                        history.push(`/product-detail-platform/${platform.node.id}`
                                                                        )
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </InfiniteScroll>
                                    }
                                    {
                                        data &&
                                        data.platforms.edges.length === 0 &&
                                        <Col xl="12" sm="12">
                                            <div className="no-platform">No Platform Found</div>
                                        </Col>
                                    }
                                </Row>
                            </Col>
                        </Row>

                        <div className="load-more-container">
                            {
                                (loading) &&
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                </Col>
                            }
                        </div>
                        <NewPlatform
                            modal={setPlatForm}
                            toggleModal={() => {
                                refetch()
                                setAddPlatForm(!setPlatForm)
                            }}
                        />
                        {
                            !_.isEmpty(editPlatform) && <EditPlatformModal
                                modal={!_.isEmpty(editPlatform)}
                                toggleModal={() => {
                                    setEditPlatform(!editPlatform)
                                }}
                                platform={editPlatform}

                            />

                        }


                    </Container>
                </div>
            }
        </React.Fragment >)
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Platform };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(ProductsList));

// export default ContactsList;
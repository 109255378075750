import gql from "graphql-tag";
import {
  FreeCreditFragment,
  FreeCreditTypeDetailFragment,
} from "../fragments/free.credit.fragment";

export const GET_ONE_FREE_CREDIT = gql`
  query ($id: ID!, $filter: FreeCreditTypeDetailFilter) {
    freeCreditType(id: $id) {
      ...FreeCreditType
      freeCreditTypeDetails(filter: $filter) {
        edges {
          node {
            ...FreeCreditTypeDetail
          }
        }
      }
    }
  }
  ${FreeCreditFragment}
  ${FreeCreditTypeDetailFragment}
`;

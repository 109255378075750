import gql from "graphql-tag";
import { AttendanceFragment } from "../fragments/attendance.fragment";
import { PageFragment } from "../fragments/page.fragment";

export const GET_ATTENDANCE_STEPS = gql`
  query (
    $filter: AttendanceStepFilter
    $paging: CursorPaging
    $sorting: [AttendanceStepSort!]
  ) {
    attendanceSteps(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        ...Page
      }
      edges {
        node {
          ...AttendanceInfo
        }
      }
    }
  }
  ${PageFragment}
  ${AttendanceFragment}
`;

import React, { } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Progress,
    Label,
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import Switch from "react-switch";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Compressor from 'compressorjs';
import CloudinaryHelper from "../../helpers/CloudinaryHelper"
import { FormGroup } from '@material-ui/core';
import DropZone from '../Workbench/Components/drop-zone';
import { UPDATE_ONE_BANNER } from '../../graphql/mutation';

function EditBanner(props) {

    const { modal, toggleModal, t, banner } = props;
    const [selectedFile, setSelectFile] = React.useState(null);
    const [fileError, setFileError] = React.useState(null);
    const [onUpload, setOnUpload] = React.useState(false);
    const [progress, setProgress] = React.useState(0);
    const [existingFile, setExistingFile] = React.useState(null);
    const [currentImage, setCurrentImage] = React.useState(null)
    const [isActive, setIsActive] = React.useState(false)

    const [updateOneBanner] = useMutation(UPDATE_ONE_BANNER);

    React.useEffect(() => {
        setSelectFile(null)
        setFileError(null)
        setProgress(0)
        setOnUpload(false)

        if (modal && banner) {
            setExistingFile(banner.node.bannerUrl)
            setCurrentImage(banner.node.bannerUrl)
            setIsActive(banner.node.isActive)
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-bonus-page">
                    <ModalHeader toggle={toggleModal}>
                        Edit Banner
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {

                                    let result
                                    if (selectedFile) {
                                        setOnUpload(true)
                                        let imageData = await Compress(selectedFile)
                                        result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=v13sqvvy`, (progressEvent) => {
                                            let val = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                            setProgress(val)
                                        })
                                        setOnUpload(false)
                                    }

                                    await updateOneBanner({
                                        variables: {
                                            input: {
                                                update: {
                                                    name: values.bannerName,
                                                    bannerUrl: selectedFile ? result.data.url : currentImage ? currentImage : null,
                                                    redirectUrl: values.redirectUrl,
                                                    isActive: isActive
                                                },
                                                id: banner.node.id
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })
                                }}
                                onInvalidSubmit={() => {

                                }}
                            >
                                <Row>
                                    {
                                        <Col lg={12}>
                                            <DropZone
                                                selectedFile={selectedFile}
                                                onFileDrop={(file) => {
                                                    setSelectFile(file)
                                                    setExistingFile(null)
                                                }}
                                                currentImage={existingFile}
                                            />
                                        </Col>
                                    }
                                    {
                                        fileError &&
                                        !selectedFile &&
                                        !existingFile &&
                                        <Col lg="12"><div className="image-url-error pb-2 font-size-10">* Please Provide an Image</div></Col>
                                    }
                                    {
                                        onUpload &&
                                        <Col lg={12}>
                                            <FormGroup className="mb-3">
                                                <Label>Uploading</Label>
                                                <Progress color="primary" value={progress}></Progress>
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        banner &&
                                        <>
                                            <Col lg={6}>
                                                <AvField
                                                    value={banner.node.name}
                                                    label="Banner Name"
                                                    name="bannerName"
                                                    type="text"
                                                    placeholder="Enter Banner Name"
                                                    errorMessage="Enter Banner Name"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <AvField
                                                    value={banner.node.redirectUrl}
                                                    label={`${t('Redirect Url')}`}
                                                    name="redirectUrl"
                                                    type="text"
                                                    placeholder={t('Enter Redirect Url')}
                                                    validate={{
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="is-active">{t('Active?')}</Label>
                                                    <Switch
                                                        uncheckedIcon={<Offsymbol text="NO" />}
                                                        checkedIcon={<OnSymbol text="YES" />}
                                                        id="is-active"
                                                        onColor="#34c38f"
                                                        offColor="#f46a6a"
                                                        onChange={() => {
                                                            setIsActive(!isActive)
                                                        }}
                                                        checked={isActive}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </>
                                    }
                                </Row>
                                {
                                    !onUpload &&
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(EditBanner));
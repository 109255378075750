import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { Link, withRouter } from "react-router-dom";

//i18n
import { useQuery } from "@apollo/react-hooks";
import { withTranslation } from "react-i18next";
import {
  Manager,
  OperatingUsers,
  Operator,
  Supervisor
} from "../../assets/scss/role.constant";
import { ME } from "../../graphql/query";

const SidebarContent = (props) => {
  const ref = useRef();

  const { data: meData } = useQuery(ME, {
    fetchPolicy: "cache-only",
  });

  useEffect(() => {
    const pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };

    if (meData) {
      initMenu();
    }
  }, [meData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="bx bxs-dashboard"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li className="menu-title">{props.t("Menu")}</li>
            {/* <li>
                            <Link to="/#" className="waves-effect">
                                <i className="bx bxs-dashboard"></i><span className="badge badge-pill badge-info float-right">03</span>
                                <span>{props.t('Dashboards')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/dashboard">{props.t('Default')}</Link></li>
                                <li><Link to="/dashboard-saas">{props.t('Saas')}</Link></li>
                                <li><Link to="/dashboard-crypto">{props.t('Crypto')}</Link></li>
                            </ul>
                        </li> */}

            {/* <li className="menu-title">{props.t('Apps')}</li> */}

            <li>
              <Link to="/cashier-workbench" className="waves-effect">
                <i className="bx bxs-wrench"></i>
                <span>{props.t("Workbench")}</span>
              </Link>
            </li>
            <li>
              <Link to="/kiosk" className="waves-effect">
                <i className="bx bxs-briefcase"></i>
                <span>{props.t("Toolbox")}</span>
              </Link>
            </li>
            <li>
              <Link to="/player" className=" waves-effect">
                <i className="bx bx-user"></i>
                <span>{props.t("Player")}</span>
              </Link>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-coin-stack"></i>
                <span>{props.t("Transaction")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/transactions">{props.t("All")}</Link>
                </li>
                <li>
                  <Link to="/transaction-deposit">{props.t("Deposit")}</Link>
                </li>
                <li>
                  <Link to="/transaction-withdraw">
                    {props.t("Withdrawal")}
                  </Link>
                </li>
                <li>
                  <Link to="/transaction-promotion">
                    {props.t("Promotion")}
                  </Link>
                </li>
                <li>
                  <Link to="/transaction-rebate">{props.t("Rebate")}</Link>
                </li>
                <li>
                  <Link to="/transaction-transfer">
                    {props.t("Game Credit Transfer")}
                  </Link>
                </li>
                <li>
                  <Link to="/transaction-rp">{props.t("Reward Point")}</Link>
                </li>
                <li>
                  <Link to="/transaction-agent">{props.t("Agent Point")}</Link>
                </li>
              </ul>
            </li>
            {meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role) && (
                <li>
                  <Link to="/referral" className=" waves-effect">
                    <i className="bx bx-wink-smile"></i>
                    <span>{props.t("Referral")}</span>
                  </Link>
                </li>
              )}
            {meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role) &&
              !Supervisor.includes(meData.me.role) && (
                <li>
                  <Link to="/product" className=" waves-effect">
                    <i className="bx bxs-joystick"></i>
                    <span>{props.t("Platform")}</span>
                  </Link>
                </li>
              )}

            {/* <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className='bx bxs-coin-stack'></i>
                                <span>{props.t('Kiosk')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="/product">{props.t('Platform')}</Link></li>
                                <li><Link to="/game-account">{props.t('Game Accounts')}</Link></li>
                            </ul>
                        </li> */}

            {/* <li>
                            <Link to="/product" className=" waves-effect">
                                <i className='bx bxs-joystick' ></i>
                                <span>{props.t('Platform')}</span>
                            </Link>
                        </li> */}
            {
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bxs-star"></i>
                  <span>{props.t("Extra")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  {meData &&
                    !OperatingUsers.includes(meData.me.role) &&
                    !Operator.includes(meData.me.role) &&
                    !Supervisor.includes(meData.me.role) && (
                      <li>
                        <Link to="/bonus" className=" waves-effect">
                          <i className="bx bx-heart"></i>
                          <span>{props.t("Bonus")}</span>
                        </Link>
                      </li>
                    )}
                  {meData &&
                    !OperatingUsers.includes(meData.me.role) &&
                    !Operator.includes(meData.me.role) && (
                      <li>
                        <Link to="/promotion" className=" waves-effect">
                          <i className="bx bx-coin"></i>
                          <span>{props.t("Promotion Type")}</span>
                        </Link>
                      </li>
                    )}
                  <li>
                    <Link to="/marketing-campaigns" className=" waves-effect">
                      <i className="bx bx-user-voice"></i>
                      <span>{props.t("Marketing Campaign")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/reward-tiers" className=" waves-effect">
                      <i className="bx bx-gift"></i>
                      <span>{props.t("Reward Point")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/agent-tiers" className=" waves-effect">
                      <i className="bx bx-network-chart"></i>
                      <span>{props.t("Agent Point")}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/attendance" className=" waves-effect">
                      <i className="bx bx-calendar"></i>
                      <span>{props.t("Attendance")}</span>
                    </Link>
                  </li>
                </ul>
              </li>
            }
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bx-line-chart"></i>
                <span>{props.t("Report")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                <li>
                  <Link to="/report/month">{props.t("Sales Summary")}</Link>
                </li>
                <li>
                  <Link to="/player-report">{props.t("Player Summary")}</Link>
                </li>
                {meData &&
                  !OperatingUsers.includes(meData.me.role) &&
                  !Operator.includes(meData.me.role) && (
                    <li>
                      <Link to="/transaction-report">
                        {props.t("Transaction Report")}
                      </Link>
                    </li>
                  )}
              </ul>
            </li>
            {/* <li>
                            <Link to="calendar" className=" waves-effect">
                                <i className="bx bx-calendar"></i>
                                <span>{props.t('Calendar')}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="chat" className=" waves-effect">
                                <i className="bx bx-chat"></i>
                                <span className="badge badge-pill badge-success float-right">{props.t('New')}</span>
                                <span>{props.t('Chat')}</span>
                            </Link>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-store"></i>
                                <span>{props.t('Ecommerce')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="ecommerce-products">{props.t('Products')}</Link></li>
                                <li><Link to="ecommerce-product-detail">{props.t('Product Detail')}</Link></li>
                                <li><Link to="ecommerce-orders">{props.t('Orders')}</Link></li>
                                <li><Link to="ecommerce-customers">{props.t('Customers')}</Link></li>
                                <li><Link to="ecommerce-cart">{props.t('Cart')}</Link></li>
                                <li><Link to="ecommerce-checkout">{props.t('Checkout')}</Link></li>
                                <li><Link to="ecommerce-shops">{props.t('Shops')}</Link></li>
                                <li><Link to="ecommerce-add-product">{props.t('Add Product')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-bitcoin"></i>
                                <span>{props.t('Crypto')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="crypto-wallet">{props.t('Wallet')}</Link></li>
                                <li><Link to="crypto-buy-sell">{props.t('Buy/Sell')}</Link></li>
                                <li><Link to="crypto-exchange">{props.t('Exchange')}</Link></li>
                                <li><Link to="crypto-lending">{props.t('Lending')}</Link></li>
                                <li><Link to="crypto-orders">{props.t('Orders')}</Link></li>
                                <li><Link to="crypto-kyc-application">{props.t('KYC Application')}</Link></li>
                                <li><Link to="crypto-ico-landing">{props.t('ICO Landing')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-envelope"></i>
                                <span>{props.t('Email')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="email-inbox">{props.t('Inbox')}</Link></li>
                                <li><Link to="email-read">{props.t('Read Email')} </Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-receipt"></i>
                                <span>{props.t('Invoices')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="invoices-list">{props.t('Invoice List')}</Link></li>
                                <li><Link to="invoices-detail">{props.t('Invoice Detail')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-briefcase-alt-2"></i>
                                <span>{props.t('Projects')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="projects-grid">{props.t('Projects Grid')}</Link></li>
                                <li><Link to="projects-list">{props.t('Projects List')}</Link></li>
                                <li><Link to="projects-overview">{props.t('Project Overview')}</Link></li>
                                <li><Link to="projects-create">{props.t('Create New')}</Link></li>
                            </ul>
                        </li>

                        <li>
                            <Link to="/#" className="has-arrow waves-effect">
                                <i className="bx bx-task"></i>
                                <span>{props.t('Tasks')}</span>
                            </Link>
                            <ul className="sub-menu" aria-expanded="false">
                                <li><Link to="tasks-list">{props.t('Task List')}</Link></li>
                                <li><Link to="tasks-kanban">{props.t('Kanban Board')}</Link></li>
                                <li><Link to="tasks-create">{props.t('Create Task')}</Link></li>
                            </ul>
                        </li> */}
            {/* <li>
                            <Link to="platform-list" className=" waves-effect">
                                <i className='bx bxs-inbox' ></i>
                                <span>{props.t('Platform')}</span>
                            </Link>
                        </li> */}

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="bx bxs-bank"></i>
                <span>{props.t("Banking")}</span>
              </Link>
              <ul className="sub-menu" aria-expanded="false">
                {meData &&
                  !OperatingUsers.includes(meData.me.role) &&
                  !Operator.includes(meData.me.role) && (
                    <li>
                      <Link to="/banks-list">{props.t("Bank")}</Link>
                    </li>
                  )}
                <li>
                  <Link to="/bank-accounts-list">{props.t("Account")}</Link>
                </li>
                {/* <li><Link to="/contacts-profile">{props.t('Messages')}</Link></li> */}
                {meData &&
                  !OperatingUsers.includes(meData.me.role) &&
                  !Operator.includes(meData.me.role) && (
                    <li>
                      <Link to="/balance-log">{props.t("Balance Log")}</Link>
                    </li>
                  )}
              </ul>
            </li>
            {meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role) && (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bxs-wrench"></i>
                    <span>{props.t("System")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {/* <li><Link to="contacts-grid">{props.t('User Grid')}</Link></li> */}
                    {!Manager.includes(meData.me.role) &&
                      !Supervisor.includes(meData.me.role) && (
                        <li>
                          <Link to="/contacts-list">{props.t("User")}</Link>
                        </li>
                      )}
                    {/* <li><Link to="/contacts-profile">{props.t('Profile')}</Link></li> */}
                    {/* <li><Link to="/contacts-profile">{props.t('Message Templates')}</Link></li> */}
                    <li>
                      <Link to="/tag">{props.t("Tag")}</Link>
                    </li>
                    {/* <li><Link to="contacts-profile">{props.t('Message Templates')}</Link></li> */}
                    {(meData.me.role === "Developer" ||
                      meData.me.role === "Admin") && (
                        <li>
                          <Link to="/settings-template">
                            {props.t("Setting")}
                          </Link>
                        </li>
                      )}
                    {(meData.me.role === "Developer" ||
                      meData.me.role === "Admin") && (
                        // <li><Link to="/telegram-page">{props.t('Telegram Page')}</Link></li>
                        <li>
                          <Link to="/#" className="has-arrow waves-effect">
                            {props.t("Telegram Menu")}
                          </Link>
                          <ul className="sub-menu" aria-expanded="false">
                            <li>
                              <Link to="/telegram-page">
                                {props.t("Telegram Page")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/telegram-button">
                                {props.t("Telegram Button")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/message-and-media">
                                {props.t("Message & Media")}
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                  </ul>
                </li>
              )}
            {meData && meData.me.role === "Developer" && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-slider"></i>
                  <span>{props.t("Developer Settings")}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/currency">{props.t("Currency")}</Link>
                  </li>
                  <li>
                    <Link to="/country">{props.t("Country")}</Link>
                  </li>
                  <li>
                    <Link to="/payment-type">{props.t("Payment Type")}</Link>
                  </li>
                  <li>
                    <Link to="/reject-reason">{props.t("Reject Reason")}</Link>
                  </li>
                </ul>
              </li>
            )}

            {meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role) &&
              !Supervisor.includes(meData.me.role) && (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="bx bxs-dashboard"></i>
                    <span>{props.t("Landing Setting")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/category-list">{props.t("Category")}</Link>
                    </li>
                    <li>
                      <Link to="/banner-list">{props.t("Banner")}</Link>
                    </li>
                  </ul>
                </li>
              )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));

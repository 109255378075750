


import { AvField, AvForm } from "availity-reactstrap-validation";
import _ from 'lodash';
import React, { Fragment } from 'react';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { CREATE_ONE_TELEGRAM_MENU_BUTTON } from "../../../graphql/mutation/CREATE_ONE_TELEGRAM_MENU_BUTTON";
import { FIND_OR_CREATE_TELEGRAM_MENU } from "../../../graphql/mutation/FIND_OR_CREATE_TELEGRAM_MENU";
import { UPDATE_ONE_TELEGRAM_MENU_BUTTON } from "../../../graphql/mutation/UPDATE_ONE_TELEGRAM_MENU_BUTTON";
import { GET_TELEGRAM_BUTTONS } from "../../../graphql/query/GET_TELEGRAM_BUTTONS";
import { GET_TELEGRAM_MENU_BUTTONS } from "../../../graphql/query/GET_TELEGRAM_MENU_BUTTONS";
import ToastHelper from '../../../helpers/ToastHelper';

export default function TelegramMenuButtonForm(props) {

    const { toggleModal, telegramMenuButton, mode, telegramMenuId, name } = props;
    const [createOneTelegramMenuButton] = useMutation(CREATE_ONE_TELEGRAM_MENU_BUTTON);
    const [updateOneTelegramMenuButton] = useMutation(UPDATE_ONE_TELEGRAM_MENU_BUTTON);
    const [search, setSearch] = React.useState('')
    const [action, setAction] = React.useState(null)
    const [existingId, setExistingId] = React.useState([]);
    const [defaultPosition, setDefaultPosition] = React.useState(null);

    const [getButtons, { data: telegramMenuButtonsData, loading: telegramMenuButtonsLoading }] = useLazyQuery(GET_TELEGRAM_MENU_BUTTONS, {
        variables: {
            filter: { telegramMenuId: { eq: telegramMenuId } }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const [findOrCreateTelegramMenu] = useMutation(FIND_OR_CREATE_TELEGRAM_MENU);

    React.useEffect(() => {
        if (mode !== null) {
            getButtons()

            if (mode === 'new') {
                setAction(null)
                findOrCreateTelegramMenu({
                    variables: {
                        name: name
                    }
                }).then(({ data }) => {
                    if (data && data.findOrCreateTelegramMenu.telegramMenuButtons.edges.length > 0) {
                        const first = _.first(data.findOrCreateTelegramMenu.telegramMenuButtons.edges)
                        setDefaultPosition(Math.ceil(first.node.position / 10) * 10);
                    } else {
                        setDefaultPosition(10)
                    }
                })
            }
        }
    }, [mode])

    React.useEffect(() => {
        if (telegramMenuButtonsData) {
            _.forEach((telegramMenuButtonsData.telegramMenuButtons.edges), o => {
                setExistingId(oldId => [...oldId, o.node.telegramButtonId]);
            })
        }
    }, [telegramMenuButtonsData])

    React.useEffect(() => {
        setAction(telegramMenuButton ? { node: telegramMenuButton.node.telegramButton } : null)
    }, [telegramMenuButton])

    const { data, loading } = useQuery(GET_TELEGRAM_BUTTONS, {
        variables: {
            paging: {
                first: 50
            },
            filter: existingId.length > 0 ? {
                action: { iLike: `%${search}%` },
                id: { notIn: existingId }
            } : { action: { iLike: `%${search}%` }, },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    })

    const handleToogleModal = () => {
        setAction(null)
        setSearch('')
        toggleModal()
    }

    return <Fragment>
        <Modal isOpen={mode !== null} role="dialog" size="md" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={handleToogleModal}>
            <div className="modal-content new-game-page">
                {
                    mode &&
                    <ModalHeader toggle={handleToogleModal}>
                        {mode.charAt(0).toUpperCase() + mode.slice(1)} Telegram Button
                    </ModalHeader>
                }
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                if (mode === 'new') {
                                    createOneTelegramMenuButton({
                                        variables: {
                                            input: {
                                                telegramMenuButton: {
                                                    telegramButtonId: action.node.id,
                                                    position: parseFloat(values.position),
                                                    telegramMenuId: telegramMenuId
                                                }
                                            }
                                        }
                                    }).then((val) => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Create Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    }).catch((e) => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    })
                                } else if (mode === 'edit') {
                                    updateOneTelegramMenuButton({
                                        variables: {
                                            input: {
                                                "update": {
                                                    telegramButtonId: action.node.id,
                                                    position: parseFloat(values.position),
                                                    telegramMenuId: telegramMenuId
                                                },
                                                id: telegramMenuButton.node.id
                                            }
                                        }
                                    }).then((val) => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    }).catch((e) => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    })
                                }
                            }}
                        >
                            <Row>
                                <Col lg="12">
                                    <FormGroup className={`${action == null ? 'text-danger' : ''}`}>
                                        <Label for="newrole-input">{'Telegram Button Action'}</Label>
                                        <AsyncTypeahead
                                            disabled={!telegramMenuButtonsData}
                                            selected={action ? [action] : []}
                                            id="action-list"
                                            isLoading={loading || telegramMenuButtonsLoading}
                                            labelKey={option => `${option.node.action}`}
                                            minLength={2}
                                            debounce={1000}
                                            onSearch={(query) => setSearch(query)}
                                            isInvalid={action == null}
                                            highlightOnlyResult
                                            options={data ? data.telegramButtons.edges : []}
                                            placeholder="Action"
                                            useCache={false}
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <span>{`${option.node.action}`}</span>
                                                </Fragment>
                                            )}
                                            errorMessage={'Enter Action Name'}
                                            onChange={(val) => {
                                                if (val.length > 0) {
                                                    console.log(val[0])
                                                    setAction(val[0])
                                                } else {
                                                    setAction(null)
                                                }
                                            }}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="12">
                                    <AvField
                                        label="Telegram Button Position"
                                        name="position"
                                        type="number"
                                        value={telegramMenuButton ? telegramMenuButton.node.position.toString() : defaultPosition}
                                        placeholder="Enter Telegram Menu Button Position"
                                        errorMessage="Enter Telegram Menu Button Position"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
import React from 'react';
import { Container, Row, Col, Card, CardBody, NavItem, NavLink, Media, Modal, ModalHeader, ModalBody } from "reactstrap";
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

import "../Crypto/datatables.scss";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { useQuery } from 'react-apollo';
import { USER, WALLET_ENTRIES, PLAYER_SUMMARY_REPORT, ME } from '../../graphql/query';
import { GET_USER_WALLETS } from '../../graphql/query/GET_USER_WALLETS';
import WalletTable from './wallet-table';
import BankerDeposit from '../Workbench/Banker/banker-deposit';
import BankerWithdraw from '../Workbench/Banker/banker-withdraw';
import WalletCreditIn from './wallet-credit-in';
import WalletCreditOut from './wallet-credit-out';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { OperatingUsers } from '../../assets/scss/role.constant';

function WalletInfo(props) {

    const first = 50
    const { playerId, walletId } = props.match.params
    const [activeTab, setActiveTab] = React.useState(null)
    const [currentWallet, setCurrentWallet] = React.useState(walletId)
    const [filter, setFilter] = React.useState({ walletId: { eq: currentWallet } })
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isDeposit, setIsDeposit] = React.useState(false)
    const [isWithdrawal, setIsWithdrawal] = React.useState(false)
    const [isCreditIn, setIsCreditIn] = React.useState(false)
    const [isCreditOut, setIsCreditOut] = React.useState(false)
    const [fromDate] = React.useState(moment().startOf("month").toDate())
    const [toDate] = React.useState(moment().endOf("month").toDate())
    const [selectedWallet, setSelectedWallet] = React.useState(null)

    const { data: dataUser } = useQuery(USER, {
        variables: {
            id: playerId
        },
        fetchPolicy: 'network-only'
    })

    const { data: reportData } = useQuery(PLAYER_SUMMARY_REPORT, {
        variables: {
            id: playerId,
            fromDate: fromDate,
            toDate: toDate,
        },
        fetchPolicy: 'network-only'
    })

    const { data: walletData, refetch: refetchWallet } = useQuery(GET_USER_WALLETS, {
        variables: {
            paging: {
                first: first
            },
            filter: {
                user: { id: { eq: playerId } }
            },
        },
        fetchPolicy: 'network-only'
    })

    const { data: walletEntriesData, fetchMore: fetchMoreWalletEntries, loading: loadingWalletEntries, refetch: refetchWalletEntries } = useQuery(WALLET_ENTRIES, {
        variables: {
            paging: {
                first: first
            },
            filter,
            sorting: { field: "createdAt", direction: "DESC" }
        },
        fetchPolicy: 'network-only'
    })

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    React.useEffect(() => {
        if (activeTab) {
            let tab = null
            if (activeTab === 'Deposit') {
                tab = ["Deposit", "Withdrawal"]
            } else if (activeTab === 'Increment') {
                tab = ["Increment", "Decrement"]
            } else if (activeTab === 'CreditIn') {
                tab = ["CreditIn", "CreditOut"]
            } else {
                tab = [activeTab]
            }

            setFilter({
                walletId: { eq: currentWallet },
                walletEntryType: { in: tab }
            })
        } else {
            setFilter({ walletId: { eq: currentWallet } })
        }
    }, [activeTab, currentWallet])

    React.useEffect(() => {
        if (walletEntriesData) {
            setAfterCursor(walletEntriesData.walletEntries.pageInfo.endCursor)
            setPageInfo(walletEntriesData.walletEntries.pageInfo)
        }
    }, [walletEntriesData])

    React.useEffect(() => {
        if (walletData) {
            setSelectedWallet(_.find(walletData.wallets.edges, function (o) {
                return o.node.id === walletId
            }))
        }
    }, [walletData]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMoreWalletEntries({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.walletEntries.edges;
                    const pageInfo = fetchMoreResult.walletEntries.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            walletEntries: {
                                __typename: previousResult.walletEntries.__typename,
                                edges: _.uniqBy([...previousResult.walletEntries.edges, ...newEdges], 'node.id'),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const FindWallet = () => {
        return _.find(walletData.wallets.edges, function (o) {
            return o.node.id === currentWallet
        })
    }

    const setPrefix = (name) => {
        if (name === "Main") {
            return '$'
        } else {
            return ''
        }
    }

    const setSuffix = (name) => {
        if (name === "Reward") {
            return 'RP'
        } else if (name === "Agent") {
            return 'AP'
        } else {
            return ''
        }
    }

    return (
        <React.Fragment>
            <div className="page-content wallet-info">
                <Container fluid>
                    <Row>
                        <Col xl="12" lg="12">
                            <Card>
                                <CardBody>

                                    <Media>
                                        <div className="mr-4">
                                            <i className="mdi mdi-account-circle text-primary h1"></i>
                                        </div>

                                        {
                                            dataUser &&
                                            <Media body>
                                                <div className="text-muted">
                                                    <h5>{dataUser.user.name}</h5>
                                                    <p className="mb-1">{dataUser.user.username}</p>
                                                    {/* <p className="mb-0">Id no: #SK0234</p> */}
                                                </div>
                                            </Media>
                                        }
                                    </Media>
                                </CardBody>
                                <CardBody className="border-top">

                                    <Row>
                                        <Col sm="6">
                                            <div>
                                                <p className="text-muted mb-2">Available Balance</p>
                                                {
                                                    walletData &&
                                                    FindWallet() &&
                                                    <h5>
                                                        <NumberFormat
                                                            value={FindWallet().node.balance}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            decimalScale={2}
                                                            prefix={`${setPrefix(FindWallet().node.name)}`}
                                                            suffix={`${setSuffix(FindWallet().node.name)}`}
                                                        />
                                                    </h5>
                                                }
                                            </div>
                                        </Col>
                                        {/* <Col sm="6">
                                            <div className="text-sm-right mt-4 mt-sm-0">
                                                <p className="text-muted mb-2">Since last month</p>
                                                <h5>+ $ 248.35   <span className="badge badge-success ml-1">+ 1.3 %</span></h5>

                                            </div>
                                        </Col> */}
                                    </Row>
                                </CardBody>

                                <CardBody className="border-top">
                                    <p className="text-muted mb-4">In this month</p>
                                    <div className="text-center">
                                        <Row>
                                            {
                                                meData &&
                                                !OperatingUsers.includes(meData.me.role) &&
                                                <Col sm="3">
                                                    <div>
                                                        <div className="font-size-24 text-primary mb-2">
                                                            <i className="bx bx-send"></i>
                                                        </div>

                                                        <p className="text-muted mb-2">Deposit</p>
                                                        {
                                                            reportData &&
                                                            <h5>{reportData.playerSummaryReport.length > 0 ? parseFloat(reportData.playerSummaryReport[0].totalDepositAmount).toFixed(2) : ''}</h5>
                                                        }
                                                        <div className="mt-3">
                                                            <Link onClick={() => setIsDeposit(true)} className="btn btn-primary btn-sm w-md">Deposit</Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                            <Col sm="6">
                                                <div className="mt-4 mt-sm-0">
                                                    <div className="font-size-24 text-primary mb-2">
                                                        <i className="bx bx-import"></i>
                                                    </div>

                                                    <p className="text-muted mb-2">Transfer</p>
                                                    {
                                                        reportData &&
                                                        <h5>{reportData.playerSummaryReport.length > 0 ? parseFloat(reportData.playerSummaryReport[0].totalCreditIn + reportData.playerSummaryReport[0].totalCreditOut).toFixed(2) : ''}</h5>
                                                    }

                                                    <div className="align-center mt-3">
                                                        <div className="mr-1">
                                                            <Link onClick={() => setIsCreditIn(true)} className="btn btn-primary btn-sm w-md">Credit In</Link>
                                                        </div>

                                                        <div className="ml-1">
                                                            <Link onClick={() => setIsCreditOut(true)} className="btn btn-primary btn-sm w-md">Credit Out</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            {
                                                meData &&
                                                !OperatingUsers.includes(meData.me.role) &&
                                                <Col sm="3">
                                                    <div className="mt-4 mt-sm-0">
                                                        <div className="font-size-24 text-primary mb-2">
                                                            <i className="bx bx-wallet"></i>
                                                        </div>

                                                        <p className="text-muted mb-2">Withdraw</p>
                                                        {
                                                            reportData &&
                                                            <h5>{reportData.playerSummaryReport.length > 0 ? parseFloat(reportData.playerSummaryReport[0].totalWithdrawalAmount).toFixed(2) : ''}</h5>
                                                        }

                                                        <div className="mt-3">
                                                            <Link onClick={() => setIsWithdrawal(true)} className="btn btn-primary btn-sm w-md">Withdraw</Link>
                                                        </div>
                                                    </div>
                                                </Col>
                                            }
                                        </Row>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                        <Col xl="12" lg="12">
                            <Row>
                                {
                                    walletData &&
                                    walletData.wallets.edges.length > 0 &&
                                    walletData.wallets.edges.map((wallet, index) => {
                                        return (
                                            <Col key={index} sm="4">
                                                <Card
                                                    className={
                                                        classnames({
                                                            "mini-stats-wid": true,
                                                            "selected": wallet.node.id === currentWallet,
                                                            "pointer": true
                                                        })
                                                    }
                                                    onClick={() => {
                                                        setSelectedWallet(wallet)
                                                        setCurrentWallet(wallet.node.id)
                                                    }}
                                                >
                                                    <CardBody>
                                                        <Media>
                                                            <div className="mr-3 align-self-center">
                                                                <i className="mdi mdi-wallet-outline h2 text-primary mb-0"></i>
                                                            </div>
                                                            <Media body>
                                                                <p className="text-muted mb-2">{`${wallet.node.name} (${wallet.node.walletType})`}</p>
                                                                {/* <h5 className="mb-0">1.02356 BTC <span className="font-size-14 text-muted">= $ 9148.00</span></h5> */}
                                                                <h5>
                                                                    <NumberFormat
                                                                        value={wallet.node.balance}
                                                                        displayType={'text'}
                                                                        thousandSeparator={true}
                                                                        decimalScale={2}
                                                                        prefix={`${setPrefix(wallet.node.name)}`}
                                                                        suffix={`${setSuffix(wallet.node.name)}`}
                                                                    />
                                                                </h5>
                                                            </Media>
                                                        </Media>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>

                            {/* <Card>
                                <CardBody>
                                    <h4 className="card-title mb-3">Overview</h4>

                                    <div>
                                        <div id="overview-chart" className="apex-charts" dir="ltr">
                                            <ReactApexChart series={this.state.series} options={this.state.options} type="line" height={240} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card> */}
                        </Col>
                    </Row>

                    <Row>
                        <Col lg="12">
                            <Card>
                                <CardBody>
                                    <h4 className="card-title mb-4">Activities</h4>
                                    {
                                        selectedWallet &&
                                        selectedWallet.node.name === "Reward" &&
                                        <ul className="nav nav-tabs nav-tabs-custom">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === null })} onClick={() => { setActiveTab(null); }} >
                                                    All
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPGenerated' })} onClick={() => { setActiveTab('RPGenerated'); }} >
                                                    Generated
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPClaimed' })} onClick={() => { setActiveTab('RPClaimed'); }} >
                                                    Claimed
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPExpired' })} onClick={() => { setActiveTab('RPExpired'); }} >
                                                    Expired
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPVoided' })} onClick={() => { setActiveTab('RPVoided'); }} >
                                                    Voided
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                    }
                                    {
                                        selectedWallet &&
                                        selectedWallet.node.name === "Agent" &&
                                        <ul className="nav nav-tabs nav-tabs-custom">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === null })} onClick={() => { setActiveTab(null); }} >
                                                    All
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'APGenerated' })} onClick={() => { setActiveTab('APGenerated'); }} >
                                                    Generated
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'APClaimed' })} onClick={() => { setActiveTab('APClaimed'); }} >
                                                    Claimed
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'APExpired' })} onClick={() => { setActiveTab('APExpired'); }} >
                                                    Expired
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'APVoided' })} onClick={() => { setActiveTab('APVoided'); }} >
                                                    Voided
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                    }
                                    {
                                        selectedWallet &&
                                        selectedWallet.node.name !== "Reward" &&
                                        selectedWallet.node.name !== "Agent" &&
                                        <ul className="nav nav-tabs nav-tabs-custom">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === null })} onClick={() => { setActiveTab(null); }} >
                                                    All
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Deposit' })} onClick={() => { setActiveTab('Deposit'); }} >
                                                    Deposit / Withdrawal
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Increment' })} onClick={() => { setActiveTab('Increment'); }} >
                                                    Increment / Decrement
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'CreditIn' })} onClick={() => { setActiveTab('CreditIn'); }} >
                                                    Credit In / Credit Out
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Bonus' })} onClick={() => { setActiveTab('Bonus'); }} >
                                                    Bonus
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'FreeCredit' })} onClick={() => { setActiveTab('FreeCredit'); }} >
                                                    Promotion
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                    }
                                    {
                                        walletEntriesData &&
                                        <InfiniteScroll
                                            dataLength={walletEntriesData.walletEntries.edges.length} //This is important field to render the next data
                                            next={() => setIsFetchMore(true)}
                                            hasMore={pageInfo.hasNextPage}
                                            loader={
                                                <div className="text-center my-3">
                                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                </div>
                                            }
                                            endMessage={
                                                <div className="text-center my-3">
                                                    <div>Yay! You have seen it all</div>
                                                </div>
                                            }
                                        >
                                            <div className="mt-4">
                                                {
                                                    selectedWallet &&
                                                    <WalletTable
                                                        wallet={selectedWallet}
                                                        data={walletEntriesData ? walletEntriesData.walletEntries.edges : []}
                                                        activeTab={activeTab}
                                                    />
                                                }
                                                {/* <MDBDataTable responsive bordered data={data} /> */}
                                            </div>
                                        </InfiniteScroll>
                                    }
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className="load-more-container">
                        {
                            (loadingWalletEntries) &&
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                </div>
                            </Col>
                        }
                    </div>
                    {
                        dataUser &&
                        isDeposit &&
                        <Modal isOpen={isDeposit} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={() => {
                            setIsDeposit(false)
                        }}>
                            <div className="modal-content">
                                <ModalHeader>
                                    {'Deposit'}
                                </ModalHeader>
                                <ModalBody>
                                    <BankerDeposit
                                        player={dataUser.user}
                                        isActive={true}
                                        playerKey={dataUser.user.username}
                                        onDone={async () => {
                                            setIsDeposit(false)
                                            setActiveTab('Deposit');
                                            refetchWalletEntries()
                                            refetchWallet()
                                        }}
                                    />
                                </ModalBody>
                            </div>
                        </Modal>
                    }
                    {
                        dataUser &&
                        isWithdrawal &&
                        <Modal isOpen={isWithdrawal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={() => {
                            setIsWithdrawal(false)
                        }}>
                            <div className="modal-content">
                                <ModalHeader>
                                    {'Withdrawal'}
                                </ModalHeader>
                                <ModalBody>
                                    <BankerWithdraw
                                        player={dataUser.user}
                                        isActive={true}
                                        playerKey={dataUser.user.username}
                                        onDone={async () => {
                                            setIsWithdrawal(false)
                                            setActiveTab('Withdrawal');
                                            refetchWalletEntries()
                                            refetchWallet()
                                        }}
                                    />
                                </ModalBody>
                            </div>
                        </Modal>
                    }
                    {
                        dataUser &&
                        isCreditIn &&
                        <WalletCreditIn
                            modal={isCreditIn}
                            toggleModal={() => {
                                setIsCreditIn(false)
                            }}
                            player={dataUser.user}
                            onDone={async () => {
                                setIsCreditIn(false)
                                setActiveTab('CreditIn');
                                refetchWalletEntries()
                                refetchWallet()
                            }}
                        />
                    }
                    {
                        dataUser &&
                        isCreditOut &&
                        <WalletCreditOut
                            modal={isCreditOut}
                            toggleModal={() => {
                                setIsCreditOut(false)
                            }}
                            player={dataUser.user}
                            onDone={async () => {
                                setIsCreditOut(false)
                                setActiveTab('CreditOut');
                                refetchWalletEntries()
                                refetchWallet()
                            }}
                        />
                    }
                </Container>
            </div>
        </React.Fragment>
    );
}

export default WalletInfo;
import gql from 'graphql-tag';
import { CategoryFragment } from '../fragments/category.fragment';

export const UPDATE_ONE_CATEGORY = gql`
    mutation(
        $input: UpdateOneCategoryInput!
    ) {
        updateOneCategory (input: $input) {
            ...CategoryInfo
        }
    }
    ${CategoryFragment}
`;
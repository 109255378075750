import gql from 'graphql-tag';
import { MarketingFragment } from '../fragments/marketing.campaign.fragment';

export const UPDATE_ONE_MARKETING_CAMPAIGN = gql`
    mutation(
        $input: UpdateOneMarketingCampaignInput!
    ) {
        updateOneMarketingCampaign (input: $input) {
            ...MarketingCampaign
        }
    }
    ${MarketingFragment}
`;
import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const CREATE_ONE_CREDITOUT = gql`
    mutation(
        $input: CreateOneCreditOutInput!
    ) {
        createOneCreditOut (input: $input) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { BonusFragment } from '../fragments/bonus.fragment';

export const GET_BONUSES = gql`
    query (
        $paging: CursorPaging
        $filter: BonusFilter
        $sorting: [BonusSort!]
    ) {
        bonuses (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...BonusInfo
                }
            }
        }
    }
    ${BonusFragment}
    ${PageFragment}
`;
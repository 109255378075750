import gql from "graphql-tag";
import { WalletFragment } from "../fragments/wallet.fragment";

export const FIND_OR_CREATE_WEEKLY_ATTENDANCE_WALLET = gql`
  mutation ($userId: String!) {
    findOrCreateWeeklyAttendanceCreditWallet(userId: $userId) {
      ...WalletInfo
    }
  }
  ${WalletFragment}
`;

import React, { useRef } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import { toggleRightSidebar } from "../../store/actions";

import { Link, useHistory } from "react-router-dom";
import { GET_KIOSK } from "../../graphql/query/GET_KIOSK";
import { CREATED_KIOSK_ACCOUNT } from "../../graphql/subscription/CREATED_KIOSK_ACCOUNT";
import KioskItem from "./kiosk-item";

import _ from "lodash";
import EditPlatformModal from "./../Platforms/edit-platform-modal";
import NewKioksAccount from "./new-kiosk";

import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import classname from "classnames";
import InfiniteScroll from "react-infinite-scroll-component";
import { OperatingUsers, Operator } from "../../assets/scss/role.constant";
import SettingEditor from "../../components/CommonForBoth/SettingEditor";
import {
  SAFE_RESTART_SOLUTION_SERVER,
  SAFE_RESTART_TELEGRAM_SERVER,
} from "../../graphql/mutation";
import { SET_KIOSK_DEFAULT } from "../../graphql/mutation/SET_KIOSK_DEFAULT";
import { UPDATE_ONE_KIOSK_ACCOUNT } from "../../graphql/mutation/UPDATE_ONE_KIOSK";
import {
  CHECK_SMS_BALANCE,
  CHECK_TELEGRAM_HEALTH,
  ME,
} from "../../graphql/query";
import { CHECK_SOLUTION_HEALTH } from "../../graphql/query/CHECK_SOLUTION_HEALTH";
import { UPDATED_KIOSK_ACCOUNT } from "../../graphql/subscription/UPDATED_KIOSK_ACCOUNT";
import ToastHelper from "../../helpers/ToastHelper";
import EditKioskAccount from "./edit-kiosk";
import KioskAccountTableItem from "./kiosk-account-table-item";

function KioskListMain(props) {
  const history = useHistory();
  const { platform } = props;

  const [addKioskAccount, setAddKioskAccount] = React.useState(false);
  const [editKioskAccount, setEditKioskAccount] = React.useState(false);
  const [editPlatform, setEditPlatform] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [isSearch, setIsSearch] = React.useState(null);
  const [newData, setNewData] = React.useState(false);
  const [pageInfo, setPageInfo] = React.useState(false);
  const [afterCursor, setAfterCursor] = React.useState(null);
  const [allowSafeButton, setAllowSafeButton] = React.useState(true);
  const [restartText, setRestartText] = React.useState("Safe Restart");
  const [allowTelegramSafeButton, setAllowTelegramSafeButton] =
    React.useState(true);
  const [telegramRestartText, setTelegramRestartText] =
    React.useState("Safe Restart");

  const [isFetchMore, setIsFetchMore] = React.useState(false);

  const [layoutToggle, setLayoutToggle] = React.useState(true);

  const [restart] = useMutation(SAFE_RESTART_SOLUTION_SERVER);
  const [restartTelegram] = useMutation(SAFE_RESTART_TELEGRAM_SERVER);
  const [activeTab, setActiveTab] = React.useState("1");

  const constuctBaseFilter = () => {
    let baseFilter = { ...props.filter };
    if (_.isEmpty(props.filter)) {
      baseFilter = {};
    }
    if (search !== "") {
      baseFilter["or"] = [
        { username: { iLike: `%${search}%` } },
        { platform: { name: { iLike: `%${search}%` } } },
      ];
    }
    if (platform) {
      baseFilter["platformId"] = { eq: platform.id };
    } else {
      baseFilter["isDefault"] = { is: true };
    }

    return { ...baseFilter };
  };

  const first = 50;
  const { loading, fetchMore, data, refetch } = useQuery(GET_KIOSK, {
    variables: {
      paging: {
        first: first,
        after: null,
      },
      filter: constuctBaseFilter(),
      sorting: [
        ...props.sort,
        { field: "isActive", direction: "DESC" },
        { field: "maintenanceMode", direction: "ASC" },
        { field: "isBotEnabled", direction: "DESC" },
        { field: "lastSyncAt", direction: "DESC", nulls: "NULLS_LAST" },
      ],
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: meData } = useQuery(ME);
  const { data: smsBalance } = useQuery(CHECK_SMS_BALANCE);
  const { data: checkTelegram } = useQuery(CHECK_TELEGRAM_HEALTH);
  const { data: checkSolution } = useQuery(CHECK_SOLUTION_HEALTH);

  const [updateOneKioskAccount] = useMutation(UPDATE_ONE_KIOSK_ACCOUNT);

  const toggleActive = (kiosk) => {
    updateOneKioskAccount({
      variables: {
        input: {
          update: {
            isActive: !kiosk.isActive,
          },
          id: kiosk.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {});
  };

  const onToggleBotEnabled = (kiosk) => {
    updateOneKioskAccount({
      variables: {
        input: {
          update: {
            isBotEnabled: !kiosk.isBotEnabled,
          },
          id: kiosk.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {});
  };

  const onToggleAutoSyncMode = (kiosk) => {
    updateOneKioskAccount({
      variables: {
        input: {
          update: {
            autoSyncMode: !kiosk.autoSyncMode,
          },
          id: kiosk.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {});
  };

  React.useEffect(() => {
    if (data) {
      setAfterCursor(data.kioskAccounts.pageInfo.endCursor);
      setPageInfo(data.kioskAccounts.pageInfo);
    }
  }, [data]);

  useSubscription(UPDATED_KIOSK_ACCOUNT);

  const { data: kioskAccountCreatedData } = useSubscription(
    CREATED_KIOSK_ACCOUNT,
    {
      variables: {
        createdBy: { neq: meData ? meData.me.username : "" },
      },
    }
  );

  React.useEffect(() => {
    if (kioskAccountCreatedData) {
      setNewData(kioskAccountCreatedData.createdKioskAccount);
    }
  }, [kioskAccountCreatedData]);

  const kioksDropDown = (kioskAccount) => {
    if (platform) {
      return (
        <DropdownMenu right>
          <DropdownItem
            onClick={() => {
              setEditKioskAccount(kioskAccount.node);
            }}
          >
            {" "}
            Edit Kiosk Account
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              history.push({
                pathname: "/game",
                state: {
                  kioskAccount: kioskAccount.node,
                  platform: { ...platform },
                },
              });
            }}
          >
            Manage Game Account
          </DropdownItem>
        </DropdownMenu>
      );
    } else {
      return (
        <DropdownMenu right>
          <DropdownItem
            onClick={() => {
              setEditKioskAccount(kioskAccount.node);
            }}
          >
            {" "}
            Edit Kiosk Account
          </DropdownItem>
        </DropdownMenu>
      );
    }
  };

  React.useEffect(() => {
    const handleScrollToEnd = () => {
      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = windowHeight + window.pageYOffset;

      if (windowBottom === docHeight && !isFetchMore && pageInfo.hasNextPage) {
        setIsFetchMore(true);

        fetchMore({
          variables: {
            paging: {
              first: first,
              after: afterCursor,
            },
            filter: constuctBaseFilter(),
            sorting: props.sort,
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            const newEdges = fetchMoreResult.kioskAccounts.edges;
            const pageInfo = fetchMoreResult.kioskAccounts.pageInfo;
            setIsFetchMore(false);
            setAfterCursor(pageInfo.endCursor);

            return newEdges.length
              ? {
                  kioskAccounts: {
                    __typename: previousResult.kioskAccounts.__typename,
                    edges: _.uniq([
                      ...previousResult.kioskAccounts.edges,
                      ...newEdges,
                    ]),
                    pageInfo,
                  },
                }
              : previousResult;
          },
        });
      }
    };
    window.addEventListener("scroll", handleScrollToEnd, { passive: true });

    return () => window.removeEventListener("scroll", handleScrollToEnd);
  }, [afterCursor]); // eslint-disable-line react-hooks/exhaustive-deps

  const [setKioskDefault] = useMutation(SET_KIOSK_DEFAULT);

  const debouncedSave = useRef(
    _.debounce((nextValue) => setSearch(nextValue), 300)
  ).current;

  return (
    <React.Fragment>
      {
        <div className={!platform ? "page-content kiosk-link" : "kiosk-link"}>
          <Container fluid>
            {!platform &&
              meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role) && (
                <Row>
                  {smsBalance && (
                    <Col md={6} lg={6} xl={3} sm={6}>
                      <Card>
                        <CardBody className="text-center">
                          <h5 className="mb-0">{smsBalance.smsCheckBalance}</h5>
                          <h6 className="mb-0">SMS Balance</h6>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {checkTelegram && (
                    <Col md={6} lg={6} xl={3} sm={6}>
                      <Card>
                        <CardBody className="text-center">
                          <div className="align-center justify-content-center">
                            <div>
                              <h5
                                className={classname({
                                  "mb-0": true,
                                  "text-danger":
                                    !checkTelegram.checkTelegramHealth,
                                  "text-success":
                                    checkTelegram.checkTelegramHealth,
                                })}
                              >
                                {checkTelegram.checkTelegramHealth
                                  ? "Online"
                                  : "Offline"}
                              </h5>
                              <h6 className="mb-0">Telegram</h6>
                            </div>
                            <button
                              type="button"
                              className={`btn btn-sm ml-2 ${
                                !allowTelegramSafeButton
                                  ? "btn-light disabled"
                                  : "btn-danger"
                              }`}
                              onClick={() => {
                                if (allowTelegramSafeButton) {
                                  setAllowTelegramSafeButton(false);
                                  setTelegramRestartText("Waiting to Restart");
                                  restartTelegram().then(() => {
                                    setTelegramRestartText("Restarting");
                                    setTimeout(() => {
                                      setAllowTelegramSafeButton(true);
                                      setTelegramRestartText("Safe Restart");
                                    }, 5000);
                                  });
                                }
                              }}
                            >
                              {telegramRestartText}
                            </button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {checkSolution && (
                    <Col md={6} lg={6} xl={3} sm={6}>
                      <Card>
                        <CardBody className="text-center">
                          <div className="align-center justify-content-center">
                            <div>
                              <h5
                                className={classname({
                                  "mb-0": true,
                                  "text-danger":
                                    !checkSolution.checkSolutionHealth,
                                  "text-success":
                                    checkSolution.checkSolutionHealth,
                                })}
                              >
                                {checkSolution.checkSolutionHealth
                                  ? "Online"
                                  : "Offline"}
                              </h5>
                              <h6 className="mb-0">Solution</h6>
                            </div>
                            <button
                              type="button"
                              className={`btn btn-sm ml-2 ${
                                !allowSafeButton
                                  ? "btn-light disabled"
                                  : "btn-danger"
                              }`}
                              onClick={() => {
                                if (allowSafeButton) {
                                  setAllowSafeButton(false);
                                  setRestartText("Waiting to Restart");
                                  restart().then(() => {
                                    setRestartText("Restarting");
                                    setTimeout(() => {
                                      setAllowSafeButton(true);
                                      setRestartText("Safe Restart");
                                    }, 5000);
                                  });
                                }
                              }}
                            >
                              {restartText}
                            </button>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
              )}
            <div className="search-bar-container">
              <div className="button-add-kiosk-container">
                <div className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <input
                      value={searchValue}
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        debouncedSave(e.target.value);
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="dropdown d-inline-block d-lg-none ml-2">
                    <button
                      type="button"
                      className="btn header-item noti-icon waves-effect"
                      id="page-header-search-dropdown"
                      onClick={() => {
                        setIsSearch(!isSearch);
                      }}
                    >
                      <i className="mdi mdi-magnify"></i>
                    </button>
                    <div
                      className={
                        isSearch
                          ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                          : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                      }
                      aria-labelledby="page-header-search-dropdown"
                    >
                      <form className="p-3">
                        <div className="form-group m-0">
                          <div className="input-group">
                            <input
                              value={searchValue}
                              type="text"
                              className="form-control"
                              placeholder="Search..."
                              onChange={(e) => {
                                setSearchValue(e.target.value);
                                debouncedSave(e.target.value);
                              }}
                            />
                            <div className="input-group-append">
                              <button className="btn btn-primary" type="submit">
                                <i className="mdi mdi-magnify"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-add-kiosk-container">
                <div className="filter-container button-filter-container">
                  <button
                    type="button"
                    className="waves-effect btn btn-outline-secondary button-add-kiosk"
                    onClick={() => {
                      props.toggleRightSidebar();
                    }}
                  >
                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i>{" "}
                    |{" "}
                    <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                  </button>
                </div>
                <div className="filter-container button-filter-container px-1">
                  <button
                    type="button"
                    className="waves-effect btn btn-outline-secondary button-add-kiosk"
                    onClick={() => {
                      setLayoutToggle(!layoutToggle);
                    }}
                  >
                    <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i>{" "}
                    |{" "}
                    <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                  </button>
                </div>
                <button
                  type="button"
                  className="waves-effect btn btn-outline-secondary button-add-kiosk"
                  onClick={() => {
                    setAddKioskAccount(true);
                  }}
                >
                  <i className="bx bx-plus font-size-15 align-middle mr-2"></i>
                  New Kiosk
                </button>
              </div>

              <div className={`reload-container ${newData ? "active" : ""}`}>
                <Card>
                  <CardBody
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    <div className="reload-title">
                      <i className="bx bx-refresh"></i>Reload
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

            {layoutToggle && data && (
              <>
                <h6 className="m-2">Working Kiosks</h6>
                <Card className="">
                  <InfiniteScroll
                    dataLength={data.kioskAccounts.edges.length} //This is important field to render the next data
                    next={() => setIsFetchMore(true)}
                    hasMore={pageInfo.hasNextPage}
                    loader={
                      <div className="text-center my-3">
                        <Link to="#" className="text-success">
                          <i className="bx bx-hourglass bx-spin mr-2"></i> Load
                          more{" "}
                        </Link>
                      </div>
                    }
                    endMessage={
                      <div className="text-center mb-3">
                        <div>Yay! You have seen it all</div>
                      </div>
                    }
                  >
                    <div className="table-responsive">
                      <Table className="table">
                        <thead>
                          <tr>
                            {_.isEmpty(platform) && <th>Platform</th>}
                            <th>Username</th>

                            {!_.isEmpty(platform) && <th>Password</th>}
                            {!_.isEmpty(platform) && <th>Second Password</th>}
                            <th>Balance</th>
                            {meData &&
                              !OperatingUsers.includes(meData.me.role) &&
                              !Operator.includes(meData.me.role) && (
                                <th>Active?</th>
                              )}
                            {platform && <th>Default?</th>}
                            {meData &&
                              !OperatingUsers.includes(meData.me.role) &&
                              !Operator.includes(meData.me.role) && (
                                <>
                                  <th>Maintenance Mode</th>
                                  <th>Bot Enabled Mode</th>
                                  <th>Auto Sync Mode</th>
                                </>
                              )}
                            <th>Last Sync At</th>
                            <th>Message </th>
                            {!_.isEmpty(platform) && <th>Actions</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {data &&
                            data.kioskAccounts.edges.map(
                              (kioskAccount, key) => (
                                <KioskAccountTableItem
                                  meData={meData}
                                  showLoginDetails={!_.isEmpty(platform)}
                                  kioskAccount={kioskAccount}
                                  key={"_kioskAccount_" + key}
                                  dropDown={kioksDropDown(kioskAccount)}
                                  onEdit={() => {
                                    setEditKioskAccount(kioskAccount.node);
                                  }}
                                  onToggleActive={(e, val) => {
                                    toggleActive(kioskAccount.node);
                                  }}
                                  onToggleDefault={() => {
                                    setKioskDefault({
                                      variables: {
                                        input: kioskAccount.node.id,
                                      },
                                    })
                                      .catch((e) => {
                                        ToastHelper.toggleToast({
                                          toastType: "error",
                                          message: e,
                                          title: "",
                                          onClick: function () {},
                                        });
                                      })
                                      .then((val) => {
                                        if (val) {
                                          ToastHelper.toggleToast({
                                            toastType: "Info",
                                            message: "Update Succesfully!",
                                            title: "",
                                            onClick: function () {},
                                          });
                                        }
                                      });
                                  }}
                                  goToPlatform={() => {
                                    // history.push(`/product-detail-platform/${kioskAccount.node.platform.id}`)
                                    setEditPlatform(kioskAccount.node.platform);
                                  }}
                                  onToggleBotEnable={() => {
                                    onToggleBotEnabled(kioskAccount.node);
                                  }}
                                  onToggleAutoSyncMode={() => {
                                    onToggleAutoSyncMode(kioskAccount.node);
                                  }}
                                />
                              )
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </InfiniteScroll>
                </Card>
              </>
            )}
            {!layoutToggle && data && (
              <InfiniteScroll
                dataLength={data.kioskAccounts.edges.length} //This is important field to render the next data
                next={() => setIsFetchMore(true)}
                hasMore={pageInfo.hasNextPage}
                loader={
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin mr-2"></i> Load more{" "}
                    </Link>
                  </div>
                }
                endMessage={
                  <div className="text-center my-3">
                    <div>Yay! You have seen it all</div>
                  </div>
                }
              >
                <Row>
                  <Col lg="12">
                    <Row>
                      {data &&
                        data.kioskAccounts.edges.map(
                          (kioskAccount, key) => (
                            <KioskItem
                              meData={meData}
                              showLoginDetails={!_.isEmpty(platform)}
                              kiosk={kioskAccount}
                              key={"_kioskAccount_" + key}
                              dropDown={kioksDropDown(kioskAccount)}
                              onEdit={() => {
                                setEditKioskAccount(kioskAccount.node);
                              }}
                              onToggleActive={(e, val) => {
                                toggleActive(kioskAccount.node);
                              }}
                              goToPlatform={() => {
                                setEditPlatform(kioskAccount.node.platform);
                                // history.push(`/product-detail-platform/${kioskAccount.node.platform.id}`)
                              }}
                              onToggleBotEnable={() => {}}
                            />
                          )
                          // JSON.stringify(platform)
                        )}
                      {data && data.kioskAccounts.edges.length === 0 && (
                        <Col xl="12" sm="12">
                          <div className="no-kiosk">No Kiosk Account Found</div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </InfiniteScroll>
            )}
            <div className="load-more-container">
              {loading && (
                <Col xs="12">
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin mr-2"></i> Load more{" "}
                    </Link>
                  </div>
                </Col>
              )}
            </div>
            {meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role) && (
                <>
                  <h6 className="m-2">Telegram Bot Settings</h6>
                  <Card>
                    <CardBody>
                      {/* <Row>
                                    <SettingEditor description="Telegram Pinned Message" settingKey="pinnedMessage" fieldType="TextArea" />
                                    <SettingEditor description="Telegram Game Tips" settingKey="gameTips" fieldType="TextArea" />
                                </Row> */}
                      <Nav
                        pills
                        className="bg-light rounded mt-4"
                        role="tablist"
                      >
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "1",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("1");
                            }}
                          >
                            Telegram Pinned Message
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "2",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("2");
                            }}
                          >
                            Telegram Game Tips
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "2.1",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("2.1");
                            }}
                          >
                            Telegram Terms & Conditions
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "3",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("3");
                            }}
                          >
                            Bank Account List
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "4",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("4");
                            }}
                          >
                            Deposit - First Step
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "5",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("5");
                            }}
                          >
                            Deposit - Second Step
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "6",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("6");
                            }}
                          >
                            Deposit - Upload
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "7",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("7");
                            }}
                          >
                            Withdrawal - First Step
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "8",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("8");
                            }}
                          >
                            Withdrawal - Second Step
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "9",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("9");
                            }}
                          >
                            Notice For Agent Menu Step
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "10",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("10");
                            }}
                          >
                            Notice For Agent Invite Step
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "11",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("11");
                            }}
                          >
                            Notice For Agent Link Step
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "12",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("12");
                            }}
                          >
                            Bot Start Message
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classname({
                              active: activeTab === "13",
                              "font-size-12": true,
                            })}
                            onClick={() => {
                              setActiveTab("13");
                            }}
                          >
                            Bot Main Menu Media
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <SettingEditor
                            description="Telegram Pinned Message"
                            settingKey="pinnedMessage"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="2">
                          <SettingEditor
                            description="Telegram Game Tips"
                            settingKey="gameTips"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="2.1">
                          <SettingEditor
                            description="Telegram Terms & Conditions"
                            settingKey="telegramTNC"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="3">
                          <SettingEditor
                            description="Notice For Bank Account List"
                            settingKey="noticeForBankAccountList"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="4">
                          <SettingEditor
                            description="Notice for Deposit First Step"
                            settingKey="noticeForDepositFirstStep"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="5">
                          <SettingEditor
                            description="Notice for Deposit Second Step"
                            settingKey="noticeForDepositSecondStep"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="6">
                          <SettingEditor
                            description="Notice for Deposit Upload"
                            settingKey="noticeForDepositUpload"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="7">
                          <SettingEditor
                            description="Notice For Withdrawal First Step"
                            settingKey="noticeForWithdrawalFirstStep"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="8">
                          <SettingEditor
                            description="Notice For Withdrawal Second Step"
                            settingKey="noticeForWithdrawalSecondStep"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="9">
                          <SettingEditor
                            description="Notice For Agent Menu Step"
                            settingKey="noticeForAgentMenuStep"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="10">
                          <SettingEditor
                            description="Notice For Agent Invite Step"
                            settingKey="noticeForAgentInviteStep"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="11">
                          <SettingEditor
                            description="Notice For Agent Link Step"
                            settingKey="noticeForAgentLinkStep"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="12">
                          <SettingEditor
                            description="Bot Start Message"
                            settingKey="botStartMessage"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                            defaultValue={JSON.stringify([
                              { language: "EN", content: "" },
                              { language: "MS", content: "" },
                              { language: "ZH", content: "" },
                            ])}
                          />
                        </TabPane>
                        <TabPane tabId="13">
                          <SettingEditor
                            description="Bot Main Menu Media"
                            settingKey="botMainMenuMedia"
                            settingType="Bot"
                            fieldType="JSON"
                            inputValue={JSON.stringify([
                              {
                                language: "EN",
                                photo:
                                  "https://res.cloudinary.com/djtp1hhhg/image/upload/v1643288196/test/image5_phlyfq.jpg",
                              },
                              {
                                language: "MS",
                                photo:
                                  "https://res.cloudinary.com/djtp1hhhg/image/upload/v1676957791/Robot-clip-art-book-covers-feJCV3-clipart_i28qwk.png",
                              },
                              {
                                language: "ZH",
                                photo:
                                  "https://res.cloudinary.com/djtp1hhhg/image/upload/v1643288196/test/image5_phlyfq.jpg",
                              },
                            ])}
                          />
                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                  <h6 className="m-2">General Settings</h6>
                  <Row>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="New Register Credit"
                            settingKey="newRegisterCredit"
                            settingType="General"
                            fieldType="TextField"
                            inputValue="0"
                            defaultValue="0"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="Show Free Credit Words in Bot"
                            settingKey="showFreeCreditWords"
                            settingType="Bot"
                            fieldType="Boolean"
                            inputValue="false"
                            defaultValue="false"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="Maximum Game Account Limit per Kiosk"
                            settingKey="maximumGameAccountLimitPerKiosk"
                            settingType="Kiosk"
                            fieldType="TextField"
                            inputValue="5"
                            defaultValue="5"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="Maximum Game Account Limit without Deposit"
                            settingKey="maximumGameAccountLimitWithoutDeposit"
                            settingType="Kiosk"
                            fieldType="TextField"
                            inputValue="3"
                            defaultValue="3"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="Enable/Disable Phone Re-Verification"
                            settingKey="enablePhoneReverification"
                            settingType="General"
                            fieldType="Boolean"
                            inputValue="true"
                            defaultValue="true"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="VPN Download Link"
                            settingKey="vpnDownloadLink"
                            settingType="General"
                            fieldType="TextField"
                            inputValue=""
                            defaultValue=""
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="Target Withdrawable Amount For Free Credit Player (0 for no limitation)"
                            settingKey="targetWithdrawableAmountForFreeCreditPlayer"
                            settingType="General"
                            fieldType="TextField"
                            inputValue="0"
                            defaultValue="0"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="Final Withdrawable Amount For Free Credit Player (0 for no limitation)"
                            settingKey="finalWithdrawableAmountForFreeCreditPlayer"
                            settingType="General"
                            fieldType="TextField"
                            inputValue="0"
                            defaultValue="0"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={4} sm={6}>
                      <Card>
                        <CardBody>
                          <SettingEditor
                            description="Game Tips Scanner"
                            settingKey="gameTipsScanner"
                            settingType="Bot"
                            fieldType="TextField"
                            inputValue=""
                            defaultValue=""
                            pattern={{
                              value:
                                "^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$",
                              errorMessage:
                                "Invalid URL. Please enter a valid URL",
                            }}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  {addKioskAccount && (
                    <NewKioksAccount
                      modal={addKioskAccount}
                      curPlatform={platform ? platform : null}
                      toggleModal={() => {
                        refetch();
                        setAddKioskAccount(!addKioskAccount);
                      }}
                    />
                  )}
                  {!_.isEmpty(editKioskAccount) && (
                    <EditKioskAccount
                      modal={!_.isEmpty(editKioskAccount)}
                      toggleModal={() => {
                        setEditKioskAccount(!editKioskAccount);
                      }}
                      kioskAccount={editKioskAccount}
                      curPlatform={editKioskAccount.platform}
                    />
                  )}
                  {!_.isEmpty(editPlatform) && (
                    <EditPlatformModal
                      modal={!_.isEmpty(editPlatform)}
                      toggleModal={() => {
                        setEditPlatform(!editPlatform);
                      }}
                      platform={editPlatform}
                    />
                  )}
                </>
              )}
          </Container>
        </div>
      }
    </React.Fragment>
  );
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType, ...state.Kiosk };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(KioskListMain)
);

// export default ContactsList;

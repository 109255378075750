import gql from 'graphql-tag';

export const CountryFragment = gql`
    fragment CountryInfo on Country {
        id
        name
        abbreviation
        phonePrefix
        phoneNoSample
        smsPrefix
        phoneRegex
        isDefault
        defaultLanguage
        currencyId
        isActive
        isArchived
        createdAt
        updatedAt
        currency {
            id
            name
            code
        }
    }
`;
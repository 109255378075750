
import { TabContent, TabPane, NavLink, NavItem, Nav, Card, Row, Col, Container } from "reactstrap";
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import classnames from "classnames";
import KioskListMain from '../Kiosk/kioks-list-main'
import {
    changeTab
} from "../../store/actions";
import { GET_ONE_PLATFORM } from "../../graphql/query/GET_ONE_PLATFORM";
import { useQuery } from "react-apollo";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { OperatingUsers } from "../../assets/scss/role.constant";
import { ME } from "../../graphql/query";

function ProductDetails(props) {
    //hack for filter
    document.title = "Platform";

    const [tabID, setTabID] = React.useState('2')

    const [editPlatform, setEditPlatform] = React.useState()

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    useEffect(() => {
        return function cleanup() {
            props.changeTab('')
        };
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    let { id } = useParams();

    const { data: newerData, error } = useQuery(GET_ONE_PLATFORM, {
        variables: { "id": id }

    });

    useEffect(() => {
        if (newerData) {
            setEditPlatform(newerData.platform)
        }

        if (error) {
            console.log(error)
        }
    }, [newerData, error]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            {
                editPlatform &&
                <div className="page-content product-link">
                    <Container fluid>
                        <Breadcrumbs title="Platform" breadcrumbItem={`Kiosk Account (${editPlatform.displayName})`} back />
                        {/* <Row>
                            <Col xs="12">
                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                    <h4 className="mb-0 font-size-18">Kiosk Account ({editPlatform.displayName})</h4>
                                    <div className="page-title-right">
                                        <Breadcrumb listClassName="m-0">
                                            <BreadcrumbItem>
                                                <Link onClick={() => {

                                                    history.push(
                                                        `/product`
                                                    )
                                                }}>Platform</Link>
                                            </BreadcrumbItem>
                                            <BreadcrumbItem active>
                                                Kiosk Account Info
                                        </BreadcrumbItem>
                                        </Breadcrumb>
                                    </div>
                                </div>
                            </Col>
                        </Row> */}

                        <Nav tabs className="nav-tabs-custom d-none">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: tabID === "1"
                                    })}
                                    onClick={() => {
                                        setTabID("1")
                                        props.changeTab('1')
                                    }}
                                >
                                    <span className="d-none d-sm-block">Platform</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: tabID === "2"
                                    })}
                                    onClick={() => {
                                        setTabID("2")
                                        props.changeTab('2')
                                    }}
                                >
                                    <span className="d-none d-sm-block">Kiosk Account </span>
                                </NavLink>
                            </NavItem>

                        </Nav>
                        <Card>
                            <TabContent activeTab={tabID}>
                                <TabPane tabId="1" className="p-3">
                                    <Row>
                                        <Col lg="12">
                                            {/* <EditPlatform
                                                platform={editPlatform}
                                            /> */}
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId="2" className="p-3">
                                    <Row>
                                        <Col sm="12">
                                            <KioskListMain
                                                platform={editPlatform}
                                            />
                                        </Col>
                                    </Row>
                                </TabPane>

                            </TabContent>
                        </Card>
                    </Container>
                </div>
            }
        </React.Fragment>
    )
}
const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.PlatformList };
};

export default connect(mapStatetoProps, { changeTab })(withTranslation()(ProductDetails));

import React from 'react';
import { useMutation } from 'react-apollo';
import Switch from "react-switch";
import { Admin, Developer, Manager, Supervisor } from '../../assets/scss/role.constant';
import { UPDATE_ONE_REWARD_TIER } from '../../graphql/mutation/UPDATE_ONE_REWARD_TIER';
import RoleHelper from '../../helpers/RoleHelper';
import ToastHelper from '../../helpers/ToastHelper';


function TierItem(props) {

    const { onEdit, tier, onDelete } = props;

    const [updateTier] = useMutation(UPDATE_ONE_REWARD_TIER);

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const disabledRoles = !RoleHelper.checkRoles([Developer, Admin, Manager, Supervisor])

    const onUserEdit = () => {
        if (!disabledRoles) {
            onEdit(tier)
        }
    }

    return <React.Fragment>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {parseFloat(tier.node.step).toFixed(0)}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={onUserEdit}>
                {parseFloat(tier.node.rewardPoint).toFixed(0)}
            </div>
        </td>
        <td>
            <Switch
                disabled={disabledRoles}
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                id="is-active"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={() => {
                    updateTier({
                        variables: {
                            input: {
                                update: {
                                    isActive: !tier.node.isActive
                                },
                                id: tier.node.id
                            }
                        }
                    }).catch(e => {
                        ToastHelper.toggleToast({
                            toastType: "error",
                            message: e,
                            title: "",
                            onClick: function () { }
                        })
                    }).then(val => {
                        if (val) {
                            ToastHelper.toggleToast({
                                message: "Update Succesfully!",
                                title: "",
                                onClick: function () { }
                            })
                        }
                    })

                }}
                checked={tier.node.isActive}
            />
        </td>
        <td className="relative align-flex">
            <button disabled={disabledRoles} type="button" className="btn btn-primary waves-effect waves-light font-size-11 align-center ml-1" onClick={() => {
                if (onEdit) {
                    onEdit(tier)
                }
            }}>
                <div>Edit</div>
            </button>
            <button disabled={disabledRoles} type="button" className="btn btn-danger waves-effect waves-light font-size-11 align-center ml-1" onClick={() => {
                if (onDelete) {
                    onDelete(tier)
                }
            }}>
                <div>Delete</div>
            </button>
        </td>
    </React.Fragment>
}

export default TierItem;
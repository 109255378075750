import gql from "graphql-tag";

import { TelegramMenuFragment } from "../fragments/telegram-menu.fragment";

export const UPDATE_ONE_TELEGRAM_MENU = gql`
  mutation ($input: UpdateOneTelegramMenuInput!) {
    updateOneTelegramMenu(input: $input) {
      ...TelegramMenu
    }
  }
  ${TelegramMenuFragment}
`;

import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Col,
    Row,
    Button,
    Card
} from "reactstrap";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";

function DropZone(props) {

    const { onFileDrop, selectedFile, currentImage } = props;

    const handleAcceptedFiles = files => {
        onFileDrop(files[0]);
    };

    return (
        <React.Fragment>
            <Row>
                {
                    currentImage &&
                    <Col lg={12}>
                        <div className="dropzone-previews mb-3" id="file-previews">
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={"file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            <img
                                                alt="img"
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                src={currentImage}
                                            />
                                        </Col>
                                        <Col>
                                            <div to="#" className="text-muted font-weight-bold">
                                                Current File
                                            </div>
                                        </Col>
                                        {
                                            // !onUpload &&
                                            <Col>
                                                <Button className="pr-2 float-right" close aria-label="Cancel" onClick={() => {
                                                    onFileDrop(null);
                                                }} >
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </Card>
                        </div>
                    </Col>
                }
                {
                    selectedFile &&
                    <Col lg={12}>
                        <div className="dropzone-previews mb-3" id="file-previews">
                            <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={"file"}
                            >
                                <div className="p-2">
                                    <Row className="align-items-center">
                                        <Col className="col-auto">
                                            <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                alt={selectedFile.name}
                                                src={URL.createObjectURL(selectedFile)}
                                            />
                                        </Col>
                                        <Col>
                                            <div to="#" className="text-muted font-weight-bold">
                                                {selectedFile.name}
                                            </div>
                                        </Col>
                                        {
                                            // !onUpload &&
                                            <Col>
                                                <Button className="pr-2 float-right" close aria-label="Cancel" onClick={() => {
                                                    onFileDrop(null);
                                                }} >
                                                </Button>
                                            </Col>
                                        }
                                    </Row>
                                </div>
                            </Card>
                        </div>
                    </Col>
                }
                {
                    !selectedFile &&
                    !currentImage &&
                    <Col lg={12}>
                        <Dropzone
                            accept={"image/jpeg, image/png, image/gif, audio/*, video/mp4"}
                            onDrop={acceptedFiles =>
                                handleAcceptedFiles(acceptedFiles)
                            }
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone mb-3">
                                    <div
                                        className="dz-message needsclick mt-2"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="mb-2">
                                            <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                        </div>
                                        <h4 className="font-size-13">Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                    </Col>
                }
            </Row>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(DropZone));
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';
import { BankAccountFragment } from '../fragments/bank.fragment';

export const CREATE_ONE_USER = gql`
    mutation(
        $input: CreateOneUserInput!
    ) {
        createOneUser (input: $input) {
            ...UserInfo
            bankAccounts {
                edges {
                    node {
                        ...BankAccountInfo
                    }
                }
            }
        }
    }
    ${UserFragment}
    ${BankAccountFragment}
`;
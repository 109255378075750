import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const PROCESS_ONE_CREDITIN = gql`
    mutation(
        $id: String!
    ) {
        processOneCreditIn (
            id: $id
        ) {
            ...TransactionInfo       
        }
    }
    ${TransactionFragment}
`;
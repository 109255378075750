// @flow
import {
    CHANGE_FILTER,
    CHANGE_SORT
} from "../../common/actionTypes";

const INIT_STATE = {
    filter: {
        and: [
            {
                or: [
                    { canDeposit: { is: true } },
                    { canWithdraw: { is: true } }
                ]
            },
        ]
    },
    sort: []
};

const BankAccounts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_FILTER:
            if (action.payload.belongs === "bank-accounts") {
                return {
                    ...state,
                    filter: action.payload.filter
                };
            } else {
                return {
                    ...state
                }
            }

        case CHANGE_SORT:
            return {
                ...state,
                sort: action.payload
            };
        default:
            return state;
    }
};

export default BankAccounts;

import gql from 'graphql-tag';
import { WalletFragment } from '../fragments/wallet.fragment';

export const RECALCULATE_WALLET_BALANCE = gql`
    mutation (
        $id: String!
    ) {
        recalculateWalletBalance (
            id: $id
        ) {
            ...WalletInfo
        }
    }
    ${WalletFragment}
`;
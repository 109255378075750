import gql from 'graphql-tag';

export const CREATE_ONE_WITHDRAWAL = gql`
    mutation(
        $input: CreateOneWithdrawalInput!
    ) {
        createOneWithdrawal (input: $input) {
            id
        }
    }
`;
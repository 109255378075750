import gql from 'graphql-tag';

import { TagFragment } from '../fragments/tag.fragment';

export const UPDATE_ONE_TAG = gql`
    mutation(
        $input: UpdateOneTagInput!
    ) {
        updateOneTag(input:$input){
            ...TagInfo  
        }
    }
    ${TagFragment}
`;


import gql from "graphql-tag";
import { TelegramButtonLanguageFragment } from "../fragments/telegram-button-language.fragment";

export const UPDATE_ONE_TELEGRAM_BUTTON_LANGUAGE = gql`
  mutation ($input: UpdateOneTelegramButtonLanguageInput!) {
    updateOneTelegramButtonLanguage(input: $input) {
      ...TelegramButtonLanguage
    }
  }
  ${TelegramButtonLanguageFragment}
`;

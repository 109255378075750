import gql from 'graphql-tag';
import { PaymentTypeFragment } from '../fragments/payment.type.fragment';

export const CREATE_ONE_PAYMENT_TYPE = gql`
    mutation(
        $input: CreateOnePaymentTypeInput!
    ) {
        createOnePaymentType (input: $input) {
            ...PaymentType
        }
    }
    ${PaymentTypeFragment}
`;
import React, { useEffect, Fragment } from "react";

import { connect } from "react-redux";
import {
    hideRightSidebar,
    changeFilter,
    changeSort
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";
import DatePicker from "react-datepicker";

import { Link } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { GET_COUNTRY } from '../../graphql/query';
import _ from 'lodash';
import { AvForm } from "availity-reactstrap-validation";
import moment from 'moment';
import { Typeahead } from 'react-bootstrap-typeahead';

import "../../components/CommonForBoth/rightbar.scss"

function BankFilter(props) {

    //filter
    const [createStart, setCreateStart] = React.useState(null)
    const [createEnd, setCreateEnd] = React.useState(null)
    const [updateStart, setUpdateStart] = React.useState(null)
    const [updateEnd, setUpdateEnd] = React.useState(null)
    const [country, setCountry] = React.useState([])

    //sort
    const [sortBankName, setSortBankName] = React.useState(null)
    const [sortCreate, setSortCreate] = React.useState(null)
    const [sortUpdate, setSortUpdate] = React.useState(null)

    const { data: countryData, loading: loadingCountry } = useQuery(GET_COUNTRY, {
        variables: {
            filter: {}
        },
        notifyOnNetworkStatusChange: true
    });

    const hideRightSidebar = (e) => {
        e.preventDefault();
        props.hideRightSidebar();
    }


    const changeFilter = (data) => {
        props.changeFilter(data);
    }

    const changeSort = (data) => {
        props.changeSort(data);
    }

    const GenerateDateFilter = (type, startDate, endDate) => {
        if (startDate && endDate) {
            return {
                and: [{
                    [type]: { gt: `${moment(startDate)}` }
                }, {
                    [type]: { lt: `${moment(endDate).add('hours', 23).add('minutes', 59).add('seconds', 59)}` }
                }]
            }
        }
        return { [type]: { lt: new Date() } }
    }

    const ResetFilter = () => {
        setCountry([])
        setCreateStart(null)
        setCreateEnd(null)
        setUpdateStart(null)
        setUpdateEnd(null)
    }

    const ResetSort = () => {
        setSortBankName(null)
        setSortCreate(null)
        setSortUpdate(null)
    }

    useEffect(() => {
        ResetFilter()
        ResetSort()
    }, [])

    const Filter = () => {
        if (country.length > 0) {
            return {
                and: [
                    {
                        country: {
                            id: {
                                in: _.map(country, item => {
                                    return item.node.id
                                })
                            }
                        }
                    },
                    GenerateDateFilter('createdAt', createStart, createEnd),
                    GenerateDateFilter('updatedAt', updateStart, updateEnd)
                ]
            }
        } else {
            return {
                and: [
                    GenerateDateFilter('createdAt', createStart, createEnd),
                    GenerateDateFilter('updatedAt', updateStart, updateEnd)
                ]
            }
        }
    }

    useEffect(() => {
        changeFilter({
            belongs: "bank",
            filter: Filter()
        })
    }, [country, createStart, createEnd, updateStart, updateEnd]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let sorts = []

        if (sortBankName !== null) {
            sorts.push({
                field: "name", direction: sortBankName
            })
        }

        if (sortCreate !== null) {
            sorts.push({
                field: "createdAt", direction: sortCreate
            })
        }

        if (sortUpdate !== null) {
            sorts.push({
                field: "updatedAt", direction: sortUpdate
            })
        }

        changeSort(sorts)
    }, [sortBankName, sortCreate, sortUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="right-bar bank-filter-page bank-link">

                <SimpleBar style={{ height: "100vh" }}>
                    <div data-simplebar className="h-100">
                        <AvForm className="needs-validation" >

                            <div className="rightbar-title px-3 py-4">
                                <Link to="#" onClick={hideRightSidebar} className="right-bar-toggle float-right">
                                    <i className="mdi mdi-close noti-icon"></i>
                                </Link>
                                <h5 className="m-0">Bank Filter</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetFilter}>
                                        Reset Filter
                                    </button>
                                </div>
                                <span className="mb-2 d-block">Country Name</span>
                                <Typeahead
                                    id="country-list"
                                    isLoading={loadingCountry}
                                    labelKey={option => `${option.node.name}`}
                                    // minLength={0}
                                    // onSearch={handleCountrySearch}
                                    // onInputChange={(val) => {
                                    //     if (val === "") {
                                    //         handleCountrySearch('')
                                    //     }
                                    // }}
                                    multiple
                                    options={countryData ? countryData.countries.edges : []}
                                    placeholder="Search for Country"
                                    defa
                                    selected={country}
                                    renderMenuItemChildren={(option, props) => (
                                        <Fragment>
                                            <span>{option.node.name}</span>
                                        </Fragment>
                                    )}
                                    // isInvalid={!isValidCountry}
                                    onChange={(val) => {
                                        if (val.length > 0) {

                                            setCountry(val)
                                        } else {
                                            setCountry([])
                                        }
                                    }}
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Created From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={createStart}
                                    onChange={date => {
                                        if (date > createEnd) {
                                            setCreateEnd(date)
                                        }
                                        setCreateStart(date)
                                    }}
                                    selectsStart
                                    startDate={createStart}
                                    endDate={createEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={createEnd}
                                    onChange={date => {
                                        setCreateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={createStart}
                                    endDate={createEnd}
                                    minDate={createStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Updated From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={updateStart}
                                    onChange={date => {
                                        if (date > updateEnd) {
                                            setUpdateEnd(date)
                                        }
                                        setUpdateStart(date)
                                    }}
                                    selectsStart
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={updateEnd}
                                    onChange={date => {
                                        setUpdateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    minDate={updateStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />

                                <hr className="mt-1" />
                            </div>

                            <div className="rightbar-title px-3 py-4  sorting-title">
                                <h5 className="m-0"> Bank Sorting</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetSort}>
                                        Reset Sort
                                    </button>
                                </div>
                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Name</span>
                                    <input
                                        className="name-sort"
                                        type="radio"
                                        id="nameAsc"
                                        name="nameSort"
                                        value="ASC"
                                        checked={sortBankName === 'ASC'}
                                        onClick={() => {
                                            if (sortBankName === 'ASC') {
                                                setSortBankName(null)
                                            } else {
                                                setSortBankName('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="nameAsc">ASC</label>
                                    {"   "}
                                    <input
                                        className="name-sort"
                                        type="radio"
                                        id="nameDesc"
                                        name="nameSort"
                                        value="DESC"
                                        checked={sortBankName === 'DESC'}
                                        onClick={() => {
                                            if (sortBankName === 'DESC') {
                                                setSortBankName(null)
                                            } else {
                                                setSortBankName('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="nameDesc">DESC</label>
                                </div>


                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Created From</span>
                                    <input
                                        type="radio"
                                        id="createAsc"
                                        name="createSort"
                                        value="ASC"
                                        checked={sortCreate === 'ASC'}
                                        onClick={() => {
                                            if (sortCreate === 'ASC') {
                                                setSortCreate(null)
                                            } else {
                                                setSortCreate('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="createAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="createDesc"
                                        name="createSort"
                                        value="DESC"
                                        checked={sortCreate === 'DESC'}
                                        onClick={() => {
                                            if (sortCreate === 'DESC') {
                                                setSortCreate(null)
                                            } else {
                                                setSortCreate('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="createDesc">DESC</label>
                                </div>

                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Updated From</span>
                                    <input
                                        type="radio"
                                        id="updateAsc"
                                        name="updateSort"
                                        value="ASC"
                                        checked={sortUpdate === 'ASC'}
                                        onClick={() => {
                                            if (sortUpdate === 'ASC') {
                                                setSortUpdate(null)
                                            } else {
                                                setSortUpdate('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="updateAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="updateDesc"
                                        name="updateSort"
                                        value="DESC"
                                        checked={sortUpdate === 'DESC'}
                                        onClick={() => {
                                            if (sortUpdate === 'DESC') {
                                                setSortUpdate(null)
                                            } else {
                                                setSortUpdate('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="updateDesc">DESC</label>
                                </div>
                            </div>


                        </AvForm>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay" onClick={hideRightSidebar}></div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeFilter,
    changeSort
})(BankFilter);

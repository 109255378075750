
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import {  Col, Card, Row, CardBody } from "reactstrap";
import { Link } from "react-router-dom";

function ProfileInfo(props) {
    const {user} = props;
    // id
    // name
    // username
    // phoneNo
    // role
    // isActive
    // isArchived
    // createdAt
    // updatedAt
    return ( <Card>
        <CardBody className="">
            <Row>
                {/* <Col lg="2">
                    <div className="avatar-md mb-3">
                        <img src={avatar1} alt="" className="img-thumbnail rounded-circle" />
                    </div>
                </Col> */}
                <Col className="text-align pb-2">
                    <div>
                        <div className="header">Account Username</div>
                        <div className="font-size-14"><Link to="#" className="text-dark">{user.name}</Link></div>
                    </div>
                    <div>
                        <div className="header">Role</div>
                        <div className="font-size-14"><Link to="#" className="text-dark">{user.role}</Link></div>

                    </div>

                </Col>
                <Col className="text-align pb-2">
                    <div>
                        <div className="header">Phone Number</div>
                        <div className="font-size-14"><Link to="#" className="text-dark">{user.phoneNo?user.phoneNo:"-"}</Link></div>
                    </div>
                    <div>
                        <div className="header">Active</div>
                        <div className="font-size-14"><Link to="#" className="text-dark">{user.isActive?"YES":"NO"}</Link></div>

                    </div>

                </Col>
            </Row>
        </CardBody>

    </Card>)
}


export default connect()(withTranslation()(ProfileInfo));

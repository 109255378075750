import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { WalletEntryFragment } from '../fragments/wallet-entries';

export const WALLET_ENTRIES = gql`
    query (
        $paging: CursorPaging
        $filter: WalletEntryFilter
        $sorting: [WalletEntrySort!]
    ) {
        walletEntries (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...WalletEntryInfo
                }
            }
        }
    }
    ${PageFragment}
    ${WalletEntryFragment}
`;
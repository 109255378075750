import React, { useRef } from 'react';
import { connect } from "react-redux";
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { Container, Row, Col, Card, CardBody, Table, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { GET_GAME_ACCOUNTS } from '../../graphql/query/GET_GAME_ACCOUNTS';

import _ from 'lodash';

import { Link } from 'react-router-dom';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import GameAccountItem from './game-account-item';
import NewGameAccount from './new-game-account';
import { CREATED_GAME_ACCOUNT } from '../../graphql/subscription/CREATED_GAME_ACCOUNT';
import EditGameAccount from './edit-game-account';
import { useHistory } from "react-router-dom";
import GameAccountTableItem from './game-account-table-item';
import { ME } from '../../graphql/query';
import InfiniteScroll from 'react-infinite-scroll-component';

function GameAccountList(props) {
    const [newGameAccount, setNewGameAccount] = React.useState(false)
    const [search, setSearch] = React.useState("")
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(null)
    const [newData, setNewData] = React.useState(false);
    const [pageInfo, setPageInfo] = React.useState(false)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [editGameAccount, setEditGameAccount] = React.useState(false);
    const [isFetchMore, setIsFetchMore] = React.useState(false);

    const [layoutToggle, setLayoutToggle] = React.useState(true);

    const first = 5


    const curKioskAccount = props.location.state.kioskAccount;
    const curPlatform = { ...props.location.state.platform }


    const { data: meData } = useQuery(ME);

    const { loading, fetchMore, data, refetch } = useQuery(GET_GAME_ACCOUNTS, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: {
                and: [
                    {
                        or: [
                            { username: { iLike: `%${search}%` } },
                        ]
                    },
                    props.filter,
                    {
                        kioskAccountId: { eq: curKioskAccount.id }
                    }
                ]
            },
            sorting: props.sort
        },
        notifyOnNetworkStatusChange: true
    })

    React.useEffect(() => {
        if (data) {

            setAfterCursor(data.gameAccounts.pageInfo.endCursor)
            setPageInfo(data.gameAccounts.pageInfo)
        }
    }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

    const { data: gameAccountCreatedData } = useSubscription(CREATED_GAME_ACCOUNT, {
        variables: {
            createdBy: { neq: meData ? meData.me.username : '' }
        }
    })

    React.useEffect(() => {
        if (gameAccountCreatedData) {
            setNewData(gameAccountCreatedData.createdGameAccount)
        }
    }, [gameAccountCreatedData])

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: search !== '' ? {
                        and: [
                            {
                                or: [
                                    { username: { iLike: `%${search}%` } },
                                ]
                            },
                            props.filter,
                            {
                                kioskAccountId: { eq: curKioskAccount.id }
                            }
                        ]
                    } : {
                            and: [props.filter,
                            {
                                kioskAccountId: { eq: curKioskAccount.id }
                            }]
                        },
                    sorting: props.sort
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.gameAccounts.edges;
                    const pageInfo = fetchMoreResult.gameAccounts.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            gameAccounts: {
                                __typename: previousResult.gameAccounts.__typename,
                                edges: _.uniq([...previousResult.gameAccounts.edges, ...newEdges]),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    const history = useHistory();
    return (
        <React.Fragment>
            <div className="page-content game-link">
                <Container fluid>

                    <Row>
                        <Col xs="12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0 font-size-18">{`Game Account List for ${curKioskAccount.username}`}</h4>
                                <div className="page-title-right">
                                    <Breadcrumb listClassName="m-0">
                                        <BreadcrumbItem>
                                            <Link onClick={() => {
                                                console.log(curPlatform)
                                                history.push(
                                                    `/product`
                                                )
                                            }}>Product</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link onClick={() => {
                                                console.log(curPlatform)
                                                history.push(
                                                    `/product-detail-platform/${curPlatform.id}`
                                                )

                                                // history.goBack()
                                            }}>{curPlatform.displayName + " - " + curKioskAccount.username}</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            Game Account
                                        </BreadcrumbItem>
                                    </Breadcrumb>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className="search-bar-container">
                        <div className="button-add-game-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="button-add-game-container">
                            <div className="filter-container button-filter-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-game" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div>
                            <div className="filter-container button-filter-container px-1">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-game" onClick={() => {
                                    setLayoutToggle(!layoutToggle)
                                }}>
                                    <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div>
                            <button type="button" className="waves-effect btn btn-outline-secondary button-add-game" onClick={() => {


                                setNewGameAccount(true)


                            }}>
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Game Account
                            </button>
                        </div>

                        <div className={`reload-container ${newData ? 'active' : ''}`}>
                            <Card>
                                <CardBody onClick={() => {
                                    window.location.reload()
                                }}>
                                    <div className="reload-title">
                                        <i className='bx bx-refresh'></i>Reload
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                    </div>

                    {
                        layoutToggle &&
                        data &&
                        <Card>
                            <InfiniteScroll
                                dataLength={data.gameAccounts.edges.length} //This is important field to render the next data
                                next={() => setIsFetchMore(true)}
                                hasMore={pageInfo.hasNextPage}
                                loader={
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                }
                                endMessage={
                                    <div className="text-center my-3">
                                        <div>Yay! You have seen it all</div>
                                    </div>
                                }
                            >
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Player</th>
                                                <th>Platform</th>
                                                <th>Kiosk Account</th>
                                                <th>Username</th>
                                                <th>Password</th>
                                                <th>Game Account Status</th>
                                                <th>Balance</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data &&
                                                data.gameAccounts.edges.map((gameAccount, key) =>

                                                    <GameAccountTableItem
                                                        gameAccount={gameAccount.node}
                                                        key={"_gameAccount_" + key}
                                                        onEdit={() => {
                                                            setEditGameAccount(gameAccount.node)
                                                        }}
                                                    />

                                                )
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </InfiniteScroll>
                        </Card>
                    }
                    {
                        !layoutToggle &&
                        <InfiniteScroll
                            dataLength={data.gameAccounts.edges.length} //This is important field to render the next data
                            next={() => setIsFetchMore(true)}
                            hasMore={pageInfo.hasNextPage}
                            loader={
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                </div>
                            }
                            endMessage={
                                <div className="text-center my-3">
                                    <div>Yay! You have seen it all</div>
                                </div>
                            }
                        >
                            <Row>
                                <Col lg="12">
                                    <Row>
                                        {
                                            data &&
                                            data.gameAccounts.edges.map((gameAccount, key) =>
                                                <GameAccountItem
                                                    gameAccount={gameAccount}
                                                    key={"_gameAccount_" + key}
                                                    onEdit={() => {
                                                        setEditGameAccount(gameAccount.node)
                                                    }}
                                                />
                                            )
                                        }
                                        {
                                            data &&
                                            data.gameAccounts.edges.length === 0 &&
                                            <Col xl="12" sm="12">
                                                <div className="no-game">No Game Account` Found</div>
                                            </Col>
                                        }
                                    </Row>
                                </Col>
                            </Row>
                        </InfiniteScroll>
                    }

                    <div className="load-more-container">
                        {
                            (loading) &&
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                </div>
                            </Col>
                        }
                    </div>
                    {
                        newGameAccount &&
                        <NewGameAccount
                            modal={newGameAccount}
                            toggleModal={() => {
                                refetch()
                                setNewGameAccount(!newGameAccount)
                            }}
                            curKioskAccount={curKioskAccount}
                        />
                    }

                    {
                        !_.isEmpty(editGameAccount) &&
                        <EditGameAccount
                            toggleModal={() => {
                                setEditGameAccount(!editGameAccount);
                            }}
                            modal={!_.isEmpty(editGameAccount)} gameAccount={editGameAccount}>
                        </EditGameAccount>
                    }
                </Container>
            </div>
        </React.Fragment>)
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Game };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(GameAccountList));


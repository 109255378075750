import React, { useEffect, Fragment, useRef } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Card
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';
import { GET_PLAYER_TAGS } from '../../graphql/query/GET_PLAYER_TAGS';
import { ADD_TAG_USER } from '../../graphql/mutation/ADD_TAG_USER';
import { REMOVE_TAG_USER } from '../../graphql/mutation/REMOVE_TAG_USER';

export default function ManageTags(props) {

    const { modal, toggleModal, user } = props;

    const [isValidTag, setIsValidTag] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [curUserTag, setCurUserTag] = React.useState([]);
    // const [tagList, setTagList] = React.useState([]);

    const [filterExisiting, setfilterExisiting] = React.useState({});
    const [removeTags] = useMutation(REMOVE_TAG_USER);
    const [addTags] = useMutation(ADD_TAG_USER);

    const typeaheadRef = useRef(null);


    const handleTagSearch = (data) => {
        setSearch(data)
    }

    const filterSetting = () => {
        let filterArr = [];
        filterArr.push({ name: { iLike: `%${search}%` } })


        if (user.tags > 0) {
            filterArr.push(filterExisiting)
        }
        console.log("filter", filterArr);
        return {
            and: filterArr
        }
    }

    const { data, loading: isLoading } = useQuery(GET_PLAYER_TAGS, {
        variables: {
            filter: filterSetting()
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });



    useEffect(() => {
        if (user) {
            setfilterExisiting(constructFilter(user));
        }
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setSearch("");
        setIsValidTag(true);
        if (user) {
            setCurUserTag(user ? _.map(user.tags.edges, 'node') : []);
        }
        // setCreateTagState(false);
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    const constructFilter = (user) => {
        if (user.tags === 0) return {}
        const usertags = user.tags.edges;
        let arr = _.map(usertags, "id")
        let value = { id: { notIn: arr } };

        return value
    }




    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                {
                    user &&
                    <div className="modal-content player-tags-page">
                        <ModalHeader toggle={toggleModal}>
                            Manage Player Tags
                        </ModalHeader>
                        <ModalBody>
                            <Card id="reset-password-wizard" className="twitter-bs-wizard m-1 p-2">
                                <AvForm className="needs-validation" onValidSubmit={(event, values) => {

                                }}>

                                    <Row className="align-items-center">
                                        <Col lg="12">
                                            <FormGroup className={`${!isValidTag ? 'text-danger' : ''}`}>
                                                <Label for="newrole-input">Tags for {user.name}</Label>
                                                <AsyncTypeahead
                                                    multiple={true}

                                                    delay={0}
                                                    id="tag"
                                                    isLoading={isLoading}
                                                    labelKey={option => `${option.name}`}
                                                    minLength={0}
                                                    onSearch={handleTagSearch}
                                                    onInputChange={(val) => {

                                                        // setInputValue(val);
                                                        // console.log(val)
                                                        if (val === "") {
                                                            setSearch(val)
                                                        }
                                                    }}

                                                    options={data ? _.differenceBy(data.tags.edges.map(({ node }, index) => {
                                                        return node
                                                    }), curUserTag.edges, 'node.id') : []}
                                                    placeholder="Search for Tags"
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <span>{option.name}</span>
                                                        </Fragment>
                                                    )}
                                                    errorMessage="Enter Tag"
                                                    isInvalid={!isValidTag}
                                                    onChange={(val) => {
                                                        // setTag(val)
                                                        console.log(val)

                                                        if (curUserTag.length === 0) {
                                                            addTags({
                                                                variables: {
                                                                    input: {
                                                                        id: user.id,
                                                                        relationIds: val.map((obj) => { return obj.id })
                                                                    }
                                                                }
                                                            }).catch((e) => {
                                                                ToastHelper.toggleToast({
                                                                    toastType: "error",
                                                                    message: e,
                                                                    title: "",
                                                                    onClick: function () { }
                                                                })
                                                            }).then(() => {
                                                                setCurUserTag(val);
                                                            })
                                                        } else {
                                                            removeTags({
                                                                variables: {
                                                                    input: {
                                                                        id: user.id,
                                                                        relationIds: curUserTag.map((obj) => { return obj.id })
                                                                    }
                                                                }
                                                            }).catch((e) => {
                                                                ToastHelper.toggleToast({
                                                                    toastType: "error",
                                                                    message: e,
                                                                    title: "",
                                                                    onClick: function () { }
                                                                })
                                                            }).then(() => {
                                                                if (val.length > 0) {
                                                                    addTags({
                                                                        variables: {
                                                                            input: {
                                                                                id: user.id,
                                                                                relationIds: val.map((obj) => { return obj.id })
                                                                            }
                                                                        }
                                                                    }).catch((e) => {
                                                                        ToastHelper.toggleToast({
                                                                            toastType: "error",
                                                                            message: e,
                                                                            title: "",
                                                                            onClick: function () { }
                                                                        })
                                                                    }).then(() => {
                                                                        setCurUserTag(val);
                                                                    })
                                                                } else {
                                                                    setCurUserTag(val);
                                                                }

                                                            })

                                                        }

                                                    }}
                                                    onBlur={(e) => {
                                                        // if (isValidTag && country.length === 0) {
                                                        //     setIsValidTag(false)
                                                        // }
                                                    }}
                                                    selected={curUserTag}
                                                    ref={typeaheadRef}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>

                                </AvForm>
                            </Card>
                        </ModalBody>
                    </div>
                }
            </Modal>
        </React.Fragment>
    );
}
import gql from "graphql-tag";

export const AttendanceFragment = gql`
  fragment AttendanceInfo on AttendanceStep {
    id
    step
    rewardPoint
    attendanceType
    isActive
    isArchived
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
`;

import gql from 'graphql-tag';
import { GameAccountFragment } from '../fragments/game-account.fragment';

export const UPDATE_GAME_ACCOUNT_BALANCE = gql`
    subscription($input: UpdateOneGameAccountSubscriptionFilterInput) {
        updatedOneGameAccount(input: $input) {
            ...GameAccountInfo
        }
    }
    ${GameAccountFragment}
`;
import gql from "graphql-tag";
import { TransactionFragment } from "../fragments/transaction.fragment";

export const ON_HOLD_ONE_TRANSACTION = gql`
  mutation ($id: String!) {
    onHoldOneTransaction(id: $id) {
      ...TransactionInfo
    }
  }
  ${TransactionFragment}
`;

import * as React from 'react';
import _ from 'lodash';

interface Props {
    name: any
    style?: any
    nameSize?: any
    id: any
}

export default function Avatar(props: Props) {
    const { name, style, nameSize } = props;
    let shortName = ""

    if (_.isEmpty(name)) {
        shortName = ''
    } else {
        let shortNameArr = name.split(' ').slice(0, 2)

        _.forEach(shortNameArr, name => {
            shortName = shortName + name.charAt(0)
        })
    }

    return (
        <div style={style}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                height: 35,
                width: 35,
                borderRadius: 35,
                backgroundColor: '#f3f3f9',
            }}>
                {
                    <div style={{
                        fontSize: nameSize,
                    }}>{shortName}</div>
                }
            </div>
        </div>
    );
}

Avatar.defaultProps = {
    nameSize: 16
}
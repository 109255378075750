import gql from 'graphql-tag';
import { CountryFragment } from '../fragments/country.fragment';

export const CREATE_ONE_COUNTRY = gql`
    mutation(
        $input: CreateOneCountryInput!
    ) {
        createOneCountry (input: $input) {
            ...CountryInfo
        }
    }
    ${CountryFragment}
`;
import React, { Fragment } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Col, Container, Progress, Row } from "reactstrap";

//Import Breadcrumb
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  OperatingUsers,
  Operator,
  Supervisor,
} from "../../../assets/scss/role.constant";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AgentWalletView from "../../../components/CommonForBoth/AgentWalletView";
import DailyAttendanceWallet from "../../../components/CommonForBoth/DailyAttendanceWallet";
import MainWalletView from "../../../components/CommonForBoth/MainWalletView";
import RewardWallet from "../../../components/CommonForBoth/RewardWallet";
import WeeklyAttendanceWallet from "../../../components/CommonForBoth/WeeklyAttendanceWallet";
import {
  CREATE_ONE_USER,
  UPDATE_ONE_BANK_ACCOUNT,
  WALLET_LIST,
} from "../../../graphql/mutation";
import { ME, USER } from "../../../graphql/query";
import { GET_TRANSACTION_AGGREGATE } from "../../../graphql/query/GET_TRANSACTION_AGGREGATE";
import { GET_USER } from "../../../graphql/query/GET_USER";
import ToastHelper from "../../../helpers/ToastHelper";
import EditUser from "../../Contacts/edit-user";
import ManageTags from "../../Player/player-tags";
import KioskAlert from "../Components/kiosk-alert";
import WalletInOut from "../Components/wallet-in-out";
import BankerCreditInOut from "./banker-credit-in-out";
import BankerDeposit from "./banker-deposit";
import BankerFreeCredit from "./banker-free-credit";
import BankerLogout from "./banker-logout-account";
import BankerRebate from "./banker-rebate";
import BankerTransfer from "./banker-transfer";
import BankerWithdraw from "./banker-withdraw";
import PlayerInfo from "./player-info";

function BankerWorkbench(props) {
  const { t } = props;
  const [action, setAction] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [toggleBank, setToggleBank] = React.useState(null);
  const [showToggleBank, setShowToggleBank] = React.useState(false);
  const [editUser, setEditUser] = React.useState(null);
  const [toTab, setToTab] = React.useState(null);
  const [newPlayer, setNewPlayer] = React.useState(false);
  const [player, setPlayer] = React.useState(null);
  const [manageTag, setMangeTag] = React.useState(false);
  const [recentSearch, setRecentSearch] = React.useState(
    JSON.parse(window.localStorage.getItem("RECENT_SEARCH"))
      ? JSON.parse(window.localStorage.getItem("RECENT_SEARCH"))
      : []
  );
  const locState = props.location.state;

  const [movementType, setMovementType] = React.useState(null);
  const [walletId, setWalletId] = React.useState(null);
  const [needRefetch, setNeedRefetch] = React.useState(null);
  const [fromWallet, setFromWallet] = React.useState(null);

  const { data: meData } = useQuery(ME, {
    fetchPolicy: "cache-first",
  });

  const [runUsers, { data: userList, loading: loadingUserList }] = useLazyQuery(
    GET_USER,
    {
      variables: {
        paging: {
          first: 50,
        },
        filter: {
          and: [
            {
              or: [
                { serialNo: { iLike: `%${search}%` } },
                { username: { iLike: `%${search}%` } },
                {
                  phoneNo:
                    meData &&
                      !Operator.includes(meData.me.role) &&
                      !OperatingUsers.includes(meData.me.role)
                      ? { iLike: `%${search}%` }
                      : { eq: `${search}` },
                },
              ],
            },
            {
              role: { eq: "Player" },
            },
          ],
        },
        sorting: {
          field: "username",
          direction: "ASC",
        },
      },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const [runUser, { data: userData, loading: loadingUser }] = useLazyQuery(
    USER,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    }
  );

  const [getDeposit, { data: depositData }] = useLazyQuery(
    GET_TRANSACTION_AGGREGATE,
    {
      fetchPolicy: "network-only",
    }
  );

  const [getWithdraw, { data: withdrawData }] = useLazyQuery(
    GET_TRANSACTION_AGGREGATE,
    {
      fetchPolicy: "network-only",
    }
  );

  const [createOneUser] = useMutation(CREATE_ONE_USER);
  const [walletList, { loading: loadingWalletList }] = useMutation(WALLET_LIST);
  const [updateOneBankAccount] = useMutation(UPDATE_ONE_BANK_ACCOUNT);

  const GetPhoneNumber = (phoneNo) => {
    if (meData && meData.me) {
      if (
        (Operator.includes(meData.me.role) ||
          OperatingUsers.includes(meData.me.role) ||
          Supervisor.includes(meData.me.role)) && phoneNo
      ) {
        return `******${phoneNo.substr(phoneNo.length - 4)}`;
      } else {
        return phoneNo;
      }
    }

    return "";
  };

  const GetDepositAndWithdraw = (player) => {
    getDeposit({
      variables: {
        filter: {
          transactionType: { eq: "Deposit" },
          playerId: { eq: player.node.id },
          transactionStatus: { eq: "Approved" },
        },
      },
    });
    getWithdraw({
      variables: {
        filter: {
          transactionType: { eq: "Withdrawal" },
          playerId: { eq: player.node.id },
          transactionStatus: { eq: "Approved" },
        },
      },
    });
  };

  const SearchUser = async (user) => {
    await setPlayer(null);
    await walletList({
      variables: {
        userId: user.node.id,
      },
    }).catch((e) => {
      ToastHelper.toggleToast({
        toastType: "error",
        message: e,
        title: "",
        onClick: function () { },
      });
    });
    await setPlayer(user);
    var filterRecentSearch = _.filter(recentSearch, function (o) {
      return o.id !== user.node.id;
    });
    var newRecentSearch = _.take(
      [
        {
          serialNo: user.node.serialNo,
          username: user.node.username,
          phoneNo: user.node.phoneNo,
          id: user.node.id,
        },
        ...filterRecentSearch,
      ],
      5
    );
    setRecentSearch(newRecentSearch);
    window.localStorage.setItem(
      "RECENT_SEARCH",
      JSON.stringify(newRecentSearch)
    );
    GetDepositAndWithdraw(user);
    setAction("player-info");
    runUser({
      variables: {
        id: user.node.id,
      },
    });
  };

  const NewPlayer = () => {
    let variables = {
      role: "Player",
    };

    createOneUser({
      variables: {
        input: {
          user: variables,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      })
      .then((val) => {
        if (val) {
          let data = {
            node: val.data.createOneUser,
          };
          SearchUser(data);
        }
      });
  };

  React.useEffect(() => {
    runUsers();
  }, [search]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (userList) console.log(userList.users.edges.length);
  }, [userList]);

  React.useEffect(() => {
    if (locState && locState.user) {
      SearchUser(locState.user);
      runUser({
        variables: {
          id: locState.user.node.id,
        },
      });
    }
  }, [props.location.state]); // eslint-disable-line react-hooks/exhaustive-deps

  const ToggleBankAccount = (account) => {
    updateOneBankAccount({
      variables: {
        input: {
          update: {
            isActive: !account.node.isActive,
          },
          id: account.node.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      })
      .then((val) => {
        if (val) {
          ToastHelper.toggleToast({
            message: "Update Succesfully!",
            title: "",
            onClick: function () { },
          });
        }
      });
  };

  return (
    <React.Fragment>
      {
        <div className="page-content banker-workbench">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Workbench" breadcrumbItem="Cashier" />
            <KioskAlert />
            <Row className="main-container">
              <Col xs={12} sm={12} md={12} lg={12} className="start-container">
                <div>
                  <div className="input-group new-player pb-3">
                    <div className="align-center">
                      <div className="start-header">Start here</div>
                      <div className="recent-search-container">
                        <div className="font-size-11 recent-search-title pb-1">
                          Recent Search
                        </div>
                        {recentSearch.length === 0 && (
                          <div>No Recent Search</div>
                        )}
                        {recentSearch.length > 0 && (
                          <div className="recent-search-buttons">
                            {recentSearch.map((recent, index) => {
                              return (
                                <button
                                  key={index}
                                  className="waves-effect btn btn-outline-primary recent-search-button"
                                  onClick={() => SearchUser({ node: recent })}
                                >
                                  <div className="font-size-12">
                                    {recent.username}
                                  </div>
                                </button>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light new-player-button"
                      onClick={() => setNewPlayer(true)}
                    >
                      New Player
                    </button>
                  </div>
                  <div className="input-group">
                    <AsyncTypeahead
                      selected={player ? [player] : []}
                      id="user-list"
                      isLoading={loadingUserList}
                      labelKey={(option) =>
                        `${option.node.serialNo}/${option.node.username}/${option.node.phoneNo
                          ? GetPhoneNumber(option.node.phoneNo)
                          : "-"
                        }`
                      }
                      minLength={2}
                      debounce={1000}
                      onSearch={(query) => setSearch(query)}
                      options={userList ? userList.users.edges : []}
                      placeholder="Serial No/ Username/ Phone No"
                      useCache={false}
                      filterBy={() => true}
                      renderMenuItemChildren={(option, props) => (
                        <Fragment>
                          <span>{`${option.node.serialNo}/${option.node.username
                            }/${option.node.phoneNo
                              ? GetPhoneNumber(option.node.phoneNo)
                              : "-"
                            }`}</span>
                        </Fragment>
                      )}
                      onChange={(val) => {
                        if (val.length > 0) {
                          SearchUser(val[0]);
                          runUser({
                            variables: {
                              id: val[0].node.id,
                            },
                          });
                        } else {
                          setPlayer(null);
                        }
                      }}
                    />
                    <div className="input-group-prepend">
                      <button
                        type="button"
                        className="btn btn-secondary waves-effect waves-light search-button"
                        onClick={() => {
                          SearchUser(player);
                        }}
                      >
                        {
                          // !loadingUser &&
                          <i className="bx bx-search font-size-16 align-middle"></i>
                        }
                        {/* {
                                                loadingUser &&
                                                <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                            } */}
                      </button>
                    </div>
                  </div>
                  <div className="actions-container">
                    {player && (
                      <>
                        {meData &&
                          !OperatingUsers.includes(meData.me.role) &&
                          !Operator.includes(meData.me.role) && (
                            <>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light deposit-button"
                                  onClick={() => setAction("deposit")}
                                >
                                  Deposit
                                </button>
                                {action === "deposit" && (
                                  <div className="selected deposit-selected"></div>
                                )}
                              </div>
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light withdraw-button"
                                  onClick={() => setAction("withdraw")}
                                >
                                  Withdraw
                                </button>
                                {action === "withdraw" && (
                                  <div className="selected withdraw-selected"></div>
                                )}
                              </div>
                            </>
                          )}
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light free-credit-button"
                            onClick={() => setAction("free-credit")}
                          >
                            Promotion
                          </button>
                          {action === "free-credit" && (
                            <div className="selected free-credit-selected"></div>
                          )}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light rebate-button"
                            onClick={() => setAction("rebate")}
                          >
                            Rebate
                          </button>
                          {action === "rebate" && (
                            <div className="selected rebate-selected"></div>
                          )}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light credit-in-button"
                            onClick={() => setAction("credit-out")}
                          >
                            Credit In / Credit Out
                          </button>
                          {action === "credit-out" && (
                            <div className="selected credit-in-selected"></div>
                          )}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light logout-player-button"
                            onClick={() => setAction("logout")}
                          >
                            Logout Player
                          </button>
                          {action === "logout" && (
                            <div className="selected logout-player-selected"></div>
                          )}
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-secondary waves-effect waves-light info-button"
                            onClick={() => setAction("player-info")}
                          >
                            Player Info
                          </button>
                          {action === "player-info" && (
                            <div className="selected info-selected"></div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Col>
              {loadingUser && (
                <Col xs="12">
                  <div className="text-center mb-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin mr-2"></i> Loading
                    </Link>
                  </div>
                </Col>
              )}
              {!loadingUser && (
                <Col xs={12} sm={12} md={12} lg={12}>
                  <Row>
                    {player && userData && userData.user && (
                      <Col xs={12} sm={12} md={12} lg={12} className="stats">
                        <div className="border p-3 rounded">
                          <Row className="pb-2">
                            <div
                              className="avatar-xs mr-3 position-absolute edit-button"
                              onClick={() => {
                                setEditUser(userData.user);
                              }}
                            >
                              <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18">
                                <i className="bx bx-edit-alt"></i>
                              </span>
                            </div>
                            <Col xs={12} sm={12} md={12} lg={12}>
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  Status:
                                </Col>
                                <Col
                                  className="col-desc"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  {userData.user.isActive
                                    ? "Active"
                                    : "Inactive"}
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  Serial No:
                                </Col>
                                <Col
                                  className="col-desc"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  {userData.user.serialNo}
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  Username:
                                </Col>
                                <Col
                                  className="col-desc"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  {userData.user.username}
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  Name:
                                </Col>
                                <Col
                                  className="col-desc"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  {userData.user.name}
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  Last Login:
                                </Col>
                                <Col
                                  className="col-desc"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >{`${userData.user.loginAt
                                  ? userData.user.loginAt
                                  : "N/A"
                                  } (${userData.user.ipAddress
                                    ? userData.user.ipAddress
                                    : "N/A"
                                  })`}</Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  Phone No:
                                </Col>
                                <Col
                                  className="col-desc"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  {GetPhoneNumber(userData.user.phoneNo)}
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  Tag(s):
                                </Col>
                                <Col
                                  className="col-desc"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  {userData.user.tags.edges.map(
                                    (tag, index) => {
                                      if (index > 3) {
                                        return;
                                      } else if (index === 3) {
                                        return (
                                          <div
                                            key={"_tag_" + index}
                                            className="p-1 m-1 badge badge-primary cursor-pointer"
                                            onClick={() => setMangeTag(true)}
                                          >
                                            {`+ ${userData.user.tags.edges.length -
                                              3
                                              } more`}
                                          </div>
                                        );
                                      }

                                      return (
                                        <div
                                          key={"_tag_" + index}
                                          className="p-1 m-1 badge badge-primary cursor-pointer"
                                          onClick={() => setMangeTag(true)}
                                        >
                                          {tag.node.name}
                                        </div>
                                      );
                                    }
                                  )}
                                  {userData.user.tags.edges.length === 0 && (
                                    <div
                                      className="cursor-pointer"
                                      onClick={() => setMangeTag(true)}
                                    >
                                      No Tag Found
                                    </div>
                                  )}
                                </Col>
                              </Row>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={12}
                              lg={12}
                              className="pt-4 pb-2"
                            >
                              <Row>
                                <Col
                                  className="col-title text-right"
                                  xs={3}
                                  sm={3}
                                  md={3}
                                  lg={3}
                                >
                                  D/W Ratio:
                                </Col>
                                <Col
                                  className="col-desc pt-1"
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  lg={8}
                                >
                                  {withdrawData && depositData && (
                                    <Progress multi>
                                      <Progress
                                        max="1"
                                        striped
                                        animated
                                        bar
                                        value={
                                          depositData
                                            ? depositData.transactionAggregate
                                              .sum.amount
                                              ? (depositData
                                                .transactionAggregate.sum
                                                .amount /
                                                (depositData
                                                  .transactionAggregate.sum
                                                  .amount +
                                                  withdrawData
                                                    .transactionAggregate.sum
                                                    .amount)) *
                                              100
                                              : 0
                                            : 0
                                        }
                                      />
                                      <Progress
                                        max="1"
                                        striped
                                        animated
                                        bar
                                        color="warning"
                                        value={
                                          withdrawData
                                            ? withdrawData.transactionAggregate
                                              .sum.amount
                                              ? (withdrawData
                                                .transactionAggregate.sum
                                                .amount /
                                                (depositData
                                                  .transactionAggregate.sum
                                                  .amount +
                                                  withdrawData
                                                    .transactionAggregate.sum
                                                    .amount)) *
                                              100
                                              : 0
                                            : 0
                                        }
                                      />
                                    </Progress>
                                  )}

                                  <div className="pt-1 font-size-10">
                                    Player deposited a total of{" "}
                                    {`$${depositData
                                      ? depositData.transactionAggregate.sum
                                        .amount
                                        ? depositData.transactionAggregate.sum
                                          .amount
                                        : 0
                                      : 0
                                      }`}{" "}
                                    and won{" "}
                                    {`$${withdrawData
                                      ? withdrawData.transactionAggregate.sum
                                        .amount
                                        ? withdrawData.transactionAggregate
                                          .sum.amount
                                        : 0
                                      : 0
                                      }`}
                                  </div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    )}
                    {player && userData && userData.user && (
                      <Col xs={4} sm={4} md={4} lg={4} className="stats">
                        {/* <Rollover
                                                player={userData.user}
                                            /> */}
                        <MainWalletView
                          player={userData.user}
                          onMovement={(type) => {
                            setMovementType(type);
                            setFromWallet("main");
                          }}
                          onUpdateWalletId={(id) => setWalletId(id)}
                          onRefetch={needRefetch}
                          onRefetchComplete={() => {
                            setNeedRefetch(null);
                          }}
                        />
                      </Col>
                    )}
                    {player && userData && userData.user && (
                      <Col xs={4} sm={4} md={4} lg={4} className="stats">
                        {/* <Withdraw
                                                player={userData.user}
                                            /> */}
                        <RewardWallet
                          player={userData.user}
                          onMovement={(type) => {
                            setMovementType(type);
                            setFromWallet("reward");
                          }}
                          onUpdateWalletId={(id) => setWalletId(id)}
                          onRefetch={needRefetch}
                          onRefetchComplete={() => {
                            setNeedRefetch(null);
                          }}
                        />
                      </Col>
                    )}
                    {player && userData && userData.user && (
                      <Col xs={4} sm={4} md={4} lg={4} className="stats">
                        {/* <Withdraw
                                                player={userData.user}
                                            /> */}
                        <AgentWalletView
                          player={userData.user}
                          onMovement={(type) => {
                            setMovementType(type);
                            setFromWallet("agent");
                          }}
                          onUpdateWalletId={(id) => setWalletId(id)}
                          onRefetch={needRefetch}
                          onRefetchComplete={() => {
                            setNeedRefetch(null);
                          }}
                        />
                      </Col>
                    )}
                    {player && userData && userData.user && (
                      <Col xs={4} sm={4} md={4} lg={4} className="stats">
                        {/* <Rollover
                                                player={userData.user}
                                            /> */}
                        <DailyAttendanceWallet
                          player={userData.user}
                          onMovement={(type) => {
                            setMovementType(type);
                            setFromWallet("daily");
                          }}
                          onUpdateWalletId={(id) => setWalletId(id)}
                          onRefetch={needRefetch}
                          onRefetchComplete={() => {
                            setNeedRefetch(null);
                          }}
                        />
                      </Col>
                    )}
                    {player && userData && userData.user && (
                      <Col xs={4} sm={4} md={4} lg={4} className="stats">
                        {/* <Rollover
                                                player={userData.user}
                                            /> */}
                        <WeeklyAttendanceWallet
                          player={userData.user}
                          onMovement={(type) => {
                            setMovementType(type);
                            setFromWallet("weekly");
                          }}
                          onUpdateWalletId={(id) => setWalletId(id)}
                          onRefetch={needRefetch}
                          onRefetchComplete={() => {
                            setNeedRefetch(null);
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              )}
            </Row>
            <div className="action-container">
              <Row
                className={`action-row ${action === "player-info" ? "active" : ""
                  }`}
              >
                <Col lg={12} className="action-wrapper">
                  {player && (
                    <PlayerInfo
                      player={player.node}
                      isActive={action === "player-info"}
                      playerKey={player.node.username}
                      toTab={toTab}
                      onApproveTransaction={() => {
                        GetDepositAndWithdraw(player);
                      }}
                      actToggleBank={(account) => {
                        setToggleBank(account);
                        setShowToggleBank(true);
                      }}
                    />
                  )}
                </Col>
              </Row>
              <Row
                className={`action-row ${action === "deposit" ? "active" : ""}`}
              >
                {player && (
                  <Col lg={12} className="action-wrapper">
                    {player && action === "deposit" && (
                      <BankerDeposit
                        player={player.node}
                        isActive={action === "deposit"}
                        playerKey={player.node.username}
                        onDone={async () => {
                          await setAction("player-info");
                          await setToTab("4");
                          GetDepositAndWithdraw(player);
                        }}
                      />
                    )}
                  </Col>
                )}
              </Row>
              <Row
                className={`action-row ${action === "withdraw" ? "active" : ""
                  }`}
              >
                {player && action === "withdraw" && (
                  <Col lg={12} className="action-wrapper">
                    <BankerWithdraw
                      player={player.node}
                      playerKey={player.node.username}
                      isActive={action === "withdraw"}
                      onDone={async () => {
                        await setAction("player-info");
                        await setToTab("4.1");
                        GetDepositAndWithdraw(player);
                      }}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className={`action-row ${action === "free-credit" ? "active" : ""
                  }`}
              >
                {player && action === "free-credit" && (
                  <Col lg={12} className="action-wrapper">
                    <BankerFreeCredit
                      player={player.node}
                      playerKey={player.node.username}
                      isActive={action === "free-credit"}
                      onDone={async () => {
                        await setAction("player-info");
                        await setToTab("4.2");
                        GetDepositAndWithdraw(player);
                      }}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className={`action-row ${action === "rebate" ? "active" : ""}`}
              >
                {player && action === "rebate" && (
                  <Col lg={12} className="action-wrapper">
                    <BankerRebate
                      player={player.node}
                      isActive={action === "rebate"}
                      onDone={async () => {
                        await setAction("player-info");
                        await setToTab("4.3");
                        GetDepositAndWithdraw(player);
                      }}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className={`action-row ${action === "credit-in" ? "active" : ""
                  }`}
              >
                {player && action === "credit-in" && (
                  <Col lg={12} className="action-wrapper">
                    <BankerTransfer
                      type="credit-in"
                      player={player.node}
                      isActive={action === "credit-in"}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className={`action-row no-border ${action === "credit-out" ? "active" : ""
                  }`}
              >
                {player && action === "credit-out" && (
                  <Col lg={12} className="action-wrapper">
                    <BankerCreditInOut
                      player={player.node}
                      isActive={action === "credit-out"}
                      onDone={async (type) => {
                        await setAction("player-info");
                        if (type === "creditIn") {
                          await setToTab("4.4");
                        } else if (type === "creditOut") {
                          await setToTab("4.5");
                        }
                      }}
                    />
                  </Col>
                )}
              </Row>
              <Row
                className={`action-row ${action === "logout" ? "active" : ""}`}
              >
                <Col lg={12} className="action-wrapper">
                  <BankerLogout />
                </Col>
              </Row>
            </div>
            {/* {
                        noUser &&
                        <SweetAlert
                            error
                            title={t('Error')}
                            onConfirm={() => setNoUser(false)}
                        >
                            {t('User {{user}} Not Found', { user: searchKey })}
                        </SweetAlert>
                    } */}
            {newPlayer && (
              <SweetAlert
                warning
                showCancel
                title={t("New Player")}
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  NewPlayer();
                  setNewPlayer(false);
                }}
                onCancel={() => setNewPlayer(false)}
              >
                {t("Are you sure?")}
              </SweetAlert>
            )}
          </Container>
          {loadingWalletList && (
            <Col xs="12">
              <div className="text-center mb-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin mr-2"></i> Loading
                </Link>
              </div>
            </Col>
          )}
        </div>
      }
      {
        <EditUser
          modal={!_.isEmpty(editUser)}
          user={editUser}
          toggleModal={() => {
            setEditUser(null);
          }}
        />
      }
      {manageTag && (
        <ManageTags
          modal={manageTag}
          toggleModal={() => {
            setMangeTag(false);
          }}
          user={userData.user}
        />
      )}
      {showToggleBank && (
        <SweetAlert
          info
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={"Alert"}
          onConfirm={() => {
            setShowToggleBank(false);
            ToggleBankAccount(toggleBank);
          }}
          onCancel={() => setShowToggleBank(false)}
        >
          {`Are you sure you want to ${toggleBank.node.isActive ? "DEACTIVATE" : "ACTIVATE"
            } ${toggleBank.node.accountName}?`}
        </SweetAlert>
      )}
      {
        <WalletInOut
          modal={movementType}
          toggleModal={() => {
            setMovementType(null);
          }}
          walletId={walletId}
          type={movementType}
          onDone={() => {
            setMovementType(null);
            setNeedRefetch(fromWallet);
          }}
        />
      }
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(BankerWorkbench));

// export default ContactsList;

import gql from 'graphql-tag';
import { BankFragment } from '../fragments/bank.fragment';

export const CREATE_ONE_BANK = gql`
    mutation(
        $input: CreateOneBankInput!
    ) {
        createOneBank (input: $input) {
            ...BankInfo
        }
    }
    ${BankFragment}
`;



import React, { Fragment, useEffect } from 'react';
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Card
} from "reactstrap";
import Dropzone from "react-dropzone";
import Compressor from 'compressorjs';

import CloudinaryHelper from "../../helpers/CloudinaryHelper"
import { CREATE_ONE_PLATFORM } from '../../graphql/mutation/CREATE_ONE_PLATFORM';
import { ME } from '../../graphql/query';
export default function NewPlatform(props) {

    const { modal, toggleModal } = props;
    const [createOnePlatform] = useMutation(CREATE_ONE_PLATFORM);
    const { data: meData } = useQuery(ME)

    const [selectedFile, setSelectFile] = React.useState(null);
    const [fileError, setFileError] = React.useState(false);

    const [isUploadingImage, setIsUploadingImage] = React.useState(false);

    const handleAcceptedFiles = files => {
        setSelectFile(files[0])
        setFileError(false)
    };

    useEffect(() => {
        if (!modal) {
            setSelectFile(null);
            setIsUploadingImage(false);
        }
    }, [modal])

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.5,
                success: resolve,
                error: reject
            });
        })
    }

    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-bank-page">
                <ModalHeader toggle={toggleModal}>
                    Add New Platform
                </ModalHeader>
                <ModalBody>
                    <div id="new-platform-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={async (event, values) => {

                                setIsUploadingImage(true)
                                
                                let result
                                if (selectedFile) {
                                    let imageData = await Compress(selectedFile)
                                    result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=gavlzxqd`)
                                }
                                
                                createOnePlatform({
                                    variables: {
                                        input: {
                                            "platform": {
                                                "name": values.platformname,
                                                "unitPrice": Number(values.unitPrices) ?? 0,
                                                "imageUrl": selectedFile ? result.data.url : null,
                                                "kioskPanelUrl": values.adminPanelUrl,
                                                "displayName": values.displayName,
                                                website: values.website,
                                                iOSApp: values.ios,
                                                androidApp: values.android
                                            }
                                        }
                                    }
                                }).catch(e => {
                                    console.log(e)
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).then(val => {
                                    if (val) {
                                        toggleModal()
                                        setIsUploadingImage(false)
                                    }
                                })
                            }}
                            onInvalidSubmit={() => {
                                // if (!selectedFile) {
                                //     setFileError(true)
                                // }
                            }}
                        >
                            <Row>
                                <Col lg="6">
                                    <AvField
                                        label="Platform Name"
                                        name="platformname"
                                        type="text"
                                        placeholder="Enter Platform Name"
                                        errorMessage="Enter Platform name"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="Display Name"
                                        name="displayName"
                                        type="text"
                                        placeholder="Enter Display Name"
                                        errorMessage="Enter Display name"
                                    />
                                </Col>
                                {
                                    meData &&
                                    <Col lg="6">
                                        <AvField
                                            label="Unit Price"
                                            name="unitPrices"
                                            type="number"
                                            defaultValue={1}
                                            disabled={meData.me.role !== 'Developer'}
                                            placeholder="Enter Unit Prices"
                                            errorMessage="Enter Unit Prices"
                                            validate={{
                                                number: true,
                                            }}
                                        />
                                    </Col>
                                }
                                <Col lg="6">
                                    <AvField
                                        label="Admin Panel Url"
                                        name="adminPanelUrl"
                                        type="text"
                                        placeholder="Enter Admin Panel Url"
                                        errorMessage="Enter Admin Panel Url"
                                        validate={{
                                            pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                        }}
                                    />
                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="Game Website"
                                        name="website"
                                        type="text"
                                        placeholder="Enter Game Website"
                                        errorMessage="Enter Game Website"
                                        validate={{
                                            pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                        }}
                                    />
                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="iOS App"
                                        name="ios"
                                        type="text"
                                        placeholder="Enter iOS App"
                                        errorMessage="Enter iOS App"
                                        validate={{
                                            pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                        }}
                                    />
                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="Android App"
                                        name="android"
                                        type="text"
                                        placeholder="Enter Android App"
                                        errorMessage="Enter Android App"
                                        validate={{
                                            pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                        }}
                                    />
                                </Col>
                            </Row>
                            {fileError && <Row><Col lg="12"><div className="image-url-error">* Please Provide an Image</div></Col></Row>}
                            <Row className="pb-1">
                                <Col lg="12">

                                    {!selectedFile &&
                                        <Dropzone
                                            onDrop={acceptedFiles =>
                                                handleAcceptedFiles(acceptedFiles)
                                            }>
                                            {({ getRootProps, getInputProps }) => (
                                                <div className="dropzone">
                                                    <div
                                                        className="dz-message needsclick mt-2"
                                                        {...getRootProps()}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-3">
                                                            <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                        </div>
                                                        <h4>Drop files here or click to upload.</h4>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    }
                                    {
                                        selectedFile &&
                                        <div
                                            className="dropzone-previews mt-3"
                                            id="file-previews"
                                        >
                                            <Card
                                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                key={"file"}
                                            >


                                                <div className="p-2">
                                                    <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                            <img
                                                                data-dz-thumbnail=""
                                                                height="80"
                                                                className="avatar-sm rounded bg-light"
                                                                alt={selectedFile.name}
                                                                src={URL.createObjectURL(selectedFile)}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            {isUploadingImage && <div className="spinner-border text-success m-1" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>}

                                                            {!isUploadingImage && <div
                                                                to="#"
                                                                className="text-muted font-weight-bold"
                                                            >
                                                                Product Image
                                                            </div>}
                                                        </Col>
                                                        <Col>
                                                            <Button className="pr-2 float-right" close aria-label="Cancel" onClick={() => {
                                                                setSelectFile(null);
                                                            }} >
                                                            </Button>

                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Card>
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { useQuery, useSubscription } from 'react-apollo';
import { GET_KIOSK } from '../../../graphql/query/GET_KIOSK';
import { UPDATED_KIOSK_ACCOUNT } from '../../../graphql/subscription/UPDATED_KIOSK_ACCOUNT';

function KioskAlert(props) {

    const { data, refetch } = useQuery(GET_KIOSK, {
        variables: {
            filter: {
                maintenanceMode: { is: true },
                isDefault: { is: true },
                isActive: { is: true }
            }
        },
        notifyOnNetworkStatusChange: true
    })

    const { data: subData } = useSubscription(UPDATED_KIOSK_ACCOUNT)

    React.useEffect(() => {
        if (subData) {
            refetch()
        }
    }, [subData]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            {
                data &&
                data.kioskAccounts.edges.length > 0 &&
                <Link to="/kiosk">
                    <Alert color="danger" role="alert" className="text-center">{`There are ${data.kioskAccounts.edges.length} working kiosks(${data.kioskAccounts.edges.map((kioskAccount) => ' ' + kioskAccount.node.platform.displayName)} ) are under maintenance, please resolve immediately`}</Alert>
                </Link>
            }
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(KioskAlert));
import gql from 'graphql-tag';

export const GET_ENUM = gql`
    query($name: String!) {
        __type(name: $name) {
            enumValues {
                name
            }
        }
    }
`;
import React from 'react';
import { Modal, Row, Col, ModalHeader, ModalBody, Button, Input } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToastHelper from '../../../helpers/ToastHelper';
import { useMutation } from 'react-apollo';
import { CREATE_ONE_WALLET_ENTRY } from '../../../graphql/mutation';
import NumberFormat from 'react-number-format';

function WalletInOut(props) {

    const { t, modal, toggleModal, walletId, type, onDone } = props;

    const [processing, setProcessing] = React.useState(false)

    const [createOneWalletEntry, { loading: loadingCreateEntry }] = useMutation(CREATE_ONE_WALLET_ENTRY)

    return (
        <React.Fragment>
            <Modal className="banker-workbench" isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} tabIndex="-1" toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    {type ? type.toUpperCase() : ''}
                </ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={async (event, values) => {
                        if (processing) {
                            return
                        }
                        setProcessing(true)

                        let result = await createOneWalletEntry({
                            variables: {
                                input: {
                                    walletEntry: {
                                        deposit: type === 'increment' || type === 'RPGenerated' || type == 'APGenerated' || type == 'DAPGenerated' || type == 'WAPGenerated' ? parseFloat(values.amount.toString().replaceAll(',', '')) : 0,
                                        withdrawal: type === 'decrement' || type === 'RPVoided' || type == 'APVoided' || type == 'DAPVoided' || type == 'WAPVoided' ? parseFloat(values.amount.toString().replaceAll(',', '')) : 0,
                                        walletId: walletId,
                                        description: values.description,
                                        walletEntryType: type[0].toUpperCase() + type.substring(1)
                                    }
                                }
                            }
                        }).catch(e => {
                            ToastHelper.toggleToast({
                                toastType: "error",
                                message: e,
                                title: "",
                                onClick: function () { }
                            })
                        })

                        if (result) {
                            if (onDone) {
                                onDone()
                            }
                        }

                        setProcessing(false)
                    }}>
                        {
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        label={t('Amount')}
                                        type="text"
                                        name="amount"
                                        placeholder="Enter Amount"
                                        errorMessage={t('Enter Amount')}
                                        thousandSeparator
                                        decimalScale={2}
                                        fixedDecimalScale
                                        tag={[Input, NumberFormat]}
                                        validate={{
                                            required: true,
                                            pattern: {
                                                value: "^[0-9,.]+$",
                                                errorMessage: `${t('Enter Only Numbers')}`
                                            }
                                        }}
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        label={t('Description')}
                                        type="text"
                                        name="description"
                                        placeholder="Enter Description"
                                        errorMessage={t('Enter Description')}
                                        validate={{
                                            required: true
                                        }}
                                    />
                                </Col>
                            </Row>
                        }
                        <div className="submit-container">
                            <Button color="primary" type="submit">
                                {
                                    loadingCreateEntry &&
                                    <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                }
                                {
                                    !loadingCreateEntry &&
                                    <div>
                                        Submit
                                    </div>
                                }
                            </Button>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(WalletInOut));
import gql from 'graphql-tag';

export const UPDATE_ONE_SETTING = gql`
    mutation(
        $input: UpdateOneSettingInput!
    ) {
        updateOneSetting(input: $input){
            id
            defaultValue
            description
            key
            value
            fieldType
            updatedAt
            developerOnly
        }
    }
`;
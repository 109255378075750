import gql from "graphql-tag";
import { TelegramButtonLanguageFragment } from "../fragments/telegram-button-language.fragment";

export const CREATE_ONE_TELEGRAM_BUTTON_LANGUAGE = gql`
  mutation ($input: CreateOneTelegramButtonLanguageInput!) {
    createOneTelegramButtonLanguage(input: $input) {
      ...TelegramButtonLanguage
    }
  }
  ${TelegramButtonLanguageFragment}
`;

import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { UDPATE_ONE_CURRENCY } from '../../graphql/mutation';

function EditCurrency(props) {

    const { modal, toggleModal, currency } = props;

    const [updateOneCurrency] = useMutation(UDPATE_ONE_CURRENCY);

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-currency-page">
                    <ModalHeader toggle={toggleModal}>
                        Add Currency
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    await updateOneCurrency({
                                        variables: {
                                            input: {
                                                update: {
                                                    name: values.name,
                                                    code: values.code,
                                                    number: parseFloat(values.number.toString().replaceAll(',', '')),
                                                    symbol: values.symbol
                                                },
                                                id: currency.node.id
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })
                                }}
                            >
                                <Row>

                                    <Col lg={6}>
                                        <AvField
                                            value={currency.node.code}
                                            label="Currency Code"
                                            name="code"
                                            type="text"
                                            placeholder="Enter Currency Code"
                                            errorMessage="Enter Currency Code"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            value={currency.node.number}
                                            label="Currency Number"
                                            name="number"
                                            type="text"
                                            placeholder="Enter Currency Number"
                                            errorMessage="Enter Currency Number"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            value={currency.node.name}
                                            label="Currency Name"
                                            name="name"
                                            type="text"
                                            placeholder="Enter Currency Name"
                                            errorMessage="Enter Currency Name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            value={currency.node.symbol}
                                            label="Currency Symbol"
                                            name="symbol"
                                            type="text"
                                            placeholder="Enter Currency Symbol"
                                            errorMessage="Enter Currency Symbol"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(EditCurrency));
import gql from 'graphql-tag';

export const CREATE_ONE_SETTING = gql`
    mutation(
        $input: CreateOneSettingInput!
    ) {
        createOneSetting(input: $input){
            id
            defaultValue
            description
            key
            value
            developerOnly
        }
    }
`;
import gql from "graphql-tag";

export const GET_AGENT_DETAIL_REPORT = gql`
  query (
    $agentId: String!
    $skip: Int
    $take: Int
    $fromDate: String
    $toDate: String
  ) {
    agentSummaryReport(
      agentId: $agentId
      skip: $skip
      take: $take
      fromDate: $fromDate
      toDate: $toDate
    ) {
      aggregation {
        totalDownlineDepositCount
        totalDownlineDepositAmount
        totalDownlineWithdrawalCount
        totalDownlineWithdrawalAmount
        totalDownlineRebateAmount
        totalDownlineBonusAmount
        totalNetProfit
        totalAPEarned
        totalDownlineCount
        newDownlineCount
      }
      result {
        downlineId
        downlineUsername
        downlineTelegramId
        downlinePhoneNo
        downlineDepositCount
        downlineDepositAmount
        downlineWithdrawalCount
        downlineWithdrawalAmount
        downlineRebateAmount
        downlineBonusAmount
        netProfit
        APEarned
      }
    }
  }
`;

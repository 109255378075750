import gql from "graphql-tag";

export const TelegramButtonLanguageFragment = gql`
  fragment TelegramButtonLanguage on TelegramButtonLanguage {
    id
    label
    language
    updatedAt
    isActive
  }
`;

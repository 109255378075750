import React from 'react';
import moment from 'moment';
import { useMutation } from 'react-apollo';
import { SYNC_GAME_ACCOUNT } from '../../../graphql/mutation';
import { UPDATE_ONE_GAME_ACCOUNT } from '../../../graphql/mutation/UPDATE_GAME_ACCOUNT';
import Switch from "react-switch";
import ToastHelper from '../../../helpers/ToastHelper';
import classnames from 'classnames';
import { GAME_ACCOUNT_RESET_PASSWORD } from '../../../graphql/mutation/GAME_ACCOUNT_RESET_PASSWORD';

function PlayerAccountItem(props) {

    const { gameAccount } = props

    const [syncGameAccount, { loading: loadingSync }] = useMutation(SYNC_GAME_ACCOUNT)
    const [updateOneGameAccount] = useMutation(UPDATE_ONE_GAME_ACCOUNT);
    const [resetPassword, { loading: loadingReset }] = useMutation(GAME_ACCOUNT_RESET_PASSWORD)

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return (
        <React.Fragment>
            <tr className="font-size-12">
                <td>{moment(gameAccount.node.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                <td>
                    <div>{gameAccount.node.kioskAccount.platform.displayName}</div>
                </td>
                <td>{gameAccount.node.username}</td>
                <td>{gameAccount.node.password}</td>
                <td>{gameAccount.node.balance ? gameAccount.node.balance : "0"}</td>
                <td className={
                    classnames({
                        "font-size-14": true,
                        "text-info": gameAccount.node.messageLabelType === 'Info',
                        "text-success": gameAccount.node.messageLabelType === 'Success',
                        "text-danger": gameAccount.node.messageLabelType === 'Error',
                        "text-warning": gameAccount.node.messageLabelType === 'Warning'
                    })
                }>{gameAccount.node.message ? gameAccount.node.message : "-"}</td>
                <td>
                    <Switch
                        uncheckedIcon={<Offsymbol text="NO" />}
                        checkedIcon={<OnSymbol text="YES" />}
                        id="is-active"
                        onColor="#34c38f"
                        offColor="#f46a6a"
                        onChange={() => {
                            updateOneGameAccount({
                                variables: {
                                    input: {
                                        "update": {
                                            isActive: !gameAccount.node.isActive
                                        },
                                        id: gameAccount.node.id
                                    }
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            }).then(val => {
                                if (val) {
                                    ToastHelper.toggleToast({
                                        message: "Update Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                }
                            })
                        }}
                        checked={gameAccount.node.isActive}
                    />
                </td>
                <td>
                    <div className="action-buttons align-left">
                        <button type="button" className="btn btn-primary waves-effect waves-light font-size-11 action-button nowrap" onClick={async () => {
                            await syncGameAccount({
                                variables: {
                                    id: gameAccount.node.id
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            })
                        }}>
                            {
                                loadingSync &&
                                <>
                                    <i className="fas fa-spinner fa-pulse font-size-13 align-middle"></i>
                                    <div className="pl-1"></div>
                                </>
                            }
                            <div className="">Sync</div>
                        </button>
                        <button type="button" className="btn btn-danger waves-effect waves-light font-size-11 action-button nowrap" onClick={async () => {
                            await resetPassword({
                                variables: {
                                    id: gameAccount.node.id
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            }).then(val => {
                                if (val) {
                                    ToastHelper.toggleToast({
                                        message: "Reset Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                }
                            })
                        }}>
                            {
                                loadingReset &&
                                <>
                                    <i className="fas fa-spinner fa-pulse font-size-13 align-middle"></i>
                                    <div className="pl-1"></div>
                                </>
                            }
                            <div className=""> Reset Password</div>
                        </button>
                    </div>
                </td>
            </tr>
        </React.Fragment>
    );
}

export default PlayerAccountItem;
import gql from 'graphql-tag';
import { CountryFragment } from '../fragments/country.fragment';

export const UPDATED_COUNTRY = gql`
    subscription {
        updatedOneCountry {
            ...CountryInfo
        }
    }
    ${CountryFragment}
`;
import React from 'react';
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useMutation } from 'react-apollo';
import { SYNC_GAME_ACCOUNT } from '../../../graphql/mutation';
import ToastHelper from '../../../helpers/ToastHelper';

function GameCard(props) {

    const { game, t, onCreditIn, onCreditOut } = props;

    const [syncGameAccount, { loading: loadingSync }] = useMutation(SYNC_GAME_ACCOUNT)

    return (
        <React.Fragment>
            <Col xs={12} sm={6} md={4} lg={3} className="gamecard">
                <Card>
                    {
                        loadingSync &&
                        <div className="loading-container">
                            <div className="loading-wrapper">
                                <i className="fas fa-spinner fa-spin font-size-20 align-middle text-white"></i>
                            </div>
                        </div>
                    }
                    <CardBody className="product-item">
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className="display-row">
                                    <div>
                                        <img src={game.node.kioskAccount.platform.imageUrl} alt="" height="40" width="40" className="icon-image" />
                                    </div>
                                    <div>
                                        <div className="header">{t('Game')}</div>
                                        <div>{game.node.kioskAccount.platform.displayName}</div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className="pt-2 header">{t('Username')}</div>
                                <div>{game.node.username}</div>
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <div className="pt-2 header">{t('Balance')}</div>
                                <div>{game.node.balance ? game.node.balance : 'NA'}</div>
                            </Col>
                            <Col>
                                <div className="buttons-container pt-2">
                                    <Button
                                        className="p-1 pl-2 pr-2"
                                        color="primary"
                                        outline
                                        onClick={() => {
                                            syncGameAccount({
                                                variables: {
                                                    id: game.node.id
                                                }
                                            }).catch(e => {
                                                ToastHelper.toggleToast({
                                                    toastType: "error",
                                                    message: e,
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            })
                                        }}
                                    >
                                        {t('Sync')}
                                    </Button>
                                    <div className="button-container">
                                        <Button
                                            className="p-1 pl-2 pr-2"
                                            color="success"
                                            outline
                                            onClick={() => {
                                                onCreditIn(game)
                                            }}
                                        >
                                            {t('In')}
                                        </Button>
                                        <Button
                                            className="p-1 pl-2 pr-2 ml-2"
                                            color="danger"
                                            outline
                                            onClick={() => {
                                                onCreditOut(game)
                                            }}
                                        >
                                            {t('Out')}
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(GameCard));
import React from "react";
import { Row, Col, Table, Card, CardBody, Button, Input } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useQuery, useLazyQuery } from "react-apollo";
import moment from "moment";
import NumberFormat from "react-number-format";
import classnames from "classnames";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import { showOverlayLoading } from "../../store/actions";
import { GET_AGENT_DETAIL_REPORT } from "../../graphql/query/GET_AGENT_DETAIL_REPORT";
import { Pagination } from "@mui/material";
import { CSVLink } from "react-csv";
import _ from "lodash";
import { ME } from "../../graphql/query";

function AgentReport(props) {
  var skip = 0;
  var take = 10;
  const { t, showOverlayLoading } = props;
  const { agentId } = props.match.params;

  const csvLink = React.useRef(null);

  const [dateDisplay, setDateDisplay] = React.useState("YYYY-MM");
  const [fromDate, setFromDate] = React.useState(
    moment().startOf("year").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = React.useState(
    moment().endOf("year").format("YYYY-MM-DD")
  );
  const [tempFromDate, setTempFromDate] = React.useState(
    moment().startOf("year").toDate()
  );
  const [tempToDate, setTempToDate] = React.useState(
    moment().endOf("year").toDate()
  );
  const [csvData, setCSVData] = React.useState([]);
  const [readyDownload, setReadyDownload] = React.useState(false);

  const dateArr = [
    { name: "Today", key: "day" },
    { name: "Yesterday", key: "day", deduct: 1 },
    { name: "In 3 Days", minus: 2 },
    { name: "In A Week", minus: 6 },
    { name: "Current Week", key: "week" },
    { name: "Last Week", key: "week", deduct: 1 },
    { name: "In A Month", minus: 29 },
    { name: "Current Month", key: "month" },
    { name: "Last Month", key: "month", deduct: 1 },
    { name: "Current Year", key: "year" },
    { name: "Last Year", key: "year", deduct: 1 },
  ];
  const [dateType, setDateType] = React.useState(dateArr[9]);
  const [page, setPage] = React.useState(1);

  const [getAgentReport, { data, loading }] = useLazyQuery(
    GET_AGENT_DETAIL_REPORT
  );
  const { data: meData } = useQuery(ME);

  React.useEffect(() => {
    if (props.match.params) {
      // setPeriodType(type)
      DateDisplayFormat("year");
    }
  }, [props.match.params]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    setPage(1);
    getAgentReport({
      variables: {
        fromDate: fromDate,
        toDate: toDate,
        agentId: agentId,
        skip: skip,
        take: take,
      },
      fetchPolicy: "network-only",
    });
  }, [fromDate, toDate]);

  const [getCSVData, { data: csvTempData }] = useLazyQuery(
    GET_AGENT_DETAIL_REPORT,
    {
      variables: {
        fromDate: fromDate,
        toDate: toDate,
        agentId: agentId,
        skip: 0,
        take: take,
      },
      fetchPolicy: "no-cache",
    }
  );

  React.useEffect(() => {
    if (data) {
      skip = data.agentSummaryReport.result.length;
    }
  }, [data]);

  const Formatter = (value) => {
    let val = parseFloat(value).toFixed(2);
    return (
      <NumberFormat
        value={val}
        displayType={"text"}
        thousandSeparator={true}
        decimalScale={2}
      />
    );
  };

  const DateDisplayFormat = (value) => {
    switch (value) {
      case "day":
        setDateDisplay("YYYY-MM-DD");
        break;
      case "month":
        setDateDisplay("YYYY-MM");
        break;
      case "year":
        setDateDisplay("YYYY");
        break;
      default:
        break;
    }
  };
  const SetDates = (dateType) => {
    if (dateType.minus) {
      setTempFromDate(moment().subtract(dateType.minus, "days").toDate());
      setTempToDate(moment().toDate());
      setFromDate(
        moment().subtract(dateType.minus, "days").format("YYYY-MM-DD")
      );
      setToDate(moment().format("YYYY-MM-DD"));
    } else if (dateType.key) {
      if (dateType.deduct) {
        setTempFromDate(
          moment()
            .subtract(dateType.deduct, dateType.key)
            .startOf(dateType.key)
            .toDate()
        );
        setTempToDate(
          moment()
            .subtract(dateType.deduct, dateType.key)
            .endOf(dateType.key)
            .toDate()
        );
        setFromDate(
          moment()
            .subtract(dateType.deduct, dateType.key)
            .startOf(dateType.key)
            .format("YYYY-MM-DD")
        );
        setToDate(
          moment()
            .subtract(dateType.deduct, dateType.key)
            .endOf(dateType.key)
            .format("YYYY-MM-DD")
        );
      } else {
        setTempFromDate(moment().startOf(dateType.key).toDate());
        setTempToDate(moment().endOf(dateType.key).toDate());
        setFromDate(moment().startOf(dateType.key).format("YYYY-MM-DD"));
        setToDate(moment().endOf(dateType.key).format("YYYY-MM-DD"));
      }
    }
  };

  React.useEffect(() => {
    showOverlayLoading(loading);
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const CSVDownload = () => {
    return (
      <>
        <Button color="primary" onClick={DownloadToCSV} style={{ width: 165 }}>
          {t("  Download (CSV)")}
          {readyDownload && <i className="bx bx-hourglass bx-spin mr-3"></i>}
        </Button>
        {/* <div className="btn btn-primary btn-sm mb-3 mr-2" onClick={DownloadToCSV}>

                </div> */}
        <CSVLink
          ref={csvLink}
          filename={"agent-summary-report.csv"}
          className="hidden"
          data={csvData}
          headers={[
            { label: "Username", key: "downlineUsername" },
            { label: "Phone No", key: "downlinePhoneNo" },
            { label: "Telegram Id", key: "downlineTelegramId" },
            { label: "Deposit Count", key: "downlineDepositCount" },
            { label: "Deposit Amount", key: "downlineDepositAmount" },
            { label: "Withdrawal Count", key: "downlineWithdrawalCount" },
            { label: "Withdrawal Amount", key: "downlineWithdrawalAmount" },
            { label: "Rebate Amount", key: "downlineRebateAmount" },
            { label: "Bonus Amount", key: "downlineBonusAmount" },
            { label: "Agent Point Earned", key: "APEarned" },
            { label: "Net Profit", key: "netProfit" },
          ]}
        ></CSVLink>
      </>
    );
  };

  const DownloadToCSV = () => {
    getCSVData();
    setReadyDownload(true);
  };

  React.useEffect(() => {
    if (csvData.length > 0) {
      if (
        csvData.length <
        csvTempData.agentSummaryReport.aggregation.totalDownlineCount
      ) {
        getCSVData({
          variables: {
            fromDate: fromDate,
            toDate: toDate,
            agentId: agentId,
            skip: csvData.length,
            take: take,
          },
        });
      } else if (csvData.length > 0 && csvLink && readyDownload) {
        csvLink.current.link.click();
        setReadyDownload(false);
        setCSVData([]);
      }
    }
  }, [csvData]);

  React.useEffect(() => {
    if (csvTempData && readyDownload) {
      setCSVData((prevData) => [
        ...prevData,
        ...csvTempData.agentSummaryReport.result,
      ]);
    }
  }, [csvTempData]);

  return (
    <React.Fragment>
      <div className="page-content report">
        <Card>
          <CardBody>
            <AvForm
              onValidSubmit={(event, values) => {
                setFromDate(
                  values.fromDate
                    ? moment(values.fromDate)
                        .startOf("day")
                        .format("YYYY-MM-DD")
                    : null
                );
                setToDate(
                  values.toDate
                    ? moment(values.toDate).startOf("day").format("YYYY-MM-DD")
                    : null
                );
              }}
            >
              <Row>
                <Col xs={12} sm={6} md={6} lg={6}>
                  <Row>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <AvField
                        value={tempFromDate}
                        selected={tempFromDate}
                        label="From Date"
                        name="fromDate"
                        type="text"
                        placeholderText="Enter From Date"
                        errorMessage="Enter From Date"
                        selectsStart
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="yyyy-MM-dd"
                        tag={[Input, DatePicker]}
                        onChange={(date) => {
                          setTempFromDate(date);
                        }}
                      />
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={6}>
                      <AvField
                        value={tempToDate}
                        selected={tempToDate}
                        label="To Date"
                        name="toDate"
                        type="text"
                        placeholderText="Enter To Date"
                        errorMessage="Enter To Date"
                        selectsStart
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        dateFormat="yyyy-MM-dd"
                        tag={[Input, DatePicker]}
                        minDate={tempFromDate}
                        onChange={(date) => {
                          setTempToDate(date);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col className="action-container" xs={12} sm={6} md={6} lg={6}>
                  <div className="mb-3">
                    <Button color="primary" type="submit">
                      {t("Submit")}
                    </Button>
                  </div>
                  {meData &&
                    (meData.me.role === "Developer" ||
                      meData.me.role === "Admin") && (
                      <div className="ml-2">
                        <CSVDownload />
                      </div>
                    )}
                </Col>
              </Row>

              <Row>
                <div className="button-container ml-3">
                  <div className="mb-2 mr-2 font-weight-medium">
                    {"Period Range: "}
                  </div>
                  {dateArr.map((type, index) => (
                    <button
                      className={classnames({
                        btn: true,
                        "mr-2": true,
                        "mb-2": true,
                        "btn-outline-primary": type.name !== dateType.name,
                        "btn-primary": type.name === dateType.name,
                      })}
                      key={index}
                      onClick={() => {
                        setDateType(type);
                        SetDates(type);
                      }}
                    >
                      {type.name}
                    </button>
                  ))}
                </div>
              </Row>
            </AvForm>

            <Row>
              <Col sm="12">
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead
                      style={{
                        background: "grey",
                        color: "white",
                      }}
                    >
                      <tr>
                        <th>Username</th>
                        <th>Phone No</th>
                        <th>Telegram Id</th>
                        <th>Deposit Count</th>
                        <th>Deposit Amount</th>
                        <th>Withdrawal Count</th>
                        <th>Withdrawal Amount</th>
                        <th>Rebate Amount</th>
                        <th>Bonus Amount</th>
                        <th>Agent Point Earned</th>
                        <th>Net Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data &&
                        data.agentSummaryReport.result.length > 0 &&
                        data.agentSummaryReport.result.map((report, index) => {
                          return (
                            <tr key={index}>
                              <th>{report.downlineUsername}</th>
                              <th>{report.downlinePhoneNo ?? "-"}</th>
                              <th>{report.downlineTelegramId ?? "-"}</th>
                              <th>{report.downlineDepositCount}</th>
                              <th>$ {report.downlineDepositAmount}</th>
                              <th>{report.downlineWithdrawalCount}</th>
                              <th>$ {report.downlineWithdrawalAmount}</th>
                              <th>$ {report.downlineRebateAmount}</th>
                              <th>$ {report.downlineBonusAmount}</th>
                              <th>{report.APEarned}</th>
                              <th>$ {report.netProfit}</th>
                            </tr>
                          );
                        })}
                      {data && data.agentSummaryReport.result.length === 0 && (
                        <tr className="text-center pt-3">
                          <td colSpan={3}>{t("No Report Found")}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {data && data.agentSummaryReport.result.length > 0 && (
                    <>
                      <div className="align-center p-2">
                        <div></div>
                        <Pagination
                          count={Math.ceil(
                            data.agentSummaryReport.aggregation
                              .totalDownlineCount / take
                          )}
                          color="primary"
                          page={page}
                          onChange={(event, value) => {
                            skip = (value - 1) * take;
                            setPage(value);
                            getAgentReport({
                              variables: {
                                fromDate: fromDate,
                                toDate: toDate,
                                agentId: agentId,
                                skip: skip,
                                take: take,
                              },
                              fetchPolicy: "network-only",
                            });
                          }}
                        />
                      </div>
                      <Table className="table mb-0">
                        <thead
                          style={{
                            background: "grey",
                            color: "white",
                          }}
                        >
                          <tr>
                            <th>New Downline Count</th>
                            <th>Total Downline Count</th>
                            <th>Downline Deposit Count</th>
                            <th>Downline Deposit Amount</th>
                            <th>Downline Withdrawal Count</th>
                            <th>Downline Withdrawal Amount</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              {data.agentSummaryReport.aggregation
                                .newDownlineCount ?? 0}
                            </th>
                            <th>
                              {data.agentSummaryReport.aggregation
                                .totalDownlineCount ?? 0}
                            </th>
                            <th>
                              {data.agentSummaryReport.aggregation
                                .totalDownlineDepositCount ?? 0}
                            </th>
                            <th>
                              ${" "}
                              {parseFloat(
                                data.agentSummaryReport.aggregation
                                  .totalDownlineDepositAmount ?? 0
                              )
                                .toFixed(2)
                                .toString()}
                            </th>
                            <th>
                              {data.agentSummaryReport.aggregation
                                .totalDownlineWithdrawalCount ?? 0}
                            </th>
                            <th>
                              ${" "}
                              {parseFloat(
                                data.agentSummaryReport.aggregation
                                  .totalDownlineWithdrawalAmount ?? 0
                              )
                                .toFixed(2)
                                .toString()}
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                      <Table className="table mb-0">
                        <thead
                          style={{
                            background: "grey",
                            color: "white",
                          }}
                        >
                          <tr>
                            <th>Total Rebate Amount</th>
                            <th>Total Bonus Amount</th>
                            <th>Agent Point Earned</th>
                            <th>Total Net Profit</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>
                              ${" "}
                              {parseFloat(
                                data.agentSummaryReport.aggregation
                                  .totalDownlineRebateAmount ?? 0
                              )
                                .toFixed(2)
                                .toString()}
                            </th>
                            <th>
                              ${" "}
                              {parseFloat(
                                data.agentSummaryReport.aggregation
                                  .totalDownlineBonusAmount ?? 0
                              )
                                .toFixed(2)
                                .toString()}
                            </th>
                            <th>
                              {parseFloat(
                                data.agentSummaryReport.aggregation
                                  .totalAPEarned ?? 0
                              )
                                .toFixed(2)
                                .toString()}
                            </th>
                            <th>
                              ${" "}
                              {parseFloat(
                                data.agentSummaryReport.aggregation
                                  .totalNetProfit ?? 0
                              )
                                .toFixed(2)
                                .toString()}
                            </th>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(
  withTranslation()(AgentReport)
);

import gql from 'graphql-tag';
import { CategoryFragment } from '../fragments/category.fragment';

export const CREATE_ONE_CATEGORY = gql`
    mutation(
        $input: CreateOneCategoryInput!
    ) {
        createOneCategory (input: $input) {
            ...CategoryInfo
        }
    }
    ${CategoryFragment}
`;
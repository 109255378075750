
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery, useSubscription, useMutation } from 'react-apollo';
import { GET_PLATFORM_BY_USER } from '../../graphql/query/GET_PLATFORM_BY_USER'
import { UPDATE_GAME_ACCOUNT_BALANCE } from '../../graphql/subscription/UPDATED_GAME_ACCOUNT'
import ProfilePlatforms from './profile-platforms';
import { SYNC_ALL_GAME_ACCOUNT } from '../../graphql/mutation/SYNC_ALL_GAME_ACCOUNT';
import ToastHelper from '../../helpers/ToastHelper';

function ProfileGameAccount(props) {

    const { user } = props;

    const { data } = useQuery(GET_PLATFORM_BY_USER, {
        variables: {
            filter: { isDefault: { is: true } },
            gamefilter: { playerId: { eq: user.id } },
            sorting: { field: "createdAt", direction: "ASC" }
        }
    });

    const [platforms, setPlatforms] = useState([]);

    const { data: updatedGameAccount } = useSubscription(UPDATE_GAME_ACCOUNT_BALANCE, { variables: { input: { filter: { playerId: { eq: "83825fa1-0707-46e8-bd3c-5e0f094ecb15" } } } } });


    const [syncAllGameAccount] = useMutation(SYNC_ALL_GAME_ACCOUNT);

    useEffect(() => {
        if (data) {
            setPlatforms(data.kioskAccounts.edges);
        }
    }, [data]);

    useEffect(() => {
        if (updatedGameAccount) {
            setSingleGameAccount(updatedGameAccount.updatedOneGameAccount);
        }
    }, [updatedGameAccount]) // eslint-disable-line react-hooks/exhaustive-deps

    const triggerLoadingALL = async () => {
        await syncAllGameAccount({ variables: { input: user.id } }).then(() => {

        }).catch(err => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: err,
                title: "",
                onClick: function () { }
            })
            var newArry = platforms.map((kioskAccount, index) => {

                let newgameAccounts = kioskAccount.node.gameAccounts.edges.map((gameAccount) => {
                    return { ...gameAccount, isLoading: false }
                });
                var newNode = { ...kioskAccount.node, gameAccounts: { edges: newgameAccounts } };
                return { ...kioskAccount, node: newNode }
            });

            setPlatforms(newArry);
        });

        var newArry = platforms.map((kioskAccount, index) => {

            let newgameAccounts = kioskAccount.node.gameAccounts.edges.map((gameAccount) => {
                return { ...gameAccount, isLoading: true }
            });
            var newNode = { ...kioskAccount.node, gameAccounts: { edges: newgameAccounts } };
            return { ...kioskAccount.node, node: newNode }
        })

        setPlatforms(newArry);

    }

    const setSingleGameAccount = (newGameAccount) => {
        var newArry = platforms.map((kioskAccount, index) => {
            let newgameAccounts = kioskAccount.node.gameAccounts.edges.map((gameAccount) => {
                if (gameAccount.node.id === newGameAccount.id) {
                    return { ...gameAccount, isLoading: false, balance: newGameAccount.balance, lastSyncAt: newGameAccount.lastSyncAt }
                } else {
                    return { ...gameAccount }
                }

            });
            var newNode = { ...kioskAccount.node, gameAccounts: { edges: newgameAccounts } };
            return { ...kioskAccount, node: newNode }
        });
        setPlatforms(newArry);
    }

    return (<React.Fragment>
        <div>
            <div className="search-bar-container mb-2">

                <div className="filter-container button-filter-container">
                    <button type="button" className="waves-effect btn btn-outline-secondary button-add-kiosk" onClick={triggerLoadingALL}>
                        Reload All
                    </button>
                </div>

            </div>
            {
                platforms && platforms.map((kioskAccount, key) =>
                    <ProfilePlatforms key={"_kioskAccount_" + key} account={kioskAccount}></ProfilePlatforms>

                )

            }

        </div>
    </React.Fragment>)
}



export default connect()(withTranslation()(ProfileGameAccount));
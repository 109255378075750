import gql from "graphql-tag";
import { PageFragment } from "../fragments/page.fragment";
import { TelegramButtonLanguageFragment } from "../fragments/telegram-button-language.fragment";

export const GET_TELEGRAM_BUTTON_LANGUAGES = gql`
  query (
    $filter: TelegramButtonLanguageFilter
    $paging: CursorPaging
    $sorting: [TelegramButtonLanguageSort!]
  ) {
    telegramButtonLanguages(
      paging: $paging
      filter: $filter
      sorting: $sorting
    ) {
      pageInfo {
        ...Page
      }
      edges {
        node {
          ...TelegramButtonLanguage
        }
      }
    }
  }
  ${PageFragment}
  ${TelegramButtonLanguageFragment}
`;

import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';

export const GET_AGENTS = gql`
    query (
        $paging: CursorPaging
        $filter: AgentFilter
        $sorting: [AgentSort!]
    ) {
        agents (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    id
                    serialNo
                    username
                    agentWalletBalance
                    mainWalletBalance
                    agentWalletExpiredAt
                    agentWalletId
                    mainWalletId
                    uplineUsername
                    downlineCount
                    agentBaseRate
                    lastAPGained
                    lastAPGainedFrom
                    lastAPGainedAt
                }
            }
        }
    }
    ${PageFragment}
`;
import gql from 'graphql-tag';
import { PlatformFragment } from '../fragments/platform.fragment';

export const UPDATE_ONE_PLATFORM = gql`
    mutation(
        $input: UpdateOnePlatformInput!
    ) {
        updateOnePlatform (input: $input) {
            ...PlatformInfo
        }
    }
    ${PlatformFragment}
`;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";
import NumberFormat from 'react-number-format';

class AccountItem extends Component {

    state = {
        drp_link: false
    }

    render() {
        const { bank, onEdit, onIncrement, onDecrement, onTransfer } = this.props;
        return (
            <React.Fragment>
                <Col xl="3" sm="6" lg="4">
                    <Card>
                        <div className="cursor-pointer" onClick={onEdit} style={{
                            backgroundColor: bank.node.bank.headerColor ? `${bank.node.bank.headerColor}CC` : '#FCBC2FCC',
                            borderTopLeftRadius: '0.25rem',
                            borderTopRightRadius: '0.25rem',
                        }}>
                            <Row className="text-align">
                                <Col xs="5" className="cursor-pointer" onClick={onEdit}>
                                    <div className="text-primary pr-0 pl-3 pt-3 pb-3">
                                        <div className="bank-name" style={{ color: bank.node.bank.titleColor ? bank.node.bank.titleColor : '#556EE6' }}>{bank.node.bank.name}</div>
                                    </div>
                                </Col>
                                <Col xs="7">
                                    <div className="text-primary p-3 text-right">
                                        <div className="bank-name" style={{ color: bank.node.bank.titleColor ? bank.node.bank.titleColor : '#556EE6' }}>
                                            <NumberFormat
                                                value={parseFloat(bank.node.balance)}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                decimalScale={2}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="">
                            <Row>
                                <Col className="text-align pb-3 cursor-pointer" onClick={onEdit}>
                                    <div>
                                        <div className="header">{bank.node.accountName}</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{bank.node.accountNo}</Link></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-align pb-3 cursor-pointer" onClick={onEdit}>
                                    <div>
                                        <div className="header">Country</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{bank.node.bank.country.name}</Link></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-align pb-3 cursor-pointer" onClick={onEdit}>
                                    <div>
                                        <div className="header">Ability</div>
                                        {
                                            bank.node.canDeposit && !bank.node.canWithdraw &&
                                            <div className="font-size-14"><Link to="#" className="text-dark">Deposit Only</Link></div>
                                        }
                                        {
                                            !bank.node.canDeposit && bank.node.canWithdraw &&
                                            <div className="font-size-14"><Link to="#" className="text-dark">Withdrawal Only</Link></div>
                                        }
                                        {
                                            bank.node.canDeposit && bank.node.canWithdraw &&
                                            <div className="font-size-14"><Link to="#" className="text-dark">Deposit and Withdrawal</Link></div>
                                        }
                                        {
                                            !bank.node.canDeposit && !bank.node.canWithdraw &&
                                            <div className="font-size-14"><Link to="#" className="text-dark">No deposit and No withdrawal</Link></div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-align pb-3 cursor-pointer" onClick={onEdit}>
                                    <div>
                                        <div className="header">Account Status</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{bank.node.bankAccountStatus}</Link></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="text-align">
                                    <div>
                                        <div className="header">Active?</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{bank.node.isActive ? 'Yes' : 'No'}</Link></div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="dropdown-actions">
                                    <ButtonDropdown
                                        isOpen={this.state.drp_link}
                                        toggle={() =>
                                            this.setState({ drp_link: !this.state.drp_link })
                                        }
                                    >
                                        <DropdownToggle caret color="secondary">
                                            Actions <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem onClick={onIncrement}>Increment</DropdownItem>
                                            <DropdownItem onClick={onDecrement}>Decrement</DropdownItem>
                                            <DropdownItem onClick={onTransfer}>Transfer</DropdownItem>
                                            <DropdownItem onClick={onEdit}>Edit Bank Account</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default AccountItem;
import React, { Fragment } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Button,
    FormGroup
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { UPDATE_ONE_BANK } from '../../graphql/mutation';
import { useQuery } from '@apollo/react-hooks';
import { GET_COUNTRY } from '../../graphql/query';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import DropZone from '../Workbench/Components/drop-zone';
import {
    showOverlayLoading
} from "../../store/actions";
import Compressor from 'compressorjs';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import CloudinaryHelper from '../../helpers/CloudinaryHelper';

function EditBank(props) {

    const { modal, toggleModal, bank } = props;

    const [search, setSearch] = React.useState('');
    const [isValidCountry, setIsValidCountry] = React.useState(true);
    const [country, setCountry] = React.useState(null);
    const [headerColor, setHeaderColor] = React.useState(null);
    const [titleColor, setTitleColor] = React.useState(null);
    const [selectedFile, setSelectFile] = React.useState(null);
    const [currentImage, setCurrentImage] = React.useState(null)

    const { data: countryData, loading: loadingCountry } = useQuery(GET_COUNTRY, {
        variables: {
            filter: {
                name: { iLike: `%${search}%` }
            }
        }
    });

    const [updateOneBank] = useMutation(UPDATE_ONE_BANK);

    React.useEffect(() => {
        setSearch('')
        setIsValidCountry(true)
        setCountry(null)
    }, [modal])

    React.useEffect(() => {
        if (bank) {
            setCurrentImage(bank.iconUrl)
            setCountry(bank.country)
            setHeaderColor(bank.headerColor)
            setTitleColor(bank.titleColor)
        }
    }, [bank])

    const handleCountrySearch = (query) => {
        setSearch(query)
    }

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                {
                    bank &&
                    < div className="modal-content edit-bank-page">
                        <ModalHeader toggle={toggleModal}>
                            Edit Bank
                        </ModalHeader>
                        <ModalBody>
                            <div id="edit-bank-wizard" className="twitter-bs-wizard">
                                <AvForm className="needs-validation"
                                    onValidSubmit={async (event, values) => {
                                        if (country) {
                                            let result
                                            if (selectedFile) {
                                                let imageData = await Compress(selectedFile)
                                                result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=vmuqed1e`)
                                            }

                                            await updateOneBank({
                                                variables: {
                                                    input: {
                                                        update: {
                                                            name: values.bankName,
                                                            countryId: country.id,
                                                            headerColor: headerColor,
                                                            titleColor: titleColor,
                                                            iconUrl: selectedFile ? result.data.url : currentImage ? currentImage : null,
                                                        },
                                                        id: bank.id
                                                    }
                                                }
                                            }).catch(e => {
                                                ToastHelper.toggleToast({
                                                    toastType: "error",
                                                    message: e,
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            }).then(val => {
                                                if (val) {
                                                    ToastHelper.toggleToast({
                                                        message: "Update Succesfully!",
                                                        title: "",
                                                        onClick: function () { }
                                                    })
                                                    props.showOverlayLoading(false)
                                                    setSelectFile(null)
                                                    toggleModal()
                                                }
                                            })
                                        } else {
                                            setIsValidCountry(false)
                                        }
                                    }}
                                    onInvalidSubmit={() => {
                                        if (country) {
                                            setIsValidCountry(false)
                                        }
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <DropZone
                                                selectedFile={selectedFile}
                                                onFileDrop={(file) => {
                                                    setSelectFile(file)
                                                    setCurrentImage(null)
                                                }}
                                                currentImage={currentImage}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup className={`${!isValidCountry ? 'text-danger' : ''}`}>
                                                <Label for="newrole-input">Country</Label>
                                                <AsyncTypeahead
                                                    delay={0}
                                                    id="country-list"
                                                    isLoading={loadingCountry}
                                                    labelKey={option => `${option.node.name}`}
                                                    minLength={0}
                                                    onSearch={handleCountrySearch}
                                                    onInputChange={(val) => {
                                                        if (val === "") {
                                                            handleCountrySearch('')
                                                        }
                                                    }}
                                                    defaultInputValue={bank.country ? bank.country.name : ''}
                                                    options={countryData ? countryData.countries.edges : []}
                                                    placeholder="Search for Country"
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <span>{option.node.name}</span>
                                                        </Fragment>
                                                    )}
                                                    errorMessage="Enter country name"
                                                    isInvalid={!isValidCountry}
                                                    onChange={(val) => {
                                                        setIsValidCountry(val.length > 0 ? true : false)
                                                        setCountry(val[0].node)
                                                    }}
                                                    onBlur={(e) => {
                                                        if (isValidCountry && country.length === 0) {
                                                            setIsValidCountry(false)
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <AvField
                                                value={bank.name}
                                                label="Bank Name"
                                                name="bankName"
                                                type="text"
                                                placeholder="Enter Bank Name"
                                                errorMessage="Enter bank name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="header-color-input">Header Color</Label>
                                                <div className="">
                                                    <input name="headerColor" defaultValue={headerColor} className="form-control" type="color" id="header-color-input" onChange={(event) => {
                                                        setHeaderColor(event.target.value)
                                                    }} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <Label for="header-color-input">Title Color</Label>
                                                <div className="">
                                                    <input name="titleColor" defaultValue={titleColor} className="form-control" type="color" id="header-color-input" onChange={(event) => {
                                                        setTitleColor(event.target.value)
                                                    }} />
                                                </div>
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                </AvForm>
                            </div>
                        </ModalBody>
                    </div>
                }
            </Modal>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(withTranslation()(EditBank));

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import error from "../../assets/images/error-img.png";
import _ from 'lodash';
class PlatformTableItem extends Component {

    state = {
        drp_link: false
    }

    render() {
        const { platform, onEdit, onManage, me } = this.props;

        const defaultKioskAccount = _.find(platform.node.kioskAccounts.edges, function (o) { return o.node.isDefault });

        return (
            <React.Fragment>

                <tr className="product-item">
                    <td><img alt="img" className="product-image img-fluid mr-3 p-1" src={platform.node.imageUrl ? platform.node.imageUrl : error} /></td>
                    {
                        me &&
                        me.role === 'Developer' &&
                        <td>
                            <div className="cursor-pointer " onClick={onEdit} >{platform.node.name}</div>
                        </td>
                    }
                    <td><div className="cursor-pointer " onClick={onEdit} >{platform.node.displayName ? platform.node.displayName : 'N/A'}</div></td>
                    <td>
                        <div className="cursor-pointer ">
                            {
                                defaultKioskAccount &&
                                <>
                                    <div className="text-align">{defaultKioskAccount.node.username}<i className="bx bx-badge-check text-success font-size-16 ml-2"></i></div>
                                    <div className="text-align">{defaultKioskAccount.node.password}</div>
                                </>
                            }
                            <button type="button" className="py-0 btn btn-primary waves-effect waves-light font-size-11 action-button mt-2" onClick={onManage}>
                                <div className="">{`Manage All (${platform.node.kioskAccounts.totalCount})`}</div>
                            </button>
                        </div>
                    </td>
                    <td><div className="cursor-pointer " onClick={onEdit} >{platform.node.unitPrice}</div></td>
                    <td>
                        <div className="text-align">
                            {
                                platform.node.kioskPanelUrl &&
                                <Link
                                    className="text-dark"
                                    onClick={() => {
                                        if (platform.node.kioskPanelUrl) {
                                            window.open(platform.node.kioskPanelUrl, "_blank")
                                        }
                                    }}
                                >
                                    <i className="bx bxs-dashboard dashboard font-size-20 mr-2"></i>
                                </Link>
                            }
                            {
                                platform.node.website &&
                                <Link
                                    className="text-dark"
                                    onClick={() => {
                                        if (platform.node.website) {
                                            window.open(platform.node.website, "_blank")
                                        }
                                    }}
                                >
                                    <i className="bx bxl-chrome chrome font-size-20 mr-2"></i>
                                </Link>
                            }
                            {
                                platform.node.iOSApp &&
                                <Link
                                    className="text-dark"
                                    onClick={() => {
                                        if (platform.node.iOSApp) {
                                            window.open(platform.node.iOSApp, "_blank")
                                        }
                                    }}
                                >
                                    <i className="bx bxl-apple ios font-size-20 mr-2"></i>
                                </Link>
                            }
                            {
                                platform.node.androidApp &&
                                <Link
                                    className="text-dark"
                                    onClick={() => {
                                        if (platform.node.androidApp) {
                                            window.open(platform.node.androidApp, "_blank")
                                        }
                                    }}
                                >
                                    <i className="bx bxl-android android font-size-20 mr-2"></i>
                                </Link>
                            }
                        </div>
                    </td>
                </tr >
            </React.Fragment >
        )
    }
}

export default PlatformTableItem;
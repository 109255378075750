// @flow
import { all, call, fork, takeEvery } from "redux-saga/effects";

import {
    CHANGE_FILTER,
    CHANGE_SORT
} from "./actionTypes";

import {
    changeFilter as changeFilterAction,
    changeSort as changeSortAction,
} from "./actions";

/**
 * Changes the filter
 * @param {*} param0
 */
function* changeFilter({ payload: filter }) {
    try {
        yield call(changeFilterAction(filter))
    } catch (error) { }
}

/**
 * Changes the sort
 * @param {*} param0
 */
function* changeSort({ payload: sort }) {
    try {
        yield call(changeSortAction(sort))
    } catch (error) { }
}

/**
 * Watchers
 */
export function* watchChangeFilter() {
    yield takeEvery(CHANGE_FILTER, changeFilter);
}

export function* watchChangeSort() {
    yield takeEvery(CHANGE_SORT, changeSort);
}

function* CommonSaga() {
    yield all([
        fork(watchChangeFilter),
        fork(watchChangeSort),
    ]);
}

export default CommonSaga;

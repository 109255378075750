import React from "react";
import { Row, Col } from "reactstrap";
import { useMutation } from "react-apollo";
import { FIND_OR_CREATE_ONE_SETTING } from "../../graphql/mutation";

const Footer = () => {

  // const [getCompanyName, { data: campanyName }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
  //   variables: {
  //     input: {
  //       description: "Company Name",
  //       key: "companyName",
  //       settingType: "CompanyInfo",
  //       fieldType: "TextField"
  //     }
  //   }
  // })

  // React.useEffect(() => {
  //   getCompanyName()
  // }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <footer className="footer">
        <div className="container-fluid">
          <Row>
            {/* {
              campanyName &&
              <Col sm={6}>{new Date().getFullYear()} © {campanyName.findOrCreateSetting.value}</Col>
            } */}
            {/* <Col sm={6}>
              <div className="text-sm-right d-none d-sm-block">
                Design & Develop by Themesbrand
              </div>
            </Col> */}
          </Row>
        </div>
      </footer>
    </React.Fragment >
  );
};

export default Footer;

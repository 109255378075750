import React from 'react';
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";

//Import Breadcrumb
import { useMutation, useQuery } from '@apollo/react-hooks';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import 'react-medium-image-zoom/dist/styles.css';
import { connect } from "react-redux";
import { Admin, Developer, Manager, Supervisor } from '../../assets/scss/role.constant';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SettingEditor from '../../components/CommonForBoth/SettingEditor';
import { DELETE_ONE_REWARD_TIER, FIND_OR_CREATE_ONE_SETTING, UPDATE_ONE_SETTING } from '../../graphql/mutation';
import { GET_TIERS } from '../../graphql/query/GET_TIERS';
import RoleHelper from '../../helpers/RoleHelper';
import { toggleRightSidebar } from "../../store/actions";
import EditTier from './edit-tier';
import NewTier from './new-tier';
import TierItem from './tier-item';

function RewardTiers(props) {
    const [addTier, setAddTier] = React.useState(false)
    const [editTier, setEditTier] = React.useState(false)
    const [tierItem, setTierItem] = React.useState(null)
    const [orderBy] = React.useState("minDepositAmount")
    const [orderDirection] = React.useState("ASC")
    const [deleteTier, setDeleteTier] = React.useState(null)
    const [showDelete, setShowDelete] = React.useState(false)

    const onEdit = (item) => {
        setTierItem(item)
        setEditTier(true)
    }

    const [updateOneSetting] = useMutation(UPDATE_ONE_SETTING)

    const [getRpExchangeRate, { data: rpExchangeRate }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'rewardPointExchangeRate',
                settingType: "General",
                value: '50',
                defaultValue: '50',
                fieldType: "TextField",
                description: 'Reward Point Exchange Rate',
                developerOnly: false,
            }
        }
    })

    const [getRewardPointReminder, { data: rewardPointReminder }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'rewardPointExpireNotification',
                settingType: "Bot",
                value: '-1',
                defaultValue: '-1',
                fieldType: "TextField",
                description: 'Reward Point Expiry Reminder (Days)[-1 for not remind]',
                developerOnly: false,
            }
        }
    })

    const [getMinRewardPointToClaim, { data: minRewardPointToClaim }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'minimumRewardPointToClaim',
                settingType: "General",
                value: '500',
                defaultValue: '500',
                fieldType: "TextField",
                description: 'Minimum Reward Point To Claim',
                developerOnly: false,
            }
        }
    })

    const [getRewardPointExpiredIn, { data: rewardPointExpiredIn }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'rewardPointExpiredIn',
                settingType: "General",
                value: '30',
                defaultValue: '30',
                fieldType: "TextField",
                description: 'Reward Point Expired In(Days)',
                developerOnly: false,
            }
        }
    })

    const [getEnableRewardModule, { data: enableRewardModule }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
        variables: {
            input: {
                key: 'enableRewardModule',
                settingType: "General",
                value: 'false',
                defaultValue: 'false',
                fieldType: "Boolean",
                description: 'Enable/Disable Reward Module',
                developerOnly: false,
            }
        }
    })

    const { data: tiersData, refetch } = useQuery(GET_TIERS, {
        variables: {
            sorting: [{ field: orderBy, direction: orderDirection }]

        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const [deleteOneTier] = useMutation(DELETE_ONE_REWARD_TIER)

    React.useEffect(() => {
        getRpExchangeRate()
        getMinRewardPointToClaim()
        getRewardPointExpiredIn()
        getEnableRewardModule()
        getRewardPointReminder()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="page-content bonus-page">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Reward Point" breadcrumbItem="Reward Point" />

                    <Row>
                        <Col md={6} lg={6} xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <SettingEditor description="Reward Point Exchange Rate" settingKey="rewardPointExchangeRate" settingType="General" fieldType="TextField" inputValue={'50'} defaultValue={'50'} roles={[Developer, Admin, Manager, Supervisor]} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} lg={6} xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <SettingEditor description="Min Reward Point To Claim" settingKey="minimumRewardPointToClaim" settingType="General" fieldType="TextField" inputValue={'500'} defaultValue={'500'} roles={[Developer, Admin, Manager, Supervisor]} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} lg={6} xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <SettingEditor description="Reward Point Expired In(Days)" settingKey="rewardPointExpiredIn" settingType="General" fieldType="TextField" inputValue={'30'} defaultValue={'30'} roles={[Developer, Admin, Manager, Supervisor]} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} lg={6} xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <SettingEditor description="Enter Reward Point Expiry Reminder (Days)" settingKey="rewardPointExpireNotification" settingType="Bot" fieldType="TextField" inputValue={'-1'} defaultValue={'-1'} roles={[Developer, Admin, Manager, Supervisor]} />
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={6} lg={6} xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <SettingEditor description="Enable Reward Module?(Global)" settingKey="enableRewardModule" settingType="General" fieldType="Boolean" inputValue={'false'} defaultValue={'false'} roles={[Developer, Admin, Manager, Supervisor]} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <div className="search-bar-container mb-3">
                        <div className="button-add-bonus-container">

                        </div>
                        <div className="button-add-bonus-container">
                            <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus ml-2" onClick={() => { setAddTier(true) }} disabled={!RoleHelper.checkRoles([Developer, Admin, Manager, Supervisor])}>
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Tier
                            </button>
                        </div>
                    </div>
                    {
                        <Card className="w-100">
                            <CardBody>
                                <div className="font-size-14 mb-2">TIER LIST</div>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Min Deposit</th>
                                                <th>Max Deposit</th>
                                                <th>Reward Multiplier</th>
                                                <th>Active?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                tiersData &&
                                                tiersData.rewardTiers.edges.length > 0 &&
                                                tiersData.rewardTiers.edges.map((rewardTier, key) =>
                                                    <tr key={"_tier" + key}>
                                                        <TierItem
                                                            onEdit={onEdit}
                                                            tier={rewardTier}
                                                            onDelete={(tier) => {
                                                                setDeleteTier(tier)
                                                                setShowDelete(true)
                                                            }}
                                                        />
                                                    </tr >
                                                )
                                            }
                                            {
                                                tiersData &&
                                                tiersData.rewardTiers.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-user pt-0">No Reward Tier Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>}
                    {
                        addTier &&
                        <NewTier
                            modal={addTier}
                            toggleModal={() => {
                                refetch()
                                setAddTier(!addTier)
                            }}
                        />
                    }
                    {
                        editTier &&
                        <EditTier
                            modal={editTier}
                            toggleModal={() => {
                                setEditTier(!editTier)
                            }}
                            tier={tierItem}
                        />
                    }
                    {
                        showDelete &&
                        <SweetAlert
                            error
                            showCancel
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={'Alert'}
                            onConfirm={() => {
                                deleteOneTier({
                                    variables: {
                                        id: deleteTier.node.id
                                    }
                                }).then(() => {
                                    refetch()
                                    setDeleteTier(null)
                                    setShowDelete(false)
                                })
                            }}
                            onCancel={() => {
                                setDeleteTier(null)
                                setShowDelete(false)
                            }}
                        >
                            {`Are you sure want to delete this reward tier?`}
                        </SweetAlert>
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(RewardTiers));

// export default ContactsList;
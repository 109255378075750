import React from "react";
import { Col, Row, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from "react-apollo";
import { UPDATE_ONE_USER } from "../../graphql/mutation";
import ToastHelper from "../../helpers/ToastHelper";
import { useQuery } from "@apollo/react-hooks";
import { GET_ENUM, ME } from "../../graphql/query";
import _ from "lodash";
import { OperatingUsers, Operator } from "../../assets/scss/role.constant";

export default function EditUser(props) {
  const { modal, toggleModal, user } = props;

  const { data: roleData } = useQuery(GET_ENUM, {
    variables: {
      name: "Role",
    },
  });

  const { data: meData } = useQuery(ME, {
    fetchPolicy: "cache-first",
  });

  const [updateOneUser] = useMutation(UPDATE_ONE_USER);

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <div className="modal-content edit-user-page">
          <ModalHeader toggle={toggleModal}>Edit User</ModalHeader>
          <ModalBody>
            <div id="edit-user-wizard" className="twitter-bs-wizard">
              <AvForm
                className="needs-validation"
                onValidSubmit={(event, values) => {
                  updateOneUser({
                    variables: {
                      input: {
                        update: {
                          serialNo: values.serialNo,
                          name: values.name,
                          username: values.username,
                          phoneNo:
                            OperatingUsers.includes(meData.me.role) ||
                            Operator.includes(meData.me.role)
                              ? user.mobileNo
                              : _.isEmpty(values.mobileNo)
                              ? null
                              : values.mobileNo,
                          role: values.role,
                        },
                        id: user.id,
                      },
                    },
                  })
                    .catch((e) => {
                      ToastHelper.toggleToast({
                        toastType: "error",
                        message: e,
                        title: "",
                        onClick: function () {},
                      });
                    })
                    .then((val) => {
                      if (val) {
                        ToastHelper.toggleToast({
                          message: "Update Succesfully!",
                          title: "",
                          onClick: function () {},
                        });
                        toggleModal();
                      }
                    });
                }}
              >
                <Row>
                  {meData && (
                    <Col lg="6">
                      <AvField
                        value={user ? user.serialNo : ""}
                        label="Serial No"
                        name="serialNo"
                        type="text"
                        placeholder="Serial No"
                        errorMessage="Serial No"
                        disabled={meData.me.role !== "Developer"}
                        validate={{ required: { value: true } }}
                      />
                    </Col>
                  )}
                  <Col lg="6">
                    <AvField
                      value={user ? user.name : ""}
                      label="Name"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      errorMessage="Enter name"
                      validate={{ required: { value: true } }}
                    />
                  </Col>
                  <Col lg="6">
                    <AvField
                      value={user ? user.username : ""}
                      label="Username"
                      name="username"
                      type="text"
                      placeholder="Enter Username"
                    />
                  </Col>
                  {!OperatingUsers.includes(meData.me.role) &&
                    !Operator.includes(meData.me.role) && (
                      <Col lg="6">
                        <AvField
                          value={user ? user.phoneNo : ""}
                          name="mobileNo"
                          label="Mobile Number"
                          placeholder="Enter Mobile Number"
                          type="text"
                          errorMessage="Enter Only Number"
                          validate={{
                            pattern: {
                              value: "^[0-9]+$",
                              errorMessage: "Only Numbers",
                            },
                          }}
                        />
                      </Col>
                    )}
                </Row>
                {user && (
                  <Row>
                    <Col lg="12">
                      <AvField
                        value={user.role}
                        name="role"
                        label="Role"
                        type="select"
                        className="custom-select"
                        disabled={user.role === "Player"}
                      >
                        {roleData &&
                          (meData.me.role === "Developer"
                            ? roleData.__type.enumValues
                            : _.filter(
                                roleData.__type.enumValues,
                                ({ name }) => {
                                  return name !== "Developer" && name !== "Bot";
                                }
                              )
                          ).map(({ name }, index) => {
                            return (
                              <option key={index} value={name}>
                                {name}
                              </option>
                            );
                          })}
                      </AvField>
                    </Col>
                  </Row>
                )}

                <div className="action-container">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
}

import gql from 'graphql-tag';
export const GET_TAG = gql`
query(
    $id: ID!
){
    tag(
        id: $id
    ){
        users(sorting:[{field:joinedAt,direction:ASC}]){
            edges {
                node {
                    id
                    serialNo
                    name
                    username
                    phoneNo
                }
            }
        }
    }
}
`;
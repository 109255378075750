import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logoutUser } from '../../store/actions';

function Logout(props) {
    React.useEffect(() => {
        window.localStorage.clear()
        props.logoutUser(props.history);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <React.Fragment></React.Fragment>;
}

export default withRouter(
    connect(
        null,
        { logoutUser }
    )(Logout)
);

import gql from 'graphql-tag';
import { CountryFragment } from '../fragments/country.fragment';

export const UPDATE_ONE_COUNTRY = gql`
    mutation(
        $input: UpdateOneCountryInput!
    ) {
        updateOneCountry (input: $input) {
            ...CountryInfo
        }
    }
    ${CountryFragment}
`;
import gql from 'graphql-tag';
import { PlatformFragment } from '../fragments/platform.fragment';

export const CREATED_PLATFORM = gql`
    subscription {
        createdPlatform {
            ...PlatformInfo
        }
    }
    ${PlatformFragment}
`;
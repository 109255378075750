import React, { useRef } from 'react';
import { Container, Card, CardBody, Table, Col } from "reactstrap";
import Switch from "react-switch";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery, useMutation } from '@apollo/react-hooks';

import _ from 'lodash';

import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import 'react-medium-image-zoom/dist/styles.css'
import NewFreeCredit from './new-free-credit';
import EditFreeCredit from './edit-free-credit'
import { GET_ONE_FREE_CREDIT } from '../../graphql/query/GET_ONE_FREE_CREDIT';
import Zoom from 'react-medium-image-zoom'

function FreeCreditInfo(props) {

    const { id } = props.match.params

    const [addFreeCredit, setAddFreeCredit] = React.useState(false)
    const [editFreeCredit, setEditFreeCredit] = React.useState(false)
    const [freeCreditItem, setFreeCreditItem] = React.useState(null)
    const [isEditImage, setIsEditImage] = React.useState(false)
    const [language, setLanguage] = React.useState(false)

    const onEdit = (bonus) => {
        setFreeCreditItem(bonus)
        setEditFreeCredit(true)
        setIsEditImage(false)
    }

    const onEditImage = (bonus, language) => {
        setLanguage(language)
        setFreeCreditItem(bonus)
        setEditFreeCredit(true)
        setIsEditImage(true)
    }

    const onNewImage = (language) => {
        setLanguage(language)
        setAddFreeCredit(true)
        setIsEditImage(true)
    }

    const { data: freeCreditData, refetch: refetchFreeCredit } = useQuery(GET_ONE_FREE_CREDIT, {
        variables: {
            id: id,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const RenderImageTable = ({ lang, header }) => {
        return <Card className="w-100">
            <CardBody>
                <div className="search-bar-container mb-3">
                    <div className="button-add-bonus-container font-size-16 font-weight-bold">
                        {header}
                    </div>
                </div>
                <div className="table-responsive">
                    <Table className="table mb-0">
                        <thead>
                            <tr>
                                <th style={{ width: '10%' }}>Image</th>
                                <th style={{ width: '30%' }}>Title</th>
                                <th style={{ width: '30%' }}>Redirect Url</th>
                                <th style={{ width: '30%' }}>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                freeCreditData &&
                                freeCreditData.freeCreditType &&
                                _.filter(freeCreditData.freeCreditType.freeCreditTypeDetails.edges, function (o) {
                                    return o.node.language === lang
                                }).length > 0 &&
                                _.filter(freeCreditData.freeCreditType.freeCreditTypeDetails.edges, function (o) {
                                    return o.node.language === lang
                                }).map((freeCreditTypeDetail, key) =>
                                    <tr key={"_free_credit" + key}>
                                        <td>
                                            <Zoom>
                                                <img
                                                    alt=""
                                                    src={freeCreditTypeDetail.node.imageUrl}
                                                    // width="40"
                                                    height="50"
                                                />
                                            </Zoom>
                                        </td>
                                        <td>
                                            <div className="cursor-pointer" onClick={() => {
                                                onEditImage(freeCreditTypeDetail, lang)
                                            }}>{freeCreditTypeDetail.node.title}</div>
                                        </td>
                                        <td>
                                            <div className="cursor-pointer" onClick={() => {
                                                onEditImage(freeCreditTypeDetail, lang)
                                            }}>{freeCreditTypeDetail.node.redirectUrl}</div>
                                        </td>
                                        <td>
                                            <div className="cursor-pointer" style={{ whiteSpace: 'pre-wrap' }} onClick={() => {
                                                onEditImage(freeCreditTypeDetail, lang)
                                            }}>{freeCreditTypeDetail.node.description}</div>
                                        </td>
                                    </tr >
                                )
                            }
                            {
                                freeCreditData &&
                                freeCreditData.freeCreditType &&
                                _.filter(freeCreditData.freeCreditType.freeCreditTypeDetails.edges, function (o) {
                                    return o.node.language === lang
                                }).length === 0 &&
                                <tr className="text-center pb-2">
                                    <td colSpan={12}>
                                        <button className="btn btn-primary btn-sm waves-effect" onClick={() => {
                                            onNewImage(lang)
                                        }}>
                                            Add new Image, URL and Description
                                        </button>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
    }

    return (
        <React.Fragment>
            <div className="page-content bonus-page">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Promotion Type" breadcrumbItem="Promotion Type Info" back />
                    {
                        freeCreditData &&
                        freeCreditData.freeCreditType &&
                        <Card className="w-100">
                            <CardBody>
                                <Col sm="4">
                                    <div>
                                        <p className="text-muted mb-2">Promotion Name</p>
                                        <h5 className="align-left cursor-pointer">{freeCreditData.freeCreditType.name}<span className="bx bx-edit-alt ml-1 font-size-17" onClick={() => onEdit(freeCreditData.freeCreditType)}></span></h5>
                                    </div>
                                </Col>
                            </CardBody>
                        </Card>
                    }
                    <RenderImageTable lang='EN' header='ENGLISH' />
                    <RenderImageTable lang='ZH' header='CHINESE' />
                    <RenderImageTable lang='MS' header='MALAY' />
                    {
                        addFreeCredit &&
                        <NewFreeCredit
                            modal={addFreeCredit}
                            isEditImage={isEditImage}
                            language={language}
                            parentId={id}
                            toggleModal={() => {
                                setAddFreeCredit(!addFreeCredit)
                                refetchFreeCredit()
                            }}
                        />
                    }
                    {
                        editFreeCredit &&
                        <EditFreeCredit
                            modal={editFreeCredit}
                            isEditImage={isEditImage}
                            language={language}
                            parentId={id}
                            toggleModal={() => {
                                setEditFreeCredit(!editFreeCredit)
                            }}
                            freeCredit={freeCreditItem}
                        />
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(FreeCreditInfo));

// export default ContactsList;
import gql from 'graphql-tag';

export const CREATE_ONE_BANK_ACCOUNT_ENTRY = gql`
    mutation(
        $input: CreateOneBankAccountEntryInput!
    ) {
        createOneBankAccountEntry (input: $input) {
           id
        }
    }
`;
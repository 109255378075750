import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';
import { PageFragment } from '../fragments/page.fragment';
import { BankAccountFragment } from '../fragments/bank.fragment';

export const GET_USER = gql`
    query (
        $paging: CursorPaging
        $filter: UserFilter
        $sorting: [UserSort!]
    ) {
        users (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...UserInfo
                    bankAccounts {
                        edges {
                            node {
                                ...BankAccountInfo
                            }
                        }
                    }
                    firstDepositAmount
                    lastWithdrawalAmount
                    referralFeePercentage
                    referralFee
                    referralFeePaidAt
                    referralFeePaidBy {
                        id
                        name
                    }
                    referrer {
                        id
                        name
                        username
                    }
                }
            }
        }
    }
    ${UserFragment}
    ${PageFragment}
    ${BankAccountFragment}
`;

import React from 'react';
import {
    UncontrolledTooltip
} from "reactstrap";
import moment from 'moment';

function CampaignInfoItem(props) {

    const { index, data, onCheck, checked, tab, campaign } = props;

    const GetLanguage = (lang) => {
        if (lang == null) {
            return "All Language"
        } else if (lang == "EN") {
            return "English"
        } else if (lang == "MS") {
            return "Malay"
        } else if (lang == "ZH") {
            return "Chinese"
        }
    }

    return <React.Fragment>
        <tr className="cursor-pointer user-select-none" onClick={() => onCheck(!checked)}>
            <td className="align-left">
                {
                    (tab === 'Available' || tab === 'Waiting' || tab === 'Sent' || tab === 'Failed') &&
                    <div className="custom-control custom-checkbox" style={{ pointerEvents: 'none' }}>
                        <input type="checkbox" className="custom-control-input" id={`checkbox_${index}`} checked={checked} />
                        <label className="custom-control-label" htmlFor={`checkbox_${index}`}>{data.node.participant.username}</label>
                    </div>
                }
                {
                    tab !== 'Available' && tab !== 'Waiting' && tab !== 'Sent' && tab !== 'Failed' &&
                    data.node.participant.username
                }
                {/* {
                    data.node.participant.tags.edges.length > 0 &&
                    <>
                        <div className="pl-1"></div>
                        <i className="bx bxs-info-circle text-danger font-size-16" id={`tooltip-campaign-${data.node.participant.id}`} />
                        <UncontrolledTooltip placement="right" target={`tooltip-campaign-${data.node.participant.id}`}>
                            {
                                data.node.participant.tags.edges.map((tag) =>
                                    <li className="align-left">
                                        {
                                            tag.node.tagType === 'Good' &&
                                            <i className="bx bx-check font-size-18 text-success mr-1" />
                                        }
                                        {
                                            tag.node.tagType === 'Neutral' &&
                                            <i className="bx bxs-circle font-size-10 text-white ml-1 mr-2 mt-1" />
                                        }
                                        {
                                            tag.node.tagType === 'Bad' &&
                                            <i className="bx bx-x font-size-18 text-danger mr-1" />
                                        }
                                        {tag.node.name}
                                    </li>
                                )
                            }
                        </UncontrolledTooltip>
                    </>
                } */}
            </td>
            <td>{moment(data.node.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
            {
                campaign &&
                campaign.marketingCampaign.marketingCampaignMedium === 'Telegram' &&
                <td>{data.node.participant.telegramId}</td>
            }
            {
                campaign &&
                campaign.marketingCampaign.marketingCampaignMedium === 'SMS' &&
                <td>{data.node.participant.phoneNo}</td>
            }
            {
                <td>{GetLanguage(data.node.participant.language)}</td>
            }
            {
                tab !== 'Sent' &&
                <td>{data.node.remark}</td>
            }
            {
                tab === 'Sent' &&
                <td>{moment(data.node.sentAt).format('YYYY-MM-DD HH:mm:ss')}</td>
            }
            <td>{data.node.participant.firstDepositAt ? moment(data.node.participant.firstDepositAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}</td>
            <td>{data.node.participant.lastDepositAt ? moment(data.node.participant.lastDepositAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}</td>
            <td>{data.node.participant.lastDepositAt ? moment().diff(moment(data.node.participant.lastDepositAt), 'days') <= 7 ? <i className="bx bx-check font-size-18 text-success"></i> : <i className="bx bx-x font-size-18 text-danger"></i> : <i className="bx bx-x font-size-18 text-danger"></i>}</td>
        </tr>
    </React.Fragment>
}

export default CampaignInfoItem;
import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import ToastHelper from '../../helpers/ToastHelper';
import DropZone from '../Workbench/Components/drop-zone';
import Compressor from 'compressorjs';
import CloudinaryHelper from '../../helpers/CloudinaryHelper';
import { UPDATE_ONE_FREE_CREDIT_TYPE_DETAIL } from '../../graphql/mutation/UPDATE_ONE_FREE_CREDIT_TYPE_DETAIL';
import { UPDATE_ONE_FREE_CREDIT_TYPE } from '../../graphql/mutation/UPDATE_ONE_FREE_CREDIT_TYPE';

function EditFreeCredit(props) {

    const { modal, toggleModal, t, freeCredit, isEditImage, language, parentId } = props;

    const [editOneFreeCreditTypeDetail] = useMutation(UPDATE_ONE_FREE_CREDIT_TYPE_DETAIL);
    const [editOneFreeCreditType] = useMutation(UPDATE_ONE_FREE_CREDIT_TYPE);

    const [selectedFile, setSelectFile] = React.useState(null);
    const [currentImage, setCurrentImage] = React.useState(freeCredit.node ? freeCredit.node.imageUrl : null)

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-bonus-page">
                    <ModalHeader toggle={toggleModal}>
                        EDIT Promotion Type
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    if (isEditImage) {
                                        let result
                                        if (selectedFile) {
                                            let imageData = await Compress(selectedFile)
                                            result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=promotion`)
                                        }
                                        editOneFreeCreditTypeDetail({
                                            variables: {
                                                input: {
                                                    update: {
                                                        title: values.title,
                                                        description: values.description,
                                                        imageUrl: selectedFile ? result.data.url : currentImage ? currentImage : null,
                                                        redirectUrl: values.redirectUrl,
                                                        language: language,
                                                        freeCreditTypeId: parentId,
                                                    },
                                                    id: freeCredit.node.id
                                                }
                                            }
                                        }).catch(e => {
                                            ToastHelper.toggleToast({
                                                toastType: "error",
                                                message: e,
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }).then(val => {
                                            if (val) {
                                                ToastHelper.toggleToast({
                                                    message: "Update Succesfully!",
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                                toggleModal()
                                            }
                                        })
                                    } else {
                                        editOneFreeCreditType({
                                            variables: {
                                                input: {
                                                    update: {
                                                        name: values.name,
                                                        isPublished: values.isPublished === 'true' ? true : false,
                                                        isActive: values.status === 'true' ? true : false
                                                    },
                                                    id: freeCredit.id
                                                }
                                            }
                                        }).catch(e => {
                                            ToastHelper.toggleToast({
                                                toastType: "error",
                                                message: e,
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }).then(val => {
                                            if (val) {
                                                ToastHelper.toggleToast({
                                                    message: "Update Succesfully!",
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                                toggleModal()
                                            }
                                        })
                                    }

                                }}
                                onInvalidSubmit={() => {

                                }}
                            >
                                {
                                    isEditImage &&
                                    <Row>
                                        <Col>
                                            <DropZone
                                                selectedFile={selectedFile}
                                                onFileDrop={(file) => {
                                                    setSelectFile(file)
                                                    setCurrentImage(null)
                                                }}
                                                currentImage={currentImage}
                                            />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    {
                                        !isEditImage &&
                                        <Col lg={12}>
                                            <AvField
                                                value={freeCredit.name}
                                                label="Promotion Type Name"
                                                name="name"
                                                type="text"
                                                placeholder="Promotion Type Name"
                                                errorMessage="Promotion Type Name"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </Col>
                                    }
                                    {
                                        isEditImage &&
                                        <>
                                            <Col lg={12}>
                                                <AvField
                                                    value={freeCredit.node.title}
                                                    label="Title"
                                                    name="title"
                                                    type="text"
                                                    placeholder="Title"
                                                    errorMessage="Title"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={12}>
                                                <AvField
                                                    value={freeCredit.node.redirectUrl}
                                                    label="Redirect Url"
                                                    name="redirectUrl"
                                                    type="text"
                                                    placeholder="Redirect Url"
                                                    errorMessage="Redirect Url"
                                                    validate={{
                                                        required: { value: false },
                                                        pattern: { value: '(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})', errorMessage: 'Invalid URL' },
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={12}>
                                                <AvField
                                                    value={freeCredit.node.description}
                                                    label="Description"
                                                    name="description"
                                                    type="textarea"
                                                    rows="5"
                                                    placeholder="Description"
                                                    errorMessage="Description"
                                                    validate={{
                                                        required: { value: false },
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {
                                        !isEditImage &&
                                        <>
                                            <Col lg={6}>
                                                <AvField
                                                    label={`${t('Published?')}`}
                                                    type="select"
                                                    name="isPublished"
                                                    className="custom-select"
                                                    id="isPublished"
                                                    value={freeCredit.isPublished ? 'true' : 'false'}
                                                >
                                                    <option value={'true'}>Yes</option>
                                                    <option value={'false'}>No</option>
                                                </AvField>
                                            </Col>
                                            <Col lg={6}>
                                                <AvField
                                                    label={`${t('Status')}`}
                                                    type="select"
                                                    name="status"
                                                    className="custom-select"
                                                    id="status-select"
                                                    value={freeCredit.isActive ? 'true' : 'false'}
                                                >
                                                    <option value={'true'}>Active</option>
                                                    <option value={'false'}>Not Active</option>
                                                </AvField>
                                            </Col>
                                        </>
                                    }
                                </Row>
                                {
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(EditFreeCredit));
import gql from 'graphql-tag';
import { GameAccountFragment } from '../fragments/game-account.fragment';


export const GAME_ACCOUNT_RESET_PASSWORD = gql`
    mutation(
        $id: String!
    ) {
        gameAccountResetPassword(id:$id){
            ...GameAccountInfo
        }
    }
    ${GameAccountFragment}
`;
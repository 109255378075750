import gql from "graphql-tag";

import { TelegramMenuFragment } from "../fragments/telegram-menu.fragment";

export const CREATE_ONE_TELEGRAM_MENU = gql`
  mutation ($input: CreateOneTelegramMenuInput!) {
    createOneTelegramMenu(input: $input) {
      ...TelegramMenu
    }
  }
  ${TelegramMenuFragment}
`;

import gql from 'graphql-tag';
import { TransactionFragment } from './transaction.fragment';

export const WalletEntryFragment = gql`
    fragment WalletEntryInfo on WalletEntry {
        id
        transaction {
            ...TransactionInfo
        }
        wallet {
            user {
                id
                username
            }
        }
        withdrawal
        deposit
        walletEntryType
        description
        createdAt
        createdBy
        updatedAt
        updatedBy
    }
    ${TransactionFragment}
`;



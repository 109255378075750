import React from 'react';
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap";
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginSuccess, apiError } from '../../store/actions';

// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { useLazyQuery, useMutation } from 'react-apollo';
import { LOGIN } from '../../graphql/mutation';
import ErrorHelper from '../../helpers/ErrorHelper';
import { ME } from '../../graphql/query';
import { OperatingUsers } from '../../assets/scss/role.constant';

function Login(props) {

    const [login] = useMutation(LOGIN);
    const [getMe, { data: meData }] = useLazyQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    React.useEffect(() => {
        props.apiError("")
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // handleValidSubmit
    const handleValidSubmit = (event, values) => {
        // props.loginUser(values, props.history);
        login({
            variables: {
                key: values.username,
                password: values.password
            }
        }).then(async (result) => {
            if (result) {
                await window.localStorage.setItem("AUTH_TOKEN", result.data.login);
                // client.cache.reset()
                // props.history.push('/dashboard');
                getMe()
                //Need this to update the app.js data
                // setTimeout(() => {
                //     window.location.reload()
                // }, 200);
            }
        }).catch((e) => {
            props.apiError(ErrorHelper.getGraphQLErrorOnly(e))
        })
    }

    React.useEffect(() => {
        if (meData) {
            if (OperatingUsers.includes(meData.me.role)) {
                window.location.pathname = '/cashier-workbench'
            } else {
                window.location.pathname = '/dashboard'
            }
        }
    }, [meData])

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark"><i className="bx bx-home h2"></i></Link>
            </div>
            <div className="account-pages my-5 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="bg-soft-primary">
                                    <Row>
                                        <Col className="col-7">
                                            <div className="text-primary p-4">
                                                <h5 className="text-primary">Welcome Back !</h5>
                                            </div>
                                        </Col>
                                        <Col className="col-5 align-self-end">
                                            <img src={profile} alt="" className="img-fluid" />
                                        </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                        <Link to="/">
                                            <div className="avatar-md profile-user-wid mb-4">
                                                <span className="avatar-title rounded-circle bg-light">
                                                    <img src={logo} alt="" className="rounded-circle" height="34" />
                                                </span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="p-2">

                                        <AvForm className="form-horizontal" onValidSubmit={handleValidSubmit}>

                                            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}

                                            <div className="form-group">
                                                <AvField name="username" label="Usrename" value="" className="form-control" placeholder="Enter username" type="text" required />
                                            </div>

                                            <div className="form-group">
                                                <AvField name="password" label="Password" value="" type="password" required placeholder="Enter Password" />
                                            </div>

                                            <div className="custom-control custom-checkbox">
                                                <input type="checkbox" className="custom-control-input" id="customControlInline" />
                                                <label className="custom-control-label" htmlFor="customControlInline">Remember me</label>
                                            </div>

                                            <div className="mt-3">
                                                <button className="btn btn-primary btn-block waves-effect waves-light" type="submit">Log In</button>
                                            </div>

                                            <div className="mt-4 text-center">
                                                <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock mr-1"></i> Forgot your password?</Link>
                                            </div>
                                        </AvForm>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { error } = state.Login;
    return { error };
}

export default withRouter(connect(mapStatetoProps, { loginSuccess, apiError })(Login));


import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';
import { TagFragment } from '../fragments/tag.fragment';

export const ADD_TAG_USER = gql`
    mutation(
        $input: RelationsInput!
    ) {
        addTagsToUser (input: $input) {
            ...UserInfo
            tags{
                edges {
                    node {
                        ...TagInfo
                    }
                }
            }
        }
    }
    ${UserFragment}
    ${TagFragment}
`;
import React from 'react';
import {
    Modal,
    ModalHeader,
    ModalBody,
} from "reactstrap";
import EditPlatform from "./edit-platform"

export default function EditPlatformModal(props) {

    const { modal, toggleModal, platform } = props;

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content edit-user-page">
                    <ModalHeader toggle={toggleModal}>
                        Edit Plaform
                    </ModalHeader>
                    <ModalBody>
                        <EditPlatform
                            platform={platform}
                            modalCallback={()=>{
                                toggleModal();
                            }}
                        />
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}
import gql from 'graphql-tag';

export const DELETE_ONE_SETTING = gql`
    mutation(
        $id: ID!
    ) {
        deleteOneSetting (input: {
            id:$id
        }) {
            id
        }
    }
`;


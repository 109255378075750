import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const ASSIGN_REFERRER = gql`
    mutation(
        $referrerId: String!
        $playerId: String!
    ) {
        assignReferrer (
            referrerId: $referrerId
            playerId: $playerId
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
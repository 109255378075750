import gql from 'graphql-tag';

export const CREATE_ONE_WALLET_ENTRY = gql`
    mutation(
        $input: CreateOneWalletEntryInput!
    ) {
        createOneWalletEntry (input: $input) {
           id
        }
    }
`;
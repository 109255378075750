import gql from 'graphql-tag';
import { PlatformFragment } from '../fragments/platform.fragment';
import { KioskFragment } from '../fragments/kiosk.fragment';


export const GET_ONE_PLATFORM = gql`
    query (
        $id:ID!
    ) {
        platform (
            id: $id 
        ) {
            ...PlatformInfo
            kioskAccounts{
                edges {
                    node {
                        ...KioskInfo
                    }
                }
            }
        }
    }
    ${KioskFragment}
    ${PlatformFragment}
`;
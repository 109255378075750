import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';

export const CREATE_ONE_KIOSK = gql`
    mutation(
        $input: CreateOneKioskAccountInput!
    ) {
        createOneKioskAccount (input: $input) {
            ...KioskInfo
        }
    }
    ${KioskFragment}
`;
import gql from 'graphql-tag';
import { CurrencyFragment } from '../fragments/currency.fragment';

export const UDPATE_ONE_CURRENCY = gql`
    mutation(
        $input: UpdateOneCurrencyInput!
    ) {
        updateOneCurrency (input: $input) {
            ...CurrencyInfo
        }
    }
    ${CurrencyFragment}
`;
import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Input,
    Label,
    Progress,
    FormGroup
} from "reactstrap";
import ReactJson from 'react-json-view'
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToastHelper from '../../helpers/ToastHelper';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useMutation, useQuery } from 'react-apollo';
import { UPDATE_ONE_SETTING } from '../../graphql/mutation';
import { GET_ENUM, ME } from '../../graphql/query';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import DropZone from '../Workbench/Components/drop-zone';
import Compressor from 'compressorjs';
import CloudinaryHelper from "../../helpers/CloudinaryHelper"
import Switch from "react-switch";

function EditSetting(props) {

    const { modal, toggleModal, t, setting } = props;
    const [fieldType, setFieldType] = React.useState(null)
    const [defaultValue, setDefaultValue] = React.useState(null)
    const [value, setValue] = React.useState(null)
    const [devOnly, setDevOnly] = React.useState(false)

    const [defaultFile, setDefaultFile] = React.useState(null)
    const [valueFile, setValueFile] = React.useState(null)

    const [currentDefaultFile, setCurrentDefaultFile] = React.useState(null)
    const [currentFile, setCurrentFile] = React.useState(null)
    const [uploadProgress, setUploadProgress] = React.useState(0)
    const [onUpload, setOnUpload] = React.useState(false);

    const { data: fieldTypeData } = useQuery(GET_ENUM, {
        variables: {
            name: "FieldType"
        }
    });

    const [updateOneSetting] = useMutation(UPDATE_ONE_SETTING)
    const { data: meData } = useQuery(ME)

    React.useEffect(() => {
        if (setting) {
            setFieldType(setting.node.fieldType)
            setDefaultValue(setting.node.fieldType === "JSON" ? JSON.parse(setting.node.defaultValue) : setting.node.defaultValue)
            setValue(setting.node.fieldType === "JSON" ? JSON.parse(setting.node.value) : setting.node.value)
            setDevOnly(setting.node.developerOnly)

            if (setting.node.fieldType === 'File' || setting.node.fieldType === 'Video') {
                setCurrentDefaultFile(setting.node.defaultValue)
                setCurrentFile(setting.node.value)
            }
        }
    }, [setting])

    React.useEffect(() => {
        if (fieldTypeData) {
            setFieldType(fieldTypeData.__type.enumValues[0].name)
        }
    }, [fieldTypeData])

    const options = {
        "buttonList": [
            [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "bold",
                "underline",
                "italic",
                "strike",
                "fontColor",
                "hiliteColor",
                "textStyle",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
            ]
        ]
    }

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const UpdateDefaultValueJson = (val) => {
        updateOneSetting({
            variables: {
                input: {
                    update: {
                        defaultValue: JSON.stringify(val),
                    },
                    id: setting.node.id
                }
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Update Succesfully!",
                    title: "",
                    onClick: function () { }
                })
            }
        })
    }

    const UpdateValueJson = (val) => {
        updateOneSetting({
            variables: {
                input: {
                    update: {
                        value: JSON.stringify(val),
                    },
                    id: setting.node.id
                }
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Update Succesfully!",
                    title: "",
                    onClick: function () { }
                })
            }
        })
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content edit-setting">
                    <ModalHeader toggle={toggleModal}>
                        {t('Edit Setting')}
                    </ModalHeader>
                    <ModalBody>
                        <div className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    let defaultImage
                                    let valueImage
                                    let upload = 'image'

                                    if (fieldType === 'File' || fieldType === 'Video') {
                                        let count = 0
                                        if (defaultFile) {
                                            count = count + 1
                                        }

                                        if (valueFile) {
                                            count = count + 1
                                        }

                                        if (count > 0) {
                                            setOnUpload(true)
                                            if (defaultFile) {
                                                let defaultData = await Compress(defaultFile)
                                                if (defaultFile['type'].split('/')[0] === 'audio') {
                                                    upload = 'video'
                                                } else if (defaultFile['type'].split('/')[0] === 'video') {
                                                    upload = 'video'
                                                }
                                                defaultImage = await CloudinaryHelper.upload(defaultData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/${upload}/upload?upload_preset=v13sqvvy`, (progressEvent) => {
                                                    let val = Math.round((progressEvent.loaded / progressEvent.total) * (100 / count))
                                                    setUploadProgress(val)
                                                })
                                            }

                                            if (valueFile) {
                                                let valueData = await Compress(valueFile)
                                                if (valueFile['type'].split('/')[0] === 'audio') {
                                                    upload = 'raw'
                                                }
                                                valueImage = await CloudinaryHelper.upload(valueData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/${upload}/upload?upload_preset=v13sqvvy`, (progressEvent) => {
                                                    let val = Math.round((progressEvent.loaded / progressEvent.total) * (100 / count)) + (100 / count)
                                                    setUploadProgress(val)
                                                })
                                            }

                                            setOnUpload(false)
                                        }
                                    }

                                    updateOneSetting({
                                        variables: {
                                            input: {
                                                update: {
                                                    description: values.description,
                                                    defaultValue: defaultImage ? defaultImage.data.url : fieldType === 'JSON' ? JSON.stringify(defaultValue) : defaultValue,
                                                    value: valueImage ? valueImage.data.url : fieldType === 'JSON' ? JSON.stringify(value) : value,
                                                    key: values.key,
                                                    fieldType: values.fieldType,
                                                    developerOnly: devOnly
                                                },
                                                id: setting.node.id
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })
                                }}
                            >
                                <Row>
                                    <Col lg="12">
                                        <AvField
                                            value={setting ? setting.node.description : ''}
                                            label="Description"
                                            name="description"
                                            type="text"
                                            placeholder="Enter Description"
                                            errorMessage="Enter description"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    {
                                        meData &&
                                        <>
                                            <Col lg="6">
                                                <AvField
                                                    disabled={meData.me.role !== "Developer"}
                                                    label="Field Type"
                                                    name="fieldType"
                                                    type="select"
                                                    className="custom-select"
                                                    value={fieldType}
                                                    onChange={(e) => {
                                                        setFieldType(e.target.value)
                                                    }}
                                                >
                                                    {
                                                        fieldTypeData &&
                                                        fieldTypeData.__type.enumValues.map(({ name }, index) => {
                                                            return (
                                                                <option key={index} value={name}>{name}</option>
                                                            )
                                                        })
                                                    }
                                                </AvField>
                                            </Col>
                                            <Col lg="6">
                                                <AvField
                                                    disabled={meData.me.role !== "Developer"}
                                                    value={setting ? setting.node.key : ''}
                                                    label="Key"
                                                    name="key"
                                                    type="text"
                                                    placeholder="Enter Key"
                                                    errorMessage="Enter key"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {
                                        fieldType === 'TextField' &&
                                        <>
                                            {
                                                meData &&
                                                meData.me.role === "Developer" &&
                                                <Col lg="6">
                                                    <AvField
                                                        value={defaultValue}
                                                        label="Default Value"
                                                        name="defaultValue"
                                                        type="text"
                                                        placeholder="Enter Default Value"
                                                        errorMessage="Enter default value"
                                                        onChange={(e) => {
                                                            setDefaultValue(e.target.value)
                                                        }}
                                                    />
                                                </Col>
                                            }
                                            <Col lg="6">
                                                <AvField
                                                    value={value}
                                                    label="Value"
                                                    name="value"
                                                    type="text"
                                                    placeholder="Enter Value"
                                                    errorMessage="Enter value"
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                    }}
                                                // validate={{
                                                //     required: { value: true },
                                                // }}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {
                                        fieldType === 'TextArea' &&
                                        <>
                                            {
                                                meData &&
                                                meData.me.role === "Developer" &&
                                                <Col lg="6">
                                                    <AvField
                                                        value={defaultValue}
                                                        label="Default Value"
                                                        name="defaultValue"
                                                        type="textarea"
                                                        rows="5"
                                                        placeholder="Enter Default Value"
                                                        errorMessage="Enter default value"
                                                        onChange={(e) => {
                                                            setDefaultValue(e.target.value)
                                                        }}
                                                    />
                                                </Col>
                                            }
                                            <Col lg="6">
                                                <AvField
                                                    value={value}
                                                    label="Value"
                                                    name="value"
                                                    type="textarea"
                                                    rows="5"
                                                    placeholder="Enter Value"
                                                    errorMessage="Enter value"
                                                    onChange={(e) => {
                                                        setValue(e.target.value)
                                                    }}
                                                // validate={{
                                                //     required: { value: true },
                                                // }}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {
                                        fieldType === 'RichText' &&
                                        <>
                                            {
                                                meData &&
                                                meData.me.role === "Developer" &&
                                                <Col xs="12">
                                                    <AvField
                                                        setContents={defaultValue}
                                                        label="Default Value"
                                                        name="defaultValue"
                                                        type="text"
                                                        placeholder="Enter Default Value"
                                                        errorMessage="Enter default value"
                                                        tag={[SunEditor, Input]}
                                                        onChange={(val) => {
                                                            setDefaultValue(val)
                                                        }}
                                                        setOptions={options}
                                                    />
                                                </Col>
                                            }
                                            <Col xs="12">
                                                <AvField
                                                    setContents={value}
                                                    label="Value"
                                                    name="value"
                                                    type="text"
                                                    placeholder="Enter Value"
                                                    errorMessage="Enter value"
                                                    tag={[SunEditor, Input]}
                                                    onChange={(val) => {
                                                        setValue(val)
                                                    }}
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                    setOptions={options}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {
                                        fieldType === 'JSON' &&
                                        <>
                                            <Col xs="12" className="mb-3">
                                                <Label>Default Value</Label>
                                                <ReactJson
                                                    src={defaultValue}
                                                    name="defaultValue"
                                                    onEdit={(value) => {
                                                        setDefaultValue(value.updated_src)
                                                        UpdateDefaultValueJson(value.updated_src)
                                                    }}
                                                    onAdd={(value) => {
                                                        setDefaultValue(value.updated_src)
                                                        UpdateDefaultValueJson(value.updated_src)
                                                    }}
                                                    onDelete={(value) => {
                                                        setDefaultValue(value.updated_src)
                                                        UpdateDefaultValueJson(value.updated_src)
                                                    }}
                                                />
                                            </Col>
                                            <Col xs="12" className="mb-3">
                                                <Label>Value</Label>
                                                <ReactJson
                                                    src={value}
                                                    name="value"
                                                    onEdit={(value) => {
                                                        setValue(value.updated_src)
                                                        UpdateValueJson(value.updated_src)
                                                    }}
                                                    onAdd={(value) => {
                                                        setValue(value.updated_src)
                                                        UpdateValueJson(value.updated_src)
                                                    }}
                                                    onDelete={(value) => {
                                                        setValue(value.updated_src)
                                                        UpdateValueJson(value.updated_src)
                                                    }}
                                                />
                                            </Col>
                                        </>
                                    }
                                    {
                                        fieldType === 'Boolean' &&
                                        <>
                                            {
                                                meData &&
                                                meData.me.role === "Developer" &&
                                                <Col lg="6">
                                                    <AvField
                                                        name="defaultValue"
                                                        label="Default Value"
                                                        uncheckedIcon={<Offsymbol text="NO" />}
                                                        checkedIcon={<OnSymbol text="YES" />}
                                                        className="mr-1 pl-0 display-block"
                                                        onColor="#34c38f"
                                                        offColor="#f46a6a"
                                                        onChange={(e) => {
                                                            setDefaultValue(e.toString())
                                                        }}
                                                        checked={defaultValue === 'true'}
                                                        tag={[Switch, Input]}
                                                    />
                                                </Col>
                                            }
                                            {
                                                <Col lg="6">
                                                    <AvField
                                                        name="value"
                                                        label="Value"
                                                        uncheckedIcon={<Offsymbol text="NO" />}
                                                        checkedIcon={<OnSymbol text="YES" />}
                                                        className="mr-1 pl-0 display-block"
                                                        onColor="#34c38f"
                                                        offColor="#f46a6a"
                                                        onChange={(e) => {
                                                            setValue(e.toString())
                                                        }}
                                                        checked={value === 'true'}
                                                        tag={[Switch]}
                                                    />
                                                </Col>
                                            }
                                        </>
                                    }
                                </Row>
                                {
                                    fieldType === 'File' &&
                                    <>
                                        <Row>
                                            <Col lg="6">
                                                {
                                                    meData &&
                                                    meData.me.role === "Developer" &&
                                                    <AvField
                                                        label="Default File"
                                                        name="defaultValue"
                                                        type="text"
                                                        placeholder="Default File"
                                                        errorMessage="* Please Provide an Image"
                                                        tag={[DropZone]}
                                                        selectedFile={defaultFile}
                                                        currentImage={currentDefaultFile}
                                                        onFileDrop={(file) => {
                                                            setCurrentDefaultFile(null)
                                                            setDefaultFile(file)
                                                        }}
                                                    />

                                                }
                                            </Col>
                                            <Col lg="6">
                                                <AvField
                                                    label="Current File"
                                                    name="value"
                                                    type="text"
                                                    placeholder="Current File"
                                                    errorMessage="* Please Provide an File"
                                                    tag={[DropZone]}
                                                    selectedFile={valueFile}
                                                    currentImage={currentFile}
                                                    onFileDrop={(file) => {
                                                        setCurrentFile(null)
                                                        setValueFile(file)
                                                    }}
                                                />
                                            </Col>
                                            {
                                                onUpload &&
                                                <Col lg={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label>Uploading</Label>
                                                        <Progress color="primary" value={uploadProgress}></Progress>
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>
                                    </>
                                }
                                {
                                    fieldType === 'Video' &&
                                    <>
                                        <Row>
                                            {
                                                meData &&
                                                meData.me.role === "Developer" &&
                                                <Col lg="6">
                                                    <AvField
                                                        label="Default Video"
                                                        name="defaultValue"
                                                        placeholder="Default Video"
                                                        errorMessage="* Please Provide an Video"
                                                        type={"video/mp4"}
                                                        tag={[DropZone]}
                                                        selectedFile={defaultFile}
                                                        currentImage={currentDefaultFile}
                                                        onFileDrop={(file) => {
                                                            setCurrentDefaultFile(null)
                                                            setDefaultFile(file)
                                                        }}
                                                    />
                                                </Col>
                                            }
                                            <Col lg="6">
                                                <AvField
                                                    label="Current Video"
                                                    name="value"
                                                    placeholder="Current Video"
                                                    errorMessage="* Please Provide an Video"
                                                    type={"video/mp4"}
                                                    tag={[DropZone]}
                                                    selectedFile={valueFile}
                                                    currentImage={currentFile}
                                                    onFileDrop={(file) => {
                                                        setCurrentFile(null)
                                                        setValueFile(file)
                                                    }}
                                                />
                                            </Col>
                                            {
                                                onUpload &&
                                                <Col lg={12}>
                                                    <FormGroup className="mb-3">
                                                        <Label>Uploading</Label>
                                                        <Progress color="primary" value={uploadProgress}></Progress>
                                                    </FormGroup>
                                                </Col>
                                            }
                                        </Row>
                                    </>
                                }
                                {
                                    setting &&
                                    meData &&
                                    meData.me.role === "Developer" &&
                                    <Row>
                                        <Col lg="6">
                                            <AvField
                                                name="developerOnly"
                                                label="Developer Only"
                                                uncheckedIcon={<Offsymbol text="NO" />}
                                                checkedIcon={<OnSymbol text="YES" />}
                                                className="mr-1 pl-0 display-block"
                                                onColor="#34c38f"
                                                offColor="#f46a6a"
                                                checked={devOnly}
                                                tag={[Switch, Input]}
                                                onChange={(e) => {
                                                    setDevOnly(e)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                }
                                <div className="action-container">
                                    <Button color="primary" type="submit">{t('Submit')}</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default connect()(withTranslation()(EditSetting));

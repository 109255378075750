import gql from 'graphql-tag';

export const FIND_OR_CREATE_ONE_SETTING = gql`
    mutation(
       $input: FindOrCreateSettingInput!
    ) {
        findOrCreateSetting (
            findOrCreateSettingInput: $input
        ) {
            id
            defaultValue
            description
            key
            value
            fieldType
            updatedAt
            developerOnly
        }
    }
`;
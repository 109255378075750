import { AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import React from "react";
import { useMutation } from "react-apollo";
import { withTranslation } from "react-i18next";
import ReactJson from "react-json-view";
import { connect } from "react-redux";
import Switch from "react-switch";
import { Input, Label } from "reactstrap";
import SunEditor from "suneditor-react";
import {
  FIND_OR_CREATE_ONE_SETTING,
  UPDATE_ONE_SETTING,
} from "../../graphql/mutation";
import RoleHelper from "../../helpers/RoleHelper";
import ToastHelper from "../../helpers/ToastHelper";

function SettingEditor(props) {
  const {
    description,
    settingKey,
    fieldType,
    inputValue,
    settingType,
    defaultValue,
    pattern,
    roles,
  } = props;

  const [value, setValue] = React.useState("");
  const [type, setType] = React.useState("");
  const [getSettings, { data: settings }] = useMutation(
    FIND_OR_CREATE_ONE_SETTING,
    {
      variables: {
        input: {
          description: description,
          key: settingKey,
          value: inputValue,
          defaultValue: defaultValue,
          settingType: settingType,
          fieldType: fieldType,
        },
      },
    }
  );

  const [updateOneSetting] = useMutation(UPDATE_ONE_SETTING);

  React.useEffect(() => {
    getSettings().catch((e) => {
      ToastHelper.toggleToast({
        toastType: "error",
        message: e,
        title: "",
        onClick: function () { },
      });
    });
  }, []);

  React.useEffect(() => {
    if (settings) {
      setValue(
        settings.findOrCreateSetting.fieldType === "JSON"
          ? JSON.parse(settings.findOrCreateSetting.value)
          : settings.findOrCreateSetting.value
      );
      setType(settings.findOrCreateSetting.fieldType);
      console.log(settings.findOrCreateSetting.fieldType);
    }
  }, [settings]);

  const updateSetting = (val) => {
    updateOneSetting({
      variables: {
        input: {
          update: {
            value: fieldType === "JSON" ? JSON.stringify(val) : val,
          },
          id: settings.findOrCreateSetting.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      })
      .then((val) => {
        if (val) {
          ToastHelper.toggleToast({
            message: "Update Succesfully!",
            title: "",
            onClick: function () { },
          });

          getSettings();
        }
      });
  };

  const options = {
    buttonList: [
      [
        "undo",
        "redo",
        "font",
        "fontSize",
        "formatBlock",
        "paragraphStyle",
        "bold",
        "underline",
        "italic",
        "strike",
        "fontColor",
        "hiliteColor",
        "textStyle",
        "removeFormat",
        "outdent",
        "indent",
        "align",
        "horizontalRule",
        "list",
      ],
    ],
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {props.text}
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {props.text}
      </div>
    );
  };

  const disabledRoles = roles !== undefined ? !RoleHelper.checkRoles(roles) : false

  return (
    <React.Fragment>
      {(type == "TextField" ||
        type == "TextArea" ||
        type == "RichText" ||
        type == "JSON" ||
        type == "Boolean") && (
          <AvForm
            className="needs-validation"
            onValidSubmit={async (event, values) => {
              updateSetting(value);
            }}
          >
            {type == "TextField" && (
              <AvField
                value={value}
                label={description}
                name="settings"
                type="text"
                placeholder={`Enter ${description}`}
                errorMessage={`Enter ${description}`}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                disabled={disabledRoles}
                validate={pattern ? { pattern: pattern } : {}}
              />
            )}
            {type == "TextArea" && (
              <AvField
                value={value}
                label={description}
                name="settings"
                type="textarea"
                rows="5"
                placeholder={`Enter ${description}`}
                errorMessage={`Enter ${description}`}
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                disabled={disabledRoles}
              />
            )}
            {type == "RichText" && (
              <AvField
                style={{
                  height: "120px",
                  overflow: "scroll",
                  marginBottom: "5px",
                }}
                setContents={value}
                label={description}
                name="settings"
                type="text"
                placeholder={`Enter ${description}`}
                errorMessage={`Enter ${description}`}
                tag={[SunEditor, Input]}
                onChange={(val) => {
                  setValue(val);
                }}
                setOptions={options}
                disabled={disabledRoles}
              />
            )}
            {type == "Boolean" && (
              <AvField
                label={description}
                name="settings"
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                className="mr-1 pl-0 display-block"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={(e) => {
                  setValue(e.toString());
                  updateSetting(e.toString());
                }}
                checked={value === "true"}
                tag={[Switch, Input]}
                disabled={disabledRoles}
              />
            )}
            {type == "JSON" && (
              <>
                <Label>{description}</Label>
                <ReactJson
                  src={value ? value : {}}
                  name="settings"
                  enableClipboard={false}
                  onEdit={disabledRoles ? false : (value) => {
                    setValue(value.updated_src);
                    updateSetting(value.updated_src);
                  }}
                />
              </>
            )}
            {type != "JSON" && type != "Boolean" && (
              <div className="align-center space-between">
                <button
                  disabled={disabledRoles}
                  type="submit"
                  className="btn btn-primary btn-sm waves-effect"
                >
                  Submit
                </button>
                {settings && (
                  <div className="align-center">
                    {`Last Updated At: `}
                    <div className="font-weight-bold ml-2">{`${moment(
                      settings.findOrCreateSetting.updatedAt
                    ).format("YYYY-MM-DD HH:mm:ss")}`}</div>
                  </div>
                )}
              </div>
            )}
            {type == "Boolean" && (
              <div className="align-center space-between">
                <div></div>
                {settings && (
                  <div className="align-center">
                    {`Last Updated At: `}
                    <div className="font-weight-bold ml-2">{`${moment(
                      settings.findOrCreateSetting.updatedAt
                    ).format("YYYY-MM-DD HH:mm:ss")}`}</div>
                  </div>
                )}
              </div>
            )}
          </AvForm>
        )}
    </React.Fragment>
  );
}

export default connect()(withTranslation()(SettingEditor));

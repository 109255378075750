import React, { useEffect, Fragment } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Card
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { GET_PLATFORMS } from '../../graphql/query/GET_PLATFORMS';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { ADD_PLATFORM_CATEGORY } from '../../graphql/mutation/ADD_PLATFORM_CATEGORY';
import { REMOVE_PLATFORM_CATEGORY } from '../../graphql/mutation/REMOVE_PLATFORM_CATEGORY';
import { GET_CATEGORY_BY_ID } from '../../graphql/query/GET_CATEGORY_BY_ID';

function ManagePlatform(props) {

    const { modal, toggleModal, category } = props;

    const [search, setSearch] = React.useState("");


    const handleTagSearch = (data) => {
        setSearch(data)
    }

    const { data: categoryData, refetch } = useQuery(GET_CATEGORY_BY_ID, {
        variables: {
            id: category ? category.node.id : ''
        },
        notifyOnNetworkStatusChange: true
    })

    const { loading, data, refetch: refetchPlatform } = useQuery(GET_PLATFORMS, {
        variables: {
            filter: { name: { iLike: `%${search}%` }, id: categoryData ? { notIn: categoryData.category.platforms.edges.map((obj) => { return obj.node.id }) } : { iLike: "%%" } },
            sorting: { field: "displayName", direction: "DESC", nulls: "NULLS_LAST" }
        },
        notifyOnNetworkStatusChange: true
    })

    const [addPlatform] = useMutation(ADD_PLATFORM_CATEGORY)
    const [removePlatform] = useMutation(REMOVE_PLATFORM_CATEGORY)

    useEffect(() => {
        setSearch("");
    }, [modal])

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                {
                    categoryData &&
                    <div className="modal-content player-tags-page">
                        <ModalHeader toggle={toggleModal}>
                            Manage Platforms
                        </ModalHeader>
                        <ModalBody>
                            <Card id="reset-password-wizard" className="twitter-bs-wizard m-1 p-2">
                                <AvForm className="needs-validation" onValidSubmit={(event, values) => {

                                }}>

                                    <Row className="align-items-center">
                                        <Col lg="12">
                                            <FormGroup>
                                                <Label for="newrole-input">Platform for {categoryData.category.name}</Label>
                                                <AsyncTypeahead
                                                    multiple={true}
                                                    delay={0}
                                                    id="tag"
                                                    isLoading={loading}
                                                    labelKey={option => `${option.node.displayName}`}
                                                    minLength={1}
                                                    onSearch={handleTagSearch}
                                                    onInputChange={(val) => {
                                                        if (val === "") {
                                                            setSearch(val)
                                                        }
                                                    }}

                                                    options={data ? data.platforms.edges : []}
                                                    placeholder="Search for Platforms"
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <span>{option.node.displayName}</span>
                                                        </Fragment>
                                                    )}
                                                    errorMessage="Enter Platforms"
                                                    onChange={async (val) => {
                                                        setSearch('')
                                                        if (categoryData.category.platforms.edges.length > 0) {
                                                            await removePlatform({
                                                                variables: {
                                                                    input: {
                                                                        id: categoryData.category.id,
                                                                        relationIds: categoryData.category.platforms.edges.map((obj) => { return obj.node.id })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        if (val.length > 0) {
                                                            await addPlatform({
                                                                variables: {
                                                                    input: {
                                                                        id: categoryData.category.id,
                                                                        relationIds: val.map((obj) => { return obj.node.id })
                                                                    }
                                                                }
                                                            })
                                                        }
                                                        await refetch()
                                                        await refetchPlatform()
                                                    }}
                                                    selected={categoryData.category.platforms.edges}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>

                                        </Col>
                                    </Row>

                                </AvForm>
                            </Card>
                        </ModalBody>
                    </div>
                }
            </Modal>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(ManagePlatform));
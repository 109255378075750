import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import BankItem from "./bank-item";
import NewBank from './new-bank';

import { GET_BANKS, ME } from '../../graphql/query';

import _ from 'lodash';

import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import EditBank from './edit-bank';
import { CREATED_BANK } from '../../graphql/subscription/CREATED_BANK';
import InfiniteScroll from 'react-infinite-scroll-component';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';

function BanksList(props) {

    const first = 50

    const [addBank, setAddBank] = React.useState(false)
    const [editBank, setEditBank] = React.useState(null)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [isSearch, setIsSearch] = React.useState(false)
    const [newData, setNewData] = React.useState(null)

    const { loading, fetchMore, data, refetch } = useQuery(GET_BANKS, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: {
                and: [
                    props.filter,
                    {
                        or: [
                            { name: { iLike: `%${search}%` } },
                        ]
                    },

                ]
            },
            sorting: [...props.sort, { field: "isActive", direction: "DESC" }, { "field": "name", direction: "ASC" }]
        },
        notifyOnNetworkStatusChange: true
    })

    const { data: meData } = useQuery(ME)

    const { data: bankCreatedData } = useSubscription(CREATED_BANK, {
        variables: {
            createdBy: { neq: meData ? meData.me.username : '' }
        }
    })

    React.useEffect(() => {

        const handleScrollToEnd = () => {
            const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
            const body = document.body;
            const html = document.documentElement;
            const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
            const windowBottom = windowHeight + window.pageYOffset;

            if (windowBottom >= docHeight && !isFetchMore && pageInfo.hasNextPage) {
                setIsFetchMore(true)
            }
        }
        window.addEventListener("scroll", handleScrollToEnd, { passive: true });

        return () => window.removeEventListener("scroll", handleScrollToEnd);
    }, [afterCursor]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (bankCreatedData) {
            setNewData(bankCreatedData.createdBank)
        }
    }, [bankCreatedData])

    React.useEffect(() => {
        if (data) {
            setAfterCursor(data.banks.pageInfo.endCursor)
            setPageInfo(data.banks.pageInfo)
        }
    }, [data])

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: search !== '' ? {
                        and: [
                            {
                                or: [
                                    { name: { iLike: `%${search}%` } },
                                    { username: { iLike: `%${search}%` } },
                                    { phoneNo: { iLike: `%${search}%` } },
                                ]
                            },
                            props.filter
                        ]
                    } : { and: [props.filter] },
                    sorting: props.sort
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.banks.edges;
                    const pageInfo = fetchMoreResult.banks.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            banks: {
                                __typename: previousResult.banks.__typename,
                                edges: _.uniq([...previousResult.banks.edges, ...newEdges]),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <div className="page-content bank-link">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Banking" breadcrumbItem="Banks List" />
                        <div className="search-bar-container">
                            <div className="button-add-bank-container">
                                <div className="app-search d-none d-lg-block">
                                    <div className="position-relative">
                                        <input
                                            value={searchValue}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setSearchValue(e.target.value)
                                                debouncedSave(e.target.value)
                                            }}
                                        />
                                        <span className="bx bx-search-alt"></span>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dropdown d-inline-block d-lg-none ml-2">
                                        <button
                                            type="button"
                                            className="btn header-item noti-icon waves-effect"
                                            id="page-header-search-dropdown"
                                            onClick={() => { setIsSearch(!isSearch) }}>
                                            <i className="mdi mdi-magnify"></i>
                                        </button>
                                        <div
                                            className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                            aria-labelledby="page-header-search-dropdown"
                                        >
                                            <form className="p-3">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input
                                                            value={searchValue}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={(e) => {
                                                                setSearchValue(e.target.value)
                                                                debouncedSave(e.target.value)
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="submit">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="button-add-bank-container">

                                <div className="filter-container button-filter-container mr-1">
                                    <button type="button" className="waves-effect btn btn-outline-secondary button-add-bank" onClick={() => {
                                        props.toggleRightSidebar();
                                    }}>
                                        <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                    </button>
                                </div>
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bank" onClick={() => { setAddBank(true) }}>
                                    <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Bank
                                </button>
                            </div>

                            <div className={`reload-container ${newData ? 'active' : ''}`}>
                                <Card>
                                    <CardBody onClick={() => {
                                        window.location.reload()
                                    }}>
                                        <div className="reload-title">
                                            <i className='bx bx-refresh'></i>Reload
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        {
                            data &&
                            <InfiniteScroll
                                dataLength={data.banks.edges.length} //This is important field to render the next data
                                next={() => setIsFetchMore(true)}
                                hasMore={pageInfo.hasNextPage}
                                loader={
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                }
                                endMessage={
                                    <div className="text-center my-3">
                                        <div>Yay! You have seen it all</div>
                                    </div>
                                }
                            >
                                <Row>
                                    <Col lg="12">
                                        <Row>
                                            {
                                                data &&
                                                data.banks.edges.map((bank, key) =>
                                                    <BankItem
                                                        bank={bank}
                                                        key={"_bank_" + key}
                                                        onEdit={() => {
                                                            setEditBank(bank.node)
                                                        }}
                                                    />
                                                )
                                            }
                                            {
                                                data &&
                                                data.banks.edges.length === 0 &&
                                                <Col xl="12" sm="12">
                                                    <div className="no-bank">No Bank Found</div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </InfiniteScroll>
                        }

                        <div className="load-more-container">
                            {
                                (loading) &&
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                </Col>
                            }
                        </div>
                        {
                            addBank &&
                            <NewBank
                                modal={addBank}
                                toggleModal={() => {
                                    refetch()
                                    setAddBank(!addBank)
                                }}
                            />
                        }
                        {
                            !_.isEmpty(editBank) &&
                            <EditBank
                                modal={!_.isEmpty(editBank)}
                                toggleModal={() => {
                                    setEditBank(!editBank)
                                }}
                                bank={editBank}
                            />
                        }
                    </Container>
                </div>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Bank };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(BanksList));

// export default ContactsList;
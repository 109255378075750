import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Button,
    Progress,
    Label
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import NumberFormat from 'react-number-format';
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../graphql/query';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Compressor from 'compressorjs';
import CloudinaryHelper from "../../helpers/CloudinaryHelper"
import { FormGroup } from '@material-ui/core';
import { UPDATE_ONE_BONUS } from '../../graphql/mutation';
import DropZone from '../Workbench/Components/drop-zone';

function EditBonus(props) {

    const { modal, toggleModal, t, bonus } = props;
    const [selectedFile, setSelectFile] = React.useState(null);
    const [existingFile, setExistingFile] = React.useState(null);
    const [fileError, setFileError] = React.useState(null);
    const [onUpload, setOnUpload] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const { data: bonusTypeData } = useQuery(GET_ENUM, {
        variables: {
            name: "BonusType"
        }
    });

    const { data: bonusComputationData } = useQuery(GET_ENUM, {
        variables: {
            name: "BonusComputation"
        }
    });

    const [updateOneBonus] = useMutation(UPDATE_ONE_BONUS);

    React.useEffect(() => {
        setSelectFile(null)
        setFileError(null)
        setProgress(0)
        setOnUpload(false)

        if (modal) {
            setExistingFile(bonus.node.bannerUrl)
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-bonus-page">
                    <ModalHeader toggle={toggleModal}>
                        Edit Bonus
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={(event, values) => {
                                    if (selectedFile) {
                                        new Compressor(selectedFile, {
                                            quality: 0.5,
                                            async success(result) {
                                                setOnUpload(true)
                                                let data = await CloudinaryHelper.upload(result, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=i4kdrkog`, (progressEvent) => {
                                                    let val = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                                    setProgress(val)
                                                })
                                                setOnUpload(false)
                                                updateOneBonus({
                                                    variables: {
                                                        input: {
                                                            update: {
                                                                name: values.bonusName,
                                                                minDepositAmount: parseFloat(values.minDeposit.toString().replaceAll(',', '')),
                                                                rolloverMultiplier: parseFloat(values.rolloverMultiplier.toString().replaceAll(',', '')),
                                                                maxCappedAmount: parseFloat(values.maxCappedAmount.toString().replaceAll(',', '')),
                                                                amount: parseFloat(values.amount.toString().replaceAll(',', '')),
                                                                bannerUrl: data.data.secure_url,
                                                                bonusType: values.applicabilityType,
                                                                bonusComputation: values.bonusComputation,
                                                                isActive: values.status === 'true' ? true : false
                                                            },
                                                            id: bonus.node.id
                                                        }
                                                    }
                                                }).catch(e => {
                                                    console.log(JSON.stringify(e))
                                                    ToastHelper.toggleToast({
                                                        toastType: "error",
                                                        message: e,
                                                        title: "",
                                                        onClick: function () { }
                                                    })
                                                }).then(val => {
                                                    if (val) {
                                                        ToastHelper.toggleToast({
                                                            message: "Update Succesfully!",
                                                            title: "",
                                                            onClick: function () { }
                                                        })
                                                        toggleModal()
                                                    }
                                                })
                                            },
                                            error(err) {
                                                ToastHelper.toggleToast({
                                                    toastType: "error",
                                                    message: err,
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            },
                                        });
                                    } else if (existingFile) {
                                        updateOneBonus({
                                            variables: {
                                                input: {
                                                    update: {
                                                        name: values.bonusName,
                                                        minDepositAmount: parseFloat(values.minDeposit.toString().replaceAll(',', '')),
                                                        rolloverMultiplier: parseFloat(values.rolloverMultiplier.toString().replaceAll(',', '')),
                                                        maxCappedAmount: parseFloat(values.maxCappedAmount.toString().replaceAll(',', '')),
                                                        amount: parseFloat(values.amount.toString().replaceAll(',', '')),
                                                        bonusType: values.applicabilityType,
                                                        bonusComputation: values.bonusComputation,
                                                        isActive: values.status === 'true' ? true : false
                                                    },
                                                    id: bonus.node.id
                                                }
                                            }
                                        }).catch(e => {
                                            ToastHelper.toggleToast({
                                                toastType: "error",
                                                message: e,
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }).then(val => {
                                            if (val) {
                                                ToastHelper.toggleToast({
                                                    message: "Update Succesfully!",
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                                toggleModal()
                                            }
                                        })
                                    } else {
                                        setFileError(true)
                                    }
                                }}
                                onInvalidSubmit={() => {

                                }}
                            >
                                <Row>
                                    <Col lg={12}>
                                        <DropZone
                                            selectedFile={selectedFile}
                                            onFileDrop={(file) => {
                                                setSelectFile(file)
                                                setExistingFile(null)
                                            }}
                                            currentImage={existingFile}
                                        />
                                    </Col>
                                    {
                                        fileError &&
                                        !selectedFile &&
                                        <Col lg="12"><div className="image-url-error pb-2 font-size-10">* Please Provide an Image</div></Col>
                                    }
                                    {
                                        onUpload &&
                                        <Col lg={12}>
                                            <FormGroup className="mb-3">
                                                <Label>Uploading</Label>
                                                <Progress color="primary" value={progress}></Progress>
                                            </FormGroup>
                                        </Col>
                                    }
                                    {
                                        bonus &&
                                        <>
                                            <Col lg={4}>
                                                <AvField
                                                    value={bonus.node.name}
                                                    label="Bonus Name"
                                                    name="bonusName"
                                                    type="text"
                                                    placeholder="Enter Bonus Name"
                                                    errorMessage="Enter bonus name"
                                                    validate={{
                                                        required: { value: true },
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <AvField
                                                    value={bonus.node.bonusType}
                                                    label={t('Applicability Type')}
                                                    type="select"
                                                    name="applicabilityType"
                                                    className="custom-select"
                                                    defaultValue={bonusTypeData && bonusTypeData.__type.enumValues[0].name}
                                                >
                                                    {
                                                        bonusTypeData &&
                                                        bonusTypeData.__type.enumValues.map(({ name }, index) => {
                                                            return (
                                                                <option key={index} value={name}>{name}</option>
                                                            )
                                                        })
                                                    }
                                                </AvField>
                                            </Col>
                                            <Col lg={4}>
                                                <AvField
                                                    value={bonus.node.minDepositAmount}
                                                    label={`${t('Minimum Deposit Amount')}`}
                                                    name="minDeposit"
                                                    type="text"
                                                    placeholder={t('Minimum Deposit Amount')}
                                                    errorMessage={t('Enter Minimum Deposit Amount')}
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                    validate={{
                                                        required: { value: true },
                                                        pattern: {
                                                            value: "^[0-9,.]+$",
                                                            errorMessage: `${t('Enter Only Numbers')}`
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <AvField
                                                    value={bonus.node.bonusComputation}
                                                    type="select"
                                                    name="bonusComputation"
                                                    className="custom-select"
                                                    label={t('Bonus Computation')}
                                                    defaultValue={bonusComputationData && bonusComputationData.__type.enumValues[0].name}
                                                >
                                                    {
                                                        bonusComputationData &&
                                                        bonusComputationData.__type.enumValues.map(({ name }, index) => {
                                                            return (
                                                                <option key={index} value={name}>{name}</option>
                                                            )
                                                        })
                                                    }
                                                </AvField>
                                            </Col>
                                            <Col lg={4}>
                                                <AvField
                                                    value={bonus.node.amount}
                                                    label={`${t('Amount')}`}
                                                    name="amount"
                                                    type="text"
                                                    placeholder={t('Amount')}
                                                    errorMessage={t('Enter Amount')}
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                    validate={{
                                                        required: { value: true },
                                                        pattern: {
                                                            value: "^[0-9,.]+$",
                                                            errorMessage: `${t('Enter Only Numbers')}`
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <AvField
                                                    value={bonus.node.rolloverMultiplier}
                                                    label={`${t('Rollover Multiplier')}`}
                                                    name="rolloverMultiplier"
                                                    type="text"
                                                    placeholder={t('Rollover Requirements')}
                                                    errorMessage={t('Enter Rollover Requirements')}
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                    validate={{
                                                        required: true,
                                                        pattern: {
                                                            value: "^[0-9,.]+$",
                                                            errorMessage: `${t('Enter Only Numbers')}`
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <AvField
                                                    value={bonus.node.maxCappedAmount}
                                                    label={`${t('Max Capped Amount')}`}
                                                    name="maxCappedAmount"
                                                    type="text"
                                                    placeholder={t('Max Capped Amount')}
                                                    errorMessage={t('Enter Max Capped Amount')}
                                                    thousandSeparator
                                                    decimalScale={2}
                                                    fixedDecimalScale
                                                    tag={[Input, NumberFormat]}
                                                    validate={{
                                                        required: { value: true },
                                                        pattern: {
                                                            value: "^[0-9,.]+$",
                                                            errorMessage: `${t('Enter Only Numbers')}`
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={4}>
                                                <AvField
                                                    label={`${t('Status')}`}
                                                    type="select"
                                                    name="status"
                                                    className="custom-select"
                                                    id="status-select"
                                                    defaultValue={bonus.node.isActive ? 'true' : 'false'}
                                                >
                                                    <option value={'true'}>Active</option>
                                                    <option value={'false'}>Not Active</option>
                                                </AvField>
                                            </Col>
                                        </>
                                    }
                                </Row>
                                {
                                    !onUpload &&
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(EditBonus));
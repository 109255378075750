import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const CREATE_ONE_FREE_CREDIT = gql`
    mutation(
        $input: CreateOneFreeCreditInput!
    ) {
        createOneFreeCredit (input: $input) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import { ApolloClient } from 'apollo-client';
import { HttpLink, InMemoryCache, split } from 'apollo-boost';
import { WebSocketLink } from "apollo-link-ws";
import { ApolloLink } from 'apollo-link';
import { onError } from "apollo-link-error";
import { getMainDefinition } from 'apollo-utilities';
import { setContext } from "apollo-link-context";

const ApolloHelper = {
    makeApolloClient() {
        const authLink = setContext((_, { headers, ...context }) => {
            const token = window.localStorage.getItem("AUTH_TOKEN");
            return {
                headers: {
                    ...headers,
                    ...(token ? { authorization: `Bearer ${token}` } : {}),
                },
                ...context,
            };
        });

        const httpLink = new HttpLink({
            uri: `http://${process.env.REACT_APP_API_URL}:443/graphql`
        });

        const wsLink: any = new WebSocketLink({
            uri: `ws://${process.env.REACT_APP_API_URL}:443/graphql`,
            options: {
                lazy: true,
                reconnect: true,
                connectionParams: () => (
                    {
                        authorization: `Bearer ${window.localStorage.getItem("AUTH_TOKEN")}`
                    }
                )
            }
        });

        wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
            wsLink.subscriptionClient.maxConnectTimeGenerator.max;

        const errorLink = onError(({ graphQLErrors, networkError }) => {
            if (graphQLErrors)
                graphQLErrors.map(({ extensions, message }: any) => {
                    if (extensions.exception && extensions.exception.response && extensions.exception.response.statusCode === 401) {
                        if (window.location.pathname !== '/login') {
                            window.localStorage.setItem("AUTH_TOKEN", '');
                            // client.cache.reset()
                            window.location.pathname = '/login'
                        }
                    }
                });

            if (networkError) console.log(`[Network error]: ${networkError}`);
        });

        const apiLink = split(
            // split based on operation type
            ({ query }) => {
                const definition = getMainDefinition(query);
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        );

        const link = ApolloLink.from([
            authLink,
            errorLink,
            apiLink,
        ]);

        const cache = new InMemoryCache()

        const client = new ApolloClient({
            link,
            cache,
            queryDeduplication: false,
            defaultOptions: {
                query: {
                    fetchPolicy: "no-cache",
                    // errorPolicy: 'ignore'
                }
            }
        });
        return client;
    }
}

export default ApolloHelper;


// import { ApolloClient, ApolloLink, InMemoryCache, split, HttpLink } from '@apollo/client';
// import { WebSocketLink } from '@apollo/client/link/ws';
// import { onError } from '@apollo/client/link/error';
// import { getMainDefinition } from '@apollo/client/utilities';

// const ApolloHelper = {
//     makeApolloClient() {
//         const authLink = new ApolloLink((operation, forward) => {
//             const token = window.localStorage.getItem("AUTH_TOKEN");
//             // add the authorization to the headers
//             operation.setContext(({ headers, ...context }: any) => {
//                 return {
//                     headers: {
//                         ...headers,
//                         authorization: `Bearer ${token}`
//                     },
//                     ...context,
//                 };
//             });

//             return forward(operation);
//         })

//         const httpLink = new HttpLink({
//             uri: `http://${process.env.REACT_APP_API_URL}:443/graphql`
//         });

//         const wsLink: any = new WebSocketLink({
//             uri: `ws://${process.env.REACT_APP_API_URL}:443/graphql`,
//             options: {
//                 lazy: true,
//                 reconnect: true,
//                 connectionParams: () => (
//                     {
//                         authorization: `Bearer ${window.localStorage.getItem("AUTH_TOKEN")}`
//                     }
//                 )
//             }
//         });

//         wsLink.subscriptionClient.maxConnectTimeGenerator.duration = () =>
//             wsLink.subscriptionClient.maxConnectTimeGenerator.max;

//         const errorLink = onError(({ graphQLErrors, networkError }) => {
//             if (graphQLErrors)
//                 graphQLErrors.map(({ extensions, message }: any) => {
//                     if (extensions.exception && extensions.exception.response && extensions.exception.response.statusCode === 401) {
//                         if (window.location.pathname !== '/login') {
//                             window.localStorage.setItem("AUTH_TOKEN", '');
//                             cache.reset()
//                             window.location.pathname = '/login'
//                         }
//                     }
//                 });

//             if (networkError) console.log(`[Network error]: ${networkError}`);
//         });

//         const apiLink = split(
//             // split based on operation type
//             ({ query }) => {
//                 const definition = getMainDefinition(query);
//                 return (
//                     definition.kind === 'OperationDefinition' &&
//                     definition.operation === 'subscription'
//                 );
//             },
//             wsLink,
//             httpLink,
//         );

//         const link = ApolloLink.from([
//             authLink,
//             errorLink,
//             apiLink,
//         ]);

//         const cache = new InMemoryCache()

//         const client = new ApolloClient({
//             link,
//             cache,
//             queryDeduplication: false,
//             defaultOptions: {
//                 query: {
//                     fetchPolicy: "network-only",
//                     // errorPolicy: 'ignore'
//                 }
//             }
//         });
//         return client;
//     }
// }

// export default ApolloHelper;
import React, { useRef } from 'react';
import Switch from "react-switch";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";

//Import Breadcrumb
import { useMutation, useQuery } from '@apollo/react-hooks';
import Breadcrumbs from '../../components/Common/Breadcrumb';

import _ from 'lodash';

import { withTranslation } from 'react-i18next';
import 'react-medium-image-zoom/dist/styles.css';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import SettingEditor from '../../components/CommonForBoth/SettingEditor';
import { UPDATE_ONE_FREE_CREDIT_TYPE } from '../../graphql/mutation/UPDATE_ONE_FREE_CREDIT_TYPE';
import { GET_FREE_CREDIT } from '../../graphql/query/GET_FREE_CREDIT';
import ToastHelper from '../../helpers/ToastHelper';
import { toggleRightSidebar } from "../../store/actions";
import NewFreeCredit from './new-free-credit';

function FreeCreditList(props) {

    const [addFreeCredit, setAddFreeCredit] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)

    const history = useHistory();

    const [updateOneFreeCreditType] = useMutation(UPDATE_ONE_FREE_CREDIT_TYPE)


    const { data: freeCreditData, refetch: refetchFreeCredit } = useQuery(GET_FREE_CREDIT, {
        variables: {
            filter: { name: { iLike: `%${search}%` } },
            sorting: [{ field: "isActive", direction: "DESC" }, { field: "isPublished", direction: "DESC" }, { field: "name", direction: "ASC" }]
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content bonus-page">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Promotion Type" breadcrumbItem="Promotion Type" />
                    <div className="search-bar-container">
                        <div className="button-add-bonus-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="button-add-bonus-container">
                            {/* <div className="filter-container button-filter-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div> */}
                            <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus" onClick={() => { setAddFreeCredit(true) }}>
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Promotion
                            </button>
                        </div>
                    </div>
                    {
                        <Card className="w-100">
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Promotion Type Name</th>
                                                <th>Published?</th>
                                                <th>Active?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                freeCreditData &&
                                                freeCreditData.freeCreditTypes.edges.length > 0 &&
                                                freeCreditData.freeCreditTypes.edges.map((freeCredit, key) =>
                                                    <tr key={"_free_credit" + key}>
                                                        {/* <td>
                                                            <Zoom>
                                                                <img
                                                                    alt=""
                                                                    src={freeCredit.node.imageUrl}
                                                                    // width="40"
                                                                    height="50"
                                                                />
                                                            </Zoom>
                                                        </td> */}
                                                        <td>
                                                            <div className="cursor-pointer " onClick={() => {
                                                                history.push(`/promotion/${freeCredit.node.id}`);
                                                            }}>{freeCredit.node.name}</div>
                                                        </td>
                                                        {/* <td>
                                                            <div className="cursor-pointer" onClick={() => {
                                                                onEdit(freeCredit)
                                                            }}>{freeCredit.node.redirectUrl}</div>
                                                        </td>
                                                        <td>
                                                            <div className="cursor-pointer" style={{ whiteSpace: 'pre-wrap' }} onClick={() => {
                                                                onEdit(freeCredit)
                                                            }}>{freeCredit.node.description}</div>
                                                        </td> */}
                                                        <td>
                                                            <Switch
                                                                id="is-published"
                                                                onColor="#34c38f"
                                                                offColor="#f46a6a"
                                                                uncheckedIcon={<Offsymbol text="NO" />}
                                                                checkedIcon={<OnSymbol text="YES" />}
                                                                onChange={() => {
                                                                    updateOneFreeCreditType({
                                                                        variables: {
                                                                            input: {
                                                                                update: {
                                                                                    isPublished: !freeCredit.node.isPublished
                                                                                },
                                                                                id: freeCredit.node.id
                                                                            }
                                                                        }
                                                                    }).catch(e => {
                                                                        ToastHelper.toggleToast({
                                                                            toastType: "error",
                                                                            message: e,
                                                                            title: "",
                                                                            onClick: function () { }
                                                                        })
                                                                    }).then(val => {
                                                                        if (val) {
                                                                            ToastHelper.toggleToast({
                                                                                message: "Update Succesfully!",
                                                                                title: "",
                                                                                onClick: function () { }
                                                                            })
                                                                        }
                                                                    })
                                                                }}
                                                                checked={freeCredit.node.isPublished}
                                                            />
                                                        </td>
                                                        <td>
                                                            <Switch
                                                                id="is-active"
                                                                onColor="#34c38f"
                                                                offColor="#f46a6a"
                                                                uncheckedIcon={<Offsymbol text="NO" />}
                                                                checkedIcon={<OnSymbol text="YES" />}
                                                                onChange={() => {
                                                                    updateOneFreeCreditType({
                                                                        variables: {
                                                                            input: {
                                                                                update: {
                                                                                    isActive: !freeCredit.node.isActive
                                                                                },
                                                                                id: freeCredit.node.id
                                                                            }
                                                                        }
                                                                    }).catch(e => {
                                                                        ToastHelper.toggleToast({
                                                                            toastType: "error",
                                                                            message: e,
                                                                            title: "",
                                                                            onClick: function () { }
                                                                        })
                                                                    }).then(val => {
                                                                        if (val) {
                                                                            ToastHelper.toggleToast({
                                                                                message: "Update Succesfully!",
                                                                                title: "",
                                                                                onClick: function () { }
                                                                            })
                                                                        }
                                                                    })
                                                                }}
                                                                checked={freeCredit.node.isActive}
                                                            />
                                                        </td>
                                                    </tr >
                                                )
                                            }
                                            {
                                                freeCreditData &&
                                                freeCreditData.freeCreditTypes.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-user pt-0">No Promotion Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                    }
                    <Row>
                        <Col md={6} lg={6} xl={4} sm={6}>
                            <Card>
                                <CardBody>
                                    <SettingEditor description="Enable Bot Promotion?(Global)" settingKey="enableBotPromotion" settingType="General" fieldType="Boolean" inputValue={'true'} defaultValue={'true'} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Card>
                        <CardBody>
                            <SettingEditor description="Bot Promotion Main Menu" settingKey="botPromotionMainMenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify([{ "language": "EN", "content": "{botName} Exclusive PromotionnnPlease select the promotion you are interested in :", "imageUrl": "https://media.istockphoto.com/vectors/cute-white-robot-character-vector-vector-id1187576166?k=20&m=1187576166&s=612x612&w=0&h=q-REVReHr8QRzKQ_TRWGU7KTP6OBIgGh-zlg91-S-j0=" }, { "language": "MS", "content": "Promosi eksklusif {botName}nnSila pilih promosi yang anda minat :", "imageUrl": "https://media.istockphoto.com/vectors/cute-white-robot-character-vector-vector-id1187576166?k=20&m=1187576166&s=612x612&w=0&h=q-REVReHr8QRzKQ_TRWGU7KTP6OBIgGh-zlg91-S-j0=" }, { "language": "ZH", "content": "{botName} 独家促销 nn请选择以下优惠以了解更多 :", "imageUrl": "https://media.istockphoto.com/vectors/cute-white-robot-character-vector-vector-id1187576166?k=20&m=1187576166&s=612x612&w=0&h=q-REVReHr8QRzKQ_TRWGU7KTP6OBIgGh-zlg91-S-j0=" }])} defaultValue={JSON.stringify([{ "language": "EN", "content": "{botName} Exclusive PromotionnnPlease select the promotion you are interested in :", "imageUrl": "https://media.istockphoto.com/vectors/cute-white-robot-character-vector-vector-id1187576166?k=20&m=1187576166&s=612x612&w=0&h=q-REVReHr8QRzKQ_TRWGU7KTP6OBIgGh-zlg91-S-j0=" }, { "language": "MS", "content": "Promosi eksklusif {botName}nnSila pilih promosi yang anda minat :", "imageUrl": "https://media.istockphoto.com/vectors/cute-white-robot-character-vector-vector-id1187576166?k=20&m=1187576166&s=612x612&w=0&h=q-REVReHr8QRzKQ_TRWGU7KTP6OBIgGh-zlg91-S-j0=" }, { "language": "ZH", "content": "{botName} 独家促销 nn请选择以下优惠以了解更多 :", "imageUrl": "https://media.istockphoto.com/vectors/cute-white-robot-character-vector-vector-id1187576166?k=20&m=1187576166&s=612x612&w=0&h=q-REVReHr8QRzKQ_TRWGU7KTP6OBIgGh-zlg91-S-j0=" }])} />
                        </CardBody>
                    </Card>
                    {
                        addFreeCredit &&
                        <NewFreeCredit
                            modal={addFreeCredit}
                            toggleModal={() => {
                                setAddFreeCredit(!addFreeCredit)
                                refetchFreeCredit()
                            }}
                        />
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(FreeCreditList));

// export default ContactsList;
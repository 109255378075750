import gql from 'graphql-tag';
import { TierFragment } from '../fragments/tier.fragment';

export const CREATE_ONE_REWARD_TIER = gql`
    mutation(
        $input: CreateOneRewardTierInput!
    ) {
        createOneRewardTier (input: $input) {
           ...TierInfo
        }
    }
    ${TierFragment}
`;
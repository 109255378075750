import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { TierFragment } from '../fragments/tier.fragment';

export const GET_TIERS = gql`
query(
    $filter: RewardTierFilter
    $paging: CursorPaging
    $sorting: [RewardTierSort!]
){
    rewardTiers(
        paging: $paging
        filter: $filter
        sorting: $sorting
    ){
        pageInfo {
            ...Page
        }
        edges {
            node {
                ...TierInfo
                
            }
        }
    }
}
${PageFragment}
${TierFragment}
`;
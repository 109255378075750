import gql from 'graphql-tag';
import { BankAccountFragment } from '../fragments/bank.fragment';

export const CREATED_BANK_ACCOUNT = gql`
    subscription {
        createdBankAccount {
            ...BankAccountInfo
        }
    }
    ${BankAccountFragment}
`;
import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";
import PlatformList from "./platform/platform-list/reducer";
import User from "./user/reducer";
import Bank from "./bank/banks/reducer";
import BankAccounts from "./bank/accounts/reducer";
import Products from "./product/reducer";
import Platform from "./platform/reducer";
import Kiosk from "./kiosk/reducer";
import Game from "./game/reducer";
import Transactions from "./transactions/reducer";
import Bonus from "./bonus/reducer";
import Banner from "./banner/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

import Player from './player/reducer'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  User,
  Bank,
  BankAccounts,
  Products,
  Platform,
  Kiosk,
  PlatformList,
  Game,
  Player,
  Transactions,
  Bonus,
  Banner
});

export default rootReducer;

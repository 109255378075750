import React, { useRef } from 'react';
import { Container, Card, CardBody, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import _ from 'lodash';
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import classnames from 'classnames';
import 'react-medium-image-zoom/dist/styles.css'
import { GET_COUNTRY } from '../../graphql/query/GET_COUNTRY';
import { UPDATED_COUNTRY } from '../../graphql/subscription/UPDATED_COUNTRY';
import CountryItem from './country-item';
import { Link } from "react-router-dom";
import NewCountry from './new-country';
import EditCountry from './edit-country';

function CountryList(props) {

    const [addCountry, setAddCountry] = React.useState(false)
    const [editCountry, setEditCountry] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [countryItem, setCcountryItem] = React.useState(null)
    const [filter, setFilter] = React.useState({})

    const onEdit = (country) => {
        setCcountryItem(country)
        setEditCountry(true)
    }

    const { data: countryData, refetch, loading } = useQuery(GET_COUNTRY, {
        variables: {
            filter: {
                ...filter
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    useSubscription(UPDATED_COUNTRY)

    React.useEffect(() => {
        UpdateFilter()
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    const UpdateFilter = () => {
        let filter = { name: { iLike: `%${search}%` } }

        if (search === '') {
            delete filter["name"]
        }

        setFilter(filter)
    }

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content currency-page">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Developer Settings" breadcrumbItem="Country" />
                    <div className="search-bar-container">
                        <div className="button-add-bonus-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="button-add-currency-container">
                            <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus ml-2" onClick={() => { setAddCountry(true) }}>
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Country
                            </button>
                        </div>
                    </div>
                    {
                        <Card className="w-100">
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '20%' }}>Name</th>
                                                <th style={{ width: '10%' }}>Abbreviation</th>
                                                <th style={{ width: '10%' }}>Phone Prefix</th>
                                                <th style={{ width: '10%' }}>SMS Prefix</th>
                                                <th style={{ width: '10%' }}>Phone Sample</th>
                                                <th style={{ width: '10%' }}>Default Language</th>
                                                <th style={{ width: '10%' }}>Currency</th>
                                                <th style={{ width: '10%' }}>Default?</th>
                                                <th style={{ width: '10%' }}>Active?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                countryData &&
                                                countryData.countries.edges.length > 0 &&
                                                countryData.countries.edges.map((country, key) =>
                                                    <tr key={"country" + key} className={
                                                        classnames({
                                                            'grey': !country.node.isDefault
                                                        })
                                                    }>
                                                        <CountryItem
                                                            onEdit={onEdit}
                                                            country={country}
                                                        />
                                                    </tr >
                                                )
                                            }
                                            {
                                                countryData &&
                                                countryData.countries.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-currency pt-0">No Country Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                    {
                                        loading &&
                                        <div className="text-center my-3">
                                            <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                        </div>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    }
                    {
                        addCountry &&
                        <NewCountry
                            modal={addCountry}
                            toggleModal={() => {
                                refetch()
                                setAddCountry(!addCountry)
                            }}
                        />
                    }
                    {
                        editCountry &&
                        <EditCountry
                            modal={editCountry}
                            toggleModal={() => {
                                setEditCountry(!editCountry)
                            }}
                            country={countryItem}
                        />
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(CountryList));
import React, { useRef } from 'react';
import { Container, Row, Card, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

import _ from 'lodash';
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery } from 'react-apollo';
import { GET_PAYMENT_TYPES } from '../../graphql/query/GET_PAYMENT_TYPES';
import { Link } from "react-router-dom";
import PaymentTypeItem from "./payment-type-item";
import NewPaymentType from './new-payment-type';


function PaymentTypeList(props) {

    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [newPayment, setNewPayment] = React.useState(false)

    const { data, loading, refetch } = useQuery(GET_PAYMENT_TYPES, {
        fetchPolicy: 'network-only'
    })

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content payment-type">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Settings" breadcrumbItem="Payment Types" />
                    <div className="search-bar-container">
                        <div className="button-add-user-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>


                        <div className="button-add-payment-container">
                            <button type="button" className="waves-effect btn btn-outline-secondary button-payment-user" onClick={() => setNewPayment(true)} >
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Payment Type
                            </button>
                        </div>
                    </div>
                    <Row>
                        {
                            <Card className="w-100">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr className="font-size-12">
                                                <th>Code</th>
                                                <th>Name</th>
                                                <th>Active?</th>
                                            </tr>
                                        </thead>
                                        <tbody className="table-body">
                                            {
                                                data &&
                                                data.paymentTypes.edges.length > 0 &&
                                                data.paymentTypes.edges.map((paymentType, key) =>
                                                    <PaymentTypeItem key={key} paymentType={paymentType} />
                                                )
                                            }
                                            {
                                                data &&
                                                data.paymentTypes.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-user pt-0">No Payment Type Found</div>
                                                    </td>
                                                </tr>
                                            }
                                            {
                                                loading &&
                                                <tr className="text-center pt-3">
                                                    <td colSpan={12}>
                                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Loading</Link>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Card>
                        }
                        <NewPaymentType
                            modal={newPayment}
                            toggleModal={() => {
                                setNewPayment(!newPayment)
                                refetch()
                            }}
                        />
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Player };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(PaymentTypeList));

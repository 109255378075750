import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';


export const SYNC_KIOSK_ACCOUNT_BALANCE = gql`
    mutation(
        $input: String!
    ) {

        kioskAccountSyncBalance(id:$input){
            ...KioskInfo
        }
        
    }
    ${KioskFragment}
`;
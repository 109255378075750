import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';

export const GET_BANK_ACCOUNT_ENTRIES = gql`
    query (
        $paging: CursorPaging
        $filter: BankAccountEntryFilter
        $sorting: [BankAccountEntrySort!]
    ) {
        bankAccountEntries (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    id
                    transaction {
                        serialNo
                    }
                    deposit
                    withdrawal
                    description
                    bankAccountEntryType
                    bankAccountId
                    transactionId
                    isActive
                    isArchived
                    createdAt
                    updatedAt
                }
            }
        }
    }
    ${PageFragment}
`;
import gql from 'graphql-tag';

export const WalletFragment = gql`
    fragment WalletInfo on Wallet {
        id
        name
        balance
        walletType
        isActive
        isArchived
        createdAt
        updatedAt
        expiredAt
    }
`;
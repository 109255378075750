import gql from 'graphql-tag';

export const REMOVE_FROM_AVAILABLE_LIST = gql`
    mutation (
        $input: MarketingCampaignParticipantListInput!
        $marketingCampaignId: String!
    ) {
        removeFromAvailableList (
            input: $input
            marketingCampaignId: $marketingCampaignId
        )
    }
`;
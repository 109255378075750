import gql from "graphql-tag";

export const EXPORT_PLAYER_LIST = gql`
  query {
    exportPlayerList {
      serialNo
      username
      name
      phoneNo
      telegramId
      telegramUsername
      joinedAt
      lastDepositAt
      lastDepositAmount
      isActive
      tags {
        name
      }
      bankAccounts {
        accountName
      }
    }
  }
`;

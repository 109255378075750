import classnames from "classnames";
import React from "react";
import { useLazyQuery, useQuery } from "react-apollo";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardText,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { OperatingUsers, Operator } from "../../../assets/scss/role.constant";
import { GET_BANK_ACCOUNTS, ME } from "../../../graphql/query";
import { GET_GAME_ACCOUNTS } from "../../../graphql/query/GET_GAME_ACCOUNTS";
import EditPlayerBankAccount from "./edit-bank-account";
import NewPlayerBankAccount from "./new-bank-account";
import PlayerBankAccounts from "./player-bank-accounts";
import PlayerGameAccounts from "./player-game-accounts";
import PlayerNewGameAccount from "./player-new-game-account";
import RecentTransaction from "./player-recent-transactions";
import PlayerStats from "./player-stats";
import PlayerDownlines from "./player-downlines";

function PlayerInfo(props) {
  const {
    t,
    player,
    playerKey,
    isActive,
    toTab,
    onApproveTransaction,
    actToggleBank,
  } = props;
  const [activeTab, setActiveTab] = React.useState("1");
  const [addBankAccount, setAddBankAccount] = React.useState(false);
  const [editAccount, setEditAccount] = React.useState(null);
  const [addGameAccount, setAddGameAccount] = React.useState(false);

  const [refetchBankAccount] = useLazyQuery(GET_BANK_ACCOUNTS, {
    variables: {
      paging: {
        first: 50,
      },
      filter: {
        playerId: { eq: player.id },
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const { data: meData } = useQuery(ME, {
    fetchPolicy: "cache-first",
  });

  const [refetchGameAccount] = useLazyQuery(GET_GAME_ACCOUNTS, {
    variables: {
      sorting: [
        {
          field: "isActive",
          direction: "DESC",
        },
        {
          field: "createdAt",
          direction: "DESC",
        },
      ],
      filter: {
        playerId: { eq: player.id },
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    if (isActive) {
      setActiveTab(toTab ? toTab : "1");
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (toTab) {
      setActiveTab(toTab);
    }
  }, [toTab]);

  return (
    <React.Fragment>
      <div className="player-container">
        <div className="header-wrapper">
          <div className="player-title">Player Infomation</div>
          {activeTab === "1" && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              onClick={() => {
                setAddGameAccount(true);
              }}
            >
              <i className="bx bx-plus font-size-16 align-middle mr-2"></i>New
              Game Account
            </Button>
          )}
          {activeTab === "5" && (
            <Button
              color="primary"
              outline
              className="waves-effect waves-light"
              onClick={() => {
                setAddBankAccount(true);
              }}
            >
              <i className="bx bx-plus font-size-16 align-middle mr-2"></i>New
              Bank Account
            </Button>
          )}
        </div>
        <div className="player-wrapper">
          <div className="player-content-info">
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        setActiveTab("1");
                      }}
                    >
                      {t("Game Accounts")}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "2"
                                            })}
                                            onClick={() => {
                                                setActiveTab("2");
                                            }}
                                        >{t('Wallet Lists')}</NavLink>
                                    </NavItem> */}
                  {meData && !OperatingUsers.includes(meData.me.role) && (
                    <NavItem>
                      <NavLink
                        style={{ cursor: "pointer" }}
                        className={classnames({
                          active: activeTab === "5",
                        })}
                        onClick={() => {
                          setActiveTab("5");
                        }}
                      >
                        {t("Bank Accounts")}
                      </NavLink>
                    </NavItem>
                  )}
                  {/* <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "3"
                                            })}
                                            onClick={() => {
                                                setActiveTab("3");
                                            }}
                                        >{t('Game Sessions')}</NavLink>
                                    </NavItem> */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4",
                      })}
                      onClick={() => {
                        setActiveTab("4");
                      }}
                    >
                      {t("Deposit")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4.1",
                      })}
                      onClick={() => {
                        setActiveTab("4.1");
                      }}
                    >
                      {t("Withdrawal")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4.2",
                      })}
                      onClick={() => {
                        setActiveTab("4.2");
                      }}
                    >
                      {t("Promotion")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4.3",
                      })}
                      onClick={() => {
                        setActiveTab("4.3");
                      }}
                    >
                      {t("Rebate")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4.4",
                      })}
                      onClick={() => {
                        setActiveTab("4.4");
                      }}
                    >
                      {t("Credit In")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4.5",
                      })}
                      onClick={() => {
                        setActiveTab("4.5");
                      }}
                    >
                      {t("Credit Out")}
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "6"
                                            })}
                                            onClick={() => {
                                                setActiveTab("6");
                                            }}
                                        >{t('Rebates')}</NavLink>
                                    </NavItem> */}
                  {meData &&
                    !OperatingUsers.includes(meData.me.role) &&
                    !Operator.includes(meData.me.role) && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "7",
                          })}
                          onClick={() => {
                            setActiveTab("7");
                          }}
                        >
                          {t("Player Stats")}
                        </NavLink>
                      </NavItem>
                    )}
                  {meData &&
                    !OperatingUsers.includes(meData.me.role) &&
                    !Operator.includes(meData.me.role) && (
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "8",
                          })}
                          onClick={() => {
                            setActiveTab("8");
                          }}
                        >
                          {t("Player Downlines")}
                        </NavLink>
                      </NavItem>
                    )}
                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" className="pt-3 pb-3">
                    {player && (
                      <PlayerGameAccounts
                        isActive={activeTab === "1"}
                        player={player}
                      />
                    )}
                  </TabPane>
                  {/* <TabPane tabId="2" className="pt-3 pb-3">
                                        {
                                            player &&
                                            <PlayerWalletLists
                                                isActive={activeTab === '2'}
                                                player={player}
                                            />
                                        }
                                    </TabPane> */}
                  <TabPane tabId="3" className="pt-3 pb-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          Food truck fixie locavore, accusamus mcsweeney's marfa
                          nulla single-origin coffee squid. Exercitation +1
                          labore velit, blog sartorial PBR leggings next level
                          wes anderson artisan four loko farm-to-table craft
                          beer twee. Qui photo booth letterpress, commodo enim
                          craft beer mlkshk aliquip jean shorts ullamco ad vinyl
                          cillum PBR. Homo nostrud organic, assumenda labore
                          aesthetic magna delectus mollit. Keytar helvetica VHS
                          salvia yr, vero magna velit sapiente labore stumptown.
                          Vegan fanny pack odio cillum wes anderson 8-bit.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="4" className="pt-3 pb-3">
                    {player && (
                      <RecentTransaction
                        isActive={activeTab === "4"}
                        player={player}
                        transactionType={"Deposit"}
                        onApprove={onApproveTransaction}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="4.1" className="pt-3 pb-3">
                    {player && (
                      <RecentTransaction
                        isActive={activeTab === "4.1"}
                        player={player}
                        transactionType={"Withdrawal"}
                        onApprove={onApproveTransaction}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="4.2" className="pt-3 pb-3">
                    {player && (
                      <RecentTransaction
                        isActive={activeTab === "4.2"}
                        player={player}
                        transactionType={["Bonus", "FreeCredit"]}
                        onApprove={onApproveTransaction}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="4.3" className="pt-3 pb-3">
                    {player && (
                      <RecentTransaction
                        isActive={activeTab === "4.3"}
                        player={player}
                        transactionType={"Rebate"}
                        onApprove={onApproveTransaction}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="4.4" className="pt-3 pb-3">
                    {player && (
                      <RecentTransaction
                        isActive={activeTab === "4.4"}
                        player={player}
                        transactionType={"CreditIn"}
                        onApprove={onApproveTransaction}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="4.5" className="pt-3 pb-3">
                    {player && (
                      <RecentTransaction
                        isActive={activeTab === "4.5"}
                        player={player}
                        transactionType={"CreditOut"}
                        onApprove={onApproveTransaction}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="5" className="pt-3 pb-3">
                    {player && (
                      <PlayerBankAccounts
                        meData={meData}
                        isActive={activeTab === "5"}
                        player={player}
                        transactionType={"credit"}
                        onEdit={(account) => {
                          setEditAccount(account);
                        }}
                        actToggleBank={(account) => {
                          actToggleBank(account);
                        }}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="6" className="pt-3 pb-3">
                    <Row>
                      <Col sm="12">
                        <CardText>
                          Trust fund seitan letterpress, keytar raw denim
                          keffiyeh etsy art party before they sold out master
                          cleanse gluten-free squid scenester freegan cosby
                          sweater. Fanny pack portland seitan DIY, art party
                          locavore wolf cliche high life echo park Austin. Cred
                          vinyl keffiyeh DIY salvia PBR, banh mi before they
                          sold out farm-to-table VHS viral locavore cosby
                          sweater. Lomo wolf viral, mustache readymade
                          thundercats keffiyeh craft beer marfa ethical. Wolf
                          salvia freegan, sartorial keffiyeh echo park vegan.
                        </CardText>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="7" className="pt-3 pb-3">
                    {player && (
                      <PlayerStats
                        isActive={activeTab === "7"}
                        player={player}
                      />
                    )}
                  </TabPane>
                  <TabPane tabId="8" className="pt-3 pb-3">
                    {player && <PlayerDownlines player={player} />}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      {addBankAccount && (
        <NewPlayerBankAccount
          modal={addBankAccount}
          toggleModal={() => {
            refetchBankAccount();
            setAddBankAccount(!addBankAccount);
          }}
          player={player}
          playerKey={playerKey}
        />
      )}
      {editAccount !== null && (
        <EditPlayerBankAccount
          modal={editAccount !== null}
          toggleModal={() => {
            refetchBankAccount();
            setEditAccount(null);
          }}
          player={player}
          playerKey={playerKey}
          account={editAccount}
        />
      )}
      {addGameAccount && (
        <PlayerNewGameAccount
          t={t}
          modal={addGameAccount}
          toggleModal={() => {
            setAddGameAccount(!addGameAccount);
          }}
          refetchGameAccount={() => {
            setAddGameAccount(!addGameAccount);
            refetchGameAccount();
          }}
          player={player}
        />
      )}
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(PlayerInfo));

import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";

class Breadcrumbs extends Component {

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col xs="12">
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <div className="align-center">
                                {
                                    this.props.back &&
                                    <>
                                        <i className="bx bxs-chevron-left text-primary font-size-20 mr-2 cursor-pointer" onClick={() => window.history.back()}></i>
                                        <h4 className="mb-0 font-size-18 cursor-pointer" onClick={() => window.history.back()}>{this.props.breadcrumbItem}</h4>
                                    </>
                                }
                                {
                                    !this.props.back &&
                                    <h4 className="mb-0 font-size-18">{this.props.breadcrumbItem}</h4>
                                }
                            </div>
                            <div className="page-title-right">
                                <Breadcrumb listClassName="m-0">
                                    <BreadcrumbItem>
                                        <Link to="#">{this.props.title}</Link>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>
                                        <Link to="#">{this.props.breadcrumbItem}</Link>
                                    </BreadcrumbItem>
                                </Breadcrumb>
                            </div>
                        </div>
                    </Col>
                </Row>
            </React.Fragment >
        );
    }
}

export default Breadcrumbs;
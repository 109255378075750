import React from 'react';
import { Row, Col, Table, Card, CardBody, Button, Input } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery } from 'react-apollo';
import { SALE_SUMMARY_REPORT } from '../../graphql/query';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import classnames from "classnames";
import { AvForm, AvField } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";

function TransactionReport(props) {

    const { t } = props;
    const { type } = props.match.params
    let history = useHistory();

    const [periodType, setPeriodType] = React.useState(type)
    const [dateDisplay, setDateDisplay] = React.useState('YYYY-MM')
    const [fromDate, setFromDate] = React.useState(moment().startOf("year").toDate())
    const [toDate, setToDate] = React.useState(moment().endOf("year").toDate())
    const [tempFromDate, setTempFromDate] = React.useState(moment().startOf("year").toDate())
    const [tempToDate, setTempToDate] = React.useState(moment().endOf("year").toDate())
    const [orderBy, setOrderBy] = React.useState('period')
    const [orderDirection, setOrderDirection] = React.useState('ASC')
    const dateArr = [{ "name": "Today", "key": "day" }, { "name": "In 3 Days", "minus": 2 }, { "name": "In A Week", "minus": 6 }, { "name": "In A Month", "minus": 29 }, { "name": "Current Month", "key": "month" }, { "name": "Current Year", "key": "year" }]
    const [dateType, setDateType] = React.useState(dateArr[5].name)

    const { data } = useQuery(SALE_SUMMARY_REPORT, {
        variables: {
            periodType: periodType ?? "year",
            fromDate: moment(fromDate).startOf('day').format('YYYY-MM-DD'),
            toDate: moment(toDate).startOf('day').format('YYYY-MM-DD'),
            orderBy: orderBy,
            orderDirection: orderDirection
        },
        fetchPolicy: 'network-only'
    })

    React.useEffect(() => {
        setPeriodType(type)
        DateDisplayFormat(type)
    }, [props.match.params]) // eslint-disable-line react-hooks/exhaustive-deps

    const Formatter = (value) => {
        let val = parseFloat(value).toFixed(2)
        return <NumberFormat
            value={val}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
        />
    }

    const DateDisplayFormat = (value) => {
        switch (value) {
            case "day":
                setDateDisplay('YYYY-MM-DD')
                break;
            case "month":
                setDateDisplay('YYYY-MM')
                break;
            case "year":
                setDateDisplay('YYYY')
                break;
            default:
                break;
        }
    }

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
            } else {
                setOrderDirection('ASC')
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)
        }
    }

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }

    return (
        <React.Fragment>
            <div className="page-content report">
                <Card>
                    <CardBody>
                        <AvForm onValidSubmit={(event, values) => {
                            history.push(`/transaction-report/${values.period}`);
                        }}>
                            <Row>
                                <Col xs={12} sm={6} md={6} lg={6}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <AvField
                                                value={tempFromDate}
                                                selected={tempFromDate}
                                                label="From Date"
                                                name="fromDate"
                                                type="text"
                                                placeholderText="Enter From Date"
                                                errorMessage="Enter From Date"
                                                selectsStart
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                tag={[Input, DatePicker]}
                                                onChange={date => {
                                                    setTempFromDate(date)
                                                }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <AvField
                                                value={tempToDate}
                                                selected={tempToDate}
                                                label="To Date"
                                                name="toDate"
                                                type="text"
                                                placeholderText="Enter To Date"
                                                errorMessage="Enter To Date"
                                                selectsStart
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                tag={[Input, DatePicker]}
                                                minDate={tempFromDate}
                                                onChange={date => {
                                                    setTempToDate(date)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={3} md={3} lg={3}>
                                    <AvField
                                        value={periodType}
                                        label={t('Period Type')}
                                        type="select"
                                        name="period"
                                        className="custom-select"
                                    >
                                        <option value={'year'}>{t('Year')}</option>
                                        <option value={'month'}>{t('Month')}</option>
                                        <option value={'day'}>{t('Day')}</option>
                                    </AvField>
                                </Col>
                                <Col className="action-container" xs={12} sm={3} md={3} lg={3}>
                                    <div className="mb-3">
                                        <Button color="primary" type="submit">{t('Submit')}</Button>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <div className="button-container ml-3">
                                    {
                                        dateArr.map(({ name, minus, key }, index) =>
                                            <button
                                                className={
                                                    classnames({
                                                        "btn": true,
                                                        "mr-2": true,
                                                        "mb-2": true,
                                                        "btn-outline-primary": name !== dateType,
                                                        "btn-primary": name === dateType
                                                    })
                                                }
                                                key={index}
                                                onClick={() => {
                                                    if (minus) {
                                                        setTempFromDate(moment().subtract(minus, "days").toDate())
                                                        setTempToDate(moment().toDate())
                                                        setDateType(name)
                                                        setFromDate(moment().subtract(minus, "days").toDate())
                                                        setToDate(moment().toDate())
                                                    } else if (key) {
                                                        setTempFromDate(moment().startOf(key).toDate())
                                                        setTempToDate(moment().endOf(key).toDate())
                                                        setDateType(name)
                                                        setFromDate(moment().startOf(key).toDate())
                                                        setToDate(moment().endOf(key).toDate())
                                                    }
                                                }}
                                            >{name}</button>
                                        )
                                    }
                                </div>
                            </Row>
                        </AvForm>

                        <Row>
                            <Col sm="12">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th colSpan="2" className="green">Deposits</th>
                                                <th colSpan="2" className="red">Withdrawals</th>
                                                <th colSpan="2" className="green">Average Deposits</th>
                                                <th colSpan="2" className="red">Average Withdrawals</th>
                                                <th colSpan="3" className="warning">Credit</th>
                                                <th colSpan="4">Players</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <Header name={periodType} className="text-capitalize" type='period' />
                                                <Header name='Total' className="green" type='totalDepositAmount' />
                                                <Header name='Num' className="green" type='totalDepositCount' />
                                                <Header name='Total' className="red" type='totalWithdrawalAmount' />
                                                <Header name='Num' className="red" type='totalWithdrawalCount' />
                                                <Header name='Average' className="green" type='averageDepositAmount' />
                                                <Header name='Highest' className="green" type='highestDepositAmount' />
                                                <Header name='Average' className="red" type='averageWithdrawalAmount' />
                                                <Header name='Highest' className="red" type='highestWithdrawalAmount' />
                                                <Header name='In' className="green" type='totalCreditIn' />
                                                <Header name='Out' className="red" type='totalCreditOut' />
                                                <Header name='Win' className="warning" type='netWinning' />
                                                <Header name='Active' type='activePlayerCount' />
                                                <Header name='New' type='newPlayerCount' />
                                                <Header name='First Deposit' type='firstDepositorCount' />
                                                <Header name='Accumulated' type='accumulatedPlayerCount' />
                                                <Header name='Profit' type='netProfit' />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data &&
                                                data.salesSummaryReport.length > 0 &&
                                                data.salesSummaryReport.map((report, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th className={
                                                                classnames({
                                                                    month: true,
                                                                    pointer: periodType !== 'day'
                                                                })
                                                            } onClick={() => {
                                                                if (periodType === 'year') {
                                                                    history.push(`/transaction-report/month/${moment(report.period).startOf('year').format('YYYY-MM-DD')}/${moment(report.period).endOf('year').format('YYYY-MM-DD')}`);
                                                                    setPeriodType('month')
                                                                } else if (periodType === 'month') {
                                                                    history.push(`/transaction-report/day/${moment(report.period).startOf('month').format('YYYY-MM-DD')}/${moment(report.period).endOf('month').format('YYYY-MM-DD')}`);
                                                                    setPeriodType('day')
                                                                    setTempFromDate(moment(report.period).startOf('month').toDate())
                                                                    setTempToDate(moment(report.period).endOf('month').toDate())
                                                                    setFromDate(moment(report.period).startOf('month').format('YYYY-MM-DD'))
                                                                    setToDate(moment(report.period).endOf('month').format('YYYY-MM-DD'))
                                                                }
                                                            }}>{moment(report.period).format(dateDisplay)}</th>
                                                            <th className="green">{Formatter(report.totalDepositAmount)}</th>
                                                            <th className="green">{report.totalDepositCount}</th>
                                                            <th className="red">{Formatter(report.totalWithdrawalAmount)}</th>
                                                            <th className="red">{report.totalWithdrawalCount}</th>
                                                            <th className="green">{Formatter(report.averageDepositAmount)}</th>
                                                            <th className="green">{Formatter(report.highestDepositAmount)}</th>
                                                            <th className="red">{Formatter(report.averageWithdrawalAmount)}</th>
                                                            <th className="red">{Formatter(report.highestWithdrawalAmount)}</th>
                                                            <th className="green">{Formatter(report.totalCreditIn)}</th>
                                                            <th className="red">{Formatter(report.totalCreditOut)}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.netWinning >= 0,
                                                                        red: report.netWinning < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.netWinning)}</th>
                                                            <th>{report.activePlayerCount}</th>
                                                            <th>{report.newPlayerCount}</th>
                                                            <th>{report.firstDepositorCount}</th>
                                                            <th>{report.accumulatedPlayerCount}</th>

                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.netProfit >= 0,
                                                                        red: report.netProfit < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.netProfit)}</th>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(TransactionReport));
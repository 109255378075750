import React, { useEffect } from "react";
import { Input, Label } from "reactstrap";

import { connect } from "react-redux";
import {
    hideRightSidebar,
    changeFilter,
    changeSort
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";
import DatePicker from "react-datepicker";

import { Link } from "react-router-dom";
import _ from 'lodash';
import { AvForm } from "availity-reactstrap-validation";
import moment from 'moment';

import "../../components/CommonForBoth/rightbar.scss"

function TransactionFilter(props) {

    const transactionEnum = [{ name: 'CreditIn' }, { name: 'CreditOut' }]
    const transactionAllEnum = [{ name: 'Deposit' }, { name: 'Withdrawal' }, { name: 'CreditIn' }, { name: 'CreditOut' }]
    const { type } = props;

    //filter
    const [createStart, setCreateStart] = React.useState(null)
    const [createEnd, setCreateEnd] = React.useState(null)
    const [updateStart, setUpdateStart] = React.useState(null)
    const [updateEnd, setUpdateEnd] = React.useState(null)
    const [transactionTypes, setTransactionTypes] = React.useState([])

    //sort
    const [sortCreate, setSortCreate] = React.useState(null)
    const [sortUpdate, setSortUpdate] = React.useState(null)

    const hideRightSidebar = (e) => {
        e.preventDefault();
        props.hideRightSidebar();
    }


    const changeFilter = (data) => {
        props.changeFilter(data);
    }

    const changeSort = (data) => {
        props.changeSort(data);
    }

    const GenerateDateFilter = (type, startDate, endDate) => {
        if (startDate && endDate) {
            return {
                and: [{
                    [type]: { gt: `${moment(startDate)}` }
                }, {
                    [type]: { lt: `${moment(endDate).add('hours', 23).add('minutes', 59).add('seconds', 59)}` }
                }]
            }
        }
        return { [type]: { lt: new Date() } }
    }

    const GenerateTransactionTypeFilter = (transactionTypes) => {
        let allData = [];
        let data = [];
        _.forEach(transactionTypes, transactionType => {
            data.push(transactionType)
        })

        _.forEach(type === 'all' ? transactionAllEnum : transactionEnum, ({ name }) => {
            allData.push(name)
        })

        return data.length === 0 ? [] : data
    }

    const ResetFilter = () => {
        setCreateStart(null)
        setCreateEnd(null)
        setUpdateStart(null)
        setUpdateEnd(null)
    }

    const ResetSort = () => {
        setSortCreate(null)
        setSortUpdate(null)
    }

    useEffect(() => {
        ResetFilter()
        ResetSort()
    }, [])

    useEffect(() => {

        let filter = {
            transactionType: { in: GenerateTransactionTypeFilter(transactionTypes) }
        }

        if (type !== 'all') {
            if (type === 'credit-in-out') {
                delete filter["paymentType"]
            } else {
                delete filter["transactionType"]
            }
        }

        if (_.isEmpty(transactionTypes)) {
            delete filter["transactionType"]
        }

        if (createStart || createEnd) {
            changeFilter({
                belongs: "transactions",
                filter: {
                    ...filter,
                    and: [
                        GenerateDateFilter('createdAt', createStart, createEnd),
                        GenerateDateFilter('updatedAt', updateStart, updateEnd),
                    ]
                }
            })
        } else {
            changeFilter({
                belongs: "transactions",
                filter: {
                    ...filter
                }
            })
        }
    }, [createStart, createEnd, updateStart, updateEnd, transactionTypes]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let sorts = []

        if (sortCreate !== null) {
            sorts.push({
                field: "createdAt", direction: sortCreate
            })
        }

        if (sortUpdate !== null) {
            sorts.push({
                field: "updatedAt", direction: sortUpdate
            })
        }
        changeSort(sorts)
    }, [sortCreate, sortUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="right-bar bank-filter-page bank-link">

                <SimpleBar style={{ height: "100vh" }}>
                    <div data-simplebar className="h-100">
                        <AvForm className="needs-validation" >

                            <div className="rightbar-title px-3 py-4">
                                <Link to="#" onClick={hideRightSidebar} className="right-bar-toggle float-right">
                                    <i className="mdi mdi-close noti-icon"></i>
                                </Link>
                                <h5 className="m-0">Transaction Filter</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetFilter}>
                                        Reset Filter
                                    </button>
                                </div>

                                <span className="mb-2 d-block">Created From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={createStart}
                                    onChange={date => {
                                        if (date > createEnd) {
                                            setCreateEnd(date)
                                        }
                                        setCreateStart(date)
                                    }}
                                    selectsStart
                                    startDate={createStart}
                                    endDate={createEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={createEnd}
                                    onChange={date => {
                                        setCreateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={createStart}
                                    endDate={createEnd}
                                    minDate={createStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Updated From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={updateStart}
                                    onChange={date => {
                                        if (date > updateEnd) {
                                            setUpdateEnd(date)
                                        }
                                        setUpdateStart(date)
                                    }}
                                    selectsStart
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={updateEnd}
                                    onChange={date => {
                                        setUpdateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    minDate={updateStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />
                                {
                                    (type === 'credit-in-out') &&
                                    <>
                                        <hr className="mt-1" />

                                        <span className="mb-2 d-block">Transaction Type</span>
                                        {
                                            transactionEnum.map(({ name }, index) => {
                                                return (
                                                    <div key={index} className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input checkbox" id={name} onChange={() => {
                                                            let data = [];
                                                            if (_.includes(transactionTypes, name)) {
                                                                data = _.filter(transactionTypes, transactionType => {
                                                                    return transactionType !== name
                                                                })
                                                            } else {
                                                                data = [...transactionTypes, name]
                                                            }

                                                            setTransactionTypes(data)
                                                        }} />
                                                        <Label className="custom-control-label" htmlFor={name}>{name}</Label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }
                                {
                                    (type === 'all') &&
                                    <>
                                        <hr className="mt-1" />

                                        <span className="mb-2 d-block">Transaction Type</span>
                                        {
                                            transactionAllEnum.map(({ name }, index) => {
                                                return (
                                                    <div key={index} className="custom-control custom-checkbox">
                                                        <Input type="checkbox" className="custom-control-input checkbox" id={name} onChange={() => {
                                                            let data = [];
                                                            if (_.includes(transactionTypes, name)) {
                                                                data = _.filter(transactionTypes, transactionType => {
                                                                    return transactionType !== name
                                                                })
                                                            } else {
                                                                data = [...transactionTypes, name]
                                                            }

                                                            setTransactionTypes(data)
                                                        }} />
                                                        <Label className="custom-control-label" htmlFor={name}>{name}</Label>
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                }
                            </div>
                        </AvForm>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay" onClick={hideRightSidebar}></div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeFilter,
    changeSort
})(TransactionFilter);

import gql from "graphql-tag";

export const PLAYER_SUMMARY_REPORT = gql`
  query (
    $id: String
    $fromDate: String
    $toDate: String
    $orderBy: String
    $orderDirection: String
    $isFirstDepositor: Boolean
    $isNewMember: Boolean
    $skip: Int
    $take: Int
  ) {
    playerSummaryReport(
      id: $id
      fromDate: $fromDate
      toDate: $toDate
      orderBy: $orderBy
      orderDirection: $orderDirection
      isFirstDepositor: $isFirstDepositor
      isNewMember: $isNewMember
      skip: $skip
      take: $take
    ) {
      id
      totalCount
      username
      totalDepositAmount
      totalDepositCount
      totalBonusAmount
      totalFreeCreditAmount
      totalWithdrawalAmount
      totalWithdrawalCount
      averageDepositAmount
      highestDepositAmount
      averageWithdrawalAmount
      highestWithdrawalAmount
      grossProfit
      netProfit
      totalCreditIn
      totalCreditOut
      netWinning
      totalRebateAmount
      adjustedProfit
      totalIncrementAmount
      totalDecrementAmount
      totalReferralAmount
      totalRewardAmount
      totalCommissionAmount
      totalDailyAttendanceAmount
      totalWeeklyAttendanceAmount
    }
  }
`;

import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import { DELETE_ONE_TELEGRAM_MENU_BUTTON } from '../../../graphql/mutation/DELETE_ONE_TELEGRAM_MENU_BUTTON';
import { ME } from '../../../graphql/query';
import { GET_ONE_TELEGRAM_MENU } from '../../../graphql/query/GET_ONE_TELEGRAM_MENU';
import { GET_TELEGRAM_MENU_BUTTONS } from '../../../graphql/query/GET_TELEGRAM_MENU_BUTTONS';
import ToastHelper from '../../../helpers/ToastHelper';
import TelegramPageForm from '../telegram-page/telegram-page-form';
import TelegramMenuButtonForm from './telegram-menu-button-form';
import TelegramMenuButtonItem from './telegram-menu-button-item';

function TelegramMenuButtonTable(props) {

    const { t } = props;
    const { pageId } = props.match.params
    const { data: meData } = useQuery(ME)

    const [deleteConfirm, setDeleteConfirm] = React.useState(false)
    const [deleteItem, setDeleteItem] = React.useState(null)

    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [orderBy, setOrderBy] = React.useState("position")
    const [orderDirection, setOrderDirection] = React.useState("ASC")
    const [mode, setMode] = React.useState(null)
    const [selectedButton, setSelectedButton] = React.useState(null)
    const [telegramPageMode, setTelegramPageMode] = React.useState(null)
    const [afterCursor, setAfterCursor] = React.useState(null)

    const first = 50

    const { data: telegramMenuData } = useQuery(GET_ONE_TELEGRAM_MENU, {
        variables: {
            id: pageId,
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const [deleteOneTelegramMenuButton] = useMutation(DELETE_ONE_TELEGRAM_MENU_BUTTON)

    const { data, refetch, fetchMore } = useQuery(GET_TELEGRAM_MENU_BUTTONS, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: search === "" ? { telegramMenuId: { eq: pageId } } : { action: { iLike: `%${search}%` }, telegramMenuId: { eq: pageId } },
            sorting: [{ field: orderBy, direction: orderDirection }]
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: search === "" ? { telegramMenuId: { eq: pageId } } : { action: { iLike: `%${search}%` }, telegramMenuId: { eq: pageId } },
                    sorting: [{ field: orderBy, nulls: "NULLS_LAST", direction: orderDirection }]
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.telegramMenuButtons.edges;
                    const pageInfo = fetchMoreResult.telegramMenuButtons.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            telegramMenuButtons: {
                                __typename: previousResult.telegramMenuButtons.__typename,
                                edges: _.uniq([...previousResult.telegramMenuButtons.edges, ...newEdges]),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (data) {
            setAfterCursor(data.telegramMenuButtons.pageInfo.endCursor)
            setPageInfo(data.telegramMenuButtons.pageInfo)
        }
    }, [data])

    const debouncedSave = React.useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
            } else {
                setOrderDirection('ASC')
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)
        }
    }

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }
    return <React.Fragment>
        <div className="page-content telegram-page max-height">
            <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Telegram Page" breadcrumbItem="Telegram Menu Button" back />
                {
                    telegramMenuData &&
                    telegramMenuData.telegramMenu &&
                    <Card className="w-100">
                        <CardBody>
                            <Row>
                                <Col sm="6">
                                    <div>
                                        <p className="text-muted mb-2">Name</p>
                                        <h5 className="align-left cursor-pointer">{telegramMenuData.telegramMenu.name}{meData && meData.me.role === 'Developer' && <span className="bx bx-edit-alt ml-1 font-size-17" onClick={() => { setTelegramPageMode('edit') }}></span>}</h5>
                                    </div>
                                </Col>
                                <Col sm="6">
                                    <div>
                                        <p className="text-muted mb-2">Description</p>
                                        <h5 className="align-left cursor-pointer">{telegramMenuData.telegramMenu.description}<span className="bx bx-edit-alt ml-1 font-size-17" onClick={() => { setTelegramPageMode('edit') }}></span></h5>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                }
                <div className="search-bar-container">
                    <div className="button-add-container">
                        <div className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input
                                    value={searchValue}
                                    type="text"
                                    className="form-control"
                                    placeholder="Search..."
                                    onChange={(e) => {
                                        setSearchValue(e.target.value)
                                        debouncedSave(e.target.value)
                                    }}
                                />
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="dropdown d-inline-block d-lg-none ml-2">
                                <button
                                    type="button"
                                    className="btn header-item noti-icon waves-effect"
                                    id="page-header-search-dropdown"
                                    onClick={() => { setIsSearch(!isSearch) }}>
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div
                                    className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                    aria-labelledby="page-header-search-dropdown"
                                >
                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input
                                                    value={searchValue}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search..."
                                                    onChange={(e) => {
                                                        setSearchValue(e.target.value)
                                                        debouncedSave(e.target.value)
                                                    }}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit">
                                                        <i className="mdi mdi-magnify"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="button-add-container">
                        <button type="button" className="waves-effect btn btn-outline-secondary button-add" onClick={() => { setMode('new') }}>
                            <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Telegram Menu Button
                        </button>
                    </div>
                </div>

                <Card>
                    <CardBody>
                        <Row>
                            <Col sm="12">
                                <div className="overflow-auto">
                                    {
                                        <InfiniteScroll
                                            dataLength={0} //This is important field to render the next data
                                            next={() => setIsFetchMore(true)}
                                            hasMore={pageInfo.hasNextPage}
                                            loader={
                                                <div className="text-center my-3">
                                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                </div>
                                            }
                                            endMessage={
                                                <div className="text-center my-3">
                                                    <div>Yay! You have seen it all</div>
                                                </div>
                                            }
                                        >
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr className="font-size-12">
                                                        <Header name='Action' className="" type='action' />
                                                        <Header name='Position' className="" type='position' />
                                                        <Header name='Active' className="" type='isActive' />
                                                    </tr>
                                                </thead>
                                                <tbody className="table-body">
                                                    {
                                                        data &&
                                                        data.telegramMenuButtons.edges.map((telegramMenuButton, index) => {
                                                            return (
                                                                <TelegramMenuButtonItem
                                                                    key={index}
                                                                    onEdit={(telegramMenuButton) => {
                                                                        setMode('edit')
                                                                        setSelectedButton(telegramMenuButton)
                                                                    }}
                                                                    onDelete={(data) => {
                                                                        setDeleteItem(data)
                                                                        setDeleteConfirm(true)
                                                                    }}
                                                                    telegramMenuButton={telegramMenuButton}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {
                                                        data &&
                                                        data.telegramMenuButtons.edges.length === 0 &&
                                                        <tr className="text-center pt-3">
                                                            <td colSpan={12}>
                                                                {t('No Telegram Menu Button Found')}
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </InfiniteScroll>
                                    }
                                </div>
                            </Col>
                        </Row>
                        {/* <div className="load-more-container">
                            {
                                (loading) &&
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                </Col>
                            }
                        </div> */}
                    </CardBody>
                </Card>
                <TelegramPageForm mode={telegramPageMode} toggleModal={() => {
                    setTelegramPageMode(null)
                }} telegramMenu={telegramMenuData ? { node: telegramMenuData.telegramMenu } : null} />
                <TelegramMenuButtonForm mode={mode} toggleModal={() => {
                    refetch()
                    setSelectedButton(null)
                    setMode(null)
                }} telegramMenuId={pageId} telegramMenuButton={selectedButton} name={telegramMenuData ? telegramMenuData.telegramMenu.name : null} />
                {
                    deleteConfirm &&
                    <SweetAlert
                        error
                        showCancel
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="primary"
                        title={'Alert'}
                        onConfirm={() => {
                            setDeleteConfirm(false)
                            deleteOneTelegramMenuButton({
                                variables: {
                                    id: deleteItem.node.id
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            }).then(val => {
                                if (val) {
                                    ToastHelper.toggleToast({
                                        message: "Update Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                    refetch()
                                    setDeleteItem(null)
                                }
                            })
                        }}
                        onCancel={() => {
                            setDeleteItem(null)
                            setDeleteConfirm(false)
                        }}
                    >
                        {`Confirm Delete?`}
                    </SweetAlert>
                }
            </Container>
        </div>
    </React.Fragment>
}

export default connect()(withTranslation()(TelegramMenuButtonTable));


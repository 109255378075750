import gql from 'graphql-tag';

export const TransactionFragment = gql`
fragment TransactionInfo on Transaction {
    id
    serialNo
    amount
    quantity
    unitPrice
    bonusAmount
    totalAmount
    rewardPoint
    agentPoint
    transactionType
    transactionStatus
    platformId
    playerId
    gameAccountId
    kioskAccountId
    kioskStatus
    kioskRemark
    fromWalletId
    toWalletId
    isActive
    isArchived
    createdAt
    updatedAt
    createdBy
    updatedBy
    receiptUrl
    movement
    rejectReason {
      id
      name
    }
    rejectRemark
    remark
    freeCreditType {
      name
    }
    bonus {
      name
    }
  }
`;
import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';

export const CREATED_KIOSK_ACCOUNT = gql`
    subscription {
        createdKioskAccount {
            ...KioskInfo
        }
    }
    ${KioskFragment}
`;
import gql from 'graphql-tag';

export const CREATE_ONE_BANK_ACCOUNT_TRANSFER = gql`
    mutation(
        $input: CreateOneBankAccountTransferInput!
    ) {
        createOneBankAccountTransfer (input: $input) {
           id
        }
    }
`;
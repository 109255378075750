import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  NavItem,
  NavLink,
  Media,
  Button,
} from "reactstrap";
import classnames from "classnames";
import NumberFormat from "react-number-format";

import "../Crypto/datatables.scss";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useQuery, useLazyQuery } from "react-apollo";
import {
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNT_ENTRIES,
  ME,
} from "../../graphql/query";
import BankEntryTable from "./bank-entry-table";
import AccountInOut from "./account-in-out";
import InfiniteScroll from "react-infinite-scroll-component";
import { OperatingUsers, Operator } from "../../assets/scss/role.constant";

function BankInfo(props) {
  const first = 50;
  const { bankAccountId } = props.match.params;
  const [activeTab, setActiveTab] = React.useState(null);
  const [currentBankAccount, setCurrentBankAccount] = React.useState(null);
  const [filter, setFilter] = React.useState({
    bankAccountId: { eq: currentBankAccount },
  });
  const [afterCursor, setAfterCursor] = React.useState(null);
  const [isFetchMore, setIsFetchMore] = React.useState(false);
  const [pageInfo, setPageInfo] = React.useState(false);
  const [movementType, setMovementType] = React.useState(null);

  const [bankAccountPageInfo, setBankAccountPageInfo] = React.useState(null);

  // const { data: dataUser, loading: loadingUser } = useQuery(USER, {
  //     variables: {
  //         id: playerId
  //     },
  //     fetchPolicy: 'network-only'
  // })

  const { data: meData } = useQuery(ME, {
    fetchPolicy: "cache-first",
  });

  const [
    getBankAccounts,
    { data: bankAccountsData, refetch: refetchBankAccounts },
  ] = useLazyQuery(GET_BANK_ACCOUNTS, {
    variables: {
      sorting: {
        field: "balance",
        direction: "DESC",
      },
    },
    fetchPolicy: "network-only",
  });

  const {
    data: bankAccountEntriesData,
    fetchMore: fetchMoreBankAccountEntries,
    refetch: refetchBankAccountEntries,
    loading: bankAccountEntriesLoading,
  } = useQuery(GET_BANK_ACCOUNT_ENTRIES, {
    variables: {
      paging: {
        first: first,
      },
      sorting: {
        field: "createdAt",
        direction: "DESC",
      },
      filter: filter,
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (currentBankAccount) {
      if (activeTab) {
        let tab = null;
        if (activeTab === "Deposit") {
          tab = ["Deposit", "Withdrawal"];
        } else if (activeTab === "Increment") {
          tab = ["Increment", "Decrement"];
        } else if (activeTab === "TransferIn") {
          tab = ["TransferIn", "TransferOut"];
        } else {
          tab = [activeTab];
        }
        setFilter({
          bankAccountId: { eq: currentBankAccount.id },
          bankAccountEntryType: { in: tab },
        });
      } else {
        setFilter({ bankAccountId: { eq: currentBankAccount.id } });
      }
    }
  }, [activeTab, currentBankAccount]);

  React.useEffect(() => {
    if (bankAccountEntriesData) {
      setAfterCursor(
        bankAccountEntriesData.bankAccountEntries.pageInfo.endCursor
      );
      setPageInfo(bankAccountEntriesData.bankAccountEntries.pageInfo);
    }
  }, [bankAccountEntriesData]);

  React.useEffect(() => {
    if (bankAccountsData) {
      setBankAccountPageInfo(bankAccountsData.bankAccounts.pageInfo);
      if (!currentBankAccount) {
        if (bankAccountId) {
          setCurrentBankAccount(
            _.find(bankAccountsData.bankAccounts.edges, function (o) {
              return o.node.id === bankAccountId;
            }).node
          );
        } else {
          setCurrentBankAccount(
            bankAccountsData.bankAccounts.edges.length > 0
              ? bankAccountsData.bankAccounts.edges[0].node
              : null
          );
        }
      }
    }
  }, [bankAccountsData]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (isFetchMore && !bankAccountEntriesLoading) {
      fetchMoreBankAccountEntries({
        variables: {
          paging: {
            first: first,
            after: afterCursor,
          },
          filter,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.bankAccountEntries.edges;
          const pageInfo = fetchMoreResult.bankAccountEntries.pageInfo;
          setIsFetchMore(false);
          setAfterCursor(pageInfo.endCursor);

          return newEdges.length
            ? {
                bankAccountEntries: {
                  __typename: previousResult.bankAccountEntries.__typename,
                  edges: _.uniqBy(
                    [...previousResult.bankAccountEntries.edges, ...newEdges],
                    "node.id"
                  ),
                  pageInfo,
                },
              }
            : previousResult;
        },
      });
    }
  }, [isFetchMore]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    getBankAccounts({
      variables: {
        paging: {
          first: 6,
          after: null,
        },
        filter: { accountHolderType: { eq: "Company" } },
        sorting: [
          {
            field: "isActive",
            direction: "DESC",
          },
          {
            field: "createdAt",
            direction: "ASC",
          },
        ],
      },
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const FindBankAccount = () => {
    return _.find(bankAccountsData.bankAccounts.edges, function (o) {
      return o.node.id === currentBankAccount.id;
    });
  };

  return (
    <React.Fragment>
      {meData &&
        !OperatingUsers.includes(meData.me.role) &&
        !Operator.includes(meData.me.role) && (
          <div className="page-content wallet-info">
            <Container fluid>
              <Row>
                <Col xl="5">
                  <Card>
                    <CardBody className="border-top">
                      <Row>
                        <Col sm="6">
                          <div>
                            <p className="text-muted mb-2">Available Balance</p>
                            {bankAccountsData &&
                              currentBankAccount &&
                              FindBankAccount() && (
                                <h5>
                                  <NumberFormat
                                    value={FindBankAccount().node.balance}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={"$"}
                                  />
                                </h5>
                              )}
                          </div>
                        </Col>
                        {/* <Col sm="6">
                                            <div className="text-sm-right mt-4 mt-sm-0">
                                                <p className="text-muted mb-2">Since last month</p>
                                                <h5>+ $ 248.35   <span className="badge badge-success ml-1">+ 1.3 %</span></h5>

                                            </div>
                                        </Col> */}
                      </Row>
                    </CardBody>

                    <CardBody className="border-top">
                      <p className="text-muted mb-4">In this month</p>
                      <div className="text-center">
                        <Row>
                          <Col sm="4">
                            <div>
                              <div className="font-size-24 text-primary mb-2">
                                <i className="bx bx-trending-up"></i>
                              </div>

                              <p className="text-muted mb-2">Increment</p>
                              <h5>$ N/A</h5>

                              <div className="mt-3">
                                <Link
                                  onClick={() => {
                                    setMovementType("increment");
                                  }}
                                  className="btn btn-primary btn-sm w-md"
                                >
                                  Increment
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="mt-4 mt-sm-0">
                              <div className="font-size-24 text-primary mb-2">
                                <i className="bx bx-trending-down"></i>
                              </div>

                              <p className="text-muted mb-2">Decrement</p>
                              <h5>$ N/A</h5>

                              <div className="mt-3">
                                <Link
                                  onClick={() => {
                                    setMovementType("decrement");
                                  }}
                                  className="btn btn-primary btn-sm w-md"
                                >
                                  Decrement
                                </Link>
                              </div>
                            </div>
                          </Col>
                          <Col sm="4">
                            <div className="mt-4 mt-sm-0">
                              <div className="font-size-24 text-primary mb-2">
                                <i className="bx bx-transfer"></i>
                              </div>

                              <p className="text-muted mb-2">Transfer</p>
                              <h5>$ N/A</h5>

                              <div className="mt-3">
                                <Link
                                  onClick={() => setMovementType("transfer")}
                                  className="btn btn-primary btn-sm w-md"
                                >
                                  Transfer
                                </Link>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>

                <Col xl="7" className="position-relative">
                  <Row className="fix-height">
                    {bankAccountsData &&
                      bankAccountsData.bankAccounts.edges.length > 0 &&
                      bankAccountsData.bankAccounts.edges.map(
                        (bankAccount, index) => {
                          return (
                            <Col key={index} sm="4">
                              <Card
                                className={classnames({
                                  "mini-stats-wid": true,
                                  selected:
                                    currentBankAccount &&
                                    bankAccount.node.id ===
                                      currentBankAccount.id,
                                  pointer: bankAccount.node.isActive,
                                  "gray-out": !bankAccount.node.isActive,
                                })}
                                onClick={() => {
                                  if (bankAccount.node.isActive) {
                                    setCurrentBankAccount(bankAccount.node);
                                  }
                                }}
                              >
                                <CardBody>
                                  <Media>
                                    <div className="mr-3 align-self-center">
                                      <i className="bx bxs-bank h2 text-primary mb-0"></i>
                                    </div>
                                    <Media body>
                                      <p className="text-muted mb-0 font-size-12">
                                        {bankAccount.node.bank.name}
                                      </p>
                                      <p className="mb-0 font-weight-medium">
                                        {bankAccount.node.accountName}
                                      </p>
                                      <p className="text-muted mb-2 font-weight-medium">
                                        {bankAccount.node.accountNo}
                                      </p>
                                      {/* <h5 className="mb-0">1.02356 BTC <span className="font-size-14 text-muted">= $ 9148.00</span></h5> */}
                                      <h5>
                                        <NumberFormat
                                          value={bankAccount.node.balance}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          prefix={"$"}
                                        />
                                      </h5>
                                    </Media>
                                  </Media>
                                </CardBody>
                              </Card>
                            </Col>
                          );
                        }
                      )}
                  </Row>
                  <div className="align-right mb-2 mt-1">
                    {bankAccountPageInfo &&
                      bankAccountPageInfo.hasPreviousPage && (
                        <Button
                          onClick={() => {
                            getBankAccounts({
                              variables: {
                                paging: {
                                  last: first,
                                  before: bankAccountPageInfo.startCursor,
                                },
                                filter: {
                                  accountHolderType: { eq: "Company" },
                                },
                                sorting: {
                                  field: "createdAt",
                                  direction: "ASC",
                                },
                              },
                            });
                          }}
                        >
                          Prev
                        </Button>
                      )}
                    {bankAccountPageInfo && bankAccountPageInfo.hasNextPage && (
                      <Button
                        className="ml-1"
                        onClick={() => {
                          getBankAccounts({
                            variables: {
                              paging: {
                                first: first,
                                after: bankAccountPageInfo.endCursor,
                              },
                              filter: { accountHolderType: { eq: "Company" } },
                              sorting: {
                                field: "createdAt",
                                direction: "ASC",
                              },
                            },
                          });
                        }}
                      >
                        Next
                      </Button>
                    )}
                  </div>
                  {/* <Card>
                                <CardBody>
                                    <h4 className="card-title mb-3">Overview</h4>

                                    <div>
                                        <div id="overview-chart" className="apex-charts" dir="ltr">
                                            <ReactApexChart series={this.state.series} options={this.state.options} type="line" height={240} />
                                        </div>
                                    </div>
                                </CardBody>
                            </Card> */}
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Activities</h4>

                      <ul className="nav nav-tabs nav-tabs-custom">
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === null,
                            })}
                            onClick={() => {
                              setActiveTab(null);
                            }}
                          >
                            All
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "Increment",
                            })}
                            onClick={() => {
                              setActiveTab("Increment");
                            }}
                          >
                            Increment / Decrement
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "Deposit",
                            })}
                            onClick={() => {
                              setActiveTab("Deposit");
                            }}
                          >
                            Deposit / Withdrawal
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={classnames({
                              active: activeTab === "TransferIn",
                            })}
                            onClick={() => {
                              setActiveTab("TransferIn");
                            }}
                          >
                            Transfer In / Transfer Out
                          </NavLink>
                        </NavItem>
                      </ul>
                      {bankAccountEntriesData && (
                        <InfiniteScroll
                          dataLength={
                            bankAccountEntriesData.bankAccountEntries.edges
                              .length
                          } //This is important field to render the next data
                          next={() => setIsFetchMore(true)}
                          hasMore={pageInfo.hasNextPage}
                          loader={
                            <div className="text-center my-3">
                              <Link to="#" className="text-success">
                                <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                                Load more{" "}
                              </Link>
                            </div>
                          }
                          endMessage={
                            <div className="text-center my-3">
                              <div>Yay! You have seen it all</div>
                            </div>
                          }
                        >
                          <div className="mt-4">
                            <BankEntryTable
                              data={
                                bankAccountEntriesData
                                  ? bankAccountEntriesData.bankAccountEntries
                                      .edges
                                  : []
                              }
                            />
                            {/* <MDBDataTable responsive bordered data={data} /> */}
                          </div>
                        </InfiniteScroll>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="load-more-container">
                {isFetchMore && (
                  <Col xs="12">
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin mr-2"></i> Load
                        more{" "}
                      </Link>
                    </div>
                  </Col>
                )}
              </div>
            </Container>
          </div>
        )}
      <AccountInOut
        modal={movementType}
        toggleModal={() => {
          setMovementType(null);
        }}
        fromBankAccount={currentBankAccount}
        type={movementType}
        onDone={() => {
          setMovementType(null);
          refetchBankAccounts();
          refetchBankAccountEntries();
        }}
      />
    </React.Fragment>
  );
}

export default BankInfo;

import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';
import { PageFragment } from '../fragments/page.fragment';

export const GET_KIOSK = gql`
    query (
        $paging: CursorPaging
        $filter: KioskAccountFilter
        $sorting: [KioskAccountSort!]
    ) {
        kioskAccounts (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...KioskInfo
                }
            }
        }
    }
    ${KioskFragment}
    ${PageFragment}
`;
import gql from 'graphql-tag';
import { BonusFragment } from '../fragments/bonus.fragment';

export const UPDATE_ONE_BONUS = gql`
    mutation(
        $input: UpdateOneBonusInput!
    ) {
        updateOneBonus (input: $input) {
            ...BonusInfo
        }
    }
    ${BonusFragment}
`;
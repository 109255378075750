
import React, { Component } from 'react';

import { Col, Card, CardBody, Row } from "reactstrap";
import moment from 'moment';

class ProfileGameAccountItem extends Component {


    state = {

    }

    render() {
        const { gameAcccount, onRefresh, isLoading } = this.props;
        return <React.Fragment>
            <Col xl="3" sm="6" lg="3">
                <Card className="profile-game-account-item">
                    <CardBody className="">
                        <Row>
                            <Col lg="12">
                                <Row>
                                    <Col className="text-align pb-3">
                                        <div>
                                            <div className="header">Game Account Username</div>
                                            <div className="font-size-14">{gameAcccount.username}</div>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className="text-align pb-3">
                                        <div>
                                            <div className="header">Wallet Balance</div>
                                            <div className="font-size-14">
                                                <div className="d-flex justify-content-between">
                                                    <div className="align-self-center">{gameAcccount.balance ? gameAcccount.balance : "-"}</div>
                                                    {!isLoading && <button type="button" className="waves-effect btn btn-outline-secondary" onClick={onRefresh}>
                                                        Reload
                                                    </button>}

                                                    {isLoading &&
                                                        <div className="spinner-border text-dark m-1" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    }
                                                </div>
                                                {/* {<Link to="#" className="text-dark">0.0</Link>} */}
                                            </div>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className="text-align pb-3">
                                        <div>
                                            <div className="header">Last Sync Date</div>
                                            <div className="font-size-14">{gameAcccount.lastSyncAt ? moment(gameAcccount.lastSyncAt).calendar() : "-"}</div>
                                        </div>
                                    </Col>

                                </Row>

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    }
}

export default ProfileGameAccountItem;
import gql from 'graphql-tag';

export const ME = gql`
    query {
        me {
            serialNo
            id
            name
            username
            role
            isActive
            email
            phoneNo
        }
    }
`;
import gql from "graphql-tag";

export const FIND_OR_CREATE_TELEGRAM_MENU = gql`
  mutation ($name: String!) {
    findOrCreateTelegramMenu(findOrCreateTelegramMenuInput: { name: $name }) {
      telegramMenuButtons(
        sorting: { field: position, direction: DESC }
        paging: { first: 1 }
      ) {
        edges {
          node {
            position
          }
        }
      }
    }
  }
`;

import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";

//Import Breadcrumb
import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AccountItem from "./account-item";
import EditBankAccount from "./edit-account";
import NewBankAccount from "./new-account";

import { GET_BANK_ACCOUNTS, ME } from "../../graphql/query";
import { CREATED_BANK_ACCOUNT } from "../../graphql/subscription/CREATD_BANK_ACCOUNT";

import _ from "lodash";

import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import { withTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { connect } from "react-redux";
import { OperatingUsers, Operator } from "../../assets/scss/role.constant";
import { UPDATE_ONE_BANK_ACCOUNT } from "../../graphql/mutation";
import ToastHelper from "../../helpers/ToastHelper";
import { toggleRightSidebar } from "../../store/actions";
import AccountInOut from "./account-in-out";
import BankAccountTableItem from "./bank-account-table-item";

function BankAccountsList(props) {
  const { t } = props;
  const first = 50;

  const [addAccount, setAddAccount] = React.useState(false);
  const [editAccount, setEditAccount] = React.useState(null);
  const [afterCursor, setAfterCursor] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [searchValue, setSearchValue] = React.useState("");
  const [pageInfo, setPageInfo] = React.useState(false);
  const [isFetchMore, setIsFetchMore] = React.useState(false);
  const [isSearch, setIsSearch] = React.useState(false);
  const [newData, setNewData] = React.useState(null);
  const [layoutToggle, setLayoutToggle] = React.useState(false);
  const [movementType, setMovementType] = React.useState(null);
  const [bankAccount, setBankAccount] = React.useState(null);
  const [orderBy, setOrderBy] = React.useState("accountName");
  const [orderDirection, setOrderDirection] = React.useState("ASC");
  const [toggleBank, setToggleBank] = React.useState(null);
  const [isToggleBank, setIsToggleBank] = React.useState(false);
  const [isToggleDeposit, setIsToggleDeposit] = React.useState(false);
  const [istoggleWithdraw, setIstoggleWithdraw] = React.useState(false);

  const { data: meData } = useQuery(ME);

  const [updateOneBankAccount] = useMutation(UPDATE_ONE_BANK_ACCOUNT);

  const { loading, fetchMore, data, refetch } = useQuery(GET_BANK_ACCOUNTS, {
    variables: {
      paging: {
        first: first,
        after: null,
      },
      filter: {
        and: [
          props.filter,
          {
            or: [
              { bank: { name: { iLike: `%${search}%` } } },
              { accountName: { iLike: `%${search}%` } },
              { accountNo: { iLike: `%${search}%` } },
            ],
          },
          { accountHolderType: { eq: "Company" } },
        ],
      },
      sorting: [
        {
          field: "isActive",
          direction: "DESC",
        },
        {
          field: orderBy,
          direction: orderDirection,
        },
        ...props.sort,
      ],
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const { data: accountCreatedData } = useSubscription(CREATED_BANK_ACCOUNT, {
    variables: {
      createdBy: { neq: meData ? meData.me.username : "" },
    },
  });

  React.useEffect(() => {
    if (isFetchMore) {
      fetchMore({
        variables: {
          paging: {
            first: first,
            after: afterCursor,
          },
          filter: {
            and: [
              {
                or: [
                  { bank: { name: { iLike: `%${search}%` } } },
                  { accountName: { iLike: `%${search}%` } },
                  { accountNo: { iLike: `%${search}%` } },
                ],
              },
              { accountHolderType: { eq: "Company" } },
              props.filter,
            ],
          },
          sorting: props.sort,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          const newEdges = fetchMoreResult.bankAccounts.edges;
          const pageInfo = fetchMoreResult.bankAccounts.pageInfo;
          setIsFetchMore(false);
          setAfterCursor(pageInfo.endCursor);

          return newEdges.length
            ? {
                bankAccounts: {
                  __typename: previousResult.bankAccounts.__typename,
                  edges: _.uniq([
                    ...previousResult.bankAccounts.edges,
                    ...newEdges,
                  ]),
                  pageInfo,
                },
              }
            : previousResult;
        },
      });
    }
  }, [isFetchMore]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (accountCreatedData) {
      setNewData(accountCreatedData.createdBankAccount);
    }
  }, [accountCreatedData]);

  React.useEffect(() => {
    if (data) {
      setAfterCursor(data.bankAccounts.pageInfo.endCursor);
      setPageInfo(data.bankAccounts.pageInfo);
    }
  }, [data]);

  const OrderBy = (type) => {
    if (orderBy === type) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
    } else {
      setOrderDirection("ASC");
      setOrderBy(type);
    }
  };

  const Header = (props) => {
    const { type, className, name } = props;

    return (
      <th
        className={`cursor-pointer ${className}`}
        onClick={() => OrderBy(type)}
      >
        <div
          className={classnames({
            "align-center": orderBy === type,
          })}
        >
          <div>{t(name)}</div>
          {orderBy === type && (
            <>
              {orderDirection === "ASC" && <i className="bx bx-sort-up"></i>}
              {orderDirection === "DESC" && <i className="bx bx-sort-down"></i>}
            </>
          )}
        </div>
      </th>
    );
  };

  const debouncedSave = useRef(
    _.debounce((nextValue) => setSearch(nextValue), 300)
  ).current;

  const ToggleBankAccount = (bank) => {
    updateOneBankAccount({
      variables: {
        input: {
          update: {
            isActive: !bank.node.isActive,
          },
          id: bank.node.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {
        if (val) {
          setToggleBank(null);
          ToastHelper.toggleToast({
            message: "Update Succesfully!",
            title: "",
            onClick: function () {},
          });
        }
      });
  };

  const ToggleDeposit = (bank) => {
    updateOneBankAccount({
      variables: {
        input: {
          update: {
            canDeposit: !bank.node.canDeposit,
          },
          id: bank.node.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {
        if (val) {
          setToggleBank(null);
          ToastHelper.toggleToast({
            message: "Update Succesfully!",
            title: "",
            onClick: function () {},
          });
        }
      });
  };

  const ToggleWithdraw = (bank) => {
    updateOneBankAccount({
      variables: {
        input: {
          update: {
            canWithdraw: !bank.node.canWithdraw,
          },
          id: bank.node.id,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {
        if (val) {
          setToggleBank(null);
          ToastHelper.toggleToast({
            message: "Update Succesfully!",
            title: "",
            onClick: function () {},
          });
        }
      });
  };

  return (
    <React.Fragment>
      {
        <div className="page-content bank-link">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <Breadcrumbs title="Banking" breadcrumbItem="Bank Accounts List" />

            <div className="search-bar-container">
              <div className="button-add-bank-container">
                <div className="app-search d-none d-lg-block">
                  <div className="position-relative">
                    <input
                      value={searchValue}
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                      onChange={(e) => {
                        setSearchValue(e.target.value);
                        debouncedSave(e.target.value);
                      }}
                    />
                    <span className="bx bx-search-alt"></span>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="dropdown d-inline-block d-lg-none ml-2">
                    <button
                      type="button"
                      className="btn header-item noti-icon waves-effect"
                      id="page-header-search-dropdown"
                      onClick={() => {
                        setIsSearch(!isSearch);
                      }}
                    >
                      <i className="mdi mdi-magnify"></i>
                    </button>
                    <div
                      className={
                        isSearch
                          ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                          : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                      }
                      aria-labelledby="page-header-search-dropdown"
                    >
                      <form className="p-3">
                        <div className="form-group m-0">
                          <div className="input-group">
                            <input
                              value={searchValue}
                              type="text"
                              className="form-control"
                              placeholder={props.t("Search") + "..."}
                              onChange={(e) => {
                                setSearchValue(e.target.value);
                                debouncedSave(e.target.value);
                              }}
                            />
                            <div className="input-group-append">
                              <button className="btn btn-primary" type="submit">
                                <i className="mdi mdi-magnify"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button-add-bank-container">
                <div className="filter-container button-filter-container">
                  <button
                    type="button"
                    className="waves-effect btn btn-outline-secondary button-add-bank"
                    onClick={() => {
                      props.toggleRightSidebar();
                    }}
                  >
                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i>{" "}
                    |{" "}
                    <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                  </button>
                </div>
                <div className="filter-container button-filter-container px-2">
                  <button
                    type="button"
                    className="waves-effect btn btn-outline-secondary button-add-bank"
                    onClick={() => {
                      setLayoutToggle(!layoutToggle);
                    }}
                  >
                    <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i>{" "}
                    |{" "}
                    <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                  </button>
                </div>
                {meData &&
                  !OperatingUsers.includes(meData.me.role) &&
                  !Operator.includes(meData.me.role) && (
                    <button
                      type="button"
                      className="waves-effect btn btn-outline-secondary button-add-bank"
                      onClick={() => {
                        setAddAccount(true);
                      }}
                    >
                      <i className="bx bx-plus font-size-15 align-middle mr-2"></i>
                      New Bank Account
                    </button>
                  )}
              </div>

              <div className={`reload-container ${newData ? "active" : ""}`}>
                <Card>
                  <CardBody
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    <div className="reload-title">
                      <i className="bx bx-refresh"></i>Reload
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>
            {!layoutToggle && data && (
              <Card className="w-100">
                <InfiniteScroll
                  dataLength={data.bankAccounts.edges.length} //This is important field to render the next data
                  next={() => setIsFetchMore(true)}
                  hasMore={pageInfo.hasNextPage}
                  loader={
                    <div className="text-center my-3">
                      <Link to="#" className="text-success">
                        <i className="bx bx-hourglass bx-spin mr-2"></i> Load
                        more{" "}
                      </Link>
                    </div>
                  }
                  endMessage={
                    <div className="text-center my-3">
                      <div>Yay! You have seen it all</div>
                    </div>
                  }
                >
                  <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead>
                        <tr className="font-size-12">
                          <Header
                            name="Account Name (No)"
                            className=""
                            type="accountName"
                          />
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <Header
                                name="Balance"
                                className=""
                                type="balance"
                              />
                            )}
                          <th></th>
                          <th>Bank</th>
                          <th>Country</th>
                          <th>Deposit</th>
                          <th>Withdraw</th>
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <th>Active</th>
                            )}
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {data &&
                          data.bankAccounts.edges.map((bankAccount, key) => (
                            <BankAccountTableItem
                              bank={bankAccount}
                              key={"_account_" + key}
                              onEdit={() => {
                                if (
                                  meData &&
                                  !OperatingUsers.includes(meData.me.role) &&
                                  !Operator.includes(meData.me.role)
                                ) {
                                  setEditAccount(bankAccount.node);
                                }
                              }}
                              onIncrement={() => {
                                setBankAccount(bankAccount.node);
                                setMovementType("increment");
                              }}
                              onDecrement={() => {
                                setBankAccount(bankAccount.node);
                                setMovementType("decrement");
                              }}
                              onTransfer={() => {
                                setBankAccount(bankAccount.node);
                                setMovementType("transfer");
                              }}
                              onToggle={() => {
                                setToggleBank(bankAccount);
                                setIsToggleBank(true);
                              }}
                              onToggleDeposit={() => {
                                setToggleBank(bankAccount);
                                setIsToggleDeposit(true);
                              }}
                              onToggleWithdraw={() => {
                                setToggleBank(bankAccount);
                                setIstoggleWithdraw(true);
                              }}
                            />
                          ))}
                        {data && data.bankAccounts.edges.length === 0 && (
                          <tr className="text-center pt-3">
                            <td colSpan={12}>{t("No Bank Account Found")}</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </InfiniteScroll>
              </Card>
            )}
            {layoutToggle && (
              <InfiniteScroll
                dataLength={data.bankAccounts.edges.length} //This is important field to render the next data
                next={() => setIsFetchMore(true)}
                hasMore={pageInfo.hasNextPage}
                loader={
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin mr-2"></i> Load more{" "}
                    </Link>
                  </div>
                }
                endMessage={
                  <div className="text-center my-3">
                    <div>Yay! You have seen it all</div>
                  </div>
                }
              >
                <Row>
                  <Col lg="12">
                    <Row>
                      {data &&
                        data.bankAccounts.edges.map((bankAccount, key) => (
                          <AccountItem
                            bank={bankAccount}
                            key={"_account_" + key}
                            onEdit={() => {
                              if (
                                meData &&
                                !OperatingUsers.includes(meData.me.role) &&
                                !Operator.includes(meData.me.role)
                              ) {
                                setEditAccount(bankAccount.node);
                              }
                            }}
                            onIncrement={() => {
                              setBankAccount(bankAccount.node);
                              setMovementType("increment");
                            }}
                            onDecrement={() => {
                              setBankAccount(bankAccount.node);
                              setMovementType("decrement");
                            }}
                            onTransfer={() => {
                              setBankAccount(bankAccount.node);
                              setMovementType("transfer");
                            }}
                          />
                        ))}
                      {data && data.bankAccounts.edges.length === 0 && (
                        <Col xl="12" sm="12">
                          <div className="pt-1 text-center">
                            No Bank Account Found
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                </Row>
              </InfiniteScroll>
            )}
            <div className="load-more-container">
              {loading && (
                <Col xs="12">
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-hourglass bx-spin mr-2"></i> Load more{" "}
                    </Link>
                  </div>
                </Col>
              )}
            </div>
            {addAccount && (
              <NewBankAccount
                modal={addAccount}
                toggleModal={() => {
                  refetch();
                  setAddAccount(!addAccount);
                }}
              />
            )}
            {!_.isEmpty(editAccount) && (
              <EditBankAccount
                modal={!_.isEmpty(editAccount)}
                toggleModal={() => {
                  setEditAccount(!editAccount);
                }}
                account={editAccount}
              />
            )}
          </Container>
        </div>
      }
      {movementType && (
        <AccountInOut
          modal={movementType}
          toggleModal={() => {
            setMovementType(null);
          }}
          fromBankAccount={bankAccount}
          type={movementType}
          onDone={() => {
            setMovementType(null);
            setBankAccount(null);
            refetch();
          }}
        />
      )}
      {isToggleBank && (
        <SweetAlert
          info
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={"Alert"}
          onConfirm={() => {
            setIsToggleBank(false);
            ToggleBankAccount(toggleBank);
          }}
          onCancel={() => setIsToggleBank(false)}
        >
          {`Are you sure you want to ${
            toggleBank.node.isActive ? "DEACTIVATE" : "ACTIVATE"
          } ${toggleBank.node.accountName}?`}
        </SweetAlert>
      )}
      {isToggleBank && (
        <SweetAlert
          info
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={"Alert"}
          onConfirm={() => {
            setIsToggleBank(false);
            ToggleBankAccount(toggleBank);
          }}
          onCancel={() => setIsToggleBank(false)}
        >
          {`Are you sure you want to ${
            toggleBank.node.isActive ? "DEACTIVATE" : "ACTIVATE"
          } ${toggleBank.node.accountName}?`}
        </SweetAlert>
      )}
      {isToggleDeposit && (
        <SweetAlert
          info
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={"Alert"}
          onConfirm={() => {
            setIsToggleDeposit(false);
            ToggleDeposit(toggleBank);
          }}
          onCancel={() => setIsToggleDeposit(false)}
        >
          {`Are you sure you want to ${
            toggleBank.node.canDeposit ? "DISABLE" : "ENABLE"
          } deposit ${toggleBank.node.accountName}?`}
        </SweetAlert>
      )}
      {istoggleWithdraw && (
        <SweetAlert
          info
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={"Alert"}
          onConfirm={() => {
            setIstoggleWithdraw(false);
            ToggleWithdraw(toggleBank);
          }}
          onCancel={() => setIstoggleWithdraw(false)}
        >
          {`Are you sure you want to ${
            toggleBank.node.canWithdraw ? "DISABLE" : "ENABLE"
          } withdrawal ${toggleBank.node.accountName}?`}
        </SweetAlert>
      )}
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType, ...state.BankAccounts };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(
  withTranslation()(BankAccountsList)
);

// export default ContactsList;

import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';
import EasyEdit from 'react-easy-edit';
import { UPDATE_ONE_SETTING, RESTORE_DEFAULT_SETTING } from '../../graphql/mutation';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { ME } from '../../graphql/query';
import SweetAlert from 'react-bootstrap-sweetalert';
import Switch from "react-switch";

function SettingItem(props) {

    const { setting, t, onEdit, onDelete } = props;
    const [updateOneSetting] = useMutation(UPDATE_ONE_SETTING)
    const [value, setValue] = React.useState('')
    const { data: meData } = useQuery(ME)
    const [confirmRestore, setConfirmRestore] = React.useState(false)

    const [restoreDefault] = useMutation(RESTORE_DEFAULT_SETTING)

    React.useEffect(() => {
        if (setting) {
            if (setting.node.fieldType === 'Boolean') {
                setValue(setting.node.value === 'true' ? true : false)
            } else {
                setValue(setting.node.value)
            }
        }
    }, [setting])

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const options = {
        "buttonList": [
            [
                "undo",
                "redo",
                "font",
                "fontSize",
                "formatBlock",
                "paragraphStyle",
                "bold",
                "underline",
                "italic",
                "strike",
                "fontColor",
                "hiliteColor",
                "textStyle",
                "removeFormat",
                "outdent",
                "indent",
                "align",
                "horizontalRule",
                "list",
            ]
        ]
    }

    const onSave = (value) => {
        updateOneSetting({
            variables: {
                input: {
                    update: {
                        value: value
                    },
                    id: setting.node.id
                }
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Update Succesfully!",
                    title: "",
                    onClick: function () { }
                })
            }
        })
    }

    return (
        <React.Fragment>
            <tbody className="table-body">
                <tr className="font-size-12">
                    <td className="relative cursor-pointer" width="15%" onClick={() => onEdit(setting)}>
                        <div>{setting.node.description}</div>
                    </td>
                    <td className="relative cursor-pointer" width="15%" onClick={() => onEdit(setting)}>
                        <div>{setting.node.key}</div>
                    </td>
                    <td className="relative cursor-pointer" width="10%" onClick={() => onEdit(setting)}>
                        <div>{setting.node.fieldType}</div>
                    </td>
                    <td className="relatives" width="45%">
                        <div className="align-center align-right line-2">
                            {
                                setting.node.fieldType === 'TextField' &&
                                <EasyEdit
                                    value={value}
                                    type="text"
                                    onSave={onSave}
                                    saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                                    cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                                    saveButtonStyle="easy-edit-button-save"
                                    cancelButtonStyle="easy-edit-button-cancel"
                                />
                            }
                            {
                                setting.node.fieldType === 'TextArea' &&
                                <EasyEdit
                                    displayComponent={
                                        <textarea disabled>{value}</textarea>
                                    }
                                    value={value}
                                    type="textarea"
                                    onSave={onSave}
                                    saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                                    cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                                    saveButtonStyle="easy-edit-button-save"
                                    cancelButtonStyle="easy-edit-button-cancel"
                                />
                            }
                            {
                                setting.node.fieldType === 'RichText' &&
                                <EasyEdit
                                    disableAutoSubmit
                                    value={value}
                                    type="text"
                                    displayComponent={
                                        <div dangerouslySetInnerHTML={{ __html: value }} />
                                    }
                                    editComponent={
                                        <SunEditor
                                            setContents={value}
                                            setOptions={options}
                                            onChange={(val) => {
                                                setValue(val)
                                            }}
                                        />
                                    }
                                    onSave={onSave}
                                    saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                                    cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                                    saveButtonStyle="easy-edit-button-save"
                                    cancelButtonStyle="easy-edit-button-cancel"
                                />
                            }
                            {
                                setting.node.fieldType === 'Boolean' &&
                                <Switch
                                    uncheckedIcon={<Offsymbol text="NO" />}
                                    checkedIcon={<OnSymbol text="YES" />}
                                    className="mr-1 pl-0"
                                    onColor="#34c38f"
                                    offColor="#f46a6a"
                                    onChange={() => {
                                        onSave((!value).toString())
                                    }}
                                    checked={value}
                                />
                            }
                            {
                                setting.node.fieldType === 'File' &&
                                <img
                                    alt="img"
                                    data-dz-thumbnail=""
                                    height="70"
                                    className="avatar-sm rounded bg-light pointer"
                                    src={value}
                                    onClick={() => {
                                        onEdit(setting)
                                    }}
                                />
                            }
                            {
                                setting.node.fieldType === 'Video' &&
                                <img
                                    alt="img"
                                    data-dz-thumbnail=""
                                    height="70"
                                    className="avatar-sm rounded bg-light pointer"
                                    src={value.replace('.mp4', '.jpg')}
                                    onClick={() => {
                                        onEdit(setting)
                                    }}
                                />
                            }
                        </div>
                    </td>
                    {
                        meData.me.role === "Developer" &&
                        <td className="relative cursor-pointer" width="10%">
                            {/* <div>{setting.node.developerOnly ? 'TRUE' : "FALSE"}</div> */}
                            <Switch
                                uncheckedIcon={<Offsymbol text="NO" />}
                                checkedIcon={<OnSymbol text="YES" />}
                                className="mr-1"
                                onColor="#34c38f"
                                offColor="#f46a6a"
                                onChange={() => {
                                    updateOneSetting({
                                        variables: {
                                            input: {
                                                update: {
                                                    developerOnly: !setting.node.developerOnly
                                                },
                                                id: setting.node.id
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }
                                    })
                                }}
                                checked={setting.node.developerOnly}
                            />
                        </td>
                    }
                    {
                        (meData.me.role === "Developer" ||
                            meData.me.role === "Admin") &&
                        <td>
                            <button type="button" className="btn btn-secondary waves-effect waves-light font-size-11 align-center ml-1" onClick={() => {
                                setConfirmRestore(true)
                            }}>
                                <div>{t('Restore')}</div>
                            </button>
                        </td>
                    }
                    {
                        meData.me.role === "Developer" &&
                        <td>
                            <button type="button" className="btn btn-danger waves-effect waves-light font-size-11 align-center ml-1" onClick={() => {
                                onDelete(setting)
                            }}>
                                <div>{t('Delete')}</div>
                            </button>
                        </td>
                    }
                </tr>
            </tbody>
            {
                confirmRestore &&
                <SweetAlert
                    error
                    showCancel
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    title={'Alert'}
                    onConfirm={() => {
                        setConfirmRestore(false)
                        restoreDefault({
                            variables: {
                                id: setting.node.id
                            }
                        }).catch(e => {
                            ToastHelper.toggleToast({
                                toastType: "error",
                                message: e,
                                title: "",
                                onClick: function () { }
                            })
                        }).then(val => {
                            if (val) {
                                ToastHelper.toggleToast({
                                    message: "Update Succesfully!",
                                    title: "",
                                    onClick: function () { }
                                })
                            }
                        })
                    }}
                    onCancel={() => setConfirmRestore(false)}
                >
                    {`This will restore to default value, are you sure?`}
                </SweetAlert>
            }
        </React.Fragment >
    );
}

export default SettingItem;
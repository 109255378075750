import gql from 'graphql-tag';

export const GET_TRANSACTION_AGGREGATE = gql`
query (
    $filter: TransactionAggregateFilter
) {
    transactionAggregate (
        filter: $filter
    ) {
        sum {
            amount
        }
    }
}
`;
import moment from 'moment';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';
import { RECALCULATE_WALLET_BALANCE, UPDATE_ONE_WALLET } from '../../graphql/mutation';
import { FIND_OR_CREATE_WEEKLY_ATTENDANCE_WALLET } from '../../graphql/mutation/FIND_OR_CREATE_WEEKLY_ATTENDANCE_WALLET';
import { ME } from '../../graphql/query';
import ToastHelper from '../../helpers/ToastHelper';

function WeeklyAttendanceWallet(props) {
    const { player, onMovement, onUpdateWalletId, onRefetch, onRefetchComplete } = props

    const ref = React.useRef(null);

    const [isEditDate, setIsEditDate] = React.useState(false)
    const [startDate, setStartDate] = React.useState(
        new Date()
    );

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    const [getWeeklyAttendanceWallet, { data, loading }] = useMutation(FIND_OR_CREATE_WEEKLY_ATTENDANCE_WALLET, {
        variables: {
            userId: player.id
        }
    })

    const [updateOneWallet] = useMutation(UPDATE_ONE_WALLET)

    const [recalculateWalletBalance, { loading: loadingBalance }] = useMutation(RECALCULATE_WALLET_BALANCE)

    React.useEffect(() => {
        getWeeklyAttendanceWallet()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (onRefetch === 'weekly') {
            getWeeklyAttendanceWallet().then(() => {
                onRefetchComplete()
            })
        }
    }, [onRefetch]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsEditDate(false)
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return (
        <React.Fragment>
            {
                loading &&
                <div className="text-center mb-3">
                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Loading</Link>
                </div>
            }
            {
                !loading &&
                data &&
                <div className="stat-component">
                    <div className="border p-3 rounded">
                        <div className="d-flex align-items-center mb-1">
                            <div className="avatar-xs mr-3">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18 cursor-pointer" onClick={() => {
                                    let path = `wallet-details/${player.id}/${data.findOrCreateWeeklyAttendanceCreditWallet.id}`
                                    window.open(path, "_blank")
                                }}>
                                    <i className="bx bx-wallet"></i>
                                </span>
                            </div>
                            <div>
                                <p className="mb-1">Weekly Attendance Wallet</p>
                                <h5>${parseFloat(data.findOrCreateWeeklyAttendanceCreditWallet.balance).toFixed(2)}</h5>
                            </div>
                        </div>
                        <div className="text-muted mt-2">
                            <p className="mb-1">Expired At</p>
                            <h5>{moment(data.findOrCreateWeeklyAttendanceCreditWallet.expiredAt).format('YYYY-MM-DD')} {moment(data.findOrCreateWeeklyAttendanceCreditWallet.expiredAt).diff(moment(), 'days') > 0 ? `(${moment(data.findOrCreateWeeklyAttendanceCreditWallet.expiredAt).diff(moment(), 'days')} days ${moment(data.findOrCreateWeeklyAttendanceCreditWallet.expiredAt).diff(moment(), 'hours') % 24} hours left)` : moment(data.findOrCreateWeeklyAttendanceCreditWallet.expiredAt).diff(moment(), 'hours') >= 0 ? `(${moment(data.findOrCreateWeeklyAttendanceCreditWallet.expiredAt).diff(moment(), 'hours')} hours left)` : '(Expired)'}  <i className="bx bxs-edit cursor-pointer font-size-18 align-middle ml-2" onClick={() => setIsEditDate(!isEditDate)}></i> </h5>
                            {
                                isEditDate &&
                                <div className="wallet-date-picker" ref={ref}>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date => {
                                            setStartDate(date)
                                            // setIsEditDate(false)
                                            updateOneWallet({
                                                variables: {
                                                    input: {
                                                        update: {
                                                            // expiredAt: moment(date).zone("+08:00").toISOString()
                                                            expiredAt: `${moment(date).format('YYYY-MM-DD')}T15:59:59.999Z`
                                                        },
                                                        id: data.findOrCreateWeeklyAttendanceCreditWallet.id
                                                    }
                                                }
                                            }).catch(e => {
                                                ToastHelper.toggleToast({
                                                    toastType: "error",
                                                    message: e,
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            }).then(val => {
                                                if (val) {
                                                    ToastHelper.toggleToast({
                                                        message: "Update Succesfully!",
                                                        title: "",
                                                        onClick: function () { }
                                                    })
                                                }

                                                getWeeklyAttendanceWallet()
                                            })
                                        }}
                                        // selectsStart
                                        inline
                                    />
                                </div>
                            }
                        </div>
                        <div className="text-muted mt-2">
                            <p className="mb-1">Actions</p>
                            <div className="align-center">
                                <button className="btn btn-primary waves-effect waves-light font-size-14 action-button btn-sm" onClick={() => {
                                    recalculateWalletBalance({
                                        variables: {
                                            id: data.findOrCreateWeeklyAttendanceCreditWallet.id
                                        }
                                    })
                                }}>
                                    {
                                        !loadingBalance &&
                                        <i className="bx bx-sync font-size-14"></i>
                                    }
                                    {
                                        loadingBalance &&
                                        <i className="fas fa-spinner fa-pulse font-size-13"></i>
                                    }
                                </button>
                                {
                                    meData &&
                                    !OperatingUsers.includes(meData.me.role) &&
                                    !Operator.includes(meData.me.role) &&
                                    <>
                                        <div className="mr-2"></div>
                                        <button className="btn btn-primary font-size-14 btn-sm" onClick={() => {
                                            onUpdateWalletId(data.findOrCreateWeeklyAttendanceCreditWallet.id)
                                            onMovement('WAPGenerated')
                                        }}>
                                            <i className="bx bx-plus"></i>
                                        </button>
                                    </>
                                }
                                {
                                    meData &&
                                    !OperatingUsers.includes(meData.me.role) &&
                                    !Operator.includes(meData.me.role) &&
                                    <>
                                        <div className="mr-2"></div>
                                        <button className="btn btn-primary font-size-14 btn-sm" onClick={() => {
                                            onUpdateWalletId(data.findOrCreateWeeklyAttendanceCreditWallet.id)
                                            onMovement('WAPVoided')
                                        }}>
                                            <i className="bx bx-minus"></i>
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="text-muted mt-3 action-container">
                            <Link to={`wallet-details/${player.id}/${data.findOrCreateWeeklyAttendanceCreditWallet.id}`} target="_blank" className="btn btn-primary btn-sm">View more <i className="mdi mdi-arrow-right ml-1"></i></Link>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default connect()(withTranslation()(WeeklyAttendanceWallet));
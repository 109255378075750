import React, { useRef } from 'react';
import { Row, Col, Nav, NavLink, Card, CardBody, NavItem, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import ReferralList from './referral-list';
import { useQuery } from 'react-apollo';
import { ME } from '../../graphql/query';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';

function Referral(props) {

    const { t } = props;
    const [tabType, setTabType] = React.useState('New')
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <div className="page-content referral">
                    <div className="search-bar-container">
                        <div className="button-add-banner-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    <Row className="pb-4">
                        <Col lg="12" >
                            <Card>
                                <CardBody>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabType === 'New'
                                                })}
                                                onClick={() => {
                                                    setTabType('New');
                                                }}
                                            >{t('New')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabType === 'Assigned'
                                                })}
                                                onClick={() => {
                                                    setTabType('Assigned');
                                                }}
                                            >{t('Assigned')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabType === 'Paid'
                                                })}
                                                onClick={() => {
                                                    setTabType('Paid');
                                                }}
                                            >{t('Paid')}</NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: tabType === "NoReferrer"
                                                })}
                                                onClick={() => {
                                                    setTabType("NoReferrer");
                                                }}
                                            >{t('No Referral')}</NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent className="mt-4">
                                        <TabPane>
                                            <ReferralList
                                                tabType={tabType}
                                                search={search}
                                            />
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(Referral));

// export default ContactsList;
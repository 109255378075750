import React from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";

// Import Routes
import {
  authProtectedRoutes,
  operatorRoute,
  publicRoutes,
  supervisorRoute,
} from "./routes/";
import AppRoute from "./routes/route";

// layouts
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import VerticalLayout from "./components/VerticalLayout/";
// Import scss
import "./assets/scss/theme.scss";

import { useMutation, useQuery, useSubscription } from "@apollo/react-hooks";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Import fackbackend Configuration file

import {
  OperatingUsers,
  Operator,
  Supervisor,
} from "./assets/scss/role.constant";
import { FIND_OR_CREATE_ONE_SETTING } from "./graphql/mutation";
import { GET_TRANSACTIONS_TOTAL_COUNT, ME } from "./graphql/query";
import { GET_TRANSACTIONS } from "./graphql/query/GET_TRANSACTIONS";
import { CREATED_USER } from "./graphql/subscription/CREATED_USER";
import ToastHelper from "./helpers/ToastHelper";

// Activating fake backend
// fakeBackend();

// Activating fake firebase
// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);

require("dotenv").config({ path: "/sample.env" });

function App(props) {
  const [audioTune, setAudioTune] = React.useState(null);
  const { data: userCreatedData } = useSubscription(CREATED_USER);
  // const { data: newTelegramData } = useSubscription(NEW_TELEGRAM)

  const { refetch: refetchPending } = useQuery(GET_TRANSACTIONS_TOTAL_COUNT, {
    variables: {
      filter: {
        transactionType: { in: ["Deposit", "Withdrawal"] },
        transactionStatus: { in: ["Pending"] },
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: meData } = useQuery(ME, {
    fetchPolicy: "cache-first",
  });

  const [getAudio, { data: audio }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
    variables: {
      input: {
        description: "New Transaction Audio",
        key: "newTransactionAudio",
        settingType: "CompanyInfo",
        fieldType: "File",
      },
    },
  });

  const maybePluralize = (count, noun, suffix = "s") =>
    `${count} Pending ${noun}${count !== 1 ? suffix : ""}`;

  useQuery(GET_TRANSACTIONS, {
    variables: {
      filter: {
        transactionType: { eq: "Deposit" },
        transactionStatus: { in: ["Pending", "Viewed"] },
      },
      paging: {
        first: 5,
      },
      sorting: [{ field: "createdAt", direction: "ASC" }],
    },
    pollInterval: 15000,
  });

  useQuery(GET_TRANSACTIONS, {
    variables: {
      filter: {
        transactionType: { eq: "Withdrawal" },
        transactionStatus: { in: ["Pending", "Viewed"] },
      },
      paging: {
        first: 5,
      },
      sorting: [{ field: "createdAt", direction: "ASC" }],
    },
    pollInterval: 15000,
  });

  useQuery(GET_TRANSACTIONS, {
    variables: {
      filter: {
        transactionType: { in: ["Withdrawal", "Deposit"] },
        transactionStatus: { eq: "OnHold" },
      },
      paging: {
        first: 5,
      },
      sorting: [{ field: "createdAt", direction: "ASC" }],
    },
    pollInterval: 15000,
  });

  React.useEffect(() => {
    if (meData) {
      getAudio();
    }
    // newTelegramTune.load();
  }, [meData]); // eslint-disable-line react-hooks/exhaustive-deps

  React.useEffect(() => {
    if (audio) {
      setAudioTune(new Audio(audio.findOrCreateSetting.value));
    } else {
      setAudioTune(new Audio(require("./assets/underground.mp3")));
    }
  }, [audio]);

  React.useEffect(() => {
    if (audioTune && audio) {
      LoadAudio();
    }
  }, [audioTune]); // eslint-disable-line react-hooks/exhaustive-deps

  const LoadAudio = async () => {
    audioTune.pause();
    audioTune.load();
    setInterval(() => {
      if (
        window.localStorage.getItem("AUTH_TOKEN") !== "" &&
        window.localStorage.getItem("AUTH_TOKEN") !== null
      ) {
        refetchPending().then((val) => {
          if (val.data.transactions.totalCount > 0) {
            audioTune.play();
            ToastHelper.toggleToast({
              toastType: "info",
              message: `${maybePluralize(
                val.data.transactions.totalCount,
                "Transaction"
              )}`,
              title: "",
              onClick: function () {
                window.location = "/dashboard";
              },
            });
          }
        });
      }
    }, 15000);
  };

  React.useEffect(() => {
    if (userCreatedData) {
      ToastHelper.toggleToast({
        toastType: "info",
        message: `Added new user - ${userCreatedData.createdUser.name}`,
        title: "",
        onClick: function () {
          window.location = "/contacts-list";
        },
      });
    }
  }, [userCreatedData]);

  // React.useEffect(() => {
  // 	if (newTelegramData) {
  // 		newTelegramTune.play();
  // 		ToastHelper.toggleToast({
  // 			toastType: "info",
  // 			message: `Added new user - ${newTelegramData.createdTelegramUser.name}`,
  // 			title: "",
  // 			onClick: function () { window.location = "/contacts-list"; }
  // 		})
  // 	}
  // }, [newTelegramData])

  const getLayout = () => {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };

  const Layout = getLayout();

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {meData &&
            (OperatingUsers.includes(meData.me.role) ||
              Operator.includes(meData.me.role)) &&
            operatorRoute.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}

          {meData &&
            Supervisor.includes(meData.me.role) &&
            supervisorRoute.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}

          {meData &&
            !OperatingUsers.includes(meData.me.role) &&
            !Operator.includes(meData.me.role) &&
            !Supervisor.includes(meData.me.role) &&
            authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
              />
            ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);

import React, { Fragment } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Button,
    FormGroup
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { CREATE_ONE_BANK } from '../../graphql/mutation';
import { useQuery } from '@apollo/react-hooks';
import { GET_COUNTRY } from '../../graphql/query';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import Compressor from 'compressorjs';
import CloudinaryHelper from '../../helpers/CloudinaryHelper';
import DropZone from '../Workbench/Components/drop-zone';

export default function NewBank(props) {

    const { modal, toggleModal } = props;

    const [search, setSearch] = React.useState('');
    const [isValidCountry, setIsValidCountry] = React.useState(true);
    const [country, setCountry] = React.useState([]);
    const [selectedFile, setSelectFile] = React.useState(null);
    const [headerColor, setHeaderColor] = React.useState('#FCBC2F');
    const [titleColor, setTitleColor] = React.useState('#556EE6');

    const { data: countryData, loading: loadingCountry } = useQuery(GET_COUNTRY, {
        variables: {
            filter: search === '' ? {} : {
                name: { iLike: `%${search}%` }
            }
        },
        notifyOnNetworkStatusChange: true
    });

    // const { data: currencyData, loading: loadingCurrency } = useQuery(GET_CURRENCY, {
    //     variables: {
    //         filter: currencySearch === '' ? {} : {
    //             name: { iLike: `%${currencySearch}%` }
    //         }
    //     },
    //     notifyOnNetworkStatusChange: true
    // });

    const [createOneBank] = useMutation(CREATE_ONE_BANK);

    React.useEffect(() => {
        setSearch('')
        setIsValidCountry(true)
        setCountry([])
    }, [modal])

    const handleCountrySearch = (query) => {
        setSearch(query)
    }

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-bank-page">
                    <ModalHeader toggle={toggleModal}>
                        Add New Bank
                        </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    if (country.length > 0) {
                                        let result
                                        if (selectedFile) {
                                            let imageData = await Compress(selectedFile)
                                            result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=vmuqed1e`)
                                        }
                                        await createOneBank({
                                            variables: {
                                                input: {
                                                    bank: {
                                                        name: values.bankName,
                                                        countryId: country[0].node.id,
                                                        headerColor: headerColor,
                                                        titleColor: titleColor,
                                                        iconUrl: selectedFile ? result.data.url : null,
                                                    }
                                                }
                                            }
                                        }).catch(e => {
                                            ToastHelper.toggleToast({
                                                toastType: "error",
                                                message: e,
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }).then(val => {
                                            if (val) {
                                                toggleModal()
                                            }
                                        })
                                    } else {
                                        if (country.length === 0) {
                                            setIsValidCountry(false)
                                        }
                                    }
                                }}
                                onInvalidSubmit={() => {
                                    if (country.length === 0) {
                                        setIsValidCountry(false)
                                    }
                                }}
                            >
                                <Row>
                                    <Col>
                                        <DropZone
                                            selectedFile={selectedFile}
                                            onFileDrop={(file) => {
                                                setSelectFile(file)
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup className={`${!isValidCountry ? 'text-danger' : ''}`}>
                                            <Label for="newrole-input">Country</Label>
                                            <AsyncTypeahead
                                                id="country-list"
                                                isLoading={loadingCountry}
                                                labelKey={option => `${option.node.name}`}
                                                minLength={0}
                                                onSearch={handleCountrySearch}
                                                onInputChange={(val) => {
                                                    if (val === "") {
                                                        handleCountrySearch('')
                                                    }
                                                }}
                                                options={countryData ? countryData.countries.edges : []}
                                                placeholder="Search for Country"
                                                renderMenuItemChildren={(option, props) => (
                                                    <Fragment>
                                                        <span>{option.node.name}</span>
                                                    </Fragment>
                                                )}
                                                errorMessage="Enter country name"
                                                isInvalid={!isValidCountry}
                                                onChange={(val) => {
                                                    setIsValidCountry(val.length > 0 ? true : false)
                                                    setCountry(val)
                                                }}
                                                onBlur={(e) => {
                                                    if (isValidCountry && country.length === 0) {
                                                        setIsValidCountry(false)
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            label="Bank Name"
                                            name="bankName"
                                            type="text"
                                            placeholder="Enter Bank Name"
                                            errorMessage="Enter bank name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    {/* <Col lg="6">
                                        <FormGroup className={`${!isValidCurrency ? 'text-danger' : ''}`}>
                                            <Label for="newrole-input">Currency</Label>
                                            <AsyncTypeahead
                                                id="currency-list"
                                                isLoading={loadingCurrency}
                                                labelKey={option => `${option.node.name}`}
                                                minLength={0}
                                                onSearch={handleCurrencySearch}
                                                onInputChange={(val) => {
                                                    if (val === "") {
                                                        handleCurrencySearch('')
                                                    }
                                                }}
                                                options={currencyData ? currencyData.currencies.edges : []}
                                                placeholder="Search for Currency"
                                                renderMenuItemChildren={(option, props) => (
                                                    <Fragment>
                                                        <span>{option.node.name}</span>
                                                    </Fragment>
                                                )}
                                                isInvalid={!isValidCountry}
                                                onChange={(val) => {
                                                    setIsValidCurrency(val.length > 0 ? true : false)
                                                    setCurrency(val)
                                                }}
                                                onBlur={(e) => {
                                                    if (isValidCurrency && currency.length === 0) {
                                                        setIsValidCurrency(false)
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col> */}
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label for="header-color-input">Header Color</Label>
                                            <div className="">
                                                <input name="headerColor" defaultValue={headerColor} className="form-control" type="color" id="header-color-input" onChange={(event) => {
                                                    setHeaderColor(event.target.value)
                                                }} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label for="header-color-input">Title Color</Label>
                                            <div className="">
                                                <input name="titleColor" defaultValue={titleColor} className="form-control" type="color" id="header-color-input" onChange={(event) => {
                                                    setTitleColor(event.target.value)
                                                }} />
                                            </div>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <div className="action-container">
                                    <Button color="primary" type="submit">Submit</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}
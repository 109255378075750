import gql from 'graphql-tag';

export const CurrencyFragment = gql`
    fragment CurrencyInfo on Currency {
        id
        name
        code
        number
        symbol
        isActive
        isArchived
        createdAt
        updatedAt
    }
`;
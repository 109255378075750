import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Button,
    FormGroup
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { CREATE_ONE_USER } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../graphql/query';
import _ from 'lodash';

export default function NewPlayer(props) {

    const { modal, toggleModal } = props;
    const [role, setRole] = React.useState('Player')

    const { data: roleData } = useQuery(GET_ENUM, {
        variables: {
            name: "Role"
        }
    });

    const [createOneUser] = useMutation(CREATE_ONE_USER);

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-user-page">
                    <ModalHeader toggle={toggleModal}>
                        Add New Player
                        </ModalHeader>
                    <ModalBody>
                        <div id="new-user-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation" onValidSubmit={(event, values) => {
                                let variables = {
                                    name: values.name,
                                    username: values.username,
                                    password: values.password,
                                    phoneNo: values.mobileNo,
                                    role: role
                                }

                                if (_.isEmpty(values.name)) {
                                    delete variables["name"]
                                }

                                if (_.isEmpty(values.username)) {
                                    delete variables["username"]
                                }

                                if (_.isEmpty(values.password)) {
                                    delete variables["password"]
                                }

                                if (_.isEmpty(values.mobileNo)) {
                                    delete variables["phoneNo"]
                                }

                                createOneUser({
                                    variables: {
                                        input: {
                                            user: variables
                                        }
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).then(val => {
                                    if (val) {
                                        toggleModal()
                                    }
                                })
                            }}>
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            label="Name"
                                            name="name"
                                            type="text"
                                            placeholder="Enter Name"
                                            errorMessage="Enter name"
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            label="Username"
                                            name="username"
                                            type="text"
                                            placeholder="Enter Username"
                                            errorMessage="Enter username"
                                            validate={{
                                                required: { value: role !== 'Player' },
                                                pattern: {
                                                    value: "^\\S*$",
                                                    errorMessage: "No Space"
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            name="mobileNo"
                                            label="Mobile Number"
                                            placeholder="Enter Mobile Number"
                                            type="text"
                                            errorMessage="Enter Only Number"
                                            validate={{
                                                pattern: {
                                                    value: "^[0-9]+$",
                                                    errorMessage: "Only Numbers"
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            label="Password"
                                            name="password"
                                            type="password"
                                            placeholder="Enter Password"
                                            errorMessage="Enter password"
                                            validate={{
                                                minLength: { value: 6, errorMessage: 'Your password must be between 6 and 13 characters' },
                                                maxLength: { value: 13, errorMessage: 'Your password must be between 6 and 13 characters' }
                                            }}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            label="Verify Password"
                                            name="verify-password"
                                            type="password"
                                            placeholder="Enter Verify Password"
                                            errorMessage="Enter Re-password"
                                            validate={{
                                                match: { value: "password" }
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12">
                                        <FormGroup>
                                            <Label for="newrole-input">Role</Label>
                                            <select disabled name="role" className="custom-select" id="newrole-input" defaultValue={role} onChange={(e) => setRole(e.target.value)}>
                                                {
                                                    roleData &&
                                                    _.filter(roleData.__type.enumValues, ({ name }) => {
                                                        return name !== "Developer"
                                                    }).map(({ name }, index) => {
                                                        return (
                                                            <option key={index} value={name}>{name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <div className="action-container">
                                    <Button color="primary" type="submit">Submit</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}
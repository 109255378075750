import React from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";
import classnames from "classnames";
import Switch from "react-switch";
import { UPDATE_ONE_BANK } from '../../graphql/mutation';
import { useMutation } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';

function BankItem(props) {

    const { bank, onEdit } = props;

    const [drp_link, setDrpLink] = React.useState(false)
    const [isActive, setIsActive] = React.useState(false)
    const [updateOneBank] = useMutation(UPDATE_ONE_BANK);

    React.useEffect(() => {
        if (bank) {
            setIsActive(bank.node.isActive)
        }
    }, [bank])

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return (
        <React.Fragment>
            <Col xl="3" sm="6" lg="4">
                <Card>
                    <div style={{
                        backgroundColor: bank.node.headerColor ? `${bank.node.headerColor}CC` : '#FCBC2FCC',
                        borderTopLeftRadius: '0.25rem',
                        borderTopRightRadius: '0.25rem',
                    }}>
                        <Row className="text-align">
                            <Col>
                                <div className={
                                    classnames({
                                        'text-primary': true,
                                        'p-3': !bank.node.iconUrl,
                                        'p-1': bank.node.iconUrl
                                    })
                                }>
                                    <div className="align-center">
                                        <div className="text-align align-center cursor-pointer" onClick={onEdit}>
                                            {
                                                bank.node.iconUrl &&
                                                <img
                                                    className="ml-3 mr-3 mt-1 mb-1"
                                                    alt=""
                                                    src={bank.node.iconUrl}
                                                    width="40"
                                                    height="40"
                                                    style={{ borderRadius: 40, objectFit: "cover" }}
                                                />
                                            }
                                            <div className="bank-name" style={{
                                                color: bank.node.titleColor ? bank.node.titleColor : '#556EE6'
                                            }}>{bank.node.name}</div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <CardBody className="">
                        <Row>
                            <Col className="text-align pb-0 cursor-pointer" onClick={onEdit}>
                                <div>
                                    <div className="header">COUNTRY NAME</div>
                                    <div className="font-size-14"><Link to="#" className="text-dark">{bank.node.country.name.toUpperCase()}</Link></div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="header pt-2">Active?</div>
                                <Switch
                                    id="is-active"
                                    onColor="#34c38f"
                                    offColor="#f46a6a"
                                    uncheckedIcon={<Offsymbol text="NO" />}
                                    checkedIcon={<OnSymbol text="YES" />}
                                    onChange={() => {
                                        updateOneBank({
                                            variables: {
                                                input: {
                                                    update: {
                                                        isActive: !isActive
                                                    },
                                                    id: bank.node.id
                                                }
                                            }
                                        }).catch(e => {
                                            ToastHelper.toggleToast({
                                                toastType: "error",
                                                message: e,
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }).then(val => {
                                            if (val) {
                                                ToastHelper.toggleToast({
                                                    message: "Update Succesfully!",
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            }
                                        })
                                        setIsActive(!isActive)
                                    }}
                                    checked={isActive}
                                />
                            </Col>
                            <Col className="dropdown-actions">
                                <ButtonDropdown
                                    isOpen={drp_link}
                                    toggle={() =>
                                        setDrpLink(!drp_link)
                                    }
                                >
                                    <DropdownToggle caret color="secondary">
                                        Actions <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={onEdit}>Edit Bank</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    );
}

export default BankItem;
import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { CREATE_ONE_REWARD_TIER } from '../../graphql/mutation/CREATE_ONE_REWARD_TIER';

function NewTier(props) {

    const { modal, toggleModal } = props;

    const [createOneTier] = useMutation(CREATE_ONE_REWARD_TIER);

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-bonus-page">
                    <ModalHeader toggle={toggleModal}>
                        Add New Tier
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    await createOneTier({
                                        variables: {
                                            input: {
                                                rewardTier: {
                                                    minDepositAmount: parseFloat(values.minDeposit),
                                                    maxDepositAmount: parseFloat(values.maxDeposit),
                                                    rewardMultiplier: parseFloat(values.rewardMultiplier),
                                                    isActive: true
                                                }
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Create Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })
                                }}
                                onInvalidSubmit={() => {

                                }}
                            >
                                <Row>
                                    <Col lg={6}>
                                        <AvField
                                            label="Min Deposit"
                                            name="minDeposit"
                                            type="text"
                                            placeholder="Enter Min Deposit"
                                            errorMessage="Enter Min Deposit"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="Max Deposit"
                                            name="maxDeposit"
                                            type="text"
                                            placeholder="Enter Min Deposit"
                                            errorMessage="Enter Min Deposit"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={12}>
                                        <AvField
                                            label="Reward Multiplier"
                                            name="rewardMultiplier"
                                            type="text"
                                            placeholder="Enter Reward Multiplier"
                                            errorMessage="Enter Reward Multiplier"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(NewTier));
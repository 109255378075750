import React from "react";
import { Row, Col, Input, Button } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm, AvField } from "availity-reactstrap-validation";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useQuery, useLazyQuery, useMutation } from "react-apollo";
import { GET_USER_WALLETS } from "../../../graphql/query/GET_USER_WALLETS";
import NumberFormat from "react-number-format";
import ToastHelper from "../../../helpers/ToastHelper";
import { showOverlayLoading } from "../../../store/actions";
import * as moment from "moment/moment";
import { GET_PLAYER_STAT } from "../../../graphql/query/GET_PLAYER_STAT";
import { CREATE_ONE_FREE_CREDIT } from "../../../graphql/mutation/CREATE_ONE_FREE_CREDIT";
import { FIND_OR_CREATE_WALLET_PAYMENT } from "../../../graphql/mutation";
import { GET_FREE_CREDIT } from "../../../graphql/query/GET_FREE_CREDIT";

let form;
let interval;

function BankerFreeCredit(props) {
  const { t, player, isActive, onDone } = props;

  const [isFreeCredit, setIsFreeCredit] = React.useState(false);
  const [currentTime, setCurrentTime] = React.useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );

  const { data: freeCreditData } = useQuery(GET_FREE_CREDIT, {
    variables: {
      filter: {
        isActive: { is: true },
      },
      sorting: {
        field: "name",
        direction: "ASC",
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const { data: dataStat } = useQuery(GET_PLAYER_STAT, {
    variables: {
      id: player.id,
    },
    fetchPolicy: "network-only",
  });

  const [getWallet, { data: walletData }] = useLazyQuery(GET_USER_WALLETS, {
    variables: {
      filter: {
        walletType: { eq: "Credit" },
        name: { eq: "Main" },
        user: { id: { eq: player.id } },
      },
    },
    fetchPolicy: "network-only",
  });

  const [createOneFreeCredit, { loading: loadingFreeCredit }] = useMutation(
    CREATE_ONE_FREE_CREDIT
  );
  const [findWallet, { data: dataWallet }] = useMutation(
    FIND_OR_CREATE_WALLET_PAYMENT
  );

  React.useEffect(() => {
    if (isActive) {
      findWallet();
      getWallet();
      interval = setInterval(() => {
        setCurrentTime(moment().format("YYYY-MM-DD HH:mm:ss"));
      }, 1000);
    } else {
      clearInterval(interval);
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  const FreeCredit = async (payload) => {
    await createOneFreeCredit({
      variables: {
        input: {
          freeCredit: payload,
        },
      },
    })
      .catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () {},
        });
      })
      .then((val) => {
        if (val) {
          setIsFreeCredit(false);
          form.reset();
          if (onDone) {
            onDone();
          }
        }
      });

    props.showOverlayLoading(false);
  };

  return (
    <React.Fragment>
      <div className="player-container">
        <div className="header-wrapper">
          <div className="player-title">Promotion</div>
        </div>
      </div>
      <div className="banker-withdraw-container p-2">
        <div className="banker-withdraw-wrapper">
          <AvForm
            className="banker-withdraw-content-info"
            ref={(ref) => (form = ref)}
            onValidSubmit={async (event, values) => {
              if (isFreeCredit) {
                return;
              }
              setIsFreeCredit(true);

              props.showOverlayLoading(true, "Loading");
              FreeCredit({
                amount: parseFloat(
                  values.amount.toString().replaceAll(",", "")
                ),
                remark: values.remark,
                paymentTypeId: dataWallet.findOrCreateWalletPaymentType.id,
                toWalletId: walletData.wallets.edges[0].node.id,
                playerId: player.id,
                freeCreditTypeId: values.freeCredit,
              });
            }}
          >
            <Row>
              <Col xs={12} sm={12} md={3} lg={3}>
                <AvField
                  defaultValue={player.serialNo}
                  label="Player"
                  name="depositor"
                  disabled
                  type="text"
                />
              </Col>
              <Col xs={12} sm={6} md={3} lg={3}>
                <AvField
                  value={
                    dataStat
                      ? `${parseFloat(
                          dataStat.playerStats.totalDepositAmount
                        ).toFixed(2)} (${parseFloat(
                          dataStat.playerStats.totalWithdrawalAmount
                        ).toFixed(2)})`
                      : "-"
                  }
                  label="Player Deposit/Withdraw"
                  name="win-loss"
                  disabled
                  type="text"
                />
              </Col>
              <Col xs={12} sm={6} md={6} lg={6}>
                <AvField
                  defaultValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                  value={isActive && currentTime}
                  label={"Transaction Created At"}
                  name="timestamp"
                  disabled
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={6} md={6} lg={6}>
                <AvField
                  label={t("Enter Amount")}
                  placeholder="Enter Amount"
                  errorMessage="Enter Amount"
                  name="amount"
                  type="text"
                  thousandSeparator
                  decimalScale={2}
                  fixedDecimalScale
                  tag={[Input, NumberFormat]}
                />
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <AvField
                  label="Promotion"
                  name="freeCredit"
                  type="select"
                  className="custom-select"
                  placeholder="Promotion"
                  errorMessage="Promotion"
                  value={
                    freeCreditData &&
                    freeCreditData.freeCreditTypes.edges.length > 0 &&
                    freeCreditData.freeCreditTypes.edges[0].node.id
                  }
                >
                  {freeCreditData &&
                    freeCreditData.freeCreditTypes.edges.map(
                      (freeCredit, index) => {
                        return (
                          <option key={index} value={freeCredit.node.id}>
                            {freeCredit.node.name}
                          </option>
                        );
                      }
                    )}
                </AvField>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <AvField
                  label="Remark"
                  name="remark"
                  type="text"
                  placeholder="Remark"
                  errorMessage="Enter Remark"
                  defaultValue={null}
                />
              </Col>
            </Row>
            <div className="submit-container">
              <Button color="primary" type="submit">
                {loadingFreeCredit && (
                  <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                )}
                {!loadingFreeCredit && <div>Submit</div>}
              </Button>
            </div>
          </AvForm>
        </div>
      </div>
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(
  withTranslation()(BankerFreeCredit)
);

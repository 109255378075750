
import React from 'react';
import Switch from "react-switch";
import { useMutation } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';
import { UDPATE_ONE_CURRENCY } from '../../graphql/mutation';

function CurrencyItem(props) {

    const { onEdit, currency } = props;

    const [updateOneCurrency] = useMutation(UDPATE_ONE_CURRENCY);

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return <React.Fragment>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(currency)
            }}>
                {currency.node.code}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(currency)
            }}>
                {currency.node.number}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(currency)
            }}>
                {currency.node.name}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(currency)
            }}>
                {currency.node.symbol}
            </div>
        </td>
        <td>
            <Switch
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                id="is-active"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={() => {
                    updateOneCurrency({
                        variables: {
                            input: {
                                update: {
                                    isActive: !currency.node.isActive
                                },
                                id: currency.node.id
                            }
                        }
                    }).catch(e => {
                        ToastHelper.toggleToast({
                            toastType: "error",
                            message: e,
                            title: "",
                            onClick: function () { }
                        })
                    }).then(val => {
                        if (val) {
                            ToastHelper.toggleToast({
                                message: "Update Succesfully!",
                                title: "",
                                onClick: function () { }
                            })
                        }
                    })

                }}
                checked={currency.node.isActive}
            />
        </td>
    </React.Fragment>
}

export default CurrencyItem;
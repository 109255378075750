import gql from 'graphql-tag';
import { BankAccountFragment } from '../fragments/bank.fragment';

export const RECALCULATE_BANK_BALANCE = gql`
    mutation (
        $id: String!
    ) {
        recalculateBankAccountBalance (
            id: $id
        ) {
            ...BankAccountInfo
        }
    }
    ${BankAccountFragment}
`;
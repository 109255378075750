import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Progress,
    Label,
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Compressor from 'compressorjs';
import CloudinaryHelper from "../../helpers/CloudinaryHelper"
import { FormGroup } from '@material-ui/core';
import DropZone from '../Workbench/Components/drop-zone';
import { CREATE_ONE_CATEGORY } from '../../graphql/mutation';

function NewCaterogy(props) {

    const { modal, toggleModal } = props;
    const [selectedFile, setSelectFile] = React.useState(null);
    const [fileError, setFileError] = React.useState(null);
    const [onUpload, setOnUpload] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    const [createOneCategory] = useMutation(CREATE_ONE_CATEGORY);

    React.useEffect(() => {
        setSelectFile(null)
        setFileError(null)
        setProgress(0)
        setOnUpload(false)
    }, [modal])

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-bonus-page">
                    <ModalHeader toggle={toggleModal}>
                        Add New Category
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={(event, values) => {
                                    if (selectedFile) {
                                        new Compressor(selectedFile, {
                                            quality: 1,
                                            async success(result) {
                                                setOnUpload(true)
                                                let data = await CloudinaryHelper.upload(result, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=v13sqvvy`, (progressEvent) => {
                                                    let val = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                                    setProgress(val)
                                                })
                                                setOnUpload(false)
                                                createOneCategory({
                                                    variables: {
                                                        input: {
                                                            category: {
                                                                name: values.categoryName,
                                                                logoUrl: data.data.secure_url,
                                                                slug: values.slug,
                                                                isActive: true
                                                            }
                                                        }
                                                    }
                                                }).catch(e => {
                                                    ToastHelper.toggleToast({
                                                        toastType: "error",
                                                        message: e,
                                                        title: "",
                                                        onClick: function () { }
                                                    })
                                                }).then(val => {
                                                    if (val) {
                                                        toggleModal()
                                                    }
                                                })
                                            },
                                            error(err) {
                                                ToastHelper.toggleToast({
                                                    toastType: "error",
                                                    message: err,
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            },
                                        });

                                    } else {
                                        setFileError(true)
                                    }
                                }}
                                onInvalidSubmit={() => {

                                }}
                            >
                                <Row>
                                    {
                                        <Col lg={12}>
                                            <DropZone
                                                selectedFile={selectedFile}
                                                onFileDrop={(file) => {
                                                    setSelectFile(file)
                                                }}
                                            />
                                        </Col>
                                    }
                                    {
                                        fileError &&
                                        !selectedFile &&
                                        <Col lg="12"><div className="image-url-error pb-2 font-size-10">* Please Provide an Image</div></Col>
                                    }
                                    {
                                        onUpload &&
                                        <Col lg={12}>
                                            <FormGroup className="mb-3">
                                                <Label>Uploading</Label>
                                                <Progress color="primary" value={progress}></Progress>
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col lg={6}>
                                        <AvField
                                            label="Category Name"
                                            name="categoryName"
                                            type="text"
                                            placeholder="Enter Category Name"
                                            errorMessage="Enter Category Name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="Slug"
                                            name="slug"
                                            type="text"
                                            placeholder="Enter Slug"
                                            errorMessage="Enter Slug"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    !onUpload &&
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(NewCaterogy));
import gql from 'graphql-tag';

export const MarketingParticipantFragment = gql`
    fragment MarketingCampaignParticipant on MarketingCampaignParticipant {
        marketingCampaignId
        participantId
        sentAt
        status
        createdAt
        updatedAt
        createdBy
        updatedBy
        remark
        participant {
            id
            username
            name
            telegramId
            phoneNo
            language
            firstDepositAt
            lastDepositAt
        }
    }
`;
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const GET_MARKETING_AVAILABLE_LIST = gql`
    query (
        $id: String!
    ) {
        marketingCampaignAvailableList (
            id: $id
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
import gql from "graphql-tag";
import { AttendanceFragment } from "../fragments/attendance.fragment";

export const UPDATE_ONE_ATTENDANCE_STEP = gql`
  mutation ($input: UpdateOneAttendanceStepInput!) {
    updateOneAttendanceStep(input: $input) {
      ...AttendanceInfo
    }
  }
  ${AttendanceFragment}
`;

import gql from 'graphql-tag';
import { BankFragment } from '../fragments/bank.fragment';

export const CREATED_BANK = gql`
    subscription {
        createdBank {
            ...BankInfo
        }
    }
    ${BankFragment}
`;
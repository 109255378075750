import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const CREATED_USER = gql`
    subscription {
        createdUser {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
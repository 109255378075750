import _ from 'lodash';
import { Component } from 'react';

export default class ErrorHelper extends Component {
    static checkUnauthorized = (error: any) => {
        let exception = error.graphQLErrors[0].extensions.exception
        if (exception.response.error === 'Unauthorized') {
            return true
        } else {
            return false
        }
    }
    static getGraphQLErrorOnly = (error: any) => {
        let exception = error.graphQLErrors[0].extensions.exception

        if (exception.response) {
            if (_.isArray(exception.response.message)) {
                return exception.response.message.join('\n').replace(/(^\w{1})|(\n{1}\w{1})/g, (match: any) => match.toUpperCase())
            } else {
                return exception.response.message
            }
        } else if (exception.message) {
            return exception.message
        } else if (error.graphQLErrors[0].message) {
            return error.graphQLErrors[0].message
        }
    }
}
import React, { Fragment } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Button,
    FormGroup
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { UPDATE_ONE_BANK_ACCOUNT } from '../../../graphql/mutation';
import ToastHelper from '../../../helpers/ToastHelper';
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import { GET_COUNTRY, GET_BANKS, GET_USER } from '../../../graphql/query';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

function EditPlayerBankAccount(props) {

    const { modal, toggleModal, t, player, playerKey, account } = props;

    const [isValidCountry, setIsValidCountry] = React.useState(true);
    const [isValidBank, setIsValidBank] = React.useState(true);
    const [country, setCountry] = React.useState([]);
    const [bank, setBank] = React.useState([])

    const [search, setSearch] = React.useState('');
    const [bankSearch, setBankSearch] = React.useState('');

    const [getUser] = useLazyQuery(GET_USER, {
        fetchPolicy: 'network-only'
    })

    const { data: countryData, loading: loadingCountry, refetch: refetchCountry } = useQuery(GET_COUNTRY, {
        variables: {
            filter: search === '' ? {} : {
                name: { iLike: `%${search}%` }
            }
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    const { data: bankData, loading: loadingBank, refetch: refetchBank } = useQuery(GET_BANKS, {
        variables: {
            sorting: {
                field: "createdAt",
                direction: "DESC"
            },
            filter: {
                and: [
                    { name: { iLike: `%${bankSearch}%` } },
                    { country: { name: { iLike: `%${country.length > 0 ? country[0].node.name : ''}%` } } }
                ]
            }
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    });

    const [updateOneBankAccount] = useMutation(UPDATE_ONE_BANK_ACCOUNT);

    React.useEffect(() => {
        if (modal) {
            setCountry([{
                node: account.node.bank.country
            }])
            setBank([{
                node: account.node.bank
            }])
            setIsValidCountry(true)
            setIsValidBank(true)
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        refetchBank()
    }, [country]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        refetchCountry()
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    const handleCountrySearch = (query) => {
        setSearch(query)
    }

    const handleBankSearch = (query) => {
        setBankSearch(query)
    }

    const RefetchUser = () => {
        getUser({
            variables: {
                filter: {
                    and: [
                        {
                            or: [
                                { username: { eq: `${playerKey}` } },
                                { phoneNo: { eq: `${playerKey}` } },
                            ]
                        },
                        {
                            role: { eq: "Player" }
                        }
                    ]
                }
            }
        })
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-account-page">
                    <ModalHeader toggle={toggleModal}>
                        {t('Edit Bank Account')}
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-account-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={(event, values) => {
                                    if (country.length > 0 && bank.length > 0) {
                                        updateOneBankAccount({
                                            variables: {
                                                input: {
                                                    update: {
                                                        bankId: bank[0].node.id,
                                                        accountName: values.accountName,
                                                        accountNo: values.accountNo,
                                                        accountHolderType: "Player",
                                                        playerId: player.id
                                                    },
                                                    id: account.node.id
                                                }
                                            }
                                        }).catch(e => {
                                            ToastHelper.toggleToast({
                                                toastType: "error",
                                                message: e,
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }).then(val => {
                                            if (val) {
                                                ToastHelper.toggleToast({
                                                    message: "Update Succesfully!",
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                                RefetchUser()
                                                toggleModal()
                                            }
                                        })
                                    } else {
                                        if (country.length === 0) {
                                            setIsValidCountry(false)
                                        }

                                        if (bank.length === 0) {
                                            setIsValidBank(false)
                                        }
                                    }
                                }}
                                onInvalidSubmit={() => {
                                    if (country.length === 0) {
                                        setIsValidCountry(false)
                                    }

                                    if (bank.length === 0) {
                                        setIsValidBank(false)
                                    }
                                }}
                            >
                                <Row>
                                    <Col lg="6">
                                        <FormGroup className={`${!isValidCountry ? 'text-danger' : ''}`}>
                                            <Label for="newrole-input">{t('Country')}</Label>
                                            <AsyncTypeahead
                                                selected={country}
                                                id="country-list"
                                                isLoading={loadingCountry}
                                                labelKey={option => `${option.node.name}`}
                                                minLength={0}
                                                onSearch={handleCountrySearch}
                                                onInputChange={(val) => {
                                                    if (val === "") {
                                                        handleCountrySearch('')
                                                    }
                                                }}
                                                options={countryData ? countryData.countries.edges : []}
                                                placeholder={t('Search for Country')}
                                                useCache={false}
                                                renderMenuItemChildren={(option, props) => (
                                                    <Fragment>
                                                        <span>{option.node.name}</span>
                                                    </Fragment>
                                                )}
                                                errorMessage={t('Enter country name')}
                                                isInvalid={!isValidCountry}
                                                onChange={(val) => {
                                                    setIsValidCountry(val.length > 0 ? true : false)
                                                    setCountry(val)
                                                    if (val.length === 0) {
                                                        setBank([])
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    if (isValidCountry && country.length === 0) {
                                                        setIsValidCountry(false)
                                                        setBank([])
                                                    }
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    {
                                        country.length > 0 &&
                                        <Col lg="6">
                                            <FormGroup className={`${!isValidBank ? 'text-danger' : ''}`}>
                                                <Label for="newrole-input">{t('Bank')}</Label>
                                                <AsyncTypeahead
                                                    id="country-list"
                                                    isLoading={loadingBank}
                                                    labelKey={option => `${option.node.name}`}
                                                    minLength={0}
                                                    selected={bank}
                                                    onSearch={handleBankSearch}
                                                    onInputChange={(val) => {
                                                        if (val === "") {
                                                            handleBankSearch('')
                                                        }
                                                    }}
                                                    options={bankData ? bankData.banks.edges : []}
                                                    placeholder={t('Search for Bank')}
                                                    useCache={false}
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <span>{option.node.name}</span>
                                                        </Fragment>
                                                    )}
                                                    errorMessage={t('Enter bank name')}
                                                    isInvalid={!isValidBank}
                                                    onChange={(val) => {
                                                        setIsValidBank(val.length > 0 ? true : false)
                                                        setBank(val)
                                                    }}
                                                    onBlur={(e) => {
                                                        if (isValidBank && bank.length === 0) {
                                                            setIsValidBank(false)
                                                        }
                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            value={account ? account.node.accountName : ''}
                                            label={t('Account Name')}
                                            name="accountName"
                                            type="text"
                                            placeholder={t('Enter Account Name')}
                                            errorMessage={t('Enter Account Name')}
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            value={account ? account.node.accountNo : ''}
                                            name="accountNo"
                                            label={t('Account Number')}
                                            placeholder={t('Enter Account Number')}
                                            type="text"
                                            errorMessage={t('Enter Account Number')}
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <div className="action-container">
                                    <Button color="primary" type="submit">{t('Submit')}</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default connect()(withTranslation()(EditPlayerBankAccount));

import gql from "graphql-tag";
import { PageFragment } from "../fragments/page.fragment";
import { TelegramButtonFragment } from "../fragments/telegram-button-fragment";
import { TelegramMenuButtonFragment } from "../fragments/telegram-menu-button.fragment";

export const GET_TELEGRAM_MENU_BUTTONS = gql`
  query (
    $filter: TelegramMenuButtonFilter
    $paging: CursorPaging
    $sorting: [TelegramMenuButtonSort!]
  ) {
    telegramMenuButtons(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        ...Page
      }
      edges {
        node {
          ...TelegramMenuButton
          telegramButton {
            ...TelegramButton
          }
        }
      }
    }
  }
  ${PageFragment}
  ${TelegramMenuButtonFragment}
  ${TelegramButtonFragment}
`;

import gql from 'graphql-tag';
import { CountryFragment } from './country.fragment';

export const BankFragment = gql`
    fragment BankInfo on Bank {
        id
        name
        headerColor
        titleColor
        iconUrl
        country {
            ...CountryInfo
        }
        isActive
        isArchived
        createdAt
        updatedAt
    }
    ${CountryFragment}
`;

export const BankAccountFragment = gql`
    fragment BankAccountInfo on BankAccount {
        id
        accountName
        accountNo
        login
        password
        balance
        dailyDepositLimit
        dailyWithdrawalLimit
        canDeposit
        canWithdraw
        accountHolderType
        bankAccountStatus
        bank {
            ...BankInfo
        }
        isActive
        isArchived
        createdAt
        updatedAt
    }
    ${BankFragment}
`;

import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useMutation } from 'react-apollo';
import EasyEdit from 'react-easy-edit';
import { useHistory } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { UPDATE_ONE_USER } from '../../graphql/mutation';
import { CHANGE_UPLINE } from '../../graphql/mutation/CHANGE_UPLINE';
import ToastHelper from '../../helpers/ToastHelper';
import CustomUserInput from '../Referral/custom-user-input';

function AgentTableItem(props) {

    const { agent, onManageInWorkbench, onManageWallet } = props;
    const [upline, setUpline] = React.useState(null)

    const [updateOneUser] = useMutation(UPDATE_ONE_USER);
    const [changeUpline] = useMutation(CHANGE_UPLINE);

    let history = useHistory();

    React.useEffect(() => {
        setUpline(agent.node.uplineUsername)
    }, [agent])

    const OnSave = (upline) => {
        changeUpline({
            variables: {
                input: {
                    userId: agent.node.id,
                    uplineUsername: upline,
                    force: true
                }
            }
        }).then(() => {
            ToastHelper.toggleToast({
                message: "Update Succesfully!",
                title: "",
                onClick: function () { }
            })
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        })
    }

    return (
        <React.Fragment>
            <tr>
                <td><div className="cursor-pointer ">{agent.node.serialNo}</div></td>
                <td>
                    <div className="d-flex">
                        <div
                            className="cursor-pointer" onClick={onManageInWorkbench}>
                            <i className="cursor-pointer bx bxs-wrench font-size-20"></i>
                        </div>
                        <div className="mr-1"></div>
                        <div className="cursor-pointer">{agent.node.username}</div>
                        {/* <div className="ml-1">(</div>
                        <div className="cursor-pointer" onClick={onEdit}>{user.node.wallets.edges.length > 0 ? parseFloat(user.node.wallets.edges[0].node.balance).toFixed(2) : '0.00'}</div>
                        <div className="mr-1"></div>
                        <div className="cursor-pointer" onClick={() => onManageWallet(user.node.wallets.edges[0].node)}>
                            <i className="bx bx-wallet font-size-20"></i>
                        </div>
                        <div>)</div> */}
                    </div>
                </td>
                <td>
                    {
                        !_.isNull(agent.node.mainWalletBalance) &&
                        <>
                            <div className="d-flex align-center">
                                <div className="cursor-pointer" onClick={() => onManageWallet({
                                    id: agent.node.mainWalletId
                                })}>
                                    <i className="bx bx-wallet font-size-15"></i>
                                </div>
                                <div className="ml-1">{`$${parseFloat(agent.node.mainWalletBalance).toFixed(2)}`}</div>
                            </div>
                        </>
                    }
                    {

                        !_.isNull(agent.node.agentWalletBalance) &&
                        // WalletHelper.getRewardWallet(user.node.wallets.edges).node.balance > 0 &&
                        <>
                            <div className="d-flex pt-1 align-center">
                                <div className="cursor-pointer" onClick={() => onManageWallet({ id: agent.node.agentWalletId })}>
                                    <i className="bx bx-network-chart font-size-15"></i>
                                </div>
                                <div className="mr-1 ml-1">{`AP${parseFloat(agent.node.agentWalletBalance).toFixed(2)}`}</div>
                                {
                                    moment(agent.node.agentWalletExpiredAt).isSameOrAfter(moment()) &&
                                    <>
                                        <i className="bx bxs-info-circle text-danger font-size-16" id={`expired-${agent.node.agentWalletId}`} />
                                        <UncontrolledTooltip placement="right" target={`expired-${agent.node.agentWalletId}`}>
                                            {`AP Expire In ${moment(agent.node.agentWalletExpiredAt).diff(moment(), 'days')} ${moment(agent.node.agentWalletExpiredAt).diff(moment(), 'days') > 1 ? 'days' : 'day'}`}
                                        </UncontrolledTooltip>
                                    </>
                                }
                            </div>
                        </>
                    }
                </td>
                <td>
                    {/* <div className="cursor-pointer">{user.node.upline ? user.node.upline.name : '-'}</div> */}
                    <EasyEdit
                        value={upline ? upline : '-'}
                        type="text"
                        placeholder="-"
                        disableAutoSubmit={upline === null}
                        editComponent={
                            <CustomUserInput
                                data={upline ? { username: upline } : null}
                                player={agent}
                                onSelected={(val) => {
                                    setUpline(val ? val.username : val)
                                }}
                            />}
                        onSave={() => {
                            OnSave(upline)
                        }}
                        onCancel={() => {
                            setUpline(agent.node.uplineUsername)
                        }}
                        saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                        cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                        saveButtonStyle="easy-edit-button-save"
                        cancelButtonStyle="easy-edit-button-cancel"
                    />
                </td>
                <td>
                    <div className="cursor-pointer ">{agent.node.downlineCount}</div>
                </td>
                <td><EasyEdit
                    value={agent.node.agentBaseRate}
                    placeholder="(Use Global)"
                    type="text"
                    onSave={(value) => {
                        updateOneUser({
                            variables: {
                                input: {
                                    update: {
                                        agentBaseRate: parseFloat(value),
                                    },
                                    id: agent.node.id
                                }
                            }
                        }).catch(e => {
                            ToastHelper.toggleToast({
                                toastType: "error",
                                message: e,
                                title: "",
                                onClick: function () { }
                            })
                        }).then(val => {
                            if (val) {
                                ToastHelper.toggleToast({
                                    message: "Update Succesfully!",
                                    title: "",
                                    onClick: function () { }
                                })
                            }
                        })
                    }}
                    saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                    cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                    saveButtonStyle="easy-edit-button-save"
                    cancelButtonStyle="easy-edit-button-cancel"
                />
                </td>
                <td><div className="cursor-pointer ">{agent.node.lastAPGained ? agent.node.lastAPGained : '-'}</div></td>
                <td><div className="cursor-pointer ">{agent.node.lastAPGainedAt ? moment(agent.node.lastAPGainedAt).format('YYYY-MM-DD HH:mm:ss') : '-'}</div></td>
                <td>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className="cursor-pointer ">{agent.node.lastAPGainedFrom ? agent.node.lastAPGainedFrom : '-'}</div>
                        {/* <div className="pl-1"></div>
                        <i className="bx bxs-info-circle text-danger font-size-16" id={`tooltip-${user.node.id}`} />
                        <UncontrolledTooltip placement="right" target={`tooltip-${user.node.id}`}>
                            {
                                transaction.node.player.tags.edges.map((tag) =>
                                    <li className="align-left">
                                        {
                                            tag.node.tagType === 'Good' &&
                                            <i className="bx bx-check font-size-18 text-success mr-1" />
                                        }
                                        {
                                            tag.node.tagType === 'Neutral' &&
                                            <i className="bx bxs-circle font-size-10 text-white ml-1 mr-2 mt-1" />
                                        }
                                        {
                                            tag.node.tagType === 'Bad' &&
                                            <i className="bx bx-x font-size-18 text-danger mr-1" />
                                        }
                                        {tag.node.name}
                                    </li>
                                )
                            }
                        </UncontrolledTooltip> */}
                    </div>
                </td>
                <td><div className="cursor-pointer new-action-button" onClick={() => {
                    window.open(`/agent-report/${agent.node.id}`, "_blank");
                }}>View Report</div></td>
            </tr >
        </React.Fragment >
    )
}

export default AgentTableItem;



import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { Fragment } from 'react';
import { useMutation } from 'react-apollo';
import {
    Button, Col, Modal, ModalBody, ModalHeader, Row
} from "reactstrap";
import { CREATE_ONE_TELEGRAM_MENU } from "../../../graphql/mutation/CREATE_ONE_TELEGRAM_MENU";
import { UPDATE_ONE_TELEGRAM_MENU } from "../../../graphql/mutation/UPDATE_ONE_TELEGRAM_MENU";
import ToastHelper from '../../../helpers/ToastHelper';


export default function TelegramPageForm(props) {

    const { toggleModal, telegramMenu, mode } = props;

    const [createOneTelegramMenu] = useMutation(CREATE_ONE_TELEGRAM_MENU)
    const [updateOneTelegramMenu] = useMutation(UPDATE_ONE_TELEGRAM_MENU);

    return <Fragment>
        <Modal isOpen={mode !== null} role="dialog" size="md" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-game-page">
                {
                    mode &&
                    <ModalHeader toggle={toggleModal}>
                        {mode.charAt(0).toUpperCase() + mode.slice(1)} Telegram Page
                    </ModalHeader>
                }
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                if (mode === 'new') {
                                    createOneTelegramMenu({
                                        variables: {
                                            input: {
                                                telegramMenu: {
                                                    name: values.name,
                                                    description: values.description
                                                }
                                            }
                                        }
                                    }).then((val) => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Create Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    }).catch((e) => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    })
                                } else if (mode === 'edit') {
                                    updateOneTelegramMenu({
                                        variables: {
                                            input: {
                                                "update": {
                                                    name: values.name,
                                                    description: values.description
                                                },
                                                id: telegramMenu.node.id
                                            }
                                        }
                                    }).then((val) => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    }).catch((e) => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    })
                                }
                            }}
                        >
                            <Row>
                                <Col lg="12">
                                    <AvField
                                        label="Telegram Page Name"
                                        name="name"
                                        type="text"
                                        value={telegramMenu ? telegramMenu.node.name : ''}
                                        placeholder="Enter Telegram Page Name"
                                        errorMessage="Enter Telegram Page Name"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                                <Col lg="12">
                                    <AvField
                                        label="Telegram Page Description"
                                        name="description"
                                        type="text"
                                        value={telegramMenu ? telegramMenu.node.description : ''}
                                        placeholder="Enter Telegram Page Description"
                                        errorMessage="Enter Telegram Page Description"
                                        validate={{
                                            required: { value: false },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
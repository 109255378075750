import gql from 'graphql-tag';
import { WalletFragment } from '../fragments/wallet.fragment';

export const UPDATE_ONE_WALLET = gql`
    mutation(
        $input: UpdateOneWalletInput!
    ) {
        updateOneWallet (
            input: $input
        ) {
            ...WalletInfo
        }
    }
    ${WalletFragment}
`;
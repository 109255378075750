
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";
class TagItem extends Component {


    state = {

    }

    constructor(props) {
        super(props);
    }


    render() {
        const { onEdit, tag, onEditPlayer } = this.props;

        const MAX_PLAYER_INDEX = 5;


        return <React.Fragment>
            <Col xl="3" sm="6" lg="4">
                <Card>
                    <CardBody style={{ padding: '15px 1.25rem' }}>
                        <Row>
                            <Col xl="12" sm="12" className="padding-bottom text-align">

                                <div className="cursor-pointer" onClick={onEdit}>
                                    <div className="header align-center">
                                        <div>Name</div>
                                        {
                                            tag.node.tagType === 'Good' &&
                                            <i className="bx bx-check font-size-20 text-success" />
                                        }
                                        {
                                            tag.node.tagType === 'Bad' &&
                                            <i className="bx bx-x font-size-20 text-danger" />
                                        }
                                    </div>
                                    <div className="font-size-14">
                                        <Link to="#" className="text-dark align-center">
                                            {tag.node.name}
                                        </Link>
                                    </div>
                                </div>
                            </Col>

                            <Col xl="12" sm="12" >
                                <div>
                                    <div className="header">Player Count</div>
                                    <div className="font-size-14">{tag.node.usersAggregate.count.id}</div>
                                </div>
                            </Col>


                            <Col xl="12" sm="12" className="padding-bottom align-center cursor-pointer  " >
                                <div onClick={onEditPlayer}>
                                    <div className="header">Players</div>
                                    <div className="d-flex flex-direction-row flex-wrap">
                                        {
                                            tag.node.users.edges.map((user, index) => {
                                                if (index === MAX_PLAYER_INDEX) {

                                                    if (tag.node.users.edges.length - (MAX_PLAYER_INDEX + 1) <= 0) {
                                                        return <div key={"user__" + index} ></div>
                                                    }
                                                    return <div key={"user__" + index} >
                                                        <div className="p-1 m-1 badge badge-primary">
                                                            {`+ ${tag.node.usersAggregate.count.id - MAX_PLAYER_INDEX} more`}
                                                        </div>
                                                    </div>
                                                } else if (index > MAX_PLAYER_INDEX) {
                                                    return <div key={"user__" + index} ></div>
                                                }
                                                return <div key={"user__" + index} className="p-1 m-1 badge badge-primary">
                                                    {user.node.username}
                                                </div>
                                            })
                                        }
                                        {
                                            tag.node.users.edges.length === 0 && <span>No Player found</span>
                                        }
                                    </div>
                                </div>
                            </Col>

                            <Col xl="12" sm="12" className="padding-bottom dropdown-actions">
                                <ButtonDropdown
                                    isOpen={this.state.drp_link}
                                    toggle={() =>
                                        this.setState({ drp_link: !this.state.drp_link })
                                    }
                                >
                                    <DropdownToggle caret color="secondary">
                                        Actions <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={onEdit}>Edit Tag</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </Row>
                    </CardBody>

                </Card>
            </Col>
        </React.Fragment>
    }
}

export default TagItem;
import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';

export const UPDATE_ONE_KIOSK_ACCOUNT= gql`
    mutation(
        $input: UpdateOneKioskAccountInput!
    ) {
        updateOneKioskAccount (
            input: $input
            ) {
            ...KioskInfo
        }
    }
    ${KioskFragment}
`;
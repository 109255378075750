import gql from 'graphql-tag';
import { CategoryFragment } from '../fragments/category.fragment';

export const ADD_PLATFORM_CATEGORY = gql`
    mutation(
        $input: RelationsInput!
    ) {
        addPlatformsToCategory (input: $input) {
            ...CategoryInfo
        }
    }
    ${CategoryFragment}
`;
import React from 'react';
import NumberFormat from 'react-number-format';

export default props => {
    const val = props.value || '0';
    return (
        <div className="vertical-center">
            <NumberFormat
                value={val}
                displayType={'text'}
                thousandSeparator={true}
                decimalScale={2}
            />
        </div>
    );
}
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';
import { BankAccountFragment } from '../fragments/bank.fragment';

export const GET_ONE_USER = gql`
    query (
        $id: ID!
    ) {
        user (
            id: $id
        ) {
            ...UserInfo
            bankAccounts {
                edges {
                    node {
                        ...BankAccountInfo
                    }
                }
            }
            firstDepositAmount
            lastWithdrawalAmount
            referralFeePercentage
            referralFee
            referralFeePaidAt
            referralFeePaidBy {
                id
                name
            }
            referrer {
                id
                name
                username
            }
        }
    }
    ${UserFragment}
    ${BankAccountFragment}
`;
import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const GET_TRANSACTIONS = gql`
query (
    $paging: CursorPaging
    $filter: TransactionFilter
    $sorting: [TransactionSort!]
) {
    transactions (
        paging: $paging
        filter: $filter
        sorting: $sorting
    ) {
        pageInfo {
            ...Page
        }
        edges {
            node {
                __typename
                ...TransactionInfo
                player {
                    upline {
                        id
                        username
                    }
                    id
                    serialNo
                    username
                    name
                    telegramId
                    lastDepositAmount
                    lastDepositAt
                    tags (sorting: {
                        field: tagType,
                        direction: ASC
                    }) {
                        edges {
                            node {
                                name
                                tagType
                            }
                        }
                    }
                }
            }
        }
    }
}
${TransactionFragment}
${PageFragment}
`;
import React, { useRef } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery, useSubscription } from '@apollo/react-hooks';

import { GET_TAGS, ME } from '../../graphql/query';

import _ from 'lodash';

import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

import EditTag from './edit-tags';
import NewTag from './new-tag';
import { CREATED_TAG } from '../../graphql/subscription/CREATE_TAG';

import TagItem from './tag-item'
import TagPlayer from './tag-player';
import InfiniteScroll from 'react-infinite-scroll-component';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';

function TagList(props) {

    const [addTag, setAddTag] = React.useState(false)
    const [editTag, setEditTag] = React.useState(null)

    const [afterCursor, setAfterCursor] = React.useState(null)
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [isSearch, setIsSearch] = React.useState(false)
    const [newData, setNewData] = React.useState(null)
    const [managePlayer, setManagePlayer] = React.useState(null);

    const { loading, fetchMore, data, refetch } = useQuery(GET_TAGS, {
        variables: {
            pageInfo: {
                first: 50,
                after: null
            },
            filter: search === "" ? {} : { name: { iLike: `%${search}%` } },
            userPaging: {
                first: 50
            },
            sorting: [{ field: "name", direction: "ASC" }]
        },
        notifyOnNetworkStatusChange: true
    })

    const { data: meData } = useQuery(ME)

    const { data: newtagdatas } = useSubscription(CREATED_TAG, {
        variables: {
            createdBy: { neq: meData ? meData.me.username : '' }
        }
    })

    React.useEffect(() => {
        if (newtagdatas) {
            setNewData(newtagdatas.createdTag)
        }
    }, [newtagdatas])

    React.useEffect(() => {
        if (isFetchMore) {
            let setting = {
                paging: {
                    first: 50,
                    after: afterCursor
                },
            };
            fetchMore({
                variables: setting,
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.tags.edges;
                    const pageInfo = fetchMoreResult.tags.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)
                    return newEdges.length
                        ? {
                            tags: {
                                __typename: previousResult.tags.__typename,
                                edges: _.uniqBy([...previousResult.tags.edges, ...newEdges], (e) => { return e.node.id }),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (data) {
            setAfterCursor(data.tags.pageInfo.endCursor)
            setPageInfo(data.tags.pageInfo)
        }
    }, [data])

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <div className="page-content bank-link">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Tag" breadcrumbItem="Tag List" />
                        <div className="search-bar-container">
                            <div className="button-add-bank-container">
                                <div className="app-search d-none d-lg-block">
                                    <div className="position-relative">
                                        <input
                                            value={searchValue}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setSearchValue(e.target.value)
                                                debouncedSave(e.target.value)
                                            }}
                                        />
                                        <span className="bx bx-search-alt"></span>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dropdown d-inline-block d-lg-none ml-2">
                                        <button
                                            type="button"
                                            className="btn header-item noti-icon waves-effect"
                                            id="page-header-search-dropdown"
                                            onClick={() => { setIsSearch(!isSearch) }}>
                                            <i className="mdi mdi-magnify"></i>
                                        </button>
                                        <div
                                            className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                            aria-labelledby="page-header-search-dropdown"
                                        >
                                            <form className="p-3">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input
                                                            value={searchValue}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={(e) => {
                                                                setSearchValue(e.target.value)
                                                                debouncedSave(e.target.value)
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="submit">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>

                                <div className="filter-container button-filter-container">
                                    {/* <button type="button" className="waves-effect btn btn-outline-secondary button-add-bank" onClick={() => {
                                     props.toggleRightSidebar();
                                 }}>
                                     <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                 </button> */}
                                </div>
                            </div>
                            <div className="button-add-bank-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bank" onClick={() => { setAddTag(true) }}>
                                    <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Tag
                                </button>
                            </div>

                            <div className={`reload-container ${newData ? 'active' : ''}`}>
                                <Card>
                                    <CardBody onClick={() => {
                                        window.location.reload()
                                    }}>
                                        <div className="reload-title">
                                            <i className='bx bx-refresh'></i>Reload
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        {
                            data &&
                            <InfiniteScroll
                                dataLength={data.tags.edges.length} //This is important field to render the next data
                                next={() => setIsFetchMore(true)}
                                hasMore={pageInfo.hasNextPage}
                                loader={
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                }
                                endMessage={
                                    <div className="text-center my-3">
                                        <div>Yay! You have seen it all</div>
                                    </div>
                                }
                            >
                                <Row>
                                    <Col lg="12">
                                        <Row>
                                            {
                                                data &&
                                                data.tags.edges.map((tag, key) =>
                                                    <TagItem key={"_tag_" + key} tag={tag} onEdit={() => {

                                                        setEditTag(tag.node);
                                                    }} onEditPlayer={(user) => {
                                                        setManagePlayer(tag.node);
                                                    }} ></TagItem>

                                                    // <Col key={"_tags_" + key} xl="3" sm="6" lg="4">


                                                    //     <Card>
                                                    //         <div style={{
                                                    //             backgroundColor: '#ADE498',
                                                    //             borderTopLeftRadius: '0.25rem',
                                                    //             borderTopRightRadius: '0.25rem',
                                                    //         }}>
                                                    //         </div>
                                                    //         <CardBody className="">
                                                    //             <Row>
                                                    //                 <Col className="text-align pb-0">
                                                    //                     <div>
                                                    //                         <div className="header">Tag Name</div>
                                                    //                         <div className="font-size-14">{tag.node.name}</div>
                                                    //                     </div>
                                                    //                 </Col>
                                                    //                 <Col>
                                                    //                     <button className="btn btn-primary btn-block waves-effect waves-light" type="button" onClick={
                                                    //                         () => {
                                                    //                             setEditTag(tag.node);
                                                    //                         }
                                                    //                     } >
                                                    //                         Edit Tag
                                                    //                 </button>
                                                    //                 </Col>
                                                    //             </Row>
                                                    //             <Row>
                                                    //                 <Col className="dropdown-actions">

                                                    //                 </Col>
                                                    //             </Row>
                                                    //         </CardBody>
                                                    //     </Card>
                                                    // </Col>
                                                )
                                            }
                                            {
                                                data &&
                                                data.tags.edges.length === 0 &&
                                                <Col xl="12" sm="12">
                                                    <div className="no-bank">No Tags Found</div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </InfiniteScroll>
                        }
                        <div className="load-more-container">
                            {
                                (loading) &&
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                </Col>
                            }
                        </div>
                        <NewTag
                            modal={addTag}
                            toggleModal={() => {
                                refetch()
                                setAddTag(false);
                            }}
                        ></NewTag>

                        <EditTag
                            modal={!_.isEmpty(editTag)}
                            toggleModal={() => {
                                setEditTag(null);
                            }}
                            tag={editTag}
                        />

                        {!_.isEmpty(managePlayer) && <TagPlayer modal={!_.isEmpty(managePlayer)}
                            toggleModal={() => {
                                refetch();
                                setManagePlayer(null);
                            }
                            }
                            tag={managePlayer}
                        />}
                    </Container>
                </div>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Bank };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(TagList));

// export default ContactsList;
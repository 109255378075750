import gql from 'graphql-tag';

export const GENERATE_AVAILABLE_LIST = gql`
    mutation (
        $marketingCampaignId: String!
    ) {
        generateAvailableList (
            marketingCampaignId: $marketingCampaignId
        )
    }
`;
import React from 'react';
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';
import { SHOW_WALLET_BALANCE_OF } from '../../graphql/query/SHOW_WALLET_BALANCE_OF';

function WalletTableItem(props) {

    const { entry, activeTab, wallet } = props

    const [calWalletBalanceOf, { data: walletBalance }] = useLazyQuery(SHOW_WALLET_BALANCE_OF)

    return (
        <tr>
            <td>
                {moment(entry.node.createdAt).format('YYYY-MM-DD HH:mm:ss')}
            </td>
            {
                activeTab !== 'Return' &&
                <td>{entry.node.walletEntryType}</td>
            }
            <td>{entry.node.description}</td>
            <td>
                <NumberFormat
                    value={entry.node.deposit}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    prefix={`${wallet.node.name === "Reward" ? '' : '$'}`}
                    suffix={`${wallet.node.name === "Reward" ? 'RP' : ''}`}
                />
            </td>
            <td>
                <NumberFormat
                    value={entry.node.withdrawal}
                    displayType={'text'}
                    thousandSeparator={true}
                    decimalScale={2}
                    prefix={`${wallet.node.name === "Reward" ? '' : '$'}`}
                    suffix={`${wallet.node.name === "Reward" ? 'RP' : ''}`} />
            </td>
            <td>
                {
                    walletBalance &&
                    <NumberFormat
                        value={walletBalance.showWalletBalanceOf}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        prefix={`${wallet.node.name === "Reward" ? '' : '$'}`}
                        suffix={`${wallet.node.name === "Reward" ? 'RP' : ''}`} />}
                {
                    !walletBalance &&
                    <i className="font-size-16 bx bx-calculator cursor-pointer" onClick={() => {
                        calWalletBalanceOf({
                            variables: {
                                walletId: wallet.node.id,
                                timestamp: entry.node.createdAt
                            }
                        })
                    }}></i>
                }
            </td>
            <td>{entry.node.transaction ? entry.node.transaction.serialNo : '-'}</td>
        </tr>
    );
}

export default withTranslation()(WalletTableItem);
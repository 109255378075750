import gql from 'graphql-tag';
import { GameAccountFragment } from '../fragments/game-account.fragment';
import { PageFragment } from '../fragments/page.fragment';
import { KioskFragment } from '../fragments/kiosk.fragment';
import { PlatformFragment } from '../fragments/platform.fragment';

export const GET_GAME_ACCOUNTS = gql`
query (
    $paging: CursorPaging
    $filter: GameAccountFilter
    $sorting: [GameAccountSort!]
) {
    gameAccounts (
        paging: $paging
        filter: $filter
        sorting: $sorting
    ) {
        pageInfo {
            ...Page
        }
        edges {
            node {
                ...GameAccountInfo
                kioskAccount {
                    ...KioskInfo
                    platform {
                        ...PlatformInfo
                    }
                }
            }
        }
    }
}
${KioskFragment}
${GameAccountFragment}
${PageFragment}
${PlatformFragment}
`;
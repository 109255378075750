import React from 'react';
import { Row, Col, Input } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import { AvForm } from "availity-reactstrap-validation";

import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";

function BankerLogout(props) {
    return (
        <React.Fragment>
            <div className="banker-deposit-container">
                <div className="banker-deposit-wrapper">
                    <div className="pl-1 pr-1 pt-2 pb-2">This will logout the player from the game.</div>
                    <AvForm className="banker-deposit-content-info">
                        <Row>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className="pl-1 pr-1 pt-2 pb-3 font-weight-medium">Player</div>
                                <Input disabled type="text" className="form-control" value={'Sheldon'} onChange={(event) => { }} />
                            </Col>
                            <Col xs={12} sm={6} md={6} lg={6}>
                                <div className="pl-1 pr-1 pt-2 pb-2 font-weight-medium">From Game Account</div>
                                <select className="custom-select">
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(BankerLogout));
import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { CategoryFragment } from '../fragments/category.fragment';
import { PlatformFragment } from '../fragments/platform.fragment';

export const GET_CATEGORY = gql`
    query (
        $paging: CursorPaging
        $filter: CategoryFilter
        $sorting: [CategorySort!]
    ) {
        categories (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...CategoryInfo
                    platforms {
                        edges {
                            node {
                                ...PlatformInfo
                            }
                        }
                    }
                }
            }
        }
    }
    ${CategoryFragment}
    ${PageFragment}
    ${PlatformFragment}
`;
import gql from 'graphql-tag';
import { PlatformFragment } from '../fragments/platform.fragment';

export const CREATE_ONE_PLATFORM = gql`
    mutation(
        $input: CreateOnePlatformInput!
    ) {
        createOnePlatform(input: $input){
            ...PlatformInfo  
        }
    }
    ${PlatformFragment}
`;
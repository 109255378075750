import gql from 'graphql-tag';
import { WalletFragment } from '../fragments/wallet.fragment';

export const FIND_OR_CREATE_REWARD_WALLET = gql`
    mutation(
        $userId: String!
    ) {
        findOrCreateRewardCreditWallet(
            userId: $userId
        ) {
            ...WalletInfo
        }
    }
    ${WalletFragment}
`;
import React from 'react';
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import NumberFormat from 'react-number-format';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useLazyQuery } from 'react-apollo';
import { GET_PLAYER_STAT } from '../../../graphql/query/GET_PLAYER_STAT';

function PlayerStats(props) {

    const { t, player, isActive } = props;

    const [runStat, { data: dataStat, loading }] = useLazyQuery(GET_PLAYER_STAT, {
        fetchPolicy: "network-only"
    })

    React.useEffect(() => {
        if (isActive) {
            runStat({
                variables: {
                    id: player.id
                }
            })
        }
    }, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="player-container">
                {
                    loading &&
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                            </div>
                        </Col>
                    </Row>
                }
                {
                    dataStat &&
                    <>
                        <Row>
                            <Col sm="12">
                                <div>
                                    <Row className="header-row">
                                        <Col className="text-center table-header">{t("Player Income Stats")}</Col>
                                    </Row>
                                    <div className="item-container">
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>{t("Total Deposit $")}</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalDepositAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Withdrawal $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalWithdrawalAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row net-income-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Gross Profit $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.grossProfit}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Bonus $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalBonusAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Promotion $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalFreeCreditAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Rebate $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalRebateAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row net-income-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Net Profit $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.netProfit}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Increment $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalIncrementAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Decrement $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalDecrementAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row net-income-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Adjusted Profit $</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.adjustedProfit}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col sm="12">
                                <div>
                                    <Row className="header-row">
                                        <Col className="text-center table-header">Playing Behavior</Col>
                                    </Row>
                                    <div className="item-container">
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Average Deposit $ (Single)</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.averageDepositAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Average Deposit $ (Daily)</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.averageDailyDepositAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Average Withdrawal $ (Single)</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.averageWithdrawalAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Average Withdrawal $ (Daily)</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.averageDailyWithdrawalAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Deposit #</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalDepositCount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    decimalScale={0}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Withdrawal #</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalWithdrawalCount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    decimalScale={0}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="pt-3">
                            <Col sm="12">
                                <div>
                                    <Row className="header-row">
                                        <Col className="text-center table-header">Activity Stats</Col>
                                    </Row>
                                    <div className="item-container">
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>First Joined</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <div>{moment(dataStat.playerStats.joinedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>First Deposited</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                {
                                                    dataStat.playerStats.firstDepositAt &&
                                                    <div>{moment(dataStat.playerStats.firstDepositAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                                                }
                                                {
                                                    !dataStat.playerStats.firstDepositAt &&
                                                    <div>Not Applicable</div>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Last Deposited</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                {
                                                    dataStat.playerStats.lastDepositAt &&
                                                    <div>{moment(dataStat.playerStats.lastDepositAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                                                }
                                                {
                                                    !dataStat.playerStats.lastDepositAt &&
                                                    <div>Not Applicable</div>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Last Withdrawn</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                {
                                                    dataStat.playerStats.lastWithdrawalAt &&
                                                    <div>{moment(dataStat.playerStats.lastWithdrawalAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                                                }
                                                {
                                                    !dataStat.playerStats.lastWithdrawalAt &&
                                                    <div>Not Applicable</div>
                                                }
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Membership Days</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalMembershipDay}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    decimalScale={0}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Total Active Days</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={dataStat.playerStats.totalActiveDay}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={''}
                                                    decimalScale={0}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="item-row">
                                            <Col xs={4} sm={4} md={4} lg={4} className="col"><div>Active Days Ratio</div></Col>
                                            <Col xs={8} sm={8} md={8} lg={8} className="col">
                                                <NumberFormat
                                                    value={parseFloat(dataStat.playerStats.activeDayRatio).toFixed(2)}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    suffix={'%'}
                                                    decimalScale={2}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </>
                }
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(PlayerStats));
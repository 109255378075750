import gql from 'graphql-tag';

export const BonusFragment = gql`
    fragment BonusInfo on Bonus {
        id
        name
        bannerUrl
        amount
        bonusType
        bonusComputation
        minDepositAmount
        maxCappedAmount
        rolloverMultiplier
        description
        isActive
        isArchived
        createdAt
        updatedAt
    }
`;
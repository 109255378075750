import gql from 'graphql-tag';
import { UserFragment } from "./user.fragment"
import { KioskFragment } from './kiosk.fragment';
export const GameAccountFragment = gql`
fragment GameAccountInfo on GameAccount {
    id
    username
    password
    gameAccountStatus
    kioskAccountId
    isActive
    isArchived
    createdAt
    updatedAt
    lastSyncAt
    balance
    message
    messageLabelType
    player{
        ...UserInfo
    }
    kioskAccount{
        ...KioskInfo
    }
}
${KioskFragment}
${UserFragment}
`;
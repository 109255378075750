import gql from 'graphql-tag';

export const RESTORE_DEFAULT_SETTING = gql`
    mutation(
        $id: String!
    ) {
        restoreDefaultSetting (
            id: $id
        ) {
            id
            defaultValue
            description
            key
            value
            developerOnly
        }
    }
`;
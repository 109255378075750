import React from 'react';
import { Table } from "reactstrap";
import { withTranslation } from 'react-i18next';
import WalletTableItem from './wallet-table-item';

function WalletTable(props) {

    const { data, t, activeTab, wallet } = props

    return (
        <React.Fragment>
            <div className="wallet-table">
                <div className="table-responsive">
                    <Table className="table table-centered table-nowrap">
                        <thead>
                            <tr>
                                <th scope="col">Created At</th>
                                {
                                    activeTab !== 'Return' &&
                                    <th scope="col">Type</th>
                                }
                                <th scope="col">Description</th>
                                <th scope="col">Deposit</th>
                                <th scope="col">Withdrawal</th>
                                <th scope="col">Wallet Balance</th>
                                <th scope="col">Transaction Ref No.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 &&
                                data.map((entry, index) => {
                                    return (
                                        <WalletTableItem key={index} entry={entry} activeTab={activeTab} wallet={wallet}/>
                                    )
                                })
                            }
                            {
                                data.length === 0 &&
                                <tr className="text-center pt-3">
                                    <td colSpan={12}>
                                        {t('No Wallet Entry Found')}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </Table>
                </div>
            </div>
        </React.Fragment>
    );
}

export default withTranslation()(WalletTable);
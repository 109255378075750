import gql from 'graphql-tag';

export const LOGIN = gql`
    mutation(
        $key: String!
        $password: String!
    ) {
        login(loginInput: {
            key: $key
            password: $password
        })
    }
`;
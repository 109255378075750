import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useQuery } from 'react-apollo';
import { GET_REJECT_REASONS } from '../../graphql/query/GET_REJECT_REASONS';

function TransactionReject(props) {

    const { modal, toggleModal, t, onDone, transaction } = props;

    const { data } = useQuery(GET_REJECT_REASONS)

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-account-page">
                    <ModalHeader toggle={toggleModal}>
                        {t('Select Company Bank Account')}
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-account-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    onDone(values)
                                }}
                            >
                                <Row>
                                    <Col lg="12">
                                        <AvField
                                            label={t('Reject Reason')}
                                            name="reason"
                                            placeholder={t('Select Reject Reason')}
                                            errorMessage={t('Select Reject Reason')}
                                            type="select"
                                            className="custom-select"
                                            value={
                                                data &&
                                                data.rejectReasons.edges.length > 0 &&
                                                data.rejectReasons.edges[0].node.id
                                            }
                                        >
                                            {
                                                data &&
                                                data.rejectReasons.edges.map((rejectReason, index) => {
                                                    return (
                                                        <option key={index} value={rejectReason.node.id}>{`${rejectReason.node.name}`}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                    <Col lg="12">
                                        <AvField
                                            label={t('Remark')}
                                            name="remark"
                                            placeholder={t('Enter Remark')}
                                            errorMessage={t('Enter Remark')}
                                            type="text"
                                            value={transaction.remark}
                                        />
                                    </Col>
                                </Row>
                                <div className="action-container">
                                    <Button color="primary" type="submit">{t('Submit')}</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default connect()(withTranslation()(TransactionReject));

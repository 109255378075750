import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const CHANGE_UPLINE = gql`
    mutation(
        $input: ChangeUplineInput!
    ) {
        changeUpline (
            changeUplineInput: $input
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
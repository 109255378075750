import gql from 'graphql-tag';

export const PlatformFragment = gql`
fragment PlatformInfo on Platform {
    id
    name
    displayName
    unitPrice
    displayName
    isActive
    isArchived
    createdAt
    updatedAt
    kioskPanelUrl
    imageUrl
    defaultSolutionMethod
    website
    iOSApp
    androidApp
  }
`;
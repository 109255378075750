import React, { Fragment } from 'react';
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import {
    Col,
    Row,
    Button,
    Card,
} from "reactstrap";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import { UPDATE_ONE_PLATFORM } from '../../graphql/mutation/UPDATE_ONE_PLATFORM';
import { GET_ONE_PLATFORM } from '../../graphql/query/GET_ONE_PLATFORM';
import Compressor from 'compressorjs';
import CloudinaryHelper from "../../helpers/CloudinaryHelper"
import { withTranslation } from 'react-i18next'
import _ from 'lodash';
import { ME } from '../../graphql/query';

function EditPlatform(props) {

    const { platform, modalCallback } = props;
    const [updateOnePlatform] = useMutation(UPDATE_ONE_PLATFORM);
    const { data: meData } = useQuery(ME)

    const [selectedFile, setSelectFile] = React.useState(null);
    const [fileError, setFileError] = React.useState(null);

    const { data: newerData, error } = useQuery(GET_ONE_PLATFORM, {
        variables: { id: platform.id }

    });

    const [isUploadingImage, setUploadingImage] = React.useState(false);

    React.useEffect(() => {
        if (newerData) {
            setSelectFile(null)
            setFileUrl(newerData.platform.imageUrl)
        } else if (error) {
            ToastHelper.toggleToast({
                toastType: "error",
                message: error,
                title: "",
                onClick: function () { }
            })

        }
    }, [newerData, error])

    const handleAcceptedFiles = files => {
        setSelectFile(files[0])
        setFileError(false)
    };

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 0.5,
                success: resolve,
                error: reject
            });
        })
    }

    const [fileUrl, setFileUrl] = React.useState(null);

    return <Fragment>
        {
            newerData &&

            <div id="new-bank-wizard" className="twitter-bs-wizard">
                <AvForm className="needs-validation"
                    onValidSubmit={async (event, values) => {
                        setUploadingImage(true)
                        let result
                        if (selectedFile) {
                            let imageData = await Compress(selectedFile)
                            result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=gavlzxqd`)
                        }

                        updateOnePlatform({
                            variables: {
                                input: {
                                    update: {
                                        "name": values.platformname,
                                        "unitPrice": values.unitPrices,
                                        "kioskPanelUrl": values.adminPanelUrl,
                                        "imageUrl": selectedFile ? result.data.url : newerData.platform.imageUrl ? newerData.platform.imageUrl : null,
                                        "displayName": values.displayName,
                                        website: values.website,
                                        iOSApp: values.ios,
                                        androidApp: values.android
                                    },
                                    id: platform.id,

                                }
                            }
                        }).catch(e => {
                            ToastHelper.toggleToast({
                                toastType: "error",
                                message: e,
                                title: "",
                                onClick: function () { }
                            })



                        }).then(val => {
                            if (val) {
                                ToastHelper.toggleToast({
                                    toastType: "Info",
                                    message: "Update Succesfully!",
                                    title: "",
                                    onClick: function () { }
                                })
                                setUploadingImage(false)


                                if (_.isFunction(modalCallback)) {
                                    modalCallback()
                                }
                            }
                        })
                    }}
                    onInvalidSubmit={() => {

                    }}
                >
                    <Row>
                        {
                            meData &&
                            <Col lg="6">
                                <AvField
                                    value={newerData.platform.name}
                                    label="Platform Name"
                                    name="platformname"
                                    type="text"
                                    disabled={meData.me.role !== 'Developer'}
                                    placeholder="Enter Platform Name"
                                    errorMessage="Enter Platform name"
                                    validate={{
                                        required: { value: true },
                                    }}
                                />
                            </Col>
                        }
                        <Col lg="6">
                            <AvField
                                value={newerData.platform.displayName}
                                label="Display Name"
                                name="displayName"
                                type="text"
                                placeholder="Enter Display Name"
                                errorMessage="Enter Display name"
                            />
                        </Col>
                        {
                            meData &&
                            <Col lg="6">
                                <AvField
                                    value={newerData.platform.unitPrice}
                                    label="Unit Price"
                                    name="unitPrices"
                                    type="number"
                                    disabled={meData.me.role !== 'Developer'}
                                    placeholder="Enter Unit Prices"
                                    errorMessage="Enter Unit Prices"
                                />
                            </Col>
                        }
                        <Col lg="6">
                            <AvField
                                value={newerData.platform.kioskPanelUrl}
                                label="Admin Panel Url"
                                name="adminPanelUrl"
                                type="text"
                                placeholder="Enter Admin Panel Url"
                                errorMessage="Enter Admin Panel Url"
                                validate={{
                                    pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                }}
                            />
                        </Col>
                        <Col lg="6">
                            <AvField
                                value={newerData.platform.website}
                                label="Game Website"
                                name="website"
                                type="text"
                                placeholder="Enter Game Website"
                                errorMessage="Enter Game Website"
                                validate={{
                                    pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                }}
                            />
                        </Col>
                        <Col lg="6">
                            <AvField
                                value={newerData.platform.iOSApp}
                                label="iOS App"
                                name="ios"
                                type="text"
                                placeholder="Enter iOS App"
                                errorMessage="Enter iOS App"
                                validate={{
                                    pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                }}
                            />
                        </Col>
                        <Col lg="6">
                            <AvField
                                value={newerData.platform.androidApp}
                                label="Android App"
                                name="android"
                                type="text"
                                placeholder="Enter Android App"
                                errorMessage="Enter Android App"
                                validate={{
                                    pattern: { value: '^https?://', errorMessage: 'Must start with http:// or https://' }
                                }}
                            />
                        </Col>
                    </Row>
                    {
                        fileError &&
                        <Row><Col lg="12"><div className="image-url-error">* Please Provide an Image</div></Col></Row>
                    }
                    <Row className="pb-1" >
                        {!fileUrl && !selectedFile &&
                            <Col lg="12">
                                <Dropzone
                                    onDrop={acceptedFiles =>
                                        handleAcceptedFiles(acceptedFiles)
                                    }>
                                    {({ getRootProps, getInputProps }) => (
                                        <div className="dropzone">
                                            <div
                                                className="dz-message needsclick mt-2"
                                                {...getRootProps()}
                                            >
                                                <input {...getInputProps()} />
                                                <div className="mb-3">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                            </Col>
                        }


                        {selectedFile &&
                            <Col lg="6">
                                <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                >
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={"file"}
                                    >


                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        height="80"
                                                        className="avatar-sm rounded bg-light"
                                                        alt={selectedFile.name}
                                                        src={selectedFile ? URL.createObjectURL(selectedFile) : null}
                                                    />
                                                </Col>
                                                <Col>
                                                    {isUploadingImage && <div className="spinner-border text-success m-1" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>}

                                                    {!isUploadingImage && <div
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        Product Image
                                                    </div>}
                                                </Col>
                                                <Col>
                                                    <Button className="pr-2 float-right" close aria-label="Cancel" onClick={() => {
                                                        setSelectFile(null);

                                                    }} >
                                                    </Button>

                                                </Col>
                                            </Row>
                                        </div>
                                    </Card>
                                </div>
                            </Col>
                        }


                        {fileUrl &&
                            <Col lg="6">
                                <div
                                    className="dropzone-previews mt-3"
                                    id="file-previews"
                                >
                                    <Card
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        key={"file"}
                                    >


                                        <div className="p-2">
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        data-dz-thumbnail=""
                                                        alt="img"
                                                        className="avatar-sm rounded bg-light"

                                                        src={fileUrl}
                                                    />
                                                </Col>
                                                <Col>
                                                    {isUploadingImage && <div className="spinner-border text-success m-1" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>}

                                                    {!isUploadingImage && <div
                                                        to="#"
                                                        className="text-muted font-weight-bold"
                                                    >
                                                        Product Image
                                                    </div>}

                                                </Col>

                                                <Col>
                                                    <Button className="pr-2 float-right" close aria-label="Cancel" onClick={() => {
                                                        setFileUrl(null);
                                                    }} >
                                                    </Button>

                                                </Col>

                                            </Row>
                                        </div>

                                    </Card>
                                </div>
                            </Col>
                        }

                    </Row>

                    <Row>
                        <div className="action-container p-2">
                            <Button className="mx-1" color="primary" type="submit">Submit</Button>

                        </div>

                    </Row>

                </AvForm>
            </div>
        }
    </Fragment >
}



const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(EditPlatform));

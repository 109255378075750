
import React from 'react';
import Switch from "react-switch";
import { useMutation } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';
import { UPDATE_ONE_COUNTRY, SET_DEFAULT_COUNTRY } from '../../graphql/mutation';

function CountryItem(props) {

    const { onEdit, country } = props;

    const [updateOneCountry] = useMutation(UPDATE_ONE_COUNTRY);
    const [setDefaultCountry] = useMutation(SET_DEFAULT_COUNTRY);

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return <React.Fragment>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(country)
            }}>
                {country.node.name}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(country)
            }}>
                {country.node.abbreviation}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(country)
            }}>
                {country.node.phonePrefix}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(country)
            }}>
                {country.node.smsPrefix}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(country)
            }}>
                {country.node.phoneNoSample}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(country)
            }}>
                {country.node.defaultLanguage}
            </div>
        </td>
        <td>
            <div className="cursor-pointer " onClick={() => {
                onEdit(country)
            }}>
                {country.node.currency ? country.node.currency.code : 'N/A'}
            </div>
        </td>
        <td>
            <Switch
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                id="is-active"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={() => {
                    setDefaultCountry({
                        variables: {
                            id: country.node.id
                        }
                    }).catch(e => {
                        ToastHelper.toggleToast({
                            toastType: "error",
                            message: e,
                            title: "",
                            onClick: function () { }
                        })
                    }).then(val => {
                        if (val) {
                            ToastHelper.toggleToast({
                                message: "Update Succesfully!",
                                title: "",
                                onClick: function () { }
                            })
                        }
                    })

                }}
                checked={country.node.isDefault}
            />
        </td>
        <td>
            <Switch
                uncheckedIcon={<Offsymbol text="NO" />}
                checkedIcon={<OnSymbol text="YES" />}
                id="is-active"
                onColor="#34c38f"
                offColor="#f46a6a"
                onChange={() => {
                    updateOneCountry({
                        variables: {
                            input: {
                                update: {
                                    isActive: !country.node.isActive
                                },
                                id: country.node.id
                            }
                        }
                    }).catch(e => {
                        ToastHelper.toggleToast({
                            toastType: "error",
                            message: e,
                            title: "",
                            onClick: function () { }
                        })
                    }).then(val => {
                        if (val) {
                            ToastHelper.toggleToast({
                                message: "Update Succesfully!",
                                title: "",
                                onClick: function () { }
                            })
                        }
                    })

                }}
                checked={country.node.isActive}
            />
        </td>
    </React.Fragment>
}

export default CountryItem;
import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useQuery } from 'react-apollo';
import { GET_BANK_ACCOUNTS } from '../../graphql/query';

function TransactionChangeBank(props) {

    const { modal, toggleModal, t, onDone, transaction } = props;

    const { data: companyBankAccounts } = useQuery(GET_BANK_ACCOUNTS, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                accountHolderType: { eq: "Company" },
                isActive: { is: true }
            },
            sorting: [{
                field: "createdAt", direction: "DESC"
            }]
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-account-page">
                    <ModalHeader toggle={toggleModal}>
                        {t('Select Company Bank Account')}
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-account-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    onDone(values)
                                }}
                            >
                                <Row>
                                    <Col lg="12">
                                        <AvField
                                            label={t('Company Bank Account')}
                                            name="bank"
                                            placeholder={t('Select Company Bank Account')}
                                            errorMessage={t('Select Company Bank Account')}
                                            type="select"
                                            className="custom-select"
                                            value={
                                                companyBankAccounts &&
                                                companyBankAccounts.bankAccounts.edges.length > 0 &&
                                                transaction.node.companyBankAccountId
                                            }
                                        >
                                            {
                                                companyBankAccounts &&
                                                companyBankAccounts.bankAccounts.edges.map((bankAccount, index) => {
                                                    return (
                                                        <option key={index} value={bankAccount.node.id}>{`${bankAccount.node.bank.name} - ${bankAccount.node.accountName} - ${bankAccount.node.accountNo}`}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                </Row>
                                <div className="action-container">
                                    <Button color="primary" type="submit">{t('Submit')}</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default connect()(withTranslation()(TransactionChangeBank));

import React from "react";
import { Row, Col, Table } from "reactstrap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLazyQuery } from "react-apollo";
import moment from "moment";
import { GET_BANK_ACCOUNTS } from "../../../graphql/query";
import _ from "lodash";
import { OperatingUsers, Operator } from "../../../assets/scss/role.constant";

function PlayerBankAccounts(props) {
  const { t, player, onEdit, isActive, actToggleBank, meData } = props;

  const [runBankAccounts, { data: bankAccounts }] = useLazyQuery(
    GET_BANK_ACCOUNTS,
    {
      variables: {
        paging: {
          first: 50,
        },
        filter: {
          playerId: { eq: player.id },
        },
        sorting: {
          field: "createdAt",
          direction: "DESC",
        },
      },
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
    }
  );

  React.useEffect(() => {
    if (isActive) {
      runBankAccounts({
        variables: {
          paging: {
            first: 50,
          },
          filter: {
            playerId: { eq: player.id },
          },
          sorting: {
            field: "createdAt",
            direction: "DESC",
          },
        },
      });
    }
  }, [isActive]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <React.Fragment>
      <div className="player-container">
        <Row>
          <Col sm="12">
            <div className="table-responsive">
              <Table className="table mb-0">
                <thead>
                  <tr className="font-size-12">
                    <th>Updated At</th>
                    <th>Bank</th>
                    <th>Account Name</th>
                    <th>Account Number</th>
                    <th>Active?</th>
                    <th>Action</th>
                  </tr>
                </thead>
                {bankAccounts &&
                  _.filter(bankAccounts.bankAccounts.edges, (e) => {
                    if (
                      OperatingUsers.includes(meData.me.role) ||
                      Operator.includes(meData.me.role)
                    ) {
                      return e.node.isActive === true;
                    } else {
                      return true;
                    }
                  }).length > 0 && (
                    <tbody className="table-body">
                      {_.filter(bankAccounts.bankAccounts.edges, (e) => {
                        if (
                          OperatingUsers.includes(meData.me.role) ||
                          Operator.includes(meData.me.role)
                        ) {
                          return e.node.isActive === true;
                        } else {
                          return true;
                        }
                      }).map((account, index) => {
                        return (
                          <tr key={index} className="font-size-12">
                            <td>
                              {moment(account.node.createdAt).format(
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </td>
                            <td>{account.node.bank.name}</td>
                            <td>{account.node.accountName}</td>
                            <td>{account.node.accountNo}</td>
                            <td>{`${account.node.isActive}`.toUpperCase()}</td>
                            <td>
                              <div className="action-buttons align-center">
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light font-size-11 action-button mr-2"
                                  onClick={() => {
                                    if (onEdit) {
                                      onEdit(account);
                                    }
                                  }}
                                >
                                  Edit
                                </button>
                                {account.node.isActive && (
                                  <button
                                    type="button"
                                    className="btn btn-danger waves-effect waves-light font-size-11 action-button"
                                    onClick={() => actToggleBank(account)}
                                  >
                                    <i className="bx bx-x font-size-15 align-middle pr-1"></i>{" "}
                                    Deactivate
                                  </button>
                                )}
                                {!account.node.isActive && (
                                  <button
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light font-size-11 action-button"
                                    onClick={() => actToggleBank(account)}
                                  >
                                    <i className="bx bx-check font-size-15 align-middle pr-1"></i>{" "}
                                    Activate
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  )}
                {bankAccounts &&
                  _.filter(bankAccounts.bankAccounts.edges, (e) => {
                    if (
                      OperatingUsers.includes(meData.me.role) ||
                      Operator.includes(meData.me.role)
                    ) {
                      return e.node.isActive === true;
                    } else {
                      return true;
                    }
                  }).length === 0 && (
                    <tbody className="table-body">
                      <tr className="text-center pt-3">
                        <td colSpan={12}>{t("No Bank Account Found")}</td>
                      </tr>
                    </tbody>
                  )}
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(PlayerBankAccounts));

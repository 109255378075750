import React, { Fragment } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../graphql/query';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { GET_CURRENCY } from '../../graphql/query/GET_CURRENCY';
import { CREATE_ONE_COUNTRY } from '../../graphql/mutation';

function NewCountry(props) {

    const { modal, toggleModal, t } = props;

    const [currencySearch, setCurrencySearch] = React.useState('');
    const [currencyId, setCurrencyId] = React.useState('');

    const { data: languageData } = useQuery(GET_ENUM, {
        variables: {
            name: "Language"
        }
    });

    const { data: currencyData, loading: loadingCurrency } = useQuery(GET_CURRENCY, {
        variables: {
            filter: currencySearch === '' ? {} : {
                name: { iLike: `%${currencySearch}%` }
            }
        },
        notifyOnNetworkStatusChange: true
    });


    const [createOneCountry] = useMutation(CREATE_ONE_COUNTRY);

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-currency-page">
                    <ModalHeader toggle={toggleModal}>
                        Add Country
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    await createOneCountry({
                                        variables: {
                                            input: {
                                                country: {
                                                    name: values.name,
                                                    abbreviation: values.abbreviation,
                                                    phonePrefix: values.phonePrefix,
                                                    smsPrefix: values.smsPrefix,
                                                    phoneNoSample: values.phoneNoSample,
                                                    phoneRegex: values.phoneRegex,
                                                    defaultLanguage: values.defaultLanguage,
                                                    currencyId: currencyId
                                                }
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Create Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })
                                }}
                            >
                                <Row>

                                    <Col lg={6}>
                                        <AvField
                                            label="Country Name"
                                            name="name"
                                            type="text"
                                            placeholder="Enter Country Name"
                                            errorMessage="Enter Country Name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="Abbreviation"
                                            name="abbreviation"
                                            type="text"
                                            placeholder="Enter Abbreviation"
                                            errorMessage="Enter Abbreviation"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="Phone Prefix"
                                            name="phonePrefix"
                                            type="text"
                                            placeholder="Enter Phone Prefix"
                                            errorMessage="Enter Phone Prefix"
                                            validate={{
                                                required: { value: false },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="Phone Regex"
                                            name="phoneRegex"
                                            type="text"
                                            placeholder="Enter Phone Regex"
                                            errorMessage="Enter Phone Regex"
                                            validate={{
                                                required: { value: false },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="SMS Prefix"
                                            name="smsPrefix"
                                            type="text"
                                            placeholder="Enter SMS Prefix"
                                            errorMessage="Enter SMS Prefix"
                                            validate={{
                                                required: { value: false },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="Phone No Sample"
                                            name="phoneNoSample"
                                            type="text"
                                            placeholder="Enter Phone No Sample"
                                            errorMessage="Enter Phone No Sample"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label={t('Default Language')}
                                            type="select"
                                            name="defaultLanguage"
                                            placeholder="Default Language"
                                            errorMessage={t('Default Language')}
                                            className="custom-select"
                                            validate={{
                                                required: true
                                            }}
                                            value={languageData && languageData.__type.enumValues[0].name}
                                        >
                                            {
                                                languageData &&
                                                languageData.__type.enumValues.map(({ name }, index) => {
                                                    return (
                                                        <option key={index} value={name}>{name}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            label="Currency"
                                            name="currency"
                                            type="text"
                                            placeholder="Enter Currency"
                                            errorMessage="Enter Currency"
                                            isLoading={loadingCurrency}
                                            onSearch={(query => setCurrencySearch(query))}
                                            onInputChange={(val) => {
                                                if (val === '') {
                                                    setCurrencySearch('')
                                                }
                                            }}
                                            onChange={(val) => {
                                                setCurrencyId(val.length > 0 ? val[0].node.id : '')
                                            }}
                                            options={currencyData ? currencyData.currencies.edges : []}
                                            labelKey={option => `${option.node.code}`}
                                            renderMenuItemChildren={(option, props) => (
                                                <Fragment>
                                                    <span>{option.node.code}</span>
                                                </Fragment>
                                            )}
                                            minLength={0}
                                            tag={[AsyncTypeahead]}
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(NewCountry));
import AnimatedNumber from "animated-number-react";
import moment from "moment";
import React from "react";
import { useLazyQuery, useQuery, useSubscription } from "react-apollo";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap";
import {
  Admin,
  Developer,
  OperatingUsers,
  Operator,
} from "../../assets/scss/role.constant";
import { ME, SALE_SUMMARY_REPORT } from "../../graphql/query";
import { GET_TRANSACTIONS } from "../../graphql/query/GET_TRANSACTIONS";
import { UPDATED_ONE_TRANSACTION } from "../../graphql/subscription/UPDATED_ONE_TRANSACTION";
import TransactionItem from "../Transactions/transaction-item";
import KioskAlert from "../Workbench/Components/kiosk-alert";

function Dashboard(props) {
  const { t } = props;

  const duration = 800;
  const history = useHistory();

  useSubscription(UPDATED_ONE_TRANSACTION, {
    shouldResubscribe: true,
  });

  const {
    data: depositData,
    loading: loadingDeposit,
    refetch: refetchDeposit,
  } = useQuery(GET_TRANSACTIONS, {
    variables: {
      filter: {
        transactionType: { eq: "Deposit" },
        transactionStatus: { in: ["Pending", "Viewed"] },
      },
      paging: {
        first: 5,
      },
      sorting: [{ field: "createdAt", direction: "ASC" }],
    },
    fetchPolicy: "network-only",
  });

  const { data: meData } = useQuery(ME, {
    fetchPolicy: "cache-first",
  });

  const {
    data: withdrawalData,
    loading: loadingWithdrawal,
    refetch: refetchWithdrawal,
  } = useQuery(GET_TRANSACTIONS, {
    variables: {
      filter: {
        transactionType: { eq: "Withdrawal" },
        transactionStatus: { in: ["Pending", "Viewed"] },
      },
      paging: {
        first: 5,
      },
      sorting: [{ field: "createdAt", direction: "ASC" }],
    },
    fetchPolicy: "network-only",
  });

  const {
    data: onHoldData,
    loading: loadingHoldData,
    refetch: refetchOnHold,
  } = useQuery(GET_TRANSACTIONS, {
    variables: {
      filter: {
        transactionType: { in: ["Withdrawal", "Deposit"] },
        transactionStatus: { eq: "OnHold" },
      },
      paging: {
        first: 5,
      },
      sorting: [{ field: "createdAt", direction: "ASC" }],
    },
    fetchPolicy: "network-only",
  });

  const [runReport, { data }] = useLazyQuery(SALE_SUMMARY_REPORT, {
    variables: {
      periodType: "day",
      fromDate: moment().startOf("day").format("YYYY-MM-DD"),
      toDate: moment().endOf("day").format("YYYY-MM-DD"),
    },
    fetchPolicy: "network-only",
  });

  // const [runUser, { data: dataUser }] = useLazyQuery(GET_USER_TOTAL_COUNT, {
  //     fetchPolicy: 'network-only'
  // })

  // React.useEffect(() => {
  //     if (meData && OperatingUsers.includes(meData.me.role)) {
  //         history.push({
  //             "pathname": "/dashboard",
  //         })
  //     } else {
  //         if (meData && Operator.includes(meData.me.role)) {
  //             history.push({
  //                 "pathname": "/dashboard",
  //             })
  //         }
  //     }
  // }, [meData])

  React.useEffect(() => {
    runReport();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const formatDollar = (num) => {
    var p = num.toFixed(2).split(".");
    return (
      p[0]
        .split("")
        .reverse()
        .reduce(function (acc, num, i) {
          return num === "-"
            ? `-${acc}`
            : num + (i && !(i % 3) ? "," : "") + acc;
        }, "") +
      "." +
      p[1]
    );
  };

  return (
    <React.Fragment>
      {
        <div className="page-content bonus-page">
          <KioskAlert />
          <Container fluid>
            {data && data.salesSummaryReport.length > 0 && (
              <Row>
                <Col md={6} lg={6} xl={3} sm={6}>
                  <Card>
                    <CardBody className="text-center">
                      <h5 className="mb-0">
                        <AnimatedNumber
                          value={
                            data
                              ? data.salesSummaryReport[0].totalDepositAmount
                              : 0
                          }
                          formatValue={formatDollar}
                          duration={duration}
                        />
                      </h5>
                      <h6 className="mb-0">Deposits</h6>
                      {meData &&
                        !OperatingUsers.includes(meData.me.role) &&
                        !Operator.includes(meData.me.role) && (
                          <Row className="mt-4">
                            <Col md={4} lg={4} xl={4} sm={4}>
                              <p className="mb-0">Total</p>
                              <p className="mb-0">
                                <AnimatedNumber
                                  value={
                                    data.salesSummaryReport[0].totalDepositCount
                                  }
                                  formatValue={(value) => value.toFixed(0)}
                                  duration={duration}
                                />
                              </p>
                            </Col>
                            <Col md={4} lg={4} xl={4} sm={4}>
                              <p className="mb-0">Average</p>
                              <p className="mb-0">
                                <AnimatedNumber
                                  value={
                                    data.salesSummaryReport[0]
                                      .averageDepositAmount
                                  }
                                  formatValue={formatDollar}
                                  duration={duration}
                                />
                              </p>
                            </Col>
                            <Col md={4} lg={4} xl={4} sm={4}>
                              <p className="mb-0">Highest</p>
                              <p className="mb-0">
                                <AnimatedNumber
                                  value={
                                    data.salesSummaryReport[0]
                                      .highestDepositAmount
                                  }
                                  formatValue={formatDollar}
                                  duration={duration}
                                />
                              </p>
                            </Col>
                          </Row>
                        )}
                    </CardBody>
                  </Card>
                </Col>
                {
                  <>
                    <Col md={6} lg={6} xl={3} sm={6}>
                      <Card>
                        <CardBody className="text-center">
                          <h5 className="mb-0">
                            <AnimatedNumber
                              value={
                                data.salesSummaryReport[0].totalWithdrawalAmount
                              }
                              formatValue={formatDollar}
                              duration={duration}
                            />
                          </h5>
                          <h6 className="mb-0">Withdrawals</h6>
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <Row className="mt-4">
                                <Col md={4} lg={4} xl={4} sm={4}>
                                  <p className="mb-0">Total</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0]
                                          .totalWithdrawalCount
                                      }
                                      formatValue={(value) => value.toFixed(0)}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                                <Col md={4} lg={4} xl={4} sm={4}>
                                  <p className="mb-0">Average</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0]
                                          .averageWithdrawalAmount
                                      }
                                      formatValue={formatDollar}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                                <Col md={4} lg={4} xl={4} sm={4}>
                                  <p className="mb-0">Highest</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0]
                                          .highestWithdrawalAmount
                                      }
                                      formatValue={formatDollar}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                              </Row>
                            )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={3} sm={6}>
                      <Card>
                        <CardBody className="text-center">
                          <h5 className="mb-0">
                            <AnimatedNumber
                              value={data.salesSummaryReport[0].grossProfit}
                              formatValue={formatDollar}
                              duration={duration}
                            />
                          </h5>
                          <h6 className="mb-0">Profits</h6>
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <Row className="mt-4">
                                <Col md={4} lg={4} xl={4} sm={4}>
                                  <p className="mb-0">In</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0].totalCreditIn
                                      }
                                      formatValue={formatDollar}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                                <Col md={4} lg={4} xl={4} sm={4}>
                                  <p className="mb-0">Out</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0]
                                          .totalCreditOut
                                      }
                                      formatValue={formatDollar}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                                <Col md={4} lg={4} xl={4} sm={4}>
                                  <p className="mb-0">Win</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0].netWinning
                                      }
                                      formatValue={formatDollar}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                              </Row>
                            )}
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} lg={6} xl={3} sm={6}>
                      <Card>
                        <CardBody className="text-center">
                          <h5 className="mb-0">
                            <AnimatedNumber
                              value={data.salesSummaryReport[0].newPlayerCount}
                              formatValue={(value) => value.toFixed(0)}
                              duration={duration}
                            />
                          </h5>
                          <h6 className="mb-0">New</h6>
                          {meData &&
                            !OperatingUsers.includes(meData.me.role) &&
                            !Operator.includes(meData.me.role) && (
                              <Row className="mt-4">
                                <Col md={6} lg={6} xl={6} sm={6}>
                                  <p className="mb-0">Active</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0]
                                          .activePlayerCount
                                      }
                                      formatValue={(value) => value.toFixed(0)}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                                <Col md={6} lg={6} xl={6} sm={6}>
                                  <p className="mb-0">First Deposit</p>
                                  <p className="mb-0">
                                    <AnimatedNumber
                                      value={
                                        data.salesSummaryReport[0]
                                          .firstDepositorCount
                                      }
                                      formatValue={(value) => value.toFixed(0)}
                                      duration={duration}
                                    />
                                  </p>
                                </Col>
                              </Row>
                            )}
                        </CardBody>
                      </Card>
                    </Col>
                  </>
                }
              </Row>
            )}
            <Card className="transactions-page">
              <CardBody>
                <CardTitle className="mb-4">Recent 5 Deposits</CardTitle>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr className="font-size-12">
                        <th>Serial No</th>
                        <th>Created At</th>
                        <th>User</th>
                        <th>Image</th>
                        <th>Amount</th>
                        <th>Bonus</th>
                        <th>Total Amount</th>
                        <th>Reward Point</th>
                        <th>Agent Point</th>
                        <th>Movement</th>
                        <th>Updated At</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {!loadingDeposit &&
                        depositData &&
                        depositData.transactions.edges.length > 0 && (
                          <>
                            {depositData.transactions.edges.map(
                              (transaction, index) => {
                                return (
                                  <TransactionItem
                                    key={index}
                                    refetch={refetchDeposit}
                                    transaction={transaction}
                                    onDone={() => {
                                      refetchDeposit();
                                      refetchOnHold();
                                    }}
                                    isAtDashboard
                                    onManageInWorkbench={() => {
                                      history.push({
                                        pathname: "/cashier-workbench",
                                        target: "_blank",
                                        state: {
                                          user: {
                                            node: transaction.node.player,
                                          },
                                        },
                                      });
                                    }}
                                  />
                                );
                              }
                            )}
                            <tr className="text-center pt-3">
                              <td colSpan={12}>
                                <Link
                                  to="/transaction-deposit/pending"
                                  className="text-success"
                                >
                                  <div>View All</div>
                                  <div>
                                    <i className="bx bx-chevrons-down bx-fade-down"></i>
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          </>
                        )}
                      {!loadingDeposit &&
                        depositData &&
                        depositData.transactions.edges.length === 0 && (
                          <tr className="text-center pt-3">
                            <td colSpan={12}>{t("No Transactions Found")}</td>
                          </tr>
                        )}
                      {loadingDeposit && (
                        <tr className="text-center pt-3">
                          <td colSpan={12}>
                            <Link to="#" className="text-success">
                              <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                              Loading
                            </Link>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            <Card className="transactions-page">
              <CardBody>
                <CardTitle className="mb-4">Recent 5 Withdrawals</CardTitle>
                <div className="table-responsive">
                  <Table className="table mb-0">
                    <thead>
                      <tr className="font-size-12">
                        <th>Serial No</th>
                        <th>Created At</th>
                        <th>User</th>
                        <th>Image</th>
                        <th>Amount</th>
                        {meData && !OperatingUsers.includes(meData.me.role) && (
                          <th>Movement</th>
                        )}
                        <th>Updated At</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="table-body">
                      {!loadingWithdrawal &&
                        withdrawalData &&
                        withdrawalData.transactions.edges.length > 0 && (
                          <>
                            {withdrawalData.transactions.edges.map(
                              (transaction, index) => {
                                return (
                                  <TransactionItem
                                    key={index}
                                    refetch={refetchWithdrawal}
                                    transaction={transaction}
                                    onDone={() => {
                                      refetchWithdrawal();
                                      refetchOnHold();
                                    }}
                                    onManageInWorkbench={() => {
                                      history.push({
                                        pathname: "/cashier-workbench",
                                        target: "_blank",
                                        state: {
                                          user: {
                                            node: transaction.node.player,
                                          },
                                        },
                                      });
                                    }}
                                  />
                                );
                              }
                            )}
                            <tr className="text-center pt-3">
                              <td colSpan={12}>
                                <Link
                                  to="/transaction-withdraw/pending"
                                  className="text-success"
                                >
                                  <div>View All</div>
                                  <div>
                                    <i className="bx bx-chevrons-down bx-fade-down"></i>
                                  </div>
                                </Link>
                              </td>
                            </tr>
                          </>
                        )}
                      {!loadingWithdrawal &&
                        withdrawalData &&
                        withdrawalData.transactions.edges.length === 0 && (
                          <tr className="text-center pt-3">
                            <td colSpan={12}>{t("No Transactions Found")}</td>
                          </tr>
                        )}
                      {loadingWithdrawal && (
                        <tr className="text-center pt-3">
                          <td colSpan={12}>
                            <Link to="#" className="text-success">
                              <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                              Loading
                            </Link>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>
            {meData &&
              (Admin.includes(meData.me.role) ||
                Developer.includes(meData.me.role)) && (
                <Card className="transactions-page">
                  <CardBody>
                    <CardTitle className="mb-4">Recent 5 On Hold</CardTitle>
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <thead>
                          <tr className="font-size-12">
                            <th>Serial No</th>
                            <th>Created At</th>
                            <th>User</th>
                            <th>Image</th>
                            <th>Amount</th>
                            <th>Bonus</th>
                            <th>Total Amount</th>
                            <th>Reward Point</th>
                            <th>Agent Point</th>
                            <th>Movement</th>
                            <th>Updated At</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {!loadingHoldData &&
                            onHoldData &&
                            onHoldData.transactions.edges.length > 0 && (
                              <>
                                {onHoldData.transactions.edges.map(
                                  (transaction, index) => {
                                    return (
                                      <TransactionItem
                                        key={index}
                                        refetch={refetchOnHold}
                                        transaction={transaction}
                                        onDone={refetchOnHold}
                                        isAtDashboard
                                        onManageInWorkbench={() => {
                                          history.push({
                                            pathname: "/cashier-workbench",
                                            target: "_blank",
                                            state: {
                                              user: {
                                                node: transaction.node.player,
                                              },
                                            },
                                          });
                                        }}
                                      />
                                    );
                                  }
                                )}
                                <tr className="text-center pt-3">
                                  <td colSpan={12}>
                                    <Link
                                      to="/transaction-deposit/pending"
                                      className="text-success"
                                    >
                                      <div>View All</div>
                                      <div>
                                        <i className="bx bx-chevrons-down bx-fade-down"></i>
                                      </div>
                                    </Link>
                                  </td>
                                </tr>
                              </>
                            )}
                          {!loadingHoldData &&
                            onHoldData &&
                            onHoldData.transactions.edges.length === 0 && (
                              <tr className="text-center pt-3">
                                <td colSpan={12}>
                                  {t("No Transactions Found")}
                                </td>
                              </tr>
                            )}
                          {loadingHoldData && (
                            <tr className="text-center pt-3">
                              <td colSpan={12}>
                                <Link to="#" className="text-success">
                                  <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                                  Loading
                                </Link>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              )}
          </Container>
        </div>
      }
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(Dashboard));

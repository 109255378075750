import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLazyQuery, useQuery } from "react-apollo";
import { GET_PLAYER_DOWNLINES } from "../../../graphql/query/GET_PLAYER_DOWNLINES";
import { Button, Pagination, Table } from "@mui/material";
import { CSVLink } from "react-csv";
import { ME } from "../../../graphql/query";
import moment from "moment";
import _ from "lodash";

function PlayerDownlines(props) {
  const { player } = props;
  const history = useHistory();
  const csvLink = React.useRef(null);
  const take = 50;
  const [loadingDownload, setLoadingDownload] = React.useState(false);
  const [csvData, setCSVData] = React.useState([]);

  const [page, setPage] = useState(1);

  const { data: meData } = useQuery(ME);
  const [getPlayerDownlines, { data, loading }] =
    useLazyQuery(GET_PLAYER_DOWNLINES);

  const [getCSVData, { data: csvTempData }] = useLazyQuery(
    GET_PLAYER_DOWNLINES,
    {
      variables: {
        id: player.id,
        paging: {
          first: take,
        },
      },
      fetchPolicy: "no-cache",
    }
  );

  useEffect(() => {
    getPlayerDownlines({
      variables: {
        id: player.id,
        paging: {
          first: take,
        },
      },
      fetchPolicy: "network-only",
    });
  }, []);

  useEffect(() => {
    if (csvData.length > 0) {
      if (csvData.length < csvTempData.users.totalCount) {
        getCSVData({
          variables: {
            id: player.id,
            paging: {
              first: take,
              after: csvTempData.users.pageInfo.endCursor,
            },
          },
        });
      } else if (csvData.length > 0 && csvLink && loadingDownload) {
        csvLink.current.link.click();
        setLoadingDownload(false);
        setCSVData([]);
      }
    }
  }, [csvData]);

  useEffect(() => {
    if (csvTempData && loadingDownload) {
      setCSVData((prevData) => [...prevData, ...csvTempData.users.edges]);
    }
  }, [csvTempData]);

  const DownloadToCSV = () => {
    getCSVData();
    setLoadingDownload(true);
  };

  const CSVDownload = () => {
    return (
      <>
        <button
          className="waves-effect btn btn-outline-secondary button-add-user ml-2"
          type="button"
          onClick={DownloadToCSV}
        >
          Download (CSV){" "}
          {loadingDownload && <i className="bx bx-hourglass bx-spin mr-3"></i>}
        </button>
        <CSVLink
          ref={csvLink}
          filename={`downlines-list-${player.username}.csv`}
          className="hidden"
          data={
            csvData.length > 0
              ? csvData.map((item) => {
                  return {
                    ...item.node,
                    phoneNo: `=""${item.node.phoneNo}""`,
                    telegramId: `=""${item.node.telegramId}""`,
                    lastDepositAt: moment(item.node.lastDepositAt).format(
                      "YYYY-MM-DD HH:mm:ss"
                    ),
                  };
                })
              : []
          }
          headers={[
            { label: "Serial Number", key: "serialNo" },
            { label: "Username", key: "username" },
            { label: "Name", key: "name" },
            // { label: 'Main Wallet ($)', key: 'mainWallet' },
            // { label: 'Reward Wallet (RP)', key: 'rewardWallet' },
            // { label: 'Agent Wallet (AP)', key: 'agentWallet' },
            { label: "Mobile Number", key: "phoneNo" },
            { label: "Joined At", key: "joinedAt" },
            { label: "Telegram Id", key: "telegramId" },
            { label: "Telegram Username", key: "telegramUsername" },
            { label: "Last Deposit At", key: "lastDepositAt" },
            { label: "Active?", key: "isActive" },
          ]}
        ></CSVLink>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="player-container">
        {data && data.users.edges.length > 0 && (
          <div className="d-flex space-between mb-2">
            <div></div>
            <div className="button-add-user-container">
              {meData &&
                (meData.me.role === "Developer" ||
                  meData.me.role === "Admin") && <CSVDownload />}
            </div>
          </div>
        )}
        {loading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin mr-2"></i> Loading{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}

        {data && (
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead>
                <tr className="font-size-12">
                  <th>Username</th>
                  <th>Mobile Number</th>
                  <th>Telegram</th>
                  <th>Last Deposit Active</th>
                </tr>
              </thead>
              <tbody className="table-body">
                {data.users.edges.map((item, index) => {
                  return (
                    <tr>
                      <td>
                        <div
                          onClick={() => {
                            history.push({
                              pathname: "/cashier-workbench",
                              target: "_blank",
                              state: {
                                user: {
                                  ...item,
                                },
                              },
                            });
                          }}
                        >
                          <Link>
                            {index + 1 + (page - 1) * take}. {item.node.name}
                          </Link>
                        </div>
                      </td>
                      <td>{item.node.phoneNo ? item.node.phoneNo : "-"}</td>
                      <td>
                        <div>
                          {item.node.telegramId ? item.node.telegramId : "-"}
                        </div>
                        <div>
                          {item.node.telegramUsername
                            ? item.node.telegramUsername
                            : "-"}
                        </div>
                      </td>
                      <td>
                        {item.node.lastDepositAt
                          ? moment(item.node.lastDepositAt).format(
                              "YYYY-MM-DD HH:mm:ss"
                            )
                          : "-"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <div className="d-flex space-between mb-2">
              <div></div>
              <div className="pa">
                <Pagination
                  count={Math.ceil(data.users.totalCount / take)}
                  color="primary"
                  page={page}
                  onChange={(event, value) => {
                    setPage(value);
                    getPlayerDownlines({
                      variables: {
                        id: player.id,
                        paging: {
                          first: take,
                          after:
                            value === 1 ? null : data.users.pageInfo.endCursor,
                        },
                      },
                      fetchPolicy: "network-only",
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
        {data && data.users.edges.length === 0 && (
          <div className="text-center my-3">No Downlines</div>
        )}
      </div>
    </React.Fragment>
  );
  // }
}

const mapStatetoProps = (state) => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(PlayerDownlines));

import gql from 'graphql-tag';
import { MarketingFragment } from '../fragments/marketing.campaign.fragment';

export const GET_CAMPAIGN = gql`
    query (
        $id: ID!
    ) {
        marketingCampaign (
            id: $id
        ) {
            ...MarketingCampaign
        }
    }
    ${MarketingFragment}
`;
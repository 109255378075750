import gql from 'graphql-tag';

export const SHOW_WALLET_BALANCE_OF = gql`
    query (
        $walletId: String!
        $timestamp: String
    ) {
        showWalletBalanceOf (
            walletId: $walletId
            timestamp: $timestamp
        )
    }
`;



import React, { Fragment } from 'react';
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation, useQuery } from 'react-apollo';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import { CREATE_ONE_TAG } from '../../graphql/mutation/CREATE_ONE_TAG';
import { GET_ENUM } from '../../graphql/query';

export default function NewTag(props) {

    const { modal, toggleModal } = props;

    const { data: tagData } = useQuery(GET_ENUM, {
        variables: {
            name: "TagType"
        }
    })

    const [createTag] = useMutation(CREATE_ONE_TAG)


    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="md" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-game-page">
                <ModalHeader toggle={toggleModal}>
                    Add New Tag
            </ModalHeader>
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                createTag({
                                    variables: {
                                        input: {
                                            tag: {
                                                name: values.tagname,
                                                tagType: values.tagType
                                            }
                                        }
                                    }
                                }).then((val) => {

                                    if (val) {
                                        toggleModal();
                                    }

                                }).catch((e) => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                })


                            }
                            }
                            onInvalidSubmit={() => {

                            }}
                        >
                            <Row>
                                <Col lg="12">
                                    <AvField
                                        label={'Tag Type'}
                                        type="select"
                                        name="tagType"
                                        className="custom-select"
                                        value={tagData && tagData.__type.enumValues[0].name}
                                    >
                                        {
                                            tagData &&
                                            tagData.__type.enumValues.map(({ name }, index) => {
                                                return (
                                                    <option key={index} value={name}>{name}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                </Col>
                                <Col lg="12">
                                    <AvField
                                        label="Tag Name"
                                        name="tagname"
                                        type="text"
                                        placeholder="Enter Tag Name"
                                        errorMessage="Enter Tag Name"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
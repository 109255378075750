import React, { useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery, useSubscription } from '@apollo/react-hooks';
import ListContact from "./list-contact";
import NewUser from "./new-user";

import { GET_USERS } from '../../graphql/query/GET_USERS';
import { CREATED_USER } from '../../graphql/subscription/CREATED_USER';
import EditUser from './edit-user';

import _ from 'lodash';
import ResetPassword from './reset-password';

import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { ME } from '../../graphql/query';
import UserTableItem from './table-contact';
import InfiniteScroll from 'react-infinite-scroll-component';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';


function ContactsList(props) {

    const first = 50

    const [addUser, setAddUser] = React.useState(false)
    const [editUser, setEditUser] = React.useState(null)
    const [resetPassword, setResetPassword] = React.useState(null)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [isSearch, setIsSearch] = React.useState(false)
    const [newData, setNewData] = React.useState(null)
    const [layoutToggle, setLayoutToggle] = React.useState(false)

    const history = useHistory();

    const { loading, fetchMore, data, refetch } = useQuery(GET_USERS, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: search !== '' ? {
                and: [
                    {
                        role: { notIn: ["Player"] }
                    },
                    {
                        or: [
                            { name: { iLike: `%${search}%` } },
                            { username: { iLike: `%${search}%` } },
                            { phoneNo: { iLike: `%${search}%` } },
                        ]
                    },
                    props.filter
                ]
            } : {
                and: [
                    {
                        role: { notIn: ["Player"] }
                    }, props.filter]
            },
            sorting: [...props.sort, { field: "username", direction: "ASC" }]
        },
        notifyOnNetworkStatusChange: true
    })

    const { data: meData } = useQuery(ME);

    const { data: userCreatedData } = useSubscription(CREATED_USER, {
        variables: {
            createdBy: { neq: meData ? meData.me.username : '' }
        }
    })

    React.useEffect(() => {
        if (userCreatedData) {
            setNewData(userCreatedData.createdUser)
        }
    }, [userCreatedData])

    React.useEffect(() => {
        if (data) {
            setAfterCursor(data.users.pageInfo.endCursor)
            setPageInfo(data.users.pageInfo)
        }
    }, [data])

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: search !== '' ? {
                        and: [
                            {
                                role: { notIn: ["Player", "Developer"] }
                            },
                            {
                                or: [
                                    { name: { iLike: `%${search}%` } },
                                    { username: { iLike: `%${search}%` } },
                                    { phoneNo: { iLike: `%${search}%` } },
                                ]
                            },
                            props.filter
                        ]
                    } : {
                        and: [{
                            role: { notIn: ["Player", "Developer"] }
                        }, props.filter]
                    },
                    sorting: props.sort
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.users.edges;
                    const pageInfo = fetchMoreResult.users.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            users: {
                                __typename: previousResult.users.__typename,
                                edges: _.uniq([...previousResult.users.edges, ...newEdges]),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <div className="page-content users-link">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="System" breadcrumbItem="Users List" />
                        <div className="search-bar-container">
                            <div className="button-add-user-container">
                                <div className="app-search d-none d-lg-block">
                                    <div className="position-relative">
                                        <input
                                            value={searchValue}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setSearchValue(e.target.value)
                                                debouncedSave(e.target.value)
                                            }}
                                        />
                                        <span className="bx bx-search-alt"></span>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dropdown d-inline-block d-lg-none ml-2">
                                        <button
                                            type="button"
                                            className="btn header-item noti-icon waves-effect"
                                            id="page-header-search-dropdown"
                                            onClick={() => { setIsSearch(!isSearch) }}>
                                            <i className="mdi mdi-magnify"></i>
                                        </button>
                                        <div
                                            className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                            aria-labelledby="page-header-search-dropdown"
                                        >
                                            <form className="p-3">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input
                                                            value={searchValue}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={(e) => {
                                                                setSearchValue(e.target.value)
                                                                debouncedSave(e.target.value)
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="submit">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                            </div>


                            <div className="button-add-user-container">
                                <div className="filter-container button-filter-container">
                                    <button type="button" className="waves-effect btn btn-outline-secondary button-add-user" onClick={() => {
                                        props.toggleRightSidebar();
                                    }}>
                                        <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                    </button>
                                </div>
                                <div className="filter-container button-filter-container px-2">
                                    <button type="button" className="waves-effect btn btn-outline-secondary button-add-user" onClick={() => {
                                        setLayoutToggle(!layoutToggle)
                                    }}>
                                        <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                                    </button>
                                </div>
                                {
                                    meData &&
                                    (meData.me.role === "Developer" || meData.me.role === "Admin") &&
                                    <button type="button" className="waves-effect btn btn-outline-secondary button-add-user" onClick={() => { setAddUser(true) }}>
                                        <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New User
                                    </button>
                                }
                            </div>

                            <div className={`reload-container ${newData ? 'active' : ''}`}>
                                <Card>
                                    <CardBody onClick={() => {
                                        window.location.reload()
                                    }}>
                                        <div className="reload-title">
                                            <i className='bx bx-refresh'></i>Reload
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <Row>
                            {
                                !layoutToggle &&
                                data &&
                                meData &&
                                <Card className="w-100">
                                    <InfiniteScroll
                                        dataLength={
                                            meData.me.role === "Developer" ?
                                                data.users.edges.length :
                                                _.filter(data.users.edges, user => {
                                                    return user.node.role !== "Developer" && user.node.role !== "Bot"
                                                }).length
                                        } //This is important field to render the next data
                                        next={() => setIsFetchMore(true)}
                                        hasMore={pageInfo.hasNextPage}
                                        loader={
                                            <div className="text-center my-3">
                                                <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                            </div>
                                        }
                                        endMessage={
                                            <div className="text-center my-3">
                                                <div>Yay! You have seen it all</div>
                                            </div>
                                        }
                                    >
                                        <div className="overflow-auto">
                                            <Table className="table mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>Serial No</th>
                                                        <th>Username</th>
                                                        <th>Name</th>
                                                        <th>Role</th>
                                                        <th>Mobile Number</th>
                                                        <th>Login At</th>
                                                        <th>Status</th>
                                                        {
                                                            (meData.me.role === "Developer" || meData.me.role === "Admin") &&
                                                            <th>Actions</th>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data &&
                                                        (meData.me.role === "Developer" ?
                                                            data.users.edges :
                                                            _.filter(data.users.edges, user => {
                                                                return user.node.role !== "Developer" && user.node.role !== "Bot"
                                                            })).map((user, key) =>
                                                                <UserTableItem user={user}
                                                                    key={"_user_" + key}
                                                                    me={meData.me}
                                                                    onEdit={() => {
                                                                        if (meData.me.role === "Developer" || meData.me.role === "Admin") {
                                                                            setEditUser(user.node)
                                                                        }
                                                                    }}
                                                                    onResetPassword={() => {
                                                                        setResetPassword(user.node)
                                                                    }}

                                                                    onManageUser={() => {
                                                                        history.push({
                                                                            "pathname": "/profile",
                                                                            state: { "user": user.node }
                                                                        })
                                                                    }}>
                                                                </UserTableItem>
                                                            )
                                                    }
                                                    {
                                                        data &&
                                                        (meData.me.role === "Developer" ?
                                                            data.users.edges :
                                                            _.filter(data.users.edges, user => {
                                                                return user.node.role !== "Developer" && user.node.role !== "Bot"
                                                            })).length === 0 &&
                                                        <tr className="text-center pb-2">
                                                            <td colSpan={12}>
                                                                <div className="no-user pt-0">No User Found</div>
                                                            </td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </Table>
                                        </div>
                                    </InfiniteScroll>
                                </Card>
                            }
                            {
                                layoutToggle &&
                                data &&
                                <InfiniteScroll
                                    dataLength={
                                        meData.me.role === "Developer" ?
                                            data.users.edges.length :
                                            _.filter(data.users.edges, user => {
                                                return user.node.role !== "Developer" && user.node.role !== "Bot"
                                            }).length
                                    }
                                    next={() => setIsFetchMore(true)}
                                    hasMore={pageInfo.hasNextPage}
                                    loader={
                                        <div className="text-center my-3">
                                            <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                        </div>
                                    }
                                    endMessage={
                                        <div className="text-center my-3">
                                            <div>Yay! You have seen it all</div>
                                        </div>
                                    }
                                >
                                    <Col lg="12">
                                        <Row>
                                            {
                                                data &&
                                                data.users.edges.map((user, key) =>
                                                    <ListContact
                                                        user={user}
                                                        key={"_user_" + key}
                                                        onEdit={() => {
                                                            setEditUser(user.node)
                                                        }}
                                                        onResetPassword={() => {
                                                            setResetPassword(user.node)
                                                        }}

                                                        onManageUser={() => {
                                                            history.push({
                                                                "pathname": "/profile",
                                                                state: { "user": user.node }
                                                            })
                                                        }
                                                        }
                                                    />
                                                )
                                            }
                                            {
                                                data &&
                                                data.users.edges.length === 0 &&
                                                <Col xl="12" sm="12">
                                                    <div className="no-user">No User Found</div>
                                                </Col>
                                            }
                                        </Row>
                                    </Col>
                                </InfiniteScroll>
                            }
                        </Row>
                        <div className="load-more-container">
                            {
                                (loading) &&
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                </Col>
                            }
                        </div>
                        <NewUser
                            modal={addUser}
                            toggleModal={() => {
                                refetch()
                                setAddUser(!addUser)
                            }}
                        />
                        <EditUser
                            modal={!_.isEmpty(editUser)}
                            toggleModal={() => {
                                setEditUser(null)
                            }}
                            user={editUser}
                        />
                        <ResetPassword
                            modal={!_.isEmpty(resetPassword)}
                            toggleModal={() => {
                                setResetPassword(null)
                            }}
                            user={resetPassword}
                        />
                    </Container>
                </div>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.User };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(ContactsList));

// export default ContactsList;
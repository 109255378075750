import React, { useRef } from 'react';
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery } from '@apollo/react-hooks';

import _ from 'lodash';

import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import 'react-medium-image-zoom/dist/styles.css'
import { GET_BANNERS, ME } from '../../graphql/query';
import NewBanner from './new-banner';
import EditBanner from './edit-banner';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';

function BannerList(props) {

    const [addBanner, setAddBanner] = React.useState(false)
    const [editBanner, setEditBanner] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState('')
    const [editItem, setEditItem] = React.useState(null)
    const [bannerItem, setBannerItem] = React.useState(null)
    // const [layoutToggle, setLayoutToggle] = React.useState(false)

    const onEdit = (banner) => {
        setBannerItem(banner)
        setEditBanner(true)
    }

    const { data: bannerData } = useQuery(GET_BANNERS, {
        variables: {
            filter: {
                and: [
                    {
                        or: [
                            { name: { iLike: `%${search}%` } }
                        ]
                    },
                ]
            },
            sorting: {
                field: "createdAt",
                direction: "DESC"
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <div className="page-content banner-page">
                    <Container fluid>
                        <Breadcrumbs title="Landing Settings" breadcrumbItem="Banners" />
                        <div className="search-bar-container">
                            <div className="button-add-banner-container">
                                <div className="app-search d-none d-lg-block">
                                    <div className="position-relative">
                                        <input
                                            value={searchValue}
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={(e) => {
                                                setSearchValue(e.target.value)
                                                debouncedSave(e.target.value)
                                            }}
                                        />
                                        <span className="bx bx-search-alt"></span>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="dropdown d-inline-block d-lg-none ml-2">
                                        <button
                                            type="button"
                                            className="btn header-item noti-icon waves-effect"
                                            id="page-header-search-dropdown"
                                            onClick={() => { setIsSearch(!isSearch) }}>
                                            <i className="mdi mdi-magnify"></i>
                                        </button>
                                        <div
                                            className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                            aria-labelledby="page-header-search-dropdown"
                                        >
                                            <form className="p-3">
                                                <div className="form-group m-0">
                                                    <div className="input-group">
                                                        <input
                                                            value={searchValue}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search..."
                                                            onChange={(e) => {
                                                                setSearchValue(e.target.value)
                                                                debouncedSave(e.target.value)
                                                            }}
                                                        />
                                                        <div className="input-group-append">
                                                            <button className="btn btn-primary" type="submit">
                                                                <i className="mdi mdi-magnify"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className="button-add-banner-container">
                                {/* <div className="filter-container button-filter-container mr-2">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-banner" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div> */}
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-banner" onClick={() => { setAddBanner(true) }}>
                                    <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Banner
                                </button>
                            </div>
                        </div>
                        <Row>
                            {
                                bannerData &&
                                bannerData.banners.edges.length > 0 &&
                                bannerData.banners.edges.map((banner, key) =>
                                    <Col xl="3" sm="6" lg="4" key={key}>
                                        <Card>
                                            <CardBody style={{ padding: '15px 1.25rem' }}>
                                                <Row>
                                                    <Col>
                                                        <div className="pb-3">
                                                            <img
                                                                className="banner-image-list"
                                                                src={banner.node.bannerUrl}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                        onEdit(banner)
                                                    }}>
                                                        <div>
                                                            <div className="header">Banner Name</div>
                                                            <div className="font-size-14"><div className="text-dark">{banner.node.name}</div></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                        onEdit(banner)
                                                    }}>
                                                        <div>
                                                            <div className="header">Redirect Url</div>
                                                            <div className="font-size-14"><div className="text-dark">{banner.node.redirectUrl ? banner.node.redirectUrl : '-'}</div></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                        onEdit(banner)
                                                    }}>
                                                        <div>
                                                            <div className="header">Active?</div>
                                                            <div className="font-size-14"><div className="text-dark">{banner.node.isActive ? 'true' : 'false'}</div></div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="dropdown-actions">
                                                        <ButtonDropdown
                                                            isOpen={editItem === banner}
                                                            toggle={() => {
                                                                if (editItem) {
                                                                    setEditItem(null)
                                                                } else {
                                                                    setEditItem(banner)
                                                                }
                                                            }}
                                                        >
                                                            <DropdownToggle caret color="secondary">
                                                                Actions <i className="mdi mdi-chevron-down"></i>
                                                            </DropdownToggle>
                                                            <DropdownMenu right>
                                                                <DropdownItem onClick={() => {
                                                                    setBannerItem(banner)
                                                                    setEditBanner(true)
                                                                }}>Edit Banner</DropdownItem>
                                                            </DropdownMenu>
                                                        </ButtonDropdown>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            }
                            {
                                bannerData &&
                                bannerData.banners.edges.length === 0 &&
                                <Col xl="12" sm="12">
                                    <div className="text-center">No Banner Found</div>
                                </Col>
                            }
                        </Row>
                        <NewBanner
                            modal={addBanner}
                            toggleModal={() => {
                                setAddBanner(!addBanner)
                            }}
                        />
                        <EditBanner
                            modal={editBanner}
                            banner={bannerItem}
                            toggleModal={() => {
                                setEditBanner(!editBanner)
                            }}
                        />
                    </Container>
                </div>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(BannerList));

// export default ContactsList;

import React from 'react';
import { Link } from "react-router-dom";
import {
    DropdownToggle,
    ButtonDropdown
} from "reactstrap";
import classnames from 'classnames';
import moment from 'moment';
import Switch from "react-switch";
import { SYNC_KIOSK_ACCOUNT_BALANCE } from '../../graphql/mutation/SYNC_KIOSK_ACCOUNT';
import { useMutation } from 'react-apollo';
import { FORCE_QUIT_KIOSK_ACCOUNT } from '../../graphql/mutation/FORCE_QUIT_KIOSK_ACCOUNT';
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';

function KioskAccountTableItem(props) {

    const { kioskAccount, dropDown, onEdit, onToggleActive, showLoginDetails, goToPlatform, onToggleBotEnable, onToggleDefault, onToggleAutoSyncMode, meData } = props;

    const [drp_link, setDrpLink] = React.useState(false)

    const [syncSingleGame, { loading }] = useMutation(SYNC_KIOSK_ACCOUNT_BALANCE);
    const [forceQuitKioskAccount, { loading: loadingForceQuit }] = useMutation(FORCE_QUIT_KIOSK_ACCOUNT);

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };
    return <React.Fragment>
        <tr className={classnames({ "kiosk-disabled": !kioskAccount.node.isDefault })}>
            {
                !showLoginDetails &&
                <td>
                    <div className="align-center">
                        <Link target="_blank" to={{ pathname: kioskAccount.node.platform.kioskPanelUrl }} className="align-center">
                            <i className="bx bxs-dashboard font-size-18" color="primary"></i>
                        </Link>
                        <div className="cursor-pointer pl-2" onClick={goToPlatform} >{kioskAccount.node.platform.displayName}</div>
                    </div>
                </td>
            }
            <td> <div className="cursor-pointer " onClick={onEdit} > {kioskAccount.node.username}</div></td>
            {showLoginDetails && <td><div className="cursor-pointer " onClick={onEdit} > {kioskAccount.node.password}</div></td>}
            {showLoginDetails && <td><div className="cursor-pointer " onClick={onEdit} > {kioskAccount.node.secondPassword}</div></td>}
            <td className="d-inline-flex">
                <div>
                    {<div>{kioskAccount.node.balance != null ? kioskAccount.node.balance : "-"}</div>}
                </div>
            </td>
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <td><div className="cursor-pointer ">
                    <Switch
                        readOnly={true}
                        uncheckedIcon={<Offsymbol text="NO" />}
                        checkedIcon={<OnSymbol text="YES" />}
                        className="mr-1"
                        onColor="#34c38f"
                        offColor="#f46a6a"
                        onChange={() => {
                            onToggleActive()
                        }
                            // this.setState({ switch3: !this.state.switch3 })
                        }
                        checked={kioskAccount.node.isActive}
                    />

                </div></td>
            }
            {
                showLoginDetails &&
                <td><div className="cursor-pointer ">
                    <Switch
                        readOnly={true}
                        uncheckedIcon={<Offsymbol text="NO" />}
                        checkedIcon={<OnSymbol text="YES" />}
                        className="mr-1"
                        onColor="#34c38f"
                        offColor="#f46a6a"
                        onChange={() => {
                            onToggleDefault()
                        }
                            // this.setState({ switch3: !this.state.switch3 })
                        }
                        checked={kioskAccount.node.isDefault}
                    />

                </div></td>
            }
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <td>
                    <div className="cursor-pointer ">
                        <Switch
                            readOnly={true}
                            disabled={true}
                            uncheckedIcon={<Offsymbol text="OFF" />}
                            checkedIcon={<OnSymbol text="ON" />}
                            className="mr-1"
                            onColor="#f46a6a"
                            offColor="#34c38f"
                            onChange={() => { }
                            }
                            checked={kioskAccount.node.maintenanceMode}
                        />
                    </div>
                </td>
            }
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <td>
                    <div className="cursor-pointer ">
                        <Switch
                            readOnly={true}
                            uncheckedIcon={<Offsymbol text="NO" />}
                            checkedIcon={<OnSymbol text="YES" />}
                            className="mr-1"
                            onColor="#34c38f"
                            offColor="#f46a6a"
                            onChange={() => {
                                onToggleBotEnable()
                            }
                                // this.setState({ switch3: !this.state.switch3 })
                            }
                            checked={kioskAccount.node.isBotEnabled}
                        />
                    </div>
                </td>
            }
            {
                meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                <td>
                    <div className="cursor-pointer ">
                        <Switch
                            readOnly={true}
                            uncheckedIcon={<Offsymbol text="NO" />}
                            checkedIcon={<OnSymbol text="YES" />}
                            className="mr-1"
                            onColor="#34c38f"
                            offColor="#f46a6a"
                            onChange={() => {
                                onToggleAutoSyncMode()
                            }
                                // this.setState({ switch3: !this.state.switch3 })
                            }
                            checked={kioskAccount.node.autoSyncMode}
                        />
                    </div>
                </td>
            }
            <td >
                <div className="d-flex">
                    <span className="mx-1">{kioskAccount.node.lastSyncAt ? moment(kioskAccount.node.lastSyncAt).calendar() : "-"}</span>
                    <div style={{ whiteSpace: "nowrap" }}>
                        <button type="button" className="py-0 btn btn-primary waves-effect waves-light font-size-11 action-button" onClick={() => {
                            syncSingleGame({ variables: { input: kioskAccount.node.id } })
                                .then(({ data: val }) => {
                                })
                                .catch((err) => { });
                        }}>
                            <div className="">
                                {
                                    loading &&
                                    <i className="bx bx-hourglass bx-spin font-size-10 align-middle mr-2"></i>
                                }
                                Sync
                            </div>
                        </button>
                        {
                            meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                            <button type="button" className="py-0 btn btn-danger waves-effect waves-light font-size-11 action-button ml-1" onClick={() => {
                                forceQuitKioskAccount({
                                    variables: {
                                        id: kioskAccount.node.id
                                    }
                                })
                                    .then(({ data: val }) => {
                                    })
                                    .catch((err) => { });
                            }}>
                                <div className="">
                                    {
                                        loadingForceQuit &&
                                        < i className="bx bx-hourglass bx-spin font-size-10 align-middle mr-2"></i>
                                    }
                                    Force Quit
                                </div>
                            </button>
                        }
                    </div>
                </div>
            </td>
            <td><div className={classnames({
                "font-size-14": true,
                "text-dark": kioskAccount.node.messageLabelType === 'Info',
                "text-success": kioskAccount.node.messageLabelType === 'Success',
                "text-danger": kioskAccount.node.messageLabelType === 'Error',
                "text-warning": kioskAccount.node.messageLabelType === 'Warning'
            })}
            >{kioskAccount.node.message ? kioskAccount.node.message : "-"}</div></td>

            {showLoginDetails && <td><ButtonDropdown
                isOpen={drp_link}
                toggle={() =>
                    setDrpLink(!drp_link)
                }
            >
                <DropdownToggle caret color="secondary">
                    Actions <i className="mdi mdi-chevron-down"></i>
                </DropdownToggle>
                {dropDown}
            </ButtonDropdown>
            </td>}
        </tr>
    </React.Fragment >
}

export default KioskAccountTableItem;
import moment from 'moment';
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';
import { FIND_OR_CREATE_MAIN_CREDIT_WALLET, RECALCULATE_WALLET_BALANCE } from '../../graphql/mutation';
import { ME } from '../../graphql/query';

function MainWalletView(props) {
    const { player, onMovement, onUpdateWalletId, onRefetch, onRefetchComplete } = props

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    const [getMainCreditWallet, { data, loading }] = useMutation(FIND_OR_CREATE_MAIN_CREDIT_WALLET, {
        variables: {
            userId: player.id
        }
    })

    const [recalculateWalletBalance, { loading: loadingBalance }] = useMutation(RECALCULATE_WALLET_BALANCE)

    React.useEffect(() => {
        getMainCreditWallet()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (onRefetch === 'main') {
            getMainCreditWallet().then(() => {
                onRefetchComplete()
            })
        }
    }, [onRefetch]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            {
                loading &&
                <div className="text-center mb-3">
                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Loading</Link>
                </div>
            }
            {
                !loading &&
                data &&
                <div className="stat-component">
                    <div className="border p-3 rounded">
                        <div className="d-flex align-items-center mb-1">
                            <div className="avatar-xs mr-3">
                                <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-18 cursor-pointer" onClick={() => {
                                    let path = `wallet-details/${player.id}/${data.findOrCreateMainCreditWallet.id}`
                                    window.open(path, "_blank")
                                }}>
                                    <i className="bx bx-wallet"></i>
                                </span>
                            </div>
                            <div>
                                <p className="mb-1">Main Wallet</p>
                                <h5>${parseFloat(data.findOrCreateMainCreditWallet.balance).toFixed(2)}</h5>
                            </div>
                        </div>
                        <div className="text-muted mt-2">
                            <p className="mb-1">Last Updated</p>
                            <h5>{moment(data.findOrCreateMainCreditWallet.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</h5>
                        </div>
                        <div className="text-muted mt-2">
                            <p className="mb-1">Actions</p>
                            <div className="align-center">
                                <button className="btn btn-primary waves-effect waves-light font-size-14 action-button btn-sm" onClick={() => {
                                    recalculateWalletBalance({
                                        variables: {
                                            id: data.findOrCreateMainCreditWallet.id
                                        }
                                    })
                                }}>
                                    {
                                        !loadingBalance &&
                                        <i className="bx bx-sync font-size-14"></i>
                                    }
                                    {
                                        loadingBalance &&
                                        <i className="fas fa-spinner fa-pulse font-size-13"></i>
                                    }
                                </button>
                                {
                                    meData &&
                                    !OperatingUsers.includes(meData.me.role) &&
                                    !Operator.includes(meData.me.role) &&
                                    <>
                                        <div className="mr-2"></div>
                                        <button className="btn btn-primary font-size-14 btn-sm" onClick={() => {
                                            onUpdateWalletId(data.findOrCreateMainCreditWallet.id)
                                            onMovement('increment')
                                        }}>
                                            <i className="bx bx-plus"></i>
                                        </button>
                                    </>
                                }
                                {
                                    meData &&
                                    !OperatingUsers.includes(meData.me.role) &&
                                    <>
                                        <div className="mr-2"></div>
                                        <button className="btn btn-primary font-size-14 btn-sm" onClick={() => {
                                            onUpdateWalletId(data.findOrCreateMainCreditWallet.id)
                                            onMovement('decrement')
                                        }}>
                                            <i className="bx bx-minus"></i>
                                        </button>
                                    </>
                                }

                            </div>
                        </div>
                        <div className="text-muted mt-3 action-container">
                            <Link to={`wallet-details/${player.id}/${data.findOrCreateMainCreditWallet.id}`} target="_blank" className="btn btn-primary btn-sm">View more <i className="mdi mdi-arrow-right ml-1"></i></Link>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default connect()(withTranslation()(MainWalletView));
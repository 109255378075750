
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import _ from 'lodash';
import error from "../../assets/images/error-img.png";
class PlatformItem extends Component {

    state = {
        drp_link: false
    }

    render() {
        const { platform, onEdit, onManage, me } = this.props;
        const defaultKioskAccount = _.find(platform.node.kioskAccounts.edges, function (o) { return o.node.isDefault });

        return <React.Fragment>
            <Col xl="4" sm="6" lg="4">
                <Card>
                    <CardBody className="product-item">
                        <Row>
                            <Col lg="12">
                                <Row>
                                    <Col className="text-align pb-3 cursor-pointer " onClick={onEdit} >
                                        <img alt="img" className="product-image img-fluid mr-3" src={platform.node.imageUrl ? platform.node.imageUrl : error} />
                                        {
                                            me.role === 'Developer' &&
                                            <div className="pl-3">
                                                <div className="header">PLATFORM NAME</div>
                                                <div className="d-flex">
                                                    <div className="font-size-14">{platform.node.name}</div>
                                                </div>

                                            </div>
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-align pb-3 cursor-pointer " onClick={onEdit}>
                                        <div>
                                            <div className="header">Display Name</div>
                                            <div className="font-size-14">{platform.node.displayName ? platform.node.displayName : 'N/A'}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-align pb-3 cursor-pointer " onClick={onEdit}>
                                        <div>
                                            <div className="header">Kiosk Account</div>
                                            <div className="font-size-14">
                                                {
                                                    defaultKioskAccount &&
                                                    <>
                                                        <div className="text-align">{defaultKioskAccount.node.username}<i className="bx bx-badge-check text-success font-size-16 ml-2"></i></div>
                                                        <div className="text-align">{defaultKioskAccount.node.password}</div>
                                                    </>
                                                }
                                                <button type="button" className="py-0 btn btn-primary waves-effect waves-light font-size-11 action-button mt-2" onClick={onManage}>
                                                    <div className="">{`Manage All (${platform.node.kioskAccounts.totalCount})`}</div>
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-align pb-3 cursor-pointer " onClick={onEdit}>
                                        <div>
                                            <div className="header">UNIT PRICE</div>
                                            <div className="font-size-14">{platform.node.unitPrice}</div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-align pb-3 cursor-pointer " onClick={onEdit}>
                                        <div className="text-align">
                                            {
                                                platform.node.kioskPanelUrl &&
                                                <Link
                                                    to={platform.node.kioskPanelUrl}
                                                    className="text-dark"
                                                    onClick={() => {
                                                        if (platform.node.kioskPanelUrl) {
                                                            window.open(platform.node.kioskPanelUrl, "_blank")
                                                        }
                                                    }}
                                                >
                                                    <i className="bx bxs-dashboard dashboard font-size-20 mr-2"></i>
                                                </Link>
                                            }
                                            {
                                                platform.node.website &&
                                                <Link
                                                    to={platform.node.website}
                                                    className="text-dark"
                                                    onClick={() => {
                                                        if (platform.node.website) {
                                                            window.open(platform.node.website, "_blank")
                                                        }
                                                    }}
                                                >
                                                    <i className="bx bxl-chrome chrome font-size-20 mr-2"></i>
                                                </Link>
                                            }
                                            {
                                                platform.node.iOSApp &&
                                                <Link
                                                    to={platform.node.iOSApp}
                                                    className="text-dark"
                                                    onClick={() => {
                                                        if (platform.node.iOSApp) {
                                                            window.open(platform.node.iOSApp, "_blank")
                                                        }
                                                    }}
                                                >
                                                    <i className="bx bxl-apple ios font-size-20 mr-2"></i>
                                                </Link>
                                            }
                                            {
                                                platform.node.androidApp &&
                                                <Link
                                                    to={platform.node.androidApp}
                                                    className="text-dark"
                                                    onClick={() => {
                                                        if (platform.node.androidApp) {
                                                            window.open(platform.node.androidApp, "_blank")
                                                        }
                                                    }}
                                                >
                                                    <i className="bx bxl-android android font-size-20 mr-2"></i>
                                                </Link>
                                            }
                                        </div>
                                    </Col>

                                </Row>
                            </Col> </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment >
    }
}

export default PlatformItem;
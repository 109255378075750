import gql from 'graphql-tag';
import { GameAccountFragment } from '../fragments/game-account.fragment';

export const CREATE_ONE_GAME_ACCOUNT_BY_KIOSK = gql`
    mutation(
        $input: CreateOneGameAccountByKioskInput!
    ) {
        createOneGameAccountByKiosk (input: $input) {
            ...GameAccountInfo
        }
    }
    ${GameAccountFragment}
`;
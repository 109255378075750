import React, { useRef } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { useQuery } from '@apollo/react-hooks';

import _ from 'lodash';

import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import NewBonus from './new-bonus';
import EditBonus from './edit-bonus';
import { GET_BONUSES } from '../../graphql/query/GET_BONUSES';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function BonusList(props) {
    const [addBonus, setAddBonus] = React.useState(false)
    const [editBonus, setEditBonus] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [newData] = React.useState(null)
    const [editItem, setEditItem] = React.useState(null)
    const [bonusItem, setBonusItem] = React.useState(null)
    const [layoutToggle, setLayoutToggle] = React.useState(false)
    const [searchValue, setSearchValue] = React.useState('')

    const onEdit = (bonus) => {
        setBonusItem(bonus)
        setEditBonus(true)
    }

    const { data: bonusData } = useQuery(GET_BONUSES, {
        variables: {
            filter: {
                and: [
                    {
                        or: [
                            { name: { iLike: `%${search}%` } }
                        ]
                    },
                    props.filter
                ]
            }
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content bonus-page">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Bonus" breadcrumbItem="Bonus" />
                    <div className="search-bar-container">
                        <div className="button-add-bonus-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="button-add-bonus-container">
                            <div className="filter-container button-filter-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div>
                            <div className="filter-container button-filter-container px-2">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus" onClick={() => {
                                    setLayoutToggle(!layoutToggle)
                                }}>
                                    <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div>
                            <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus" onClick={() => { setAddBonus(true) }}>
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Bonus
                            </button>
                        </div>

                        <div className={`reload-container ${newData ? 'active' : ''}`}>
                            <Card>
                                <CardBody onClick={() => {
                                    window.location.reload()
                                }}>
                                    <div className="reload-title">
                                        <i className='bx bx-refresh'></i>Reload
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    {!layoutToggle &&
                        <Card className="w-100">
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Bonus Name</th>
                                                <th>Bonus Type</th>
                                                <th>Bonus Computation</th>
                                                <th>Amount</th>
                                                <th>Minimum Deposit Amount</th>
                                                <th>Max Capped Amount</th>
                                                <th>Rollover Requirements</th>
                                                <th>Active?</th>
                                                <th>Actions</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                bonusData &&
                                                bonusData.bonuses.edges.length > 0 &&
                                                bonusData.bonuses.edges.map((bonus, key) =>
                                                    <tr key={"_bonus" + key}>
                                                        <td>
                                                            <div className="">
                                                                <Zoom>
                                                                    <img
                                                                        alt=""
                                                                        src={bonus.node.bannerUrl}
                                                                        // width="40"
                                                                        height="40"
                                                                    />
                                                                </Zoom>
                                                            </div>
                                                        </td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{bonus.node.name}</div></td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{bonus.node.bonusType}</div></td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{bonus.node.bonusComputation}</div></td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{parseFloat(bonus.node.amount).toFixed(2)}</div></td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{parseFloat(bonus.node.minDepositAmount).toFixed(2)}</div></td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{parseFloat(bonus.node.maxCappedAmount).toFixed(2)}</div></td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{parseFloat(bonus.node.rolloverMultiplier).toFixed(2)}</div></td>
                                                        <td><div className="cursor-pointer " onClick={() => {
                                                            onEdit(bonus)
                                                        }}>{bonus.node.isActive ? 'YES' : 'NO '}</div></td>
                                                        <td >
                                                            <Col className="dropdown-actions-table">
                                                                <ButtonDropdown
                                                                    isOpen={editItem === bonus}
                                                                    toggle={() => {
                                                                        if (editItem) {
                                                                            setEditItem(null)
                                                                        } else {
                                                                            setEditItem(bonus)
                                                                        }
                                                                    }}
                                                                >
                                                                    <DropdownToggle caret color="secondary">
                                                                        Actions <i className="mdi mdi-chevron-down"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu right>
                                                                        <DropdownItem onClick={() => {
                                                                            setBonusItem(bonus)
                                                                            setEditBonus(true)
                                                                        }}>Edit Bonus</DropdownItem>
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>
                                                            </Col>

                                                        </td>
                                                    </tr >
                                                )
                                            }
                                            {
                                                bonusData &&
                                                bonusData.bonuses.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-user pt-0">No Bonus Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>}
                    {layoutToggle && <Row>
                        {
                            bonusData &&
                            bonusData.bonuses.edges.length > 0 &&
                            bonusData.bonuses.edges.map((bonus, key) =>
                                <Col xl="3" sm="6" lg="4">
                                    <Card>
                                        <CardBody style={{ padding: '15px 1.25rem' }}>
                                            <Row>
                                                <Col>
                                                    <div className="pb-3">
                                                        <img
                                                            className="banner-image-list"
                                                            src={bonus.node.bannerUrl}
                                                            alt=""
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                    onEdit(bonus)
                                                }}>
                                                    <div>
                                                        <div className="header">Bonus Name</div>
                                                        <div className="font-size-14"><div className="text-dark">{bonus.node.name}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                    onEdit(bonus)
                                                }}>
                                                    <div>
                                                        <div className="header">Bonus Type</div>
                                                        <div className="font-size-14"><div className="text-dark">{bonus.node.bonusType}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                    onEdit(bonus)
                                                }}>
                                                    <div>
                                                        <div className="header">Bonus Computation</div>
                                                        <div className="font-size-14"><div className="text-dark">{bonus.node.bonusComputation}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                    onEdit(bonus)
                                                }}>
                                                    <div>
                                                        <div className="header">Amount</div>
                                                        <div className="font-size-14"><div className="text-dark">{parseFloat(bonus.node.amount).toFixed(2)}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                    onEdit(bonus)
                                                }}>
                                                    <div>
                                                        <div className="header">Minimum Deposit Amount</div>
                                                        <div className="font-size-14"><div className="text-dark">{parseFloat(bonus.node.minDepositAmount).toFixed(2)}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                    onEdit(bonus)
                                                }}>
                                                    <div>
                                                        <div className="header">Max Capped Amount</div>
                                                        <div className="font-size-14"><div className="text-dark">{parseFloat(bonus.node.maxCappedAmount).toFixed(2)}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={() => {
                                                    onEdit(bonus)
                                                }}>
                                                    <div>
                                                        <div className="header">Rollover Requirements</div>
                                                        <div className="font-size-14"><div className="text-dark">{parseFloat(bonus.node.rolloverMultiplier).toFixed(2)}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xl="12" sm="12" className="padding-bottom text-align">
                                                    <div>
                                                        <div className="header">Active?</div>
                                                        <div className="font-size-14"><div className="text-dark">{bonus.node.isActive ? 'YES' : 'NO '}</div></div>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="dropdown-actions">
                                                    <ButtonDropdown
                                                        isOpen={editItem === bonus}
                                                        toggle={() => {
                                                            if (editItem) {
                                                                setEditItem(null)
                                                            } else {
                                                                setEditItem(bonus)
                                                            }
                                                        }}
                                                    >
                                                        <DropdownToggle caret color="secondary">
                                                            Actions <i className="mdi mdi-chevron-down"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu right>
                                                            <DropdownItem onClick={() => {
                                                                setBonusItem(bonus)
                                                                setEditBonus(true)
                                                            }}>Edit Bonus</DropdownItem>
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )
                        }
                        {
                            bonusData &&
                            bonusData.bonuses.edges.length === 0 &&
                            <Col xl="12" sm="12">
                                <div className="text-center">No Bonus Found</div>
                            </Col>
                        }
                    </Row>}
                    {
                        addBonus &&
                        <NewBonus
                            modal={addBonus}
                            toggleModal={() => {
                                setAddBonus(!addBonus)
                            }}
                        />
                    }
                    {
                        editBonus &&
                        <EditBonus
                            modal={editBonus}
                            bonus={bonusItem}
                            toggleModal={() => {
                                setEditBonus(!editBonus)
                            }}
                        />
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Bonus };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(BonusList));

// export default ContactsList;

import React from 'react';
import { Col } from "reactstrap";
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";
import moment from 'moment';
import Switch from "react-switch";
import { UPDATE_ONE_USER } from '../../graphql/mutation';
import { useMutation } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';

function UserTableItem(props) {

    const [drp_link, setDrplink] = React.useState(false)

    const { user, onEdit, onResetPassword, onManageUser, me } = props;
    const [updateOneUser] = useMutation(UPDATE_ONE_USER);

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return (
        <React.Fragment>
            {
                me &&
                <tr>
                    <td><div className={(me.role === 'Developer' || me.role === 'Admin') && 'cursor-pointer'} onClick={onEdit}>{user.node.serialNo}</div></td>
                    <td><div className={(me.role === 'Developer' || me.role === 'Admin') && 'cursor-pointer'} onClick={onEdit}>{user.node.username}</div></td>
                    <td><div className={(me.role === 'Developer' || me.role === 'Admin') && 'cursor-pointer'} onClick={onEdit}>{user.node.name}</div></td>
                    <td><div className={(me.role === 'Developer' || me.role === 'Admin') && 'cursor-pointer'} onClick={onEdit}>{user.node.role}</div></td>
                    <td><div className={(me.role === 'Developer' || me.role === 'Admin') && 'cursor-pointer'} onClick={onEdit}>{user.node.phoneNo ? user.node.phoneNo : "-"}</div></td>
                    <td>{user.node.loginAt ? moment(user.node.loginAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}</td>
                    {
                        (me.role === "Developer" || me.role === "Admin") &&
                        <td>
                            <Switch
                                disabled={user.node.role === "Developer" || user.node.role === "Bot" || user.node.id === me.id}
                                uncheckedIcon={<Offsymbol text="NO" />}
                                checkedIcon={<OnSymbol text="YES" />}
                                className="mr-1"
                                onColor="#34c38f"
                                offColor="#f46a6a"
                                onChange={() => {
                                    updateOneUser({
                                        variables: {
                                            input: {
                                                update: {
                                                    isActive: !user.node.isActive
                                                },
                                                id: user.node.id
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                        }
                                    })
                                }}
                                checked={user.node.isActive}
                            />
                        </td>
                    }
                    {
                        (me.role !== "Developer" && me.role !== "Admin") &&
                        <td>{user.node.isActive ? 'Active' : 'Inactive'}</td>
                    }
                    {
                        (me.role === 'Developer' || me.role === 'Admin') &&
                        <td >
                            <Col className="dropdown-actions-table">
                                <ButtonDropdown
                                    isOpen={drp_link}
                                    toggle={() =>
                                        setDrplink(!drp_link)
                                    }
                                >
                                    <DropdownToggle caret color="secondary">
                                        Actions <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu right>
                                        <DropdownItem onClick={onEdit}>Edit User</DropdownItem>
                                        <DropdownItem onClick={onResetPassword}>Change Password</DropdownItem>
                                        <DropdownItem>Purge Session</DropdownItem>
                                        <DropdownItem onClick={onManageUser}>Manage User</DropdownItem>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </Col>
                        </td>
                    }
                </tr >
            }
        </React.Fragment >
    )
}

export default UserTableItem;
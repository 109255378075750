// @flow
import { all, call, fork, takeEvery } from "redux-saga/effects";

import {
    CHANGE_TAB
} from "./actionTypes";

import {
    changeTab as changeTabAction,
} from "./actions";

/**
 * Changes the tab
 * @param {*} param0
 */
function* changeTab({ payload: index }) {
    try {
        yield call(changeTabAction(index))
    } catch (error) { }
}
/**
 * Watchers
 */
export function* watchChangeTab() {
    yield takeEvery(CHANGE_TAB, changeTab);
}

function* PlatformListSaga() {
    yield all([
        fork(watchChangeTab),
    ]);
}

export default PlatformListSaga;

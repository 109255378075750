import gql from 'graphql-tag';

export const TagFragment = gql`
fragment TagInfo on Tag {
    id
    name
    createdAt
    updatedAt
    tagType
  }
`;
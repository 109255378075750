// @flow
import {
    CHANGE_FILTER,
    CHANGE_SORT
} from "../common/actionTypes";

const GenerateDateFilter = (type) => {
    return { [type]: { gte: "2020-01-01 00:00:00" } }
}

const INIT_STATE = {
    filter: {
        and: [
            GenerateDateFilter('createdAt'),
            GenerateDateFilter('updatedAt')
        ]
    },
    sort: []
};

const Products = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_FILTER:
            if (action.payload.belongs === "bank-accounts") {
                return {
                    ...state,
                    filter: action.payload.filter
                };
            } else {
                return {
                    ...state
                }
            }

        case CHANGE_SORT:
            return {
                ...state,
                sort: action.payload
            };
        default:
            return state;
    }
};

export default Products;

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import { Provider } from 'react-redux';
import ApolloHelper from "./provider/apollo";
import { ApolloProvider } from '@apollo/react-hooks';

import store from './store';

const app = (
    <Provider store={store}>
        <ApolloProvider client={ApolloHelper.makeApolloClient('')}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
// serviceWorker.unregister();

import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';

export const UPDATED_KIOSK_ACCOUNT = gql`
    subscription($input: UpdateOneKioskAccountSubscriptionFilterInput) {
        updatedOneKioskAccount(input: $input) {
            ...KioskInfo
        }
    }
    ${KioskFragment}
`;
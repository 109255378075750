import gql from 'graphql-tag';
import { MarketingFragment } from '../fragments/marketing.campaign.fragment';

export const CREATE_ONE_MARKETING_CAMPAIGN = gql`
    mutation(
        $input: CreateOneMarketingCampaignInput!
    ) {
        createOneMarketingCampaign (input: $input) {
            ...MarketingCampaign
        }
    }
    ${MarketingFragment}
`;
import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, Row } from "reactstrap";
import Avatar from '../../components/Common/Avatar';
import {
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    ButtonDropdown
} from "reactstrap";

class ListContact extends Component {

    state = {
        drp_link: false
    }

    render() {
        const { user, onEdit, onResetPassword, onManageUser } = this.props;
        return (
            <React.Fragment>
                <Col xl="3" sm="6" lg="4">
                    <Card>
                        <CardBody style={{ padding: '15px 1.25rem' }}>
                            <Row>
                                <Col xl="12" sm="12" className="padding-bottom text-align cursor-pointer " onClick={onEdit}>
                                    <Avatar name={user.node.name} style={{ paddingRight: 15 }} />
                                    <div>
                                        <div className="header">Serial No</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{user.node.serialNo}</Link></div>
                                    </div>
                                </Col>
                                <Col xl="12" sm="12" className="padding-bottom align-center cursor-pointer " onClick={onEdit}>
                                    <div>
                                        <div className="header">Name / Username</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{user.node.name} <span className="text-username">({user.node.username})</span></Link></div>
                                    </div>
                                </Col>
                                <Col xl="12" sm="12" className="padding-bottom align-center cursor-pointer " onClick={onEdit}>
                                    <div>
                                        <div className="header">Role</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{user.node.role}</Link></div>
                                    </div>
                                </Col>
                                <Col xl="12" sm="12" className="padding-bottom align-center cursor-pointer " onClick={onEdit}>
                                    <div>
                                        <div className="header">Mobile Number</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">{user.node.phoneNo ? user.node.phoneNo : 'N/A'}</Link></div>
                                    </div>
                                </Col>
                                <Col xl="12" sm="12" className="padding-bottom align-center">
                                    <div>
                                        <div className="header">Last Accessed</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">2020-06-24 19:13:02</Link></div>
                                    </div>
                                </Col>
                                <Col xl="12" sm="12" className="padding-bottom align-center">
                                    <div>
                                        <div className="header">Status</div>
                                        <div className="font-size-14"><Link to="#" className="text-dark">Active</Link></div>
                                    </div>
                                </Col>
                                <Col xl="12" sm="12" className="padding-bottom dropdown-actions">
                                    <ButtonDropdown
                                        isOpen={this.state.drp_link}
                                        toggle={() =>
                                            this.setState({ drp_link: !this.state.drp_link })
                                        }
                                    >
                                        <DropdownToggle caret color="secondary">
                                            Actions <i className="mdi mdi-chevron-down"></i>
                                        </DropdownToggle>
                                        <DropdownMenu right>
                                            <DropdownItem onClick={onEdit}>Edit User</DropdownItem>
                                            <DropdownItem onClick={onResetPassword}>Change Password</DropdownItem>
                                            <DropdownItem>Purge Session</DropdownItem>
                                            <DropdownItem onClick={onManageUser}>Manage User</DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </Col>
                            </Row>
                        </CardBody>
                        {/* <CardFooter className="bg-transparent border-top">
                            <div className="contact-links d-flex font-size-20">
                                <div className="flex-fill">
                                    <Link to="" id={"message" + user.id}>
                                        <i className="bx bx-message-square-dots"></i>
                                        <UncontrolledTooltip placement="top" target={"message" + user.id}>
                                            Message
                                                </UncontrolledTooltip>
                                    </Link>
                                </div>
                                <div className="flex-fill">
                                    <Link to="" id={"project" + user.id}>
                                        <i className="bx bx-pie-chart-alt"></i>
                                        <UncontrolledTooltip placement="top" target={"project" + user.id}>
                                            Projects
                                                </UncontrolledTooltip>
                                    </Link>
                                </div>
                                <div className="flex-fill">
                                    <Link to="" id={"profile" + user.id}>
                                        <i className="bx bx-user-circle"></i>
                                        <UncontrolledTooltip placement="top" target={"profile" + user.id}>
                                            Profile
                                                </UncontrolledTooltip>
                                    </Link>
                                </div>
                            </div>
                        </CardFooter> */}
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default ListContact;
import React from 'react';
import classnames from "classnames";
import { useMutation } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';
import {
    Input
} from "reactstrap";
import EasyEdit from 'react-easy-edit';
import { AvForm, AvField } from "availity-reactstrap-validation";
import NumberFormat from 'react-number-format';
import CustomDisplay from './custom-display';
import CustomUserInput from './custom-user-input';
import moment from 'moment';
import { ASSIGN_REFERRER, SET_REFERRAL_FEE, MARK_NO_REFERRAL, PAY_REFERRAL_FEE, UPDATE_ONE_USER } from '../../graphql/mutation';

class CustomInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || ''
        };
        this.onChange = this.onChange.bind(this);
    }

    onChange(e) {
        const newValue = e.target.value
        this.props.setParentValue(newValue);
        this.setState({
            value: newValue
        });
    }

    render() {
        const { value } = this.state;
        return (
            <AvForm
                className="needs-validation referral-form"
            >
                <AvField
                    name="comission"
                    value={value}
                    type="text"
                    autoFocus
                    placeholder={'Comission'}
                    errorMessage={'Enter Comission'}
                    thousandSeparator
                    decimalScale={2}
                    fixedDecimalScale
                    tag={[Input, NumberFormat]}
                    validate={{
                        pattern: {
                            value: "^[0-9,.]+$",
                            errorMessage: `${'Enter Only Numbers'}`
                        }
                    }}
                    onChange={this.onChange}
                />
            </AvForm>
        );
    }
}

function ReferralItem(props) {

    const { user, refetchList, type } = props;
    const [referrer, setReferrer] = React.useState(null)
    const [paying, setPaying] = React.useState(false)

    const [assignReferrer] = useMutation(ASSIGN_REFERRER)
    const [setReferralFee] = useMutation(SET_REFERRAL_FEE)
    const [markNoReferral] = useMutation(MARK_NO_REFERRAL)
    const [updateOneUser] = useMutation(UPDATE_ONE_USER)
    const [payReferralFee, { loading: loadingPay }] = useMutation(PAY_REFERRAL_FEE)

    React.useEffect(() => {
        setReferrer(user.node.referrer)
    }, [user])

    const OnSave = (referrer) => {
        if (referrer) {
            if (referrer.id !== "no-referrer") {
                assignReferrer({
                    variables: {
                        referrerId: referrer.id,
                        playerId: user.node.id
                    }
                }).then(() => {
                    ToastHelper.toggleToast({
                        message: "Update Succesfully!",
                        title: "",
                        onClick: function () { }
                    })
                    if (refetchList) {
                        refetchList()
                        setReferrer(null)
                    }
                }).catch(e => {
                    ToastHelper.toggleToast({
                        toastType: "error",
                        message: e,
                        title: "",
                        onClick: function () { }
                    })
                })
            }
        }
    }

    const SaveReferralFeeByPecentage = (percentage) => {
        setReferralFee({
            variables: {
                setReferralFeeInput: {
                    referralFeePercentage: parseFloat(percentage),
                    referralFee: parseFloat(parseFloat(percentage) * parseFloat(user.node.firstDepositAmount)) / 100
                },
                playerId: user.node.id
            }
        }).then(() => {
            if (refetchList) {
                refetchList()
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        })
    }

    const SaveReferralFeeByValue = (value) => {
        setReferralFee({
            variables: {
                setReferralFeeInput: {
                    referralFeePercentage: (parseFloat(value.toString().replaceAll(',', '')) / parseFloat(user.node.firstDepositAmount.toString().replaceAll(',', ''))) * 100,
                    referralFee: parseFloat(value.toString().replaceAll(',', ''))
                },
                playerId: user.node.id
            }
        }).then(() => {
            if (refetchList) {
                refetchList()
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        })
    }

    return (
        <React.Fragment>
            <tbody className="table-body">
                <tr className="font-size-12">
                    <td className="relative">
                        <div className="vertical-center">{user.node.username}</div>
                    </td>
                    <td className={classnames({
                        "relative": true,
                        "align-center": true
                    })}
                    >
                        <div
                            className={classnames({
                                "no-value": !referrer,
                                "vertical-center": true,
                                "align-left": true
                            })}
                        >
                            {
                                !user.node.referralFeePaidAt &&
                                type === "Assigned" &&
                                <div><i className="bx bx-x font-size-20 text-danger cursor-pointer mr-2" onClick={() => {
                                    updateOneUser({
                                        variables: {
                                            input: {
                                                update: {
                                                    referrerId: null,
                                                    referralStatus: "New"
                                                },
                                                id: user.node.id
                                            }
                                        }
                                    }).catch(e => {
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Update Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            if (refetchList) {
                                                refetchList()
                                                setReferrer(null)
                                            }
                                        }
                                    })
                                }}></i></div>
                            }
                            <EasyEdit
                                value={referrer ? referrer.name : 'N/A'}
                                type="text"
                                disableAutoSubmit={referrer === null}
                                allowEdit={type !== 'Paid'}
                                editComponent={
                                    <CustomUserInput
                                        data={referrer}
                                        player={user}
                                        onSelected={(val) => {
                                            setReferrer(val)
                                        }}
                                    />}
                                onSave={() => {
                                    OnSave(referrer)
                                }}
                                onCancel={() => {
                                    setReferrer(user.node.referrer)
                                }}
                                saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                                cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                                saveButtonStyle="easy-edit-button-save"
                                cancelButtonStyle="easy-edit-button-cancel"
                            />
                        </div>
                        <div className="pl-2"></div>
                        {
                            !user.node.referralFeePaidAt &&
                            type !== "NoReferrer" &&
                            <button type="button" className="btn btn-primary waves-effect waves-light font-size-11 align-right ml-2" onClick={async () => {
                                markNoReferral({
                                    variables: {
                                        playerId: user.node.id
                                    }
                                }).then(() => {
                                    if (refetchList) {
                                        refetchList()
                                        setReferrer(null)
                                    }
                                    ToastHelper.toggleToast({
                                        message: "Update Succesfully!",
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                })
                            }}>
                                <div>No Referral</div>
                            </button>
                        }
                    </td>
                    <td className="relative">
                        <div
                            className={classnames({
                                "no-value": !user.node.firstDepositAmount,
                                "vertical-center": true
                            })}
                        >{user.node.firstDepositAmount ? user.node.firstDepositAmount : 'N/A'}</div>
                    </td>
                    <td className="relative">
                        {
                            user.node.referralFeePercentage &&
                            <EasyEdit
                                value={user.node.referralFeePercentage ? parseFloat(user.node.referralFeePercentage).toFixed(2) : '0.00'}
                                type="text"
                                allowEdit={type !== 'Paid'}
                                editComponent={<CustomInput />}
                                displayComponent={<CustomDisplay />}
                                onSave={(value) => {
                                    SaveReferralFeeByPecentage(value)
                                }}
                                saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                                cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                                saveButtonStyle="easy-edit-button-save"
                                cancelButtonStyle="easy-edit-button-cancel"
                            />
                        }
                        {
                            !user.node.referralFeePercentage &&
                            <div
                                className={classnames({
                                    "no-value": !user.node.referralFeePercentage,
                                    "vertical-center": true
                                })}
                            >{'N/A'}</div>
                        }
                    </td>
                    <td
                        className={classnames({
                            "relative": true,
                            "align-center": true
                        })}
                    >
                        {
                            user.node.referralFee > 0 &&
                            <EasyEdit
                                value={user.node.referralFee ? parseFloat(user.node.referralFee).toFixed(2) : '0.00'}
                                type="text"
                                allowEdit={type !== 'Paid'}
                                editComponent={<CustomInput />}
                                displayComponent={<CustomDisplay />}
                                onSave={(value) => {
                                    SaveReferralFeeByValue(value)
                                }}
                                saveButtonLabel={<i className="mdi mdi-check font-size-16 align-middle"></i>}
                                cancelButtonLabel={<i className="mdi mdi-close font-size-16 align-middle"></i>}
                                saveButtonStyle="easy-edit-button-save"
                                cancelButtonStyle="easy-edit-button-cancel"
                            />
                        }
                        {
                            user.node.referralFee <= 0 &&
                            <div
                                className={classnames({
                                    "no-value": !user.node.referralFee,
                                    "vertical-center": true
                                })}
                            >{'N/A'}</div>
                        }
                        {
                            user.node.referralFee > 0 &&
                            !user.node.referralFeePaidAt &&
                            user.node.referrer &&
                            <button type="button" className="btn btn-primary waves-effect waves-light font-size-11 align-right ml-2" onClick={async () => {
                                if (paying) {
                                    return
                                }
                                setPaying(true)
                                await payReferralFee({
                                    variables: {
                                        playerId: user.node.id
                                    }
                                }).then(() => {
                                    if (refetchList) {
                                        refetchList()
                                        setReferrer(null)
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                })
                                setPaying(false)
                            }}>
                                {
                                    (loadingPay) &&
                                    <>
                                        <i className="fas fa-spinner fa-spin font-size-14 align-middle"></i>
                                        <div className="pl-1"></div>
                                    </>
                                }
                                {
                                    (!loadingPay) &&
                                    <div>Pay</div>
                                }
                            </button>
                        }
                    </td>
                    <td className="relative">
                        <div
                            className={classnames({
                                "no-value": !user.node.referralFeePaidBy,
                                "vertical-center": true
                            })}
                        >{user.node.referralFeePaidBy ? user.node.referralFeePaidBy.name : 'N/A'}</div>
                    </td>
                    <td className="relative">
                        <div
                            className={classnames({
                                "no-value": !user.node.referralFeePaidBy,
                                "vertical-center": true
                            })}
                        >{user.node.referralFeePaidAt ? moment(user.node.referralFeePaidAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A'}</div>
                    </td>
                </tr>
            </tbody>
        </React.Fragment >
    );
}

export default ReferralItem;
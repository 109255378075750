import gql from "graphql-tag";

export const UserFragment = gql`
  fragment UserInfo on User {
    id
    serialNo
    name
    username
    phoneNo
    phoneNoVerifiedAt
    language
    role
    email
    isActive
    isArchived
    createdAt
    telegramId
    telegramUsername
    updatedAt
    joinedAt
    loginAt
    ipAddress
    enableRewardPoint
    agentBaseRate
    lastAPGained
    lastAPGainedAt
    lastAPGainedFrom
    lastDepositAt
    upline {
      id
      name
      serialNo
      username
    }
    downlines {
      totalCount
    }
  }
`;


import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Container, Card, Col, Row, CardBody } from "reactstrap";
import ProfileGameAccountItem from "./profile-game-account-item"
import { useMutation } from 'react-apollo';
import { SYNC_GAME_ACCOUNT_BALANCE } from '../../graphql/mutation/SYNC_GAME_ACCOUNT_BALANCE';
import ToastHelper from '../../helpers/ToastHelper';
import _ from 'lodash';

function ProfilePlatforms(props) {

    const { account } = props;

    const [gameAccounts, setGameAccounts] = useState([]);

    useEffect(() => {
        if (account.node.gameAccounts) {
            setGameAccounts(_.map(account.node.gameAccounts.edges, 'node'))
        }

    }, [account])


    const [syncSingleGame] = useMutation(SYNC_GAME_ACCOUNT_BALANCE);

    const sync = id => e => {

        console.log('index: ' + id);
        console.log('property name: ' + e.target.name);

        let newArray = gameAccounts.map((gameAccount) => {
            if (gameAccount.id === id) {
                return { ...gameAccount, isLoading: true }
            } else {
                return { ...gameAccount }
            }


        });

        console.log(newArray)

        setGameAccounts(newArray); // ??


        syncSingleGame({ variables: { input: id } }).catch((e) => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
            let newArray = gameAccounts.map((gameAccount) => {
                if (gameAccount.id === id) {
                    return { ...gameAccount, isLoading: false }
                } else {
                    return { ...gameAccount }
                }


            });
            setGameAccounts(newArray);
        }).then((val) => {
            if (val) {
                var { data } = val;
                console.log(data)

                // let newArray = gameAccounts.map((gameAccount) => {
                //     if (gameAccount.id == id) {
                //         return { ...gameAccount, isLoading: false, balance: data.gameAccountSyncBalance.balance, lastSyncAt: data.gameAccountSyncBalance.lastSyncAt }
                //     } else {
                //         return { ...gameAccount }
                //     }


                // });
                // setGameAccounts(newArray);
            }
        })
    }




    return (
        <Card>
            <Row className="text-align">
                <Col>
                    <div className="text-primary p-3">
                        Game Account(s) for {account.node.platform.displayName}</div>
                </Col>
            </Row>
            <CardBody>
                {
                    <Container fluid>
                        <Row>
                            {
                                gameAccounts.map((gameAccount, key) =>
                                    <ProfileGameAccountItem
                                        gameAcccount={gameAccount}
                                        key={"_gameAccount_" + key + gameAccount.id}
                                        onRefresh={sync(gameAccount.id)}
                                        isLoading={
                                            gameAccount.isLoading
                                        }
                                    />

                                    // JSON.stringify(platform)
                                )
                            }

                        </Row>
                    </Container>
                }

                {
                    gameAccounts.length === 0 &&
                    <Col xl="12" sm="12">
                        <div className="no-game">No Game Account Found</div>
                    </Col>
                }
            </CardBody>
        </Card>
    )
}



export default connect()(withTranslation()(ProfilePlatforms));
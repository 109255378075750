import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const CREATE_ONE_DEPOSIT = gql`
    mutation(
        $input: CreateOneDepositInput!
    ) {
        createOneDeposit (input: $input) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const VIEW_ONE_TRANSACTION = gql`
    mutation(
        $id: String!
    ) {
        viewOneTransaction (id: $id) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import React, { useEffect} from "react";
import { Input, Label } from "reactstrap";

import { connect } from "react-redux";
import {
    hideRightSidebar,
    changeFilter,
    changeSort
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";
import { Link } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../graphql/query';
import _ from 'lodash';
import { AvForm } from "availity-reactstrap-validation";

import "../../components/CommonForBoth/rightbar.scss"
import NumberFormat from "react-number-format";

function BonusFilter(props) {

    //filter
    const [bonusTypes, setBonusTypes] = React.useState([])
    const [bonusComputations, setBonusComputations] = React.useState([])
    const [minAmount, setMinAmount] = React.useState('')
    const [maxAmount, setMaxAmount] = React.useState('')
    const [minDepositAmount, setMinDepositAmount] = React.useState('')
    const [maxDepositAmount, setMaxDepositAmount] = React.useState('')
    const [minCappedAmount, setMinCappedAmount] = React.useState('')
    const [maxCappedAmount, setMaxCappedAmount] = React.useState('')
    const [minRollAmount, setMinRollAmount] = React.useState('')
    const [maxRollAmount, setMaxRollAmount] = React.useState('')

    const { data: bonusTypeData } = useQuery(GET_ENUM, {
        variables: {
            name: "BonusType"
        }
    });

    const { data: bonusComputationData } = useQuery(GET_ENUM, {
        variables: {
            name: "BonusComputation"
        }
    });

    const hideRightSidebar = (e) => {
        e.preventDefault();
        props.hideRightSidebar();
    }

    const changeFilter = (data) => {
        props.changeFilter(data);
    }

    const ResetFilter = () => {
        setBonusTypes([])
        setBonusComputations([])
        setMinAmount('')
        setMaxAmount('')
        setMinDepositAmount('')
        setMaxDepositAmount('')
        setMinCappedAmount('')
        setMaxCappedAmount('')
        setMinRollAmount('')
        setMaxRollAmount('')
    }

    useEffect(() => {
        ResetFilter()
    }, [])

    useEffect(() => {
        if (bonusTypeData) {
            changeFilter({
                belongs: "bonus",
                filter: {
                    and: [
                        {
                            bonusType: { in: GenerateBonusFilter(bonusTypes) }
                        },
                        {
                            bonusComputation: { in: GenerateBonusComputation(bonusComputations) }
                        },
                        {
                            amount: { between: { lower: parseFloat(minAmount === '' ? 0 : minAmount), upper: parseFloat(maxAmount === '' ? Number.MAX_VALUE : maxAmount) } }
                        },
                        {
                            minDepositAmount: { between: { lower: parseFloat(minDepositAmount === '' ? 0 : minDepositAmount), upper: parseFloat(maxDepositAmount === '' ? Number.MAX_VALUE : maxDepositAmount) } }
                        },
                        {
                            maxCappedAmount: { between: { lower: parseFloat(minCappedAmount === '' ? 0 : minCappedAmount), upper: parseFloat(maxCappedAmount === '' ? Number.MAX_VALUE : maxCappedAmount) } }
                        },
                        {
                            rolloverMultiplier: { between: { lower: parseFloat(minRollAmount === '' ? 0 : minRollAmount), upper: parseFloat(maxRollAmount === '' ? Number.MAX_VALUE : maxRollAmount) } }
                        }
                    ]
                }
            })
        }
    }, [bonusTypes, bonusComputations, minAmount, maxAmount, minDepositAmount, maxDepositAmount, minCappedAmount, maxCappedAmount, minRollAmount, maxRollAmount]) // eslint-disable-line react-hooks/exhaustive-deps

    const GenerateBonusFilter = (bonusTypes) => {
        let allData = [];
        let data = [];
        _.forEach(bonusTypes, bonus => {
            data.push(bonus)
        })

        _.forEach(bonusTypeData.__type.enumValues, ({ name }) => {
            allData.push(name)
        })

        return data.length === 0 ? allData : data
    }

    const GenerateBonusComputation = (bonusComputations) => {
        let allData = [];
        let data = [];
        _.forEach(bonusComputations, bonusComputation => {
            data.push(bonusComputation)
        })

        _.forEach(bonusComputationData.__type.enumValues, ({ name }) => {
            allData.push(name)
        })

        return data.length === 0 ? allData : data
    }

    return (
        <React.Fragment>
            <div className="right-bar bank-filter-page bank-link">

                <SimpleBar style={{ height: "100vh" }}>
                    <div data-simplebar className="h-100">
                        <AvForm className="needs-validation" >

                            <div className="rightbar-title px-3 py-4">
                                <Link to="#" onClick={hideRightSidebar} className="right-bar-toggle float-right">
                                    <i className="mdi mdi-close noti-icon"></i>
                                </Link>
                                <h5 className="m-0">Bonus Filter</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetFilter}>
                                        Reset Filter
                                    </button>
                                </div>

                                <span className="mb-2 d-block">Bonus Type</span>
                                {
                                    bonusTypeData &&
                                    bonusTypeData.__type.enumValues.map(({ name }, index) => {
                                        return (
                                            <div key={index} className="custom-control custom-checkbox">
                                                <Input type="checkbox" checked={_.includes(bonusTypes, name)} className="custom-control-input checkbox" id={name} onChange={() => {
                                                    let data = [];
                                                    if (_.includes(bonusTypes, name)) {
                                                        data = _.filter(bonusTypes, bonusType => {
                                                            return bonusType !== name
                                                        })
                                                    } else {
                                                        data = [...bonusTypes, name]
                                                    }

                                                    setBonusTypes(data)
                                                }} />
                                                <Label className="custom-control-label" htmlFor={name}>{name}</Label>
                                            </div>
                                        )
                                    })
                                }
                                <hr className="mt-3" />

                                <span className="mb-2 d-block">Bonus Computation</span>
                                {
                                    bonusComputationData &&
                                    bonusComputationData.__type.enumValues.map(({ name }, index) => {
                                        return (
                                            <div key={index} className="custom-control custom-checkbox">
                                                <Input type="checkbox" checked={_.includes(bonusComputations, name)} className="custom-control-input checkbox" id={name} onChange={() => {
                                                    let data = [];
                                                    if (_.includes(bonusComputations, name)) {
                                                        data = _.filter(bonusComputations, bonusType => {
                                                            return bonusType !== name
                                                        })
                                                    } else {
                                                        data = [...bonusComputations, name]
                                                    }

                                                    setBonusComputations(data)
                                                }} />
                                                <Label className="custom-control-label" htmlFor={name}>{name}</Label>
                                            </div>
                                        )
                                    })
                                }
                                <hr className="mt-3" />

                                <span className="mb-2 d-block">Amount</span>
                                <div className="align-center">
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={minAmount}
                                        className="form-control"
                                        placeholder="Min Amount"
                                        onChange={(event) => setMinAmount(event.target.value)}
                                    />
                                    <div className="ml-2 mr-2">-</div>
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={maxAmount}
                                        className="form-control"
                                        placeholder="Max Amount"
                                        onChange={(event) => setMaxAmount(event.target.value)}
                                    />
                                </div>
                                <hr className="mt-3" />

                                <span className="mb-2 d-block">Min Deposit Amount</span>
                                <div className="align-center">
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={minDepositAmount}
                                        className="form-control"
                                        placeholder="Min Amount"
                                        onChange={(event) => setMinDepositAmount(event.target.value)}
                                    />
                                    <div className="ml-2 mr-2">-</div>
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={maxDepositAmount}
                                        className="form-control"
                                        placeholder="Max Amount"
                                        onChange={(event) => setMaxDepositAmount(event.target.value)}
                                    />
                                </div>
                                <hr className="mt-3" />

                                <span className="mb-2 d-block">Max Capped Amount</span>
                                <div className="align-center">
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={minCappedAmount}
                                        className="form-control"
                                        placeholder="Min Amount"
                                        onChange={(event) => setMinCappedAmount(event.target.value)}
                                    />
                                    <div className="ml-2 mr-2">-</div>
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={maxCappedAmount}
                                        className="form-control"
                                        placeholder="Max Amount"
                                        onChange={(event) => setMaxCappedAmount(event.target.value)}
                                    />
                                </div>
                                <hr className="mt-3" />

                                <span className="mb-2 d-block">Rollover Amount</span>
                                <div className="align-center">
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={minRollAmount}
                                        className="form-control"
                                        placeholder="Min Amount"
                                        onChange={(event) => setMinRollAmount(event.target.value)}
                                    />
                                    <div className="ml-2 mr-2">-</div>
                                    <NumberFormat
                                        debounce={1000}
                                        decimalScale={2}
                                        fixedDecimalScale
                                        value={maxRollAmount}
                                        className="form-control"
                                        placeholder="Max Amount"
                                        onChange={(event) => setMaxRollAmount(event.target.value)}
                                    />
                                </div>
                                <hr className="mt-3" />
                            </div>
                        </AvForm>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay" onClick={hideRightSidebar}></div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeFilter,
    changeSort
})(BonusFilter);

import toastr from 'toastr';

const ToastHelper = {
    toggleToast({ toastType, message, title, onClick }) {

        toastr.options = {
            progressBar: true,
        }

        toastr.options.onclick = onClick;

        //Toaster Types
        if (toastType === "info")
            toastr.info(message, title)
        else if (toastType === "warning")
            toastr.warning(message, title)
        else if (toastType === "error")
            toastr.error(message, title)
        else
            toastr.success(message, title)
    }
}

export default ToastHelper
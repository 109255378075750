import gql from 'graphql-tag';
import { BannerFragment } from '../fragments/banner.fragment';

export const UPDATE_ONE_BANNER = gql`
    mutation(
        $input: UpdateOneBannerInput!
    ) {
        updateOneBanner (input: $input) {
            ...BannerInfo
        }
    }
    ${BannerFragment}
`;
import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const CHANGE_COMPANY_BANK_ACCOUNT = gql`
    mutation(
        $companyBankAccountId: String!
        $id: String!
    ) {
        transactionChangeCompanyBankAccount (
            companyBankAccountId: $companyBankAccountId
            id: $id
        ) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import React, { Fragment } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { AvForm } from "availity-reactstrap-validation";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery } from 'react-apollo';
import { GET_USER } from '../../graphql/query/GET_USER';

function CustomUserInput(props) {

    const { t, onSelected, player, data } = props

    const [search, setSearch] = React.useState('')

    const { data: userList, loading: loadingUserList } = useQuery(GET_USER, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                and: [
                    {
                        or: [
                            { username: { iLike: `%${search}%` } },
                            { name: { iLike: `%${search}%` } },
                        ]
                    },
                    {
                        role: { eq: "Player" }
                    },
                    {
                        username: { neq: player.node.username }
                    }
                ]
            },
            sorting: {
                field: 'createdAt',
                direction: 'ASC'
            },
        },
        fetchPolicy: 'network-only'
    })

    return (
        <React.Fragment>
            <AvForm
                className="needs-validation referral-form"
            >
                <AsyncTypeahead
                    positionFixed
                    selected={data ? [{
                        node: {username: data.username}
                    }] : []}
                    id="referrer-list"
                    isLoading={loadingUserList}
                    autoFocus
                    labelKey={option => `${option.node.username}`}
                    minLength={2}
                    onSearch={(query) => setSearch(query)}
                    options={userList ? userList.users.edges : []}
                    placeholder={t('Search User')}
                    useCache={false}
                    renderMenuItemChildren={(option, props) => (
                        <Fragment>
                            <span>{option.node.username}</span>
                        </Fragment>
                    )}
                    errorMessage={t('Enter user')}
                    onChange={(val) => {
                        if (val.length > 0) {
                            props.setParentValue(val[0].node.username);
                            if (onSelected) {
                                onSelected(val[0].node)
                            }
                        } else {
                            if (onSelected) {
                                onSelected(null)
                            }
                        }
                    }}
                />
            </AvForm>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(CustomUserInput));
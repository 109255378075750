import gql from "graphql-tag";
import { TelegramMenuFragment } from "../fragments/telegram-menu.fragment";

export const GET_ONE_TELEGRAM_MENU = gql`
  query ($id: ID!) {
    telegramMenu(id: $id) {
      ...TelegramMenu
      telegramMenuButtons {
        totalCount
      }
    }
  }
  ${TelegramMenuFragment}
`;

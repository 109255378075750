import gql from "graphql-tag";

export const FreeCreditFragment = gql`
  fragment FreeCreditType on FreeCreditType {
    id
    name
    isPublished
    isActive
    isArchived
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
`;

export const FreeCreditTypeDetailFragment = gql`
  fragment FreeCreditTypeDetail on FreeCreditTypeDetail {
    id
    title
    description
    imageUrl
    redirectUrl
    language
  }
`;

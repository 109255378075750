
import React, { useEffect } from "react";
import "../../components/CommonForBoth/rightbar.scss"
import SimpleBar from "simplebar-react";
import { connect } from "react-redux";
import {
    hideRightSidebar,
    changeFilter,
    changeSort
} from "../../store/actions";

import moment from 'moment';
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";

function PlatformFilter(props) {

    //  //filter
    const [createStart, setCreateStart] = React.useState(null)
    const [createEnd, setCreateEnd] = React.useState(null)
    const [updateStart, setUpdateStart] = React.useState(null)
    const [updateEnd, setUpdateEnd] = React.useState(null)

    //  //sort
    const [sortPlaformName, setSortPlatformName] = React.useState(null)
    const [sortCreate, setSortCreate] = React.useState(null)
    const [sortUpdate, setSortUpdate] = React.useState(null)



    const hideRightSidebar = (e) => {
        e.preventDefault();
        props.hideRightSidebar();
    }


    const changeFilterAct = (data) => {
        props.changeFilter(data);
    }

    const changeSortAct = (data) => {
        props.changeSort(data);
    }

    const GenerateDateFilter = (type, startDate, endDate) => {
        if (startDate && endDate) {
            return {
                and: [{
                    [type]: { gt: `${moment(startDate)}` }
                }, {
                    [type]: { lt: `${moment(endDate).add('hours', 23).add('minutes', 59).add('seconds', 59)}` }
                }]
            }
        }
        return { [type]: { lt: new Date() } }
    }

    const ResetFilter = () => {
        setSortCreate(null)
        setSortUpdate(null)
        setSortPlatformName(null)
    }

    const ResetSort = () => {
        setSortCreate(null)
        setSortUpdate(null)
        setSortPlatformName(null)
    }

    useEffect(() => {
        ResetFilter()
        ResetSort()
    }, [])

    useEffect(() => {
        changeFilterAct({
            belongs: "platform",
            filter: {
                and: [
                    GenerateDateFilter('createdAt', createStart, createEnd),
                    GenerateDateFilter('updatedAt', updateStart, updateEnd)
                ]
            }
        })
    }, [createStart, createEnd, updateStart, updateEnd]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let sorts = []

        if (sortPlaformName !== null) {
            sorts.push({
                field: "name", direction: sortPlaformName
            })
        }

        if (sortCreate !== null) {
            sorts.push({
                field: "createdAt", direction: sortCreate
            })
        }

        if (sortUpdate !== null) {
            sorts.push({
                field: "updatedAt", direction: sortUpdate
            })
        }

        changeSortAct(sorts)
    }, [sortPlaformName, sortCreate, sortUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="right-bar bank-filter-page bank-link">

                <SimpleBar style={{ height: "100vh" }}>

                    <div data-simplebar className="h-100">
                        <div className="rightbar-title px-3 py-4">
                            <Link to="#" onClick={hideRightSidebar} className="right-bar-toggle float-right">
                                <i className="mdi mdi-close noti-icon"></i>
                            </Link>
                            <h5 className="m-0">Platform Filter</h5>
                        </div>

                        <hr className="my-0" />

                        <div className="p-4">
                            <div className="reset-button">
                                <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetFilter}>
                                    Reset Filter
                                    </button>
                            </div>

                            <DatePicker
                                className="form-control start-date"
                                selected={createStart}
                                onChange={date => {
                                    if (date > createEnd) {
                                        setCreateEnd(date)
                                    }
                                    setCreateStart(date)
                                }}
                                selectsStart
                                startDate={createStart}
                                endDate={createEnd}
                                maxDate={new Date()}
                                dateFormat="dd MM yyyy"
                                placeholderText="From date"
                            />
                            <DatePicker
                                className="form-control"
                                selected={createEnd}
                                onChange={date => {
                                    setCreateEnd(date)
                                }}
                                selectsEnd
                                startDate={createStart}
                                endDate={createEnd}
                                minDate={createStart}
                                maxDate={new Date()}
                                dateFormat="dd MM yyyy"
                                placeholderText="To date"
                            />

                            <hr className="mt-1" />

                            <span className="mb-2 d-block">Updated From</span>
                            <DatePicker
                                className="form-control start-date"
                                selected={updateStart}
                                onChange={date => {
                                    if (date > updateEnd) {
                                        setUpdateEnd(date)
                                    }
                                    setUpdateStart(date)
                                }}
                                selectsStart
                                startDate={updateStart}
                                endDate={updateEnd}
                                maxDate={new Date()}
                                dateFormat="dd MM yyyy"
                                placeholderText="From date"
                            />
                            <DatePicker
                                className="form-control"
                                selected={updateEnd}
                                onChange={date => {
                                    setUpdateEnd(date)
                                }}
                                selectsEnd
                                startDate={updateStart}
                                endDate={updateEnd}
                                minDate={updateStart}
                                maxDate={new Date()}
                                dateFormat="dd MM yyyy"
                                placeholderText="To date"
                            />
                        </div>

                        <hr className="my-0" />

                        <div className="p-4">
                            <div className="reset-button">
                                <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetSort}>
                                    Reset Sort </button>
                            </div>
                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">Name</span>
                                <input
                                    className="name-sort"
                                    type="radio"
                                    id="nameAsc"
                                    name="nameSort"
                                    value="ASC"
                                    checked={sortPlaformName === 'ASC'}
                                    onClick={() => {
                                        if (sortPlaformName === 'ASC') {
                                            setSortPlatformName(null)
                                        } else {
                                            setSortPlatformName('ASC')
                                        }
                                    }}
                                    onChange={() => { }}
                                />
                                <label htmlFor="nameAsc">ASC</label>
                                {"   "}
                                <input
                                    className="name-sort"
                                    type="radio"
                                    id="nameDesc"
                                    name="nameSort"
                                    value="DESC"
                                    checked={sortPlaformName === 'DESC'}
                                    onClick={() => {
                                        if (sortPlaformName === 'DESC') {
                                            setSortPlatformName(null)
                                        } else {
                                            setSortPlatformName('DESC')
                                        }
                                    }}
                                    onChange={() => { }}
                                />
                                <label htmlFor="nameDesc">DESC</label>
                            </div>


                            <hr className="mt-1" />

                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">Created From</span>
                                <input
                                    type="radio"
                                    id="createAsc"
                                    name="createSort"
                                    value="ASC"
                                    checked={sortCreate === 'ASC'}
                                    onClick={() => {
                                        if (sortCreate === 'ASC') {
                                            setSortCreate(null)
                                        } else {
                                            setSortCreate('ASC')
                                        }
                                    }}
                                    onChange={() => { }}
                                />
                                <label htmlFor="createAsc">ASC</label>
                                {"   "}
                                <input
                                    type="radio"
                                    id="createDesc"
                                    name="createSort"
                                    value="DESC"
                                    checked={sortCreate === 'DESC'}
                                    onClick={() => {
                                        if (sortCreate === 'DESC') {
                                            setSortCreate(null)
                                        } else {
                                            setSortCreate('DESC')
                                        }
                                    }}
                                    onChange={() => { }}
                                />
                                <label htmlFor="createDesc">DESC</label>
                            </div>

                            <hr className="mt-1" />

                            <div className="radio-toolbar">
                                <span className="mb-2 d-block">Updated From</span>
                                <input
                                    type="radio"
                                    id="updateAsc"
                                    name="updateSort"
                                    value="ASC"
                                    checked={sortUpdate === 'ASC'}
                                    onClick={() => {
                                        if (sortUpdate === 'ASC') {
                                            setSortUpdate(null)
                                        } else {
                                            setSortUpdate('ASC')
                                        }
                                    }}
                                    onChange={() => { }}
                                />
                                <label htmlFor="updateAsc">ASC</label>
                                {"   "}
                                <input
                                    type="radio"
                                    id="updateDesc"
                                    name="updateSort"
                                    value="DESC"
                                    checked={sortUpdate === 'DESC'}
                                    onClick={() => {
                                        if (sortUpdate === 'DESC') {
                                            setSortUpdate(null)
                                        } else {
                                            setSortUpdate('DESC')
                                        }
                                    }}
                                    onChange={() => { }}
                                />
                                <label htmlFor="updateDesc">DESC</label>
                            </div>
                        </div>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay" onClick={hideRightSidebar}></div>
        </React.Fragment>)
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeFilter,
    changeSort
})(PlatformFilter);

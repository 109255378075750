import React from "react";
import { useMutation, useQuery } from "react-apollo";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import { OperatingUsers, Operator } from "../../assets/scss/role.constant";
import { RECALCULATE_BANK_BALANCE } from "../../graphql/mutation";
import { ME } from "../../graphql/query";

function BankAccountTableItem(props) {
  const {
    bank,
    onEdit,
    onIncrement,
    onDecrement,
    onTransfer,
    onToggle,
    onToggleDeposit,
    onToggleWithdraw,
  } = props;

  const [recalculateBankBalance, { loading: loadingBalance }] = useMutation(
    RECALCULATE_BANK_BALANCE
  );
  const { data: meData } = useQuery(ME);

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {props.text}
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {props.text}
      </div>
    );
  };

  return (
    <React.Fragment>
      <tr>
        <td>
          <div className="align-center">
            {meData &&
              !OperatingUsers.includes(meData.me.role) &&
              !Operator.includes(meData.me.role) && (
                <>
                  <div>
                    <Link
                      to={`bankaccount-details/${bank.node.id}`}
                      target="_blank"
                      className=""
                    >
                      <i className="bx bx-food-menu font-size-16"></i>
                    </Link>
                  </div>
                  <div className="pl-2"></div>
                </>
              )}
            <div className="cursor-pointer" onClick={onEdit}>
              <span className="font-weight-bold">{bank.node.accountName}</span>
              {` (${bank.node.accountNo})`}
            </div>
          </div>
        </td>
        {meData &&
          !OperatingUsers.includes(meData.me.role) &&
          !Operator.includes(meData.me.role) && (
            <td>
              <div className="align-center">
                <div className="cursor-pointer" onClick={onEdit}>
                  <NumberFormat
                    value={parseFloat(bank.node.balance)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </div>
                <div className="pl-2"></div>
                <button
                  className="btn btn-primary waves-effect waves-light font-size-11 action-button btn-sm"
                  onClick={() => {
                    recalculateBankBalance({
                      variables: {
                        id: bank.node.id,
                      },
                    });
                  }}
                >
                  {!loadingBalance && (
                    <i className="bx bx-sync font-size-14"></i>
                  )}
                  {loadingBalance && (
                    <i className="fas fa-spinner fa-pulse font-size-13"></i>
                  )}
                </button>
              </div>
            </td>
          )}
        <td>
          {meData &&
            !OperatingUsers.includes(meData.me.role) &&
            !Operator.includes(meData.me.role) && (
              <div className="align-center">
                <button
                  className="btn btn-primary btn-sm"
                  onClick={onIncrement}
                >
                  <i className="bx bx-plus font-size-14 pt-1"></i>
                </button>
                <div className="ml-2"></div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={onDecrement}
                >
                  {" "}
                  <i className="bx bx-minus font-size-14 pt-1"></i>
                </button>
                <div className="ml-2"></div>
                <button className="btn btn-primary btn-sm" onClick={onTransfer}>
                  {" "}
                  <i className="bx bx-transfer font-size-14 pt-1"></i>
                </button>
              </div>
            )}
        </td>
        <td>
          <div className="cursor-pointer" onClick={onEdit}>
            {bank.node.bank.name}
          </div>
        </td>
        <td>
          <div className="cursor-pointer" onClick={onEdit}>
            {bank.node.bank.country.name}
          </div>
        </td>
        <td>
          <div className="cursor-pointer">
            <Switch
              readOnly={true}
              uncheckedIcon={<Offsymbol text="NO" />}
              checkedIcon={<OnSymbol text="YES" />}
              className="mr-1"
              onColor="#34c38f"
              offColor="#f46a6a"
              onChange={() => {
                onToggleDeposit(bank);
              }}
              checked={bank.node.canDeposit}
            />
          </div>
        </td>
        <td>
          <div className="cursor-pointer">
            <Switch
              readOnly={true}
              uncheckedIcon={<Offsymbol text="NO" />}
              checkedIcon={<OnSymbol text="YES" />}
              className="mr-1"
              onColor="#34c38f"
              offColor="#f46a6a"
              onChange={() => {
                onToggleWithdraw(bank);
              }}
              checked={bank.node.canWithdraw}
            />
          </div>
        </td>
        {meData &&
          !OperatingUsers.includes(meData.me.role) &&
          !Operator.includes(meData.me.role) && (
            <td>
              <div className="cursor-pointer">
                <Switch
                  readOnly={true}
                  uncheckedIcon={<Offsymbol text="NO" />}
                  checkedIcon={<OnSymbol text="YES" />}
                  className="mr-1"
                  onColor="#34c38f"
                  offColor="#f46a6a"
                  onChange={() => {
                    onToggle(bank);
                  }}
                  checked={bank.node.isActive}
                />
              </div>
            </td>
          )}
      </tr>
    </React.Fragment>
  );
}

export default BankAccountTableItem;

import React, { useRef } from 'react';
import { Link, useHistory } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";

import _ from 'lodash';

//Import Breadcrumb
import { useQuery, useSubscription } from '@apollo/react-hooks';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import 'react-medium-image-zoom/dist/styles.css';
import { connect } from "react-redux";
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SettingEditor from '../../components/CommonForBoth/SettingEditor';
import { ME } from '../../graphql/query';
import { GET_AGENTS } from '../../graphql/query/GET_AGENTS';
import { UPDATED_ONE_USER } from '../../graphql/subscription/UPDATED_ONE_USER';
import { toggleRightSidebar } from "../../store/actions";
import AgentTableItem from './agent-table-item';

function AgentTiers(props) {

    const { t } = props;
    const history = useHistory();

    const [orderBy, setOrderBy] = React.useState("lastAPGainedAt")
    const [orderDirection, setOrderDirection] = React.useState("DESC")
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [pageInfo, setPageInfo] = React.useState(false)

    const first = 50

    const { data: meData } = useQuery(ME, {
        fetchPolicy: 'cache-first'
    })

    const Filter = () => {
        if (!_.isEmpty(search)) {
            return {
                username: { iLike: `%${search}%` }
            }
        } else {
            return {
                username: { neq: '' }
            }
        }
    }

    const { loading, fetchMore, data } = useQuery(GET_AGENTS, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: Filter(),
            sorting: [{ field: orderBy, nulls: "NULLS_LAST", direction: orderDirection }]
        },
        fetchPolicy: 'network-only'
    })

    React.useEffect(() => {
        if (data) {
            setAfterCursor(data.agents.pageInfo.endCursor)
            setPageInfo(data.agents.pageInfo)
        }
    }, [data])

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`cursor-pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
            } else {
                setOrderDirection('ASC')
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)
        }
    }

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    useSubscription(UPDATED_ONE_USER)

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: {
                        username: { iLike: `%${search}%` }
                    },
                    sorting: [{ field: orderBy, nulls: "NULLS_LAST", direction: orderDirection }]
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.agents.edges;
                    const pageInfo = fetchMoreResult.agents.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            agents: {
                                __typename: previousResult.agents.__typename,
                                edges: _.uniq([...previousResult.agents.edges, ...newEdges]),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="page-content agent-page">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Agent Point" breadcrumbItem="Agent Point" />
                    {
                        meData && !OperatingUsers.includes(meData.me.role) && !Operator.includes(meData.me.role) &&
                        <Row>
                            <Col md={6} lg={6} xl={4} sm={6}>
                                <Card>
                                    <CardBody>
                                        <SettingEditor description="Gloabl Agent Base Rate" settingKey="globalAgentBaseRate" settingType="General" fieldType="TextField" inputValue={'1'} defaultValue={'1'} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6} lg={6} xl={4} sm={6}>
                                <Card>
                                    <CardBody>
                                        <SettingEditor description="Minimum Agent Point To Claim" settingKey="minAgentPointToClaim" settingType="General" fieldType="TextField" inputValue={'1'} defaultValue={'1'} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6} lg={6} xl={4} sm={6}>
                                <Card>
                                    <CardBody>
                                        <SettingEditor description="Agent Point Expired In(Days)" settingKey="agentPointExpiredIn" settingType="General" fieldType="TextField" inputValue={'30'} defaultValue={'30'} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6} lg={6} xl={4} sm={6}>
                                <Card>
                                    <CardBody>
                                        <SettingEditor description="Agent Point Expiry Reminder (Days)[-1 for not remind]" settingKey="agentPointExpireNotification" settingType="Bot" fieldType="TextField" inputValue={'-1'} defaultValue={'-1'} />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={6} lg={6} xl={4} sm={6}>
                                <Card>
                                    <CardBody>
                                        <SettingEditor description="Enable Agent Module?(Global)" settingKey="enableAgentModule" settingType="General" fieldType="Boolean" inputValue={'false'} defaultValue={'false'} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                    {
                        <Card className="w-100">
                            <CardBody>
                                <div style={{
                                    fontSize: 17,
                                    fontWeight: 600,
                                    paddingBottom: 10
                                }}>AGENT</div>
                                <div className="search-bar-container">
                                    <div className="button-add-user-container">
                                        <div className="app-search d-none d-lg-block">
                                            <div className="position-relative">
                                                <input
                                                    value={searchValue}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Search..."
                                                    onChange={(e) => {
                                                        setSearchValue(e.target.value)
                                                        debouncedSave(e.target.value)
                                                    }}
                                                />
                                                <span className="bx bx-search-alt"></span>
                                            </div>
                                        </div>
                                        <div className="d-flex">
                                            <div className="dropdown d-inline-block d-lg-none ml-2">
                                                <button
                                                    type="button"
                                                    className="btn header-item noti-icon waves-effect"
                                                    id="page-header-search-dropdown"
                                                    onClick={() => { setIsSearch(!isSearch) }}>
                                                    <i className="mdi mdi-magnify"></i>
                                                </button>
                                                <div
                                                    className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                                    aria-labelledby="page-header-search-dropdown"
                                                >
                                                    <form className="p-3">
                                                        <div className="form-group m-0">
                                                            <div className="input-group">
                                                                <input
                                                                    value={searchValue}
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder={props.t('Search') + "..."}
                                                                    onChange={(e) => {
                                                                        setSearchValue(e.target.value)
                                                                        debouncedSave(e.target.value)
                                                                    }}
                                                                />
                                                                <div className="input-group-append">
                                                                    <button className="btn btn-primary" type="submit">
                                                                        <i className="mdi mdi-magnify"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    {/* <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Min Deposit</th>
                                                <th>Max Deposit</th>
                                                <th>Reward Multiplier</th>
                                                <th>Active?</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        </tbody>
                                    </Table> */}

                                    {
                                        data &&
                                        <InfiniteScroll
                                            dataLength={data.agents.edges.length} //This is important field to render the next data
                                            next={() => setIsFetchMore(true)}
                                            hasMore={pageInfo.hasNextPage}
                                            loader={
                                                <div className="text-center my-3">
                                                    <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                </div>
                                            }
                                            endMessage={
                                                <div className="text-center my-3">
                                                    <div>Yay! You have seen it all</div>
                                                </div>
                                            }
                                        >
                                            <div className="table-responsive">

                                                <Table className="table mb-0 min-h-300">
                                                    <thead>
                                                        <tr className="font-size-12">
                                                            <Header name='Serial No' className="" type='serialNo' />
                                                            <Header name='Username' className="" type='username' />
                                                            <Header name='Agent Wallet' className="" type='agentWalletBalance' />
                                                            <Header name='Upline' className="" type='uplineUsername' />
                                                            <Header name='Downline Count' className="" type='downlineCount' />
                                                            <Header name='Agent Base Rate' className="" type='agentBaseRate' />
                                                            <Header name='Last AP Gained' className="" type='lastAPGained' />
                                                            <Header name='Last AP Gained At' className="" type='lastAPGainedAt' />
                                                            <Header name='Last AP Gained From' className="" type='lastAPGainedFrom' />
                                                            <th>Action</th>
                                                            {/* <th>Actions</th> */}

                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-body">
                                                        {
                                                            data.agents.edges.map((agent, key) =>
                                                                <AgentTableItem agent={agent}
                                                                    key={"_agent_" + key}
                                                                    onManageWallet={(wallet) => {
                                                                        let path = `wallet-details/${agent.node.id}/${wallet.id}`
                                                                        window.open(path, "_blank")
                                                                    }}
                                                                    onManageInWorkbench={() => {
                                                                        history.push({
                                                                            "pathname": "/cashier-workbench",
                                                                            target: "_blank",
                                                                            state: { user: agent }
                                                                        });
                                                                    }}
                                                                >
                                                                </AgentTableItem>
                                                            )
                                                        }
                                                        {
                                                            data.agents.edges.length === 0 &&
                                                            <tr className="text-center pb-2">
                                                                <td colSpan={12}>
                                                                    <div className="no-user pt-0">No Agent Found</div>
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </InfiniteScroll>
                                    }
                                </div>
                                <div className="load-more-container">
                                    {
                                        (loading) &&
                                        !pageInfo &&
                                        <Col xs="12">
                                            <div className="text-center my-3">
                                                <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                            </div>
                                        </Col>
                                    }
                                </div>
                            </CardBody>
                        </Card>
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(AgentTiers));
import gql from 'graphql-tag';

export const PlayerStatFragment = gql`
fragment PlayerStat on PlayerStatsResponse {
    id
    username
    totalDepositAmount
    totalBonusAmount
    totalFreeCreditAmount
    totalWithdrawalAmount
    netProfit
    grossProfit
    averageDepositAmount
    averageDailyDepositAmount
    averageWithdrawalAmount
    averageDailyWithdrawalAmount
    totalDepositCount
    totalWithdrawalCount
    joinedAt
    firstDepositAt
    lastDepositAt
    lastWithdrawalAt
    totalMembershipDay
    totalActiveDay
    activeDayRatio
    lastLoginAt
    totalGameAccount
    totalCreditIn
    totalCreditOut
    mostPlayingGame
    lessPlayingGame
    lastWithdrawalAmount
    totalRebateAmount
    adjustedProfit
    totalIncrementAmount
    totalDecrementAmount
  }
`;
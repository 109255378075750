import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import UserFilter from "../../pages/Contacts/user-filter";
import BankFilter from "../../pages/Banking/bank-filter";
import BankAccountFilter from "../../pages/Banking/account-filter";
import PlatformFilter from "../../pages/Platforms/platform-filter";
import KioskFilter from "../../pages/Kiosk/kiosk-filter";
import GameAccountFilter from "../../pages/GameAccount/game-account-filter";
import TransactionFilter from "../../pages/Transactions/transaction-filter";
import PlayerFilter from "../../pages/Player/player-filter";
import LoadingOverlay from 'react-loading-overlay';
import BonusFilter from "../../pages/Bonus/bonus-filter";

class Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    };
    this.toggleMenuCallback = this.toggleMenuCallback.bind(this);
    this.toggleRightSidebar = this.toggleRightSidebar.bind(this);
  }

  toggleRightSidebar() {
    this.props.toggleRightSidebar();
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2);
  };

  componentDidMount() {

    if (this.props.isPreloader === true) {
      document.getElementById('preloader').style.display = "block";
      document.getElementById('status').style.display = "block";

      setTimeout(function () {

        document.getElementById('preloader').style.display = "none";
        document.getElementById('status').style.display = "none";

      }, 2500);
    }
    else {
      document.getElementById('preloader').style.display = "none";
      document.getElementById('status').style.display = "none";
    }

    // Scroll Top to 0
    window.scrollTo(0, 0);
    let currentage = this.capitalizeFirstLetter(this.props.location.pathname);

    document.title =
      currentage;
    if (this.props.leftSideBarTheme) {
      this.props.changeSidebarTheme(this.props.leftSideBarTheme);
    }

    if (this.props.layoutWidth) {
      this.props.changeLayoutWidth(this.props.layoutWidth);
    }

    if (this.props.leftSideBarType) {
      this.props.changeSidebarType(this.props.leftSideBarType);
    }
    if (this.props.topbarTheme) {
      this.props.changeTopbarTheme(this.props.topbarTheme);
    }

    if (this.props.showRightSidebar) {
      this.toggleRightSidebar();
    }
  }
  toggleMenuCallback = () => {
    if (this.props.leftSideBarType === "default") {
      this.props.changeSidebarType("condensed", this.state.isMobile);
    } else if (this.props.leftSideBarType === "condensed") {
      this.props.changeSidebarType("default", this.state.isMobile);
    }
  };

  render() {
    return (
      <React.Fragment>
        <LoadingOverlay
          active={this.props.overlayLoading}
          spinner
          text={this.props.overlayMessage}
        >
          <div id="preloader">
            <div id="status">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          </div>


          <div id="layout-wrapper">
            <Header toggleMenuCallback={this.toggleMenuCallback} toggleRightSidebar={this.toggleRightSidebar} />
            <Sidebar theme={this.props.leftSideBarTheme}
              type={this.props.leftSideBarType}
              isMobile={this.state.isMobile} />
            <div className="main-content">
              {this.props.children}
            </div>
            <Footer />
          </div>
          {/* <Rightbar /> */}

          {
            this.props.location.pathname === '/player' && <PlayerFilter />
          }
          {
            this.props.location.pathname === '/contacts-list' && <UserFilter />
          }
          {
            this.props.location.pathname === '/banks-list' && <BankFilter />
          }

          {
            this.props.location.pathname === '/product' && <PlatformFilter />
          }

          {
            this.props.location.pathname === '/kiosk' && <KioskFilter />
          }

          {
            this.props.location.pathname === '/game' && <GameAccountFilter />
          }
          {
            this.props.location.pathname === '/game-account' && <GameAccountFilter />
          }
          {
            this.props.platformInfoIndex === '2' && <KioskFilter />
          }
          {
            this.props.platformInfoIndex === '3' && <GameAccountFilter />
          }
          {
            this.props.location.pathname === '/bank-accounts-list' && <BankAccountFilter />
          }
          {
            this.props.location.pathname.split('/')[1] === 'bonus' && <BonusFilter />
          }
          {
            this.props.location.pathname.split('/')[1] === 'transactions' && <TransactionFilter type='all' />
          }
          {
            this.props.location.pathname === '/transaction-deposit' && <TransactionFilter type='deposit' />
          }
          {
            this.props.location.pathname === '/transaction-transfer' && <TransactionFilter type='credit-in-out' />
          }
          {
            this.props.location.pathname === '/transaction-withdraw' && <TransactionFilter type='withdraw' />
          }
        </LoadingOverlay>
      </React.Fragment>
    );
  }
}


const mapStatetoProps = state => {
  return {
    ...state.Layout,
    ...state.PlatformList
  };
};
export default connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  toggleRightSidebar,
  changeTopbarTheme,
  changeLayoutWidth
})(withRouter(Layout));


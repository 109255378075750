import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const UPDATED_ONE_USER = gql`
    subscription {
        updatedOneUser {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
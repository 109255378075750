import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const CREATE_ONE_REBATE = gql`
    mutation(
        $input: CreateOneRebateInput!
    ) {
        createOneRebate (input: $input) {
            ...TransactionInfo
        }
    }
    ${TransactionFragment}
`;
import React from 'react';
import { Row, Col, Card, CardBody } from "reactstrap";
import GameCard from '../Components/game-card';
import CreditIn from '../Components/credit-in';
import CreditOut from '../Components/credit-out';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery } from 'react-apollo';
import { GET_GAME_ACCOUNTS } from '../../../graphql/query/GET_GAME_ACCOUNTS';
import { GET_USER_WALLETS } from '../../../graphql/query/GET_USER_WALLETS';
import WalletHelper from '../../../helpers/WalletHelper';

function BankerCreditInOut(props) {

    const { player, isActive, t, onDone } = props;
    const [creditIn, setCreditIn] = React.useState(false)
    const [creditOut, setCreditOut] = React.useState(false)
    const [game, setGame] = React.useState(false)

    const { data: walletData } = useQuery(GET_USER_WALLETS, {
        variables: {
            filter: {
                user: { id: { eq: player.id } },
                walletType: { eq: "Credit" },
                name: { eq: "Main" }
            },
        },
        fetchPolicy: 'network-only'
    });

    const { data: gameAccounts, refetch: refetchGameAccounts } = useQuery(GET_GAME_ACCOUNTS, {
        variables: {
            sorting: {
                field: "createdAt",
                direction: "DESC"
            },
            filter: {
                playerId: { eq: player.id }
            },
        },
        notifyOnNetworkStatusChange: true
    })

    React.useEffect(() => {
        if (isActive) {
        }
    }, [isActive])

    return (
        <React.Fragment>
            <div className="player-container">
                <div className="header-wrapper">
                    <div className="player-title">{t('Credit In/Out')}</div>
                </div>
            </div>
            <Row>
                {
                    walletData &&
                    WalletHelper.getMainWallet(walletData.wallets.edges) &&
                    <Col xs={12} sm={6} md={4} lg={3} className="gamecard">
                        <Card className="bg-success wallet-container">
                            <CardBody className="product-item">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <div className="wallet-wrapper p-3">
                                            <div className="text-center text-white ">{t('Main Wallet')}</div>
                                            <div className="text-center text-white font-size-16">{WalletHelper.getMainWallet(walletData.wallets.edges).node.balance}</div>
                                        </div>
                                    </Col>

                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                }

                {
                    gameAccounts &&
                    gameAccounts.gameAccounts.edges.map((game, index) => {
                        return (
                            <GameCard
                                key={index}
                                game={game}
                                isActive={isActive}
                                onCreditIn={(game) => {
                                    setCreditIn(true)
                                    setGame(game)
                                }}
                                onCreditOut={(game) => {
                                    setCreditOut(true)
                                    setGame(game)
                                }}
                            />
                        )
                    })
                }
            </Row>
            {
                creditIn &&
                <CreditIn
                    modal={creditIn}
                    toggleModal={() => setCreditIn(!creditIn)}
                    game={game}
                    player={player}
                    onDone={() => {
                        setCreditIn(!creditIn)
                        refetchGameAccounts()
                        onDone('creditIn')
                    }}
                />
            }
            {
                creditOut &&
                <CreditOut
                    modal={creditOut}
                    toggleModal={() => setCreditOut(!creditOut)}
                    game={game}
                    player={player}
                    onDone={() => {
                        setCreditOut(!creditOut)
                        refetchGameAccounts()
                        onDone('creditOut')
                    }}
                />
            }
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(BankerCreditInOut));
import gql from 'graphql-tag';
import { RejectReasonFragment } from '../fragments/reject.reason.fragment';

export const UPDATE_ONE_REJECT_REASON = gql`
    mutation(
        $input: UpdateOneRejectReasonInput!
    ) {
        updateOneRejectReason (input: $input) {
            ...RejectReason
        }
    }
    ${RejectReasonFragment}
`;
import gql from 'graphql-tag';
import { TagFragment } from '../fragments/tag.fragment';
import { PageFragment } from '../fragments/page.fragment';

export const GET_PLAYER_TAGS = gql`
query(
    $filter: TagFilter
    $paging: CursorPaging
    
){
    tags(
        paging: $paging
        filter: $filter
        sorting: { field: name, direction: ASC }
    ){
        pageInfo {
            ...Page
        }
        edges {
            node {
                ...TagInfo
            }
        }
    }
}
${PageFragment}
${TagFragment}
`;
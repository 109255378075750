
import React from 'react';
import { connect } from "react-redux";
import { withTranslation } from 'react-i18next';
import { Col, Card, Row, CardBody, CardDeck } from "reactstrap";
import { Link } from "react-router-dom";
import ProfileGameAccount from "./profile-game-account"
import ProfileInfo from "./profile-info"

function ProfileMain(props) {

    const { profile } = props;

    return (

        <React.Fragment>
            <Row className="pb-4">
                <Col lg="12" >
                    <CardDeck>
                        <ProfileInfo user={profile}></ProfileInfo>
                        <Card>
                            <CardBody className="">
                                <Row>
                                    <Col className="text-align pb-2">
                                        <div>
                                            <div className="header">Last Login Date</div>
                                            <div className="font-size-14"><Link to="#" className="text-dark">-</Link></div>
                                        </div>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col className="text-align pb-2">
                                        <div>
                                            <div className="header">Account Balance</div>
                                            <div className="font-size-14"><Link to="#" className="text-dark">0</Link></div>
                                        </div>
                                    </Col>

                                </Row>
                            </CardBody>

                        </Card>
                    </CardDeck>
                </Col>
            </Row>
            <Row>
                {/* <Col xl="12">
                    <Card>
                        <Row className="text-align">
                            <Col>
                                <div className="text-primary p-3">
                                    Profile Balance Info
                    </div>
                            </Col>
                        </Row>
                        <CardBody className="">
                            <Row>
                                <Col sm="12">

                                    <Row>
                                        <Col xs="3">

                                            <h5 className="font-size-15">Win Lost Rate</h5>
                                            <p className="text-muted mb-0">33.3%</p>

                                        </Col>
                                        <Col xs="3">
                                            <h5 className="font-size-15">Win Lost </h5>
                                            <p className="text-muted mb-0">300</p>
                                        </Col>
                                        <Col xs="3">
                                            <h5 className="font-size-15">Main Wallet Balance</h5>
                                            <p className="text-muted mb-0">3442.34</p>
                                        </Col>
                                        <Col xs="3">
                                            <h5 className="font-size-15">Last Topup Date</h5>
                                            <p className="text-muted mb-0">28-05-2020</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                </Col>

                <Col xl="12">
                    <Card>
                        <Row className="text-align">
                            <Col>
                                <div className="text-primary p-3">
                                    Account Details
                    </div>
                            </Col>
                        </Row>
                        <CardBody className="h-100">
                            <AvForm className="banker-deposit-content-info">
                                <Row>
                                    <Col xs={12} sm={6} md={3} lg={3}>
                                        <div className="pl-1 pr-1 pt-2 pb-2 font-weight-medium">Referer</div>
                                        <Input disabled type="text" className="form-control" value={'Sheldon'} onChange={(event) => { }} />
                                    </Col>
                                    <Col xs={12} sm={6} md={3} lg={3}>
                                        <div className="pl-1 pr-1 pt-2 pb-2 font-weight-medium">Group</div>
                                        <Input disabled type="text" className="form-control" value={'AXWE'} onChange={(event) => { }} />
                                    </Col>
                                    <Col xs={12} sm={6} md={3} lg={3}>
                                        <div className="pl-1 pr-1 pt-2 pb-2 font-weight-medium">Location</div>
                                        <Input disabled type="text" className="form-control" value={'Kepong'} onChange={(event) => { }} />
                                    </Col>
                                    <Col xs={12} sm={6} md={3} lg={3}>
                                        <div className="pl-1 pr-1 pt-2 pb-2 font-weight-medium">Last Topup Date</div>
                                        <Input disabled type="text" className="form-control" value={'28-05-2020'} onChange={(event) => { }} />
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>
                    </Card>

                </Col> */}

                {/* <Col xl="12">
                    <ProfileActivation></ProfileActivation>
                </Col>

                <Col xl="12">
                    <ProfileRecentTransaction></ProfileRecentTransaction>
                </Col> */}
                <Col xl="12">
                    <ProfileGameAccount user={profile}>
                    </ProfileGameAccount>
                </Col>
            </Row>
        </React.Fragment>)
}


export default connect()(withTranslation()(ProfileMain));
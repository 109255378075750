import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
    Label,
    FormGroup,
    Progress
} from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useLazyQuery } from 'react-apollo';
import { GET_BANK_ACCOUNTS } from '../../graphql/query';
import DropZone from '../Workbench/Components/drop-zone';
import Compressor from 'compressorjs';
import CloudinaryHelper from '../../helpers/CloudinaryHelper';

function WithdrawBank(props) {

    const { modal, toggleModal, t, onDone, transaction } = props;

    const [selectedFile, setSelectFile] = React.useState(null)
    const [onUpload, setOnUpload] = React.useState(false);
    const [progress, setProgress] = React.useState(0);

    document.onpaste = function (pasteEvent) {
        // consider the first item (can be easily extended for multiple items)
        var item = pasteEvent.clipboardData.items[0];

        if (item.type.indexOf("image") === 0) {
            setSelectFile(item.getAsFile());
        }
    }

    const [getBankAccounts, { data: companyBankAccounts }] = useLazyQuery(GET_BANK_ACCOUNTS, {
        variables: {
            paging: {
                first: 50
            },
            filter: {
                accountHolderType: { eq: "Company" },
                isActive: { is: true }
            },
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })

    React.useEffect(() => {
        if (modal) {
            getBankAccounts()
            setOnUpload(false)
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    const Compress = (file) => {
        const fileType = file['type'];
        const validImageTypes = ['image/jpeg', 'image/png'];
        if (!validImageTypes.includes(fileType)) {
            return file
        }

        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: 1,
                success: resolve,
                error: reject
            });
        })
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-account-page">
                    <ModalHeader toggle={toggleModal}>
                        {t('Select Company Bank Account')}
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-account-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {
                                    let result
                                    if (selectedFile) {
                                        setOnUpload(true)
                                        let imageData = await Compress(selectedFile)
                                        result = await CloudinaryHelper.upload(imageData, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=rjhrtxfi`, (progressEvent) => {
                                            let val = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                            setProgress(val)
                                        })
                                        setOnUpload(false)
                                    }
                                    onDone(values, result ? result.data.url : null)
                                }}
                            >
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            label={t('Company Bank Account')}
                                            name="bank"
                                            placeholder={t('Select Company Bank Account')}
                                            errorMessage={t('Select Company Bank Account')}
                                            type="select"
                                            className="custom-select"
                                            value={
                                                companyBankAccounts &&
                                                companyBankAccounts.bankAccounts.edges.length > 0 &&
                                                companyBankAccounts.bankAccounts.edges[0].node.id
                                            }
                                        >
                                            {
                                                companyBankAccounts &&
                                                companyBankAccounts.bankAccounts.edges.map((bankAccount, index) => {
                                                    return (
                                                        <option key={index} value={bankAccount.node.id}>{`${bankAccount.node.bank.name} - ${bankAccount.node.accountName} - ${bankAccount.node.accountNo}`}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            label={t('Amount')}
                                            name="amount"
                                            placeholder={t('Enter Amount')}
                                            errorMessage={t('Enter Amount')}
                                            type="text"
                                            value={transaction.amount}
                                        />
                                    </Col>
                                    <Col lg="12">
                                        <Label>Receipt</Label>
                                        <DropZone
                                            selectedFile={selectedFile}
                                            onFileDrop={(file) => {
                                                setSelectFile(file)
                                            }}
                                        />
                                    </Col>
                                    {
                                        onUpload &&
                                        <Col lg={12}>
                                            <FormGroup className="mb-3">
                                                <Label>Uploading</Label>
                                                <Progress color="primary" value={progress}></Progress>
                                            </FormGroup>
                                        </Col>
                                    }
                                </Row>
                                <div className="action-container">
                                    <Button color="primary" type="submit">{t('Submit')}</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}

export default connect()(withTranslation()(WithdrawBank));

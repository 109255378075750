import React, { Fragment } from 'react';
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import { UPDATE_ONE_KIOSK_ACCOUNT } from '../../graphql/mutation/UPDATE_ONE_KIOSK';


export default function EditKioskAccount(props) {

    const { modal, toggleModal, kioskAccount, curPlatform } = props;


    const [platform, setPlatform] = React.useState([])

    React.useEffect(() => {
        if (curPlatform) {
            setPlatform(curPlatform);
        }
    }, [curPlatform])

    const [updateOneKioskAccount] = useMutation(UPDATE_ONE_KIOSK_ACCOUNT);
    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-bank-page">
                <ModalHeader toggle={toggleModal}>
                    Edit Kiosk Account
                </ModalHeader>
                <ModalBody>
                    <div id="new-bank-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                updateOneKioskAccount({
                                    variables: {
                                        input: {
                                            "update": {
                                                "username": values.username,
                                                "password": values.password,
                                                "secondPassword": values.secondPassword,
                                                "platformId": platform.id
                                            },
                                            id: kioskAccount.id
                                        }
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).then(val => {
                                    if (val) {
                                        toggleModal()
                                    }
                                })
                            }}
                            onInvalidSubmit={() => {

                            }}
                        >
                            <Row>
                                <Col lg="12">
                                    <AvField
                                        label="Kiosk Login"
                                        name="username"
                                        type="text"
                                        placeholder="Enter Kiosk Login"
                                        errorMessage="Enter Kiosk Login"
                                        value={kioskAccount.username}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <AvField
                                        label="Password"
                                        name="password"
                                        type="text"
                                        placeholder="Enter Password"
                                        errorMessage="Enter Password"
                                        value={kioskAccount.password}
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />

                                </Col>
                                <Col lg="6">
                                    <AvField
                                        label="Second Password"
                                        name="secondPassword"
                                        type="text"
                                        placeholder="Enter Second Password"
                                        errorMessage="Enter Second Password"
                                        value={kioskAccount.secondPassword}

                                    />

                                </Col>
                            </Row>
                            <Row>
                                <Col lg="6">
                                    <AvField
                                        label="Platform"
                                        name="Platform"
                                        type="text"
                                        disabled={true}
                                        value={platform.displayName}
                                        placeholder=""
                                        errorMessage=""
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>

                            </Row>


                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
import gql from 'graphql-tag';
import { BankAccountFragment } from '../fragments/bank.fragment';

export const CREATE_ONE_BANK_ACCOUNT = gql`
    mutation(
        $input: CreateOneBankAccountInput!
    ) {
        createOneBankAccount (input: $input) {
            ...BankAccountInfo
        }
    }
    ${BankAccountFragment}
`;
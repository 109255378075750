import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
    hideRightSidebar,
    changeFilter,
    changeSort,
} from "../../store/actions";

//SimpleBar
import SimpleBar from "simplebar-react";
import DatePicker from "react-datepicker";

import { Link } from "react-router-dom";
import { useQuery } from '@apollo/react-hooks';
import { GET_TAGS_ONLY } from '../../graphql/query/GET_TAGS_ONLY';
import _ from 'lodash';
import { AvForm, AvField } from "availity-reactstrap-validation";
import moment from 'moment';

import "../../components/CommonForBoth/rightbar.scss"

function PlayerFilter(props) {

    //filter
    const [createStart, setCreateStart] = React.useState(null);
    const [createEnd, setCreateEnd] = React.useState(null);
    const [updateStart, setUpdateStart] = React.useState(null);
    const [updateEnd, setUpdateEnd] = React.useState(null);
    const [username, setUsername] = React.useState('')
    const [phoneNo, setPhoneNo] = React.useState('')

    //sort
    const [sortRoles, setSortRoles] = React.useState(null)
    const [sortUsername, setSortUsername] = React.useState(null)
    const [sortPhoneNo, setSortPhoneNo] = React.useState(null)
    const [sortCreate, setSortCreate] = React.useState(null)
    const [sortUpdate, setSortUpdate] = React.useState(null)

    const { data: roleData } = useQuery(GET_TAGS_ONLY, {
        variables: {
            input: {

            }
        }
    });

    const hideRightSidebar = (e) => {
        e.preventDefault();
        props.hideRightSidebar();
    }

    const changeFilter = (data) => {

        props.changeFilter(data);
    }

    const changeSort = (data) => {
        props.changeSort(data);
    }

    const GenerateDateFilter = (type, startDate, endDate) => {
        if (startDate && endDate) {
            return {
                and: [{
                    [type]: { gt: `${moment(startDate)}` }
                }, {
                    [type]: { lt: `${moment(endDate).add('hours', 23).add('minutes', 59).add('seconds', 59)}` }
                }]
            }
        }
        return { [type]: { lt: new Date() } }
    }

    const ResetFilter = () => {
        setUsername('')
        setPhoneNo('')
        setCreateStart(null)
        setCreateEnd(null)
        setUpdateStart(null)
        setUpdateEnd(null)
        var x = document.getElementsByClassName("checkbox");
        _.forEach(x, checkbox => {
            checkbox.checked = false;
        })
    }

    const ResetSort = () => {
        setSortRoles(null)
        setSortUsername(null)
        setSortPhoneNo(null)
        setSortCreate(null)
        setSortUsername(null)
    }

    useEffect(() => {
        ResetFilter()
        ResetSort()
    }, [])

    useEffect(() => {
        if (roleData) {
            let variables = {
                username: { iLike: `%${username}%` },
                phoneNo: { iLike: `%${phoneNo}%` },
            }
            if (_.isEmpty(phoneNo)) {
                delete variables["phoneNo"]
            }
            changeFilter(
                {
                    belongs: "player-list",
                    filter:
                    {
                        and: [
                            variables,

                            GenerateDateFilter('createdAt', createStart, createEnd),
                            GenerateDateFilter('updatedAt', updateStart, updateEnd)
                        ]
                    }
                }
            )
        }
    }, [username, phoneNo, createStart, createEnd, updateStart, updateEnd]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {

        let sorts = []

        if (sortRoles !== null) {
            sorts.push({
                field: "role", direction: sortRoles
            })
        }

        if (sortUsername !== null) {
            sorts.push({
                field: "username", direction: sortUsername
            })
        }

        if (sortPhoneNo !== null) {
            sorts.push({
                field: "phoneNo", direction: sortPhoneNo
            })
        }

        if (sortCreate !== null) {
            sorts.push({
                field: "createdAt", direction: sortCreate
            })
        }

        if (sortUpdate !== null) {
            sorts.push({
                field: "updatedAt", direction: sortUpdate
            })
        }

        changeSort(sorts)
    }, [sortRoles, sortUsername, sortPhoneNo, sortCreate, sortUpdate]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="right-bar users-link">

                <SimpleBar style={{ height: "100vh" }}>
                    <div data-simplebar className="h-100">
                        <AvForm className="needs-validation" >

                            <div className="rightbar-title px-3 py-4">
                                <Link to="#" onClick={hideRightSidebar} className="right-bar-toggle float-right">
                                    <i className="mdi mdi-close noti-icon"></i>
                                </Link>
                                <h5 className="m-0">User Filter</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetFilter}>
                                        Reset Filter
                                    </button>
                                </div>
                                {/* <span className="mb-2 d-block">Role</span>
                                {
                                    roleData &&
                                    _.filter(roleData.__type.enumValues, ({ name }) => {
                                        return name !== "Developer"
                                    }).map(({ name }, index) => {
                                        return (
                                            <div key={index} className="custom-control custom-checkbox">
                                                <Input type="checkbox" className="custom-control-input checkbox" id={name} onChange={() => {
                                                    let data = [];
                                                    if (_.includes(roles, name)) {
                                                        data = _.filter(roles, role => {
                                                            return role !== name
                                                        })
                                                    } else {
                                                        data = [...roles, name]
                                                    }

                                                    setRoles(data)
                                                }} />
                                                <Label className="custom-control-label" htmlFor={name}>{name}</Label>
                                            </div>
                                        )
                                    })
                                }

                                <hr className="mt-1" /> */}

                                <span className="mb-2 d-block">Username</span>
                                <AvField
                                    value={username}
                                    name="username"
                                    type="search"
                                    onChange={(e) => {
                                        setUsername(e.target.value)
                                    }}
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Mobile Number</span>
                                <AvField
                                    value={phoneNo}
                                    name="mobile-no"
                                    type="search"
                                    validate={{
                                        pattern: {
                                            value: "^[0-9]+$",
                                            errorMessage: "Enter Only Number"
                                        }
                                    }}
                                    onChange={(e) => {
                                        setPhoneNo(e.target.value)
                                    }}
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Created From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={createStart}
                                    onChange={date => {
                                        if (date > createEnd) {
                                            setCreateEnd(date)
                                        }
                                        setCreateStart(date)
                                    }}
                                    selectsStart
                                    startDate={createStart}
                                    endDate={createEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={createEnd}
                                    onChange={date => {
                                        setCreateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={createStart}
                                    endDate={createEnd}
                                    minDate={createStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />

                                <hr className="mt-1" />

                                <span className="mb-2 d-block">Updated From</span>
                                <DatePicker
                                    className="form-control start-date"
                                    selected={updateStart}
                                    onChange={date => {
                                        if (date > updateEnd) {
                                            setUpdateEnd(date)
                                        }
                                        setUpdateStart(date)
                                    }}
                                    selectsStart
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="From date"
                                />
                                <DatePicker
                                    className="form-control"
                                    selected={updateEnd}
                                    onChange={date => {
                                        setUpdateEnd(date)
                                    }}
                                    selectsEnd
                                    startDate={updateStart}
                                    endDate={updateEnd}
                                    minDate={updateStart}
                                    maxDate={new Date()}
                                    dateFormat="dd MM yyyy"
                                    placeholderText="To date"
                                />

                                <hr className="mt-1" />
                            </div>

                            <div className="rightbar-title px-3 py-4  user-sorting-title">
                                <h5 className="m-0">User Sorting</h5>
                            </div>

                            <hr className="my-0" />

                            <div className="p-4">
                                <div className="reset-button">
                                    <button type="button" className="waves-effect btn btn-outline-secondary" onClick={ResetSort}>
                                        Reset Sort
                                    </button>
                                </div>
                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Role</span>
                                    <input
                                        className="role-sort"
                                        type="radio"
                                        id="roleAsc"
                                        name="roleSort"
                                        value="ASC"
                                        checked={sortRoles === 'ASC'}
                                        onClick={() => {
                                            if (sortRoles === 'ASC') {
                                                setSortRoles(null)
                                            } else {
                                                setSortRoles('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="roleAsc">ASC</label>
                                    {"   "}
                                    <input
                                        className="role-sort"
                                        type="radio"
                                        id="roleDesc"
                                        name="roleSort"
                                        value="DESC"
                                        checked={sortRoles === 'DESC'}
                                        onClick={() => {
                                            if (sortRoles === 'DESC') {
                                                setSortRoles(null)
                                            } else {
                                                setSortRoles('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="roleDesc">DESC</label>
                                </div>


                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Username</span>
                                    <input
                                        type="radio"
                                        id="usernameAsc"
                                        name="usernameSort"
                                        value="ASC"
                                        checked={sortUsername === 'ASC'}
                                        onClick={() => {
                                            if (sortUsername === 'ASC') {
                                                setSortUsername(null)
                                            } else {
                                                setSortUsername('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="usernameAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="usernameDesc"
                                        name="usernameSort"
                                        value="DESC"
                                        checked={sortUsername === 'DESC'}
                                        onClick={() => {
                                            if (sortUsername === 'DESC') {
                                                setSortUsername(null)
                                            } else {
                                                setSortUsername('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="usernameDesc">DESC</label>
                                </div>

                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Mobile Number</span>
                                    <input
                                        type="radio"
                                        id="mobileAsc"
                                        name="mobileSort"
                                        value="ASC"
                                        checked={sortPhoneNo === 'ASC'}
                                        onClick={() => {
                                            if (sortPhoneNo === 'ASC') {
                                                setSortPhoneNo(null)
                                            } else {
                                                setSortPhoneNo('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="mobileAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="mobileDesc"
                                        name="mobileSort"
                                        value="DESC"
                                        checked={sortPhoneNo === 'DESC'}
                                        onClick={() => {
                                            if (sortPhoneNo === 'DESC') {
                                                setSortPhoneNo(null)
                                            } else {
                                                setSortPhoneNo('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="mobileDesc">DESC</label>
                                </div>

                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Created From</span>
                                    <input
                                        type="radio"
                                        id="createAsc"
                                        name="createSort"
                                        value="ASC"
                                        checked={sortCreate === 'ASC'}
                                        onClick={() => {
                                            if (sortCreate === 'ASC') {
                                                setSortCreate(null)
                                            } else {
                                                setSortCreate('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="createAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="createDesc"
                                        name="createSort"
                                        value="DESC"
                                        checked={sortCreate === 'DESC'}
                                        onClick={() => {
                                            if (sortCreate === 'DESC') {
                                                setSortCreate(null)
                                            } else {
                                                setSortCreate('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="createDesc">DESC</label>
                                </div>

                                <hr className="mt-1" />

                                <div className="radio-toolbar">
                                    <span className="mb-2 d-block">Updated From</span>
                                    <input
                                        type="radio"
                                        id="updateAsc"
                                        name="updateSort"
                                        value="ASC"
                                        checked={sortUpdate === 'ASC'}
                                        onClick={() => {
                                            if (sortUpdate === 'ASC') {
                                                setSortUpdate(null)
                                            } else {
                                                setSortUpdate('ASC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="updateAsc">ASC</label>
                                    {"   "}
                                    <input
                                        type="radio"
                                        id="updateDesc"
                                        name="updateSort"
                                        value="DESC"
                                        checked={sortUpdate === 'DESC'}
                                        onClick={() => {
                                            if (sortUpdate === 'DESC') {
                                                setSortUpdate(null)
                                            } else {
                                                setSortUpdate('DESC')
                                            }
                                        }}
                                        onChange={() => { }}
                                    />
                                    <label htmlFor="updateDesc">DESC</label>
                                </div>
                            </div>


                        </AvForm>
                    </div>
                </SimpleBar>
            </div>
            <div className="rightbar-overlay" onClick={hideRightSidebar}></div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return { ...state.Layout };
};

export default connect(mapStatetoProps, {
    hideRightSidebar,
    changeFilter,
    changeSort
})(PlayerFilter);

import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToastHelper from '../../helpers/ToastHelper';
import { useMutation } from 'react-apollo';
import { CREATE_ONE_PAYMENT_TYPE } from '../../graphql/mutation';

export default function NewPaymentType(props) {

    const { modal, toggleModal } = props;

    const [createOnePaymentType] = useMutation(CREATE_ONE_PAYMENT_TYPE)

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-payment-type">
                    <ModalHeader toggle={toggleModal}>
                        Add New Payment Type
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-user-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation" onValidSubmit={async (event, values) => {
                                await createOnePaymentType({
                                    variables: {
                                        input: {
                                            paymentType: {
                                                code: values.code,
                                                name: values.name,
                                                isActive: true
                                            }
                                        }
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).then(val => {
                                    if (val) {
                                        toggleModal()
                                    }
                                })
                            }}>
                                <Row>
                                    <Col lg="6">
                                        <AvField
                                            label="Code"
                                            name="code"
                                            type="text"
                                            placeholder="Enter Code"
                                            errorMessage="Enter Code"
                                            validate={{
                                                required: true
                                            }}
                                        />
                                    </Col>
                                    <Col lg="6">
                                        <AvField
                                            label="Name"
                                            name="name"
                                            type="text"
                                            placeholder="Enter Name"
                                            errorMessage="Enter Name"
                                            validate={{
                                                required: true
                                            }}
                                        />
                                    </Col>
                                </Row>
                                <div className="action-container">
                                    <Button color="primary" type="submit">Submit</Button>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment>
    );
}
import React from 'react';
import { Row, Col, Table } from "reactstrap";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery } from 'react-apollo';
import { GET_USER } from '../../graphql/query';
import ReferralItem from './referral-item';
import InfiniteScroll from 'react-infinite-scroll-component';

function ReferralList(props) {

    const { t, tabType, search } = props;
    const first = 50
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)

    const { data: dataUser, fetchMore, refetch, loading } = useQuery(GET_USER, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: {
                and: [
                    {
                        or: [
                            { username: { iLike: `%${search}%` } },
                            { phoneNo: { iLike: `%${search}%` } },
                        ]
                    },
                    { role: { eq: 'Player' } },
                    { referralStatus: { eq: tabType } }
                ]
            },
            sorting: {
                field: 'updatedAt',
                direction: 'DESC'
            },
        },
        fetchPolicy: 'network-only'
    })

    React.useEffect(() => {
        if (dataUser) {
            setAfterCursor(dataUser.users.pageInfo.endCursor)
            setPageInfo(dataUser.users.pageInfo)
        }
    }, [dataUser])

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: {
                        role: { eq: 'Player' },
                        referralStatus: { eq: tabType }
                    },
                    sorting: {
                        field: 'createdAt',
                        direction: 'ASC'
                    },
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.users.edges;
                    const pageInfo = fetchMoreResult.users.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            users: {
                                __typename: previousResult.users.__typename,
                                edges: _.uniqBy([...previousResult.users.edges, ...newEdges], 'node.id'),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="">
                <Row>
                    <Col sm="12">
                        {
                            dataUser &&
                            <InfiniteScroll
                                dataLength={dataUser.users.edges.length} //This is important field to render the next data
                                next={() => setIsFetchMore(true)}
                                hasMore={pageInfo.hasNextPage}
                                loader={
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                }
                                endMessage={
                                    <div className="text-center my-3">
                                        <div>Yay! You have seen it all</div>
                                    </div>
                                }
                            >
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Player</th>
                                                <th>Referrer</th>
                                                <th>First Deposit</th>
                                                <th>Pecentage (%)</th>
                                                <th>Payable Amount</th>
                                                <th>Paid By</th>
                                                <th>Paid At</th>
                                            </tr>
                                        </thead>
                                        {
                                            !loading &&
                                            dataUser &&
                                            dataUser.users.edges.map((user, index) => {
                                                return (
                                                    <ReferralItem
                                                        key={index}
                                                        t={t}
                                                        user={user}
                                                        type={tabType}
                                                        refetchList={() => {
                                                            refetch({
                                                                variables: {
                                                                    paging: {
                                                                        first: first,
                                                                        after: null
                                                                    },
                                                                    filter: {
                                                                        role: { eq: 'Player' },
                                                                        referralStatus: { eq: tabType }
                                                                    },
                                                                    sorting: {
                                                                        field: 'createdAt',
                                                                        direction: 'ASC'
                                                                    },
                                                                },
                                                            })
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                        {
                                            dataUser &&
                                            dataUser.users.edges.length === 0 &&
                                            <tbody>
                                                <tr className="text-center pt-3">
                                                    <td colSpan={12}>
                                                        {t('No User Found')}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }
                                    </Table>
                                </div>
                            </InfiniteScroll>
                        }
                    </Col>
                </Row>
                <div className="load-more-container">
                    {
                        (loading) &&
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                            </div>
                        </Col>
                    }
                </div>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(ReferralList));
import React from 'react';
import { Row, Col, Nav, NavLink, Card, CardBody, NavItem, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery, useMutation } from 'react-apollo';
import CampaignInfoList from './campaign-info-list';
import { GET_CAMPAIGN, CHECK_SMS_BALANCE } from '../../graphql/query';
import moment from 'moment';
import Switch from "react-switch";
import { UPDATE_ONE_MARKETING_CAMPAIGN } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';
import EditCampaign from './edit-campaign';
import Breadcrumbs from '../../components/Common/Breadcrumb';

function CampaignInfo(props) {

    const { t } = props;
    const [tabType, setTabType] = React.useState('Raw')
    const [search, setSearch] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [edit, setEdit] = React.useState(false)
    const [nextBot, setNextBot] = React.useState(null)

    const { data: campaign } = useQuery(GET_CAMPAIGN, {
        variables: {
            id: props.location.state ? props.location.state.campaign.id : ''
        }
    })

    const { data: smsBalance } = useQuery(CHECK_SMS_BALANCE);

    const [updateOneCampaign] = useMutation(UPDATE_ONE_MARKETING_CAMPAIGN);

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };


    React.useEffect(() => {
        if (!props.location.state) {
            window.location = "/dashboard";
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const GetNextDate = (min) => {
        const start = moment();
        if (min === 60) {
            return moment(start).add(60, 'minutes').format(`HH:00`);
        } else if ((min - start.minute()) > 0) {
            return moment(start).format(`HH:${min}`);
        }
        return moment(start).add(60, 'minutes').format(`HH:${min}`);
    }

    const CheckNext = () => {
        const start = moment();
        if (start.minute() < 15) {
            return 'Raw'
        } else if (start.minute() < 30) {
            return 'Available'
        } else if (start.minute() < 45) {
            return 'Waiting'
        } else if (start.minute() < 60) {
            return 'Sent'
        }
    }

    const GetLanguage = (lang) => {
        if (lang == null) {
            return "All"
        } else if (lang == "EN") {
            return "English"
        } else if (lang == "MS") {
            return "Malay"
        } else if (lang == "ZH") {
            return "Chinese"
        }
    }

    React.useEffect(() => {
        setNextBot(CheckNext())
        setInterval(() => {
            setNextBot(CheckNext())
        }, 60000)
    }, [])

    return (
        <React.Fragment>
            <div className="page-content referral campaign">
                {
                    campaign &&
                    <Breadcrumbs title="Marketing Campaign" breadcrumbItem={campaign.marketingCampaign.name} back />
                }
                {
                    campaign &&
                    <Card>
                        <CardBody className="border-top">
                            <Row>
                                <Col sm="4">
                                    <div>
                                        <p className="text-muted mb-2">Campaign Name</p>
                                        <h5 className="align-left cursor-pointer">{campaign.marketingCampaign.name}<span className="bx bx-edit-alt ml-1 font-size-17" onClick={() => setEdit(true)}></span></h5>
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div>
                                        <p className="text-muted mb-2">Start At</p>
                                        <h5>{moment(campaign.marketingCampaign.startAt).format('YYYY-MM-DD HH:mm:ss')}</h5>
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div>
                                        <p className="text-muted mb-2">End At</p>
                                        <h5>{moment(campaign.marketingCampaign.endAt).format('YYYY-MM-DD HH:mm:ss')}</h5>
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div>
                                        <p className="text-muted mb-2">Medium</p>
                                        <h5>{campaign.marketingCampaign.marketingCampaignMedium} {campaign.marketingCampaign.marketingCampaignMedium === 'SMS' && smsBalance ? `(Balance ${smsBalance.smsCheckBalance})` : ''}</h5>
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div>
                                        <p className="text-muted mb-2">Targeting Language</p>
                                        <h5>{GetLanguage(campaign.marketingCampaign.targetingLanguage)}</h5>
                                    </div>
                                </Col>
                                <Col sm="4">
                                    <div className="align-left">
                                        <div className="mr-4">
                                            <p className="text-muted mb-2">Active?</p>
                                            <Switch
                                                readOnly={true}
                                                uncheckedIcon={<Offsymbol text="NO" />}
                                                checkedIcon={<OnSymbol text="YES" />}
                                                className="mr-1"
                                                onColor="#34c38f"
                                                offColor="#f46a6a"
                                                onChange={() => {
                                                    updateOneCampaign({
                                                        variables: {
                                                            input: {
                                                                update: {
                                                                    isActive: !campaign.marketingCampaign.isActive
                                                                },
                                                                id: campaign.marketingCampaign.id
                                                            }
                                                        }
                                                    }).catch(e => {
                                                        ToastHelper.toggleToast({
                                                            toastType: "error",
                                                            message: e,
                                                            title: "",
                                                            onClick: function () { }
                                                        })
                                                    }).then(val => {
                                                        if (val) {
                                                            ToastHelper.toggleToast({
                                                                message: "Update Succesfully!",
                                                                title: "",
                                                                onClick: function () { }
                                                            })
                                                        }
                                                    })
                                                }}
                                                checked={campaign.marketingCampaign.isActive}
                                            />
                                        </div>
                                        <div>
                                            <p className="text-muted mb-2">Auto Mode?</p>
                                            <Switch
                                                readOnly={true}
                                                uncheckedIcon={<Offsymbol text="NO" />}
                                                checkedIcon={<OnSymbol text="YES" />}
                                                className="mr-1"
                                                onColor="#34c38f"
                                                offColor="#f46a6a"
                                                onChange={() => {
                                                    updateOneCampaign({
                                                        variables: {
                                                            input: {
                                                                update: {
                                                                    autoMode: !campaign.marketingCampaign.autoMode
                                                                },
                                                                id: campaign.marketingCampaign.id
                                                            }
                                                        }
                                                    }).catch(e => {
                                                        ToastHelper.toggleToast({
                                                            toastType: "error",
                                                            message: e,
                                                            title: "",
                                                            onClick: function () { }
                                                        })
                                                    }).then(val => {
                                                        if (val) {
                                                            ToastHelper.toggleToast({
                                                                message: "Update Succesfully!",
                                                                title: "",
                                                                onClick: function () { }
                                                            })
                                                        }
                                                    })
                                                }}
                                                checked={campaign.marketingCampaign.autoMode}
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12">
                                    <div>
                                        <p className="text-muted mb-2">Message</p>
                                        <h5>{campaign.marketingCampaign.message}</h5>
                                    </div>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                }
                <div className="search-bar-container">
                    <div className="button-add-banner-container">
                        <div className="app-search d-none d-lg-block">
                            <div className="position-relative">
                                <input
                                    value={search}
                                    type="text"
                                    className="form-control"
                                    placeholder="Username/Telegram ID/Phone No..."
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                                <span className="bx bx-search-alt"></span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="dropdown d-inline-block d-lg-none ml-2">
                                <button
                                    type="button"
                                    className="btn header-item noti-icon waves-effect"
                                    id="page-header-search-dropdown"
                                    onClick={() => { setIsSearch(!isSearch) }}>
                                    <i className="mdi mdi-magnify"></i>
                                </button>
                                <div
                                    className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                    aria-labelledby="page-header-search-dropdown"
                                >
                                    <form className="p-3">
                                        <div className="form-group m-0">
                                            <div className="input-group">
                                                <input
                                                    value={search}
                                                    type="text"
                                                    className="form-control"
                                                    placeholder={props.t('Username/Telegram ID/Phone No') + "..."}
                                                    onChange={(e) => setSearch(e.target.value)}
                                                />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" type="submit">
                                                        <i className="mdi mdi-magnify"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <Row className="pb-4">
                    <Col lg="12" >
                        <Card>
                            <CardBody>
                                <Nav tabs>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: tabType === 'Raw',
                                                'align-left': true
                                            })}
                                            onClick={() => {
                                                setTabType('Raw');
                                            }}
                                        >{t('Raw')} {campaign && campaign.marketingCampaign.autoMode && <><i className={`bx bx-bot font-size-16 ml-1 mr-1 ${nextBot === 'Raw' ? 'bx-tada text-success' : ''}`}></i> {`(${GetNextDate(15)})`}</>}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: tabType === 'Available',
                                                'align-left': true
                                            })}
                                            onClick={() => {
                                                setTabType('Available');
                                            }}
                                        >{t('Available')} {campaign && campaign.marketingCampaign.autoMode && <><i className={`bx bx-bot font-size-16 ml-1 mr-1 ${nextBot === 'Available' ? 'bx-tada text-success' : ''}`}></i> {`(${GetNextDate(30)})`} </>}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: tabType === 'Waiting',
                                                'align-left': true
                                            })}
                                            onClick={() => {
                                                setTabType('Waiting');
                                            }}
                                        >{t('Waiting')} {campaign && campaign.marketingCampaign.autoMode && <><i className={`bx bx-bot font-size-16 ml-1 mr-1 ${nextBot === 'Waiting' ? 'bx-tada text-success' : ''}`}></i> {`(${GetNextDate(45)})`}</>}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: tabType === 'Sent',
                                                'align-left': true
                                            })}
                                            onClick={() => {
                                                setTabType('Sent');
                                            }}
                                        >{t('Sent')} {campaign && campaign.marketingCampaign.isActive && <><i className={`bx bx-bot font-size-16 ml-1 mr-1 ${nextBot === 'Sent' ? 'bx-tada text-success' : ''}`}></i> {`(${GetNextDate(60)})`}</>}</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: tabType === 'Failed',
                                                'align-left': true
                                            })}
                                            onClick={() => {
                                                setTabType('Failed');
                                            }}
                                        >{t('Failed')}</NavLink>
                                    </NavItem>
                                </Nav>
                                <TabContent className="mt-4">
                                    <TabPane>
                                        {
                                            <CampaignInfoList
                                                campaign={campaign}
                                                tab={tabType}
                                                campaignId={props.location.state.campaign.id}
                                                search={search}
                                            />
                                        }
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
            {
                edit &&
                <EditCampaign
                    modal={edit}
                    toggleModal={() => {
                        setEdit(!edit)
                    }}
                    campaign={{
                        node: campaign.marketingCampaign
                    }}
                />
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(CampaignInfo));

import React from "react";

import { connect } from "react-redux";

import { Link } from "react-router-dom";// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
//i18n
import { withTranslation } from 'react-i18next';

// Redux Store
import { toggleRightSidebar } from "../../store/actions";
import { useMutation } from "react-apollo";
import { FIND_OR_CREATE_ONE_SETTING } from "../../graphql/mutation";

function Header(props) {

  const [isSearch, setIsSearch] = React.useState(false)

  const [getCompanyName, { data: campanyName }] = useMutation(FIND_OR_CREATE_ONE_SETTING, {
    variables: {
      input: {
        description: "Company Name",
        key: "companyName",
        settingType: "CompanyInfo",
        fieldType: "TextField"
      }
    }
  })

  /**
   * Toggle sidebar
   */
  const toggleMenu = () => {
    props.toggleMenuCallback();
  }

  React.useEffect(() => {
    getCompanyName()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  return (
    <React.Fragment>
      <header id="page-topbar" className="z-index-5" >
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box header-center">

              <Link to="/" className="logo logo-light">
                <span className="logo-xs d-lg-none">
                  {
                    campanyName &&
                    <h6 className="mb-0 text-white">{campanyName.findOrCreateSetting.value}</h6>
                  }
                </span>
                <span className="logo-sm">
                  {
                    campanyName &&
                    <h5 className="mb-0 text-white">{campanyName.findOrCreateSetting.value}</h5>
                  }
                </span>
                <span className="logo-lg">
                  {
                    campanyName &&
                    <h3 className="mb-0 text-white">{campanyName.findOrCreateSetting.value}</h3>
                  }
                </span>
              </Link>
            </div>

            <button type="button" onClick={toggleMenu} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ml-2">
              <button onClick={() => { setIsSearch(!isSearch) }} type="button" className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown">
                <i className="mdi mdi-magnify"></i>
              </button>
              <div className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                aria-labelledby="page-header-search-dropdown">

                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input type="text" className="form-control" placeholder="Search ..." aria-label="Recipient's username" />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit"><i className="mdi mdi-magnify"></i></button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <LanguageDropdown />

            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button type="button" onClick={toggleFullscreen} className="btn header-item noti-icon waves-effect" data-toggle="fullscreen">
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>

            {/* <NotificationDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
}

const mapStatetoProps = state => {
  const { layoutType } = state.Layout;
  return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(Header));

import gql from 'graphql-tag';


export const SET_KIOSK_DEFAULT = gql`
    mutation(
        $input: String!
    ) {
        kioskAccountSetDefault(id: $input){
            username
            password
            id
          }
    }
`;
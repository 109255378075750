import React from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    Button,
    Progress,
    Label,
} from "reactstrap";
import ToastHelper from '../../helpers/ToastHelper';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import { GET_ENUM } from '../../graphql/query';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import Compressor from 'compressorjs';
import CloudinaryHelper from "../../helpers/CloudinaryHelper"
import { FormGroup } from '@material-ui/core';
import DropZone from '../Workbench/Components/drop-zone';
import { CREATE_ONE_MARKETING_CAMPAIGN } from '../../graphql/mutation';
import DatePicker from "react-datepicker";
import moment from 'moment';

function NewCampaign(props) {

    const { modal, toggleModal, t } = props;
    const [selectedFile, setSelectFile] = React.useState(null);
    const [fileError, setFileError] = React.useState(null);
    const [onUpload, setOnUpload] = React.useState(false);
    const [medium, setMedium] = React.useState("")
    const [progress, setProgress] = React.useState(0);
    const [fromDate, setFromDate] = React.useState(moment().startOf("day").toDate())
    const [toDate, setToDate] = React.useState(moment().add('month', 1).endOf("day").toDate())

    const { data: mediumData } = useQuery(GET_ENUM, {
        variables: {
            name: "MarketingCampaignMedium"
        }
    });

    const { data: languageData } = useQuery(GET_ENUM, {
        variables: {
            name: "Language"
        }
    });

    const [createOneCampaign] = useMutation(CREATE_ONE_MARKETING_CAMPAIGN);

    React.useEffect(() => {
        setSelectFile(null)
        setFileError(null)
        setProgress(0)
        setOnUpload(false)
    }, [modal])

    React.useEffect(() => {
        if (mediumData) {
            setMedium(mediumData.__type.enumValues[0].name)
        }
    }, [mediumData])

    const GetLanguage = (lang) => {
        if (lang == null) {
            return "All Language"
        } else if (lang == "EN") {
            return "English"
        } else if (lang == "MS") {
            return "Malay"
        } else if (lang == "ZH") {
            return "Chinese"
        }
    }

    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                <div className="modal-content new-bonus-page">
                    <ModalHeader toggle={toggleModal}>
                        Add New Campaign
                    </ModalHeader>
                    <ModalBody>
                        <div id="new-bank-wizard" className="twitter-bs-wizard">
                            <AvForm className="needs-validation"
                                onValidSubmit={async (event, values) => {

                                    let imageData;
                                    if (selectedFile) {
                                        new Compressor(selectedFile, {
                                            quality: 0.5,
                                            async success(result) {
                                                setOnUpload(true)
                                                imageData = await CloudinaryHelper.upload(result, `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload?upload_preset=i4kdrkog`, (progressEvent) => {
                                                    let val = Math.round((progressEvent.loaded / progressEvent.total) * 100)
                                                    setProgress(val)
                                                })
                                                setOnUpload(false)
                                            },
                                            error(err) {
                                                ToastHelper.toggleToast({
                                                    toastType: "error",
                                                    message: err,
                                                    title: "",
                                                    onClick: function () { }
                                                })
                                            },
                                        });
                                    }

                                    await createOneCampaign({
                                        variables: {
                                            input: {
                                                marketingCampaign: {
                                                    name: values.campaignName,
                                                    mediaLink: imageData ? imageData.data.secure_url : null,
                                                    message: values.message,
                                                    startAt: values.fromDate,
                                                    endAt: values.toDate,
                                                    marketingCampaignMedium: values.campaignMedium,
                                                    targetingLanguage: values.targetingLanguage == "All Language" ? null : values.targetingLanguage,
                                                    isActive: true
                                                }
                                            }
                                        }
                                    }).catch(e => {
                                        console.log(JSON.stringify(e))
                                        ToastHelper.toggleToast({
                                            toastType: "error",
                                            message: e,
                                            title: "",
                                            onClick: function () { }
                                        })
                                    }).then(val => {
                                        if (val) {
                                            ToastHelper.toggleToast({
                                                message: "Create Succesfully!",
                                                title: "",
                                                onClick: function () { }
                                            })
                                            toggleModal()
                                        }
                                    })
                                }}
                                onInvalidSubmit={() => {

                                }}
                            >
                                <Row>
                                    {
                                        medium !== "SMS" &&
                                        <Col lg={12}>
                                            <DropZone
                                                selectedFile={selectedFile}
                                                onFileDrop={(file) => {
                                                    setSelectFile(file)
                                                }}
                                            />
                                        </Col>
                                    }
                                    {
                                        fileError &&
                                        !selectedFile &&
                                        <Col lg="12"><div className="image-url-error pb-2 font-size-10">* Please Provide an Image</div></Col>
                                    }
                                    {
                                        onUpload &&
                                        <Col lg={12}>
                                            <FormGroup className="mb-3">
                                                <Label>Uploading</Label>
                                                <Progress color="primary" value={progress}></Progress>
                                            </FormGroup>
                                        </Col>
                                    }
                                    <Col lg={6}>
                                        <AvField
                                            label="Campaign Name"
                                            name="campaignName"
                                            type="text"
                                            placeholder="Enter Campaign Name"
                                            errorMessage="Enter Campaign name"
                                            validate={{
                                                required: { value: true },
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            type="select"
                                            name="campaignMedium"
                                            className="custom-select"
                                            label={t('Campaign Medium')}
                                            value={mediumData && mediumData.__type.enumValues[0].name}
                                            onChange={(e) => {
                                                setMedium(e.target.value)
                                                setSelectFile(null)
                                            }}
                                        >
                                            {
                                                mediumData &&
                                                mediumData.__type.enumValues.map(({ name }, index) => {
                                                    return (
                                                        <option key={index} value={name}>{name}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            value={fromDate}
                                            selected={fromDate}
                                            label="Start Date"
                                            name="fromDate"
                                            type="text"
                                            placeholderText="Enter Start Date"
                                            errorMessage="Enter Start Date"
                                            selectsStart
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM-dd"
                                            tag={[Input, DatePicker]}
                                            onChange={date => {
                                                setFromDate(date)
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            value={toDate}
                                            selected={toDate}
                                            label="End Date"
                                            name="toDate"
                                            type="text"
                                            placeholderText="Enter End Date"
                                            errorMessage="Enter End Date"
                                            selectsStart
                                            peekNextMonth
                                            showMonthDropdown
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="yyyy-MM-dd"
                                            minDate={fromDate}
                                            tag={[Input, DatePicker]}
                                            onChange={date => {
                                                setToDate(date)
                                            }}
                                        />
                                    </Col>
                                    <Col lg={6}>
                                        <AvField
                                            type="select"
                                            name="targetingLanguage"
                                            className="custom-select"
                                            defaultValue={"All Language"}
                                            label={t('Targeting Language')}
                                        >
                                            <option key={9999} value={"All Language"}>All Language</option>
                                            {
                                                languageData &&
                                                languageData.__type.enumValues.map(({ name }, index) => {
                                                    return (
                                                        <option key={index} value={name}>{GetLanguage(name)}</option>
                                                    )
                                                })
                                            }
                                        </AvField>
                                    </Col>
                                    <Col lg={12}>
                                        <AvField
                                            label="Message"
                                            name="message"
                                            type="textarea"
                                            placeholder="Enter Campaign Message"
                                            errorMessage="Enter Campaign Message"
                                        />
                                    </Col>
                                </Row>
                                {
                                    !onUpload &&
                                    <div className="action-container">
                                        <Button color="primary" type="submit">Submit</Button>
                                    </div>
                                }
                            </AvForm>
                        </div>
                    </ModalBody>
                </div>
            </Modal>
        </React.Fragment >
    );
}

export default connect()(withTranslation()(NewCampaign));
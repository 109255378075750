import { Pagination } from '@mui/material';
import { AvField, AvForm } from "availity-reactstrap-validation";
import classnames from "classnames";
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useLazyQuery, useQuery } from 'react-apollo';
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import { withTranslation } from 'react-i18next';
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Input, Label, Row, Table } from "reactstrap";
import { OperatingUsers, Operator } from '../../assets/scss/role.constant';
import { ME, PLAYER_SUMMARY_REPORT } from '../../graphql/query';
import {
    showOverlayLoading
} from "../../store/actions";

function PlayerReport(props) {

    var take = 100
    const { t, showOverlayLoading } = props;
    let history = useHistory();

    const csvLink = React.useRef(null);

    const [fromDate, setFromDate] = React.useState(moment().startOf("month").toDate())
    const [toDate, setToDate] = React.useState(moment().endOf("month").toDate())
    const [tempFromDate, setTempFromDate] = React.useState(moment().startOf("month").toDate())
    const [tempToDate, setTempToDate] = React.useState(moment().endOf("month").toDate())
    const [orderBy, setOrderBy] = React.useState('username')
    const [orderDirection, setOrderDirection] = React.useState('ASC')
    const [isFirstDepositor, setIsFirstDepositor] = React.useState(false)
    const [isNewMember, setIsNewMember] = React.useState(false)
    const [csvData, setCSVData] = React.useState([])
    const [readyDownload, setReadyDownload] = React.useState(false)

    const dateArr = [{ "name": "Today", "key": "day" }, { "name": "Yesterday", "key": "day", "deduct": 1 }, { "name": "In 3 Days", "minus": 2 }, { "name": "In A Week", "minus": 6 }, { "name": "Current Week", "key": "week" }, { "name": "Last Week", "key": "week", "deduct": 1 }, { "name": "In A Month", "minus": 29 }, { "name": "Current Month", "key": "month" }, { "name": "Last Month", "key": "month", "deduct": 1 }, { "name": "Current Year", "key": "year" }, { "name": "Last Year", "key": "year", "deduct": 1 }]
    const [dateType, setDateType] = React.useState(dateArr[7])
    const [filter, setFilter] = React.useState({
        fromDate: fromDate,
        toDate: toDate,
        orderBy: orderBy,
        orderDirection: orderDirection,
        isFirstDepositor: isFirstDepositor,
        isNewMember: isNewMember
    })
    const [page, setPage] = React.useState(1);

    const { data: meData } = useQuery(ME);

    const [getReport, { data, loading }] = useLazyQuery(PLAYER_SUMMARY_REPORT, {
        variables: {
            ...filter,
            skip: 0,
            take: take
        },
        fetchPolicy: 'network-only'
    })

    const [getCSVData, { data: csvTempData }] = useLazyQuery(PLAYER_SUMMARY_REPORT, {
        variables: {
            ...filter,
            skip: 0,
            take: take
        },
        fetchPolicy: 'no-cache'
    })

    React.useEffect(() => {
        setPage(1)
        getReport({
            variables: filter,
            fetchPolicy: 'network-only'
        })
    }, [filter])

    const Formatter = (value) => {
        let val = parseFloat(value).toFixed(2)
        return <NumberFormat
            value={val}
            displayType={'text'}
            thousandSeparator={true}
            decimalScale={2}
        />
    }

    const SetDates = (dateType) => {
        if (dateType.minus) {
            setTempFromDate(moment().subtract(dateType.minus, "days").toDate())
            setTempToDate(moment().toDate())
            setFilter({
                fromDate: moment().subtract(dateType.minus, "days").format('YYYY-MM-DD'),
                toDate: moment().format('YYYY-MM-DD'),
                orderBy: orderBy,
                orderDirection: orderDirection,
                isFirstDepositor: isFirstDepositor,
                isNewMember: isNewMember
            })
        } else if (dateType.key) {
            if (dateType.deduct) {
                setTempFromDate(moment().subtract(dateType.deduct, dateType.key).startOf(dateType.key).toDate())
                setTempToDate(moment().subtract(dateType.deduct, dateType.key).endOf(dateType.key).toDate())
                setFilter({
                    fromDate: moment().subtract(dateType.deduct, dateType.key).startOf(dateType.key).format('YYYY-MM-DD'),
                    toDate: moment().subtract(dateType.deduct, dateType.key).endOf(dateType.key).format('YYYY-MM-DD'),
                    orderBy: orderBy,
                    orderDirection: orderDirection,
                    isFirstDepositor: isFirstDepositor,
                    isNewMember: isNewMember
                })
            } else {
                setTempFromDate(moment().startOf(dateType.key).toDate())
                setTempToDate(moment().endOf(dateType.key).toDate())
                setFilter({
                    fromDate: moment().startOf(dateType.key).format('YYYY-MM-DD'),
                    toDate: moment().endOf(dateType.key).format('YYYY-MM-DD'),
                    orderBy: orderBy,
                    orderDirection: orderDirection,
                    isFirstDepositor: isFirstDepositor,
                    isNewMember: isNewMember
                })
            }
        }
    }

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
                setFilter({
                    fromDate: fromDate,
                    toDate: toDate,
                    orderBy: orderBy,
                    orderDirection: 'DESC',
                    isFirstDepositor: isFirstDepositor,
                    isNewMember: isNewMember
                })
            } else {
                setOrderDirection('ASC')
                setFilter({
                    fromDate: fromDate,
                    toDate: toDate,
                    orderBy: orderBy,
                    orderDirection: 'ASC',
                    isFirstDepositor: isFirstDepositor,
                    isNewMember: isNewMember
                })
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)

            setFilter({
                fromDate: fromDate,
                toDate: toDate,
                orderBy: type,
                orderDirection: 'ASC',
                isFirstDepositor: isFirstDepositor,
                isNewMember: isNewMember
            })
        }
    }

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }

    React.useEffect(() => {
        showOverlayLoading(loading)
    }, [loading]) // eslint-disable-line react-hooks/exhaustive-deps

    const DownloadToCSV = () => {
        getCSVData()
        setReadyDownload(true)
    }

    const CSVDownload = () => {
        return (
            <>
                <Button color="primary" onClick={DownloadToCSV} style={{ width: 165 }}>{t('  Download (CSV)')}{readyDownload && <i className="bx bx-hourglass bx-spin mr-3"></i>}</Button>
                {/* <div className="btn btn-primary btn-sm mb-3 mr-2" onClick={DownloadToCSV}>

                </div> */}
                <CSVLink
                    ref={csvLink}
                    filename={'player-report.csv'}
                    className="hidden"
                    data={csvData}
                    headers={[
                        { label: 'Player', key: 'username' },
                        { label: 'Total Deposit Amount', key: 'totalDepositAmount' },
                        { label: 'Total Deposit Count', key: 'totalDepositCount' },
                        { label: 'Total Withdrawal Amount', key: 'totalWithdrawalAmount' },
                        { label: 'Total Withdrawal Count', key: 'totalWithdrawalCount' },
                        { label: 'Total Bonus Amount', key: 'totalBonusAmount' },
                        { label: 'Total Free Credit Amount', key: 'totalFreeCreditAmount' },
                        { label: 'Total Rebate Amount', key: 'totalRebateAmount' },
                        { label: 'Total Referral Amount', key: 'totalReferralAmount' },
                        { label: 'Total Reward Amount', key: 'totalRewardAmount' },
                        { label: 'Total Commission Amount', key: 'totalCommissionAmount' },
                        { label: 'Total Daily Attendance Amount', key: 'totalDailyAttendanceAmount' },
                        { label: 'Total Weekly Attendance Amount', key: 'totalWeeklyAttendanceAmount' },
                        { label: 'Total Increment Amount', key: 'totalIncrementAmount' },
                        { label: 'Total Decrement Amount', key: 'totalDecrementAmount' },
                        { label: 'Average Deposit Amount', key: 'averageDepositAmount' },
                        { label: 'Highest Deposit Amount', key: 'highestDepositAmount' },
                        { label: 'Average Withdrawal Amount', key: 'averageWithdrawalAmount' },
                        { label: 'Highest Withdrawal Amount', key: 'highestWithdrawalAmount' },
                        { label: 'Total Credit In', key: 'totalCreditIn' },
                        { label: 'Total Credit Out', key: 'totalCreditOut' },
                        { label: 'Net Winning', key: 'netWinning' },
                        { label: 'Gross Profit', key: 'grossProfit' }
                    ]}
                >
                </CSVLink>
            </>
        )
    }

    React.useEffect(() => {
        if (csvTempData && readyDownload) {
            setCSVData((prevData) => [...prevData, ...csvTempData.playerSummaryReport])
        }
    }, [csvTempData])

    React.useEffect(() => {
        if (csvData.length > 0) {
            if (csvData.length < _.first(csvData).totalCount) {
                getCSVData({
                    variables: {
                        ...filter,
                        skip: csvData.length,
                        take: take
                    }
                })
            } else if (csvData.length > 0 && csvLink && readyDownload) {
                csvLink.current.link.click()
                setReadyDownload(false)
                setCSVData([])
            }
        }
    }, [csvData])

    return (
        <React.Fragment>
            <div className="page-content report">
                <Card>
                    <CardBody>
                        <AvForm onValidSubmit={(event, values) => {
                            setFromDate(values.fromDate ? moment(values.fromDate).startOf('day').toDate() : null)
                            setToDate(values.toDate ? moment(values.toDate).endOf('day').toDate() : null)

                            setFilter({
                                fromDate: values.fromDate ? moment(values.fromDate).startOf('day').toDate() : null,
                                toDate: values.toDate ? moment(values.toDate).endOf('day').toDate() : null,
                                orderBy: orderBy,
                                orderDirection: orderDirection,
                                isFirstDepositor: isFirstDepositor,
                                isNewMember: isNewMember
                            })
                        }}>
                            <Row>
                                <Col xs={12} sm={10} md={10} lg={10}>
                                    <Row>
                                        <Col xs={12} sm={5} md={5} lg={5}>
                                            <AvField
                                                value={tempFromDate}
                                                selected={tempFromDate}
                                                label="From Date"
                                                name="fromDate"
                                                type="text"
                                                placeholderText="Enter From Date"
                                                errorMessage="Enter From Date"
                                                selectsStart
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                tag={[Input, DatePicker]}
                                                onChange={date => {
                                                    setTempFromDate(date)
                                                }}
                                            />
                                        </Col>
                                        <Col xs={12} sm={5} md={5} lg={5}>
                                            <AvField
                                                value={tempToDate}
                                                selected={tempToDate}
                                                label="To Date"
                                                name="toDate"
                                                type="text"
                                                placeholderText="Enter To Date"
                                                errorMessage="Enter To Date"
                                                selectsStart
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                dateFormat="yyyy-MM-dd"
                                                tag={[Input, DatePicker]}
                                                minDate={tempFromDate}
                                                onChange={date => {
                                                    setTempToDate(date)
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col className="action-container" xs={12} sm={2} md={2} lg={2}>
                                    <div className="mb-3">
                                        <Button color="primary" type="submit">{t('Submit')}</Button>
                                    </div>
                                    {
                                        meData &&
                                        (meData.me.role === 'Developer' || meData.me.role === 'Admin') &&
                                        <div className="ml-2">
                                            <CSVDownload />
                                        </div>
                                    }
                                </Col>
                            </Row>
                            <Row className="ml-0">
                                <div className="button-container ml-3">
                                    {
                                        dateArr.map((type, index) =>
                                            <button
                                                key={index}
                                                className={
                                                    classnames({
                                                        "btn": true,
                                                        "mr-2": true,
                                                        "mb-2": true,
                                                        "btn-outline-primary": type.name !== dateType.name,
                                                        "btn-primary": type.name === dateType.name
                                                    })
                                                }
                                                onClick={() => {
                                                    setDateType(type)
                                                    SetDates(type)
                                                }}
                                            >{type.name}</button>
                                        )
                                    }
                                </div>
                            </Row>
                            <Row>
                                <div className="button-container ml-3 mt-1 mb-3">
                                    <div className="mr-3 font-weight-medium">{'Type: '}</div>
                                    <div className="custom-control custom-checkbox mr-3">
                                        <Input checked={isNewMember} type="checkbox" className="custom-control-input checkbox" id="is-new-member" onChange={(e) => setIsNewMember(!isNewMember)} />
                                        <Label className="custom-control-label" htmlFor="is-new-member">{t('Show New Member Only')}</Label>
                                    </div>
                                    <div className="custom-control custom-checkbox">
                                        <Input checked={isFirstDepositor} type="checkbox" className="custom-control-input checkbox" id="is-first-deposit" onChange={(e) => setIsFirstDepositor(!isFirstDepositor)} />
                                        <Label className="custom-control-label" htmlFor="is-first-deposit">{t('Show First Depositor Only')}</Label>
                                    </div>
                                </div>
                            </Row>
                        </AvForm>

                        <Row>
                            <Col sm="12">
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th colSpan="2" className="green">Deposits</th>
                                                <th colSpan="2" className="red">Withdrawals</th>
                                                <th colSpan="8" className="warning">Extra</th>
                                                <th colSpan="2" className="adjust">Adjustment</th>
                                                {
                                                    meData &&
                                                    (!OperatingUsers.includes(meData.me.role) &&
                                                        !Operator.includes(meData.me.role)) &&
                                                    <>
                                                        <th colSpan="2" className="green">Average Deposits</th>
                                                        <th colSpan="2" className="red">Average Withdrawals</th>
                                                        <th colSpan="3" className="warning">Credit</th>
                                                    </>
                                                }

                                                <th colSpan="1" className="">Profit</th>
                                            </tr>
                                        </thead>
                                        <thead>
                                            <tr>
                                                <Header name='Player' className="text-capitalize" type='username' />
                                                <Header name='Total' className="green" type='totalDepositAmount' />
                                                <Header name='Num' className="green" type='totalDepositCount' />
                                                <Header name='Total' className="red" type='totalWithdrawalAmount' />
                                                <Header name='Num' className="red" type='totalWithdrawalCount' />
                                                <Header name='Bonus' className="warning" type='totalBonusAmount' />
                                                <Header name='Promotion' className="warning" type='totalFreeCreditAmount' />
                                                <Header name='Rebate' className="warning" type='totalRebateAmount' />
                                                <Header name='Referral' className="warning" type='totalReferralAmount' />
                                                <Header name='Reward' className="warning" type='totalRewardAmount' />
                                                <Header name='Comission' className="warning" type='totalCommissionAmount' />
                                                <Header name='Daily Attendance' className="warning" type='totalDailyAttendanceAmount' />
                                                <Header name='Weekly Attendance' className="warning" type='totalWeeklyAttendanceAmount' />
                                                <Header name='Increment' className="green" type='totalIncrementAmount' />
                                                <Header name='Decrement' className="red" type='totalDecrementAmount' />
                                                {
                                                    meData &&
                                                    (!OperatingUsers.includes(meData.me.role) &&
                                                        !Operator.includes(meData.me.role)) &&
                                                    <>
                                                        <Header name='Average' className="green" type='averageDepositAmount' />
                                                        <Header name='Highest' className="green" type='highestDepositAmount' />
                                                        <Header name='Average' className="red" type='averageWithdrawalAmount' />
                                                        <Header name='Highest' className="red" type='highestWithdrawalAmount' />
                                                        <Header name='In' className="green" type='totalCreditIn' />
                                                        <Header name='Out' className="red" type='totalCreditOut' />
                                                        <Header name='Win' className="warning" type='netWinning' />
                                                    </>
                                                }
                                                <Header name='Gross' className="" type='grossProfit' />
                                                {/* <Header name='Net' className="" type='netProfit' />
                                                <Header name='Adjusted' className="" type='adjustedProfit' /> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data &&
                                                data.playerSummaryReport.length > 0 &&
                                                data.playerSummaryReport.map((report, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th>
                                                                <div className="align-left">
                                                                    <div className="cursor-pointer" onClick={() => {
                                                                        history.push({
                                                                            "pathname": "/cashier-workbench",
                                                                            target: "_blank",
                                                                            state: {
                                                                                user: {
                                                                                    node: {
                                                                                        username: report.username,
                                                                                        id: report.id
                                                                                    }
                                                                                }
                                                                            }
                                                                        });
                                                                    }}>
                                                                        <i className="bx bxs-wrench font-size-20 mr-2"></i>
                                                                    </div>
                                                                    {report.username}
                                                                </div>
                                                            </th>
                                                            <th className="green">{Formatter(report.totalDepositAmount)}</th>
                                                            <th className="green">{report.totalDepositCount}</th>
                                                            <th className="red">{Formatter(report.totalWithdrawalAmount)}</th>
                                                            <th className="red">{report.totalWithdrawalCount}</th>
                                                            <th className="warning">{Formatter(report.totalBonusAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalFreeCreditAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalRebateAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalReferralAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalRewardAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalCommissionAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalDailyAttendanceAmount)}</th>
                                                            <th className="warning">{Formatter(report.totalWeeklyAttendanceAmount)}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.totalIncrementAmount >= 0,
                                                                        red: report.totalIncrementAmount < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.totalIncrementAmount)}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        red: true
                                                                    })
                                                                }
                                                            >{Formatter(report.totalDecrementAmount)}</th>
                                                            {
                                                                meData &&
                                                                (!OperatingUsers.includes(meData.me.role) &&
                                                                    !Operator.includes(meData.me.role)) &&
                                                                <>
                                                                    <th className="green">{Formatter(report.averageDepositAmount)}</th>
                                                                    <th className="green">{Formatter(report.highestDepositAmount)}</th>
                                                                    <th className="red">{Formatter(report.averageWithdrawalAmount)}</th>
                                                                    <th className="red">{Formatter(report.highestWithdrawalAmount)}</th>
                                                                    <th className="green">{Formatter(report.totalCreditIn)}</th>
                                                                    <th className="red">{Formatter(report.totalCreditOut)}</th>
                                                                    <th
                                                                        className={
                                                                            classnames({
                                                                                green: report.netWinning >= 0,
                                                                                red: report.netWinning < 0
                                                                            })
                                                                        }
                                                                    >{Formatter(report.netWinning)}</th>
                                                                </>
                                                            }
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.grossProfit >= 0,
                                                                        red: report.grossProfit < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.grossProfit)}</th>
                                                            {/* <th
                                                                className={
                                                                    classnames({
                                                                        green: report.netProfit >= 0,
                                                                        red: report.netProfit < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.netProfit)}</th>
                                                            <th
                                                                className={
                                                                    classnames({
                                                                        green: report.adjustedProfit >= 0,
                                                                        red: report.adjustedProfit < 0
                                                                    })
                                                                }
                                                            >{Formatter(report.adjustedProfit)}</th> */}
                                                        </tr>
                                                    )
                                                })
                                            }
                                            {
                                                data &&
                                                data.playerSummaryReport.length === 0 &&
                                                <tr className="text-center pt-3">
                                                    <td colSpan={21}>
                                                        {t('No Report Found')}
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                        {
                            data &&
                            data.playerSummaryReport.length > 0 &&
                            <>
                                <div className="align-center p-2">
                                    <div></div>
                                    <Pagination count={Math.ceil(_.first(data.playerSummaryReport).totalCount / take)} color="primary" page={page} onChange={(event, value) => {
                                        // skip = (value - 1) * take
                                        setPage(value)

                                        getReport({
                                            variables: {
                                                ...filter,
                                                skip: (value - 1) * take,
                                                take: take
                                            },
                                            fetchPolicy: 'network-only'
                                        })
                                    }} />
                                </div>
                            </>
                        }
                    </CardBody>
                </Card>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { showOverlayLoading })(withTranslation()(PlayerReport));
import React, { useRef } from 'react';
import { Container, Row, Col, Table, Card, CardBody, NavLink, NavItem } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery, useMutation } from 'react-apollo';
import { GET_TRANSACTIONS } from '../../graphql/query/GET_TRANSACTIONS';
import TransactionItem from './transaction-item';
import classnames from 'classnames';
import _ from 'lodash';
import { Link, useHistory } from "react-router-dom";
import { GET_TRANSACTIONS_TOTAL_COUNT } from '../../graphql/query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DELETE_TRANSACTION } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';
import SweetAlert from "react-bootstrap-sweetalert";

function TransactionTransfer(props) {

    const first = 50;
    const { t } = props;
    const { tab } = props.match.params
    const [search, setSearch] = React.useState(props.location.state && props.location.state.serialNo ? props.location.state.serialNo : '')
    const [searchValue, setSearchValue] = React.useState(props.location.state && props.location.state.serialNo ? props.location.state.serialNo : '')
    const [isSearch, setIsSearch] = React.useState(false)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState('Completed')
    const [confirmDelete, setConfirmDelete] = React.useState(false)
    const [transaction, setTransaction] = React.useState(false)
    const [deleteTransaction] = useMutation(DELETE_TRANSACTION)

    const [filter, setFilter] = React.useState({
        and: [{
            or: [{ transactionType: { eq: "CreditIn" }, }, { transactionType: { eq: "CreditOut" } }],
        }, {
            kioskStatus: { eq: "Completed" }
        }]
    })
    const [orderBy, setOrderBy] = React.useState("createdAt")
    const [orderDirection, setOrderDirection] = React.useState("DESC")
    const history = useHistory();

    const Filter = (filter) => {
        if (_.isEmpty(search)) {
            if (_.isEmpty(props.filter)) {
                return {
                    ...filter
                }
            } else {
                return {
                    ...filter,
                    and: [
                        props.filter
                    ]
                }
            }
        } else {
            if (_.isEmpty(props.filter)) {
                return {
                    ...filter,
                    or: [
                        { serialNo: { iLike: `%${search}%` } },
                        { player: { username: { iLike: `%${search}%` } } }
                    ]
                }
            } else {
                return {
                    ...filter,
                    and: [
                        props.filter,
                        {
                            or: [
                                { serialNo: { iLike: `%${search}%` } },
                                { player: { username: { iLike: `%${search}%` } } }
                            ]
                        }
                    ]
                }
            }
        }
    }

    const [transactionFilter, setTransactionFilter] = React.useState({
        ...Filter({
            and: [{
                or: [{ transactionType: { eq: "CreditIn" }, }, { transactionType: { eq: "CreditOut" } }],
            }, {
                kioskStatus: { eq: "Completed" }
            }]
        })
    })

    const { data: transactionData, fetchMore, loading, refetch } = useQuery(GET_TRANSACTIONS, {
        variables: {
            filter: { ...transactionFilter },
            paging: {
                first: first
            },
            sorting: {
                field: orderBy,
                direction: orderDirection
            }
        },
        fetchPolicy: 'network-only'
    })

    // const { data: depositCount, refetch: refetchDeposit } = useQuery(GET_TRANSACTIONS_TOTAL_COUNT, {
    //     variables: {
    //         filter: {
    //             ...depositFilter
    //         },
    //     },
    //     fetchPolicy: 'network-only'
    // })

    // const { data: processingCount, refetch: refetchProcessing } = useQuery(GET_TRANSACTIONS_TOTAL_COUNT, {
    //     variables: {
    //         filter: {
    //             ...processingFilter
    //         },
    //     },
    //     fetchPolicy: 'network-only'
    // })

    const DeleteTransaction = async () => {
        setConfirmDelete(false)
        await deleteTransaction({
            variables: {
                id: transaction.node.id
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then((val) => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Transaction Deleted",
                    title: "",
                    onClick: function () { }
                })
                // refetchDeposit()
                // refetchProcessing()
            }
        })
    }

    React.useEffect(() => {
        if (!_.isEmpty(props.filter)) {
            setTransactionFilter({
                ...Filter(filter)
            })
        } else {
            setTransactionFilter({
                ...Filter(filter)
            })
        }
    }, [search, props.filter]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (transactionData) {
            setAfterCursor(transactionData.transactions.pageInfo.endCursor)
            setPageInfo(transactionData.transactions.pageInfo)
        }
    }, [transactionData])

    React.useEffect(() => {
        if (tab === 'pending') {
            setActiveTab('Pending');
            UpdateFilter('Pending')
        } else if (tab === 'all') {
            setActiveTab(null);
            UpdateFilter(null)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: { ...transactionFilter },
                    sorting: {
                        field: orderBy,
                        direction: orderDirection
                        // props.sort
                    }
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.transactions.edges;
                    const pageInfo = fetchMoreResult.transactions.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            transactions: {
                                __typename: previousResult.transactions.__typename,
                                edges: _.uniqBy([...previousResult.transactions.edges, ...newEdges], 'node.id'),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const UpdateFilter = (type) => {
        if (type !== null) {
            setFilter({
                transactionType: { in: ["CreditIn", "CreditOut"] },
                kioskStatus: { eq: type }
            })
            setTransactionFilter({
                ...Filter({
                    transactionType: { in: ["CreditIn", "CreditOut"] },
                    kioskStatus: { eq: type }
                })
            })
        } else {
            setFilter({
                transactionType: { in: ["CreditIn", "CreditOut"] }
            })
            setTransactionFilter({
                ...Filter({
                    transactionType: { in: ["CreditIn", "CreditOut"] }
                })
            })
        }

    }

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
            } else {
                setOrderDirection('ASC')
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)
        }
    }

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content transactions-page max-height">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Transactions" breadcrumbItem="Transfer" />
                    <div className="search-bar-container">
                        <div className="button-add-bank-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="align-right">
                            <div className="filter-container button-filter-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bank" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="12">
                                    <div className="overflow-auto">
                                        <ul className="nav nav-tabs nav-tabs-custom mb-2">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Completed' })} onClick={() => {
                                                    setActiveTab('Completed');
                                                    UpdateFilter('Completed')
                                                }} >
                                                    Completed
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Failed' })} onClick={() => {
                                                    setActiveTab('Failed');
                                                    UpdateFilter('Failed')
                                                }} >
                                                    Failed
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Pending' })} onClick={() => {
                                                    setActiveTab('Pending');
                                                    UpdateFilter('Pending')
                                                }} >
                                                    Pending
                                                    {/* {
                                                        depositCount &&
                                                        <span>{` (${depositCount.transactions.totalCount})`}</span>
                                                    } */}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Processing' })} onClick={() => {
                                                    setActiveTab('Processing');
                                                    UpdateFilter('Processing')
                                                }} >
                                                    Processing
                                                    {/* {
                                                        processingCount &&
                                                        <span>{` (${processingCount.transactions.totalCount})`}</span>
                                                    } */}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === null })} onClick={() => {
                                                    setActiveTab(null);
                                                    UpdateFilter(null)
                                                }} >
                                                    All
                                                </NavLink>
                                            </NavItem>

                                        </ul>
                                        {
                                            transactionData &&
                                            <InfiniteScroll
                                                dataLength={transactionData.transactions.edges.length} //This is important field to render the next data
                                                next={() => setIsFetchMore(true)}
                                                hasMore={pageInfo.hasNextPage}
                                                loader={
                                                    <div className="text-center my-3">
                                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                    </div>
                                                }
                                                endMessage={
                                                    <div className="text-center my-3">
                                                        <div>Yay! You have seen it all</div>
                                                    </div>
                                                }
                                            >
                                                <Table className="table mb-0">
                                                    <thead>
                                                        <tr className="font-size-12">
                                                            <Header name='Serial No' className="" type='serialNo' />
                                                            <Header name='Created At' className="" type='createdAt' />
                                                            <th>Type</th>
                                                            <th>Username</th>
                                                            <Header name='Amount' className="" type='amount' />
                                                            <th>Movement</th>
                                                            <th>Remark</th>
                                                            <Header name='Updated At' className="" type='updatedAt' />
                                                            <Header name='Status' className="" type='kioskStatus' />
                                                        </tr>
                                                    </thead>
                                                    {
                                                        transactionData &&
                                                        transactionData.transactions.edges.map((transaction, index) => {
                                                            return (
                                                                <TransactionItem
                                                                    key={index}
                                                                    refetch={refetch}
                                                                    transaction={transaction}
                                                                    onDone={() => {
                                                                        refetch()
                                                                        // refetchDeposit()
                                                                        // refetchProcessing()
                                                                    }}
                                                                    onDelete={() => {
                                                                        setConfirmDelete(true)
                                                                        setTransaction(transaction)
                                                                    }}
                                                                    onManageInWorkbench={() => {
                                                                        history.push({
                                                                            "pathname": "/cashier-workbench",
                                                                            target: "_blank",
                                                                            state: { user: { node: transaction.node.player } }
                                                                        });
                                                                    }}
                                                                />
                                                            )
                                                        })
                                                    }
                                                    {
                                                        transactionData &&
                                                        transactionData.transactions.edges.length === 0 &&
                                                        <tbody className="table-body">
                                                            <tr className="text-center pt-3">
                                                                <td colSpan={12}>
                                                                    {t('No Transactions Found')}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    }
                                                </Table>
                                            </InfiniteScroll>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className="load-more-container">
                                {
                                    (loading) &&
                                    <Col xs="12">
                                        <div className="text-center my-3">
                                            <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                        </div>
                                    </Col>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            {
                confirmDelete &&
                <SweetAlert
                    error
                    showCancel
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    title={'Alert'}
                    onConfirm={DeleteTransaction}
                    onCancel={() => setConfirmDelete(false)}
                >
                    {`Confirm Delete Transaction ${transaction.node.serialNo}`}
                </SweetAlert>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Transactions };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(TransactionTransfer));

// export default ContactsList;
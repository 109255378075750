import gql from "graphql-tag";
import { TelegramButtonFragment } from "../fragments/telegram-button-fragment";

export const UPDATE_ONE_TELEGRAM_BUTTON = gql`
  mutation ($input: UpdateOneTelegramButtonInput!) {
    updateOneTelegramButton(input: $input) {
      ...TelegramButton
    }
  }
  ${TelegramButtonFragment}
`;

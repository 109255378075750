import React from 'react';
import { Modal, Row, Col, Input, Button, ModalHeader, ModalBody } from "reactstrap";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import ToastHelper from '../../helpers/ToastHelper';
import NumberFormat from 'react-number-format';
import { GET_USER_WALLETS } from '../../graphql/query/GET_USER_WALLETS';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import _ from 'lodash';
import { CREATE_ONE_CREDITIN } from '../../graphql/mutation/CREATE_ONE_CREDITIN';
import { PROCESS_ONE_CREDITIN } from '../../graphql/mutation';
import { GET_GAME_ACCOUNTS } from '../../graphql/query/GET_GAME_ACCOUNTS';

function CreditIn(props) {

    const { t, modal, toggleModal, player, onDone } = props;
    const [wallet, setWallet] = React.useState(null)
    const [processing, setProcessing] = React.useState(false)
    const [gameAccount, setGameAccount] = React.useState(null)

    const { data: gameAccounts } = useQuery(GET_GAME_ACCOUNTS, {
        variables: {
            sorting: {
                field: "createdAt",
                direction: "DESC"
            },
            filter: {
                playerId: { eq: player.id }
            },
        },
        notifyOnNetworkStatusChange: true
    })

    const [creditIn, { loading: loadingCreditIn }] = useMutation(CREATE_ONE_CREDITIN)
    const [processCreditIn, { loading: loadingProcessCreditIn }] = useMutation(PROCESS_ONE_CREDITIN)

    const [getWallet, { data: walletData }] = useLazyQuery(GET_USER_WALLETS, {
        variables: {
            filter: {
                user: { id: { eq: player.id } },
                walletType: { eq: "Credit" },
                name: { eq: "Main" }

            },
        },
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {
        if (walletData) {
            if (walletData.wallets.edges.length > 0) {
                setWallet(walletData.wallets.edges[0].node)
            }
        }
    }, [walletData])

    React.useEffect(() => {
        if (modal) {
            getWallet()
        }
    }, [modal]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (gameAccounts) {
            setGameAccount(gameAccounts.gameAccounts.edges[0].node)
        }
    }, [gameAccounts])

    return (
        <React.Fragment>
            <Modal className="banker-workbench" isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="credit-in-modal" tabIndex="-1" toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>
                    {t('Credit In')}
                </ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={async (event, values) => {
                        if (processing) {
                            return
                        }

                        setProcessing(true)
                        let result = await creditIn({
                            variables: {
                                input: {
                                    creditIn: {
                                        quantity: parseFloat(values.transferAmount),
                                        platformId: gameAccount.kioskAccount.platformId,
                                        playerId: player.id,
                                        kioskAccountId: gameAccount.kioskAccountId,
                                        gameAccountId: gameAccount.id,
                                        fromWalletId: values.wallet
                                    }
                                }
                            }
                        }).catch(e => {
                            ToastHelper.toggleToast({
                                toastType: "error",
                                message: e,
                                title: "",
                                onClick: function () { }
                            })
                        })
                        if (result) {
                            await processCreditIn({
                                variables: {
                                    id: result.data.createOneCreditIn.id
                                }
                            }).catch(e => {
                                ToastHelper.toggleToast({
                                    toastType: "error",
                                    message: e,
                                    title: "",
                                    onClick: function () { }
                                })
                            })
                            if (onDone) {
                                onDone()
                            }
                        }

                        setProcessing(false)
                    }}>
                        {
                            <Row>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        value={gameAccount ? gameAccount.kioskAccount.platform.displayName : ''}
                                        label={t('To Platfrom')}
                                        disabled
                                        type="text"
                                        name="platfrom"
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        label={t('To Game Account')}
                                        name="game-account"
                                        type="select"
                                        className="custom-select"
                                        onChange={(e) => {
                                            let selectedGameAccount = _.find(gameAccounts.gameAccounts.edges, gameAcc => {
                                                return gameAcc.node.id === e.target.value
                                            })
                                            setGameAccount(selectedGameAccount.node)
                                        }}
                                        value={gameAccount ? gameAccount.id : ""}
                                    >
                                        {
                                            gameAccounts &&
                                            gameAccounts.gameAccounts.edges.length > 0 &&
                                            gameAccounts.gameAccounts.edges.map((gameAccount, index) => {
                                                return (
                                                    <option key={index} value={gameAccount.node.id}>{gameAccount.node.username}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        label={t('From Wallet')}
                                        type="select"
                                        name="wallet"
                                        className="custom-select"
                                        onChange={(e) => {
                                            let selectedWallet = _.find(walletData.wallets.edges, wal => {
                                                return wal.node.id === e.target.value
                                            })
                                            setWallet(selectedWallet.node)
                                        }}
                                        value={walletData &&
                                            walletData.wallets.edges.length > 0 &&
                                            walletData.wallets.edges[0].node.id
                                        }
                                    >
                                        {
                                            walletData &&
                                            walletData.wallets.edges.length > 0 &&
                                            walletData.wallets.edges.map((wallet, index) => {
                                                return (
                                                    <option key={index} value={wallet.node.id}>{`${wallet.node.name}(${wallet.node.walletType}): $${wallet.node.balance}`}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6}>
                                    <AvField
                                        name="transferAmount"
                                        label={`${t('Transfer Amount')}`}
                                        placeholder={t('Enter Transfer Amount')}
                                        type="text"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        tag={[Input, NumberFormat]}
                                        validate={{
                                            required: { value: true, errorMessage: "Enter Transfer Amount" },
                                            max: { value: wallet ? parseFloat(wallet.balance) : null, errorMessage: "Exceed Available Balance" },
                                            // step: { value: 5, errorMessage: `Product of ${5}` }
                                        }}
                                        helpMessage={wallet ? `Available Balance: ${parseFloat(wallet.balance).toFixed(2)}` : ''}
                                    />
                                </Col>
                            </Row>
                        }
                        <div className="submit-container">
                            <Button color="primary" type="submit">
                                {
                                    (loadingCreditIn || loadingProcessCreditIn) &&
                                    <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                }
                                {
                                    (!loadingCreditIn && !loadingProcessCreditIn) &&
                                    <div>
                                        Submit
                                </div>
                                }
                            </Button>
                        </div>
                    </AvForm>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(CreditIn));
import gql from "graphql-tag";

export const GET_PLAYER_DOWNLINES = gql`
  query ($id: String!, $paging: CursorPaging) {
    users(filter: { uplineId: { eq: $id } }, paging: $paging) {
      edges {
        node {
          id
          serialNo
          name
          username
          phoneNo
          telegramId
          telegramUsername
          lastDepositAt
          isActive
          joinedAt
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
`;

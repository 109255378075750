// @flow
import {
    CHANGE_FILTER,
    CHANGE_SORT
} from "../common/actionTypes";

const INIT_STATE = {
    filter: {
        and: [
            {
                username: { iLike: `%%` },
            }
        ]
    },
    sort: []
};

const Player = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_FILTER:
            if (action.payload.belongs === "player-list") {
                return {
                    ...state,
                    filter: action.payload.filter
                };
            } else {
                return {
                    ...state
                }
            }

        case CHANGE_SORT:
            return {
                ...state,
                sort: action.payload
            };
        default:
            return state;
    }
};

export default Player;

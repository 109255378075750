import React, { useRef } from 'react';
import { Container, Row, Col, Table, NavItem, NavLink, Card, CardBody } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery, useMutation } from 'react-apollo';
import { GET_TRANSACTIONS } from '../../graphql/query/GET_TRANSACTIONS';
import TransactionItem from './transaction-item';
import classnames from 'classnames';
import _ from 'lodash';
import { Link, useHistory } from "react-router-dom";
import { GET_TRANSACTIONS_TOTAL_COUNT } from '../../graphql/query';
import InfiniteScroll from 'react-infinite-scroll-component';
import { DELETE_TRANSACTION } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';
import SweetAlert from "react-bootstrap-sweetalert";

function Transactions(props) {

    const first = 50;
    const { t } = props;
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState('Approved')
    const [filter, setFilter] = React.useState({ transactionStatus: { eq: 'Approved' } })
    const [orderBy, setOrderBy] = React.useState("createdAt")
    const [orderDirection, setOrderDirection] = React.useState("DESC")
    const [confirmDelete, setConfirmDelete] = React.useState(false)
    const [transaction, setTransaction] = React.useState(false)
    const [showReject, setShowReject] = React.useState(false)

    const Filter = (filter) => {
        if (_.isEmpty(search)) {
            if (props.match.params.serialNo) {
                if (_.isEmpty(props.filter)) {
                    return {
                        ...filter,
                        player: { serialNo: { iLike: `%${props.match.params.serialNo ? props.match.params.serialNo : ''}%` } }
                    }
                } else {
                    return {
                        ...filter,
                        and: [
                            props.filter,
                            { player: { serialNo: { iLike: `%${props.match.params.serialNo ? props.match.params.serialNo : ''}%` } } }
                        ]
                    }
                }
            } else {
                if (_.isEmpty(props.filter)) {
                    return {
                        ...filter
                    }
                } else {
                    return {
                        ...filter,
                        and: [
                            props.filter
                        ]
                    }
                }
            }
        } else {
            if (_.isEmpty(props.filter)) {
                return {
                    ...filter,
                    and: [
                        {
                            or: [
                                { serialNo: { iLike: `%${search}%` } },
                                { player: { username: { iLike: `%${search}%` } } },
                            ]
                        },
                        { player: { serialNo: { iLike: `%${props.match.params.serialNo ? props.match.params.serialNo : ''}%` } } }
                    ]
                }
            } else {
                return {
                    ...filter,
                    and: [
                        props.filter,
                        {
                            or: [
                                { serialNo: { iLike: `%${search}%` } },
                                { player: { username: { iLike: `%${search}%` } } },
                            ]
                        },
                        { player: { serialNo: { iLike: `%${props.match.params.serialNo ? props.match.params.serialNo : ''}%` } } }
                    ]
                }
            }
        }
    }

    const [transactionFilter, setTransactionFilter] = React.useState({ ...Filter(filter), transactionType: { notIn: ["CreditIn", "CreditOut"] } })
    const [pendingFilter, setPendingFilter] = React.useState({ ...Filter(filter), transactionStatus: { eq: "Pending" } })
    const [viewedFilter, setViewedFilter] = React.useState({ ...Filter(filter), transactionStatus: { eq: "Viewed" } })

    const [deleteTransaction] = useMutation(DELETE_TRANSACTION, {
        update: (cache, { data }) => {
            const existingTransactions = cache.readQuery({
                query: GET_TRANSACTIONS, variables: {
                    filter: Filter(filter),
                    sorting: [{
                        field: orderBy,
                        direction: orderDirection
                    }]
                },
            });
            cache.writeQuery({
                query: GET_TRANSACTIONS,
                variables: {
                    filter: Filter(filter),
                    sorting: [{
                        field: orderBy,
                        direction: orderDirection
                    }]
                },
                data: {
                    transactions: {
                        pageInfo: existingTransactions.transactions.pageInfo,
                        edges: existingTransactions.transactions.edges.filter(t => t.node.id !== transaction.node.id)
                    }
                }
            })
        }
    })

    const history = useHistory();

    const { data: transactionData, fetchMore, loading, refetch } = useQuery(GET_TRANSACTIONS, {
        variables: {
            paging: {
                first: first
            },
            filter: {
                ...transactionFilter
            },
            sorting: [{
                field: orderBy,
                direction: orderDirection
            }]
        },
        fetchPolicy: 'network-only'
    })

    const { data: depositCount, refetch: refetchDeposit } = useQuery(GET_TRANSACTIONS_TOTAL_COUNT, {
        variables: {
            filter: {
                ...pendingFilter
            },
        },
        fetchPolicy: 'network-only'
    })

    const { data: viewedCount, refetch: refetchView } = useQuery(GET_TRANSACTIONS_TOTAL_COUNT, {
        variables: {
            filter: {
                ...viewedFilter
            },
        },
        fetchPolicy: 'network-only'
    })

    const DeleteTransaction = async () => {
        setConfirmDelete(false)
        await deleteTransaction({
            variables: {
                id: transaction.node.id
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then((val) => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Transaction Deleted",
                    title: "",
                    onClick: function () { }
                })
                refetchDeposit()
                refetchView()
            }
        })
    }

    React.useEffect(() => {
        if (transactionData) {
            setAfterCursor(transactionData.transactions.pageInfo.endCursor)
            setPageInfo(transactionData.transactions.pageInfo)
        }
    }, [transactionData])

    React.useEffect(() => {
        if (!_.isEmpty(props.filter)) {
            setTransactionFilter({ ...Filter(filter), transactionType: { notIn: ["CreditIn", "CreditOut"] } })
            setPendingFilter({ ...Filter(filter), transactionStatus: { eq: "Pending" } })
            setViewedFilter({ ...Filter(filter), transactionStatus: { eq: "Viewed" } })
        }
    }, [search, props.filter])

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: Filter(filter),
                    sorting: [{
                        field: orderBy,
                        direction: orderDirection,
                    }]
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.transactions.edges;
                    const pageInfo = fetchMoreResult.transactions.pageInfo;
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            transactions: {
                                __typename: previousResult.transactions.__typename,
                                edges: _.uniqBy([...previousResult.transactions.edges, ...newEdges], 'node.id'),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })

            setIsFetchMore(false)
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const UpdateFilter = (type) => {
        if (type !== null) {
            setFilter({
                // transactionType: { eq: "Deposit" },
                transactionStatus: { eq: type }
            })

            setTransactionFilter({
                ...Filter({
                    transactionStatus: { eq: type }
                }), transactionType: { notIn: ["CreditIn", "CreditOut"] }
            })
        } else {
            setFilter({
                // transactionType: { eq: "Deposit" }
            })
        }
    }

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
            } else {
                setOrderDirection('ASC')
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)
        }
    }

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content transactions-page max-height">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Transactions" breadcrumbItem="All" />
                    <div className="search-bar-container">
                        <div className="button-add-bank-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div className="align-right">
                            <div className="filter-container button-filter-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bank" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="12">
                                    <div className="overflow-auto">
                                        <ul className="nav nav-tabs nav-tabs-custom mb-2">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Approved' })} onClick={() => {
                                                    setActiveTab('Approved');
                                                    UpdateFilter('Approved')
                                                    setShowReject(false)
                                                }} >
                                                    Approved
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Rejected' })} onClick={() => {
                                                    setActiveTab('Rejected');
                                                    UpdateFilter('Rejected')
                                                    setShowReject(true)
                                                }} >
                                                    Rejected
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Pending' })} onClick={() => {
                                                    setActiveTab('Pending');
                                                    UpdateFilter('Pending')
                                                    setShowReject(false)
                                                }} >
                                                    Pending
                                                    {
                                                        depositCount &&
                                                        <span>{` (${depositCount.transactions.totalCount})`}</span>
                                                    }
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'Viewed' })} onClick={() => {
                                                    setActiveTab('Viewed');
                                                    UpdateFilter('Viewed')
                                                    setShowReject(false)
                                                }} >
                                                    Viewed
                                                    {
                                                        viewedCount &&
                                                        <span>{` (${viewedCount.transactions.totalCount})`}</span>
                                                    }
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === null })} onClick={() => {
                                                    setActiveTab(null);
                                                    UpdateFilter(null)
                                                    setShowReject(true)
                                                }} >
                                                    All
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        {
                                            <InfiniteScroll
                                                dataLength={transactionData ? transactionData.transactions.edges.length : 0} //This is important field to render the next data
                                                next={() => setIsFetchMore(true)}
                                                hasMore={pageInfo.hasNextPage}
                                                loader={
                                                    <div className="text-center my-3">
                                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                    </div>
                                                }
                                                endMessage={
                                                    <div className="text-center my-3">
                                                        <div>Yay! You have seen it all</div>
                                                    </div>
                                                }
                                            >
                                                <Table className="table mb-0">
                                                    <thead>
                                                        <tr className="font-size-12">
                                                            <Header name='Serial No' className="" type='serialNo' />
                                                            <Header name='Created At' className="" type='createdAt' />
                                                            <Header name='Transaction Type' className="" type='transactionType' />
                                                            <th>Username</th>
                                                            <th>Image</th>
                                                            <Header name='Amount' className="" type='amount' />
                                                            <Header name='Bonus' className="" type='bonus' />
                                                            <Header name='Total Amount' className="" type='totalAmount' />
                                                            <th>Movement</th>
                                                            {
                                                                showReject &&
                                                                <>
                                                                    <th>Reject Reason</th>
                                                                    <th>Reject Remark</th>
                                                                </>
                                                            }
                                                            <Header name='Updated At' className="" type='updatedAt' />
                                                            <Header name='Status' className="" type='transactionStatus' />
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-body">
                                                        {
                                                            transactionData &&
                                                            transactionData.transactions.edges.map((transaction, index) => {
                                                                return (
                                                                    <TransactionItem
                                                                        key={index}
                                                                        refetch={refetch}
                                                                        showReject={showReject}
                                                                        transaction={transaction}
                                                                        type={'All'}
                                                                        onDone={() => {
                                                                            refetch()
                                                                            refetchDeposit()
                                                                            refetchView()
                                                                        }}
                                                                        onDelete={() => {
                                                                            setConfirmDelete(true)
                                                                            setTransaction(transaction)
                                                                        }}
                                                                        onManageInWorkbench={() => {
                                                                            history.push({
                                                                                "pathname": "/cashier-workbench",
                                                                                target: "_blank",
                                                                                state: { user: { node: transaction.node.player } }
                                                                            });
                                                                        }}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                        {
                                                            transactionData &&
                                                            transactionData.transactions.edges.length === 0 &&
                                                            <tr className="text-center pt-3">
                                                                <td colSpan={12}>
                                                                    {t('No Transactions Found')}
                                                                </td>
                                                            </tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </InfiniteScroll>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className="load-more-container">
                                {
                                    (loading) &&
                                    <Col xs="12">
                                        <div className="text-center my-3">
                                            <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                        </div>
                                    </Col>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            {
                confirmDelete &&
                <SweetAlert
                    error
                    showCancel
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="primary"
                    title={'Alert'}
                    onConfirm={DeleteTransaction}
                    onCancel={() => setConfirmDelete(false)}
                >
                    {`Confirm Delete Transaction ${transaction.node.serialNo}`}
                </SweetAlert>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Transactions };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(Transactions));

// export default ContactsList;
import gql from 'graphql-tag';
import { BonusFragment } from '../fragments/bonus.fragment';

export const CREATE_ONE_BONUS = gql`
    mutation(
        $input: CreateOneBonusInput!
    ) {
        createOneBonus (input: $input) {
            ...BonusInfo
        }
    }
    ${BonusFragment}
`;
import gql from 'graphql-tag';
import { PlatformFragment } from '../fragments/platform.fragment';
import { PageFragment } from '../fragments/page.fragment';
import { KioskFragment } from '../fragments/kiosk.fragment';

export const GET_PLATFORMS = gql`
query (
    $paging: CursorPaging
    $filter: PlatformFilter
    $sorting: [PlatformSort!]
) {
    platforms (
        paging: $paging
        filter: $filter
        sorting: $sorting
    ) {
        pageInfo {
            ...Page
        }
        edges {
            node {
                ...PlatformInfo
                kioskAccounts {
                    totalCount
                    edges {
                        node {
                            ...KioskInfo
                        }
                    }
                }
            }
        }
    }
}
${PlatformFragment}
${PageFragment}
${KioskFragment}
`;
import _ from 'lodash';
import moment from "moment";
import React from "react";
import { useMutation, useQuery } from "react-apollo";
import SweetAlert from "react-bootstrap-sweetalert";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import {
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledTooltip,
} from "reactstrap";
import {
  Admin,
  Developer,
  OperatingUsers,
} from "../../assets/scss/role.constant";
import {
  APPROVE_ONE_WITHDRAWAL,
  CALCULATE_REWARD_POINT,
  CHANGE_COMPANY_BANK_ACCOUNT,
  PROCESS_ONE_CREDITIN,
  PROCESS_ONE_CREDITOUT,
  REJECT_ONE_DEPOSIT,
  REJECT_ONE_WITHDRAWAL,
  VIEW_ONE_TRANSACTION,
} from "../../graphql/mutation";
import { APPROVE_ONE_DEPOSIT } from "../../graphql/mutation/APPROVE_ONE_DEPOSIT";
import { CALCULATE_AGENT_POINT } from "../../graphql/mutation/CALCULATE_AGENT_POINT";
import { ON_HOLD_ONE_TRANSACTION } from "../../graphql/mutation/ON_HOLD_ONE_TRANSACTION";
import { UPDATE_ONE_TRANSACTION } from "../../graphql/mutation/UPDATE_ONE_TRANSACTION";
import { ME } from "../../graphql/query";
import ToastHelper from "../../helpers/ToastHelper";
import WithdrawBank from "../Banking/withdraw-bank";
import TransactionChangeBank from "./transaction-change-bank";
import TransactionReject from "./transaction-reject";

function TransactionItem(props) {
  const {
    transaction,
    onDone,
    type,
    onManageInWorkbench,
    onDelete,
    showReject,
    isAtDashboard,
  } = props;
  const [drp_link, setDrpLink] = React.useState(false);
  const [isWithdrawal, setIsWithdrawal] = React.useState(false);
  const [isReject, setIsReject] = React.useState(false);
  const [isDepost, setIsDeposit] = React.useState(false);
  const [isCalculateRp, setIsCalculateRp] = React.useState(false);
  const [isUpdateBank, setIsUpdateBank] = React.useState(false);

  const [processCreditIn, { loading: loadingProcessCreditIn }] =
    useMutation(PROCESS_ONE_CREDITIN);
  const [processCreditOut, { loading: loadingProcessCreditOut }] = useMutation(
    PROCESS_ONE_CREDITOUT
  );
  const [approveOneDeposit, { loading: loadingDeposit }] =
    useMutation(APPROVE_ONE_DEPOSIT);
  const [rejectOneDeposit, { loading: loadingRejectDeposit }] =
    useMutation(REJECT_ONE_DEPOSIT);
  const [approveOneWithdrawal, { loading: loadingApproveWithdrawal }] =
    useMutation(APPROVE_ONE_WITHDRAWAL);
  const [rejectOneWithdrawal, { loading: loadingRejectWithdrawal }] =
    useMutation(REJECT_ONE_WITHDRAWAL);
  const [viewOneTransaction, { loading: loadingViewOneTransaction }] =
    useMutation(VIEW_ONE_TRANSACTION);
  const [calculateRp] = useMutation(CALCULATE_REWARD_POINT);
  const [calculateAgentPoint] = useMutation(CALCULATE_AGENT_POINT);
  const [updateOneTransaction] = useMutation(UPDATE_ONE_TRANSACTION);
  const [updateTransactionBankAccount] = useMutation(
    CHANGE_COMPANY_BANK_ACCOUNT
  );
  const [onHoldOneTransaction] = useMutation(ON_HOLD_ONE_TRANSACTION);

  const { data: meData } = useQuery(ME);

  const ViewedTransaction = async () => {
    await viewOneTransaction({
      variables: {
        id: transaction.node.id,
      },
    }).catch((e) => {
      ToastHelper.toggleToast({
        toastType: "error",
        message: e,
        title: "",
        onClick: function () { },
      });
    });

    if (onDone) {
      onDone();
    }
  };

  const onHoldTransaction = async () => {
    await onHoldOneTransaction({
      variables: {
        id: transaction.node.id,
      },
    }).catch((e) => {
      ToastHelper.toggleToast({
        toastType: "error",
        message: e,
        title: "",
        onClick: function () { },
      });
    });

    if (onDone) {
      onDone();
    }
  };

  const ProcessTransaction = async () => {
    setIsDeposit(false);
    if (transaction.node.transactionType === "CreditIn") {
      await processCreditIn({
        variables: {
          id: transaction.node.id,
        },
      }).catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      });
    } else if (transaction.node.transactionType === "CreditOut") {
      await processCreditOut({
        variables: {
          id: transaction.node.id,
        },
      }).catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      });
    } else if (transaction.node.transactionType === "Deposit") {
      await approveOneDeposit({
        variables: {
          id: transaction.node.id,
        },
      }).catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      });
    } else if (transaction.node.transactionType === "Withdrawal") {
      await approveOneWithdrawal({
        variables: {
          id: transaction.node.id,
        },
      }).catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      });
    }

    if (onDone) {
      onDone();
    }
  };

  const ProcessTransactionDebounce = _.debounce(ProcessTransaction, 300)

  const RejectTransaction = async () => {
    if (transaction.node.transactionType === "Deposit") {
      await rejectOneDeposit({
        variables: {
          id: transaction.node.id,
        },
      }).catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      });
    } else if (transaction.node.transactionType === "Withdrawal") {
      await rejectOneWithdrawal({
        variables: {
          id: transaction.node.id,
        },
      }).catch((e) => {
        ToastHelper.toggleToast({
          toastType: "error",
          message: e,
          title: "",
          onClick: function () { },
        });
      });
    }

    if (onDone) {
      onDone();
    }
  };

  const StatusType = () => {
    if (
      transaction.node.transactionStatus === "Pending" ||
      transaction.node.kioskStatus === "Pending"
    ) {
      return "pending";
    } else if (transaction.node.transactionStatus === "Viewed") {
      return "viewed";
    } else if (
      transaction.node.transactionStatus === "Approved" ||
      transaction.node.kioskStatus === "Completed"
    ) {
      return "created";
    } else if (
      transaction.node.transactionStatus === "Processing" ||
      transaction.node.kioskStatus === "Processing"
    ) {
      return "processing";
    } else {
      return "error";
    }
  };

  const ProcessCalculateRp = () => {
    setIsCalculateRp(false);
    if (transaction.node.rewardPoint === 0) {
      calculateRp({
        variables: {
          input: {
            transactionId: transaction.node.id,
            force: true,
          },
        },
      });
    } else {
      updateOneTransaction({
        variables: {
          input: {
            update: {
              rewardPoint: 0,
            },
            id: transaction.node.id,
          },
        },
      });
    }
  };

  return (
    <React.Fragment>
      <tr className="font-size-12 transactions-page">
        <td className="one-line">
          {!transaction.node.player.telegramId && (
            <i className="bx bxl-chrome font-size-18 mr-2 chrome"></i>
          )}
          {transaction.node.player.telegramId && (
            <i className="bx bxl-telegram font-size-18 mr-2 telegram"></i>
          )}
          {transaction.node.serialNo}
        </td>
        <td>
          <div>
            {moment(transaction.node.createdAt).format("YYYY-MM-DD HH:mm:ss")}
          </div>
          {transaction.node.createdBy && (
            <div className="text-align">
              <span className="mr-1 text-primary">
                <i className="bx bx-user font-size-20" />
              </span>
              {transaction.node.createdBy}
            </div>
          )}
        </td>
        {((transaction.node.transactionType !== "Deposit" &&
          transaction.node.transactionType !== "Withdrawal" &&
          transaction.node.transactionType !== "FreeCredit" &&
          transaction.node.transactionType !== "Rebate" &&
          transaction.node.transactionType !== "Bonus") ||
          type === "All") && <td>{transaction.node.transactionType}</td>}
        <td className="one-line">
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="cursor-pointer" onClick={onManageInWorkbench}>
              <i className="bx bxs-wrench font-size-20 mr-2"></i>
            </div>
            {transaction.node.player.username}
            {transaction.node.player.tags.edges.length > 0 && (
              <>
                <div className="pl-1"></div>
                <i
                  className="bx bxs-info-circle text-danger font-size-16"
                  id={`tooltip-${transaction.node.id}`}
                />
                <UncontrolledTooltip
                  placement="right"
                  target={`tooltip-${transaction.node.id}`}
                >
                  {transaction.node.player.tags.edges.map((tag) => (
                    <li className="align-left">
                      {tag.node.tagType === "Good" && (
                        <i className="bx bx-check font-size-18 text-success mr-1" />
                      )}
                      {tag.node.tagType === "Neutral" && (
                        <i className="bx bxs-circle font-size-10 text-white ml-1 mr-2 mt-1" />
                      )}
                      {tag.node.tagType === "Bad" && (
                        <i className="bx bx-x font-size-18 text-danger mr-1" />
                      )}
                      {tag.node.name}
                    </li>
                  ))}
                </UncontrolledTooltip>
              </>
            )}
            {transaction.node.transactionType === "Withdrawal" &&
              transaction.node.player.lastDepositAt && (
                <>
                  <div className="pl-1"></div>
                  <i
                    className="bx bxs-dollar-circle text-success font-size-16"
                    id={`tooltip_withdrawal-${transaction.node.id}`}
                  />
                  <UncontrolledTooltip
                    placement="right"
                    target={`tooltip_withdrawal-${transaction.node.id}`}
                  >
                    <li className="align-left font-size-10">Last Deposit</li>
                    <li className="align-left text-success font-size-12">
                      ${transaction.node.player.lastDepositAmount}
                    </li>
                    <li className="align-left text-success font-size-12">
                      {moment(transaction.node.player.lastDepositAt).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </li>
                  </UncontrolledTooltip>
                </>
              )}
          </div>
        </td>
        {(transaction.node.transactionType === "Deposit" ||
          transaction.node.transactionType === "Withdrawal" ||
          type === "All") && (
            <td>
              {transaction.node.receiptUrl && (
                <Zoom>
                  <img alt="img" src={transaction.node.receiptUrl} height="40" />
                </Zoom>
              )}
              {!transaction.node.receiptUrl && <div>-</div>}
            </td>
          )}
        {(transaction.node.transactionType === "Deposit" || type === "All") && (
          <>
            <td>{transaction.node.amount}</td>
            <td className="align-left">
              {transaction.node.bonusAmount}
              {transaction.node.bonus && transaction.node.bonus.name && (
                <>
                  <div className="pl-1"></div>
                  <i
                    className="bx bxs-info-circle text-info font-size-16"
                    id={`bonus-${transaction.node.id}`}
                  />
                  <UncontrolledTooltip
                    placement="right"
                    target={`bonus-${transaction.node.id}`}
                  >
                    <li className="align-left">
                      {transaction.node.bonus.name}
                    </li>
                  </UncontrolledTooltip>
                </>
              )}
            </td>
          </>
        )}
        {(type === "FreeCredit" || type === "Rebate") && (
          <>
            <td>{transaction.node.amount}</td>
          </>
        )}
        <td>{transaction.node.totalAmount}</td>
        {isAtDashboard && transaction.node.transactionType === "Deposit" && (
          <td className="one-line">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div>{transaction.node.rewardPoint}</div>
              {(transaction.node.transactionStatus === "Pending" ||
                transaction.node.transactionStatus === "Viewed") &&
                transaction.node.rewardPoint === 0 && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setIsCalculateRp(true);
                    }}
                  >
                    <i className="bx bx-calculator font-size-18 ml-1 text-success"></i>
                  </div>
                )}
              {transaction.node.rewardPoint > 0 && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setIsCalculateRp(true);
                  }}
                >
                  <i className="bx bx-x font-size-18 ml-1 text-danger"></i>
                </div>
              )}
            </div>
          </td>
        )}
        {isAtDashboard && transaction.node.transactionType === "Deposit" && (
          <td className="one-line">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <div>
                {parseFloat(transaction.node.agentPoint).toFixed(2).toString()}
              </div>
              {(transaction.node.transactionStatus === "Pending" ||
                transaction.node.transactionStatus === "Viewed") &&
                transaction.node.agentPoint === 0 &&
                transaction.node.player.upline && (
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      calculateAgentPoint({
                        variables: {
                          input: {
                            transactionId: transaction.node.id,
                          },
                        },
                      });
                    }}
                  >
                    <i className="bx bx-calculator font-size-18 ml-1 text-success"></i>
                  </div>
                )}
              {transaction.node.agentPoint > 0 && (
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    updateOneTransaction({
                      variables: {
                        input: {
                          update: {
                            agentPoint: 0,
                          },
                          id: transaction.node.id,
                        },
                      },
                    });
                  }}
                >
                  <i className="bx bx-x font-size-18 ml-1 text-danger"></i>
                </div>
              )}
            </div>
            {transaction.node.player.upline && (
              <div className="text-align">
                <span className="mr-1 text-primary">
                  <i className="bx bx-user font-size-20" />
                </span>
                {transaction.node.player.upline.username}
              </div>
            )}
          </td>
        )}
        {(transaction.node.transactionType === "CreditIn" ||
          transaction.node.transactionType === "CreditOut") && (
            <>
              <td>
                {transaction.node.movement ? transaction.node.movement : "N/A"}
              </td>
              <td>
                {transaction.node.kioskRemark
                  ? transaction.node.kioskRemark
                  : "N/A"}
              </td>
            </>
          )}
        {(transaction.node.transactionType === "Deposit" ||
          transaction.node.transactionType === "Bonus" ||
          transaction.node.transactionType === "FreeCredit" ||
          transaction.node.transactionType === "Rebate") && (
            <>
              <td>
                <div className="space-between">
                  <div>
                    {transaction.node.movement
                      ? transaction.node.movement
                      : "N/A"}
                  </div>
                  {transaction.node.transactionStatus === "Approved" && (
                    <>
                      <div className="ml-2"></div>
                      <i
                        className="bx bxs-message-square-edit text-warning font-size-18 cursor-pointer"
                        onClick={() => {
                          setIsUpdateBank(true);
                        }}
                      />
                    </>
                  )}
                </div>
              </td>
            </>
          )}
        {meData &&
          !OperatingUsers.includes(meData.me.role) &&
          transaction.node.transactionType === "Withdrawal" && (
            <>
              <td>
                <div className="space-between">
                  <div>
                    {transaction.node.movement
                      ? transaction.node.movement
                      : "N/A"}
                  </div>
                  {transaction.node.transactionStatus === "Approved" && (
                    <>
                      <div className="ml-2"></div>
                      <i
                        className="bx bxs-message-square-edit text-warning font-size-18 cursor-pointer"
                        onClick={() => {
                          setIsUpdateBank(true);
                        }}
                      />
                    </>
                  )}
                </div>
              </td>
            </>
          )}
        {(type === "FreeCredit" || type === "Rebate") && (
          <>
            {!transaction.node.freeCreditType && !transaction.node.bonus && (
              <td>-</td>
            )}
            {transaction.node.freeCreditType && (
              <td>{transaction.node.freeCreditType.name}</td>
            )}
            {transaction.node.bonus && <td>{transaction.node.bonus.name}</td>}
          </>
        )}
        {showReject &&
          transaction.node.transactionType !== "CreditIn" &&
          transaction.node.transactionType !== "CreditOut" && (
            <>
              <td>
                {transaction.node.rejectReason
                  ? transaction.node.rejectReason.name
                  : ""}
              </td>
              <td>{transaction.node.rejectRemark}</td>
            </>
          )}
        {
          <td>
            <div>
              <div>
                {moment(transaction.node.updatedAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                )}
              </div>
              {transaction.node.updatedBy && (
                <div className="text-align">
                  <span className="mr-1 text-primary">
                    <i className="bx bx-user font-size-20" />
                  </span>
                  {transaction.node.updatedBy}
                </div>
              )}
            </div>
          </td>
        }
        <td>
          <div className="space-between">
            <div className="pr-2">
              <div className="status-container">
                <div className={StatusType()}>
                  {transaction.node.transactionStatus
                    ? transaction.node.transactionStatus
                    : transaction.node.kioskStatus}
                </div>
              </div>
            </div>
            <>
              {(transaction.node.kioskStatus === "Failed" ||
                transaction.node.kioskStatus === "Processing") && (
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light font-size-11 align-center"
                    onClick={ProcessTransactionDebounce}
                  >
                    {(loadingProcessCreditIn || loadingProcessCreditOut) && (
                      <>
                        <i className="fas fa-spinner fa-spin font-size-14 align-middle"></i>
                        <div className="pl-1"></div>
                      </>
                    )}
                    <div>Retry</div>
                  </button>
                )}
              {(transaction.node.transactionStatus === "Approved" ||
                transaction.node.kioskStatus === "Completed") &&
                meData &&
                meData.me.role === "Developer" && (
                  <button
                    type="button"
                    className="btn btn-danger waves-effect waves-light font-size-11 align-center"
                    onClick={onDelete}
                  >
                    {/* {
                                        (loadingDeleteTranaction) &&
                                        <>
                                            <i className="fas fa-spinner fa-spin font-size-14 align-middle"></i>
                                            <div className="pl-1"></div>
                                        </>
                                    } */}
                    <div>Delete</div>
                  </button>
                )}
              {(transaction.node.transactionStatus === "Pending" ||
                transaction.node.transactionStatus === "OnHold" ||
                transaction.node.kioskStatus === "Pending" ||
                transaction.node.transactionStatus === "Viewed") && (
                  <ButtonDropdown
                    isOpen={drp_link}
                    toggle={() => setDrpLink(!drp_link)}
                  >
                    <DropdownToggle
                      className="font-size-11 align-center"
                      caret
                      color="secondary"
                    >
                      {(loadingProcessCreditIn ||
                        loadingProcessCreditOut ||
                        loadingDeposit ||
                        loadingRejectDeposit ||
                        loadingApproveWithdrawal ||
                        loadingRejectWithdrawal ||
                        loadingViewOneTransaction) && (
                          <>
                            <i className="fas fa-spinner fa-spin font-size-14 align-middle"></i>
                            <div className="pl-1"></div>
                          </>
                        )}
                      Actions <i className="mdi mdi-chevron-down"></i>
                    </DropdownToggle>
                    <DropdownMenu right>
                      {!(
                        transaction.node.transactionStatus === "OnHold" ||
                        transaction.node.transactionType === "CreditIn" ||
                        transaction.node.transactionType === "CreditOut" ||
                        transaction.node.transactionStatus === "Viewed"
                      ) && (
                          <DropdownItem
                            className="font-size-11"
                            onClick={ViewedTransaction}
                          >
                            Mark Viewed
                          </DropdownItem>
                        )}
                      {meData &&
                        (Admin.includes(meData.me.role) ||
                          Developer.includes(meData.me.role)) &&
                        !(
                          transaction.node.transactionType === "CreditIn" ||
                          transaction.node.transactionType === "CreditOut" ||
                          transaction.node.transactionStatus === "OnHold"
                        ) && (
                          <DropdownItem
                            className="font-size-11"
                            onClick={onHoldTransaction}
                          >
                            On Hold
                          </DropdownItem>
                        )}
                      <DropdownItem
                        className="font-size-11"
                        onClick={() => {
                          if (transaction.node.transactionType === "Withdrawal") {
                            setIsWithdrawal(true);
                          } else {
                            setIsDeposit(true);
                          }
                        }}
                      >
                        Approve
                      </DropdownItem>
                      <DropdownItem
                        className="font-size-11"
                        onClick={() => setIsReject(true)}
                      >
                        Reject
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                )}
            </>
          </div>
        </td>
      </tr>
      {transaction && (
        <WithdrawBank
          modal={isWithdrawal}
          transaction={transaction.node}
          toggleModal={() => setIsWithdrawal(!isWithdrawal)}
          onDone={async (values, receipt) => {
            await updateOneTransaction({
              variables: {
                input: {
                  update: {
                    companyBankAccountId: values.bank,
                    amount: parseFloat(values.amount),
                    totalAmount: parseFloat(values.amount),
                    receiptUrl: receipt,
                  },
                  id: transaction.node.id,
                },
              },
            })
              .then((val) => {
                if (val) {
                  ToastHelper.toggleToast({
                    message: "Update Succesfully!",
                    title: "",
                    onClick: function () { },
                  });
                }
              })
              .catch((e) => {
                ToastHelper.toggleToast({
                  toastType: "error",
                  message: e,
                  title: "",
                  onClick: function () { },
                });
              });
            await ProcessTransactionDebounce();
            setIsWithdrawal(!isWithdrawal);
          }}
        />
      )}
      {transaction && isReject && (
        <TransactionReject
          modal={isReject}
          transaction={transaction.node}
          toggleModal={() => setIsReject(!isReject)}
          onDone={async (values) => {
            await updateOneTransaction({
              variables: {
                input: {
                  update: {
                    rejectRemark: values.remark,
                    rejectReasonId: values.reason,
                  },
                  id: transaction.node.id,
                },
              },
            })
              .then((val) => {
                if (val) {
                  ToastHelper.toggleToast({
                    message: "Update Succesfully!",
                    title: "",
                    onClick: function () { },
                  });
                }
              })
              .catch((e) => {
                ToastHelper.toggleToast({
                  toastType: "error",
                  message: e,
                  title: "",
                  onClick: function () { },
                });
              });
            await RejectTransaction();
            setIsReject(!isReject);
          }}
        />
      )}
      {transaction && isUpdateBank && (
        <TransactionChangeBank
          modal={isUpdateBank}
          transaction={transaction}
          toggleModal={() => {
            setIsUpdateBank(false);
          }}
          onDone={async (values) => {
            setIsUpdateBank(false);
            await updateTransactionBankAccount({
              variables: {
                companyBankAccountId: values.bank,
                id: transaction.node.id,
              },
            })
              .then((val) => {
                if (val) {
                  ToastHelper.toggleToast({
                    message: "Update Succesfully!",
                    title: "",
                    onClick: function () { },
                  });
                }
              })
              .catch((e) => {
                ToastHelper.toggleToast({
                  toastType: "error",
                  message: e,
                  title: "",
                  onClick: function () { },
                });
              });
          }}
        />
      )}
      {isDepost && (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={"Confirm Approve Transaction?"}
          onConfirm={ProcessTransactionDebounce}
          onCancel={() => setIsDeposit(false)}
        />
      )}
      {isCalculateRp && (
        <SweetAlert
          warning
          showCancel
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="primary"
          title={
            transaction.node.rewardPoint === 0
              ? "Confirm Recalculate RP?"
              : "Confirm Remove RP?"
          }
          onConfirm={ProcessCalculateRp}
          onCancel={() => setIsCalculateRp(false)}
        />
      )}
    </React.Fragment>
  );
}

export default TransactionItem;

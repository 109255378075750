import React, { useRef } from 'react';
import { Row, Col, Table, UncontrolledPopover } from "reactstrap";
import { Link } from "react-router-dom";
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import CampaignInfoItem from './campaign-info-item';
import { useMutation, useQuery, useLazyQuery } from 'react-apollo';
import { GENERATE_RAW_LIST, GENERATE_AVAILABLE_LIST, GENERATE_WAITING_LIST, REMOVE_FROM_WAITING_LIST } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GET_MARKETING_PARTICIPANTS } from '../../graphql/query/GET_MARKETING_PARTICIPANTS';
import { REMOVE_FROM_SENT_LIST } from '../../graphql/mutation/REMOVE_FROM_SENT_LIST';
import { REMOVE_FROM_FAILED_LIST } from '../../graphql/mutation/REMOVE_FROM_FAILED_LIST';
import { REMOVE_FROM_AVAILABLE_LIST } from '../../graphql/mutation/REMOVE_FROM_AVAILABLE_LIST';
import { REMOVE_FROM_RAW_LIST } from '../../graphql/mutation/REMOVE_FROM_RAW_LIST';
import { CSVLink } from "react-csv";
import { GET_MARKETING_PARTICIPANTS_COUNT } from '../../graphql/query/GET_MARKETING_PARTICIPANTS_COUNT';

function CampaignInfoList(props) {

    const { tab, campaignId, campaign, search } = props;

    const first = 50

    const csvLink = useRef(null);


    const Sorting = () => {
        if (tab === 'Sent' || tab === 'Failed') {
            return {
                field: 'sentAt',
                direction: 'DESC'
            }
        } else {
            return {
                field: 'updatedAt',
                direction: 'DESC'
            }
        }
    }

    const [selectAll, setSelectAll] = React.useState(false)
    const [selectedData, setSelectedData] = React.useState([])
    const [isMember, setIsMember] = React.useState(false)
    const [isNotMember, setIsNotMember] = React.useState(false)
    const [isActive, setIsActive] = React.useState(false)
    const [isNotActive, setIsNotActive] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [pageInfo, setPageInfo] = React.useState(false)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [filter, setFilter] = React.useState({})
    const [readyDownload, setReadyDownload] = React.useState(false)
    const [waitingListReady, setWaitingListReady] = React.useState(false)

    const [generateRawList, { loading: loadingRawList }] = useMutation(GENERATE_RAW_LIST)
    const [generateAvailableList, { loading: loadingAvailableList }] = useMutation(GENERATE_AVAILABLE_LIST)
    const [generateWaitingList, { loading: loadingWaitingList }] = useMutation(GENERATE_WAITING_LIST)
    const [removeFromRawList, { loading: loadingRemoveFromRawList }] = useMutation(REMOVE_FROM_RAW_LIST)
    const [removeFromAvailableList, { loading: loadingRemoveFromAvailableList }] = useMutation(REMOVE_FROM_AVAILABLE_LIST)
    const [removeFromWaitingList, { loading: loadingRemoveWaitingList }] = useMutation(REMOVE_FROM_WAITING_LIST)
    const [removeFromSentList, { loading: loadingRemoveSentList }] = useMutation(REMOVE_FROM_SENT_LIST)
    const [removeFromFailedList, { loading: loadingRemoveFailedList }] = useMutation(REMOVE_FROM_FAILED_LIST)

    const { data, refetch, fetchMore } = useQuery(GET_MARKETING_PARTICIPANTS, {
        variables: {
            paging: {
                first: first,
                after: null
            },
            filter: {
                marketingCampaignId: { eq: campaignId },
                status: { eq: tab },
                ...filter
            },
            sorting: Sorting()
        },
        fetchPolicy: 'network-only'
    })

    const [getCSVData, { data: csvData }] = useLazyQuery(GET_MARKETING_PARTICIPANTS, {
        fetchPolicy: 'network-only'
    })

    const [generateWaiting, { data: waitingData }] = useLazyQuery(GET_MARKETING_PARTICIPANTS_COUNT, {
        variables: {
            filter: {
                marketingCampaignId: { eq: campaignId },
                status: { eq: tab },
                ...filter
            }
        },
        fetchPolicy: 'network-only'
    })

    React.useEffect(() => {
        if (waitingData) {
            if (waitingListReady) {
                GenerateWaitingList(waitingData)
            }
            setWaitingListReady(false)
        }
    }, [waitingData])

    React.useEffect(() => {
        setSelectAll(false)
        setSelectedData([])
        setIsMember(false)
        setIsNotMember(false)
        setIsActive(false)
        setIsNotActive(false)
    }, [tab])

    React.useEffect(() => {
        if (data) {
            setAfterCursor(data.marketingCampaignParticipants.pageInfo.endCursor)
            setPageInfo(data.marketingCampaignParticipants.pageInfo)
        }
    }, [data])

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: {
                        marketingCampaignId: { eq: campaignId },
                        status: { eq: tab },
                        ...filter
                    },
                    sorting: Sorting()
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.marketingCampaignParticipants.edges;
                    const pageInfo = fetchMoreResult.marketingCampaignParticipants.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            marketingCampaignParticipants: {
                                __typename: previousResult.marketingCampaignParticipants.__typename,
                                edges: _.uniq([...previousResult.marketingCampaignParticipants.edges, ...newEdges]),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        let filter = {}

        if (isMember) {
            filter["firstDepositAt"] = { isNot: null }
        }

        if (isNotMember) {
            filter["firstDepositAt"] = { is: null }
        }

        if (isActive) {
            filter["lastDepositAt"] = { gt: moment().subtract(14, 'day').format('YYYY-MM-DD') }
        }

        if (isNotActive) {
            if (!_.isEmpty(filter["lastDepositAt"])) {
                filter["or"] = [{ "lastDepositAt": { gt: moment().subtract(14, 'day').format('YYYY-MM-DD') } }, { "lastDepositAt": { lt: moment().subtract(28, 'day').format('YYYY-MM-DD') } }]
                delete filter["lastDepositAt"]
            } else {
                filter["lastDepositAt"] = { lt: moment().subtract(28, 'day').format('YYYY-MM-DD') }
            }
        }

        if (!_.isEmpty(search)) {
            filter = {
                ...filter,
                or: [
                    { serialNo: { iLike: `%${search}%` } },
                    { username: { iLike: `%${search}%` } },
                    { telegramId: { iLike: `%${search}%` } },
                    { phoneNo: { iLike: `%${search}%` } }
                ]
            }
        }

        setFilter(!_.isEmpty(filter) ? { participant: filter } : {})
        setSelectedData([])
        setSelectAll(false)
        // getCount()
    }, [isMember, isNotMember, isActive, isNotActive, search]) // eslint-disable-line react-hooks/exhaustive-deps

    const GenerateRawList = () => {
        generateRawList({
            variables: {
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Generate Succesfully!",
                    title: "",
                    onClick: function () { }
                })

                refetch()
            }
        })
    }

    const GenerateAvailableList = () => {
        generateAvailableList({
            variables: {
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Generate Succesfully!",
                    title: "",
                    onClick: function () { }
                })

                refetch()
                // refetchCountData()
            }
        })
    }

    const FetchWaitingList = () => {
        setWaitingListReady(true)
        if (_.isEmpty(filter)) {
            GenerateWaitingList()
        } else {
            generateWaiting()
        }
    }

    const GenerateWaitingList = (data) => {
        let input = {
            all: (selectAll || selectedData.length === 0) && _.isEmpty(filter),
            participantIds: _.isEmpty(filter) ? selectedData : ((selectAll || selectedData.length === 0) ? data ? _.map(data.marketingCampaignParticipants.edges, 'node.participantId') : [] : selectedData)
        }

        if ((selectAll || selectedData.length === 0) && _.isEmpty(filter)) {
            delete input["participantIds"]
        }

        generateWaitingList({
            variables: {
                input,
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Generate Succesfully!",
                    title: "",
                    onClick: function () { }
                })
                setSelectedData([])
                setSelectAll(false)
                refetch()
                // refetchCountData()
            }
        })
    }

    const RemoveFromWaitingList = () => {
        let input = {
            all: selectAll || selectedData.length === 0,
            participantIds: selectedData
        }

        if (selectAll || selectedData.length === 0) {
            delete input["participantIds"]
        }

        removeFromWaitingList({
            variables: {
                input,
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Remove Succesfully!",
                    title: "",
                    onClick: function () { }
                })
                setSelectedData([])
                refetch()
                // refetchCountData()
            }
        })
    }

    const RemoveFromRawList = () => {

        removeFromRawList({
            variables: {
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Remove Succesfully!",
                    title: "",
                    onClick: function () { }
                })
                setSelectedData([])
                refetch()
                // refetchCountData()
            }
        })
    }

    const RemoveFromAvailableList = () => {
        let input = {
            all: selectAll || selectedData.length === 0,
            participantIds: selectedData
        }

        if (selectAll || selectedData.length === 0) {
            delete input["participantIds"]
        }

        removeFromAvailableList({
            variables: {
                input,
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Generate Succesfully!",
                    title: "",
                    onClick: function () { }
                })
                setSelectedData([])
                refetch()
                // refetchCountData()
            }
        })
    }

    const RemoveFromFailedList = () => {
        let input = {
            all: selectAll || selectedData.length === 0,
            participantIds: selectedData
        }

        if (selectAll || selectedData.length === 0) {
            delete input["participantIds"]
        }

        removeFromFailedList({
            variables: {
                input,
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Remove Succesfully!",
                    title: "",
                    onClick: function () { }
                })
                setSelectedData([])
                refetch()
                // refetchCountData()
            }
        })
    }

    const RemoveFromSentList = () => {
        let input = {
            all: selectAll || selectedData.length === 0,
            participantIds: selectedData
        }

        if (selectAll || selectedData.length === 0) {
            delete input["participantIds"]
        }

        removeFromSentList({
            variables: {
                input,
                marketingCampaignId: campaignId
            }
        }).catch(e => {
            ToastHelper.toggleToast({
                toastType: "error",
                message: e,
                title: "",
                onClick: function () { }
            })
        }).then(val => {
            if (val) {
                ToastHelper.toggleToast({
                    message: "Generate Succesfully!",
                    title: "",
                    onClick: function () { }
                })
                setSelectedData([])
                refetch()
                // refetchCountData()
            }
        })
    }

    const ResendList = () => {
        if (tab === 'Sent') {
            RemoveFromSentList()
        } else if (tab === 'Failed') {
            RemoveFromFailedList()
        }
    }

    const GetNextDate = (min) => {
        const start = moment();

        if (min === 60) {
            return moment(start).add(60, 'minutes').format(`HH:00`);
        } else if ((min - start.minute()) > 0) {
            return moment(start).format(`HH:${min}`);
        }
        return moment(start).add(60, 'minutes').format(`HH:${min}`);

    }

    const DownloadToCSV = () => {
        getCSVData({
            variables: {
                filter: {
                    marketingCampaignId: { eq: campaignId },
                    status: { eq: tab },
                    ...filter
                },
                sorting: Sorting()
            }
        })
        setReadyDownload(true)
    }

    React.useEffect(() => {
        if (csvData) {
            if (csvLink && readyDownload) {
                csvLink.current.link.click()
                setReadyDownload(false)
            }
        }
    }, [csvData])

    const CSVName = () => {
        var fileName = `${_.toLower(tab)}-list.csv`

        if (isMember) {
            fileName = 'member.csv'
            if (isActive && isNotActive) {
                fileName = 'member-active-and-not-active.csv'
            } else if (isActive) {
                fileName = 'member-active-in-14-days.csv'
            } else if (isNotActive) {
                fileName = 'member-not-active-in-28-days.csv'
            }
        } else if (isActive && isNotActive) {
            fileName = 'active-and-not-active.csv'
        } else if (isActive) {
            fileName = 'active-in-14-days.csv'
        } else if (isNotActive) {
            fileName = 'not-active-in-28-days.csv'
        } else if (isNotMember) {
            fileName = 'non-member.csv'
        }

        return fileName
    }

    const CSVDownload = () => {
        return (
            <>
                <div className="btn btn-primary btn-sm mb-3 mr-2" onClick={DownloadToCSV}>
                    Download (CSV)
                </div>
                <CSVLink
                    ref={csvLink}
                    filename={CSVName()}
                    className="hidden"
                    data={csvData ? _.map(csvData.marketingCampaignParticipants.edges, 'node').map(item => {
                        return {
                            ...item,
                            ...{
                                updatedAt: moment(item.updatedAt).format('YYYY-MM-DD HH:mm:ss'),
                                ...item.participant,
                                participant: {
                                    telegramId: !_.isEmpty(item.participant.telegramId) ? item.participant.telegramId : '-',
                                    phoneNo: !_.isEmpty(item.participant.phoneNo) ? item.participant.phoneNo : '-',
                                    sentAt: moment(item.participant.sentAt).format('YYYY-MM-DD HH:mm:ss'),
                                    firstDepositAt: item.participant.firstDepositAt ? moment(item.participant.firstDepositAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
                                    lastDepositAt: item.participant.lastDepositAt ? moment(item.participant.lastDepositAt).format('YYYY-MM-DD HH:mm:ss') : 'N/A',
                                    activeOver7Days: item.participant.lastDepositAt ? moment().diff(moment(item.participant.lastDepositAt), 'days') <= 7 ? '✅' : '❌' : '❌'
                                }

                            }
                        }
                    }) : []}
                    headers={[
                        { label: 'Player', key: 'name' },
                        { label: 'Updated At', key: 'updatedAt' },
                        { label: 'Telegram Id', key: 'participant.telegramId' },
                        { label: 'Phone No', key: 'participant.phoneNo' },
                        { label: 'Language', key: 'language' },
                        { label: 'Remark', key: 'remark' },
                        { label: 'Sent At', key: 'participant.sentAt' },
                        { label: 'First Deposit At', key: 'participant.firstDepositAt' },
                        { label: 'Last Deposit At', key: 'participant.lastDepositAt' },
                        { label: 'Active Over 7 Days', key: 'participant.activeOver7Days' }
                    ]}
                >
                </CSVLink>
            </>
        )
    }

    return (
        <React.Fragment>
            {
                data &&
                <div className="">
                    <Row>
                        <Col sm="12">
                            <div className="align-center">
                                {
                                    (tab === 'Available' || tab === 'Waiting') &&
                                    <div className="custom-control custom-checkbox mb-3 align-left width-33">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox_all`} onChange={() => {
                                            if (!selectAll) {
                                                if (data) {
                                                    setSelectedData(_.map(data.marketingCampaignParticipants.edges, 'node.participantId'))
                                                }
                                            } else {
                                                setSelectedData([])
                                            }
                                            setSelectAll(!selectAll)
                                        }} checked={selectAll} />
                                        <label className="custom-control-label" htmlFor={`checkbox_all`} >Select All {tab === 'Waiting' && `(${data && data.marketingCampaignParticipants.totalCount})`}</label>
                                        {
                                            tab === 'Waiting' &&
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm ml-2"
                                                onClick={RemoveFromWaitingList}
                                            >
                                                {
                                                    loadingRemoveWaitingList &&
                                                    <i className="bx bx-hourglass bx-spin mr-2"></i>
                                                }
                                                {selectedData.length > 0 ? `Remove Selected (${selectedData.length}) from Waiting List` : ' Remove All from Waiting List'}
                                            </button>
                                        }
                                        {
                                            tab === 'Available' &&
                                            <>
                                                <button
                                                    type="button"
                                                    className="btn btn-success btn-sm ml-2"
                                                    onClick={FetchWaitingList}
                                                >
                                                    {
                                                        loadingWaitingList &&
                                                        <i className="bx bx-hourglass bx-spin mr-2"></i>
                                                    }
                                                    {
                                                        data &&
                                                            selectedData.length > 0 ? `Push ${_.isEmpty(filter) ? 'Selected' : 'Filtered'} (${selectedData.length}) to Waiting list` : `Push All ${_.isEmpty(filter) ? '' : `Filtered`} ${!_.isEmpty(data) ? `(${data.marketingCampaignParticipants.totalCount})` : ''} to Waiting list`
                                                    }
                                                </button>
                                                <button
                                                    type="button"
                                                    className="btn btn-primary btn-sm ml-2"
                                                    id="UncontrolledPopover"
                                                >
                                                    {_.filter([isMember, isNotMember, isActive, isNotActive], o => o === true).length > 0 ? `Filter (${_.filter([isMember, isNotMember, isActive, isNotActive], o => o === true).length})` : 'Filter'}
                                                </button>
                                                <UncontrolledPopover placement="bottom" target="UncontrolledPopover" trigger="legacy">
                                                    <div className="m-2">
                                                        <div className="m-1">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id={'member'} onChange={() => {
                                                                    setIsMember(!isMember)
                                                                    setIsNotMember(false)
                                                                }} checked={isMember} />
                                                                <label className="custom-control-label" htmlFor={'member'}>Member (Ever Deposit)</label>
                                                            </div>
                                                        </div>
                                                        <div className="m-1">
                                                            <div className="custom-control custom-checkbox">
                                                                <input type="checkbox" className="custom-control-input" id={'non-member'} onChange={() => {
                                                                    setIsNotMember(!isNotMember)
                                                                    setIsMember(false)
                                                                    setIsActive(false)
                                                                    setIsNotActive(false)
                                                                }} checked={isNotMember} />
                                                                <label className="custom-control-label" htmlFor={'non-member'}>Non Member (Never Deposit)</label>
                                                            </div>
                                                        </div>
                                                        <div className="m-1">
                                                            <div className="custom-control custom-checkbox">
                                                                <input disabled={isNotMember} type="checkbox" className="custom-control-input" id={'active-member'} onChange={() => setIsActive(!isActive)} checked={isActive} />
                                                                <label className={`custom-control-label ${isNotMember ? 'disabled' : ''}`} htmlFor={'active-member'}>Active in 14 days</label>
                                                            </div>
                                                        </div>
                                                        <div className="m-1">
                                                            <div className="custom-control custom-checkbox">
                                                                <input disabled={isNotMember} type="checkbox" className="custom-control-input" id={'non-active-member'} onChange={() => setIsNotActive(!isNotActive)} checked={isNotActive} />
                                                                <label className={`custom-control-label ${isNotMember ? 'disabled' : ''}`} htmlFor={'non-active-member'}>Not Active over 28 days</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </UncontrolledPopover>
                                                <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm ml-2"
                                                    onClick={RemoveFromAvailableList}
                                                >
                                                    {
                                                        loadingRemoveFromAvailableList &&
                                                        <i className="bx bx-hourglass bx-spin mr-2"></i>
                                                    }
                                                    {selectedData.length > 0 ? `Remove Selected (${selectedData.length}) from Available List` : ' Remove All from Available List'}
                                                </button>
                                            </>
                                        }
                                    </div>
                                }
                                {
                                    tab === 'Raw' &&
                                    <div className="mb-3">
                                        <button
                                            type="button"
                                            className="btn btn-danger btn-sm"
                                            onClick={RemoveFromRawList}
                                        >
                                            {
                                                loadingRemoveFromRawList &&
                                                <i className="bx bx-hourglass bx-spin mr-2"></i>
                                            }
                                            Remove All from Raw List
                                        </button>
                                    </div>
                                }
                                {
                                    (tab === 'Sent' || tab === 'Failed') &&
                                    <div className="custom-control custom-checkbox mb-3 align-left width-33">
                                        <input type="checkbox" className="custom-control-input" id={`checkbox_all`} onChange={() => {
                                            if (!selectAll) {
                                                if (data) {
                                                    setSelectedData(_.map(data.marketingCampaignParticipants.edges, 'node.participantId'))
                                                }
                                            } else {
                                                setSelectedData([])
                                            }
                                            setSelectAll(!selectAll)
                                        }} checked={selectAll} />
                                        <label className="custom-control-label" htmlFor={`checkbox_all`} >Select All {`(${data && data.marketingCampaignParticipants.totalCount})`}</label>
                                        {
                                            <button
                                                type="button"
                                                className="btn btn-danger btn-sm ml-2"
                                                onClick={ResendList}
                                            >
                                                {
                                                    (loadingRemoveSentList || loadingRemoveFailedList) &&
                                                    <i className="bx bx-hourglass bx-spin mr-2"></i>
                                                }
                                                {selectedData.length > 0 ? `Resend Selected (${selectedData.length})` : ' Resend All'}
                                            </button>
                                        }
                                    </div>
                                }
                                {
                                    (tab !== 'Available' && tab !== 'Waiting' && tab !== 'Sent') &&
                                    <div className="width-33"></div>
                                }
                                {
                                    tab === 'Raw' &&
                                    <>
                                        {
                                            campaign &&
                                            campaign.marketingCampaign.autoMode &&
                                            <div className="ml-2 mb-3 width-33">{`Next auto generating will be started at `}{<span className="font-weight-bold ml-1">{GetNextDate(15)}</span>}</div>
                                        }
                                        {
                                            campaign &&
                                            !campaign.marketingCampaign.autoMode &&
                                            <div className="width-33"></div>
                                        }
                                        <div className="align-left width-33 flex-end">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm w-md mb-3 mr-2"
                                                onClick={GenerateRawList}
                                            >
                                                {
                                                    loadingRawList &&
                                                    <i className="bx bx-hourglass bx-spin mr-2"></i>
                                                }
                                                Generate Raw List
                                            </button>
                                            <CSVDownload />
                                        </div>
                                    </>
                                }
                                {
                                    tab === 'Available' &&
                                    <>
                                        {
                                            campaign &&
                                            campaign.marketingCampaign.autoMode &&
                                            <div className="mb-3 width-33">{`Next auto generating will be started at `}{<span className="font-weight-bold ml-1">{GetNextDate(30)}</span>}</div>
                                        }
                                        {
                                            campaign &&
                                            !campaign.marketingCampaign.autoMode &&
                                            <div className="width-33"></div>
                                        }
                                        <div className="align-left width-33 flex-end">
                                            <button
                                                type="button"
                                                className="btn btn-primary btn-sm mb-3 mr-2"
                                                onClick={GenerateAvailableList}
                                            >
                                                {
                                                    loadingAvailableList &&
                                                    <i className="bx bx-hourglass bx-spin mr-2"></i>
                                                }
                                                Generate Available List
                                            </button>
                                            <CSVDownload />
                                        </div>
                                    </>
                                }
                                {
                                    tab === "Waiting" &&
                                    <>
                                        <div className="mb-3 text-center width-33">
                                            {
                                                campaign &&
                                                campaign.marketingCampaign.autoMode &&
                                                <div>{`Next auto generating will be started at `}{<span className="font-weight-bold ml-1">{GetNextDate(45)}</span>}</div>
                                            }
                                        </div>
                                        <div className="width-33"></div>
                                        <div className="align-left width-33 flex-end">
                                            <CSVDownload />
                                        </div>
                                    </>
                                }
                                {
                                    tab === "Sent" &&
                                    <>
                                        <div className="mb-3 text-center width-33">
                                            <div>{`Next auto sending will be started at `}{<span className="font-weight-bold ml-1">{GetNextDate(60)}</span>}</div>
                                        </div>
                                        <div className="width-33"></div>
                                        <div className="align-left width-33 flex-end">
                                            <CSVDownload />
                                        </div>
                                    </>
                                }
                                {
                                    tab === 'Failed' &&
                                    <>
                                        <div className="width-33"></div>
                                        <div className="width-33"></div>
                                        <div className="align-left width-33 flex-end">
                                            <CSVDownload />
                                        </div>
                                    </>

                                }
                            </div>
                            <InfiniteScroll
                                dataLength={data.marketingCampaignParticipants.edges.length} //This is important field to render the next data
                                next={() => setIsFetchMore(true)}
                                hasMore={pageInfo.hasNextPage}
                                loader={
                                    <div className="text-center my-3">
                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                    </div>
                                }
                                endMessage={
                                    <div className="text-center my-3">
                                        <div>Yay! You have seen it all</div>
                                    </div>
                                }
                            >
                                <div className="campaign-table">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th style={{ width: '10%' }}>Player</th>
                                                <th style={{ width: '10%' }}>Updated At</th>
                                                {
                                                    campaign &&
                                                    campaign.marketingCampaign.marketingCampaignMedium === 'Telegram' &&
                                                    <th style={{ width: '10%' }}>Telegram Id</th>
                                                }
                                                {
                                                    campaign &&
                                                    campaign.marketingCampaign.marketingCampaignMedium === 'SMS' &&
                                                    <th style={{ width: '10%' }}>Phone No</th>
                                                }
                                                <th style={{ width: '10%' }}>Language</th>
                                                {
                                                    tab !== 'Sent' &&
                                                    <th style={{ width: '15%' }}>Remark</th>
                                                }
                                                {
                                                    tab === 'Sent' &&
                                                    <th style={{ width: '10%' }}>Sent At</th>
                                                }
                                                <th style={{ width: '10%' }}>First Deposit At</th>
                                                <th style={{ width: '10%' }}>Last Deposit At</th>
                                                <th style={{ width: '10%' }}>Active Over 7 Days</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.marketingCampaignParticipants.edges.length > 0 &&
                                                data.marketingCampaignParticipants.edges.map((data, index) => {
                                                    return (
                                                        <CampaignInfoItem campaign={campaign} tab={tab} key={index} index={index} data={data} checked={selectedData.includes(data.node.participantId)} onCheck={(checked) => {
                                                            if (checked) {
                                                                setSelectedData(selectedData => [...selectedData, data.node.participantId]);
                                                            } else {
                                                                if (selectedData.includes(data.node.participantId)) {
                                                                    const newSelection = selectedData.filter((select) => {
                                                                        return select !== data.node.participantId;
                                                                    });
                                                                    setSelectedData(newSelection);
                                                                    setSelectAll(false)
                                                                }
                                                            }
                                                        }} />
                                                    )
                                                })
                                            }
                                            {
                                                data.marketingCampaignParticipants.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-user pt-0">No User Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </div>
            }
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(CampaignInfoList));
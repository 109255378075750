
import React from 'react';
import { useMutation, useQuery } from 'react-apollo';
import Switch from "react-switch";
import { UPDATE_ONE_TELEGRAM_MENU } from '../../../graphql/mutation/UPDATE_ONE_TELEGRAM_MENU';
import { ME } from '../../../graphql/query';
import ToastHelper from '../../../helpers/ToastHelper';


function TelegramPageItem(props) {

    const { onEdit, telegramMenu, onDelete } = props;
    const { data: meData } = useQuery(ME)
    const [updateTelegramMenu] = useMutation(UPDATE_ONE_TELEGRAM_MENU);

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
                {props.text}
            </div>
        );
    };

    return <React.Fragment>
        <tr className="font-size-12 telegram-page">
            <td>
                <div className="cursor-pointer " onClick={() => {
                    onEdit(telegramMenu)
                }}>
                    {telegramMenu.node.name}
                </div>
            </td>
            <td>
                <div className="cursor-pointer " onClick={() => {
                    onEdit(telegramMenu)
                }}>
                    {telegramMenu.node.description}
                </div>
            </td>
            <td>
                <div className="cursor-pointer " onClick={() => {
                    onEdit(telegramMenu)
                }}>
                    {telegramMenu.node.telegramMenuButtons.totalCount}
                </div>
            </td>
            <td>
                <Switch
                    uncheckedIcon={<Offsymbol text="NO" />}
                    checkedIcon={<OnSymbol text="YES" />}
                    id="is-active"
                    onColor="#34c38f"
                    offColor="#f46a6a"
                    onChange={() => {
                        updateTelegramMenu({
                            variables: {
                                input: {
                                    update: {
                                        isActive: !telegramMenu.node.isActive
                                    },
                                    id: telegramMenu.node.id
                                }
                            }
                        }).catch(e => {
                            ToastHelper.toggleToast({
                                toastType: "error",
                                message: e,
                                title: "",
                                onClick: function () { }
                            })
                        }).then(val => {
                            if (val) {
                                ToastHelper.toggleToast({
                                    message: "Update Succesfully!",
                                    title: "",
                                    onClick: function () { }
                                })
                            }
                        })

                    }}
                    checked={telegramMenu.node.isActive}
                />
            </td>
            {
                meData.me.role === "Developer" &&
                <td>
                    <button type="button" className="btn btn-danger waves-effect waves-light font-size-11 align-center ml-1" onClick={() => {
                        onDelete(telegramMenu)
                    }}>
                        <div>{'Delete'}</div>
                    </button>
                </td>
            }
        </tr>
    </React.Fragment>
}

export default TelegramPageItem;
import gql from 'graphql-tag';

export const DELETE_ONE_REWARD_TIER = gql`
    mutation(
        $id: ID!
    ) {
        deleteOneRewardTier (input: {
            id:$id
        }) {
            id
        }
    }
`;


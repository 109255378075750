import gql from "graphql-tag";
import { TelegramButtonFragment } from "../fragments/telegram-button-fragment";

export const GET_ONE_TELEGRAM_BUTTON = gql`
  query ($id: ID!) {
    telegramButton(id: $id) {
      ...TelegramButton
    }
  }
  ${TelegramButtonFragment}
`;

import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const MARK_NO_REFERRAL = gql`
    mutation(
        $playerId: String!
    ) {
        markAsNoReferrer (
            playerId: $playerId
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
import gql from 'graphql-tag';

export const LAST_DEPOSIT = gql`
    query (
        $playerId: String!
    ) {
        lastDeposit (
            playerId: $playerId
        ) {
            id
            requiredRolloverAmount
            accumulatedRolloverAmount
            amount
            transactionType
            transactionStatus
        }
    }
`;
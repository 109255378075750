import React, { useRef } from 'react';
import { Container, Row, Col, Table, NavItem, NavLink, Card, CardBody } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { toggleRightSidebar } from "../../store/actions";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery } from 'react-apollo';
import classnames from 'classnames';
import _ from 'lodash';
import { Link, useHistory } from "react-router-dom";
import InfiniteScroll from 'react-infinite-scroll-component';
import { WALLET_ENTRIES } from '../../graphql/query';
import RewardPointItem from './reward-point-item';
import { GET_USERS_ID } from '../../graphql/query/GET_USERS_ID';

function TransactionRewardPoint(props) {

    const first = 50;
    const { t } = props;
    const { tab } = props.match.params
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [afterCursor, setAfterCursor] = React.useState(null)
    const [pageInfo, setPageInfo] = React.useState(false)
    const [isFetchMore, setIsFetchMore] = React.useState(false)
    const [activeTab, setActiveTab] = React.useState('RPGenerated')
    const [filter, setFilter] = React.useState({ walletEntryType: { in: 'RPGenerated' } })
    const [orderBy, setOrderBy] = React.useState("createdAt")
    const [orderDirection, setOrderDirection] = React.useState("DESC")
    const history = useHistory();

    const UsernameFilter = () => {
        if (_.isEmpty(search)) {
            return {}
        } else {
            return { username: { iLike: `%${search}%` } }
        }
    }

    const {data: userData} = useQuery(GET_USERS_ID, {
        variables: {
            paging: {
                first: 50
            },
            filter: { ...UsernameFilter() }

        }
    })

    const Filter = (filter) => {
        if (_.isEmpty(search)) {
            return {
                ...filter
            }
        } else {
            return {
                ...filter,
                wallet: {userId: { in: userData.users.edges.map((user) => { return user.node.id }) } },
                isActive: {is: true}
            }
        }
    }

    const [transactionFilter, setTransactionFilter] = React.useState({ ...Filter(filter) })

    const { data: transactionData, fetchMore, loading, refetch } = useQuery(WALLET_ENTRIES, {
        variables: {
            paging: {
                first: first
            },
            filter: { ...transactionFilter },
            sorting: [{
                field: orderBy,
                direction: orderDirection
            }]
        },
        fetchPolicy: 'network-only'
    })

    React.useEffect(() => {
        if (userData) {
            setTransactionFilter({ ...Filter(filter) })
        }
    }, [userData])

    React.useEffect(() => {
        if (transactionData) {
            setAfterCursor(transactionData.walletEntries.pageInfo.endCursor)
            setPageInfo(transactionData.walletEntries.pageInfo)
        }
    }, [transactionData])

    React.useEffect(() => {
        if (tab === 'pending') {
            setActiveTab('Pending');
            UpdateFilter('Pending')
        } else if (tab === 'all') {
            setActiveTab(null);
            UpdateFilter(['RPGenerated', 'RPClaimed', 'RPExpired', 'RPVoided'])
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (isFetchMore) {
            fetchMore({
                variables: {
                    paging: {
                        first: first,
                        after: afterCursor
                    },
                    filter: Filter(filter),
                    sorting: [{
                        field: orderBy,
                        direction: orderDirection,
                    }]
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                    const newEdges = fetchMoreResult.walletEntries.edges;
                    const pageInfo = fetchMoreResult.walletEntries.pageInfo;
                    setIsFetchMore(false)
                    setAfterCursor(pageInfo.endCursor)

                    return newEdges.length
                        ? {
                            walletEntries: {
                                __typename: previousResult.walletEntries.__typename,
                                edges: _.uniqBy([...previousResult.walletEntries.edges, ...newEdges], 'node.id'),
                                pageInfo
                            }
                        }
                        : previousResult;
                }
            })
        }
    }, [isFetchMore]) // eslint-disable-line react-hooks/exhaustive-deps

    const UpdateFilter = (type) => {
        if (type !== null) {
            setFilter({
                walletEntryType: { in: type }
            })

            setTransactionFilter({
                ...Filter({
                    walletEntryType: { in: type }
                })
            })
        } else {
            setFilter({
                walletEntryType: { in: ['RPGenerated', 'RPClaimed', 'RPExpired', 'RPVoided'] }
            })

            setTransactionFilter({
                ...Filter({
                    walletEntryType: { in: ['RPGenerated', 'RPClaimed', 'RPExpired', 'RPVoided'] }
                })
            })
        }
    }

    const OrderBy = (type) => {
        if (orderBy === type) {
            if (orderDirection === 'ASC') {
                setOrderDirection('DESC')
            } else {
                setOrderDirection('ASC')
            }
        } else {
            setOrderDirection('ASC')
            setOrderBy(type)
        }
    }

    const Header = (props) => {

        const { type, className, name } = props

        return (
            <th className={`pointer ${className}`} onClick={() => OrderBy(type)}>
                <div className={
                    classnames({
                        'align-center': orderBy === type
                    })
                }>
                    <div>{t(name)}</div>
                    {
                        orderBy === type &&
                        <>
                            {
                                orderDirection === 'ASC' &&
                                <i className="bx bx-sort-up"></i>
                            }
                            {
                                orderDirection === 'DESC' &&
                                <i className="bx bx-sort-down"></i>
                            }
                        </>
                    }
                </div>
            </th>
        )
    }

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content transactions-page max-height">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Transactions" breadcrumbItem="Reward Point" />
                    <div className="search-bar-container">
                        <div className="button-add-bank-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>

                        {/* <div className="align-right">
                            <div className="filter-container button-filter-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bank" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div>
                        </div> */}
                    </div>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col sm="12">
                                    <div className="overflow-auto">
                                        <ul className="nav nav-tabs nav-tabs-custom mb-2">
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPGenerated' })} onClick={() => {
                                                    setActiveTab('RPGenerated');
                                                    UpdateFilter('RPGenerated')
                                                }} >
                                                    Generated
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPClaimed' })} onClick={() => {
                                                    setActiveTab('RPClaimed');
                                                    UpdateFilter('RPClaimed')
                                                }} >
                                                    Claimed
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPExpired' })} onClick={() => {
                                                    setActiveTab('RPExpired');
                                                    UpdateFilter('RPExpired')
                                                }} >
                                                    Expired
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === 'RPVoided' })} onClick={() => {
                                                    setActiveTab('RPVoided');
                                                    UpdateFilter('RPVoided')
                                                }} >
                                                    Voided
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink className={classnames({ active: activeTab === null })} onClick={() => {
                                                    setActiveTab(null);
                                                    UpdateFilter(null)
                                                }} >
                                                    All
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                        {
                                            transactionData &&
                                            <InfiniteScroll
                                                dataLength={transactionData.walletEntries.edges.length} //This is important field to render the next data
                                                next={() => setIsFetchMore(true)}
                                                hasMore={pageInfo.hasNextPage}
                                                loader={
                                                    <div className="text-center my-3">
                                                        <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                                    </div>
                                                }
                                                endMessage={
                                                    <div className="text-center my-3">
                                                        <div>Yay! You have seen it all</div>
                                                    </div>
                                                }
                                            >
                                                <Table className="table mb-0">
                                                    <thead>
                                                        <tr className="font-size-12">
                                                            <th>Transaction ID</th>
                                                            <Header name='Created At' className="" type='createdAt' />
                                                            <th>Username</th>
                                                            <Header name='Collected' className="" type='deposit' />
                                                            <Header name='Used' className="" type='withdrawal' />
                                                            <th>Movement</th>
                                                            <Header name='Updated At' className="" type='updatedAt' />
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="table-body">
                                                        {
                                                            transactionData &&
                                                            transactionData.walletEntries.edges.map((walletEntry, index) => {
                                                                return (
                                                                    <RewardPointItem
                                                                        key={index}
                                                                        walletEntry={walletEntry}
                                                                        onManageInWorkbench={() => {
                                                                            history.push({
                                                                                "pathname": "/cashier-workbench",
                                                                                target: "_blank",
                                                                                state: { user: { node: walletEntry.node.wallet.user } }
                                                                            });
                                                                        }}
                                                                    />
                                                                )
                                                            })
                                                        }
                                                    </tbody>
                                                </Table>
                                            </InfiniteScroll>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            <div className="load-more-container">
                                {
                                    (loading) &&
                                    <Col xs="12">
                                        <div className="text-center my-3">
                                            <Link to="#" className="text-success"><i className="bx bx-hourglass bx-spin mr-2"></i> Load more </Link>
                                        </div>
                                    </Col>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType, ...state.Transactions };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(TransactionRewardPoint));
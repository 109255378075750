import gql from 'graphql-tag';
import { TransactionFragment } from '../fragments/transaction.fragment';

export const UPDATED_ONE_TRANSACTION = gql`
    subscription {
        updatedOneTransaction {
            __typename
            ...TransactionInfo
            player {
                id
                serialNo
                username
                name
                telegramId
                tags (sorting: {
                    field: tagType,
                    direction: ASC
                }) {
                    edges {
                        node {
                            name
                            tagType
                        }
                    }
                }
            }
        }
    }
    ${TransactionFragment}
`;
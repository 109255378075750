import React from 'react';
import moment from 'moment';
import { useQuery } from 'react-apollo';
import { ME } from '../../graphql/query';
function AgentPointItem(props) {

    const { walletEntry, onManageInWorkbench } = props;

    const { data: meData } = useQuery(ME)

    return (
        <React.Fragment>
            <tr className="font-size-12 transactions-page">
                <td>
                    {walletEntry.node.id}
                </td>
                <td>
                    <div>{moment(walletEntry.node.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                    {
                        walletEntry.node.createdBy &&
                        <div className="text-align">
                            <span className="mr-1 text-primary"><i className="bx bx-user font-size-20" /></span>{walletEntry.node.createdBy}
                        </div>
                    }
                </td>
                <td>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {
                            walletEntry.node.wallet.user &&
                            <div className="cursor-pointer" onClick={onManageInWorkbench}>
                                <i className="bx bxs-wrench font-size-20 mr-2"></i>
                            </div>
                        }
                        {walletEntry.node.wallet ? walletEntry.node.wallet.user ? walletEntry.node.wallet.user.username : '-' : '-'}
                    </div>
                </td>
                <td>
                    {`${walletEntry.node.deposit}RP`}
                </td>
                <td>
                    {`${walletEntry.node.withdrawal}RP`}
                </td>
                <td>
                    {walletEntry.node.description}
                </td>
                <td>
                    <div>{moment(walletEntry.node.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                    {
                        walletEntry.node.updatedBy &&
                        <div className="text-align">
                            <span className="mr-1 text-primary"><i className="bx bx-user font-size-20" /></span>{walletEntry.node.updatedBy}
                        </div>
                    }
                </td>
                <td>
                    {walletEntry.node.walletEntryType}
                </td>
                {/* <td className="one-line">
                    {
                        !transaction.node.player.telegramId &&
                        <i className="bx bxl-chrome font-size-18 mr-2 chrome"></i>
                    }
                    {
                        transaction.node.player.telegramId &&
                        <i className="bx bxl-telegram font-size-18 mr-2 telegram"></i>
                    }
                    {transaction.node.serialNo}
                </td>
                <td>
                    <div>{moment(transaction.node.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                    {
                        transaction.node.createdBy &&
                        <div className="text-align">
                            <span className="mr-1 text-primary"><i className="bx bx-user font-size-20" /></span>{transaction.node.createdBy}
                        </div>
                    }
                </td>
                {
                    ((transaction.node.transactionType !== 'Deposit' &&
                        transaction.node.transactionType !== 'Withdrawal' &&
                        transaction.node.transactionType !== 'FreeCredit' &&
                        transaction.node.transactionType !== 'Rebate' &&
                        transaction.node.transactionType !== 'Bonus') || type === 'All') &&
                    <td>{transaction.node.transactionType}</td>
                }
                <td className="one-line">
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div className="cursor-pointer" onClick={onManageInWorkbench}>
                            <i className="bx bxs-wrench font-size-20 mr-2"></i>
                        </div>
                        {transaction.node.player.username}
                        {
                            transaction.node.player.tags.edges.length > 0 &&
                            <>
                                <div className="pl-1"></div>
                                <i className="bx bxs-info-circle text-danger font-size-16" id={`tooltip-${transaction.node.id}`} />
                                <UncontrolledTooltip placement="right" target={`tooltip-${transaction.node.id}`}>
                                    {
                                        transaction.node.player.tags.edges.map((tag) =>
                                            <li className="align-left">
                                                {
                                                    tag.node.tagType === 'Good' &&
                                                    <i className="bx bx-check font-size-18 text-success mr-1" />
                                                }
                                                {
                                                    tag.node.tagType === 'Neutral' &&
                                                    <i className="bx bxs-circle font-size-10 text-white ml-1 mr-2 mt-1" />
                                                }
                                                {
                                                    tag.node.tagType === 'Bad' &&
                                                    <i className="bx bx-x font-size-18 text-danger mr-1" />
                                                }
                                                {tag.node.name}
                                            </li>
                                        )
                                    }
                                </UncontrolledTooltip>
                            </>
                        }
                    </div>
                </td> */}
            </tr>
        </React.Fragment >
    );
}

export default AgentPointItem;
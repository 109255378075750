import gql from 'graphql-tag';
import { TierFragment } from '../fragments/tier.fragment';

export const UPDATE_ONE_REWARD_TIER = gql`
    mutation(
        $input: UpdateOneRewardTierInput!
    ) {
        updateOneRewardTier (input: $input) {
           ...TierInfo
        }
    }
    ${TierFragment}
`;
import gql from "graphql-tag";

export const TelegramMenuButtonFragment = gql`
  fragment TelegramMenuButton on TelegramMenuButton {
    id
    position
    telegramMenuId
    isActive
    telegramButtonId
    telegramButton {
      id
      action
    }
  }
`;

import gql from 'graphql-tag';

export const DELETE_TRANSACTION = gql`
    mutation(
        $id: String!
    ) {
        deleteTransaction (id:$id)
    }
`;


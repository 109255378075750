import gql from "graphql-tag";

export const TelegramMenuFragment = gql`
  fragment TelegramMenu on TelegramMenu {
    id
    name
    description
    isActive
  }
`;

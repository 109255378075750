import gql from 'graphql-tag';

export const GENERATE_WAITING_LIST = gql`
    mutation (
        $input: MarketingCampaignParticipantListInput!
        $marketingCampaignId: String!
    ) {
        generateWaitingList (
            input: $input
            marketingCampaignId: $marketingCampaignId
        )
    }
`;
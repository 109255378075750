import gql from 'graphql-tag';

export const GET_SETTINGS = gql`
query (
    $filter: SettingFilter
    $sorting: [SettingSort!]
) {
    settings (
        filter: $filter
        sorting: $sorting
    ) {
        edges {
            node {
                id
                defaultValue
                description
                key
                value
                fieldType
                developerOnly
            }
        }
    }
}
`;
import gql from 'graphql-tag';

export const TierFragment = gql`
fragment TierInfo on RewardTier {
    id
    minDepositAmount
    maxDepositAmount
    rewardMultiplier
    isActive
    isArchived
    createdAt
    updatedAt
    createdBy
    updatedBy
  }
`;
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const UPDATE_ONE_USER = gql`
    mutation(
        $input: UpdateOneUserInput!
    ) {
        updateOneUser (
            input: $input
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
import gql from 'graphql-tag';

export const CALCULATE_BONUS_AMOUNT = gql`
    query (
        $depositAmount: Float!
        $bonusId: String!
    ) {
        calculateBonus (
            depositAmount: $depositAmount
            bonusId: $bonusId
        ) {
            requiredRolloverAmount
            bonusAmount
        }
    }
`;
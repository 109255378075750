import {
    CHANGE_FILTER,
    CHANGE_SORT
} from "./actionTypes";

export const changeFilter = layout => ({
    type: CHANGE_FILTER,
    payload: layout
});

export const changeSort = layout => ({
    type: CHANGE_SORT,
    payload: layout
});
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';
import { PageFragment } from '../fragments/page.fragment';
import { TagFragment } from '../fragments/tag.fragment';

export const GET_USERS = gql`
    query (
        $paging: CursorPaging
        $filter: UserFilter
        $sorting: [UserSort!]
        $tagfilter:TagFilter
    ) {
        users (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...UserInfo
                    wallets {
                        edges {
                            node {
                                id
                                balance
                                name
                                walletType
                                expiredAt
                            }
                        }
                    }
                    tags(
                        filter:$tagfilter
                        sorting: { field: name, direction: ASC }
                    ){
                        edges {
                            node {
                                ...TagInfo
                            }
                        }
                    }
                }
            }
        }
    }
    ${UserFragment}
    ${PageFragment}
    ${TagFragment}
`;
import gql from 'graphql-tag';

import { GameAccountFragment } from '../fragments/game-account.fragment';

export const UPDATE_ONE_GAME_ACCOUNT= gql`
    mutation(
        $input: UpdateOneGameAccountInput!
    ) {
        updateOneGameAccount (
            input: $input
            ) {
            ...GameAccountInfo
        }
    }
    ${GameAccountFragment}
`;
import gql from "graphql-tag";
import { PageFragment } from "../fragments/page.fragment";
import { TelegramButtonFragment } from "../fragments/telegram-button-fragment";

export const GET_TELEGRAM_BUTTONS = gql`
  query (
    $filter: TelegramButtonFilter
    $paging: CursorPaging
    $sorting: [TelegramButtonSort!]
  ) {
    telegramButtons(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        ...Page
      }
      edges {
        node {
          ...TelegramButton
        }
      }
    }
  }
  ${PageFragment}
  ${TelegramButtonFragment}
`;

import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';

export const GET_MARKETING_PARTICIPANTS_COUNT = gql`
    query (
        $paging: CursorPaging
        $filter: MarketingCampaignParticipantFilter
        $sorting: [MarketingCampaignParticipantSort!]
    ) {
        marketingCampaignParticipants (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            totalCount
            pageInfo {
                ...Page
            }
            edges {
                node {
                    participantId
                }
            }
        }
    }
    ${PageFragment}
`;
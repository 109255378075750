import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { MarketingFragment } from '../fragments/marketing.campaign.fragment';

export const GET_MARKETING_CAMPAIGN = gql`
    query (
        $paging: CursorPaging
        $filter: MarketingCampaignFilter
        $sorting: [MarketingCampaignSort!]
    ) {
        marketingCampaigns (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...MarketingCampaign
                }
            }
        }
    }
    ${MarketingFragment}
    ${PageFragment}
`;



import { AvField, AvForm } from "availity-reactstrap-validation";
import React, { Fragment } from 'react';
import { useMutation, useQuery } from 'react-apollo';
import {
    Button, Col, Modal, ModalBody, ModalHeader, Row
} from "reactstrap";
import { UPDATE_ONE_TAG } from '../../graphql/mutation/UPDATE_ONE_TAG';
import { GET_ENUM } from '../../graphql/query';
import ToastHelper from '../../helpers/ToastHelper';

export default function EditTag(props) {

    const { modal, toggleModal, tag } = props;
    const [updateOneTag] = useMutation(UPDATE_ONE_TAG);

    const { data: tagData } = useQuery(GET_ENUM, {
        variables: {
            name: "TagType"
        }
    })

    return <Fragment>
        <Modal isOpen={modal} role="dialog" size="md" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
            <div className="modal-content new-game-page">
                <ModalHeader toggle={toggleModal}>
                    Edit Tag
                </ModalHeader>
                <ModalBody>
                    <div id="new-game-wizard" className="twitter-bs-wizard">
                        <AvForm className="needs-validation"
                            onValidSubmit={(event, values) => {
                                updateOneTag({
                                    variables: {
                                        input: {
                                            "update": {
                                                name: values.tagname,
                                                tagType: values.tagType
                                            },
                                            id: tag.id
                                        }
                                    }
                                }).then((val) => {
                                    if (val) {
                                        toggleModal()
                                    }

                                }).catch((e) => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                })
                            }}
                        >
                            <Row>
                                <Col lg="12">
                                    <AvField
                                        label={'Tag Type'}
                                        type="select"
                                        name="tagType"
                                        className="custom-select"
                                        value={tag ? tag.tagType : tagData && tagData.__type.enumValues[0].name}
                                    >
                                        {
                                            tagData &&
                                            tagData.__type.enumValues.map(({ name }, index) => {
                                                return (
                                                    <option key={index} value={name}>{name}</option>
                                                )
                                            })
                                        }
                                    </AvField>
                                </Col>
                                <Col lg="12">
                                    <AvField
                                        label="Tag Name"
                                        name="tagname"
                                        type="text"
                                        value={tag ? tag.name : ''}
                                        placeholder="Enter Tag Name"
                                        errorMessage="Enter Tag Name"
                                        validate={{
                                            required: { value: true },
                                        }}
                                    />
                                </Col>
                            </Row>

                            <div className="action-container">
                                <Button color="primary" type="submit">Submit</Button>
                            </div>
                        </AvForm>
                    </div>
                </ModalBody>
            </div>
        </Modal>
    </Fragment >
}
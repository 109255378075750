import React, { useRef } from 'react';
import { Card, CardBody, Container, Table } from "reactstrap";

//Import Breadcrumb
import { useQuery } from '@apollo/react-hooks';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import 'react-medium-image-zoom/dist/styles.css';
import { connect } from "react-redux";
import { Admin, Developer, Manager, Supervisor } from '../../assets/scss/role.constant';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { GET_MARKETING_CAMPAIGN } from '../../graphql/query';
import RoleHelper from '../../helpers/RoleHelper';
import { toggleRightSidebar } from "../../store/actions";
import CampaignItem from './campaign-item';
import EditCampaign from './edit-campaign';
import NewCampaign from './new-campaign';

function MarketingCampaignList(props) {

    const [addCampaign, setAddCampaign] = React.useState(false)
    const [editCampaign, setEditCampaign] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [searchValue, setSearchValue] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [campaignItem, setCampaignItem] = React.useState(null)
    const [layoutToggle] = React.useState(false)
    const [filter, setFilter] = React.useState({})

    const onEdit = (campaign) => {
        setCampaignItem(campaign)
        setEditCampaign(true)
    }

    const { data: marketingCampaignData, refetch } = useQuery(GET_MARKETING_CAMPAIGN, {
        variables: {
            filter: {
                ...filter
            },
            sorting: [{ field: "createdAt", direction: "DESC" }]

        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only'
    });

    React.useEffect(() => {
        UpdateFilter()
    }, [search]) // eslint-disable-line react-hooks/exhaustive-deps

    const UpdateFilter = () => {
        let filter = { name: { iLike: `%${search}%` } }

        if (search === '') {
            delete filter["name"]
        }

        setFilter(filter)
    }

    const debouncedSave = useRef(
        _.debounce(nextValue => setSearch(nextValue), 300)).current

    return (
        <React.Fragment>
            <div className="page-content bonus-page">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Marketing Campaign" breadcrumbItem="Marketing Campaign" />
                    <div className="search-bar-container">
                        <div className="button-add-bonus-container">
                            <div className="app-search d-none d-lg-block">
                                <div className="position-relative">
                                    <input
                                        value={searchValue}
                                        type="text"
                                        className="form-control"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            setSearchValue(e.target.value)
                                            debouncedSave(e.target.value)
                                        }}
                                    />
                                    <span className="bx bx-search-alt"></span>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="dropdown d-inline-block d-lg-none ml-2">
                                    <button
                                        type="button"
                                        className="btn header-item noti-icon waves-effect"
                                        id="page-header-search-dropdown"
                                        onClick={() => { setIsSearch(!isSearch) }}>
                                        <i className="mdi mdi-magnify"></i>
                                    </button>
                                    <div
                                        className={isSearch ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show" : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"}
                                        aria-labelledby="page-header-search-dropdown"
                                    >
                                        <form className="p-3">
                                            <div className="form-group m-0">
                                                <div className="input-group">
                                                    <input
                                                        value={searchValue}
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Search..."
                                                        onChange={(e) => {
                                                            setSearchValue(e.target.value)
                                                            debouncedSave(e.target.value)
                                                        }}
                                                    />
                                                    <div className="input-group-append">
                                                        <button className="btn btn-primary" type="submit">
                                                            <i className="mdi mdi-magnify"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="button-add-bonus-container">
                            {/* <div className="filter-container button-filter-container">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus" onClick={() => {
                                    props.toggleRightSidebar();
                                }}>
                                    <i className="bx bxs-filter-alt font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-sort font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div> */}
                            {/* <div className="filter-container button-filter-container px-2">
                                <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus" onClick={() => {
                                    setLayoutToggle(!layoutToggle)
                                }}>
                                    <i className="bx bx-list-ul font-size-15 align-middle mr-2 filter"></i> | <i className="bx bx-grid-alt font-size-15 align-middle mr-2 sort"></i>
                                </button>
                            </div> */}
                            <button type="button" className="waves-effect btn btn-outline-secondary button-add-bonus ml-2" onClick={() => { setAddCampaign(true) }} disabled={!RoleHelper.checkRoles([Developer, Admin, Manager, Supervisor])}>
                                <i className="bx bx-plus font-size-15 align-middle mr-2"></i>New Campaign
                            </button>
                        </div>
                    </div>
                    {!layoutToggle &&
                        <Card className="w-100">
                            <CardBody>
                                <div className="table-responsive">
                                    <Table className="table mb-0">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Campaign Name</th>
                                                <th>Medium</th>
                                                <th>Start At</th>
                                                <th>End At</th>
                                                <th>Targeting Language</th>
                                                <th>Message</th>
                                                <th>Active?</th>
                                                <th>Auto Mode?</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                marketingCampaignData &&
                                                marketingCampaignData.marketingCampaigns.edges.length > 0 &&
                                                marketingCampaignData.marketingCampaigns.edges.map((marketingCampaign, key) =>
                                                    <tr key={"_marketing" + key}>
                                                        <CampaignItem
                                                            onEdit={onEdit}
                                                            marketingCampaign={marketingCampaign}
                                                        />
                                                    </tr >
                                                )
                                            }
                                            {
                                                marketingCampaignData &&
                                                marketingCampaignData.marketingCampaigns.edges.length === 0 &&
                                                <tr className="text-center pb-2">
                                                    <td colSpan={12}>
                                                        <div className="no-user pt-0">No Campaign Found</div>
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>}
                    {
                        addCampaign &&
                        <NewCampaign
                            modal={addCampaign}
                            toggleModal={() => {
                                refetch()
                                setAddCampaign(!addCampaign)
                            }}
                        />
                    }
                    {
                        editCampaign &&
                        <EditCampaign
                            modal={editCampaign}
                            toggleModal={() => {
                                setEditCampaign(!editCampaign)
                            }}
                            campaign={campaignItem}
                        />
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps, { toggleRightSidebar })(withTranslation()(MarketingCampaignList));

// export default ContactsList;
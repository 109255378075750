import classnames from 'classnames';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import SettingEditor from "../../../components/CommonForBoth/SettingEditor";

function MessageAndMediaList(props) {

    const [activeTab, setActiveTab] = React.useState('messageAndMediaforDepositSubmenu')


    const defaultValue = [
        {
            language: 'EN',
            content: 'EN Content',
            imageUrl: `https://res.cloudinary.com/djtp1hhhg/image/upload/v1676957791/Robot-clip-art-book-covers-feJCV3-clipart_i28qwk.png`,
        },
        {
            language: 'MS',
            content: 'MS Content',
            imageUrl: `https://res.cloudinary.com/djtp1hhhg/image/upload/v1676957791/Robot-clip-art-book-covers-feJCV3-clipart_i28qwk.png`,
        },
        {
            language: 'ZH',
            content: 'ZH Content',
            imageUrl: `https://res.cloudinary.com/djtp1hhhg/image/upload/v1676957791/Robot-clip-art-book-covers-feJCV3-clipart_i28qwk.png`,
        },
    ]

    return <React.Fragment>
        <div className="page-content telegram-page max-height">
            <Container fluid>
                {/* Render Breadcrumbs */}
                <Breadcrumbs title="Message & Media" breadcrumbItem="Message & Media" />
                <div className="chat-leftsidebar-nav">
                    <Nav pills>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforDepositSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforDepositSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Deposit Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforWithdrawalSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforWithdrawalSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Withdrawal Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforCreditTransferSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforCreditTransferSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Credit Transfer Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforGameIDSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforGameIDSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Game ID Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforNewIDSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforNewIDSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">New ID Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforRewardSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforRewardSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Reward Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforRewardPointSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforRewardPointSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Reward Point Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforAttendancePointSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforAttendancePointSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Attendance Point Submenu</span>
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === "messageAndMediaforAgentPointSubmenu" })}
                                onClick={() => { setActiveTab('messageAndMediaforAgentPointSubmenu'); }}
                            >
                                <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                <span className="d-none d-sm-block">Agent Point Submenu</span>
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab} className="py-4">
                        <TabPane tabId="messageAndMediaforDepositSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for deposit Submenu" settingKey="messageAndMediaforDepositSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforWithdrawalSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for withdrawal Submenu" settingKey="messageAndMediaforWithdrawalSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforCreditTransferSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for credit transfer Submenu" settingKey="messageAndMediaforCreditTransferSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforGameIDSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for GameID Submenu" settingKey="messageAndMediaforGameIDSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforNewIDSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for New id Submenu" settingKey="messageAndMediaforNewIDSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforRewardSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for reward Submenu" settingKey="messageAndMediaforRewardSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforRewardPointSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for reward point Submenu" settingKey="messageAndMediaforRewardPointSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforAttendancePointSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for attendance point Submenu" settingKey="messageAndMediaforAttendancePointSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                        <TabPane tabId="messageAndMediaforAgentPointSubmenu">
                            <Row>
                                <Col md={12} lg={12} xl={12} sm={12}>
                                    <Card>
                                        <CardBody>
                                            <SettingEditor description="Media for agent point Submenu" settingKey="messageAndMediaforAgentPointSubmenu" settingType="Bot" fieldType="JSON" inputValue={JSON.stringify(defaultValue)} defaultValue={JSON.stringify(defaultValue)} />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </TabPane>
                    </TabContent>
                </div>
            </Container>
        </div>
    </React.Fragment>
}

export default connect()(withTranslation()(MessageAndMediaList));
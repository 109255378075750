import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { PaymentTypeFragment } from '../fragments/payment.type.fragment';

export const GET_PAYMENT_TYPES = gql`
query (
    $paging: CursorPaging
    $filter: PaymentTypeFilter
    $sorting: [PaymentTypeSort!]
) {
    paymentTypes (
        paging: $paging
        filter: $filter
        sorting: $sorting
    ) {
        pageInfo {
            ...Page
        }
        edges {
            node {
               ...PaymentType
            }
        }
    }
}
${PageFragment}
${PaymentTypeFragment}
`;
import gql from 'graphql-tag';
import { GameAccountFragment } from '../fragments/game-account.fragment';

export const CREATE_ONE_GAME = gql`
    mutation(
        $input: CreateOneGameAccountInput!
    ) {
        createOneGameAccount (input: $input) {
            ...GameAccountInfo
        }
    }
    ${GameAccountFragment}
`;
import gql from "graphql-tag";
import { FreeCreditTypeDetailFragment } from "../fragments/free.credit.fragment";

export const CREATE_ONE_FREE_CREDIT_TYPE_DETAIL = gql`
  mutation ($input: CreateOneFreeCreditTypeDetailInput!) {
    createOneFreeCreditTypeDetail(input: $input) {
      ...FreeCreditTypeDetail
    }
  }
  ${FreeCreditTypeDetailFragment}
`;

import gql from 'graphql-tag';
import { KioskFragment } from '../fragments/kiosk.fragment';
import { PageFragment } from '../fragments/page.fragment';
import { PlatformFragment } from '../fragments/platform.fragment';
import { GameAccountFragment } from '../fragments/game-account.fragment'

export const GET_PLATFORM_BY_USER = gql`
    query (
        $paging: CursorPaging
        $filter: KioskAccountFilter
        $sorting: [KioskAccountSort!]
        $gamefilter: GameAccountFilter
    ) {
        kioskAccounts (
            paging: $paging
            filter: $filter
            sorting: $sorting
        ) {
            pageInfo {
                ...Page
            }
            edges {
                node {
                    ...KioskInfo
                    gameAccounts (
                        filter: $gamefilter
                    ){
                        edges {
                            node {
                                ...GameAccountInfo
                            }
                        }
                    }
                }
            }
        }
    }
    ${KioskFragment}
    ${PlatformFragment}
    ${PageFragment}
    ${GameAccountFragment}
`;
import gql from 'graphql-tag';

import { TagFragment } from '../fragments/tag.fragment';

export const CREATE_ONE_TAG = gql`
    mutation(
        $input: CreateOneTagInput!
    ) {
        createOneTag(input: $input){
            ...TagInfo  
        }
    }
    ${TagFragment}
`;
import gql from "graphql-tag";

export const SALE_SUMMARY_REPORT = gql`
  query (
    $periodType: PeriodType!
    $fromDate: String
    $toDate: String
    $orderBy: String
    $orderDirection: String
    $skip: Int
    $take: Int
  ) {
    salesSummaryReport(
      periodType: $periodType
      fromDate: $fromDate
      toDate: $toDate
      orderBy: $orderBy
      orderDirection: $orderDirection
      skip: $skip
      take: $take
    ) {
      totalCount
      period
      totalNewRegisterCreditAmount
      totalDepositAmount
      totalDepositCount
      averageDepositAmount
      highestDepositAmount
      totalBonusAmount
      totalFreeCreditAmount
      totalWithdrawalAmount
      totalWithdrawalCount
      averageWithdrawalAmount
      highestWithdrawalAmount
      totalCreditIn
      totalCreditOut
      netWinning
      grossProfit
      netProfit
      activePlayerCount
      firstDepositorCount
      newPlayerCount
      accumulatedPlayerCount
      diffDepositPercentage
      totalRebateAmount
      adjustedProfit
      totalIncrementAmount
      totalDecrementAmount
      totalReferralAmount
      totalRewardAmount
      totalCommissionAmount
      totalDailyAttendanceAmount
      totalWeeklyAttendanceAmount
    }
  }
`;

import React, { useEffect, Fragment, useRef } from 'react';
import {
    Col,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    FormGroup,
    Card
} from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import ToastHelper from '../../helpers/ToastHelper';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import _ from 'lodash';
import { GET_USERS } from '../../graphql/query/GET_USERS';
import { ADD_TAG_USER } from '../../graphql/mutation/ADD_TAG_USER';
import { REMOVE_TAG_USER } from '../../graphql/mutation/REMOVE_TAG_USER';
import { GET_TAG } from '../../graphql/query/GET_TAG';

export default function TagPlayer(props) {

    const { modal, toggleModal, tag } = props;

    const [isValidTag, setIsValidTag] = React.useState(true);
    const [search, setSearch] = React.useState("");
    const [curUserTag, setCurUserTag] = React.useState([]);

    const [removeTags] = useMutation(REMOVE_TAG_USER);
    const [addTags] = useMutation(ADD_TAG_USER);

    const typeaheadRef = useRef(null);


    const handleTagSearch = (data) => {
        setSearch(data)
    }

    const { data, loading: isLoading } = useQuery(GET_USERS, {
        variables: {
            filter: {
                and: [
                    { name: { iLike: `%${search}%` } },
                    { role: { eq: "Player" } },
                ]
            },
            paging: {
                first: 50
            },
            tagfilter:
                { id: { neq: tag.id } },
            sorting: [{ field: "name", direction: "ASC" }]

        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'network-only',
    });

    const [getTag, { data: tagData }] = useLazyQuery(GET_TAG, {
        variables: {
            id: tag.id
        },
        fetchPolicy: 'network-only'
    })


    useEffect(() => {
        if (tag) {
            getTag()
            // setfilterExisiting(constructFilter(user));
        }
    }, [tag]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (tagData) {
            console.log(tagData)
            setCurUserTag(_.map(tagData.tag.users.edges, 'node'));
        }
    }, [tagData])

    useEffect(() => {
        // setTag([]);
        setSearch("");
        setIsValidTag(true);
    }, [modal])



    const constructFilter = (user) => {
        const usertags = user.tags;
        console.log(usertags);
        let arr = _.map(usertags, "id")
        console.log(arr);
        return { id: { notIn: arr } }
    }


    return (
        <React.Fragment>
            <Modal isOpen={modal} role="dialog" size="lg" autoFocus={true} centered={true} id="verificationModal" tabIndex="-1" toggle={toggleModal}>
                {
                    tag &&
                    <div className="modal-content player-tags-page">
                        <ModalHeader toggle={toggleModal}>
                            Manage Tags
                        </ModalHeader>
                        <ModalBody>
                            <Card id="reset-password-wizard" className="twitter-bs-wizard m-1 p-2">
                                <AvForm className="needs-validation" onValidSubmit={(event, values) => {

                                }}>

                                    <Row className="align-items-center">
                                        <Col lg="12">
                                            <FormGroup className={`${!isValidTag ? 'text-danger' : ''}`}>
                                                <Label for="newrole-input">Tags for {tag.name}</Label>
                                                <AsyncTypeahead
                                                    multiple={true}

                                                    delay={0}
                                                    id="tag"
                                                    isLoading={isLoading}
                                                    labelKey={option => `${option.username}`}
                                                    minLength={0}
                                                    onSearch={handleTagSearch}
                                                    onInputChange={(val) => {

                                                        // setInputValue(val);
                                                        // console.log(val)
                                                        if (val === "") {
                                                            setSearch(val)
                                                        }
                                                    }}

                                                    options={data ? data.users.edges.map(({ node }, index) => {
                                                        return node
                                                    }) : []}
                                                    placeholder="Search for Tags"
                                                    renderMenuItemChildren={(option, props) => (
                                                        <Fragment>
                                                            <span>{option.username}</span>
                                                        </Fragment>
                                                    )}
                                                    errorMessage="Enter Tag"
                                                    isInvalid={!isValidTag}
                                                    onChange={(val) => {
                                                        console.log(val);

                                                        let addedUsers = _.differenceBy(val, curUserTag, 'id');
                                                        console.log("addedUsers", addedUsers)




                                                        let deletedUsers = _.differenceBy(curUserTag, val, 'id');
                                                        console.log("deletedUsers", deletedUsers)



                                                        if (addedUsers.length > 0) {
                                                            addTags({
                                                                variables: {
                                                                    input: {
                                                                        id: addedUsers[0].id,
                                                                        relationIds: [tag.id]
                                                                    }
                                                                }
                                                            }).catch((e) => {
                                                                ToastHelper.toggleToast({
                                                                    toastType: "error",
                                                                    message: e,
                                                                    title: "",
                                                                    onClick: function () { }
                                                                })
                                                            }).then((data) => {

                                                                if (data) setCurUserTag(val);
                                                            })


                                                        }


                                                        if (deletedUsers.length > 0) {
                                                            removeTags({
                                                                variables: {
                                                                    input: {
                                                                        id: deletedUsers[0].id,
                                                                        relationIds: [tag.id]
                                                                    }
                                                                }
                                                            }).catch((e) => {
                                                                ToastHelper.toggleToast({
                                                                    toastType: "error",
                                                                    message: e,
                                                                    title: "",
                                                                    onClick: function () { }
                                                                })
                                                            }).then((data) => {
                                                                if (data) {
                                                                    setCurUserTag(val);
                                                                }

                                                            })

                                                        }

                                                    }


                                                        // setTag(val)

                                                        // if (curUserTag.length == 0) {

                                                        // } else {
                                                        //     removeTags({
                                                        //         variables: {
                                                        //             input: {
                                                        //                 id: user.id,
                                                        //                 relationIds: curUserTag.map((obj) => { return obj.id })
                                                        //             }
                                                        //         }
                                                        //     }).catch((e) => {
                                                        //         ToastHelper.toggleToast({
                                                        //             toastType: "error",
                                                        //             message: e,
                                                        //             title: "",
                                                        //             onClick: function () { }
                                                        //         })
                                                        //     }).then(() => {
                                                        //         if (val.length > 0) {
                                                        //             addTags({
                                                        //                 variables: {
                                                        //                     input: {
                                                        //                         id: user.id,
                                                        //                         relationIds: val.map((obj) => { return obj.id })
                                                        //                     }
                                                        //                 }
                                                        //             }).catch((e) => {
                                                        //                 ToastHelper.toggleToast({
                                                        //                     toastType: "error",
                                                        //                     message: e,
                                                        //                     title: "",
                                                        //                     onClick: function () { }
                                                        //                 })
                                                        //             }).then(() => {
                                                        //                 setCurUserTag(val);
                                                        //             })
                                                        //         } else {
                                                        //             setCurUserTag(val);
                                                        //         }

                                                        //     })

                                                        // }

                                                    }
                                                    onBlur={(e) => {
                                                        // if (isValidTag && country.length === 0) {
                                                        //     setIsValidTag(false)
                                                        // }
                                                    }}
                                                    selected={curUserTag}
                                                    ref={typeaheadRef}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            {/* {!createTagState && <Button color="primary" type="submit" onClick={() => {

                                                console.log(tag)



                                                if (!_.isEmpty(tag)) {
                                                    setTagList([...tagList, tag[0]])

                                                    setTag([]);

                                                    setSearch('');
                                                    refetch();

                                                    typeaheadRef.current.clear();


                                                } else {
                                                    setCreateTagState(true)
                                                }

                                            }}>Add</Button>} */}


                                            {/* {
                                                createTagState && <Col>
                                                    <div>
                                                        Tag Not found! Create new?
                                                    </div>
                                                    <div className="m-auto">
                                                        <Button color="danger" className="m-2" type="submit" onClick={() => {
                                                            setCreateTagState(false)
                                                        }}>No</Button>
                                                        <Button color="primary" className="m-2" type="submit" onClick={() => {

                                                            console.log("search", search);
                                                            // console.log(typeaheadRef.current);

                                                            createTag({
                                                                variables: {
                                                                    input: { tag: { name: search } }
                                                                }
                                                            }).catch(e => {
                                                                ToastHelper.toggleToast({
                                                                    toastType: "error",
                                                                    message: e,
                                                                    title: "",
                                                                    onClick: function () { }
                                                                })
                                                            }).then(val => {
                                                                if (val) {
                                                                    // console.log(val.data.createOneTag);

                                                                    setSearch('');
                                                                    refetch();
                                                                    typeaheadRef.current.clear();


                                                                }
                                                            })



                                                        }}>Yes</Button>
                                                    </div>
                                                </Col>
                                            } */}
                                        </Col>
                                    </Row>

                                </AvForm>
                            </Card>
                        </ModalBody>
                    </div>
                }
            </Modal>
        </React.Fragment>
    );
}
import gql from 'graphql-tag';
import { PageFragment } from '../fragments/page.fragment';
import { RejectReasonFragment } from '../fragments/reject.reason.fragment';

export const GET_REJECT_REASONS = gql`
query (
    $paging: CursorPaging
    $filter: RejectReasonFilter
    $sorting: [RejectReasonSort!]
) {
    rejectReasons (
        paging: $paging
        filter: $filter
        sorting: $sorting
    ) {
        pageInfo {
            ...Page
        }
        edges {
            node {
               ...RejectReason
            }
        }
    }
}
${PageFragment}
${RejectReasonFragment}
`;
import React from 'react';
import { Container, Row, Col, Nav, NavLink, Card, CardBody, TabContent, TabPane, Table, Button } from "reactstrap";
import classnames from "classnames";
import { GET_ENUM, GET_SETTINGS, ME } from '../../graphql/query';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { useQuery, useLazyQuery, useMutation } from 'react-apollo';
import SettingItem from './setting-item';
import NewSetting from './new-setting';
import EditSetting from './edit-setting';
import { DELETE_ONE_SETTING } from '../../graphql/mutation';
import ToastHelper from '../../helpers/ToastHelper';
import SweetAlert from 'react-bootstrap-sweetalert';

function SettingsTemplate(props) {

    const { t } = props;

    const [addSetting, setAddSetting] = React.useState(false)
    const [editSetting, setEditSetting] = React.useState(null)
    const [deleteConfirm, setDeleteConfirm] = React.useState(false)
    const [deleteItem, setDeleteItem] = React.useState(null)

    const { data: settingsTypeData } = useQuery(GET_ENUM, {
        variables: {
            name: "SettingType"
        }
    });

    const { data: meData } = useQuery(ME)

    const [deleteSetting] = useMutation(DELETE_ONE_SETTING)

    const [runSettings, { data: settingsData, loading: loadingSettings }] = useLazyQuery(GET_SETTINGS, {
        variables: {
            sorting: {
                field: "description",
                direction: "ASC"
            }
        },
        fetchPolicy: 'network-only'
    })

    const [activeTab, setActiveTab] = React.useState('0')

    React.useEffect(() => {
        if (settingsTypeData) {
            GetSettings()
        }
    }, [settingsTypeData && activeTab]) // eslint-disable-line react-hooks/exhaustive-deps

    const GetSettings = () => {
        runSettings({
            variables: {
                filter: {
                    settingType: { eq: settingsTypeData.__type.enumValues[activeTab].name }
                }
            },
        })
    }

    const Icon = (type) => {
        switch (type) {
            case "Banking":
                return "bxs-bank"
            case "Kiosk":
                return "bx-laptop"
            case "API":
                return "bx-server"
            case "LandingPage":
                return "bxs-dashboard"
            case "Bot":
                return "bx-bot"
            case "Developer":
                return "bxs-skull"
            default:
                return "bx-help-circle";
        }
    }

    return (
        <React.Fragment>
            <div className="page-content settings">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="System" breadcrumbItem="Settings Template" />
                    <Row>
                        <Col lg="12">
                            <div className="vertical-nav">
                                <Row>
                                    <Col lg="2" sm="4">
                                        <Nav pills className="flex-column">
                                            {
                                                settingsTypeData &&
                                                settingsTypeData.__type.enumValues.map(({ name }, index) => {
                                                    return (
                                                        <NavLink key={index} className={classnames({ active: activeTab === index.toString() })} onClick={() => {
                                                            setActiveTab(index.toString())
                                                        }} >
                                                            <i className={`bx ${Icon(name)} nav-icon d-block mb-2`}></i>
                                                            <p className="font-weight-bold mb-0">{name}</p>
                                                        </NavLink>
                                                    )
                                                })
                                            }
                                        </Nav>
                                    </Col>
                                    {
                                        settingsTypeData &&
                                        <Col lg="10" sm="8">
                                            <Card>
                                                <CardBody>
                                                    <TabContent>
                                                        <TabPane>
                                                            <h4 className="card-title mb-4">{settingsTypeData.__type.enumValues[activeTab].name}</h4>
                                                            <Row className="pt-3">
                                                                <Col sm="12">
                                                                    <div className="table-responsive">
                                                                        <Table className="table mb-0">
                                                                            <thead>
                                                                                <tr className="font-size-12">
                                                                                    <th>Description</th>
                                                                                    <th>Key</th>
                                                                                    <th>Type</th>
                                                                                    <th>Value</th>
                                                                                    {
                                                                                        meData &&
                                                                                        meData.me.role === "Developer" &&
                                                                                        < th > Developer Only</th>
                                                                                    }
                                                                                </tr>
                                                                            </thead>
                                                                            {
                                                                                loadingSettings &&
                                                                                <tbody className="table-body">
                                                                                    <tr className="text-center pt-3">
                                                                                        <td colSpan={12}>
                                                                                            <i className="fas fa-spinner fa-spin font-size-17 align-middle"></i>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            }
                                                                            {
                                                                                meData &&
                                                                                settingsData &&
                                                                                (meData.me.role === "Developer" ? settingsData.settings.edges :
                                                                                    _.filter(settingsData.settings.edges, setting => {
                                                                                        return setting.node.developerOnly === false
                                                                                    })).map((setting, index) => {
                                                                                        return (
                                                                                            <SettingItem
                                                                                                key={index}
                                                                                                setting={setting}
                                                                                                t={t}
                                                                                                onEdit={(data) => {
                                                                                                    setEditSetting(data)
                                                                                                }}
                                                                                                onDelete={() => {
                                                                                                    setDeleteItem(setting)
                                                                                                    setDeleteConfirm(true)
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                            }
                                                                            {
                                                                                settingsData &&
                                                                                meData &&
                                                                                settingsData &&
                                                                                (meData.me.role === "Developer" ? settingsData.settings.edges :
                                                                                    _.filter(settingsData.settings.edges, setting => {
                                                                                        return setting.node.developerOnly === false
                                                                                    })).length === 0 &&
                                                                                <tbody className="table-body">
                                                                                    <tr className="text-center pt-3">
                                                                                        <td colSpan={12}>
                                                                                            {t('No Settings Found')}
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            }
                                                                            {
                                                                                !loadingSettings &&
                                                                                <tr className="text-center">
                                                                                    <td colSpan={12}>
                                                                                        <Button color="primary font-size-12" onClick={() => {
                                                                                            setAddSetting(true)
                                                                                        }}>{t('New Key')}</Button>
                                                                                    </td>
                                                                                </tr>
                                                                            }
                                                                        </Table>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </TabPane>
                                                    </TabContent>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {
                        settingsTypeData &&
                        <NewSetting
                            modal={addSetting}
                            toggleModal={() => {
                                setAddSetting(false)
                                GetSettings()
                            }}
                            settingType={settingsTypeData.__type.enumValues[activeTab].name}
                        />
                    }
                    {
                        <EditSetting
                            modal={!_.isEmpty(editSetting)}
                            toggleModal={() => {
                                setEditSetting(null)
                                GetSettings()
                            }}
                            setting={editSetting}
                        />
                    }
                    {
                        deleteConfirm &&
                        <SweetAlert
                            error
                            showCancel
                            confirmBtnBsStyle="danger"
                            cancelBtnBsStyle="primary"
                            title={'Alert'}
                            onConfirm={() => {
                                setDeleteConfirm(false)
                                deleteSetting({
                                    variables: {
                                        id: deleteItem.node.id
                                    }
                                }).catch(e => {
                                    ToastHelper.toggleToast({
                                        toastType: "error",
                                        message: e,
                                        title: "",
                                        onClick: function () { }
                                    })
                                }).then(val => {
                                    if (val) {
                                        ToastHelper.toggleToast({
                                            message: "Update Succesfully!",
                                            title: "",
                                            onClick: function () { }
                                        })
                                        GetSettings()
                                        setDeleteItem(null)
                                    }
                                })
                            }}
                            onCancel={() => {
                                setDeleteItem(null)
                                setDeleteConfirm(false)
                            }}
                        >
                            {`Confirm Delete?`}
                        </SweetAlert>
                    }
                </Container>
            </div>
        </React.Fragment >
    );
    // }
}

const mapStatetoProps = state => {
    const { layoutType } = state.Layout;
    return { layoutType };
};

export default connect(mapStatetoProps)(withTranslation()(SettingsTemplate));

// export default ContactsList;
import gql from "graphql-tag";
import { PageFragment } from "../fragments/page.fragment";
import { TelegramMenuFragment } from "../fragments/telegram-menu.fragment";

export const GET_TELEGRAM_MENUS = gql`
  query (
    $filter: TelegramMenuFilter
    $paging: CursorPaging
    $sorting: [TelegramMenuSort!]
  ) {
    telegramMenus(paging: $paging, filter: $filter, sorting: $sorting) {
      pageInfo {
        ...Page
      }
      edges {
        node {
          ...TelegramMenu
          telegramMenuButtons {
            totalCount
          }
        }
      }
    }
  }
  ${PageFragment}
  ${TelegramMenuFragment}
`;

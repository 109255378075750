import gql from 'graphql-tag';


export const SYNC_ALL_GAME_ACCOUNT = gql`
    mutation(
        $input: String!
    ) {
        gameAccountSyncAllBalances(playerId: $input) {
            id
        }
    }
`;
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const SET_REFERRAL_FEE = gql`
    mutation(
        $setReferralFeeInput: SetReferralFeeInput!
        $playerId: String!
    ) {
        setReferralFee(
            setReferralFeeInput: $setReferralFeeInput
            playerId: $playerId
        ) {
          ...UserInfo 
        }
    }
    ${UserFragment}
`;
import gql from 'graphql-tag';
import { UserFragment } from '../fragments/user.fragment';

export const MARK_PHONE_AS_VERIFIED = gql`
    mutation(
        $userId: String!
    ) {
        markPhoneNoAsVerified (
            userId: $userId
        ) {
            ...UserInfo
        }
    }
    ${UserFragment}
`;
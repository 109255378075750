import gql from "graphql-tag";
import { TelegramMenuButtonFragment } from "../fragments/telegram-menu-button.fragment";

export const UPDATE_ONE_TELEGRAM_MENU_BUTTON = gql`
  mutation ($input: UpdateOneTelegramMenuButtonInput!) {
    updateOneTelegramMenuButton(input: $input) {
      ...TelegramMenuButton
    }
  }
  ${TelegramMenuButtonFragment}
`;

import React from "react";
import { Col, Row, Modal, ModalHeader, ModalBody, Button } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useMutation } from "react-apollo";
import { UPDATE_ONE_USER } from "../../../graphql/mutation";
import ToastHelper from "../../../helpers/ToastHelper";
import _ from "lodash";

export default function EditProfile(props) {
  const { modal, toggleModal, user } = props;

  const [updateOneUser] = useMutation(UPDATE_ONE_USER);

  return (
    <React.Fragment>
      <Modal
        isOpen={modal}
        role="dialog"
        size="lg"
        autoFocus={true}
        centered={true}
        id="verificationModal"
        tabIndex="-1"
        toggle={toggleModal}
      >
        <div className="modal-content edit-user-page">
          <ModalHeader toggle={toggleModal}>Edit User</ModalHeader>
          <ModalBody>
            <div id="edit-user-wizard" className="twitter-bs-wizard">
              <AvForm
                className="needs-validation"
                onValidSubmit={(event, values) => {
                  updateOneUser({
                    variables: {
                      input: {
                        update: {
                          name: values.name,
                          username: values.username,
                          phoneNo: _.isEmpty(values.mobileNo)
                            ? null
                            : values.mobileNo,
                          email: _.isEmpty(values.email) ? null : values.email,
                        },
                        id: user.id,
                      },
                    },
                  })
                    .catch((e) => {
                      ToastHelper.toggleToast({
                        toastType: "error",
                        message: e,
                        title: "",
                        onClick: function () {},
                      });
                    })
                    .then((val) => {
                      if (val) {
                        ToastHelper.toggleToast({
                          message: "Update Succesfully!",
                          title: "",
                          onClick: function () {},
                        });
                        toggleModal();
                      }
                    });
                }}
              >
                <Row>
                  <Col lg="6">
                    <AvField
                      value={user ? user.serialNo : ""}
                      label="Serial No"
                      name="serialNo"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col lg="6">
                    <AvField
                      value={user ? user.role : ""}
                      label="Role"
                      name="role"
                      type="text"
                      disabled
                    />
                  </Col>
                  <Col lg="6">
                    <AvField
                      value={user ? user.name : ""}
                      label="Name"
                      name="name"
                      type="text"
                      placeholder="Enter Name"
                      errorMessage="Enter name"
                      validate={{ required: { value: true } }}
                    />
                  </Col>
                  <Col lg="6">
                    <AvField
                      value={user ? user.username : ""}
                      label="Username"
                      name="username"
                      type="text"
                      disabled
                      placeholder="Enter Username"
                    />
                  </Col>
                  <Col lg="6">
                    <AvField
                      value={user ? user.phoneNo : ""}
                      name="mobileNo"
                      label="Mobile Number"
                      placeholder="Enter Mobile Number"
                      type="text"
                      errorMessage="Enter Only Number"
                      validate={{
                        pattern: {
                          value: "^[0-9]+$",
                          errorMessage: "Only Numbers",
                        },
                      }}
                    />
                  </Col>
                  <Col lg="6">
                    <AvField
                      value={user ? user.email : ""}
                      name="email"
                      label="Email"
                      placeholder="Enter Email"
                      type="email"
                      errorMessage="Enter Email"
                    />
                  </Col>
                </Row>
                <div className="action-container">
                  <Button color="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </AvForm>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </React.Fragment>
  );
}
